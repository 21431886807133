import { Component, Input } from '@angular/core'

@Component({
	selector: 'loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
	@Input() activateSpinner: boolean

	constructor() {
		this.activateSpinner = true
	}
}

<div class="loading-container" [hidden]="!session.isLogging()">
	<div class="tail-spin">&nbsp;</div>
</div>

<div class="login">
	<div class="topview container-fluid" [hidden]="session.isLogged() && session.isLogging()">
		<div class="row justify-content-center">
			<div class="login-container">
				<div class="panel panel-default">
					<div class="login-title-container">
						<h3>{{ 'LOGIN.PAGE_TITLE' | translate }}</h3>
					</div>

					<div class="panel-body">
						<!-- loginForm.$valid &&  -->
						<form role="form" #loginForm="ngForm" name="lForm" (ngSubmit)="login(loginForm)" novalidate>
							<div class="form-group" *ngIf="customer != ''">
								<span>Welcome {{ customer }} user!</span>
							</div>

							<div class="form-group">
								<label for="username">{{ 'LOGIN.USERNAME' | translate }}</label>
								<input type="text" class="form-control" name="username" id="username" placeholder="{{ 'LOGIN.USERNAME_HINT' | translate }}" [(ngModel)]="guestUser.username" #username="ngModel" required />
								<!-- ="login.username" -->
								<div class="error-wrapper" [hidden]="!(username.touched && username.invalid)">
									<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
								</div>
							</div>
							<div class="form-group">
								<label for="password">{{ 'LOGIN.PASSWORD' | translate }}</label>
								<fa-icon [icon]="showPwd ? faEyeOpen : faEyeClosed" (click)="clickTogglePassword()" aria-hidden="true" class="show-psw-icon"></fa-icon>
								<input [type]="showPwd ? 'text' : 'password'" class="form-control" name="password" id="password" placeholder="{{ 'LOGIN.PASSWORD_HINT' | translate }}" [(ngModel)]="guestUser.password" #password="ngModel" required />

								<div class="error-wrapper" [hidden]="!(password.touched && password.invalid)">
									<p class="error-message ng-hide">{{ 'MESSAGE.REQUIRED' | translate }}</p>
								</div>
							</div>
							<a class="float_right forgotpwd" (click)="openRecoverPwdModal()" ngbTooltip="{{ 'LOGIN.LINK_RECOVER_PWD_HINT' | translate }}" tooltip-placement="bottom"> {{ 'LOGIN.LINK_RECOVER_PWD' | translate }} </a>
							<div class="button-container">
								<button type="submit" class="btn btn-primary" [disabled]="loginForm.invalid || warning">{{ 'LOGIN.LOGIN_BUTTON' | translate }}</button>
							</div>
						</form>
						<div class="error-login-container">
							<p class="error-login ng-hide">{{ 'SESSION_STATUS.' + session.getStatus() | translate }}</p>
							<!-- [hidden]="session.getStatus()<3" -->
						</div>
					</div>
				</div>
				<div [hidden]="!oldFeBuild">
					<div class="alert alert-warning d-flex align-items-center warn-message" role="alert">
						<p style="white-space: pre-line">
							<fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon>
							{{ oldFeMessage }}
						</p>
					</div>
				</div>
			</div>
		</div>
		<!--        <footer></footer>  -->
	</div>
</div>

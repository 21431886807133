<div class="first-login">
	<!-- <button type="button" class="btn" (click)="success=!success">sucess</button>
	<button type="button" class="btn" (click)="isLoading=!isLoading">loading</button> only for test -->

	<div class="topview container-fluid" [hidden]="!session.isLogged() || session.isLogging()">
		<div class="row justify-content-center">
			<div class="col-6 verify-puk-container py-4">
				<div class="panel panel-default position-relative">
					<loader [activateSpinner]="isLoading "></loader>

					<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
						<div [hidden]="false" class="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div> -->

					<div class="verify-puk__panel" [ngClass]="isLoading ? '' : 'show' ">
						<div class="panel-heading" [hidden]="success">
							<h3 class="panel-title fw-bold">{{ 'VERIFY_PUK.PAGE_TITLE' | translate }}</h3>
						</div>
						<div class="panel-body">
							<p [hidden]="success || fail">{{ 'VERIFY_PUK.DESCRIPTION' | translate }}</p>
							<p [hidden]="success || fail">{{ 'VERIFY_PUK.DESCRIPTION_LINE2' | translate }}</p>

							<p [hidden]="!fail" style="color: #ff3b2d">{{ 'VERIFY_PUK.DESCRIPTION_FAIL' | translate }}</p>

							<div>
								<form name="verifyPukForm" [hidden]="success" #verifyPukForm="ngForm">
									<div class="form-group">
										<label for="password">{{ 'VERIFY_PUK.PUK' | translate }}</label>
										<!-- TODO controllo lunghezza minima?  -->
										<input type="password" maxlength="20" class="form-control normalcase" name="puk" id="puk" placeholder="{{ 'VERIFY_PUK.PUK_HINT' | translate }}" [(ngModel)]="puk" required />
									</div>

									<button type="submit" (click)="submit()" class="btn btn-primary mt-4" [disabled]="verifyPukForm.invalid">{{ 'BUTTONS.SUBMIT' | translate }}</button>
								</form>
							</div>

							<div class="row" [hidden]="!success">
								<div class="col-12 text-center success-container position-relative">
									<h3 class="fw-bold">{{ 'VERIFY_PUK.DESCRIPTION_SUCCESS' | translate }}</h3>

									<loader [activateSpinner]="showIcon "></loader>
									<!-- <div class="loader-container" [ngClass]="showIcon ? '' : 'show' ">
										<div [hidden]="false" class="lds-ring">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
										<p>Loading...</p>
									</div> -->
									<fa-icon style="color: #28b32e; font-size: 100px" [ngClass]="showIcon ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
									<div>
										<p>{{ puk }}</p>
										<button type="button" class="btn btn-primary" (click)="goLanding()" [disabled]="!showIcon">{{ 'BUTTONS.PROCEED' | translate }}</button>
									</div>
								</div>
							</div>

							<!-- 13.12.2017 RESET PUK - rivedere grafica/testo -->
							<!-- non attivo sulla nexus
								<div [hidden]="!fail">
								<p>&nbsp;
								<p>{{"VERIFY_PUK.RESET_TITLE_MSG" | translate}}</p>
								<button type="button" class="btn btn-primary btn-block" ng-click="forgotPuk()">{{"VERIFY_PUK.RESET" | translate}}</button>
								</div>
								-->
						</div>
					</div>
				</div>
			</div>
		</div>
		<footer></footer>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core'

import { Patient } from '../../models/patient.model'
import { SessionService } from '../../service/session.service'

@Component({
	selector: 'patient',
	templateUrl: './patient.component.html',
	styleUrls: ['./patient.component.scss'],
})
export class PatientComponent implements OnInit {
	@Input() currPatient: Patient

	constructor(public session: SessionService) {
		// public currPatient: Patient
		this.currPatient = new Patient() // 13.01.2023 patch per mantis 306
	}

	ngOnInit(): void {}
}

<div class="push-update-container panel-modal position-relative" style="min-height: 30vh">
	<div class="btn-close-container-pos">
		@if(!pushing.state){
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
		}
	</div>

	<div class="container-fluid">
		<div class="row mt-3" *ngIf="!pushing.state">
			<!-- device selection list -->
			<div class="col-sm-12 col-xxl-6 px-1">
				<div class="title-section text-center">
					<h4 class="fw-bold">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.TITLE1' | translate }}</h4>
					<p>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.SUBTITLE1' | translate }}</p>
				</div>

				<!-- filters section -->
				<div class="container-fluid">
					<section class="row">
						<div class="col-12">
							<p class="filter-title-section w-50" (click)="showFilters = !showFilters" [ngClass]="showFilters ? '' : 'border-bottom' ">
								<label class="fw-bold uppercase">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.FILTERS.TITLE' | translate }}</label>
								<fa-icon [icon]="faSortDown" faSortDown [ngClass]="showFilters ? 'show' : '' " aria-hidden="true" class="float-end"></fa-icon>

								<span *ngIf="filterSet" class="me-2 fw-light float-end">{{'DISTRIB_LIST.FILTERS.MESSAGE' | translate}}<fa-icon [icon]="faCircleExclamation" faCircleExclamation aria-hidden="true"></fa-icon></span>
							</p>

							<form [formGroup]="filterForm" class="filter-form__container d-flex justify-content-between" [ngClass]="showFilters ? 'show' : '' ">
								<!-- VERSION -->
								<div class="col-xxl-3 col-sm-3 d-flex align-items-center pe-2">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label style="color: #4f0aeb">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.FILTERS.BUILD' | translate }}</mat-label>

										<mat-select name="version" (selectionChange)="filterTableValue()" formControlName="version">
											<mat-option *ngFor="let ver of availableVersions" [value]="ver.value"> {{ver.label}} </mat-option>
										</mat-select>
									</mat-form-field>
									<fa-icon class="ms-2" [icon]="faXmark" faXmark style="color: #ff3b2d" [ngClass]="filterForm.get('version').value != null ? 'show': '' " (click)="filterForm.get('version').reset(); filterTableValue()"></fa-icon>
								</div>
								<!-- COUNTRY -->
								<div class="col-xxl-3 col-sm-3 d-flex align-items-center pe-2">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label style="color: #4f0aeb">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.FILTERS.COUNTRY' | translate }}</mat-label>

										<mat-select name="country" (selectionChange)="filterTableValue()" formControlName="country">
											<mat-option *ngFor="let c of availableCountry" [value]="c"> {{c}} </mat-option>
										</mat-select>
									</mat-form-field>
									<fa-icon class="ms-2" [icon]="faXmark" faXmark style="color: #ff3b2d" [ngClass]="filterForm.get('country').value != null ? 'show': '' " (click)="filterForm.get('country').reset(); filterTableValue()"></fa-icon>
								</div>

								<!-- OS -->
								<div class="col-xxl-3 col-sm-3 d-flex align-items-center pe-2">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label style="color: #4f0aeb">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.FILTERS.OS_VERSION' | translate }}</mat-label>

										<mat-select name="os" (selectionChange)="filterTableValue()" formControlName="os">
											<mat-option *ngFor="let os of availableOS" [value]="os"> {{os}} </mat-option>
										</mat-select>
									</mat-form-field>
									<fa-icon class="ms-2" [icon]="faXmark" faXmark style="color: #ff3b2d" [ngClass]="filterForm.get('os').value != null ? 'show': '' " (click)="filterForm.get('os').reset(); filterTableValue()"></fa-icon>
								</div>

								<!-- ORGANIZATION -->
								<div class="col-xxl-3 col-sm-3 d-flex align-items-center pe-2">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label style="color: #4f0aeb">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.FILTERS.ORGANIZATION' | translate }}</mat-label>

										<mat-select name="organization" (selectionChange)="filterTableValue()" formControlName="organization">
											<mat-option *ngFor="let org of availableOrganization" [value]="org"> {{org}} </mat-option>
										</mat-select>
									</mat-form-field>
									<fa-icon class="ms-2" [icon]="faXmark" faXmark style="color: #ff3b2d" [ngClass]="filterForm.get('organization').value != null ? 'show': '' " (click)="filterForm.get('organization').reset(); filterTableValue()"></fa-icon>
								</div>

								<!-- BRAND -->
								<!-- <div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
                                    <mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
                                        <mat-label style="color: #4f0aeb"></mat-label>
            
                                        <mat-select name="brand" (selectionChange)="filterTableValue()" formControlName="brand">
                                            <mat-option *ngFor="let brand of availableBrand" [value]="brand"> {{brand}} </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('brand').value != null ? 'show': '' " (click)="filterForm.get('brand').reset(); filterTableValue()"></fa-icon>
                                </div> -->
							</form>
						</div>
						<div class="col-12 mt-2 d-flex flex-row align-items-center justify-content-between">
							<div class="checkbox-push-all">
								<input class="form-check-input" type="checkbox" id="pushToAll" (click)="pushToAll()" [(ngModel)]="pushToAllDevicesCheckbox" />
								<span class="ms-2 fw-bold">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.PUSH_ALL' | translate }}</span>
							</div>
							<mat-form-field appearance="outline">
								<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
							</mat-form-field>
						</div>
					</section>
				</div>

				<!-- device list section-->
				<div class="container-fluid">
					<section class="row mt-2">
						<div class="col-12 position-relative">
							@if(pushToAllDevicesCheckbox){
							<div class="push-to-all-screen">
								<p class="fw-bold w-50 text-center">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.PUSH_ALL_MESSAGE' | translate }}</p>
							</div>
							}

							<loader [activateSpinner]="activateSpinner"></loader>
							<div class="table-container p-2 d-flex flex-column justify-content-between">
								<table mat-table class="p-2" [dataSource]="devicesList" matSort #sort1="matSort" style="width: 100%" [ngClass]="[!activateSpinner ? 'show' : '' , pushToAllDevicesCheckbox? 'op-3' : '']">
									<!-- select -->
									<ng-container matColumnDef="select">
										<th mat-header-cell *matHeaderCellDef>
											<input class="form-check-input" type="checkbox" id="checkAll" (click)="checkAll()" [disabled]="devicesListRaw.length == targetDevicesListRaw.length" [(ngModel)]="all" ngbTooltip="{{'SW_UPDT.PUBLISH_UPDATE_MODAL.TOOLTIPS.SEL_ALL' | translate }}" style="cursor: pointer" />
										</th>
										<td mat-cell *matCellDef="let element">
											<div class="checkbox" placement="left">
												<input class="form-check-input" type="checkbox" id="flexCheckDefault-{{element.id}}" [(ngModel)]="element.selected" (click)="$event.stopPropagation(); element.selected = !element.selected; AddRemoveItem(element)" />
											</div>
										</td>
									</ng-container>

									<!-- Device SN -->
									<ng-container matColumnDef="sn">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.DEVICE_SN' | translate }}</th>
										<td mat-cell *matCellDef="let element">{{ element.sn }}</td>
									</ng-container>

									<!-- main_build -->
									<ng-container matColumnDef="main_build">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.MAIN_BUILD' | translate }}</th>
										<td mat-cell *matCellDef="let element"><span>{{ element.main_build }}</span> <span *ngIf="element.main_build_num ">{{ '( '+ element.main_build_num + ' )'}}</span></td>
									</ng-container>

									<!-- country -->
									<ng-container matColumnDef="country">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.COUNTRY' | translate }}</th>
										<td mat-cell *matCellDef="let element">{{ element.country }}</td>
									</ng-container>

									<!-- OS -->
									<ng-container matColumnDef="os_ver">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.OS' | translate }}</th>
										<td mat-cell *matCellDef="let element">{{ element.os_ver }}</td>
									</ng-container>

									<!-- organization -->
									<ng-container matColumnDef="organization">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.ORGANIZATION' | translate }}</th>
										<td mat-cell *matCellDef="let element">@if(element.decritted){ {{ element.organization }} } @else {<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion></fa-icon>}</td>
									</ng-container>

									<!-- Filter -->
									<!-- <ng-container matColumnDef="filter">
										<th mat-header-cell *matHeaderCellDef class="text-end">
											<mat-form-field appearance="outline">
												<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
											</mat-form-field>
										</th>
										<td mat-cell *matCellDef="let element"></td>
									</ng-container> -->

									<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
									<tr mat-row (click)="row.selected=true;AddRemoveItem(row)" *matRowDef="let row; columns: displayedColumns"></tr>
								</table>

								<mat-paginator #paginator1 [pageSizeOptions]="[10, 20]" showFirstLastButtons> </mat-paginator>
							</div>
						</div>
					</section>
				</div>
			</div>

			<!-- push update recap -->
			<div class="col-sm-12 col-xxl-6 px-1">
				<!-- selected device list section-->

				<div class="container-fluid d-flex flex-column justify-content-between" style="height: 100%">
					<section class="row">
						<div class="col-12">
							<div class="title-section mb-2 mt-xxl-0 mt-sm-4">
								<h4 class="fw-bold text-center">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.TITLE2' | translate }}</h4>
								<div class="row">
									<div class="col-6">
										<p class="section-title fw-bold border-bottom"><label>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.TITLE1' | translate }}</label></p>
										<p class="update-description">
											<span>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.DESCRIPTION' | translate }}</span><span class="fw-bold me-3">{{update.description}}</span><br />
											<span>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.FILE' | translate }}</span><span class="fw-bold">{{update.url}}</span><br />
											<span>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.BUILD' | translate }}</span><span class="fw-bold me-3">{{update.build_num}}</span> <span>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.UPDATE_DATE' | translate }} </span
											><span class="fw-bold">{{session.formatDate(update.release_date)}}</span>
										</p>
									</div>
									<div class="col-6">
										<p class="section-title fw-bold border-bottom"><label>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.TITLE2' | translate }}</label></p>
										<p class="update-description">
											<span>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.MIN_BUILD' | translate }}</span><span class="fw-bold me-3">{{update.min_build_num ? update.min_build_num : 'none'}}</span><br />
											<span>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.UPDATE_DETAILS.OS' | translate }}</span><span class="fw-bold">{{update.os_ver ? update.os_ver : 'none'}}</span><br />
										</p>
									</div>
								</div>
							</div>

							<div class="target-table-container p-2 d-flex flex-column justify-content-between position-relative">
								@if(pushToAllDevicesCheckbox){
								<div class="push-to-all-screen">
									<p class="fw-bold w-50 text-center">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.PUSH_ALL_MESSAGE' | translate }}</p>
								</div>
								}

								<table mat-table [dataSource]="targetDevicesList" matSort #sort2="matSort" style="width: 100%" [ngClass]="pushToAllDevicesCheckbox? 'op-3' : ''">
									<!-- remove -->
									<ng-container matColumnDef="select">
										<th mat-header-cell *matHeaderCellDef>
											<fa-icon [icon]="faCircleMinus" faCircleMinus (click)="uncheckAll()" ngbTooltip="{{'SW_UPDT.PUBLISH_UPDATE_MODAL.TOOLTIPS.REMOVE_ALL' | translate }}"></fa-icon>
										</th>
										<td mat-cell *matCellDef="let element">
											<fa-icon [icon]="faCircleMinus" faCircleMinus (click)="element.selected=false;AddRemoveItem(element)" ngbTooltip="{{'SW_UPDT.PUBLISH_UPDATE_MODAL.TOOLTIPS.REMOVE' | translate }}"></fa-icon>
										</td>
									</ng-container>

									<!-- Device SN -->
									<ng-container matColumnDef="sn">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.DEVICE_SN' | translate }}</th>
										<td mat-cell *matCellDef="let element">{{ element.sn }}</td>
									</ng-container>

									<!-- main_build -->
									<ng-container matColumnDef="main_build">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.MAIN_BUILD' | translate }}</th>
										<td mat-cell *matCellDef="let element"><span>{{ element.main_build }}</span> <span *ngIf="element.main_build_num ">{{ '( '+ element.main_build_num + ' )'}}</span></td>
									</ng-container>

									<!-- country -->
									<ng-container matColumnDef="country">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.COUNTRY' | translate }}</th>
										<td mat-cell *matCellDef="let element">{{ element.country }}</td>
									</ng-container>

									<!-- OS -->
									<ng-container matColumnDef="os_ver">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.OS' | translate }}</th>
										<td mat-cell *matCellDef="let element">{{ element.os_ver }}</td>
									</ng-container>

									<!-- organization -->
									<ng-container matColumnDef="organization">
										<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.ORGANIZATION' | translate }}</th>
										<td mat-cell *matCellDef="let element">@if(element.decritted){ {{ element.organization }} } @else {<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion></fa-icon>}</td>
									</ng-container>

									<tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
									<tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
								</table>

								<div class="banner-no-target text-center mt-4" *ngIf="targetDevicesListRaw.length == 0 && !pushToAllDevicesCheckbox">
									<p>{{'SW_UPDT.PUBLISH_UPDATE_MODAL.NO_DEVICE_SEL' | translate }}</p>
								</div>

								<mat-paginator #paginator2 [pageSizeOptions]="[10, 20]" showFirstLastButtons> </mat-paginator>
							</div>
						</div>
					</section>
					<div class="row">
						<div class="col-12 text-center">
							<button class="btn btn-primary shadow" (click)="push()" [disabled]="targetDevicesListRaw.length == 0 && !pushToAllDevicesCheckbox">{{'SW_UPDT.PUBLISH_UPDATE_MODAL.PUBLISH_UPDATE' | translate }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- result of push -->
		<div class="row" *ngIf="pushing.state">
			<div class="col-12 position-relative pt-4" style="min-height: 30vh">
				<loader [activateSpinner]="activateSpinner"></loader>

				<div class="update-result-messages text-center" style="min-height: 25vh">
					<h4 class="fw-bold mb-4" [ngClass]="!activateSpinner ? 'show': '' ">{{pushing.message}}</h4>
					<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true" [ngClass]="!activateSpinner&& pushing.result ? 'show': '' "></fa-icon>
					<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true" [ngClass]="!activateSpinner && !pushing.result ? 'show': '' "></fa-icon>
				</div>
			</div>
		</div>
	</div>
</div>

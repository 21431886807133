import { Component, OnInit, Input, Output } from '@angular/core'

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'

import { Doctor } from 'src/app/models/doctor.model'
import { Util } from '../../models/util.model'
import { SaleInfo, BalanceRec } from '../../models/salePlan.model' // 28.09.2022

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'doctorbalance',
	templateUrl: './balance.modal.html',
	styleUrls: ['./balance.modal.scss'],
	providers,
})
export class BalanceModal implements OnInit {
	@Input() currentDoctor: Doctor
	//@Input() records: BalanceRec[];
	records: BalanceRec[]
	saleInfo: SaleInfo
	activeTab: string

	//dispPassword: string; // dispositive admin pwd field, for edit, delete, etc
	//wrongPwd: boolean;

	flagExpiry: string
	flagSpace: string
	flagCredits: string
	flagRef: string

	userPlanReady: boolean

	constructor(public activeModal: NgbActiveModal, public session: SessionService) {
		console.log('balanceModal - constructor')

		this.records = []
		this.userPlanReady = false
		// this.saleInfo = new SaleInfo(null);
		this.flagExpiry = Config.GRAY
		this.flagSpace = Config.GRAY
		this.flagCredits = Config.GRAY
		this.flagRef = Config.GRAY
	}

	ngOnInit(): void {
		console.log('balanceModal - ngOnInit')
		this.records = this.currentDoctor.balanceRecords

		// this.initWarningFlags()

		this.session.loadUserPlan(this.currentDoctor.user_id).then((ris) => {
			// console.log(ris);
			this.saleInfo = ris
			this.userPlanReady = true
		})
	}

	// da migliorare
	// private initWarningFlags() {
	// 	// expiry ************************
	// 	let today = new Date()
	// 	let preAlert = 15 // in giorni
	// 	let tPreAlert = new Date(today.setDate(today.getDate() + preAlert))

	// 	let expDt = this.currentDoctor.getExpiryDate()
	// 	if (expDt) {
	// 		if (expDt < today) {
	// 			this.flagExpiry = Config.RED + ' - expired'
	// 		} else if (expDt < tPreAlert) {
	// 			this.flagExpiry = Config.YELLOW + ' - less than 15 days before expire'
	// 		} else {
	// 			this.flagExpiry = Config.GREEN
	// 		}
	// 	}

	// 	// credits ************************
	// 	let limit = 10
	// 	let totC = this.currentDoctor.getAvailableCredits()
	// 	if (totC) {
	// 		if (totC < 0) {
	// 			this.flagCredits = Config.RED + ' - no credits'
	// 		} else if (totC > 0 && totC < limit) {
	// 			this.flagCredits = Config.YELLOW + ' - few credits'
	// 		} else {
	// 			this.flagCredits = Config.GREEN + ' - ok enough credits'
	// 		}
	// 	}

	// 	// space ************************
	// 	let maxSize = 2 * 1024 * 1024 * 1024 // 2 GB // 2147483648
	// 	let usedBytes = this.currentDoctor.used_bytes
	// 	let warnSize = (maxSize * 80) / 100 //  80 %  circa 1717986918

	// 	// TODO a meno che non ci sia la subscription
	// 	if (usedBytes) {
	// 		if (usedBytes > maxSize) {
	// 			this.flagSpace = Config.RED + ' - used more than max_size'
	// 			Util.debug('(balance) RED - used more than max_size')
	// 		} else if (usedBytes > 0 && usedBytes > warnSize) {
	// 			this.flagSpace = Config.YELLOW + ' - almost reached the max size'
	// 			Util.debug('(balance) YELLOW - used more than warn_size')
	// 		} else {
	// 			this.flagSpace = Config.GREEN + ' - ok, less than 2GB'
	// 		}
	// 	}

	// 	// *** refertatore ********

	// 	let totRef = this.currentDoctor.getSpecialistNum()
	// 	if (totRef > 0) {
	// 		// relazione senza fee per il servizio
	// 		if (!expDt && totRef > 0) {
	// 			this.flagRef = Config.RED
	// 			this.flagRef += ' - relation without fee!' // 19.10.2022 temp info
	// 			Util.debug('(balance) RED - relation without fee')
	// 		}

	// 		// 07.10.2022 refertatori non privati ma senza crediti
	// 		/* TODO, non ho il salePlan
	//   let levelPlan = this.currentDoctor.getPlanLevel();
	//   Util.debug("(balance) levelPlan: "+levelPlan);  // qui sempre basic!
	//   if(levelPlan == Config.SALE_MIDI){
	//     for(let i=0; i<totRef; i++){
	//       if(this.currentDoctor.specialists[i].user_subtype != Config.SUB_PRIVATE){
	//         this.flagRef = Config.RED;
	//         Util.debug("(balance) RED - middle plan and relation with non-private REF");
	//         break;
	//       }
	//     }
	//   }
	//   */
	// 	}
	// }
}

<div class="panel panel-modal panel-default ng-cloak position-relative">
	<!-- modal-panel -->
	<div class="panel-heading">
		<label class="panel-title">Device details</label>
	</div>
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
	</div>

	<div class="panel-body" [hidden]="!session.hasLoadedDevice()">
		<div class="container">
			<div class="row mb-2">
				<div class="col-sm-8">
					<b>Info</b>
					<table class="table">
						<!-- table-striped data-table -->
						<thead>
							<tr>
								<th>Model</th>
								<th>SN</th>
								<th>Assembled date</th>
								<th>Brand</th>
								<th>GMT Offset</th>
								<th>Available Space</th>
								<th>Last Updated</th>
							</tr>
						</thead>
						<tbody>
							<td>{{currentDevice.model}}</td>
							<td>{{currentDevice.sn}}</td>
							<td>{{session.formatDate(currentDevice.assembled_date)}}</td>
							<td>{{currentDevice.branding}}</td>
							<td>{{currentDevice.gmt_offset}}</td>
							<td>{{parent.formatSize(currentDevice.available_space)}}</td>
							<td>{{session.formatDateTime(currentDevice.last_update)}}</td>
						</tbody>
					</table>
				</div>
			</div>

			<div class="row mb-2">
				<!-- <div class="quarter-container">
        <b>Info:</b>
        <br>Model: {{currentDevice.model}}
        <br>SN: {{currentDevice.sn}}
        <br>Assembled date: {{session.formatDate(currentDevice.assembled_date)}}
        <br>Brand: {{currentDevice.branding}}
        <br>GMT Offset: {{currentDevice.gmt_offset}}
        <br>Available Space: {{parent.formatSize(currentDevice.available_space)}}
        <br>Last Updated: {{session.formatDateTime(currentDevice.last_update)}}
        <p>&nbsp;</p>
      </div> -->

				<div class="col-sm-12">
					<b>Components:</b>
					<table class="table">
						<thead>
							<tr>
								<th scope="col">Type</th>
								<th scope="col">Name</th>
								<th scope="col">Model</th>
								<th scope="col">SN</th>
								<th scope="col">Build</th>
								<th scope="col">BuildNum</th>
								<th scope="col">Os Vers.</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let comp of currentDevice.components">
								<td>{{comp.dev_type}}</td>
								<td>{{comp.name}}</td>
								<td>{{comp.model}}</td>
								<td>{{comp.sn}}</td>
								<td>{{comp.build}}</td>
								<td>{{comp.build_num}}</td>
								<td>{{comp.os_ver}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-6">
					<b>Packages:</b><br />
					<textarea readonly [value]="getCurrentPacksInfo()" rows="15" cols="55"></textarea>

					<p>&nbsp;</p>
				</div>

				<div class="col-sm-6">
					<b>Users</b>
					<table class="table">
						<!-- table-striped data-table -->
						<thead>
							<tr>
								<th>user</th>
								<th>ip</th>
								<th>last time</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let ud of currentDevice.user_devices">
								<td>{{ud.user_id}}</td>
								<td>{{ud.caller_ip}}</td>
								<td>{{session.formatDate(ud.last_use)}}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div class="row" > <!-- enabled for all *ngIf="currentDevice.model == 'VX610' && !session.isProduction()"-->
				<div class="col-sm-6">
					<b>Updates</b> <br />
					Get <a (click)="checkUpdates()" class="underline">available updates</a> for this device: <br />&nbsp;<br />
					<div *ngFor="let updt of deviceUpdtList">{{updt.toStringShort()}} <br /></div>
					<p class="error-message ng-hide" [hidden]="!zeroUpdates">No updates available for this device</p>
				</div>
			</div>

			<div class="row">
				<div class="d-flex flex-row-reverse">
					<input type="clear" class="btn btn-secondary" (click)="dismiss()" value="{{'BUTTONS.CLOSE' | translate}}" />
				</div>
			</div>
		</div>
	</div>
</div>

//var dtFormat = require('dateformat');
//import * as dtFormat from 'dateformat';

export class DateParser {
	// parse a date in yyyy-mm-dd format

	static parseDate(input: string) {
		if (!input) {
			return null
		}

		// 10.06.2020 appende la Z al posto di UTC, funziona anche con firefox
		input = String(input).replace(' UTC', 'Z')

		return new Date(input)
	}

	//static stringify(input: Date) {    // 24.02.2017
	static stringify(input: string) {
		if (!input) {
			return ''
		}

		// 24.02.2017
		//return input.getFullYear() + "-" + (input.getMonth() + 1) + "-" + input.getDate();
		//return input.getFullYear() + "-" + (input.getUTCMonth() + 1) + "-" + input.getUTCDate();

		var d = new Date(input)
		//var d = input;

		var month = String(d.getMonth() + 1)
		var day = String(d.getDate())
		var year = String(d.getFullYear())

		if (month.length < 2) month = '0' + month
		if (day.length < 2) day = '0' + day

		return year + '-' + month + '-' + day
	}

	// 27.04.2020 solo data in formato SQL
	static formatSqlDate(myDate: Date) {
		if (!myDate) {
			return ''
		}
		var myStrDate = myDate.toISOString().substring(0, 10) // solo yyyy-MM-dd
		return myStrDate
	}

	static formatSqlDateLong(myDate: Date) {
		if (!myDate) {
			return ''
		}
		let myStrDate = myDate.toISOString().substring(0, 16) // yyyy-MM-dd hh:mm   27.06.2023 estesa
    // 2023-06-27T07:45
    // NB: il server toglie la T 
		return myStrDate
	}

	// 10.08.2022
	/*
    static formatDateAs(myDate: Date, myFormat: string){      
      //var dtFormat = require('dateformat');      
      let ret = dtFormat(myDate, myFormat);
      return ret;
    }
    */

	// 07.07.2021 patch per date dal picker su statistiche - reportCount
	static formatSqlDateFromUTC(myDate: Date) {
		if (!myDate) {
			return ''
		}

		var dt = myDate // 24.06.2021 fix
		// 'undo' the timezone offset again (so we end up on the original date again)
		dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset())
		var myStrDate = dt.toISOString().substring(0, 10) // solo yyyy-MM-dd
		return myStrDate
	}

	// 23.01.2017
	static getYearOnly(input: Date): number {
		if (!input) {
			return 0
		}
		return input.getFullYear() // 4 cifre
	}

	// 16.06.2017
	/*
    static getLocaleDate(myStringDate){
        var currData = new Date(myStringDate);
        var niceDt = currData.to .toLocaleDateString() + " " + currData.toLocaleTimeString(); 
        console.log("(getLocaleDate) nice d: "+niceDt);
        return niceDt;
    }*/
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
//import { TranslateService} from '@ngx-translate/core';

//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import {  } from '@fortawesome/free-regular-svg-icons';
import { faSave, faCalendarAlt, faTrashAlt, faCircleCheck } from '@fortawesome/free-regular-svg-icons' // faCheckSquare, faTimesCircle,
import {
	faInfoCircle,
	faXmark,
	faArrowRightArrowLeft,
	faCircleQuestion,
	faCircleMinus,
	faCirclePlus,
	faArrowsLeftRight,
	faFilter,
	faFileCsv,
	faSpinner,
} from '@fortawesome/free-solid-svg-icons' // faUpload

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { CsvLine } from '../../models/csvLine.model'
import { DateParser } from '../../models/dateParser.model'
import { Report } from '../../models/report.model'
import { Distrib } from '../../models/specialist.model'
import { Doctor } from '../../models/doctor.model'
import { CoreUser, User } from '../../models/user.model'
import { Patient } from '../../models/patient.model'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import {
	StatInMemory,
	Statfilter,
	Statistics,
	basicPieGraph,
	pieChartDatas,
	ColumnDatas,
	ColumnGraph,
	ColumnSeries,
	statOptions,
	createArrays,
	performance,
	report,
	graders,
	balances,
	purchaseHOpt,
	purchaseList,
	funnelChart,
	serieValues,
	customersAddedStat,
	gradingsList,
	gradingsListData,
	conversionStat,
} from 'src/app/models/statistics.model'
import { Country } from 'src/app/models/countries.models'

import * as _moment from 'moment'
import { ToastOptions } from 'src/app/models/toast.model'
import { AppToastService } from 'src/app/service/toast.service'
import { DataModelService } from 'src/app/service/data-model.service'
import { TranslateService } from '@ngx-translate/core'
import { SaleInfo, SalePlan } from 'src/app/models/salePlan.model'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { DateAdapter } from '@angular/material/core'
import { toCSV } from 'src/app/elements/activities/activities.component'
import { ApexYAxis } from 'ng-apexcharts'

const moment = _moment

//import JSZip = require("../modules/jszip");
//import FileSaver = require("../modules/FileSaver");

// see https://stackoverflow.com/questions/43097159/import-jszip-in-angular-2-project
//import * as JSZip from 'jszip';

export interface aireportStat {
	// comes from server response
	channel: string
	created_by: number
	country: string
	creation_date: string
	device: string
	dt_month: string
	dt_week: string
	flow_time: string
	gr_request: string
	grading_time: string
	lang: string
	patient_id: number
	received: string
	report_id: number
	status: number
	traffic_light_left: string
	traffic_light_right: string
	username: string
}

export interface aiQualityReport {
	country: string
	created_by: number
	creation_date: string
	image_id: number
	last_update: string
	quality: number
	username: string
}

export class operatorAi {
	id: number
	username: string
	country: string
	type: string
	tot: number
	green: number
	yellow: number
	red: number
	reports: aiReportDone[]
	isOnChart: boolean

	constructor(obj?: aireportStat[]) {
		this.id = 0
		this.username = ''
		this.country = ''
		this.type = ''
		this.tot = 0
		this.green = 0
		this.yellow = 0
		this.red = 0
		this.reports = []
		this.isOnChart = false

		if (obj) {
			for (let i = 0; i < obj.length; i++) {
				const el = obj[i]
				let report = new aiReportDone(el)
				this.reports.push(report)
			}

			this.id = obj[0].created_by
			this.username = obj[0].username
			this.country = obj[0].country
			this.type = obj[0].channel
			this.tot = this.reports.length
			this.green = this.reports.filter((el) => el.traffic_light == 'green').length
			this.yellow = this.reports.filter((el) => el.traffic_light == 'yellow').length
			this.red = this.reports.filter((el) => el.traffic_light == 'red').length
		}
	}
}

export class aiReportDone {
	report_id: number
	device: string
	traffic_light: string
	creation_date: string
	received: string
	patient_id: number
	dt_month: string
	dt_week: string

	constructor(obj?: aireportStat) {
		this.traffic_light = ''
		this.device = ''
		this.report_id = 0
		this.creation_date = ''
		this.received = ''
		this.patient_id = 0
		this.dt_month = ''
		this.dt_week = ''

		if (obj) {
			this.traffic_light = this.checkColors(obj.traffic_light_left, obj.traffic_light_right)
			this.device = obj.device
			this.report_id = obj.report_id
			this.creation_date = obj.creation_date
			this.received = obj.received
			this.patient_id = obj.patient_id
			this.dt_month = obj.dt_month
			this.dt_week = obj.dt_week
		}
	}

	checkColors(color1: string, color2: string): string {
		const colorArray = ['green', 'yellow', 'red']
		const index1 = colorArray.indexOf(color1)
		const index2 = colorArray.indexOf(color2)

		if (index1 > index2) {
			return color1
		} else {
			return color2
		}
	}
}

export class aiRequest {
	total: number
	green: number
	yellow: number
	red: number

	constructor(obj?: operatorAi[]) {
		this.total = 0
		this.green = 0
		this.yellow = 0
		this.red = 0

		if (obj && obj.length > 0) {
			for (let i = 0; i < obj.length; i++) {
				const el = obj[i]

				this.total = this.total + el.tot
				this.green = this.green + el.green
				this.yellow = this.yellow + el.yellow
				this.red = this.red + el.red
			}
		}
	}
}

@Component({
	selector: 'statistics',
	templateUrl: './statistics.component.html',
	styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
	graderList: MatTableDataSource<operatorAi>
	// @ViewChild(MatTable) table: MatTable<any>
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild('filter') input: ElementRef

	graderListPerformance: MatTableDataSource<graders>
	// @ViewChild(MatTable) table2: MatTable<any>
	@ViewChild('paginator2') paginator2: MatPaginator
	@ViewChild('filter2') input2: ElementRef

	displayedColumns: string[]
	displayedColumns2: string[]

	balanceOptList: MatTableDataSource<purchaseHOpt>
	@ViewChild('balOptPaginator') balOptPaginator: MatPaginator
	@ViewChild('balOptFilter') balOptFilter: ElementRef

	matPurchaseList: MatTableDataSource<purchaseList>
	@ViewChild('purchasePaginator') purchasePaginator: MatPaginator
	@ViewChild(MatSort) purchasesort: MatSort

	gradingsList: MatTableDataSource<gradingsList>
	@ViewChild('gradingsPaginator') gradingsPaginator: MatPaginator
	@ViewChild('gradingsFilter') gradingsInput: ElementRef
	gradingsDisplayedColumns: string[]

	matHistoryOptColumns: string[]

	currentAction: string
	activeTab: string

	// campi per conteggio esami
	//provider: string;
	usrOption: string // ATT: usato sia per trends che per users - FIXME
	errMsg: string
	static ALL_LEV1 = 'lev1' // vd html

	// campi per stats on images
	stat_type: string
	examTypeFilter: string
	examTypes: string[]
	examType: string

	eye: string
	device: string
	operId: string // 09.09.2021 per non usare stesso dei log

	// 21.08.2018 per richiesta log, valutare se spostare da qui
	userId: string
	username: string

	isSupport: boolean

	// per ricerca sulla tabella logs
	dbLogType: string // 16.11.2022
	dbLogRange: string

	// 07.07.2021 per parametro di export on users
	activityDays: number
	activeType: string // {count_usr_visits, inactive_users, slow_specs}

	exportType: string // 14.06.2021 su tab users, distinguo tra export users ed export log
	myTokInfo: string // 22.06.2021
	loggedUsers: any[] // 20.09.2021

	// 22.06.2021 per date picker
	fromDay: NgbDateStruct
	toDay: NgbDateStruct

	fromDayDt: Date
	toDayDt: Date

	cadenceIsPristine: boolean

	// AI REPORTS
	aiRequest: boolean
	aiRequestChart: boolean
	performance: boolean
	aiReqWithCountry: boolean
	aiReqWithCadence: boolean
	seeDss: boolean //var used to see only dss or mcs on globalAIcolumnChart
	totalReq: number
	totalDone: number
	totalFail: number
	dss: aiRequest
	mcs: aiRequest
	totalAiRequests: number
	avgAiResponseTime: number
	totalReportDone: aireportStat[]
	aiQualityRecords: aiQualityReport[]
	dssList: operatorAi[]
	mcsList: operatorAi[]
	aiTotpieGraphs: basicPieGraph
	aiTotpieGraphsGlobal: basicPieGraph
	aiMCSpieGraphs: basicPieGraph
	aiDSSpieGraphs: basicPieGraph
	avgResponseTimePieGraphs: basicPieGraph
	aiQualityPieGraphs: basicPieGraph
	globalAIcolumnChart: ColumnGraph
	aiGradersColumnChart: ColumnGraph
	graderPerformance: graders[]
	performanceList: performance[]
	performanceList_copy: performance[]
	performanceList_deleted: performance[]
	performancePieChart: basicPieGraph
	performanceColumnChart: ColumnGraph
	performanceWithCadence: boolean
	showdeleted: boolean
	keepOldChart: boolean
	purchaseList: balances[]
	optPurchaseList: purchaseHOpt[]
	purchaseReady: boolean
	purchaseColumnChartReady: boolean
	purchasePieChart: basicPieGraph
	purchaseColumnChart: ColumnGraph
	purchaseThresholdFlag: boolean
	customersList: customersAddedStat[]
	conversionList: conversionStat[]
	loadCSV: boolean

	gradings: boolean
	gradingsTableFlag: boolean
	gradingsPieChart: basicPieGraph
	gradingsFollowUpPieChart: basicPieGraph
	gradingsListData: gradingsListData[]

	maxRepDate: NgbDateStruct
	minRepDate: NgbDateStruct

	// per la risposta
	progressCount = 0
	gotCount = 0
	msgTot: string
	//images: any;
	statsRec: any[] // data.Exam[];

	btnCountDisabled = false // 21.12.2020 ripristinato [ls] usato sia per trends che per users
	btnGetImgDisabled = true
	btnDownloadDisabled = true
	showLoader = false

	lastReport: Report
	statusMsg = ''

	// 08.09.2022 search a patient from code, by admins
	patientFilter: string
	//myPatient: Patient;
	myPatientInfo: string

	zipContent: Blob
	zipContentList = [] // lista di zip per il download
	zipList = ''
	okZip = false

	//MAX_NUM_IMG = 700;   // 26.10.2021 causa un noMemory sul server DEV
	MAX_NUM_IMG = 300 // 04.04.2022 reduced from 500 because images from VX650 are quite big

	MAX_NUM_IMG_CRI = 5000
	MAX_NUM_IN_ZIP = 50 // 04.04.2022 reduced because images from VX650 are quite big

	//aiEnabled: boolean // 26.08.2022 // 04.10.2023 not used
	loading: boolean

	fullReport: boolean

	statisticForm: FormGroup
	cadence: string[]
	prevCadence: string
	profiles: Statfilter[]
	countries: Country[]
	options: statOptions[]

	graphTitle: string

	fromDate: string //the date formatted like DB
	toDate: string
	minDate: Date
	maxDate: Date
	diffDays: number // differenza in giorni tra fromDate e toDate

	statInMemory: StatInMemory[] //array di report salvati
	statistic: StatInMemory // se quando chiedo la statistica. l'ho giá fatta, la copio dalla memoria e la metto qui

	funnelCharts: funnelChart[]
	pieGraphs: basicPieGraph[]
	ColumnGraphs: ColumnGraph[]
	ColumnGraphsBig: ColumnGraph[]

	distributorList: Distrib[]
	doctorList: Doctor[]
	// doctorListFiltered: Doctor[]

	aiServiceOptSelected: boolean

	faInfoCircle = faInfoCircle
	faSave = faSave
	//visitGraded = faUpload;
	faCalendar = faCalendarAlt
	faTrashAlt = faTrashAlt
	faXmark = faXmark
	faArrowRightArrowLeft = faArrowRightArrowLeft
	faCircleCheck = faCircleCheck
	faCircleQuestion = faCircleQuestion
	faCircleMinus = faCircleMinus
	faCirclePlus = faCirclePlus
	faArrowsLeftRight = faArrowsLeftRight
	faFilter = faFilter
	faFileCsv = faFileCsv
	faSpinner = faSpinner

	constructor(
		public session: SessionService, //private translator: TranslateService
		public dataService: DataModelService,
		private toastService: AppToastService,
		private translator: TranslateService,
		private dateAdapter: DateAdapter<Date>
	) {
		Util.debug('(Statistics) - constructor')
		//this.submitted = false;
		//this.oggi = new Date();
		this.dateAdapter.setLocale(this.session.getDateFormat() === 'dd/MM/yyyy' ? 'en-GB' : 'en-US')

		this.loggedUsers = []

		this.minRepDate = { year: 2019, month: 1, day: 1 } // first Nexus born
		this.maxRepDate = Util.dateToNgb(this.getYesterday()) // 05.07.2022

		this.msgTot = '' // espone il totale del count fatto sulle immagini
		//this.rootScope = $rootScope;

		this.userId = ''
		this.username = ''
		this.graphTitle = ''

		this.isSupport = this.session.isSupport()

		// this.aiEnabled = this.session.userCanSeeAiReportStatus() // 26.08.2022 // 04.10.2023 not used

		this.btnCountDisabled = false // 21.12.2020

		// per tab users
		//this.exportType = "exportUsers";
		this.exportType = 'exportLog' // 08.07.2021 piu' innoquo, esce alert se non valorizzati i campi
		this.myTokInfo = 'signed-in users '

		this.initExamTypes()

		this.lastReport = new Report()

		this.patientFilter = '' // 12.09.2022
		this.myPatientInfo = ''

		this.statisticForm = new FormGroup({
			cadence: new FormControl(null),
			fromDate: new FormControl(null, Validators.required),
			toDate: new FormControl(null, Validators.required),
			profile: new FormControl(null, Validators.required),
			country: new FormControl(null),
			option: new FormControl(null, Validators.required),
			full: new FormControl(this.fullReport),
			size: new FormControl(false),
			opticians: new FormControl(null), //[Validators.nullValidator, this.opticianValidator.bind(this)]
		})

		this.session
			.getUsedCountry()
			.then((countries: Country[]) => {
				// console.log(countries)

				this.countries = countries
				Util.debug('Used countries received, n: ' + countries.length)

				if (this.isSupport) {
					let countryObj = this.countries.find((c) => c.alpha3 === this.session.user.getCountry())
					// console.log(countryObj)
					this.statisticForm.get('country').setValue(countryObj)
					this.statisticForm.get('country').markAsDirty()
					this.statisticForm.get('country').markAsTouched()
				}
			})
			.catch((err) => {
				console.log(err)
			})

		this.statisticForm.get('option').valueChanges.subscribe((val) => {
			if (val.type === 'plans') {
				this.statisticForm.get('cadence').reset()
				this.statisticForm.controls.fromDate.clearValidators()
			} else {
				this.statisticForm.controls.fromDate.setValidators([Validators.required])
			}

			if (val.type === 'grading_requests' || val.type === 'pats_funnel_ai_hg_cadence') {
				this.minDate = new Date(2023, 8, 19)

				if (moment(this.statisticForm.get('fromDate').value).isBefore(moment(new Date(2023, 8, 19)))) {
					this.statisticForm.get('fromDate').setValue(moment(this.minDate))
				}
			} else {
				this.minDate = new Date(2018, 0, 1)
			}

			this.statisticForm.controls.fromDate.updateValueAndValidity()
		})

		this.statisticForm.get('cadence').disable()
		this.statisticForm.get('country').disable()
		this.statisticForm.get('full').disable()
		this.statisticForm.get('opticians').disable()

		this.cadenceIsPristine = true

		this.profiles = [Statistics.operator, Statistics.grader]

		this.aiRequest = false
		this.aiRequestChart = false
		this.performance = false
		this.aiReqWithCountry = false
		this.aiReqWithCadence = false
		this.totalReq = 0
		this.totalDone = 0
		this.totalFail = 0
		this.dss = new aiRequest()
		this.mcs = new aiRequest()
		this.totalAiRequests = 0
		this.avgAiResponseTime = 0
		this.aiServiceOptSelected = false
		this.aiQualityRecords = []
		this.totalReportDone = []
		this.dssList = []
		this.mcsList = []
		this.displayedColumns = []
		this.displayedColumns2 = []
		this.seeDss = false
		this.graderPerformance = []
		this.performanceList = []
		this.performanceList_copy = []
		this.performanceList_deleted = []
		this.performanceWithCadence = false
		this.showdeleted = false

		this.gradings = false
		this.gradingsTableFlag = false
		this.gradingsDisplayedColumns = []

		this.purchaseList = []
		this.matHistoryOptColumns = []
		this.optPurchaseList = []
		this.purchaseReady = false
		this.purchaseColumnChartReady = false
		this.purchaseThresholdFlag = false
		this.loadCSV = false

		this.customersList = []
		this.conversionList = []

		this.keepOldChart = false

		// this.graderList = new MatTableDataSource<operatorAi>([])

		this.fullReport = false

		this.distributorList = []
		this.doctorList = []
		// this.doctorListFiltered = []

		this.options = []
		this.cadence = []

		this.fromDate = ''
		this.toDate = ''
		this.diffDays = 0
		this.maxDate = new Date()
		this.minDate = new Date(2018, 0, 1)

		this.loading = false

		this.statInMemory = []
		this.statistic = null

		this.funnelCharts = []
		this.pieGraphs = []
		this.ColumnGraphs = []
		this.ColumnGraphsBig = []
	}

	ngOnInit(): void {
		Util.debug('(Statistics) - ngOnInit')
		this.setActiveTab('trends') // parto dal primo
		//this.setActiveTab("images");
	}

	// validator usato per lalista di id optician
	// private opticianValidator(control: FormControl): { [s: string]: boolean } {
	// 	if (control.value) {
	// 		var valid = control.value.match(/^\d+(,\d+)*$/)
	// 		if (valid == null) {
	// 			return { ['Invalid opticians strings']: true }
	// 		}
	// 	}
	// 	return null
	// }

	public showInfo() {
		let header = this.translator.instant('TOAST.HEADER.INFO')
		let body = 'Inset opticians username with "," as separator. Example opt1@Nexus, opt2@Nexus,7684@Nexus'
		let options = new ToastOptions('info')

		this.toastService.show(header, body, false, options, 'center')
	}

	private initExamTypes() {
		// solo quelli che hanno immagini
		this.examTypes = []

		this.examTypes.push(Config.EXM_DRYEYE)
		this.examTypes.push(Config.EXM_EXT)
		this.examTypes.push(Config.EXM_FUNDUS)
		this.examTypes.push(Config.EXM_PACHY)
		this.examTypes.push(Config.EXM_PACHYMULT)
		this.examTypes.push(Config.EXM_RETRO)
		this.examTypes.push(Config.EXM_TOPO)
		this.examTypes.push(Config.EXM_WF)
	}

	// 05.07.2022 string, non piu' numerico
	setActiveTab(tabName) {
		this.activeTab = tabName
		// 19.01.2022 forzo bottone abilitato
		this.btnCountDisabled = false

		let ieri = this.getYesterday() // come default max, calcolo fino a ieri, giornata chiusa

		switch (tabName) {
			case 'trends': {
				// COUNT EXAMS
				this.currentAction = 'examsCount'
				this.maxRepDate = Util.dateToNgb(this.getYesterday())
				this.usrOption = CsvLine.HG // "hg_reports";  // CsvLine.OPT // "operators";
				this.fromDay = null // 19.01.2022
				this.toDay = Util.dateToNgb(ieri)

				break
			}

			case 'users': {
				// USERS
				this.currentAction = 'Users'

				//this.usrOption = 'Specialist'; // per export angrafico
				this.usrOption = Config.ALL_LEV1 // 07.10.2022 per export angrafico+credits
				this.activeType = 'count_usr_visits' // per export usage
				//this.exportType = 'exportLog'; // 08.07.2021 piu' innoquo, esce alert se non valorizzati i campi
				this.exportType = 'exportUsers' // 07.10.2022 per i credits, piu' usato
				let oggi = new Date()
				this.maxRepDate = Util.dateToNgb(oggi)
				this.toDay = Util.dateToNgb(oggi) // 07.10.2022 per verifica insert appena fatte...

				this.activityDays = 7 // week
				this.loggedUsers = [] // 20.09.2021

				if (this.session.isAdmin()) {
					// 20.01.2023
					this.countValidTok() // 13.07.2021 spostato qui
				}

				this.dbLogType = 'login'
				this.dbLogRange = 'today'

				break
			}

			case 'images': {
				// IMAGES
				this.currentAction = 'Images'
				this.examTypeFilter = ''
				this.eye = 'any'
				this.device = ''

				this.btnGetImgDisabled = true // prima va fatto il count

				this.fromDay = null // 19.01.2022
				//this.toDayDt = this.getYesterday();  // come default max, calcolo fino a ieri
				this.toDay = Util.dateToNgb(ieri)

				break
			}

			case 'patients': {
				//  13.09.2022
				this.currentAction = 'Patients'
				break
			}

			case 'devices': {
				// DEVICES, 13.09.2022
				this.currentAction = 'Devices'
				this.device = ''
				break
			}
		}

		//console.log("(statistics) active tab:"+n);
	}

	// 05.07.2022
	onNavChange(event) {
		let nextTab = event.nextId
		Util.debug('C (onNavChange) event, next stats tab: ' + nextTab)
		//console.log(event);
		// prende tabChange da html solo se e' il primo load ?
		this.setActiveTab(nextTab)
	}

	// ########## TRENDS #######

	private loadDotors(): Promise<boolean> {
		Util.debug('(Statistics) - loadDorctors')

		const promise = new Promise<boolean>((resolve, reject) => {
			if (this.dataService.hasLoadedDoctors() && this.dataService.loadedDoctorsAreDecrypted()) {
				console.log('doctors already loaded and decrypted')

				this.doctorList = this.dataService.doctorList

				// console.log(this.doctorList)

				resolve(true)
			} else {
				console.log('doctors not loaded yet or previous are crypted')
				this.session
					.loadDoctorList(false)
					.then((data) => {
						// console.log(data)
						this.doctorList = data
						resolve(true)
					})
					.catch((err) => {
						console.log(err)
						reject(false)
					})
			}
		})

		return promise
	}

	private loadSpecialist(): Promise<boolean> {
		Util.debug('(Statistics) - specialists')

		const promise = new Promise<boolean>((resolve, reject) => {
			if (this.dataService.hasLoadedDistribList()) {
				console.log('Spacialists already loaded')
				this.distributorList = this.session.getDtDistribList()

				// console.log(this.distributorList)

				resolve(true)
			} else {
				console.log('Spacialists not loaded yet')
				this.session
					.loadRemotes(false)
					.then((data) => {
						// console.log(data)

						this.distributorList = data

						resolve(true)
					})
					.catch((err) => {
						console.log(err)

						reject(false)
					})
			}
		})

		return promise
	}

	public setCadence() {
		Util.debug('(Statistics) - Set cadence')

		this.cadence = []
		this.statisticForm.get('cadence').disable()

		let toD = this.statisticForm.get('toDate').value
		let fromD = this.statisticForm.get('fromDate').value

		if (toD && fromD) {
			this.diffDays = toD.diff(fromD, 'days') + 1

			// console.log(this.diffDays)

			if (this.diffDays <= 21) {
				// <=21 giorni solo days (3 settimane)
				this.cadence.push(Statistics.day)
				//
			} else if (this.diffDays > 21 && this.diffDays <= 84) {
				// da 3 settimane a 12 settimane days e weekly
				//
				this.cadence.push(Statistics.day, Statistics.weekly)
				//
			} else if (this.diffDays > 84 && this.diffDays <= 365) {
				// da 12 setimane a 1 anno weekly e monthly
				//
				this.cadence.push(Statistics.weekly, Statistics.monthly)
				//
			} else if (this.diffDays > 365 && this.diffDays <= 730) {
				// da 1 anno a 2 anni monthly e yearly
				//
				this.cadence.push(Statistics.monthly, Statistics.yearly)
				//
			} else {
				// sopra i 2 anni solo yearly
				//
				this.cadence.push(Statistics.yearly)
				//
			}

			if (
				this.usrOption == Statistics.accounts ||
				this.usrOption == Statistics.accounts_plan ||
				this.usrOption == Statistics.aireportsfull ||
				this.usrOption == Statistics.performances ||
				this.usrOption == Statistics.grading_req ||
				this.usrOption == Statistics.purchase_h ||
				this.usrOption == Statistics.customers ||
				this.usrOption == Statistics.conversion_customers ||
				this.usrOption == Statistics.conversion_ai
				// this.usrOption == Statistics.hg_reports //per come sono ora i dati ricevuti non lo permettono
			) {
				this.statisticForm.get('cadence').enable()
			}
		}

		const datesSet = this.statisticForm.get('fromDate').value !== null && this.statisticForm.get('toDate').value !== null
		if (!this.cadence.includes(this.statisticForm.get('cadence').value) && datesSet) {
			this.statisticForm.get('cadence').reset()
		}
	}

	public optionSelected() {
		Util.debug('(Statistics) - optionSelected')

		// console.log(this.statisticForm.get('option').value)

		let opt = this.statisticForm.get('option').value

		// this.statisticForm.get('option').setValue(opt.type)

		this.usrOption = opt.type

		this.graphTitle = opt.text

		if (opt.type == Statistics.accounts || opt.type == Statistics.hg_reports) {
			this.statisticForm.get('full').enable()
		} else {
			this.statisticForm.get('full').disable()
		}

		if (
			opt.type == Statistics.accounts ||
			opt.type == Statistics.accounts_plan ||
			opt.type == Statistics.funnel_hg ||
			opt.type == Statistics.funnel_ai ||
			opt.type == Statistics.aireportsfull ||
			opt.type == Statistics.grading_req ||
			opt.type == Statistics.purchase_h ||
			opt.type == Statistics.customers ||
			opt.type == Statistics.conversion_customers ||
			opt.type == Statistics.conversion_ai ||
			opt.type == Statistics.hg_reports ||
			// opt.type == Statistics.operators ||
			opt.type == Statistics.performances
		) {
			this.statisticForm.get('country').enable()

			if (
				this.statisticForm.get('fromDate').valid &&
				this.statisticForm.get('toDate').valid &&
				opt.type !== Statistics.funnel_hg &&
				opt.type !== Statistics.funnel_ai
			) {
				this.statisticForm.get('cadence').enable()
			} else {
				this.statisticForm.get('cadence').reset()
				this.statisticForm.get('cadence').setValue(null)
				this.statisticForm.get('cadence').disable()
			}
		} else {
			if (!this.isSupport) {
				this.statisticForm.get('country').reset()
				this.statisticForm.get('country').setValue(null)
				this.statisticForm.get('country').disable()
				this.statisticForm.get('cadence').reset()
				this.statisticForm.get('cadence').setValue(null)
				this.statisticForm.get('cadence').disable()
			}

			this.statisticForm.get('full').disable()
		}

		// provvisorio finché estrrazione non gestirá e cadenzr
		if (opt.type == Statistics.hg_reports) {
			this.statisticForm.get('country').enable()
			this.statisticForm.get('cadence').reset()
			this.statisticForm.get('cadence').setValue(null)
			this.statisticForm.get('cadence').disable()
		}

		if (
			opt.type == Statistics.purchase_h ||
			opt.type == Statistics.grading_req ||
			opt.type == Statistics.customers ||
			opt.type == Statistics.conversion_customers ||
			opt.type == Statistics.conversion_ai ||
			opt.type == Statistics.aireportsfull
		) {
			this.statisticForm.get('opticians').enable()

			if (this.doctorList.length == 0) {
				if (this.dataService.hasLoadedDoctors()) {
					this.doctorList = this.dataService.doctorList
				} else {
					this.session
						.loadDoctorList(true)
						.then((data) => {
							// console.log(data)
							this.doctorList = data
						})
						.catch((err) => {
							console.log(err)
						})
				}
			}
		} else {
			this.statisticForm.get('opticians').reset()
			this.statisticForm.get('opticians').setValue(null)
			this.statisticForm.get('opticians').disable()
		}
	}

	public profileSelected() {
		// based on profile selction, the options available changes
		this.options = []

		this.statisticForm.get('option').setValue('')

		let opt: statOptions

		// if (!this.isSupport) {
		opt = { type: Statistics.accounts, text: this.translator.instant('STATISTICS.TYPE.ACCOUNT_CR') }
		this.options.push(opt)
		// }

		let profile = this.statisticForm.get('profile').value.db_name

		Util.debug('(statistics) - profile selected ' + profile)

		if (profile == Config.PR_OPTICIAN) {
			let opt: statOptions

			// if (!this.isSupport) {
			opt = { type: Statistics.accounts_plan, text: this.translator.instant('STATISTICS.TYPE.ACCOUNT_PL') }
			this.options.push(opt)

			opt = { type: Statistics.funnel_hg, text: this.translator.instant('STATISTICS.TYPE.FUNNEL_HG') }
			this.options.push(opt)

			opt = { type: Statistics.funnel_ai, text: this.translator.instant('STATISTICS.TYPE.FUNNEL_AI') }
			this.options.push(opt)

			opt = { type: Statistics.grading_req, text: this.translator.instant('STATISTICS.TYPE.GRADING_REQ') }
			this.options.push(opt)

			opt = { type: Statistics.purchase_h, text: this.translator.instant('STATISTICS.TYPE.PURCHASE_H') }
			this.options.push(opt)

			// 20.05.2024 temporaneamente nascosti
			//   if (!this.isSupport) {
			opt = { type: Statistics.customers, text: this.translator.instant('STATISTICS.TYPE.CUSTOMERS_ADDED') }
			this.options.push(opt)
			//   }

			// 20.05.2024 temporaneamente nascosti
			//   if (!this.isSupport) {
			opt = { type: Statistics.conversion_customers, text: this.translator.instant('STATISTICS.TYPE.CONVERSION_CUSTOMERS') }
			this.options.push(opt)
			//   }

			// 20.05.2024 temporaneamente nascosti
			//   if (!this.isSupport) {
			opt = { type: Statistics.conversion_ai, text: this.translator.instant('STATISTICS.TYPE.CONVERSION_AI') }
			this.options.push(opt)
			//   }

			opt = { type: Statistics.aireportsfull, text: this.translator.instant('STATISTICS.TYPE.AI_REPORTS') }
			this.options.push(opt)

			// opt = { type: Statistics.operators, text: this.translator.instant('STATISTICS.TYPE.EXAMS_DONE') }
			// this.options.push(opt)
			// }

			// opt = { type: Statistics.visits, text: this.translator.instant('STATISTICS.TYPE.VISIT_DONE') }
			// this.options.push(opt)
		} else {
			opt = { type: Statistics.hg_reports, text: this.translator.instant('STATISTICS.TYPE.REPORTS_DONE') }
			this.options.push(opt)

			// if (!this.isSupport) {
			opt = { type: Statistics.performances, text: this.translator.instant('STATISTICS.TYPE.PERFORMANCES') }
			this.options.push(opt)
			// }
		}

		// console.log(this.options)
	}

	public getReport() {
		Util.debug('(statistics) - getReport start')
		// console.log(this.statisticForm)

		if (!this.keepOldChart) {
			this.aiRequest = false
			this.aiRequestChart = false
			this.aiReqWithCountry = false
			this.funnelCharts = []
			this.pieGraphs = []
			this.ColumnGraphs = []
			this.ColumnGraphsBig = []
			this.performance = false
			this.purchaseReady = false
			this.gradings = false
			this.purchaseColumnChartReady = false
			this.gradingsFollowUpPieChart = null
		}

		this.loading = true

		this.fromDate = moment(this.statisticForm.get('fromDate').value).format('YYYY-MM-DD')
		this.toDate = moment(this.statisticForm.get('toDate').value).format('YYYY-MM-DD')

		this.cadenceIsPristine = this.statisticForm.get('cadence').pristine

		console.log('(statistics) T action :' + this.currentAction)

		if (this.currentAction == 'examsCount') {
			// disab button "proceed", mettere un loader ?
			this.btnCountDisabled = true

			let alreadyReq = this.checkMemoryReport()

			// console.log(alreadyReq)

			Util.debug('(statistics) - report already req ' + alreadyReq)

			if (
				alreadyReq &&
				this.usrOption !== Statistics.customers &&
				this.usrOption !== Statistics.conversion_customers &&
				this.usrOption !== Statistics.conversion_ai
			) {
				console.log(this.statistic)

				let list = this.statistic.response.slice()

				if (
					this.usrOption == Statistics.accounts ||
					this.usrOption == Statistics.accounts_plan ||
					this.usrOption == Statistics.funnel_hg ||
					this.usrOption == Statistics.funnel_ai ||
					this.usrOption == Statistics.aireportsfull ||
					this.usrOption == Statistics.performances ||
					this.usrOption == Statistics.hg_reports ||
					this.usrOption == Statistics.grading_req ||
					this.usrOption == Statistics.purchase_h ||
					this.usrOption == Statistics.customers ||
					this.usrOption == Statistics.conversion_customers ||
					this.usrOption == Statistics.conversion_ai
				) {
					if (this.fullReport) {
						this.addField(list).then((resp) => {
							this.saveToCSV(this.applyFilter(resp))
						})
					} else {
						this.saveToCSV(this.applyFilter(list))
					}
				} else {
					this.saveToCSV(list)
				}

				this.btnCountDisabled = false
			} else {
				// 3 chiamate diverse
				if (this.usrOption == 'aireportsfull') {
					return this.exportAiReports()
				} else if (
					this.usrOption == Statistics.accounts_plan ||
					this.usrOption == Statistics.funnel_hg ||
					this.usrOption == Statistics.funnel_ai ||
					this.usrOption == Statistics.performances ||
					this.usrOption == Statistics.grading_req ||
					this.usrOption == Statistics.purchase_h ||
					this.usrOption == Statistics.customers ||
					this.usrOption == Statistics.conversion_customers ||
					this.usrOption == Statistics.conversion_ai
				) {
					this.getStatistics()
					// } else if (this.usrOption == Statistics.performances) {
					// 	this.getPerformance()
					// } else if (this.usrOption == Statistics.grading_req) {
					// 	this.getGrRequest()
					// } else if (this.usrOption == Statistics.purchase_h) {
					// 	this.getPurchaseh()
				} else {
					return this.elabExamsCount()
				}
			}
		} else {
			Util.debug('(statistics) T unknown action ' + this.currentAction)
		}
	}

	private checkMemoryReport(): boolean {
		Util.debug('(statistics) - checkMemoryReport')
		let resp = false

		// console.log(this.statInMemory)

		let fromDate = this.fromDate
		if (this.usrOption == Statistics.accounts_plan && this.statisticForm.get('cadence').value == null) {
			fromDate = ''
		}

		for (let i = 0; i < this.statInMemory.length; i++) {
			const stat = this.statInMemory[i]

			if (
				stat.fromDate == fromDate &&
				stat.toDate == this.toDate &&
				stat.option == this.statisticForm.get('option').value &&
				stat.optList == this.statisticForm.get('opticians').value
			) {
				this.statistic = stat

				return (resp = true)
			}
		}

		return resp
	}

	private getStatistics() {
		let fromDate = this.fromDate

		if (this.usrOption == Statistics.accounts_plan) {
			fromDate = null
			if (this.statisticForm.get('cadence').value != null) {
				fromDate = this.fromDate
			}
		}

		let ids = ''

		if (
			this.usrOption == Statistics.purchase_h ||
			this.usrOption == Statistics.customers ||
			this.usrOption == Statistics.conversion_customers ||
			this.usrOption == Statistics.conversion_ai ||
			this.usrOption == Statistics.aireportsfull
		) {
			let opticians = this.statisticForm.get('opticians').value

			if (opticians && opticians.length != 0) {
				opticians = opticians.split(',')

				for (let i = 0; i < opticians.length; i++) {
					const opt = opticians[i]

					let doc = this.doctorList.filter((el) => el.username.toLowerCase() == opt.trim().toLowerCase())

					if (doc.length > 0) {
						ids += doc[0].id.toString() + ','
					}
				}

				if (ids.length == 0) {
					this.btnCountDisabled = false

					this.loading = false

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant('STATISTICS.ERROR.INVALID_OPTS')
					let options = new ToastOptions('error')

					this.toastService.show(header, body, false, options, 'center')

					return
				}
			}
		}

		const cadence = this.statisticForm.get('cadence').value

		this.session
			.getStatistics(fromDate, this.toDate, this.usrOption, ids, cadence || '')
			.then((stat) => {
				// console.log(stat)
				let resp

				if (this.usrOption == Statistics.accounts_plan) {
					if (fromDate) {
						resp = stat.trend_plans
					} else {
						resp = stat.operators_plans
					}
					this.loading = false
				} else if (this.usrOption == Statistics.funnel_hg) {
					resp = stat.operators_hg_funnel
				} else if (this.usrOption == Statistics.funnel_ai) {
					resp = stat.oper_funnel_ai
				} else if (this.usrOption == Statistics.performances) {
					resp = stat.performances
				} else if (this.usrOption == Statistics.grading_req) {
					resp = stat.grading_req
				} else if (this.usrOption == Statistics.purchase_h) {
					resp = stat.balances
				} else if (this.usrOption == Statistics.customers) {
					resp = stat.patients_cadence
				} else if (this.usrOption == Statistics.conversion_customers) {
					resp = stat.pats_hg_cadence
				} else if (this.usrOption == Statistics.conversion_ai) {
					resp = stat.pats_funnel_ai_hg_cadence
				}

				let opticians = this.statisticForm.get('opticians').value

				if (
					(this.usrOption == Statistics.customers ||
						this.usrOption == Statistics.conversion_customers ||
						this.usrOption == Statistics.conversion_ai ||
						this.usrOption == Statistics.aireportsfull) &&
					opticians &&
					opticians.length != 0
				) {
					opticians = opticians.split(',')
					opticians = opticians.map((opt) => opt.trim().toLowerCase())

					resp = resp.filter((el) => opticians.includes(el.username.toString().toLowerCase()))
				}

				this.saveReportMemory(resp)

				this.saveToCSV(this.applyFilter(resp))

				this.btnCountDisabled = false
			})
			.catch((err) => {
				console.log(err)
				this.btnCountDisabled = false

				this.loading = false

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = err.customMessage || err.error.error ||  err.statusText 
				let options = new ToastOptions('error')

				this.toastService.show(header, body, false, options, 'center')
			})
	}

	// 10.11.2021 esteso a fare export anche dei reports
	// 26.01.2021 conta gli esami fatti su un range di date
	private elabExamsCount() {
		console.log('(stats) from: ' + this.fromDate + ' to: ' + this.toDate)
		const countrySelected = this.usrOption == Statistics.hg_reports && this.statisticForm.get('country').value !== null

		return this.session[countrySelected ? 'getGradersReports' : 'getExamsCount'](this.fromDate, this.toDate, this.usrOption)
			.then((myResponse: any) => {
				if (myResponse) {
					// console.log(myResponse)

					if (myResponse.length == 0) {
						alert('No record found with filter [' + this.fromDate + ',' + this.toDate + ']')
						//this.btnCountDisabled = false;
					} else {
						if (this.usrOption == Statistics.hg_reports && this.statisticForm.get('country').value !== null) {
							this.session.getPendingGradings(this.toDate, this.statisticForm.get('country').value.alpha3).then((pendingGradingRes) => {
								this.groupPendingGradings(pendingGradingRes.pending_grading)
									.then((pendingGradingGrouped) => {
										myResponse.forEach((record) => {
											record.gradings_pending = pendingGradingGrouped.filter(
												(pending_grading) => pending_grading.grader_id === record.user_group_id || pending_grading.grader_id === record.grader_id
											).length
										})
									})
									.then(() => {
										this.replaceGrouped(myResponse.slice(), myResponse).then((res) => {
											this.saveReportMemory(res)

											let list = res.slice()

											this.saveToCSV(this.applyFilter(list.sort((a, b) => a.username.localeCompare(b.username))))
										})
									})
							})
						} else {
							// download dei record su zip file
							//alert("(elabReportCount) OK "+len);
							this.saveReportMemory(myResponse)

							let list = myResponse.slice()

							// if (this.usrOption == Statistics.accounts || this.usrOption == Statistics.hg_reports || this.usrOption == Statistics.operators) {
							if (this.usrOption == Statistics.accounts || this.usrOption == Statistics.hg_reports) {
								if (this.fullReport) {
									this.addField(list).then((resp) => {
										this.saveToCSV(this.applyFilter(resp))
									})
								} else {
									this.saveToCSV(this.applyFilter(list))
								}
							} else {
								this.saveToCSV(myResponse)
							}
						}
					}
					this.btnCountDisabled = false // riabilito per altra ricerca
				} else {
					console.log('(elabExamsCount) - KO resp!')
				}
				this.loading = false
			})
			.catch((err) => {
				let msg = this.session.parseErrorMessage(err, 'alert')
				alert('Stats error: ' + msg)

				this.btnCountDisabled = false

				this.loading = false
			})

		//}
	}

	// 26.08.2022 Tab TRENDS, export diverso dagli altri, serve per i conteggi billable
	exportAiReports() {
		Util.debug('(stats AI) from: ' + this.fromDate + ' to: ' + this.toDate)

		this.aiRequest = true

		const aiReportPromise = this.session.getAiRepExport(this.fromDate, this.toDate)
		const aiQualityPromise = this.session.getAiQualityExport(this.fromDate, this.toDate)

		return Promise.all([aiReportPromise, aiQualityPromise])
			.then(([lines, qualityRecords]) => {
				if (lines) {
					if (qualityRecords) {
						this.aiQualityRecords = qualityRecords
					}

					let opticians = this.statisticForm.get('opticians').value

					if (opticians && opticians.length != 0) {
						opticians = opticians.split(',')

						for (let i = 0; i < opticians.length; i++) {
							const opt = opticians[i]

							lines = lines.filter((el) => el.username.toLowerCase() == opt.trim().toLowerCase())
							this.aiQualityRecords = this.aiQualityRecords.filter((el) => el.username.toLowerCase() == opt.trim().toLowerCase())
						}
					}

					let country = this.statisticForm.get('country').value

					if (country && country.length != 0) {
						this.aiQualityRecords = this.aiQualityRecords.filter((el) => el.country === country.alpha3)
					}

					this.saveReportMemory(lines)

					this.saveToCSV(this.applyFilter(lines))

					let len = 0
					len = lines.length

					this.btnCountDisabled = false // riabilito per altra ricerca
				} else {
					console.log('(elabExamsCount) - KO resp!')
				}
				this.loading = false
			})
			.catch((err) => {
				let msg = this.session.parseErrorMessage(err, 'alert')
				alert('Stats error: ' + msg)
				this.btnCountDisabled = false

				this.loading = false
			})
	}

	private saveReportMemory(response) {
		Util.debug('(statistics) - saveReportMemory start')

		let report: StatInMemory = new StatInMemory()

		// nel caso in cui non é selezionata la cadenza con selezionato account plan, la query e i risultati sono diversi e la fromDate non é inviata, per cui in questo modo evito che non sia fatta una nuova query
		let fromDate = this.fromDate
		if (this.usrOption == Statistics.accounts_plan && this.statisticForm.get('cadence').value == null) {
			fromDate = ''
		}
		report.fromDate = fromDate
		report.toDate = this.toDate
		report.option = this.usrOption
		report.profile = this.statisticForm.get('profile').value.db_name
		report.response = response

		if (
			this.usrOption == Statistics.purchase_h ||
			this.usrOption == Statistics.customers ||
			this.usrOption == Statistics.conversion_customers ||
			this.usrOption == Statistics.conversion_ai ||
			this.usrOption == Statistics.aireportsfull
		) {
			report.optList = this.statisticForm.get('opticians').value
		}

		this.statInMemory.push(report)

		// console.log(this.statInMemory)
	}

	private applyFilter(list): any[] {
		Util.debug('(Statistics) - applyFilter start')
		// fix in caso di country null (account vecchi o boh)
		for (let i = 0; i < list.length; i++) {
			const el = list[i]
			if (el.country == null) {
				el.country = 'NULL'
			}
		}

		let filteredList = null
		// console.log(list)

		let profile = this.statisticForm.get('profile').value.db_name

		let option = this.statisticForm.get('option').value.type

		// console.log(option)

		if (option == Statistics.accounts) {
			// solo con questa statistica
			if (profile == Config.PR_OPTICIAN) {
				filteredList = list.filter((el) => el.user_type == profile)
			} else {
				// aggiunti con il filtro specialist anche doctor e clinic admin
				filteredList = list.filter((el) => (el.user_type == profile && el.user_subtype != Config.SUB_MINI) || el.user_type == Config.PR_CLINIC)
			}
		} else {
			filteredList = list
		}

		let opticians = this.statisticForm.get('opticians').value

		if (option == Statistics.grading_req) {
			if (opticians && opticians.length != 0) {
				filteredList = []

				opticians = opticians.split(',').map((optUsername: string) => optUsername.trim())
				// console.log(opticians)

				try {
					for (let i = 0; i < opticians.length; i++) {
						const opt = opticians[i]
						let optUser = this.doctorList.filter((el) => el.username.toLowerCase() == opt.toLowerCase())

						if (!this.statisticForm.get('country').pristine && optUser[0].country !== this.statisticForm.get('country').value.alpha3) {
							throw new Error(this.translator.instant('STATISTICS.ERROR.WRONG_COUNTRY_OPTS', { optUsername: optUser[0].username }))
						}

						let filtered = list.filter((el) => el.opt_id == optUser[0].id)

						filteredList = filteredList.concat(filtered)
					}
				} catch (err: any) {
					err.customMessage = err.name === 'TypeError' ? this.translator.instant('STATISTICS.ERROR.INVALID_OPTS') : err.message
					throw err
				}
			}
		}

		//country
		if (!this.statisticForm.get('country').pristine) {
			let country = this.statisticForm.get('country').value

			filteredList = filteredList.filter((el) => el.country == country.alpha3)
		}

		// console.log(filteredList)

		return filteredList
	}

	private addField(reports): Promise<any> {
		return Promise.all([this.loadDotors(), this.loadSpecialist()])
			.then((values) => {
				// console.log(values)

				if (values[0] && values[1]) {
					for (let i = 0; i < reports.length; i++) {
						const report = reports[i]

						if (this.usrOption == Statistics.hg_reports) {
							report.organization_name = null
							report.name_surname = null

							if (!this.isSupport) {
								report.opt_organization_name = null
								report.opt_name_surname = null
							}

							let distrbs = this.distributorList.filter((el) => el.user_id == report.id)
							let opts = this.doctorList.filter((el) => el.user_id == report.opt_id)

							if (distrbs.length > 0 && opts.length > 0) {
								report.organization_name = distrbs[0].mainAddress.organization
								report.name_surname = distrbs[0].name

								if (!this.isSupport) {
									report.opt_organization_name = opts[0].mainAddress.organization
									report.opt_name_surname = opts[0].name
								}
							}
						} else {
							report.organization_name = null
							report.name_surname = null

							if (report.user_type == Config.PR_SPECIALIST || report.user_type == Config.PR_DISTRIB || report.user_type == Config.PR_CLINIC) {
								let record = this.distributorList.filter((el) => el.user_id == report.user_id)
								report.organization_name = record[0].mainAddress.organization
								report.name_surname = record[0].name
							}

							if (report.user_type == Config.PR_OPTICIAN) {
								let record = this.doctorList.filter((el) => el.user_id == report.user_id)

								report.organization_name = record[0].mainAddress.organization
								report.name_surname = record[0].name
							}
						}
					}

					// console.log(reports)
				} else {
					console.log('some trouble on load distributors or doctors')
				}

				return reports
			})
			.catch((err) => {
				console.log(err)
			})
	}

	// private saveToCSVAI(report) {
	// 	Util.debug('(statistics) - saveToCSV start')

	// 	let strBuffer = ''
	// 	let tmp = Util.convertToCSV(report)
	// 	strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)

	// 	let filename = 'ai_report_list' + '_' + this.fromDate + '_' + this.toDate + '.csv'
	// 	let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })

	// 	Util.mySaveAs(filename, blob)
	// }

	private saveToCSV(report) {
		Util.debug('(statistics) - saveToCSV')
		// console.log(report)
		this.loading = false

		if (report.length > 0) {
			this.buildGraphs(report)

			let strBuffer = ''
			let filename = ''
			filename = this.buildTitle()
			filename = filename.replaceAll('<b>', '')
			filename = filename.replaceAll('</b>', '')

			let skipCSV = false
			let profile = this.statisticForm.get('profile').value.db_name

			if (profile == Config.PR_OPTICIAN && this.isSupport && this.usrOption != Statistics.accounts && this.usrOption != Statistics.customers) {
				skipCSV = true
			}

			if (profile == Config.PR_SPECIALIST && this.isSupport && this.usrOption == Statistics.performances) {
				skipCSV = true
			}

			//all'inizio usavo il title fuori dal grafico x cui lo formattavo un po, lo mantenuto magari serve

			if (!skipCSV) {
				filename = filename + '.csv'

				// if (this.usrOption != Statistics.visits) {
				let tmp = Util.convertToCSV(report)
				strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)

				let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })
				Util.mySaveAs(filename, blob)
			}

			let header = this.translator.instant('TOAST.HEADER.SUCCESS')
			let body = filename + 'report generated'
			let options = new ToastOptions('success')

			this.toastService.show(header, body, false, options, 'bottom-right')
		} else {
			this.aiRequest = false
			this.aiReqWithCountry = false
			this.aiRequestChart = false

			let header = this.translator.instant('TOAST.HEADER.INFO')
			let body = 'NO record with the selected filters'
			let options = new ToastOptions('info')

			this.toastService.show(header, body, false, options, 'center')
		}
	}

	private buildTitle(): string {
		let country = ''
		if (!this.statisticForm.get('country').pristine) {
			country = ' - ' + this.statisticForm.get('country').value.name
		}

		let cadence = ''
		if (!this.statisticForm.get('cadence').pristine) {
			cadence = '<b>' + this.statisticForm.get('cadence').value + '</b> trend'
		}

		let fromDate = 'From: <b>' + this.fromDate + '</b> -'
		if (this.statisticForm.get('option').value == Statistics.accounts_plan && this.statisticForm.get('cadence').pristine) {
			fromDate = 'Up'
		}

		let opticians = ''
		if (this.statisticForm.get('opticians').value && this.statisticForm.get('opticians').value != '') {
			opticians = ' - ' + this.statisticForm.get('opticians').value + ' - '
		}

		let title =
			'<b>' +
			this.graphTitle +
			' - ' +
			this.statisticForm.get('profile').value.filter_name +
			opticians +
			country +
			'</b> ( ' +
			fromDate +
			' To: <b>' +
			this.toDate +
			'</b>)' +
			cadence

		return title
	}

	private buildGraphs(report) {
		Util.debug('(statistics) - buildGraphs')
		//non devo preoccuparmi del profile scelto xk qui mi arriva giá filtrato, seleziono solo quella colonna
		let reportCopy = report.slice()

		let title = this.buildTitle()

		let generated = false

		const fromDate = this.statisticForm.get('fromDate').value
		const toDate = this.statisticForm.get('toDate').value

		// console.log(this.usrOption)

		//######################
		// ACCOUNTS CREATED
		//#####################
		if (this.usrOption == Statistics.accounts) {
			Util.debug('(statistics) - buildGraphs - account created')

			let profile = this.statisticForm.get('profile').value.db_name

			if (this.statisticForm.get('cadence').pristine) {
				Util.debug('(statistics) - buildGraphs - cadence not selected')

				//
				//grafico 2 global - account attivati (con e senza country attiva)
				//
				let pieChartData: pieChartDatas = new pieChartDatas()

				pieChartData.title = title

				pieChartData.labels = ['Active', 'Inactive']

				let active = reportCopy.filter((el) => el.subscription_time != null)

				let inactive = reportCopy.length - active.length

				pieChartData.data = [active.length, inactive]

				pieChartData.colors = ['#28b32e', '#9b9b9b'] // 2 colori predefiniti per active e non active (verde grigio)

				let graph = new basicPieGraph(pieChartData)

				this.pieGraphs.push(graph)
				// this.pieGraphs.splice(0, 0, graph) //prova per mettere gli ultimi grafici allinizio, peró viene invertito ovviamente anche l'ordine dei grafici creati adogni click e non va bene

				//
				//grafico 1 global - account creati (se non é selezionata una country)
				//
				if (this.statisticForm.get('country').pristine) {
					let graph = new basicPieGraph(this.buildCountryPieChart(reportCopy, title, 'country', false))

					this.pieGraphs.push(graph)
					// this.pieGraphs.splice(0, 0, graph)
				}

				//
				// grafico 3 global - difference between self-registered and admin created accounts
				//

				if (profile === Config.PR_OPTICIAN) {
					let registerDiffPieChartData: pieChartDatas = new pieChartDatas()
					let selfRegistered = reportCopy.filter((el) => el.created_by === 0)
					let adminCreated = reportCopy.length - selfRegistered.length

					registerDiffPieChartData.title = title
					registerDiffPieChartData.labels = ['Self Registered', 'Created by Admin']
					registerDiffPieChartData.data = [selfRegistered.length, adminCreated]
					registerDiffPieChartData.colors = ['#ffa600', '#bc5090']

					let registerDiffGraph = new basicPieGraph(registerDiffPieChartData)

					this.pieGraphs.push(registerDiffGraph)
				} else {
					let specTypeDiffPieChartData: pieChartDatas = new pieChartDatas()
					let partnerSpecs = reportCopy.filter((el) => el.user_subtype === Config.SUB_STD)
					let privateSpecs = reportCopy.length - partnerSpecs.length

					specTypeDiffPieChartData.title = title
					specTypeDiffPieChartData.labels = ['VX Partner', 'Private']
					specTypeDiffPieChartData.data = [partnerSpecs.length, privateSpecs]
					specTypeDiffPieChartData.colors = ['#ffa600', '#bc5090']

					let specTypeDiffGraph = new basicPieGraph(specTypeDiffPieChartData)

					this.pieGraphs.push(specTypeDiffGraph)
				}
			} else {
				// se é selzionata una cadenza
				let filter = this.statisticForm.get('cadence').value
				// console.log(filter)

				Util.debug('(statistics) - buildGraphs - cadence selected: ' + filter)

				// manipulate array to restrict entry_type to 'Self Registration' or 'Other'
				reportCopy = reportCopy.map((el) => {
					return { ...el, entry_type: el.created_by === 0 ? 'Self Registration' : 'Other' }
				})

				let entriesArr = reportCopy.filter((el) => el.entry_type != null)
				let possibleEntryTypes: any[] = [...new Set(entriesArr.map((item) => item.entry_type))]

				let ColumnDataTot: ColumnDatas = new ColumnDatas()
				let graphTot: ColumnGraph
				let seriesTot: ColumnSeries[] = createArrays.createSeries(possibleEntryTypes, true)
				ColumnDataTot.title = title
				ColumnDataTot.stacked = true

				let values: serieValues = new serieValues('creation_date', 'cr_week', 'cr_month')
				let chart = this.buildSeriesColumnChart(reportCopy, fromDate, toDate, seriesTot, values, 'entry_type')

				ColumnDataTot.data = chart.series
				ColumnDataTot.xaxis = chart.xaxis
				ColumnDataTot.xaxisLength = chart.xaxis.length
				graphTot = new ColumnGraph(ColumnDataTot)
				graphTot.stroke.width = Array(seriesTot.length).fill(0)
				graphTot.stroke.width[seriesTot.length - 1] = 4
				graphTot.dataLabels.enabledOnSeries = [seriesTot.length - 1]

				if (this.statisticForm.get('size').value) {
					this.ColumnGraphsBig.push(graphTot)
				} else {
					this.ColumnGraphs.push(graphTot)
				}
			}

			generated = true

			//######################
			// ACCOUNT PLANS OPTION
			//#####################
		} else if (this.usrOption == Statistics.accounts_plan) {
			Util.debug('(statistics) - buildGraphs - accounts plans')

			let activePlans = reportCopy.filter((el) => el.activation_time != null)
			let inactivePlans = reportCopy.filter((el) => el.activation_time == null)
			let plans = [SalePlan.SALE_BASIC, SalePlan.SALE_MIDI, SalePlan.SALE_ADV, SalePlan.SALE_UAI, SalePlan.SALE_FRTR, SalePlan.SALE_EXP, SalePlan.SALE_FRZ]

			let activePieChartData: pieChartDatas = new pieChartDatas()
			let inactivePieChartData: pieChartDatas = new pieChartDatas()
			const planLabels = ['Free', 'Nexus subscription', 'Nexus subscription + HG', 'Unlimited AI', 'Free Trial', 'Expired']

			activePieChartData.colors = ['#81b9f2', '#49ae04', '#d62115', '#e9c00b', '#129cb5', '#d97f6a'] // same colors used for columns
			inactivePieChartData.colors = activePieChartData.colors
			activePieChartData.title = 'Active - ' + title
			activePieChartData.labels = planLabels

			inactivePieChartData.title = 'Inactive - ' + title
			inactivePieChartData.labels = planLabels

			const planCounts = {}
			plans.forEach((plan) => {
				planCounts[plan] = {}
				planCounts[plan].countA = 0
				planCounts[plan].countI = 0
			})

			for (let i = 0; i < plans.length; i++) {
				const plan = plans[i]

				let filteredA
				let filteredI

				if (plan == SalePlan.SALE_ADV) {
					filteredA = activePlans.filter(
						(el) => el.historical_plan_level == plan && el.historical_free_trial_active == 'N' && el.historical_plan_name !== SalePlan.SALE_UAI
					)
					filteredI = inactivePlans.filter(
						(el) => el.historical_plan_level == plan && el.historical_free_trial_active == 'N' && el.historical_plan_name !== SalePlan.SALE_UAI
					)
				} else if (plan == SalePlan.SALE_FRTR) {
					filteredA = activePlans.filter((el) => el.historical_plan_level == SalePlan.SALE_ADV && el.historical_free_trial_active == 'Y')
					filteredI = inactivePlans.filter((el) => el.historical_plan_level == SalePlan.SALE_ADV && el.historical_free_trial_active == 'Y')
				} else if (plan == SalePlan.SALE_UAI) {
					filteredA = activePlans.filter((el) => el.historical_plan_name == SalePlan.SALE_UAI)
					filteredI = inactivePlans.filter((el) => el.historical_plan_name == SalePlan.SALE_UAI)
				} else {
					filteredA = activePlans.filter((el) => el.historical_plan_level == plan)
					filteredI = inactivePlans.filter((el) => el.historical_plan_level == plan)
				}

				planCounts[plan].countA = filteredA.length
				planCounts[plan].countI = filteredI.length
			}

			const sumA = [
				planCounts['basic'].countA,
				planCounts['middle'].countA,
				planCounts['advanced'].countA,
				planCounts['unlimited_AI'].countA,
				planCounts['free trial'].countA,
				planCounts['expired'].countA + planCounts['frozen'].countA,
			]
			const sumI = [
				planCounts['basic'].countI,
				planCounts['middle'].countI,
				planCounts['advanced'].countI,
				planCounts['unlimited_AI'].countI,
				planCounts['free trial'].countI,
				planCounts['expired'].countI + planCounts['frozen'].countI,
			]
			activePieChartData.data = sumA
			inactivePieChartData.data = sumI

			let graphA = new basicPieGraph(activePieChartData)
			let graphI = new basicPieGraph(inactivePieChartData)
			this.pieGraphs.push(graphA, graphI)

			generated = true

			//######################
			// FUNNEL HG OPTION
			//#####################
		} else if (this.usrOption == Statistics.funnel_hg) {
			Util.debug('(statistics) - buildGraphs - funnel hg')
			let plans = [SalePlan.SALE_BASIC, SalePlan.SALE_MIDI, SalePlan.SALE_ADV, SalePlan.SALE_FRTR, SalePlan.SALE_EXP, SalePlan.SALE_FRZ]

			if (this.statisticForm.get('cadence').pristine) {
				// main funnel chart
				const mainFunnel: pieChartDatas = new pieChartDatas()
				mainFunnel.title = title

				const mainLabels = [
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.ACCOUNT_CREATED'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.ACCOUNT_ACTIVATED'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.FREE_TRIAL'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.PURCHASED_SUB'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.PURCHASED_CREDITS'),
				]
				mainFunnel.labels = mainLabels

				const mainFunnelArray = []
				mainFunnelArray.push(reportCopy.length)
				const activatedAccounts = reportCopy.filter((el) => el.activation_time != null)
				mainFunnelArray.push(activatedAccounts.length)
				const freeTrialActivated = []
				const freeTrialNotActivated = []
				activatedAccounts.forEach((el) => (el.free_trial_purchased == 'Y' ? freeTrialActivated.push(el) : freeTrialNotActivated.push(el)))
				mainFunnelArray.push(freeTrialActivated.length)
				const nexusLicensePurchased = freeTrialActivated.filter((el) => el.date_first_purchase_subscr !== null)
				mainFunnelArray.push(nexusLicensePurchased.length)
				const creditsPurchased = freeTrialActivated.filter((el) => el.date_first_purchase_credits !== null)
				mainFunnelArray.push(creditsPurchased.length)

				mainFunnel.data = [{ data: mainFunnelArray }]

				const mainFunnelGraph = new funnelChart(mainFunnel)

				// free trial credits funnel chart
				const freeTrialCreditsFunnel: pieChartDatas = new pieChartDatas()
				freeTrialCreditsFunnel.title = title.replace(
					this.translator.instant('STATISTICS.TYPE.FUNNEL_HG'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.FREE_TRIAL_CREDITS')
				)

				const freeTrialCreditsLabels = [
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.FREE_CR_GIVEN'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.FREE_CR_USED'),
				]
				freeTrialCreditsFunnel.labels = freeTrialCreditsLabels

				const freeTrialCreditsArray = []
				const freeTrialCreditsGiven = freeTrialActivated.reduce((acc: number, currentEl) => acc + (currentEl.free_credits_given || 0), 0)
				freeTrialCreditsArray.push(freeTrialCreditsGiven)
				const freeTrialCreditsUsed = freeTrialActivated.reduce((acc: number, currentEl) => acc + (currentEl.free_credits_used || 0), 0)
				freeTrialCreditsArray.push(freeTrialCreditsUsed)

				freeTrialCreditsFunnel.data = [{ data: freeTrialCreditsArray }]

				const freeTrialCreditsFunnelGraph = new funnelChart(freeTrialCreditsFunnel)

				// free trial users, paid credits funnel chart
				const freeTrialPaidCreditsFunnel: pieChartDatas = new pieChartDatas()
				freeTrialPaidCreditsFunnel.title = title.replace(
					this.translator.instant('STATISTICS.TYPE.FUNNEL_HG'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.FREE_TRIAL_PAID_CR')
				)

				const paidCreditsLabels = [this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.CR_BOUGHT'), this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.CR_USED')]
				freeTrialPaidCreditsFunnel.labels = paidCreditsLabels

				const freeTrialPaidCreditsArray = []
				const freeTrialPaidCreditsGiven = creditsPurchased.reduce((acc: number, currentEl) => acc + (currentEl.paid_credits_purchased || 0), 0)
				freeTrialPaidCreditsArray.push(freeTrialPaidCreditsGiven)
				const freeTrialPaidCreditsUsed = creditsPurchased.reduce((acc: number, currentEl) => acc + (currentEl.paid_credits_used || 0), 0)
				freeTrialPaidCreditsArray.push(freeTrialPaidCreditsUsed)

				freeTrialPaidCreditsFunnel.data = [{ data: freeTrialPaidCreditsArray }]

				const freeTrialPaidCreditsFunnelGraph = new funnelChart(freeTrialPaidCreditsFunnel)

				// NON - free trial users, paid credits funnel chart
				const nonFreeTrialPaidCreditsFunnel: pieChartDatas = new pieChartDatas()
				nonFreeTrialPaidCreditsFunnel.title = title.replace(
					this.translator.instant('STATISTICS.TYPE.FUNNEL_HG'),
					this.translator.instant('STATISTICS.FUNNEL_HG_LABELS.NO_FREE_TRIAL_PAID_CR')
				)

				nonFreeTrialPaidCreditsFunnel.labels = paidCreditsLabels

				const nonFreeTrialPaidCreditsArray = []
				const nonFreeTrialPaidCreditsGiven = freeTrialNotActivated.reduce((acc: number, currentEl) => acc + (currentEl.paid_credits_purchased || 0), 0)
				nonFreeTrialPaidCreditsArray.push(nonFreeTrialPaidCreditsGiven)
				const nonFreeTrialPaidCreditsUsed = freeTrialNotActivated.reduce((acc: number, currentEl) => acc + (currentEl.paid_credits_used || 0), 0)
				nonFreeTrialPaidCreditsArray.push(nonFreeTrialPaidCreditsUsed)

				nonFreeTrialPaidCreditsFunnel.data = [{ data: nonFreeTrialPaidCreditsArray }]

				const nonFreeTrialPaidCreditsFunnelGraph = new funnelChart(nonFreeTrialPaidCreditsFunnel)

				this.funnelCharts.push(mainFunnelGraph)
				this.funnelCharts.push(freeTrialCreditsFunnelGraph)
				this.funnelCharts.push(freeTrialPaidCreditsFunnelGraph)
				this.funnelCharts.push(nonFreeTrialPaidCreditsFunnelGraph)
			}
			generated = true

			//######################
			// FUNNEL AI OPTION
			//#####################
		} else if (this.usrOption == Statistics.funnel_ai) {
			Util.debug('(statistics) - buildGraphs - funnel ai')

			if (this.statisticForm.get('cadence').pristine) {
				// main funnel chart
				const mainFunnel: pieChartDatas = new pieChartDatas()
				mainFunnel.title = title
				mainFunnel.labels = [
					this.translator.instant('STATISTICS.FUNNEL_AI_LABELS.ACCOUNT_VX610'),
					this.translator.instant('STATISTICS.FUNNEL_AI_LABELS.ACCOUNT_ACTIVATED'),
					this.translator.instant('STATISTICS.FUNNEL_AI_LABELS.AI_PURCHASED'),
					this.translator.instant('STATISTICS.FUNNEL_AI_LABELS.AI_ACTIVE'),
				]

				const mainFunnelArray = []
				mainFunnelArray.push(reportCopy.length)
				const activatedAccounts = reportCopy.filter((el) => el.subscription_time != null)
				mainFunnelArray.push(activatedAccounts.length)
				const aiPlanPurchased = activatedAccounts.filter((el) => el.AI_plan_start_date != null)
				mainFunnelArray.push(aiPlanPurchased.length)
				const aiPlanActive = aiPlanPurchased.filter((el) => el.AI_plan_is_valid === 'Y')
				mainFunnelArray.push(aiPlanActive.length)

				mainFunnel.data = [{ data: mainFunnelArray }]

				const mainFunnelGraph = new funnelChart(mainFunnel)

				this.funnelCharts.push(mainFunnelGraph)
			}
			generated = true

			//######################
			// AI REPORTS OPTION
			//#####################
		} else if (this.usrOption == Statistics.aireportsfull) {
			Util.debug('(statistics) - buildGraphs - AI Reports')

			this.aiReqWithCountry = false
			this.aiReqWithCadence = false
			this.aiServiceOptSelected = this.statisticForm.get('opticians').value && this.statisticForm.get('opticians').value != ''
			title = title.replace('- Operator', '')

			this.dssList = []
			this.mcsList = []

			this.totalReq = reportCopy.length
			// creo due liste, una di report buoni e una con quelli non validi
			this.totalReportDone = reportCopy.filter((el) => el.status > 2)

			let listTotalFail: aireportStat[] = reportCopy.filter((el) => el.status < 3)

			this.totalDone = this.totalReportDone.length
			this.totalFail = listTotalFail.length

			// console.log(this.totalReportDone)
			// console.log(listTotalFail)

			let operators = [...new Set(this.totalReportDone.map((item) => item.username))] // mi ricavo tutti gli operatori

			// console.log(operators)

			// per ogni operatore mi creo un record nelle 2 liste mcs e dss (nel caso remoto in cui un operatore abbia entrambi i tipi di ai)
			for (let i = 0; i < operators.length; i++) {
				const op = operators[i]

				let opExamsDSS = this.totalReportDone.filter((el) => el.username == op && el.channel == 'dss')
				let opExamsMCS = this.totalReportDone.filter((el) => el.username == op && el.channel == 'mcs')

				if (opExamsDSS.length > 0) {
					// se > 0 vuol dire che questo operatore ha report dss
					let op = new operatorAi(opExamsDSS) // nel costruttore della classe viene fatto tutto
					this.dssList.push(op)
				}

				if (opExamsMCS.length > 0) {
					// se > 0 vuol dire che questo operatore ha report mcs
					let op = new operatorAi(opExamsMCS) // nel costruttore della classe viene fatto tutto
					this.mcsList.push(op)
				}
			}
			// console.log(this.dssList)
			// console.log(this.mcsList)
			this.mcs = new aiRequest(this.mcsList)
			this.dss = new aiRequest(this.dssList)

			// SECTION 1

			let pieChartDataTot: pieChartDatas = new pieChartDatas()
			let pieChartDataMCS: pieChartDatas = new pieChartDatas()
			let pieChartDataDSS: pieChartDatas = new pieChartDatas()
			let pieChartDataAvgResponseTime: pieChartDatas = new pieChartDatas()
			let pieChartQuality: pieChartDatas = new pieChartDatas()

			pieChartDataTot.title = 'Total ' + title
			pieChartDataMCS.title = 'MCS ' + title
			pieChartDataDSS.title = 'DSS ' + title
			pieChartDataAvgResponseTime.title = 'Avg Response Time ' + title
			pieChartQuality.title = 'Quality Metrics ' + title

			pieChartDataTot.labels = ['Successful', 'KO Received']
			pieChartDataMCS.labels = ['Green', 'Yellow', 'red']
			pieChartDataDSS.labels = ['Green', 'Yellow', 'red']
			pieChartDataAvgResponseTime.labels = ['<= 30 sec', '30 - 60 sec', '60 - 120 sec', '> 120 sec']
			pieChartQuality.labels = ['Optimal', 'Sub-optimal', 'Acceptable', 'Borderline', 'Insufficient']

			pieChartDataTot.colors = ['#28b32e', '#9b9b9b']
			pieChartDataMCS.colors = ['#28b32e', '#cccc00', '#ff3b2d']
			pieChartDataDSS.colors = ['#28b32e', '#cccc00', '#ff3b2d']
			pieChartDataAvgResponseTime.colors = ['#156082', '#e97132', '#196b24', '#0f9ed5']
			pieChartQuality.colors = ['#156082', '#e97132', '#196b24', '#0f9ed5', '#a02b93']

			let sumTot = []
			sumTot.push(this.totalDone, this.totalFail)
			pieChartDataTot.data = sumTot

			let sumMCS = []
			sumMCS.push(this.mcs.green, this.mcs.yellow, this.mcs.red)
			pieChartDataMCS.data = sumMCS

			let sumDSS = []
			sumDSS.push(this.dss.green, this.dss.yellow, this.dss.red)
			pieChartDataDSS.data = sumDSS

			let avgRespData = []
			let lessThan30 = 0
			let between30and60 = 0
			let between60and120 = 0
			let moreThan120 = 0
			let totalGrTime = 0

			this.totalReportDone.forEach((el) => {
				const grTime = parseInt(el.grading_time) || 0
				totalGrTime += grTime
				if (grTime <= 30) {
					lessThan30++
				} else if (grTime <= 60) {
					between30and60++
				} else if (grTime <= 120) {
					between60and120++
				} else {
					moreThan120++
				}
			})

			let qualityData = []
			let optimal = 0
			let subOptimal = 0
			let acceptable = 0
			let borderline = 0
			let insufficient = 0
			this.aiQualityRecords.forEach((el) => {
				switch (el.quality) {
					case 1:
						optimal++
						break
					case 2:
						subOptimal++
						break
					case 3:
						acceptable++
						break
					case 4:
						borderline++
						break
					case 5:
						insufficient++
						break
					default:
						console.log(`Unexpected quality value: ${el.quality}`)
						break
				}
			})

			this.avgAiResponseTime = Math.trunc(totalGrTime / this.totalReportDone.length)
			this.totalAiRequests = this.totalReportDone.length
			avgRespData.push(lessThan30, between30and60, between60and120, moreThan120)
			pieChartDataAvgResponseTime.data = avgRespData
			qualityData.push(optimal, subOptimal, acceptable, borderline, insufficient)
			pieChartQuality.data = qualityData

			if (this.statisticForm.get('cadence').pristine) {
				this.aiTotpieGraphs = new basicPieGraph(pieChartDataTot)
				this.aiMCSpieGraphs = new basicPieGraph(pieChartDataMCS)
				this.aiDSSpieGraphs = new basicPieGraph(pieChartDataDSS)
				this.avgResponseTimePieGraphs = new basicPieGraph(pieChartDataAvgResponseTime)
				this.aiQualityPieGraphs = new basicPieGraph(pieChartQuality)
				

				this.aiTotpieGraphs.chart.height = 380
				this.aiMCSpieGraphs.chart.height = 380
				this.aiDSSpieGraphs.chart.height = 380
				this.avgResponseTimePieGraphs.chart.height = 380
				this.aiQualityPieGraphs.chart.height = 380

				this.aiTotpieGraphsGlobal = new basicPieGraph()
				this.globalAIcolumnChart = new ColumnGraph()

				if (this.statisticForm.get('country').pristine && !this.aiServiceOptSelected) {
					// SECTION 2

					// PIE CHART
					this.aiTotpieGraphsGlobal = new basicPieGraph(this.buildCountryPieChart(this.totalReportDone, 'Global ' + title, 'country', false))
					this.aiTotpieGraphsGlobal.chart.height = 380

					//COLUMN CHART
					this.aiColumnChartGlobal()
				}

				// SECTION 3
				if (!this.aiServiceOptSelected) this.buildGraderList()
			} else {
				this.aiRequest = false
				this.aiReqWithCountry = false
				this.aiRequestChart = false
				this.aiReqWithCadence = true
				if (!this.aiServiceOptSelected) this.buildGraderList()
			}

			this.aiRequestChart = true
			generated = true

			//######################
			// PERFORMANCE OPTION
			//#####################
		} else if (this.usrOption == Statistics.performances) {
			this.performanceWithCadence = false
			this.performanceList = report

			this.buildPerformanceGraderList()

			generated = true

			//######################
			// Grading req OPTION
			//#####################
		} else if (this.usrOption == Statistics.grading_req) {
			if (this.statisticForm.get('cadence').pristine) {
				Util.debug('(statistics) - buildGraphs grading req - cadence not selected')

				// let grad_type : any[] = [...new Set(reportCopy.map((item)=>item.grad_type))]
				// console.log(grad_type)

				if (this.statisticForm.get('country').pristine && !this.statisticForm.get('opticians').value && this.statisticForm.get('opticians').value == '') {
					let graph = new basicPieGraph(this.buildCountryPieChart(reportCopy, title, 'country', false))

					this.pieGraphs.push(graph)
				}

				let graph = new basicPieGraph(this.buildCountryPieChart(reportCopy, title, 'grad_type', false))

				this.pieGraphs.push(graph)
			} else {
				let grad_type: any[] = [...new Set(reportCopy.map((item) => item.grad_type))]

				let columnDataPlan: ColumnDatas = new ColumnDatas()
				let seriesTot: ColumnSeries[] = createArrays.createSeries(grad_type)
				let chartTot: ColumnGraph

				let values: serieValues = new serieValues('request_day', 'visit_week', 'visit_month')
				let chart = this.buildSeriesColumnChart(reportCopy, fromDate, toDate, seriesTot, values, 'grad_type')

				columnDataPlan.title = title
				columnDataPlan.data = chart.series
				columnDataPlan.stacked = true
				columnDataPlan.rotate = true

				columnDataPlan.xaxis = chart.xaxis
				columnDataPlan.xaxisLength = chart.xaxis.length
				columnDataPlan.data.forEach((c) => {
					c.type = 'line'
				})

				chartTot = new ColumnGraph(columnDataPlan)
				chartTot.stroke.width = 4

				if (this.statisticForm.get('size').value) {
					this.ColumnGraphsBig.push(chartTot)
				} else {
					this.ColumnGraphs.push(chartTot)
				}
			}

			generated = true

			//######################
			// Purchase history options
			//#####################
		} else if (this.usrOption == Statistics.purchase_h) {
			this.purchaseList = reportCopy.slice()

			this.buildPurchaseHistoryTables()

			generated = true
			this.loading = false
			this.purchaseReady = true

			//######################
			// EXAM DONE options
			//#####################
			// } else if (this.usrOption == Statistics.operators) {
			// 	// if (!this.statisticForm.get('country').pristine) {
			// 	let ColumnData: ColumnDatas = new ColumnDatas()

			// 	let exams: any[] = [...new Set(reportCopy.map((item) => item.exam_type))]

			// 	exams.sort()

			// 	let series = createArrays.createSeries(['exams'])

			// 	ColumnData.title = this.buildTitle()
			// 	ColumnData.stacked = false
			// 	ColumnData.data = series
			// 	ColumnData.xaxis = exams
			// 	ColumnData.xaxisLength = exams.length

			// 	for (let i = 0; i < exams.length; i++) {
			// 		const exam = exams[i]

			// 		let filter = reportCopy.filter((el) => el.exam_type == exam)

			// 		series[0].data.push(filter.length)
			// 	}

			// 	let columnChart = new ColumnGraph(ColumnData)

			// 	this.ColumnGraphsBig.push(columnChart)
			// 	// }

			// 	generated = true

			//######################
			// REPORTS DONE options
			//#####################
		} else if (this.usrOption == Statistics.hg_reports) {
			this.gradings = true
			this.gradingsDisplayedColumns = ['onChart', 'username', 'type', 'gradings_done', 'gradings_pending']
			let report_copy = report.slice()

			this.replaceGrouped(report_copy, report).then((resp) => {
				// console.log(resp)
				// cadenza non disponibile con i dati ricevuti

				if (this.statisticForm.get('country').pristine) {
					this.gradingsTableFlag = false

					let graph = new basicPieGraph(this.buildCountryPieChart(resp, title, 'country', true))

					this.gradingsPieChart = graph
				} else {
					this.gradingsTableFlag = true
					this.gradingsListData = resp

					let graph = new basicPieGraph(this.buildCountryGradingsPieChart(resp, title, 'username'))
					let followUpgraph = new basicPieGraph(this.buildCountryGradingsFollowUpPieChart(title))

					this.gradingsPieChart = graph
					this.gradingsFollowUpPieChart = followUpgraph

					const uniqueUsernames: any[] = [...new Set(this.gradingsListData.map((item) => item.username))]
					const gradingsArr: gradingsList[] = uniqueUsernames.map((username: string) => {
						const type = this.gradingsListData.find((r) => {
							return r.username === username
						}).user_subtype
						const gradings_done = this.gradingsListData
							.filter((r) => r.username === username)
							.reduce((accumulator, currentValue) => accumulator + currentValue.tot_reports, 0)
						const gradings_pending = this.gradingsListData.filter((r) => r.username === username)[0].gradings_pending

						return {
							username,
							type,
							gradings_done,
							gradings_pending,
							isOnChart: false,
						}
					})

					this.gradingsList = new MatTableDataSource<gradingsList>(gradingsArr)
					this.gradingsList.paginator = this.gradingsPaginator
				}
			})
			//#####################
			// CUSTOMERS ADDED
			//#####################
		} else if (this.usrOption == Statistics.customers) {
			Util.debug('(statistics) - buildGraphs - customers added')
			this.customersList = reportCopy.slice()

			// country filter if set, else global
			if (!this.statisticForm.get('country').pristine) {
				const selectedCountry = this.statisticForm.get('country').value
				// console.log(selectedCountry)
				this.customersList = this.customersList.filter((el: customersAddedStat) => el.country === selectedCountry.alpha3)
			}

			// no cadence selected
			if (this.statisticForm.get('cadence').pristine) {
				Util.debug('(statistics) - buildGraphs - cadence not selected')
				let selfRegisteredCustomersNumber = 0
				let otherCustomersNumber = 0

				this.customersList.forEach((group) => {
					if (group.origin === 'SelfReg') {
						selfRegisteredCustomersNumber += group.tot
					} else {
						otherCustomersNumber += group.tot
					}
				})

				let pieChartData: pieChartDatas = new pieChartDatas()
				pieChartData.title = title
				pieChartData.labels = ['Self Registration', 'Other']

				// "as any" is used as workaround
				pieChartData.data = [selfRegisteredCustomersNumber as any, otherCustomersNumber]
				pieChartData.colors = ['#81b9f2', '#49ae04']

				let graph = new basicPieGraph(pieChartData)
				this.pieGraphs.push(graph)
			} else {
				// cadence selected
				let filter = this.statisticForm.get('cadence').value
				Util.debug('(statistics) - buildGraphs - cadence selected: ' + filter)

				let entriesArr = this.customersList.filter((el: customersAddedStat) => el.origin != null)
				let possibleEntryTypes: any[] = [...new Set(entriesArr.map((item) => item.origin))]

				let ColumnDataTot: ColumnDatas = new ColumnDatas()
				let graphTot: ColumnGraph
				let seriesTot: ColumnSeries[] = createArrays.createSeries(possibleEntryTypes, true)
				ColumnDataTot.title = title
				ColumnDataTot.stacked = true

				let chart = this.buildSeriesColumnChartWithTotValues(this.customersList, fromDate, toDate, seriesTot, 'origin')

				ColumnDataTot.data = chart.series
				ColumnDataTot.xaxis = chart.xaxis
				ColumnDataTot.xaxisLength = chart.xaxis.length
				graphTot = new ColumnGraph(ColumnDataTot)
				graphTot.stroke.width = Array(seriesTot.length).fill(0)
				graphTot.stroke.width[seriesTot.length - 1] = 4
				graphTot.dataLabels.enabledOnSeries = [seriesTot.length - 1]

				if (this.statisticForm.get('size').value) {
					this.ColumnGraphsBig.push(graphTot)
				} else {
					this.ColumnGraphs.push(graphTot)
				}
			}
			generated = true
			//#####################
			// CONVERSION FROM CUSTOMERS
			//#####################
		} else if (this.usrOption == Statistics.conversion_customers) {
			Util.debug('(statistics) - buildGraphs - conversion_customers')
			this.conversionList = reportCopy.slice()

			// country filter if set, else global
			if (!this.statisticForm.get('country').pristine) {
				const selectedCountry = this.statisticForm.get('country').value
				this.conversionList = this.conversionList.filter((el: conversionStat) => el.country === selectedCountry.alpha3)
			}

			let ColumnDataTot: ColumnDatas = new ColumnDatas()
			let graphTot: ColumnGraph
			let seriesTot: ColumnSeries[] = createArrays.createSeries(['Patients examined', 'Grading request'])
			ColumnDataTot.title = title
			ColumnDataTot.stacked = false

			let chart = this.buildSeriesColumnConversionChart(this.conversionList, fromDate, toDate, seriesTot)

			ColumnDataTot.data = chart.series
			ColumnDataTot.xaxis = chart.xaxis
			ColumnDataTot.xaxisLength = chart.xaxis.length
			graphTot = new ColumnGraph(ColumnDataTot)
			const conversionData = graphTot.series[0].data.map((el, i) => {
				const val = parseInt((((graphTot.series[1].data[i] as number) / el) * 100 || 0).toFixed(0))
				return val
			})
			graphTot.series.push({
				name: 'conversion %',
				type: 'line',
				data: conversionData,
			})
			graphTot.yaxis = [
				{
					seriesName: 'Patients examined',
				},
				{
					show: false,
					seriesName: 'Patients examined',
				},
				{
					opposite: true,
					seriesName: 'conversion %',
				},
			]
			graphTot.stroke.width = Array(seriesTot.length).fill(0)
			graphTot.stroke.dashArray = Array(seriesTot.length).fill([0])
			graphTot.stroke.width[seriesTot.length - 1] = 4
			graphTot.stroke.dashArray[seriesTot.length - 1] = 3

			if (this.statisticForm.get('size').value) {
				this.ColumnGraphsBig.push(graphTot)
			} else {
				this.ColumnGraphs.push(graphTot)
			}
			//#####################
			// CONVERSION FROM AI
			//#####################
		} else if (this.usrOption == Statistics.conversion_ai) {
			Util.debug('(statistics) - buildGraphs - conversion_customers')
			this.conversionList = reportCopy.slice()

			// country filter if set, else global
			if (!this.statisticForm.get('country').pristine) {
				const selectedCountry = this.statisticForm.get('country').value
				this.conversionList = this.conversionList.filter((el: conversionStat) => el.country === selectedCountry.alpha3)
			}

			let ColumnDataTot: ColumnDatas = new ColumnDatas()
			let graphTot: ColumnGraph
			let seriesTot: ColumnSeries[] = createArrays.createSeries(['Total patients', 'AI reviewed', 'HG reviewed'])
			ColumnDataTot.title = title
			ColumnDataTot.stacked = false

			let chart = this.buildSeriesColumnConversionChart(this.conversionList, fromDate, toDate, seriesTot, true)
			ColumnDataTot.data = chart.series
			ColumnDataTot.xaxis = chart.xaxis
			ColumnDataTot.xaxisLength = chart.xaxis.length
			graphTot = new ColumnGraph(ColumnDataTot)
			const conversionData = graphTot.series[1].data.map((el, i) => {
				const val = parseInt((((graphTot.series[2].data[i] as number) / el) * 100 || 0).toFixed(0))
				return val
			})
			graphTot.series.push({
				name: 'conversion %',
				type: 'line',
				data: conversionData,
			})

			graphTot.yaxis = [
				{
					seriesName: 'AI reviewed',
				},
				{
					show: false,
					seriesName: 'AI reviewed',
				},
				{
					show: false,
					seriesName: 'AI reviewed',
				},
				{
					opposite: true,
					seriesName: 'conversion %',
				},
			]
			graphTot.stroke.width = Array(seriesTot.length).fill(0)
			graphTot.stroke.dashArray = Array(seriesTot.length).fill([0])
			graphTot.stroke.width[seriesTot.length - 1] = 4
			graphTot.stroke.dashArray[seriesTot.length - 1] = 3
			if (this.statisticForm.get('size').value) {
				this.ColumnGraphsBig.push(graphTot)
			} else {
				this.ColumnGraphs.push(graphTot)
			}
		}

		if (generated) {
			let header = this.translator.instant('TOAST.HEADER.SUCCESS')
			let body = 'Graph generated'
			let options = new ToastOptions('success')

			this.toastService.show(header, body, false, options, 'bottom-right')
		}
	}

	// funzione comune per creare il grafico a torta diviso per country
	private buildCountryPieChart(list, title: string, parameter: string, somma: boolean) {
		let pieChartData: pieChartDatas = new pieChartDatas()
		pieChartData.title = title
		const uniqueParameters: any[] = [...new Set(list.map((item) => item[parameter]))]

		uniqueParameters.sort()

		pieChartData.labels = uniqueParameters

		let datas = []

		if (somma) {
			for (let i = 0; i < uniqueParameters.length; i++) {
				const par = uniqueParameters[i]

				let data = list.filter((el) => el[parameter] == par)
				let sum = 0

				data.forEach((element) => {
					sum += element.tot
				})

				datas.push(sum)
			}
		} else {
			for (let i = 0; i < uniqueParameters.length; i++) {
				const par = uniqueParameters[i]

				datas.push(list.filter((el) => el[parameter] == par).length)
			}
		}

		pieChartData.data = datas

		return pieChartData
	}

	private buildCountryGradingsPieChart(list, title: string, parameter: string) {
		let pieChartData: pieChartDatas = new pieChartDatas()
		pieChartData.title = title
		const uniqueParameters: any[] = [...new Set(list.map((item) => item[parameter]))]
		uniqueParameters.sort()
		pieChartData.labels = uniqueParameters

		let datas = []

		uniqueParameters.forEach((par) => {
			let data = list.filter((el) => el[parameter] == par)
			let sum = 0

			data.forEach((element) => {
				sum += element.tot_reports
			})

			datas.push(sum)
		})

		pieChartData.data = datas

		return pieChartData
	}

	private buildCountryGradingsFollowUpPieChart(title: string, grader?) {
		const parameters = ['tot_follow_soon', 'tot_follow_3_months', 'tot_follow_6_months', 'tot_follow_1_year']
		let pieChartData: pieChartDatas = new pieChartDatas()
		pieChartData.title = title + ' - Follow-up indications'
		pieChartData.labels = ['soon', '2/3 months', '6 months', '1 year']

		let datas = []

		parameters.forEach((par) => {
			let sum = 0

			if (grader) {
				const filteredGradingsListData = this.gradingsListData.filter((el) => el.username === grader.username)

				filteredGradingsListData.forEach((element) => {
					sum += element[par]
				})
			} else {
				this.gradingsListData.forEach((element) => {
					sum += element[par]
				})
			}

			datas.push(sum)
		})

		pieChartData.data = datas

		return pieChartData
	}

	public buildPurchaseHistoryTables() {
		Util.debug('(statistics) - buildPurchaseHistorytables ')

		let opticians: string[] = [...new Set(this.purchaseList.map((item) => item.username))]

		this.optPurchaseList = []

		this.matHistoryOptColumns = ['username', 'id', 'prev_balance', 'sold', 'used', 'final_balance']

		for (let i = 0; i < opticians.length; i++) {
			const optName = opticians[i]

			let purchase = this.purchaseList.filter((el) => el.username == optName)
			let opt = new purchaseHOpt(purchase)
			let credits = purchase.filter((el) => el.i_type == SalePlan.ITEM_CREDITS && el.notes !== 'initial_available_balance')

			opt.prev_balance = purchase.find((p) => p.notes === 'initial_available_balance').amount
			opt.final_balance = opt.prev_balance

			if (credits.length > 0) {
				credits.forEach((element) => {
					if (element.amount > 0) {
						opt.sold += element.amount
						opt.final_balance += element.amount
					} else {
						opt.used += Math.abs(element.amount)
						opt.final_balance += element.amount
					}
				})
			}

			this.optPurchaseList.push(opt)
		}
		// console.log(this.optPurchaseList)

		this.optPurchaseList.sort((a, b) => b.sold - a.sold)

		this.balanceOptList = new MatTableDataSource<purchaseHOpt>(this.optPurchaseList)
		this.balanceOptList.filterPredicate = (data: purchaseHOpt, filter: string) => {
			return data.final_balance <= parseInt(filter)
		}
		this.balanceOptList.paginator = this.balOptPaginator

		this.buildPurchaseHistoryPiechart()
		this.buildPurchaseHistoryColumnChart()
	}

	private buildPurchaseHistoryPiechart() {
		let pieChart: pieChartDatas = new pieChartDatas()

		pieChart.labels = ['Not used', 'Used']
		pieChart.data = []
		pieChart.title = 'Global - ' + this.buildTitle()

		let nused = 0
		let used = 0
		let array = []

		for (let i = 0; i < this.optPurchaseList.length; i++) {
			const opt = this.optPurchaseList[i]

			nused += opt.prev_balance + opt.sold - opt.used
			used += opt.used
		}

		if (nused < 0) {
			nused = 0
		}

		array.push(nused, used)
		pieChart.data = array
		this.purchasePieChart = new basicPieGraph(pieChart)
		this.purchasePieChart.chart.height = 550
	}

	public buildPurchaseHistoryColumnChart(optician?: purchaseHOpt) {
		if (!this.statisticForm.get('cadence').pristine) {
			const purchaseListNoBalance = this.purchaseList.filter((el) => el.notes !== 'initial_available_balance')
			let columnDataPlan: ColumnDatas = new ColumnDatas()

			let list: balances[] = []

			if (optician) {
				list = purchaseListNoBalance.filter((el) => el.username == optician.username && el.i_type == SalePlan.ITEM_CREDITS)
				columnDataPlan.title = optician.username + ' - ' + this.buildTitle()
			} else {
				list = purchaseListNoBalance.filter((el) => el.i_type == SalePlan.ITEM_CREDITS)
				columnDataPlan.title = this.buildTitle()
			}

			let filter = this.statisticForm.get('cadence').value

			const fromDate = this.statisticForm.get('fromDate').value
			const toDate = this.statisticForm.get('toDate').value

			let serie = ['sold', 'used']

			let seriesTot: ColumnSeries[] = createArrays.createSeries(serie)
			seriesTot = seriesTot.map((serie) => {
				return {
					...serie,
					type: 'line',
				}
			})
			let xaxis: string[]

			columnDataPlan.data = seriesTot
			columnDataPlan.stacked = true
			columnDataPlan.rotate = true

			switch (filter) {
				// DAY CADENCE
				case Statistics.day:
					xaxis = createArrays.createDaysArray(fromDate, this.diffDays)

					for (let x = 0; x < seriesTot.length; x++) {
						const serie = seriesTot[x]

						for (let i = 0; i < xaxis.length; i++) {
							const val = xaxis[i]

							let recordSold = list.filter((el) => moment.utc(new Date(el.buy_date)).format('DD-MM-YYYY') == val && el.amount > 0)
							let recordUsed = list.filter((el) => moment.utc(new Date(el.buy_date)).format('DD-MM-YYYY') == val && el.amount < 0)

							if (serie.name == 'sold') {
								serie.data[i] = recordSold.reduce((accumulator, object) => {
									return accumulator + object.amount
								}, 0) //somma tutti gli amount
							} else {
								serie.data[i] = recordUsed.reduce((accumulator, object) => {
									return accumulator + Math.abs(object.amount)
								}, 0) //somma tutti gli amount
							}
						}
					}

					break
				// WEEKLY CADENCE
				case Statistics.weekly:
					xaxis = createArrays.createWeekArray(fromDate, toDate) //array di tutte le settimane comprese nell'intervallo, che sará l'asse delle x

					// per ogni country, filtro per il valore dell'asse x e la stessa country
					for (let x = 0; x < seriesTot.length; x++) {
						const serie = seriesTot[x]

						for (let i = 0; i < xaxis.length; i++) {
							const val = xaxis[i]

							let recordSold = list.filter((el) => el.buy_week == val && el.amount > 0)
							let recordUsed = list.filter((el) => el.buy_week == val && el.amount < 0)

							if (serie.name == 'sold') {
								serie.data[i] = recordSold.reduce((accumulator, object) => {
									return accumulator + object.amount
								}, 0)
							} else {
								serie.data[i] = recordUsed.reduce((accumulator, object) => {
									return accumulator + Math.abs(object.amount)
								}, 0)
							}
						}
					}

					break

				// MONTHLY CADENCE
				case Statistics.monthly:
					xaxis = createArrays.createMonthlyArray(fromDate, toDate)

					for (let x = 0; x < seriesTot.length; x++) {
						const serie = seriesTot[x]

						for (let i = 0; i < xaxis.length; i++) {
							const val = xaxis[i]

							let recordSold = list.filter((el) => el.buy_month == val && el.amount > 0)
							let recordUsed = list.filter((el) => el.buy_month == val && el.amount < 0)

							if (serie.name == 'sold') {
								serie.data[i] = recordSold.reduce((accumulator, object) => {
									return accumulator + object.amount
								}, 0)
							} else {
								serie.data[i] = recordUsed.reduce((accumulator, object) => {
									return accumulator + Math.abs(object.amount)
								}, 0)
							}
						}
					}

					break

				// YEARLY CADENCE
				case Statistics.yearly:
					xaxis = createArrays.createYearArray(fromDate, toDate)

					for (let x = 0; x < seriesTot.length; x++) {
						const serie = seriesTot[x]

						for (let i = 0; i < xaxis.length; i++) {
							const val = xaxis[i]

							let recordSold = list.filter((el) => moment.utc(new Date(el.buy_date)).year() + '' == val && el.amount > 0)
							let recordUsed = list.filter((el) => moment.utc(new Date(el.buy_date)).year() + '' == val && el.amount < 0)

							if (serie.name == 'sold') {
								serie.data[i] = recordSold.reduce((accumulator, object) => {
									return accumulator + object.amount
								}, 0)
							} else {
								serie.data[i] = recordUsed.reduce((accumulator, object) => {
									return accumulator + Math.abs(object.amount)
								}, 0)
							}
						}
					}

					break
			}
			columnDataPlan.xaxis = xaxis
			columnDataPlan.xaxisLength = xaxis.length
			// console.log(columnDataPlan)
			this.purchaseColumnChart = new ColumnGraph(columnDataPlan)
			this.purchaseColumnChart.stroke.width = Array(seriesTot.length).fill(4)

			this.purchaseColumnChartReady = true
		}

		// console.log(this.columnChart)
	}

	private buildPerformanceGraderList() {
		Util.debug('(statistics) - buildPerformanceGraderList ')

		this.performanceList_copy = this.performanceList.slice() //creo una copia

		this.performanceList_deleted = this.performanceList_copy.filter((el) => el.username.includes('DEL_')) // nei distributori non ho un parametro come nella doctors che mi dice se deleted o no, ma lo vedo dal nome

		this.performanceList_copy = this.performanceList_copy.filter((el) => !el.username.includes('DEL_'))

		this.displayedColumns2 = ['isSelected', 'username', 'type', 'country', 'tot_reports', 'av_grading_time', 'over_48h', 'av_lock_time_s']

		if (this.showdeleted) {
			this.performanceList_copy = this.performanceList_copy.concat(this.performanceList_deleted)
		}

		this.replaceGrouped(this.performanceList_copy, this.performanceList).then((resp) => {
			// funzione che mi sostituisce tutti i nomi dei miniC con il clinicAdmin, in modo da poter raggruppare tutti i report insieme come se fossero tutti del clinicAdmin

			let specialistList: string[] = [...new Set(resp.map((item) => item.username))] // creo array di tutti nomi di specialist

			this.graderPerformance = []

			for (let i = 0; i < specialistList.length; i++) {
				const spec = specialistList[i]

				let performance = this.performanceList.filter((el) => el.username == spec)

				let grader = new graders(performance)

				grader.tot_reports = grader.reports.length

				let totGr = 0
				let totLock = 0

				for (let x = 0; x < grader.reports.length; x++) {
					const report = grader.reports[x]

					if (report.grading_time && report.grading_time != '') {
						//questo controllo in quanto i report vecchi non hanno questi parametri
						let gr_time = parseFloat(report.grading_time)
						grader.av_grading_time += gr_time
						totGr++

						let gr_time_h = gr_time / 60
						gr_time_h = gr_time_h / 60
						// percentuale di report entro le 24h e dopo le 48h, dopo lo divido per tot per la percentuale
						if (gr_time_h < 24 && gr_time_h > 0) {
							grader.within_24h++
						} else if (gr_time_h > 48) {
							grader.over_48h++
						}
					}

					if (report.lock_time && report.lock_time != '') {
						grader.av_lock_time += parseFloat(report.lock_time)
						totLock++
					}
				}
				if (grader.av_grading_time > 0) {
					grader.av_grading_time = grader.av_grading_time / totGr //media
					grader.av_grading_time = grader.av_grading_time / 60 //minuti
					grader.av_grading_time = grader.av_grading_time / 60 //ore
					grader.av_grading_time = parseFloat(grader.av_grading_time.toFixed(1))

					let hours = Math.trunc(grader.av_grading_time)
					let minutes = grader.av_grading_time * 60 - 60 * hours

					if (minutes < 9) {
						grader.av_grading_time_s = hours + ':0' + minutes
					} else {
						grader.av_grading_time_s = hours + ':' + minutes
					}

					grader.within_24h = Math.trunc((grader.within_24h / totGr) * 100)
					grader.over_48h = Math.trunc((grader.over_48h / totGr) * 100)
				}

				if (grader.av_lock_time > 0) {
					grader.av_lock_time = Math.trunc(grader.av_lock_time / totLock) //media // levo i decimali
					let minutes = Math.trunc(grader.av_lock_time / 60)
					let seconds = grader.av_lock_time - 60 * minutes

					if (seconds < 9) {
						grader.av_lock_time_s = minutes + ':0' + seconds
					} else {
						grader.av_lock_time_s = minutes + ':' + seconds
					}
				}

				this.graderPerformance.push(grader)
			}

			this.graderPerformance.sort((a, b) => b.tot_reports - a.tot_reports)

			this.graderListPerformance = new MatTableDataSource<graders>(this.graderPerformance)

			this.graderListPerformance.paginator = this.paginator2

			this.buildPerformancePieChart()
		})
	}

	public buildPerformancePieChart(grader?: graders) {
		Util.debug('(statistics) - buildPerformancePieChart ')

		if (!this.statisticForm.get('cadence').pristine) {
			this.buildPerformanceColumnChart(grader)
		}

		let list: graders[] = []

		this.graderPerformance.forEach((el) => (el.isSelected = false))

		let pieChart: pieChartDatas = new pieChartDatas()

		pieChart.labels = ['In time', 'Out of time']
		pieChart.colors = ['#28b32e', '#9b9b9b']
		pieChart.data = []

		if (grader) {
			grader.isSelected = true
			list.push(grader)
			pieChart.title = grader.username + ' - ' + this.buildTitle()
		} else {
			list = this.graderPerformance
			pieChart.title = this.buildTitle()
		}
		const limitH = 172800 // 48h
		let inTime = 0
		let outTime = 0
		let array = []

		for (let i = 0; i < list.length; i++) {
			const grader = list[i]

			for (let x = 0; x < grader.reports.length; x++) {
				const report = grader.reports[x]

				if (report.grading_time && report.grading_time != '') {
					let grTime = parseFloat(report.grading_time)

					if (grTime < limitH && grTime > 0) {
						inTime++
					} else {
						outTime++
					}
				}
			}
		}
		array.push(inTime, outTime)
		pieChart.data = array

		this.performancePieChart = new basicPieGraph(pieChart)

		this.loading = false

		this.performance = true
	}

	private buildPerformanceColumnChart(grader?: graders) {
		let columnDataPlan: ColumnDatas = new ColumnDatas()

		let list: performance[] = []

		if (grader) {
			list = this.performanceList_copy.filter((el) => el.username == grader.username)
			columnDataPlan.title = grader.username + ' - ' + this.buildTitle()
		} else {
			list = this.performanceList_copy
			columnDataPlan.title = this.buildTitle()
		}

		// let filter = this.statisticForm.get('cadence').value

		const fromDate = this.statisticForm.get('fromDate').value
		const toDate = this.statisticForm.get('toDate').value

		let serie = ['report']

		let seriesTot: ColumnSeries[] = createArrays.createSeries(serie)

		let values: serieValues = new serieValues('creation_date', 'dt_week', 'dt_month')
		let chart = this.buildSeriesColumnChart(list, fromDate, toDate, seriesTot, values)

		columnDataPlan.data = chart.series
		columnDataPlan.stacked = false
		columnDataPlan.rotate = true

		columnDataPlan.xaxis = chart.xaxis
		columnDataPlan.xaxisLength = chart.xaxis.length

		this.performanceColumnChart = new ColumnGraph(columnDataPlan)
		this.performanceWithCadence = true
	}

	public showDeletedGraders(event) {
		this.showdeleted = event.target.checked
		this.buildPerformanceGraderList()
	}

	public togglePurchaseThreshold() {
		this.balanceOptList.filter = ''
		this.purchaseThresholdFlag = !this.purchaseThresholdFlag
	}

	private replaceGrouped(list: any[], mainList: any[]): Promise<any[]> {
		const promise = new Promise<any[]>((resolve, reject) => {
			let groupped = mainList.filter((el) => el.user_group_id > 0)

			let groupIds: number[] = [...new Set(groupped.map((item) => item.user_group_id))]

			let promiseArray = []

			// carico tutti i nomi dei clinicAdmin che mi servono
			for (let i = 0; i < groupIds.length; i++) {
				const id = groupIds[i]

				promiseArray.push(
					this.session.loadDistrib(id + '').then((resp) => {
						let graders = list.filter((el) => el.user_group_id == id)

						graders.forEach((el) => (el.username = resp.username))
					})
				)
			}

			Promise.all(promiseArray).then(() => {
				resolve(list)
			})
		})

		return promise
	}

	private groupPendingGradings(list: any[]): Promise<any[]> {
		const promise = new Promise<any[]>((resolve) => {
			let groupped = list.filter((el) => el.grader_id > 0)

			let graderIds: number[] = [...new Set(groupped.map((item) => item.grader_id))]

			let promiseArray = []

			// carico tutti i nomi dei clinicAdmin che mi servono
			graderIds.forEach((id) => {
				promiseArray.push(
					this.session.loadDistrib(id + '').then((resp) => {
						let graders = list.filter((el) => el.grader_id == id)

						graders.forEach((el) => (el.username = resp.username))
					})
				)
			})

			Promise.all(promiseArray).then(() => {
				resolve(list)
			})
		})

		return promise
	}

	public buildGradingsFollowUpTable(grader?) {
		this.gradingsList.data = this.gradingsList.data.map((fGrader) => {
			return {
				...fGrader,
				isOnChart: grader ? fGrader.username === grader.username : false,
			}
		})

		const title = grader ? this.buildTitle().replace('Grader', grader.username) : this.buildTitle()
		const followUpgraph = new basicPieGraph(this.buildCountryGradingsFollowUpPieChart(title, grader || null))

		this.gradingsFollowUpPieChart = followUpgraph
	}

	public resetGraderList() {
		this.mcsList.forEach((e) => (e.isOnChart = false))
		this.buildAiGradersCadence(this.mcsList)
	}

	public buildGraderList(grader?: operatorAi) {
		//riutilizzo la stessa funzione per costruirmi la lista a default e al click, sia per la cadence che per details
		Util.debug('(statistics) - buildGraphs - buildGraderList')

		this.displayedColumns = ['onChart', 'username', 'tot', 'green', 'yellow', 'red']

		this.mcsList.sort((a, b) => b.tot - a.tot)

		if (this.aiReqWithCadence) {
			let array: operatorAi[] = []
			array = this.mcsList
			this.mcsList.forEach((e) => (e.isOnChart = false))

			if (grader) {
				grader.isOnChart = true
				array = []
				array.push(grader)
			}

			this.buildAiGradersCadence(array)
		} else {
			if (grader) {
				let indx = this.mcsList.indexOf(grader)

				// se il grader selezionato é giá nel grafico, quindi true, devo levarlo
				if (grader.isOnChart) {
					this.mcsList[indx].isOnChart = false

					// se il grader selezionato non é giá nel grafico, quindi false, devo aggiungerlo
				} else {
					this.mcsList[indx].isOnChart = true
				}
			} else {
				let nMax = 10 // decidere che numero mettere a default

				if (nMax > this.mcsList.length) {
					nMax = this.mcsList.length
				}
				// mostro nel grafico solo i primi 15
				for (let i = 0; i < nMax; i++) {
					const el = this.mcsList[i]
					el.isOnChart = true
				}
			}
			let onCharList = this.mcsList.filter((el) => el.isOnChart == true)

			this.buildAiGradersColumnChart(onCharList)
		}

		this.graderList = new MatTableDataSource<operatorAi>(this.mcsList)

		this.graderList.paginator = this.paginator
	}

	private buildAiGradersColumnChart(graderList: operatorAi[]) {
		// GRADERS COLUMN CHART
		let ColumnData: ColumnDatas = new ColumnDatas()
		let graders: any[] = [...new Set(graderList.map((item) => item.username))]
		let series = createArrays.createSeries(['green', 'yellow', 'red'])

		let title = this.buildTitle()

		ColumnData.title = 'Global ' + title.replace('- Operator', '')
		ColumnData.stacked = false
		ColumnData.data = series
		ColumnData.xaxis = graders
		ColumnData.xaxisLength = graders.length
		ColumnData.colors = ['#28b32e', '#cccc00', '#ff3b2d']

		for (let i = 0; i < graders.length; i++) {
			const grader = graders[i]

			let filtered = graderList.filter((el) => el.username == grader)

			if (filtered.length > 0) {
				series[0].data.push(filtered[0].green)
				series[1].data.push(filtered[0].yellow)
				series[2].data.push(filtered[0].red)
			}
		}

		this.aiGradersColumnChart = new ColumnGraph(ColumnData)
	}

	public filterText() {
		this.graderList.filter = this.input.nativeElement.value
		this.graderList.filter = this.graderList.filter.trim().toLocaleLowerCase()
		this.graderList.filter = this.graderList.filter
	}

	public filterText2() {
		this.graderListPerformance.filter = this.input2.nativeElement.value
		this.graderListPerformance.filter = this.graderListPerformance.filter.trim().toLocaleLowerCase()
		this.graderListPerformance.filter = this.graderListPerformance.filter
	}

	public filterGradingsText() {
		this.gradingsList.filter = this.gradingsInput.nativeElement.value
		this.gradingsList.filter = this.gradingsList.filter.trim().toLocaleLowerCase()
		this.gradingsList.filter = this.gradingsList.filter
	}

	public filterOptText() {
		this.balanceOptList.filter = this.balOptFilter.nativeElement.value
		this.balanceOptList.filter = this.balanceOptList.filter.trim()
		this.balanceOptList.filter = this.balanceOptList.filter
	}

	public exportTable(tableSource: MatTableDataSource<any>) {
		this.loadCSV = true

		let filterList = tableSource.filteredData.slice()
		let exportFilterList: toCSV[]

		exportFilterList = []

		if (this.statisticForm.get('option').value === Statistics.purchase_h) {
			filterList = filterList.map((obj) => {
				const { purchaseList: _, ...rest } = obj
				return rest
			})
		}

		for (let i = 0; i < filterList.length; i++) {
			const act = Util.flattenObject(filterList[i])

			exportFilterList.push(act)
		}

		let tmp = Util.convertToCSV(exportFilterList)

		let strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)
		var filename = 'export' + '_' + this.statisticForm.get('option').value + '_' + Date.now() + '.csv'

		let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })

		setTimeout(() => {
			Util.mySaveAs(filename, blob)
			this.loadCSV = false
		}, 600)
	}

	public aiColumnChartGlobal() {
		// spostata su funzione perché posso richiamarla al click per cambiare la lista di partenza mcs o dss
		Util.debug('(statistics) - buildGraphs - AI Reports aiColumnChartGlobal')

		let ColumnData: ColumnDatas = new ColumnDatas()

		let title = this.buildTitle()
		title = title.replace('- Operator', '')

		let countries: any[] = [...new Set(this.totalReportDone.map((item) => item.country))]

		let series = createArrays.createSeries(['green', 'yellow', 'red'])

		ColumnData.title = 'Graders - ' + title
		ColumnData.stacked = false
		ColumnData.data = series
		ColumnData.xaxis = countries
		ColumnData.xaxisLength = countries.length
		ColumnData.colors = ['#28b32e', '#cccc00', '#ff3b2d']

		for (let i = 0; i < countries.length; i++) {
			const country = countries[i]

			let filtered = []

			if (this.seeDss) {
				filtered = this.dssList.filter((el) => el.country == country) // solo mcs
			} else {
				filtered = this.mcsList.filter((el) => el.country == country) // solo mcs
			}

			if (filtered.length > 0) {
				let green = filtered.reduce((summ, object) => {
					// reduce, somma lo stesso field dell'array ricevuto senza usare un ciclo for
					return summ + object.green
				}, 0)
				let yellow = filtered.reduce((summ, object) => {
					return summ + object.yellow
				}, 0)
				let red = filtered.reduce((summ, object) => {
					return summ + object.red
				}, 0)

				// for (let x = 0; x < filtered.length; x++) {
				// 	const el = filtered[x]

				// 	green = green + el.green
				// 	yellow = yellow + el.yellow
				// 	red = red + el.red
				// }
				series[0].data.push(green)
				series[1].data.push(yellow)
				series[2].data.push(red)
			}
		}

		this.globalAIcolumnChart = new ColumnGraph(ColumnData)

		this.aiReqWithCountry = true
	}

	private buildAiGradersCadence(graderList: operatorAi[]) {
		const fromDate = this.statisticForm.get('fromDate').value
		const toDate = this.statisticForm.get('toDate').value

		let aiReportList: aiReportDone[] = []
		//creo la lista report AI
		for (let i = 0; i < graderList.length; i++) {
			const reports = graderList[i].reports

			for (let n = 0; n < reports.length; n++) {
				const rep = reports[n]
				aiReportList.push(rep)
			}
		}

		let seriesTot = createArrays.createSeries(['green', 'yellow', 'red'], true)
		let columnDataPlan: ColumnDatas = new ColumnDatas()

		let title = this.buildTitle()
		title = title.replace('- Operator', '')

		if (graderList.length == 1) {
			title = graderList[0].username + ' - ' + title
		}
		let values: serieValues = new serieValues('received', 'dt_week', 'dt_month')
		let chart = this.buildSeriesColumnChart(aiReportList, fromDate, toDate, seriesTot, values, 'traffic_light')

		columnDataPlan.title = title
		columnDataPlan.data = chart.series
		columnDataPlan.stacked = true
		columnDataPlan.rotate = true
		columnDataPlan.colors = ['#28b32e', '#cccc00', '#ff3b2d']

		columnDataPlan.xaxis = chart.xaxis
		columnDataPlan.xaxisLength = chart.xaxis.length

		this.aiGradersColumnChart = new ColumnGraph(columnDataPlan)
		this.aiGradersColumnChart.stroke.width = Array(seriesTot.length).fill(0)
		this.aiGradersColumnChart.stroke.width[seriesTot.length - 1] = 4
		this.aiGradersColumnChart.dataLabels.enabledOnSeries = [seriesTot.length - 1]
		this.aiGradersColumnChart.colors.push('#717ace')
	}

	private buildSeriesColumnChart(
		array,
		fromDate: moment.Moment,
		toDate: moment.Moment,
		seriesTot: ColumnSeries[],
		values: serieValues,
		serieValue?: string
	): { series: ColumnSeries[]; xaxis: string[] } {
		const filter = this.statisticForm.get('cadence').value
		let xaxis: string[]

		switch (filter) {
			// DAY CADENCE
			case Statistics.day:
				xaxis = createArrays.createDaysArray(fromDate, this.diffDays)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						// console.log(val)
						let record = []

						if (serieValue) {
							record = array.filter((el) => moment.utc(new Date(el[values.day])).format('DD-MM-YYYY') == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => moment.utc(new Date(el[values.day])).format('DD-MM-YYYY') == val)
						}
						if (serie.name === 'Total') {
							record = array.filter((el) => moment.utc(new Date(el[values.day])).format('DD-MM-YYYY') == val)
						}
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break
			// WEEKLY CADENCE
			case Statistics.weekly:
				xaxis = createArrays.createWeekArray(fromDate, toDate) //array di tutte le settimane comprese nell'intervallo, che sará l'asse delle x

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						let record = []

						if (serieValue) {
							record = array.filter((el) => el[values.week] == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => el[values.week] == val)
						}
						if (serie.name === 'Total') {
							record = array.filter((el) => el[values.week] == val)
						}
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break

			// MONTHLY CADENCE
			case Statistics.monthly:
				xaxis = createArrays.createMonthlyArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						let record = []
						if (serieValue) {
							record = array.filter((el) => el[values.month] == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => el[values.month] == val)
						}

						if (serie.name === 'Total') {
							record = array.filter((el) => el[values.month] == val)
						}
						// console.log(record.length)
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break

			// YEARLY CADENCE
			case Statistics.yearly:
				xaxis = createArrays.createYearArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						let record = []

						if (serieValue) {
							record = array.filter((el) => moment.utc(new Date(el[values.year])).year() + '' == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => moment.utc(new Date(el[values.year])).year() + '' == val)
						}
						if (serie.name === 'Total') {
							record = array.filter((el) => moment.utc(new Date(el[values.year])).year() + '' == val)
						}
						// console.log(record.length)
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break
		}

		return { series: seriesTot, xaxis: xaxis }
	}

	private buildSeriesColumnChartWithTotValues(
		array,
		fromDate: moment.Moment,
		toDate: moment.Moment,
		seriesTot: ColumnSeries[],
		serieValue?: string
	): { series: ColumnSeries[]; xaxis: string[] } {
		const filter = this.statisticForm.get('cadence').value
		let xaxis: string[]

		switch (filter) {
			// DAY CADENCE
			case Statistics.day:
				xaxis = createArrays.createDaysArray(fromDate, this.diffDays)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]
						serie.data[i] = 0

						array.forEach((el) => {
							if (serieValue) {
								if (moment(el.by_day).format('DD-MM-YYYY') == val && el[serieValue] == serie.name) {
									serie.data[i] += el.tot
								}
							} else {
								if (moment(el.by_day).format('DD-MM-YYYY') == val) {
									serie.data[i] += el.tot
								}
							}

							if (serie.name === 'Total') {
								if (moment(el.by_day).format('DD-MM-YYYY') == val) {
									serie.data[i] += el.tot
								}
							}
						})
					}
				}

				break
			// WEEKLY CADENCE
			case Statistics.weekly:
				xaxis = createArrays.createWeekArray(fromDate, toDate) //array di tutte le settimane comprese nell'intervallo, che sará l'asse delle x

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]
						serie.data[i] = 0

						array.forEach((el) => {
							if (serieValue) {
								if (el.by_week == val && el[serieValue] == serie.name) {
									serie.data[i] += el.tot
								}
							} else {
								if (el.by_week == val) {
									serie.data[i] += el.tot
								}
							}

							if (serie.name === 'Total') {
								if (el.by_week == val) {
									serie.data[i] += el.tot
								}
							}
						})
					}
				}

				break

			// MONTHLY CADENCE
			case Statistics.monthly:
				xaxis = createArrays.createMonthlyArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						serie.data[i] = 0

						array.forEach((el) => {
							if (serieValue) {
								if (el.by_month == val && el[serieValue] == serie.name) {
									serie.data[i] += el.tot
								}
							} else {
								if (el.by_month == val) {
									serie.data[i] += el.tot
								}
							}

							if (serie.name === 'Total') {
								if (el.by_month == val) {
									serie.data[i] += el.tot
								}
							}
						})
					}
				}

				break

			// YEARLY CADENCE
			case Statistics.yearly:
				xaxis = createArrays.createYearArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]
						serie.data[i] = 0

						array.forEach((el) => {
							if (serieValue) {
								if (el.by_year == val && el[serieValue] == serie.name) {
									serie.data[i] += el.tot
								}
							} else {
								if (el.by_year == val) {
									serie.data[i] += el.tot
								}
							}

							if (serie.name === 'Total') {
								if (el.by_year == val) {
									serie.data[i] += el.tot
								}
							}
						})
					}
				}
				break
		}

		return { series: seriesTot, xaxis: xaxis }
	}

	private buildSeriesColumnConversionChart(
		array,
		fromDate: moment.Moment,
		toDate: moment.Moment,
		seriesTot: ColumnSeries[],
		isAi = false
	): { series: ColumnSeries[]; xaxis: string[] } {
		const filter = this.statisticForm.get('cadence').pristine ? 'yearly' : this.statisticForm.get('cadence').value
		let xaxis: string[]

		switch (filter) {
			// DAY CADENCE
			case Statistics.day:
				xaxis = createArrays.createDaysArray(fromDate, this.diffDays)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]
						serie.data[i] = 0

						array.forEach((el) => {
							if (moment(el.by_day).format('DD-MM-YYYY') == val) {
								if (!isAi) {
									if (serie.name === 'Patients examined') {
										serie.data[i] += el.tot
									} else if (serie.name === 'Grading request' && el.to_HG === 'Y') {
										serie.data[i] += el.tot
									}
								} else {
									if (el.ai_reviewed === 'Y') {
										if (serie.name === 'AI reviewed') {
											serie.data[i] += el.tot
										} else if (serie.name === 'HG reviewed' && el.to_HG === 'Y') {
											serie.data[i] += el.tot
										}
									}

									if (serie.name === 'Total patients') {
										serie.data[i] += el.tot
									}
								}
							}
						})
					}
				}

				break
			// WEEKLY CADENCE
			case Statistics.weekly:
				xaxis = createArrays.createWeekArray(fromDate, toDate) //array di tutte le settimane comprese nell'intervallo, che sará l'asse delle x

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]
						serie.data[i] = 0

						array.forEach((el) => {
							if (el.by_week == val) {
								if (!isAi) {
									if (serie.name === 'Patients examined') {
										serie.data[i] += el.tot
									} else if (serie.name === 'Grading request' && el.to_HG === 'Y') {
										serie.data[i] += el.tot
									}
								} else {
									if (el.ai_reviewed === 'Y') {
										if (serie.name === 'AI reviewed') {
											serie.data[i] += el.tot
										} else if (serie.name === 'HG reviewed' && el.to_HG === 'Y') {
											serie.data[i] += el.tot
										}
									}

									if (serie.name === 'Total patients') {
										serie.data[i] += el.tot
									}
								}
							}
						})
					}
				}

				break

			// MONTHLY CADENCE
			case Statistics.monthly:
				xaxis = createArrays.createMonthlyArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						serie.data[i] = 0

						array.forEach((el) => {
							if (el.by_month == val) {
								if (!isAi) {
									if (serie.name === 'Patients examined') {
										serie.data[i] += el.tot
									} else if (serie.name === 'Grading request' && el.to_HG === 'Y') {
										serie.data[i] += el.tot
									}
								} else {
									if (el.ai_reviewed === 'Y') {
										if (serie.name === 'AI reviewed') {
											serie.data[i] += el.tot
										} else if (serie.name === 'HG reviewed' && el.to_HG === 'Y') {
											serie.data[i] += el.tot
										}
									}

									if (serie.name === 'Total patients') {
										serie.data[i] += el.tot
									}
								}
							}
						})
					}
				}

				break

			// YEARLY CADENCE
			case Statistics.yearly:
				xaxis = createArrays.createYearArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]
						serie.data[i] = 0

						array.forEach((el) => {
							if (el.by_year == val) {
								if (!isAi) {
									if (serie.name === 'Patients examined') {
										serie.data[i] += el.tot
									} else if (serie.name === 'Grading request' && el.to_HG === 'Y') {
										serie.data[i] += el.tot
									}
								} else {
									if (el.ai_reviewed === 'Y') {
										if (serie.name === 'AI reviewed') {
											serie.data[i] += el.tot
										} else if (serie.name === 'HG reviewed' && el.to_HG === 'Y') {
											serie.data[i] += el.tot
										}
									}

									if (serie.name === 'Total patients') {
										serie.data[i] += el.tot
									}
								}
							}
						})
					}
				}
				break
		}

		return { series: seriesTot, xaxis: xaxis }
	}

	public deletecharts(event) {
		this.keepOldChart = event.target.checked
	}

	//remove functions specifica per ogni tipo di grafico
	public removePieGraph(index) {
		this.pieGraphs.splice(index, 1)
	}

	public removeColumnGraphs(index) {
		this.ColumnGraphs.splice(index, 1)
	}

	public removeColumnGraphsBig(index) {
		this.ColumnGraphsBig.splice(index, 1)
	}

	// ########## TRENDS ENDS #######

	// 13.07.2021 richiamata solo sul tab users
	countValidTok() {
		// 22.06.2021
		this.session
			.queryStatsTok()
			.then((ris) => {
				Util.debug('(countValidTok) ok')
				//console.log(ris);
				let tot = 0
				if (ris && ris.totTok) {
					tot = ris.totTok
				}
				this.myTokInfo = 'signed-in users now: ' + tot
				//this.myTokInfo = "active users now: "+tot;
			})
			.catch((err) => {
				let msg = err.data ? err.data.error : err.toString()
				console.log('(stats.count_tokens) 2- KO ' + msg)
				console.log(err)
				this.myTokInfo = msg
			})
	}

	// 20.09.2021 ritorna la lista di utenti loggati
	getLoggedUsers() {
		// se pochi, li stampa a video
		// se tanti, fa un download ? TODO
		this.loggedUsers = []
		//console.log("(stats - loggedUsers - inizio)");

		this.session
			.queryStatsLoggedUsers()
			.then((ris) => {
				//console.log("(stats - loggedUsers):");
				//console.log(ris);

				let list = []
				if (ris && ris.loggedUsers) {
					list = ris.loggedUsers
					if (list != null) {
						// let tot = list.length
						// if (tot <= 15) {
						// 	// TODO valutare soglia
						this.loggedUsers = list
						// } else {
						// 	// TOO many...
						// 	let myObj = { id: 0, username: 'Too many to list, TODO download list' }
						// 	this.loggedUsers = [myObj]
						// }
					}
				}
			})
			.catch((err) => {
				let msg = err.data ? err.data.error : err.toString()
				console.log('(stats.loggedUsers) 2- KO ' + msg)
				console.log(err)
			})
	}

	// richiamata dal bottone di count su tab images
	countImages(form?) {
		this.stat_type = 'count_images'
		this.currentAction = 'images'

		this.okZip = false // sto ripartendo con una nuova ricerca
		this.zipContentList = []
		this.msgTot = ''
		this.statusMsg = '' // relativo allo zip

		this.submitImages()
	}

	getImages(form?) {
		this.currentAction = 'images'

		// TODO decidere la soglia
		if (this.gotCount > this.MAX_NUM_IMG) {
			// 27.10.2021 fix, se si clicca piu' volte, accoda troppo
			//this.msgTot += ", zip too big to be downloaded, please reduce the query";
			this.msgTot = ' ' + this.gotCount + ' exams, zip too big to be downloaded, please reduce the query'

			alert('zip too big, reduce the query result to less than ' + this.MAX_NUM_IMG + ' records, or download them encrypted.')
			return
		}

		this.stat_type = 'images'

		this.showLoader = true
		this.statusMsg = 'Decrypting Images'

		this.btnGetImgDisabled = true // disabilito x non far partire 2 volte la query x errore di doppio click

		this.submitImages()
	}

	/*
    // 05.07.2022
    private mySaveAs(filename, myBlob){
      Util.mySaveAs(filename, myBlob);   // 02.08.2022 centralizzata
    }
    */

	// validare il range di date non serve in quanto lo fa giá il picker material
	// private validateRangeDate(flagRequired?) {
	// 	/* TEMP TODO

	//   if(flagRequired){
	//     if(this.fromDay == null || this.fromDay == '' ||
	//       this.toDay == null || this.toDay == ''){
	//       this.errMsg = "Please use YYYY-MM-DD on both fields";   // msg di errore
	//       console.log(this.errMsg);
	//       alert(this.errMsg);   // TODO, mostrarlo su pg html ma toglierlo su touch del field
	//       return false;
	//     }
	//   }

	//   // controlli validita' date, DA MIGLIORARE!
	//   if(!this.isValidDate(this.fromDay) || !this.isValidDate(this.toDay)){
	//       this.errMsg = "Date format not valid, please use YYYY-MM-DD";   // msg di errore
	//       console.log(this.errMsg);
	//       alert(this.errMsg);   // TODO, mostrarlo su pg html ma toglierlo su touch del field
	//       return false;
	//   }
	//   */

	// 	// 02.11.2020 controllo intervallo
	// 	if (this.fromDay > this.toDay) {
	// 		this.errMsg = 'Invalid interval, fromDay should be before toDay'
	// 		console.log(this.errMsg)
	// 		alert(this.errMsg)
	// 		return false
	// 	}

	// 	return true
	// }

	// 09.03.2021
	exportUsers() {
		let myType = null

		if (this.usrOption) {
			if (
				this.usrOption != Config.PR_SPECIALIST &&
				this.usrOption != Config.PR_DISTRIB &&
				this.usrOption != Config.PR_OPTICIAN &&
				this.usrOption != Config.PR_DOCTOR &&
				this.usrOption != Config.ALL_LEV1
			) {
				// tutti, 30.08.2022

				myType = null // non riconosciuto, ignoro
			} else {
				myType = this.usrOption
			}
		}

		//console.log("(exportUsers) doExport, type: "+this.usrOption);

		// 28.07.2022 esteso anche ai livelli 1
		if (this.usrOption == Config.PR_SPECIALIST || this.usrOption == Config.PR_DISTRIB) {
			Util.debug('(exportUsers) lev2, type: ' + this.usrOption)

			this.getLev2ListToExport(myType).then((strBuffer) => {
				this.saveBuffAsCsv(strBuffer, myType)
				this.btnCountDisabled = false // per prox
			})
		} else if (this.usrOption == Config.PR_OPTICIAN || this.usrOption == Config.PR_DOCTOR || this.usrOption == Config.ALL_LEV1) {
			Util.debug('(exportUsers) lev1, type: ' + this.usrOption)

			this.getLev1ListToExport(myType).then((strBuffer) => {
				this.saveBuffAsCsv(strBuffer, myType, this.toDay)
				this.btnCountDisabled = false // per prox
			})
		} else {
			Util.debug('(exportUsers) other, type: ' + this.usrOption)
		}
	}

	// 28.07.2022
	private getLev2ListToExport(myType: string): Promise<string> {
		let distribList = []
		let ignoreCritt = false // per avere anche email e country
		let strBuffer = ''

		return this.session.loadRemotes(ignoreCritt).then(() => {
			distribList = this.session.getDtDistribList()
			let len = distribList.length
			console.log('(exportUsers) distrib list full, len: ' + len)
			strBuffer += Distrib.getCsvTitle('anagr') + '\r\n' // CRLF non c'e'
			for (let i = 0; i < len; i++) {
				let myDist = distribList[i]
				if ((!myType || myType == myDist.user_type) && !myDist.isDeleted()) {
					// && !myDist.isTest()
					let line = myDist.getCsvLine('anagr')
					strBuffer += line + '\r\n' // CRLF non c'e'
				}
			}
			return strBuffer
		})
	}

	// 28.07.2022
	private getLev1ListToExport(myType: string): Promise<string> {
		let myList: Doctor[]

		let ignoreCritt = true
		let mode = 'credits' // 03.10.2022 aggiunto saldo crediti
		let toDate = Util.ngbToString(this.toDay)
		Util.debug('(getLev1ToExport) to day: ' + toDate)

		return this.session.loadDoctors(ignoreCritt, mode, toDate).then(() => {
			myList = this.session.getDtDoctorList()
			console.log('(exportUsers) list decrypted, type: ' + myType)

			let len = myList.length
			let strBuffer = ''
			strBuffer += CoreUser.getCsvTitle('credits') + '\r\n' // CRLF non c'e'
			for (let i = 0; i < len; i++) {
				let myUser = myList[i]
				// 24.08.2022 esteso con type lev1, all
				if ((!myType || myType == Config.ALL_LEV1 || myType == myUser.user_type) && !myUser.isDeleted()) {
					let line = myUser.getCsvLine('credits')
					strBuffer += line + '\r\n' // CRLF non c'e'
				}
			}
			return strBuffer
		})
	}

	private saveBuffAsCsv(strBuffer, myType, myDate?) {
		// 05.10.2022 if export credits with toDay filter, use this date [ls]
		let myDt = ''
		if (myDate) {
			myDt = Util.ngbToString(this.toDay)
			//Util.debug('(saveBuffAsCsv) ' + this.toDay + ' dt: ' + myDt);
		} else {
			myDate = new Date()
			myDt = DateParser.formatSqlDate(myDate)
		}

		let filename = 'users' + '_' + myType + '_' + myDt + '.csv'
		let blob = new Blob([strBuffer], { type: 'text/plain;charset=utf-8' })
		//FileSaver.saveAs(blob, filename); // TEMP TODO
		Util.mySaveAs(filename, blob) // 28.07.2022
	}

	// 07.07.2021
	exportActivity() {
		// activityDays
		console.log('(exportActivity) days: ' + this.activityDays)

		this.errMsg = '' // tutto ok

		return this.session
			.getStatisticsActivity(this.activityDays, this.activeType)
			.then((myResponse) => {
				if (myResponse != null) {
					console.log('(exportActivity) got :')
					// console.log(myResponse)

					// array
					let len = 0
					let lines = []

					lines = myResponse
					len = lines.length

					if (len == 0) {
						alert('No record found with filter [' + this.activityDays + ']')
					} else {
						// download dei record su zip file
						//alert("(exportActivity) OK "+len);

						let strBuffer = ''
						strBuffer += CsvLine.getTitle(this.activeType) + '\r\n' // CRLF non c'e'

						for (let i = 0; i < len; i++) {
							let line = new CsvLine(lines[i], this.activeType)
							line.notes = 'in the last ' + this.activityDays + ' days' // TODO, migliorare!
							strBuffer += line.getCsvLine() + '\r\n' // CRLF non c'e'
						}

						let filename = 'activity_' + this.activeType + '_days_' + this.activityDays + '.csv'
						let blob = new Blob([strBuffer], { type: 'text/plain;charset=utf-8' })
						//FileSaver.saveAs(blob, filename);   //TEMP TODO

						Util.mySaveAs(filename, blob) // 28.07.2022
						this.btnCountDisabled = false
					}
					this.btnCountDisabled = false // at the end, re-enable
				}
			})
			.catch((err) => {
				let msg = err.data ? err.data.error : err.toString()
				console.log('(elabExamsCount) - KO ' + msg)
				console.log(err)
				alert('Error: ' + msg)
				this.btnCountDisabled = false // at the end, re-enable
			})
	}

	// 14.06.2021 filtra i log del server con id utente
	private elabServerLog() {
		// controllo userId o username obbligatori (in automatico come REQUIRED ? )
		// (todo filtro su date/periodo - non ora )
		let filtro = (this.userId + this.username).trim()
		if (filtro == '') {
			alert('Please provide a userId or username to filter server log.')
			this.btnCountDisabled = false // 28.07.2021 fix
			return false
		}

		let now = new Date()
		let szDate = this.session.formatDateAs(now, 'yyyyMMdd') // 09.08.2022

		this.btnCountDisabled = true
		return this.session
			.getServerLog(this.userId, this.username)
			.then((srvLog) => {
				//Util.debug("(elabServerLog) resp:");
				//console.log(srvLog);

				if (srvLog != null) {
					let len = 0
					let logLines = srvLog.lines // array
					if (logLines != null) {
						len = logLines.length
						console.log('(elabServerLog) got ' + len + ' rows.') // ok
						//console.log(srvLog);
					}

					if (len == 0) {
						alert('No record found with filter [' + this.userId + ',' + this.username + ']')
						this.btnCountDisabled = false // 25.10.2021 fix
					} else {
						// download dei record su zip file

						let strBuffer = ''
						let usrFilter = 'usr' // +this.userId;

						// 03.03.2022 se filtrato con username, avrei id vuoto
						if (this.userId != null && this.userId.length > 0) {
							usrFilter += this.userId
						} else {
							usrFilter += this.username
						}

						for (let i = 0; i < len; i++) {
							strBuffer += logLines[i] // CRLF  c'e' gia'
						}

						// 14.06.2021 per ora scarichiamo non compresso
						/*
            let zip = new JSZip();
            let filename = "srvLog_"+usrFilter+".log";   
            zip.file(filename, strBuffer);  
            let date = this.filter('date')(this.today, "yyyyMMdd");       
            
            zip.generateAsync({type:"blob"})
            .then((content) => {                               
                let zipName = date +"_"+usrFilter+"_serverLog.zip";
                FileSaver.saveAs(content, zipName); 
            });  
            */

						let filename = szDate + '_srvLog_' + usrFilter + '.log'
						let blob = new Blob([strBuffer], { type: 'text/plain;charset=utf-8' })

						// FileSaver.saveAs(blob, filename);  //TEMP TODO
						//this.mySaveAs(filename, blob); // 28.07.2022
						Util.mySaveAs(filename, blob) // 09.08.2022

						this.btnCountDisabled = false // 07.07.2021 fix
					}
				} else {
					Util.debug('(elabServerLog) null resp!')
				}
			})
			.catch((err) => {
				let msg = err.data ? err.data.error : err.toString()
				console.log('(elabServerLog) - KO ' + msg)
				console.log(err)

				// 20.07.2022
				msg = this.session.parseErrorMessage(err, 'alert')

				alert('Error: ' + msg)
				this.btnCountDisabled = false // 07.07.2021 fix
			})
	}

	// 17.11.2022
	private elabDbLog() {
		//alert("(elabDBlog) not available yet");

		let userId = 0
		if (this.dbLogType == 'user') {
			let filtroUser = this.userId.trim()
			if (filtroUser == '') {
				alert('(DB) Please provide a userId to filter DB log.')
				this.btnCountDisabled = false
				return false
			} else {
				userId = parseInt(this.userId)
			}
		}

		this.btnCountDisabled = true
		return this.session
			.getDbLog(this.dbLogType, this.dbLogRange, userId)
			.then((resp) => {
				let logRecords = []
				if (resp != null) {
					let len = 0
					logRecords = resp.logs // array
					if (logRecords != null) {
						len = logRecords.length
						console.log('(elabDBLog) got ' + len + ' rows.') // ok
						//console.log(srvLog);
					}
					if (len == 0) {
						alert('(DB logs) No record found with filter [' + this.userId + ']')
						this.btnCountDisabled = false
					} else {
						let strBuffer = ''
						let tmp = Util.convertToCSV(logRecords)
						strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)
						let info = 'DB_' + this.dbLogType // andra' nel filename
						if (this.dbLogType == 'user') {
							info += '_' + this.userId
						}
						this.saveBuffAsCsv(strBuffer, info)
						this.btnCountDisabled = false // per prox
					}
				} else {
					alert('(DB logs) No record found')
					this.btnCountDisabled = false
				}
			})
			.catch((err) => {
				if (!this.session.isExpired(err)) {
					console.log(err)
				}
			})
	}

	// 14.07.2021 unica chiamata per count e getImg, differenzio solo elab risposta
	elabStatImages() {
		if (this.examTypeFilter == '') {
			alert('Please choose an exam type')
			this.btnCountDisabled = false // 28.07.2021 fix
			return
		}

		console.log('(elabStatImages) - eye ' + this.eye + ' exam: ' + this.examTypeFilter)
		//alert("TODO");

		// 05.10.2022 TODO, mancano su html e su api
		let szFromDay = '' // Util.ngbToString(this.fromDay);
		let szToDay = '' // Util.ngbToString(this.toDay);
		//let szFromDay = DateParser.formatSqlDateFromUTC(this.fromDayDt);
		//let szToDay = DateParser.formatSqlDateFromUTC(this.toDayDt);

		let constrains = {}
		constrains['stat_type'] = this.stat_type // "count_images" o "images"
		constrains['exam_type'] = this.examTypeFilter
		constrains['eye'] = this.eye
		constrains['device'] = this.device
		constrains['fromDay'] = szFromDay
		constrains['toDay'] = szToDay
		constrains['operId'] = this.operId // 09.09.2021

		return this.session
			.getStatsImages(constrains)
			.then((myResponse) => {
				if (myResponse != null) {
					//console.log(myResponse);   // SOLO PER TEST

					if (this.stat_type == 'count_images') {
						console.log('(elabStatImages) going to count images')
						return this.elabImagesCount(myResponse)
					} else if (this.stat_type == 'images') {
						//console.log("(elabStatImages) images");

						//this.statsRec = myResponse; // record-esami con immagini gia' decrittate da session
						this.statsRec = myResponse[0] // record-esami con immagini gia' decrittate da session
						let imgTags = myResponse[1] // i nomi dei campi immagine

						if (this.statsRec != null) {
							console.log('(elabStatImages)  going to download images: ' + this.statsRec.length)
							this.zipImages(imgTags) // prepara zip per download
						} else {
							console.log('(elabStatImages) null decrypted images! ')
						}
					}
				}
			})
			.catch((err) => {
				let msg = err.data ? err.data.error : err.toString()
				console.log('(elabStatImages) - KO ' + msg)
				console.log(err)
				alert(msg)
				this.btnCountDisabled = false

				this.showLoader = false // 09.09.2021
				this.statusMsg = ''
			})
	}

	// 13.07.2021
	elabImagesCount(myResponse) {
		if (myResponse != null) {
			let tot = myResponse.stats
			console.log('(elabImagesCount) got :' + tot)
			this.gotCount = Number(tot)

			//this.msgTot = " "+ this.gotCount +" images";
			this.msgTot = ' ' + this.gotCount + ' exams' // un esame fundus puo' avere 7 immagini

			//this.msgTot = tot;
		} else {
			this.gotCount = 0
			this.msgTot = '' + myResponse // msg di errore ?!
			console.log('(elabImagesCount) ERR :' + this.msgTot)
		}

		this.btnCountDisabled = false

		// 09.08.2022 TEMP TODO, importare lo zip
		/*
    if(this.gotCount > 0){ // abilito bottone per proseguire con getImgs
      this.btnGetImgDisabled = false;
    }
    */
	}

	// lavora sulla globale images, ricevuta dalla session.
	// abbiamo un array con elementi ExamImage:
	/* ExamImage {
                photo_id: 105,                                                          
                image: "OnBDWLXKiljaK[...]"
              }
  */
	zipImages(imgTags) {
		//let zip = new JSZip(); // TEMP TODO

		if (!this.statsRec || this.statsRec.length == 0) {
			console.log('(zipImages) ko immagini ')
			alert('KO, no images') // 02.09.2019
			this.btnCountDisabled = false // 28.07.2021 fix
			return false
		} else {
			// 27.10.2021 solo per test
			console.log('(zipImages) working on : ' + this.statsRec.length)
		}

		// 27.10.2021 spostato qui, fuori dal ciclo for
		let imgNames: string[]

		// le immagini possono essere su campi di nome diverso ,non solo "image"
		if (imgTags == null) {
			console.log('(zipImages) NULL tags!')
			imgNames = []
			imgNames.push('image')
		} else {
			console.log('(zipImages) tags ' + imgTags)
			imgNames = imgTags.split(',')
		}

		//this.msgTot += ", start to zip...";
		//console.log("(zipImages) inizio "+this.images.length);  // per test
		//console.log("(zipImages) loaded jsZip version ", zip.version); // undefined

		let i = 0
		let iOff = 0
		//let totZip = 0;

		while (i < this.statsRec.length) {
			//zip = new JSZip();  // TEMP TODO
			let filename = ''
			let fileExtension = ''

			for (i = iOff; i < this.MAX_NUM_IN_ZIP + iOff && i < this.statsRec.length; i++) {
				let currExam = this.statsRec[i]

				// gestione piu' immagini su stesso esame_occhio
				for (let m = 0; m < imgNames.length; m++) {
					let myImgName = imgNames[m].trim()

					let imgStream = ''
					let img = ''
					filename = '' // "test.jpg";

					if (currExam[myImgName] == null) {
						//console.log("(zipImages) "+i+" ko img "+myImgName+" exam_id: "+currExam.id);
						continue // salta questa e riparte con quella dopo
					} else {
						imgStream = currExam[myImgName] // funziona come se fosse currExam.image
						// 27.10.2021 solo per test
						//console.log("(zipImages) "+i+" ok img "+myImgName+" exam_id: "+currExam.id);
					}

					// CryptoUtilsService.JPEG_BASE64

					let isJpeg = imgStream.indexOf('data:image/jpeg;base64,') == 0

					if (isJpeg) {
						// le fundus, retro,
						img = imgStream.substring(23) //  23 = len("data:image/jpeg;base64,")
						fileExtension = '.jpg'
					} else if (imgStream.indexOf('data:image/png;base64,') == 0) {
						// le dryeye ?
						img = imgStream.substring(22) // toglie "data:image/png;base64,"  in testa
						fileExtension = '.png'
					} else {
						let beg = imgStream.substring(0, 25) // 04.04.2022 moved from substr to substring
						console.log('(zipImages) unrecognized image format: ' + beg)
						img = imgStream
						fileExtension = '.image'
					}

					filename += currExam.exam_type

					if (currExam.id) {
						filename += '_' + currExam.id
					} else {
						// 28.10.2021 fix, altrimenti manca univocita'
						filename += '_n_' + i
					}

					filename += '_' + myImgName
					if (currExam.eye) {
						filename += '_' + currExam.eye
					}

					// 10.06.2021 aggiungo created_by, id utente doctor
					if (currExam.created_by != null) {
						filename += '_usr_' + currExam.created_by
					}

					filename = filename + fileExtension
					//zip.file(filename, img, {base64: true});  // TEMP TODO
					//console.log("(zipImages) added "+filename);  // ok
				}
			} // chiude for su singolo zip

			console.log('(zipImages) loop ended, Generating async Zip... ')

			iOff += this.MAX_NUM_IN_ZIP

			this.statusMsg = 'Generating Zip'

			/*zip.generateAsync({type:"blob"})  // TEMP TODO
      .then((content) => {
      //.then(function(content) {
          this.btnDownloadDisabled = false;
          this.showLoader = false;
          this.statusMsg = "Complete";

          // 28.10.2021 solo per trace             
          //console.log("(generateAsync) done!");
          //let totFiles = 1;
          //zip.forEach(function (relativePath, zipEntry) {  // 2) print entries
          //  console.log("(generateAsync) "+totFiles+" - "+zipEntry.name);
          //  totFiles++;            
          //});
          
          this.zipContentList.push(content);  // per spezzare zip in piu' parti                    
          //this.rootScope.$apply();   // TEMP TODO           
      }); 
      */
		} // chiude while sugli zip

		this.okZip = true
		this.btnCountDisabled = false
	}

	// 15.07.2021 // con zip multipli
	downloadImagesZip(numZip) {
		let today = new Date()

		let date = '' // TEMP TODO  //this.filter('date')(today, "dd-MM-yyyy");

		let zipName = date + '_statistics_' + numZip + '.zip'
		let pos = numZip - 1 // posizione su array parte da zero
		if (pos >= 0 && pos < this.zipContentList.length) {
			//FileSaver.saveAs(this.zipContentList[pos], zipName); // TEMP TODO
		} else {
			console.log('(downloadImagesZip) KO, richiesto: ' + numZip + ' max: ' + this.zipContentList.length)
		}
	}

	// 19.01.2022
	getLastHgReport() {
		//console.log("(getLastHgReport) inizio ");
		this.session
			.getLastReport('HG')
			.then((ris) => {
				//console.log("(getLastHgReport) ok");
				//console.log(ris);  // 02.09.2022 tolta trace
				//this.lastReport = new Report(ris.data.report);
				this.lastReport = new Report(ris.report)
			})
			.catch((err) => {
				let msg = err.data ? err.data.error : err.toString()
				console.log('(getLastHgReport) 2- KO ' + msg)
				console.log(err)
			})
	}

	// 13.03.2018
	isValidDate(myStrDate: string) {
		//let sqlDtFormat = "YYYY-MM-DD"; // trovare il modo di passarlo ?
		if (myStrDate.length != 10) {
			// vogliamo formato YYYY-MM-DD
			return false
		}

		let myDate = new Date(myStrDate)

		//return angular.isDate(myDate);
		return myDate != null
	}

	// 10.11.2021 utility per impostare il range di date su
	// last week, last month o last year
	setRange(range) {
		let fromDay: Date
		let toDay: Date

		toDay = this.getYesterday()

		switch (range) {
			case 'week':
				fromDay = this.getPastDays(7)
				break

			case 'month':
				fromDay = this.getPastDays(30)
				break

			case 'year':
				fromDay = this.getPastDays(365)
				break
		}

		//this.fromDayDt = fromDay;
		//this.toDayDt = toDay;

		this.fromDay = Util.dateToNgb(fromDay)
		this.toDay = Util.dateToNgb(toDay)
	}

	// 10.11.2021
	getYesterday(): Date {
		return this.getPastDays(1)
	}

	getPastDays(totBack): Date {
		let oggi = new Date()
		let oldDay = new Date(oggi.setDate(oggi.getDate() - totBack))
		return oldDay
	}

	// submitTrends() {
	// 	console.log('(statistics) T action :' + this.currentAction)

	// 	if (this.currentAction == 'examsCount') {
	// 		// disab button "proceed", mettere un loader ?
	// 		this.btnCountDisabled = true

	// 		// 26.08.2022 distinguo export dei report AI, non sono un count ma una lista
	// 		if (this.usrOption == 'aireportsfull') {
	// 			return this.exportAiReports()
	// 		} else {
	// 			return this.elabExamsCount()
	// 		}
	// 	} else {
	// 		Util.debug('(statistics) T unknown action ' + this.currentAction)
	// 	}
	// }

	submitUsers() {
		console.log('(statistics) U action :' + this.currentAction + ' sub: ' + this.exportType)
		this.btnCountDisabled = true

		// 14.06.2021 distinguo in base a exportType
		if (this.exportType == 'exportUsers') {
			return this.exportUsers()
		} else if (this.exportType == 'exportLog') {
			return this.elabServerLog() // 14.06.2021
		} else if (this.exportType == 'usrActivity') {
			return this.exportActivity() // 07.07.2021
		} else if (this.exportType == 'exportDBLog') {
			return this.elabDbLog() // 17.11.2022
		}
	}

	submitImages() {
		console.log('(statistics) I action :' + this.currentAction)

		this.btnGetImgDisabled = true // disabilito x non far partire 2 volte la query x errore di doppio click
		this.btnCountDisabled = true

		return this.elabStatImages()
	}

	// 08.09.2022
	submitPatients() {
		alert('Patients - TODO')
	}

	// 12.09.2022
	submitDevices() {
		alert('Devices - TODO')
	}

	findPatient() {
		//alert("find patient - todo");

		if (this.patientFilter != '') {
			this.session.findPatientByCode(this.patientFilter).then((pat) => {
				//Util.debug("(findPatient) ris:");  // 12.09.2022
				//console.log(pat);

				if (pat && pat.patient) {
					let myPatient = new Patient(pat.patient)

					this.myPatientInfo = 'Code: ' + myPatient.code + ' created by: ' + myPatient.created_by

					// TODO add a link to go directly
					let docId = myPatient.created_by
					let patId = myPatient.id
					// (click) = this.session.loadVisitsPage(docId, patId);
				} else {
					// not found
					//Util.debug("(findPatient) ko patient, not found.");
					this.myPatientInfo = 'Not found'
				}
			})
		}
	}

	parseAvgGradingTime(grTime: number) {
		let hours = Math.trunc(grTime)

		if (hours <= 48) {
			return `${hours} hours`
		} else {
			return `${Math.round(hours / 24)} days`
		}
	}

	setMaxDateRange(e: Event) {
		e.preventDefault()
		this.statisticForm.get('fromDate').setValue(moment(this.minDate))
		this.statisticForm.get('toDate').setValue(moment(this.maxDate))
		this.setCadence()
	}
}

import { Attribute, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { faXmark, faCheck } from '@fortawesome/free-solid-svg-icons'

import { Country } from 'src/app/models/countries.models'

@Component({
	selector: 'searchdropdown',
	templateUrl: './searchdropdown.component.html',
	styleUrls: ['./searchdropdown.component.scss'],
})
export class SearchdropdownComponent implements OnInit {
	@Input() countries: Country[]
	@Input() userCountry: Country

	@Output() outcountry = new EventEmitter<{ countryIso2: string; countryIso3: string }>()
	@Output() cancelCountry = new EventEmitter<boolean>()

	@ViewChild('listCountries', { static: false }) listCountries: ElementRef
	@ViewChild('country') country: ElementRef

	currentValue: any
	dropdownOpen: boolean = false
	visible: boolean = false
	drpcountry: string = ''

	countrySelected: boolean

	//countries: Countries[] = [];

	dataFiltered: Country[] = []

	faXmark = faXmark
	faCheck = faCheck

	constructor(@Attribute('type') public type: string, public translator: TranslateService) {
		this.countrySelected = false
	}

	ngOnInit(): void {
		if (this.countries != null) {
			console.log('(Searchdrop) tot: ' + this.countries.length)
		}

		this.dataFiltered = this.countries

		// per poter pre-selezionare una country dal componente che usa la searchdropdrown
		if (this.userCountry) {
			this.select(this.userCountry)
		}
	}

	select(country: Country) {
		console.log('(Searchdrop) choosen: ' + country.alpha2 + ' ' + country.alpha3)
		this.visible = false
		this.drpcountry = country.name
		this.countrySelected = true

		this.outcountry.emit({ countryIso2: country.alpha2, countryIso3: country.alpha3 })
	}

	resetCountry() {
		this.country.nativeElement.value = ''
		this.countrySelected = false
		this.drpcountry = ''
		this.cancelCountry.emit(true)
		this.dataFiltered = this.countries
	}

	filter(event: KeyboardEvent) {
		// console.log(this.drpcountry);
		// console.log(event);

		if (event.key) {
			if (event.key === 'Enter') {
				this.visible = !this.visible

				//let lista: HTMLUListElement = <HTMLUListElement>document.getElementById('testaPiatta');
				//console.log(lista.nodeName);

				//let testa = document.getElementById("testaPiatta");

				// let childs: Array<HTMLTableSectionElement> = Array.from();
				//let arrIds = [];
				//for (let child of childs) {
				//  if (!(child.nodeName && child.nodeName.toLowerCase() == 'li')) continue;
				//  arrIds.push(child.dataset.id);
				//}
				//console.log(arrIds);

				// console.log(testo.nodeValue);
				console.log(this.listCountries.nativeElement)
			} else if (event.key.toLowerCase() == 'arrowdown') {
				if (this.visible) {
				}
				let selected = document.getElementsByClassName('selected')
				console.log(event)
			} else if (event.key.toLowerCase() == 'arrowup') {
				console.log(event)
			}
		}

		this.dataFiltered = this.countries.filter((country) => {
			return country.name.toLowerCase().includes(this.drpcountry.toLowerCase())
		})
	}
}

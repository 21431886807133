import { Component, OnInit, ViewChild } from '@angular/core'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { SalePlan, skuArticles } from '../../models/salePlan.model'
import { CsvLine } from '../../models/csvLine.model'
import { DateParser } from '../../models/dateParser.model'
import { NgForm } from '@angular/forms'

import * as _moment from 'moment'
const moment = _moment

export interface purchasedService {
	refId: string
	username: string
	date: string
	items: {
		code: string
		quantity: number
	}[]
	kind: string
	additions: additionsType
}

export enum additionsType {
	DIRECT,
	FINANCE,
	DISTRIBUTOR,
	FINANCE_DISTRIBUTOR,
}

@Component({
	selector: 'app-sap',
	templateUrl: './sap.component.html',
	styleUrls: ['./sap.component.scss'],
})
export class SapComponent implements OnInit {
	@ViewChild('serviceForm') serviceForm: NgForm
	csvContent: string // tutto il contenuto del file
	filteredLines: string[] // solo le righe filtrate per Nexus
	sapRecords: any[] // matrice con i records spezzati per colonne

	salePlanList: SalePlan[]
	srvResponse: string
	responseLines: any[]

	singlePlan: any // 13.04.2023 per insert manuale singolo
	srvSingleSetResp: string

	usernameFilter: string // 26.07.2023 filter for manual set
	myOpticianInfo: string

	salePlanKinds: string[] // 02.05.2024
	currentYear: number // 14.05.2024
	filterYear: string

	// NEW
	skuArticles: skuArticles[]
	skuSelected: string
	service: purchasedService
	success: boolean
	skuDescription: string

	additionsList: {
		label: string
		value: additionsType
	}[]

	constructor(public session: SessionService) {
		this.csvContent = ''
		this.filteredLines = []
		this.sapRecords = new Array()
		this.salePlanList = []
		this.srvResponse = ''
		this.responseLines = []
		this.skuArticles = []
		this.skuSelected = ''
		this.skuDescription = ''

		this.singlePlan = {
			userId: 0,
			planId: 0,
			credits: 0,
			months: 0,
			kind: 'BUY', // 02.05.2024 default
		}

		this.initService()

		this.srvSingleSetResp = ''
		this.currentYear = new Date().getFullYear() // 14.05.2024 // dinamico in base alla data di oggi
		this.filterYear = '' + this.currentYear
	}

	ngOnInit(): void {
		this.initPlanList() // 02.01.2023

		this.salePlanKinds = ['BUY', 'FREE', 'GIFT', 'reduction']

		this.additionsList = [
			{ label: 'DIRECT', value: additionsType.DIRECT },
			{ label: 'FINANCE', value: additionsType.FINANCE },
			{ label: 'DISTRIBUTOR', value: additionsType.DISTRIBUTOR },
			{ label: 'FINANCE & DISTRIBUTOR', value: additionsType.FINANCE_DISTRIBUTOR },
		]

		this.session.getSKUArticlesList().then((list) => {
			//console.log(list)
			this.skuArticles = list
		})
	}

	initService() {
		this.service = {
			refId: '',
			username: '',
			date: '',
			items: [
				{
					code: '',
					quantity: 0,
				},
			],
			kind: 'BUY',
			additions: null,
		}

		this.success = false
	}

	saveNewUserService() {
		// console.log(this.serviceForm)

		this.service.date = moment.utc(new Date()).format('YYYY-MM-DD')

		console.log(this.service)

		this.session
			.buyUserServices(this.service)
			.then((resp) => {
				console.log(resp)
				this.success = true

				setTimeout(() => {
					this.initService()
				}, 5000)
			})
			.catch((err) => {
				console.log(err)

				alert('KO: ' + err.error.error)

				setTimeout(() => {
					this.initService()
				}, 5000)
			})
	}

	initPlanList() {
		this.session.loadSalePlans().then((list) => {
			this.salePlanList = list
		})
	}

	public addDescription() {
		let service = this.skuArticles.find((x) => x.sku == this.service.items[0].code)
		if (service) {
			this.skuDescription = service.description
		} else {
			this.skuDescription = ''
		}
	}

	readSapFile() {
		let files = (<HTMLInputElement>document.getElementById('sapFile')).files

		//let files = input.files;
		//var content = this.csvContent;
		if (files && files.length) {
			let fileToRead = files[0]

			console.log('Filename: ' + fileToRead.name)
			console.log('Type: ' + fileToRead.type)
			console.log('Size: ' + fileToRead.size + ' bytes')

			Util.myReadFileAsText(fileToRead).then((textFromFileLoaded) => {
				//let firstWords = textFromFileLoaded.substring(0,40);
				//alert("ok, letto il file, prima riga: "+firstWords);

				this.csvContent = ''

				// 04.07.2024 tolto, poi cmq viene caricato lo stream originario ! [ls]
				// carica su array e filtra le righe
				//this.filterSapLines(textFromFileLoaded)

				//let totRec = 0;
				//let totRec = this.filteredLines.length

				if (textFromFileLoaded != null) {
					//if (totRec > 0) {
					//this.sapRecords = new Array(totRec)

					/*
					for (let i = 0; i < totRec; i++) {
						this.csvContent += this.filteredLines[i] + '\n'

						// matrice
						//let rec = this.filteredLines[i].split(";");
						//this.sapRecords[i].push(rec);
					}
          */

					// invia al server

					//let sapApi = Config.sapEndpoint
					let myForm = new FormData() // as form data

					// FIXME convertire filteredLines in uno stream...
					//let myStream = writeStream.write(JSON.stringify(filteredLines));

					let myStream = fileToRead // all the file

					myForm.append('stream', myStream)
					myForm.append('year', this.filterYear) // 02.07.2024

					//myForm.append('stream', fileToRead) // all the file
					//myForm.append('stream', textFromFileLoaded);  // all the file content
					//myForm.append('stream', this.csvContent);  // only filtered lines

					// 02.01.2023
					return this.session.uploadCsvSap(myForm).then((res) => {
						// Success

						if (res.message) {
							console.log('(sap) ok ' + res.message)
							this.srvResponse = res.message
						}

						// lista di record elaborati, con esito
						if (res.data) {
							this.responseLines = res.data
							let myLen = this.responseLines.length
							console.log('(sap) ok ' + myLen)

							// per esporle nella textarea....
							for (let i = 0; i < myLen; i++) {
								this.csvContent += JSON.stringify(this.responseLines[i]) + '\n'
							}
						} else {
							console.log(res)
						}
					})
				}
			})
		}
	}

	// non serve proprio...
	private DISAB_filterSapLines(myContent: string) {
		let lines = []
		this.filteredLines = [] // svuoto
		let len = 0

		if (myContent != null) {
			lines = myContent.split('\n')
			if (lines) len = lines.length
		}
		//Util.debug("(filterSapLines) tot: "+len); // 226

		if (len > 0) this.filteredLines.push(lines[0]) // intestazione

		for (let i = 1; i < len; i++) {
			let isValidRow = false

			// 21.06.2024 rimosso test, aggiunti tanti SKU, gestiti lato server o da GUI parsap
			// filtro rustico su due valori, verra' fatto meglio
			//if (lines[i].indexOf('NX001') > 0 || lines[i].indexOf('NEXUS_SERVICE') > 0) {
			isValidRow = true
			//}

			// 14.05.2024 filtro su anno corrente e/o precedente
			// primo elemento della riga e' tipo "03 Apr 2024"
			if (isValidRow && this.filterYear != 'ALL') {
				let myDateIndex = lines[i].indexOf(';')

				if (myDateIndex > 0 && myDateIndex < 15) {
					let myDate = lines[i].substring(0, myDateIndex)

					if (myDate.indexOf(this.filterYear) > 0) {
						isValidRow = true
					} else {
						isValidRow = false
					}
					Util.debug('(filterSapLines) line ' + i + ' date: ' + myDate + ' is_valid? ' + isValidRow)
				}
			}

			if (isValidRow) {
				this.filteredLines.push(lines[i])
			}
		}

		Util.debug('(filterSapLines) filtered: ' + this.filteredLines.length + ' from tot: ' + len) // 226

		return // filteredLines;
	}

	// 11.01.2023 convert in CSV the result from the server
	public downloadSapResult() {
		let strBuffer = ''

		//alert("downloadSapResult - see trace on console! ");
		let tmp = Util.convertJsonToCsv(this.responseLines, CsvLine.SEP)
		//let tmp = Util.convertToCSV(this.responseLines);
		//strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)

		let allCsv = tmp.join('\r\n')
		//console.log(allCsv);

		strBuffer = allCsv

		let myDate = new Date()
		let szToDay = DateParser.formatSqlDate(myDate)

		let filename = 'sap_response_' + szToDay + '.csv'
		let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })

		Util.mySaveAs(filename, blob)
	}

	// 13.04.2023 save new plan/credits/fee for single user
	public forceSinglePlan() {
		if (this.singlePlan.userId <= 0) {
			this.srvSingleSetResp = 'Invalid user_id'
			return false
		}

		// TODO se valori mesi e cresiti negativi -> kind deve essere su reduction
		// se mesi e crediti discordanti nel segno, uno negativo e uno positivo, rifiutare l'azione, xche' il kind e' unico

		this.srvSingleSetResp = '*** working... ***'

		this.session
			.updateUserPlan(this.singlePlan)
			.then((resp) => {
				//console.log(resp);

				if (resp.done) this.srvSingleSetResp = resp.done // a message
				else this.srvSingleSetResp = '*** DONE ***'

				return true
			})
			.catch((err) => {
				let msg = this.session.parseErrorMessage(err, 'alert')
				this.srvSingleSetResp = 'ERROR - ' + msg
				//alert(msg);
			})
	}

	// 26.07.2023
	findOptician() {
		this.myOpticianInfo = ''

		if (!this.usernameFilter) {
			return
		}

		if (!this.session.hasLoadedDoctors()) {
			this.myOpticianInfo = 'Operators not loaded yet.'
		} else {
			let doct = this.session.getDoctorByUsername(this.usernameFilter)
			if (doct) {
				this.myOpticianInfo = 'user id: ' + doct.user_id
			} else {
				this.myOpticianInfo = 'not found'
			}
		}
		return
	}
}

import { Injectable } from '@angular/core'
import { Util } from '../models/util.model'
import { SessionService } from './session.service'
import { Config } from 'src/config'
import { DataModelService } from './data-model.service'
import { Subject, Subscription } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class QrCodeService {
	uuidList: uuidList[]
	userId: number
	userLoggedSubscription: Subscription
	scannedQRCode: Subject<string> = new Subject<string>()
	scannedQRCodeSubscription: Subscription

	constructor(private session: SessionService, private data: DataModelService) {
		Util.debug('QrCodeService - START')

		this.uuidList = []

		this.userLoggedSubscription = this.session.userLogged.subscribe((resp: boolean) => {
			// console.log(resp)
			if (resp) {
				this.session.canStartQRCodeService().then((response: boolean) => {
					// console.log(response)
					if (response) {
						Util.debug('(QrCodeService) [userLogged] ok')
						this.serviceInit()
					}
				})
			} else {
				this.clear()
			}
		})
	}

	private serviceInit() {
		Util.debug('QrCodeService - serviceInit')
		this.userId = this.session.user.user_id
		this.getUUIDList()
		this.clearOldUUIDs()
	}

	public setUUIDList(uuid: string, id: number) {
		Util.debug('QrCodeService - setUUIDList')
		let uuidItem: uuidList = { uuid: uuid, id: id, date: new Date(), state: 'new', toDelete: false }
		this.uuidList.push(uuidItem)
		localStorage.setItem('uuid_' + this.userId, JSON.stringify(this.uuidList))
	}

	public getUUIDList() {
		Util.debug('QrCodeService - getUUIDList')
		let savedLocal = localStorage.getItem('uuid_' + this.userId)
		if (savedLocal) {
			this.uuidList = JSON.parse(savedLocal)
		} else {
			this.uuidList = []
		}
	}

	public getValidUUIDList(uuids: string[]): string[] {
		let uuidList: string[] = []

		let validList: uuidList[] = this.uuidList.filter((el) => uuids.includes(el.uuid))

		if (validList.length > 0) {
			uuidList = validList.map((el) => el.uuid)
		}
		return uuidList
	}

	public removeManagedUUID(uuids: string[]) {
		let uuidSToKeep: uuidList[] = []

		uuidSToKeep = this.uuidList.filter((el) => !uuids.includes(el.uuid))

		this.uuidList = uuidSToKeep
		this.replaceUUIDList(uuidSToKeep)
	}

	// older than 2gg
	private clearOldUUIDs() {
		if (this.uuidList.length > 0) {
			const today = new Date()
			// mi ricavo solo quelli toDelete false, gli  altri gli elimineró giá rimpiazzando uuidList = rawList
			let rawList = this.uuidList.filter((el) => el.toDelete == false)

			// quelli salvati da piú di 2gg, ,imposto lo toDelete a true, in modo che alla prossima login vengono eliminati
			if (rawList.length > 0) {
				for (let uuid of rawList) {
					const diffTime = Math.abs(today.valueOf() - new Date(uuid.date).valueOf())
					const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

					if (diffDays > 2) {
						uuid.toDelete = true
					}
				}
			}

			this.uuidList = rawList
			this.replaceUUIDList(rawList)
		}
	}

	private replaceUUIDList(uuidList: uuidList[]) {
		localStorage.setItem('uuid_' + this.userId, JSON.stringify(uuidList))
	}

	public newUUID(uuid: string): Promise<{ uuid_id: number }> {
		const request: any = this.session.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.patientUUIDEndpoint

		const data = {
			patient_uuid: {
				uuid: uuid,
			},
		}
		return this.data.myPost(request, data).catch((err) => {
			console.log('(QrCodeService) [myPost] ko')
			this.session.isExpired(err) // eventualmente forza logout
			throw err
		})
	}

	public listenForScannedQRCode() {
		this.scannedQRCodeSubscription = this.scannedQRCode.subscribe((uuid: string) => {
			this.uuidList.find((el) => el.uuid == uuid).state = 'scanned'
			this.replaceUUIDList(this.uuidList)
		})
	}

	public unsubscribeForScannedQRCode() {
		this.scannedQRCodeSubscription.unsubscribe()
	}

	private clear() {
		Util.debug('(QrCodeService) clear')
		this.uuidList = []
		this.userId = 0
	}
}

export interface uuidList {
	uuid: string
	id: number
	date: Date
	state: string
	toDelete: boolean
}

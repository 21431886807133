<div>
	<div class="row">
		<div>
			<p class="pe-5"><b>{{'CREDITS.FILTER_MAINTITLE' | translate}}</b></p>

			<form #filters="ngForm" class="d-flex">
				<div>
					<mat-form-field ngbTooltip="{{ 'CREDITS.REMOVE_NEGATIVE_T' | translate }}" appearance="outline">
						<mat-label>{{'CREDITS.FILTER_TITLE_1' | translate}}</mat-label>
						<mat-select [ngModel]="0" name="movements" (selectionChange)="filterValue(filters)">
							<mat-option *ngFor="let mov of movements" [value]="mov.value"> {{mov.viewValue}} </mat-option>
						</mat-select>
					</mat-form-field>
				</div>

				<div class="ms-4">
					<mat-form-field ngbTooltip="{{ 'CREDITS.REMOVE_NEGATIVE_T' | translate }}" appearance="outline">
						<mat-label> {{'CREDITS.FILTER_TITLE_2' | translate}}</mat-label>
						<mat-select [ngModel]="0" name="types" (selectionChange)="filterValue(filters)">
							<mat-option *ngFor="let type of types" [value]="type.value"> {{type.viewValue}} </mat-option>
						</mat-select>
					</mat-form-field>
				</div>
				<div class="ms-4">
					<mat-form-field ngbTooltip="{{ 'CREDITS.REMOVE_NEGATIVE_T' | translate }}" appearance="outline">
						<mat-label> {{'CREDITS.FILTER_TITLE_3' | translate}}</mat-label>
						<mat-select [ngModel]="0" name="grading" (selectionChange)="filterValue(filters)">
							<mat-option *ngFor="let grd of grading" [value]="grd.value"> {{grd.viewValue}} </mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</form>
		</div>

		<div class="container position-relative">
			<table mat-table [dataSource]="balanceListTable" matSort style="width: 100%">
				<!-- first Column -->
				<ng-container matColumnDef="buy_date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.DATE' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{session.formatDateTime(element.buy_date)}}</td>
				</ng-container>

				<ng-container matColumnDef="sku">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.SKU' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{element.sku}}</td>
				</ng-container>

				<ng-container matColumnDef="description">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.SKU_DESCRIPTION' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{(element.description)}}</td>
				</ng-container>

				<ng-container matColumnDef="i_type">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.TYPE' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{decodeBalanceType(element.i_type)}}</td>
				</ng-container>

				<ng-container matColumnDef="amount">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.AMOUNT' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{element.amount}}</td>
				</ng-container>

				<!-- <ng-container matColumnDef="expiry_date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.EXPIRY_DATE' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{session.formatDate(element.expiry_date)}}</td>
				</ng-container> -->

				<ng-container matColumnDef="ref_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.REF_ID' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{element.ref_id}}</td>
				</ng-container>

				<ng-container matColumnDef="channel">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.CHANNEL' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{element.channel}}</td>
				</ng-container>

				<ng-container matColumnDef="kind">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.KIND' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{element.kind}}</td>
				</ng-container>

				<ng-container matColumnDef="notes">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'CREDITS.NOTES' | translate}}</th>
					<td mat-cell *matCellDef="let element">{{element.notes}}</td>
				</ng-container>

				<!-- last Column - block bouble click view - only click the buttons -->
				<ng-container matColumnDef="filter">
					<th mat-header-cell *matHeaderCellDef class="text-end">
						<mat-form-field appearance="outline">
							<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
						</mat-form-field>
					</th>
					<td mat-cell *matCellDef="let element"></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>

			<mat-paginator [pageSizeOptions]="[3, 10, 20]" showFirstLastButtons> </mat-paginator>

			<div class="noresults-banner" [ngClass]="{show : balanceList.length == 0}">
				<div class="container">
					<div class="row text-center noresults-banner__cont">
						<div class="col-5"></div>
						<div class="col-2 alert alert-secondary" role="alert">{{ 'CREDITS.FILTER_NORESULTS' | translate }}</div>
						<div class="col-5"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

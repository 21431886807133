import { Component, Input, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Address } from 'src/app/models/address.model'
import { Doctor } from 'src/app/models/doctor.model'
import { Distrib, RelationDetail, Specialist, relationsStatus } from 'src/app/models/specialist.model'
import { Util } from 'src/app/models/util.model'
import { SessionService } from 'src/app/service/session.service'

import {
	faLocationDot,
	faCircleCheck,
	faUser,
	faCircleXmark,
	faPenToSquare,
	faSave,
	faTrashCan,
	faRotateLeft,
	faUserPlus,
	faCaretUp,
	faCaretDown,
} from '@fortawesome/free-solid-svg-icons'
import { Config } from 'src/config'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { TranslateService } from '@ngx-translate/core'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'
import { ToastOptions } from 'src/app/models/toast.model'
import { AppToastService } from 'src/app/service/toast.service'
import { SaleInfo } from 'src/app/models/salePlan.model'

@Component({
	selector: 'docrelations',
	templateUrl: './relations.modal.html',
	styleUrls: ['./relations.modal.scss'],
})
export class RelationsModal implements OnInit, AfterViewInit {
	@Input() currentDoctor: Doctor
	@Input() waitDoctor: boolean = true //from doctor list

	waitDistrib: boolean
	noRelations: boolean

	specialistsRelated: Specialist[] //lista specialist asociati dal doctor
	distribRelated: RelationDetail[] //lista oggetti specialist visualizzati a destra
	relationstatus: string
	relationType: string
	distance: string
	relationDate: Date

	loadDoctor: Promise<any> //from doctor list

	doctorAddress: string
	docMainAddress: Address
	docLogo: string
	// docBrand: string //not used brand
	supportHideNew: boolean
	docSaleInfo: SaleInfo

	//spec table
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef
	displayedColumns: string[]
	specialistList: MatTableDataSource<availableGraders>
	askForGraders: boolean
	showTable: boolean
	allAvailableGraders: Map<number, availableGraders>
	// nearestGraders: Map<number, availableGraders>
	originalFilterPredicate: any
	// showAll: boolean
	// noNearest: boolean
	searchNewGrader: boolean

	expandedElement: availableGraders | null

	//icons
	faLocationDot = faLocationDot
	faCircleCheck = faCircleCheck
	faUser = faUser
	faCircleXmark = faCircleXmark
	faSave = faSave
	faPenToSquare = faPenToSquare
	faTrashCan = faTrashCan
	faRotateLeft = faRotateLeft
	faUserPlus = faUserPlus
	faCaretUp = faCaretUp
	faCaretDown = faCaretDown

	constructor(
		public activeModal: NgbActiveModal,
		private toastService: AppToastService,
		public session: SessionService,
		private translator: TranslateService,
		private modalService: NgbModal
	) {
		Util.debug('relationsModal - constructor')

		this.waitDistrib = true
		this.noRelations = true

		this.specialistsRelated = []
		this.distribRelated = []

		this.relationstatus = ''
		this.relationType = ''
		this.distance = ''
		this.relationDate = null

		this.doctorAddress = ''
		this.currentDoctor = new Doctor()
		this.docLogo = ''
		// this.docBrand = ''
		this.supportHideNew = false
		this.docSaleInfo = new SaleInfo(null)

		this.displayedColumns = ['subType', 'username', 'name', 'organization', 'type', 'test', 'distance', 'city', 'search', 'expand']
		this.specialistList = new MatTableDataSource<availableGraders>([])
		this.askForGraders = false
		this.showTable = false

		this.allAvailableGraders = new Map<number, availableGraders>()
		// this.nearestGraders = new Map<number, availableGraders>()
		// this.showAll = false
		// this.noNearest = false
		this.searchNewGrader = false
	}

	ngOnInit(): void {
		Util.debug('relationsModal - ngOnInit')

		this.loadDoctor.then(() => {
			this.initComponent()
		})
	}

	ngAfterViewInit(): void {
		Util.debug('relationsModal - ngAfterViewInit')
	}

	private initComponent(): Promise<boolean> {
		Util.debug('(relationsModal) - initComponent')

		this.searchNewGrader = false
		this.showTable = false
		this.specialistsRelated = []
		this.distribRelated = []

		const promise = new Promise<boolean>((resolve, reject) => {
			if (this.currentDoctor.logo && this.currentDoctor.logo != '' && this.currentDoctor.logo_name != 'transparent.png') {
				this.docLogo = this.currentDoctor.logo
			}
			// console.log(this.currentDoctor)

			this.docMainAddress = this.currentDoctor.getMainAddress()
			this.doctorAddress = this.docMainAddress.getExtendedAddressLabel()
			// this.docBrand = this.currentDoctor.settings.brand

			this.session.loadUserPlan(this.currentDoctor.user_id).then((ris) => {
				this.docSaleInfo = ris
				this.supportHideNew = this.docSaleInfo.salePlan.isFree() || this.docSaleInfo.salePlan.isFrozen()

				let specialist = this.currentDoctor.specialists
				if (specialist && specialist.length > 0) {
					this.noRelations = false
					this.specialistsRelated = specialist.filter((el) => el.user_subtype != Config.SUB_MINI) //TODO check if correct

					Util.debug('(relationsModal) - specialist length (not mini): ' + this.specialistsRelated.length)
					// console.log(this.specialistsRelated)

					let promiseArray: Promise<RelationDetail>[] = []

					for (let spec of this.specialistsRelated) {
						promiseArray.push(this.initDistribRelation(spec))
					}

					Promise.all(promiseArray)
						.then((results: RelationDetail[]) => {
							// console.log(results)

							this.distribRelated = results
							this.addEmptyBox()
							this.selectDistrib(this.distribRelated[0])
							this.waitDistrib = false

							// console.log(this.distribRelated)

							resolve(true)
						})
						.catch((err) => {
							console.log(err)
						})
				} else {
					this.noRelations = true
					Util.debug('(relationsModal) - no specialist')
					this.waitDistrib = false
					resolve(true) // ?? check

					this.addEmptyBox()
				}
			})
		})

		return promise
	}

	initDistribRelation(specialist: Specialist): Promise<RelationDetail> {
		Util.debug('(relationsModal) - initDistribRelation for specilist id: ' + specialist.distributor_id)

		let relDet: RelationDetail = new RelationDetail()

		return this.session
			.getDtDistrib(specialist.distributor_id)
			.then((distrib) => {
				if (distrib && distrib.isDecrypted) {
					relDet = new RelationDetail(distrib, this.docMainAddress)
					relDet.created_by = specialist.created_by
					relDet.relStatus = specialist.rel_status
					relDet.relation_id = specialist.relation_id
					relDet.affiliation_date = new Date(specialist.affiliation_date)
					return relDet
				} else {
					Util.debug('(relationsModal) - distrib not decrypted, loadDistrib')
					this.session
						.loadDistrib(specialist.distributor_id)
						.then((dist) => {
							// console.log(dist)
							relDet = new RelationDetail(dist, this.docMainAddress)
							return relDet
						})
						.catch((err) => {
							console.log(err)
							relDet.not_available = true
							// console.log(relDet)
							return relDet
						})
				}
			})
			.catch((err) => {
				console.log(err)
				relDet.not_available = true
				// console.log(relDet)
				return relDet
			})
	}

	private addEmptyBox() {
		Util.debug('(relationsModal) - addEmptyBox')

		if ((this.distribRelated.length == 0 && !this.supportHideNew) || !this.session.isSupport()) {
			Util.debug('add empty box for add new grader')
			let relDet: RelationDetail = new RelationDetail()
			relDet.created_by = -1
			this.distribRelated.push(relDet)
		}
	}

	public selectDistrib(distrib: RelationDetail) {
		// console.log(distrib)
		if (distrib.created_by == -1 || distrib.not_available) {
			Util.debug('(relationsModal) - add new grader box selected - return ')
			return
		}
		Util.debug('(relationsModal) - selectDistrib id: ' + distrib.distributor.id)

		this.relationType = ''
		this.relationstatus = ''
		this.distance = ''
		this.relationDate = null

		this.deselectAllDistrib()

		if (distrib.created_by == 0) {
			this.relationType = this.translator.instant('RELATIONS.OPERATOR_SIDE.REL_TYPE_AUTO')
		} else {
			this.relationType = this.translator.instant('RELATIONS.OPERATOR_SIDE.REL_TYPE_MANUAL') + distrib.created_by
		}

		this.relationstatus = distrib.relStatus

		if (distrib.distance == -1) {
			this.distance = this.translator.instant('RELATIONS.OPERATOR_SIDE.NO_DISTANCE')
		} else {
			this.distance = distrib.distance.toFixed(1) + ' km'
		}

		this.relationDate = distrib.affiliation_date

		distrib.selected = true
	}

	private deselectAllDistrib() {
		Util.debug('(relationsModal) - deselectAllDistrib')
		this.distribRelated.forEach((distrib) => {
			distrib.selected = false
		})
	}

	// public getGraders(rel: RelationDetail) {
	// 	Util.debug('(relationsModal) - getGraders')

	// 	this.searchNewGrader = true
	// 	this.showTable = true
	// 	this.askForGraders = true

	// 	rel.changeStatus = true

	// 	this.session
	// 		.getNearestRelations(this.currentDoctor.user_id)
	// 		.then((myList: nearestRel[]) => {
	// 			// console.log(myList)
	// 			Util.debug('(relationsModal) - getNearestRelations - tot: ' + myList.length)

	// 			if (myList.length > 0) {
	// 				let arrayPromise: Promise<Distrib>[] = []

	// 				for (let el of myList) {
	// 					arrayPromise.push(this.session.getDtDistrib(el.grader_id))
	// 				}

	// 				Promise.all(arrayPromise).then((distribList) => {
	// 					// console.log(distribList)
	// 					Util.debug('(relationsModal) - get all Graders from data end')

	// 					for (let distr of distribList) {
	// 						let element = myList.find((el) => el.grader_id == distr.user_id)
	// 						let gr = new availableGraders(distr, element.status)

	// 						gr.distance = distr.getCloserLocation(this.docMainAddress).distance

	// 						this.nearestGraders.set(gr.id, gr)
	// 						this.allAvailableGraders.set(gr.id, gr)
	// 					}

	// 					this.askForGraders = false

	// 					// console.log(this.allAvailableGraders.values())

	// 					let list = Array.from(this.allAvailableGraders.values())
	// 					this.initSpecialistList(list)
	// 				})
	// 			} else {
	// 				this.noNearest = true
	// 				this.showAll = true
	// 				this.showAllGraders()
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			console.log(err)
	// 		})
	// }

	// public showAllGraders() {
	// 	// console.log(this.showAll)

	// 	//resetto il filtro quando cambio lista
	// 	this.input.nativeElement.value = ''
	// 	this.specialistList.filter = this.input.nativeElement.value.trim().toLocaleLowerCase()

	// 	if (this.showAll) {
	// 		Util.debug('(relationsModal) - showAllGraders')
	// 		this.getAvailableGraders()
	// 	} else {
	// 		Util.debug('(relationsModal) - showAllGraders, show only nearesr')
	// 		// rimuovo i nearest dalla lista
	// 		let keys = this.allAvailableGraders.keys()

	// 		for (let key of keys) {
	// 			let skip = this.nearestGraders.has(key)

	// 			if (!skip) {
	// 				this.allAvailableGraders.delete(key)
	// 			}
	// 		}

	// 		let list = Array.from(this.allAvailableGraders.values())
	// 		this.initSpecialistList(list)
	// 	}
	// }

	public getAvailableGraders(rel: RelationDetail) {
		Util.debug('(relationsModal) - getGraders')

		this.searchNewGrader = true
		this.showTable = true
		this.askForGraders = true

		rel.changeStatus = true

		this.askForGraders = true
		this.session
			.getAvailableGraders(this.currentDoctor.user_id)
			.then((distribList) => {
				// console.log(distribList)
				Util.debug('getAvailableGraders - tot: ' + distribList.length)
				for (let distr of distribList) {
					let gr = new availableGraders(distr)

					if (!this.allAvailableGraders.has(gr.id)) {
						let result: { indx: number; distance: number; city: string } = distr.getCloserLocation(this.docMainAddress)
						gr.distance = result.distance
						gr.city = result.city

						if (gr.distance == -1) {
							gr.distance = 99999 //caso non abbia la location, quindi per mostrarli alla fine della lista e non allinizio metto un numero alto che poi nascondo
						}
						this.allAvailableGraders.set(gr.id, gr)
					}
				}
				this.askForGraders = false

				let list = Array.from(this.allAvailableGraders.values())

				// ask for more information
				this.session.getDtDeletedRelations(this.currentDoctor.user_id).then((relations: relationsStatus[]) => {
					// console.log(relations)

					for (let rel of relations) {
						let gr = list.find((el) => el.distributor.user_id == rel.distributor_id)
						if (gr) {
							gr.previousRelInfo = true
							gr.status = rel.rel_type
							gr.updatedBy = rel.updated_by
							gr.relStartDate = new Date(rel.affiliation_date)
							gr.relEndDate = new Date(rel.cessation_date)

							this.allAvailableGraders.set(gr.id, gr)
						}
					}
				})

				this.initSpecialistList(list)
			})
			.catch((err) => {
				console.log(err)

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = err.error.error
				let options = new ToastOptions('error')
				this.toastService.show(header, body, false, options, 'center')

				this.loadChangedDoctor()
			})
	}

	public discardRelation(rel?: RelationDetail) {
		Util.debug('(relationsModal) - discard ')

		if (this.askForGraders) {
			//se clicco mentre sta caricando
			return
		}
		if (rel) {
			rel.changeStatus = false
		}
		// this.showAll = false
		this.searchNewGrader = false
		this.showTable = false
		this.initSpecialistList([])
		// this.nearestGraders.clear()
		this.allAvailableGraders.clear()
	}

	public deleteRelation(rel: RelationDetail) {
		Util.debug('(relationsModal) - deleteRelation for: ' + rel.distributor.user_id)

		if (this.session.isSupport()) {
			console.log('Delete not available for support')
			return
		}

		let msg = this.translator.instant('RELATIONS.OPERATOR_SIDE.DELETE_RELATION')

		this.confirm(msg)
			.result.then(() => {
				this.waitDoctor = true //volendo si puó non mettere
				this.relationstatus = ''
				this.relationType = ''
				this.distance = ''

				this.discardRelation()

				this.session
					.deleteRelation(rel.relation_id)
					.then(() => {
						Util.debug('(relationsModal) - deleteRelation for: ' + rel.distributor.user_id + ' ok')

						this.loadChangedDoctor().then(() => {
							let header = this.translator.instant('TOAST.HEADER.SUCCESS')
							let body = this.translator.instant('RELATIONS.OPERATOR_SIDE.DELETED') + rel.distributor.username
							let options = new ToastOptions('success')
							this.toastService.show(header, body, false, options, 'bottom-right')
						})
					})
					.catch((err) => {
						console.log(err)

						let header = this.translator.instant('TOAST.HEADER.ERROR')
						let body = this.translator.instant('RELATIONS.OPERATOR_SIDE.NOT_DELETED') + err.error.error
						let options = new ToastOptions('error')
						this.toastService.show(header, body, false, options, 'center')

						this.loadChangedDoctor()
					})
			})
			.catch(() => {
				Util.debug('dismissed')

				return
			})
	}

	public changeRelation(newGrader: availableGraders) {
		// console.log(newGrader)
		Util.debug('(relationsModal) - deleteRelation ')
		let oldGrader = this.getSelectedGrader() //se undefined é Add new grader

		let msg = ''

		if (oldGrader) {
			msg = this.translator.instant('RELATIONS.OPERATOR_SIDE.CHANGE_RELATION')
		} else {
			msg = this.translator.instant('RELATIONS.OPERATOR_SIDE.CREATE_RELATION')
		}

		this.confirm(msg)
			.result.then(() => {
				this.waitDoctor = true //volendo si puó non mettere
				this.relationstatus = ''
				this.relationType = ''
				this.distance = ''

				this.discardRelation()

				if (oldGrader) {
					Util.debug('(relationsModal) - changeRelation from old grader: ' + oldGrader.distributor.user_id + ' with new: ' + newGrader.distributor.user_id)
					this.session
						.deleteRelation(oldGrader.relation_id)
						.then(() => {
							Util.debug('(relationsModal) - deleteRelation for: ' + oldGrader.distributor.user_id + ' ok')

							this.createRelation(newGrader)
						})
						.catch((err) => {
							console.log(err)

							this.waitDoctor = false
							oldGrader.changeStatus = false
							let lastDistrb = this.distribRelated.find((el) => el.distributor.user_id == oldGrader.distributor.user_id)
							this.selectDistrib(lastDistrb)

							let header = this.translator.instant('TOAST.HEADER.ERROR')
							let body = this.translator.instant('RELATIONS.OPERATOR_SIDE.NOT_CHANGED') + err.error.error
							let options = new ToastOptions('error')
							this.toastService.show(header, body, false, options, 'center')
						})
				} else {
					Util.debug('(relationsModal) - AddRelation with grader: ' + newGrader.distributor.user_id)
					this.createRelation(newGrader)
				}
			})
			.catch(() => {
				Util.debug('dismissed')

				return
			})
	}

	private createRelation(newGrader: availableGraders) {
		this.session.createOpticianRelation(this.currentDoctor.username, newGrader.distributor).then((res) => {
			Util.debug('(relationsModal) - createRelation ok response: ' + res.response)
			// console.log(res)

			this.loadChangedDoctor()
				.then(() => {
					// seleziono l'ultimo aggiunto
					let lastDistrb = this.distribRelated.find((el) => el.distributor.user_id == newGrader.distributor.user_id)
					this.selectDistrib(lastDistrb)

					let header = this.translator.instant('TOAST.HEADER.SUCCESS')
					let body = this.translator.instant('RELATIONS.OPERATOR_SIDE.CREATED') + newGrader.name
					let options = new ToastOptions('success')
					this.toastService.show(header, body, false, options, 'bottom-right')
				})
				.catch((err) => {
					console.log(err)
					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant('RELATIONS.OPERATOR_SIDE.NOT_CREATEDe ') + err.error.error
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')
				})
		})
	}

	private getSelectedGrader(): RelationDetail {
		let rel = this.distribRelated.find((el) => el.selected && el.changeStatus)
		return rel
	}

	private loadChangedDoctor(): Promise<boolean> {
		let docId = this.currentDoctor.user_id

		const promise = new Promise<boolean>((resolve, reject) => {
			this.session.loadDoctor(docId).then((val: boolean) => {
				// console.log(val)

				this.currentDoctor = this.session.getDtDoctor(docId)

				this.initComponent().then(() => {
					this.waitDoctor = false

					resolve(val)
				})
			})
		})

		return promise
	}

	private initSpecialistList(list: availableGraders[]) {
		Util.debug('(relationsModal) - initSpecialistList with graders: ' + list.length)
		// console.log(list)
		this.specialistList = new MatTableDataSource<availableGraders>(list)
		this.specialistList.sort = this.sort
		this.specialistList.paginator = this.paginator

		this.sort.active = 'distance'
		this.sort.direction = 'asc'
		this.sort.sortChange.emit()

		this.originalFilterPredicate = this.specialistList.filterPredicate
	}

	public filterText() {
		this.specialistList.filter = this.input.nativeElement.value.trim().toLocaleLowerCase()
	}

	private confirm(message: string): NgbModalRef {
		let confirmModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal

		confirmModal.componentInstance.isExit = false
		confirmModal.componentInstance.isQuest = true
		confirmModal.componentInstance.warnText = message

		return confirmModal
	}
}

export class availableGraders {
	id: number
	distributor: Distrib
	username: string
	name: string
	brand: string
	organization: string
	displayName: string
	type: string
	city: string
	distance: number
	status: string
	// relType: string
	relStartDate: Date
	relEndDate: Date
	updatedBy: number
	previousRelInfo: boolean

	constructor(distrib: Distrib) {
		this.id = distrib.user_id
		this.distributor = distrib
		this.username = distrib.username
		this.name = distrib.name
		this.brand = distrib.getBrand()
		this.organization = distrib.getOrganization()
		this.displayName = distrib.getDisplayName()
		this.type = distrib.user_type
		this.city = ''
		this.distance = 0
		this.status = ''
		// this.relType = ''
		this.relStartDate = null
		this.relEndDate = null
		this.updatedBy = 0
		this.previousRelInfo = false
	}
}

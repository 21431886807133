import { Component, OnInit, ViewChild } from '@angular/core'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DBCountrySettings } from 'src/app/models/countries.models'

import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { faCircleCheck, faCircleXmark, faEdit, faSave } from '@fortawesome/free-solid-svg-icons'

@Component({
	selector: 'app-dbsettings',
	templateUrl: './dbsettings.component.html',
	styleUrls: ['./dbsettings.component.scss'],
})
export class dbSettingComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	countryDBSettingsList: MatTableDataSource<DBCountrySettings>

	countryDBSettings: DBCountrySettings[]
	displayedColumns: string[]

	editDBSettings: DBCountrySettings
	isEditing: boolean

	public yesNoOptions = ['Y', 'N']

	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark
	faEdit = faEdit
	faSave = faSave

	constructor(public session: SessionService) {
		this.displayedColumns = [] //'country', 'ecommerce', 'free_trial', 'hg_service', 'p_self_reg', 'proximity_threshold'
		this.countryDBSettings = []

		this.isEditing = false
	}

	ngOnInit(): void {
		this.session
			.requestDBCountrySettings()
			.then((settings) => {
				this.countryDBSettings = settings

				for (var prop in this.countryDBSettings[0]) {
					this.displayedColumns.push(prop)
				}

				this.countryDBSettingsList = new MatTableDataSource(this.countryDBSettings)
				this.countryDBSettingsList.paginator = this.paginator
				this.countryDBSettingsList.sort = this.sort
			})
			.catch((err) => {
				console.log(err)
				alert(err)
			})
	}

	public edit(countryDBSettings: DBCountrySettings) {
		if (!this.isEditing) {
			this.isEditing = true
			countryDBSettings.isEditing = true
			this.editDBSettings = countryDBSettings
		} else {
			return
		}
	}

	public undoEdit(countryDBSettings: DBCountrySettings) {
		countryDBSettings.isEditing = false
		this.isEditing = false
		this.editDBSettings = null
	}

	public save(countryDBSettings: DBCountrySettings) {
		if (this.editDBSettings) {
			countryDBSettings.isEditing = false
			this.isEditing = false
			this.editDBSettings = null
			alert('te piasaria eh!!! non ancora implementato ')
		} else {
			alert('No settings to save')
			countryDBSettings.isEditing = false
			this.isEditing = false
			this.editDBSettings = null
		}
	}

	public isTypeString(value) {
		return typeof value === 'string'
	}

	public onlyNumbers(value) {
		const soloNumeri = /^\d+$/

		if (!soloNumeri.test(value)) {
			this.editDBSettings[value] = this.editDBSettings[value].replace(/\D/g, '')
		}
	}
}

import { Component, OnInit, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, NgbActiveModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'

import { User, UserType } from '../../models/user.model'
import { Doctor } from 'src/app/models/doctor.model'
import { Address } from 'src/app/models/address.model'
import { Country } from '../../models/countries.models'
import { Util } from '../../models/util.model'

import { faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import { totalmem } from 'os'
//import { faBell, faEye, faArrowAltCircleDown, faUserCircle } from '@fortawesome/free-regular-svg-icons';

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'mini',
	templateUrl: './mini.modal.html',
	styleUrls: ['./mini.modal.scss'],
	providers,
})
export class MiniModal implements OnInit {
	myDiagnGroup: any

	//info_logo: string = "already loaded";

	currentAction: string
	currentModal

	success = false
	fail = false

	//currentDoctor: Doctor;  // arriva dalla lista, non ha tutte le tabelle figlie

	miniDoct: User // 04.02.2021 per nuovo miniB -> spostato

	btnSaveDisabled: boolean // 11.02.2021
	validMiniPwd: boolean // 31.03.2021

	currOrderN: string // per gestire la delete   24.02.2021
	public yesNoOptions = ['Y', 'N']

	faInfoCircle = faInfoCircle // 22.03.2022
	faTriangleExclamation = faTriangleExclamation

	constructor(public session: SessionService, public translator: TranslateService, public activeModal: NgbActiveModal) {
		console.log('miniModal - constructor')
	}

	ngOnInit(): void {
		console.log('miniModal - ngOnInit')

		// 22.02.2021 nascondo msg ok/error
		this.success = false
		this.fail = false

		this.miniDoct = new User(this.session.cryptoUtils)

		this.miniDoct.type = UserType.OPERATOR
		
    // 05.04.2023 meglio optician
    //this.miniDoct.subType = UserType.DOCTOR
		//this.miniDoct.role = Config.PR_DOCTOR
    this.miniDoct.subType = UserType.OPTICIAN
		this.miniDoct.role = Config.PR_OPTICIAN

		this.miniDoct.user_subtype = Config.SUB_MINI // "Mini";

		//this.miniDoct.username = "user@nexus";  // 22.03.2022 sostituisce l'hint, meglio ?

		// forzo la stessa organization dell'utente corrente, poi readonly ?
		let mySuperB = this.session.getCurrentUser()
		//this.myDiagnGroup = mySuperB.getSzDiagnosisGrp();  // 25.01.2022

		this.miniDoct.mainAddress = mySuperB.mainAddress
		this.miniDoct.mainAddress.ref_email = ''
		this.miniDoct.mainAddress.phone1 = ''

		//this.miniDoct.country
		let addrCountry = this.getReadOnlyCountry()
		this.miniDoct.country = Country.getCodeFromDescr(addrCountry)

		/*
    this.miniDoct.mainAddress.organization = mySuperB.mainAddress.organization;
    this.miniDoct.mainAddress.country = mySuperB.mainAddress.country;
    this.miniDoct.mainAddress.province = mySuperB.mainAddress.province;
    this.miniDoct.mainAddress.city = mySuperB.mainAddress.city;
    this.miniDoct.mainAddress.address_line1 = mySuperB.mainAddress.address_line1;
    */
	}

	submit() {
		//if (this.currentAction == "createMini") {  // solo superB
		this.btnSaveDisabled = true
		return this.createMiniB()
		//}
	}

	// 31.03.2021 portata su Util, richiamata anche da altri [ls]
	validatePwd(value) {
		//console.log("validatePwd: "+value);
		// console.log(value);
		this.validMiniPwd = Util.validatePwd(value)
	}

	// 22.03.2022
	validateUsername(value?) {
		if (value == null) {
			value = this.miniDoct.username
		}

		this.fail = this.containsInvalidChars(value)
		if (this.fail) return false

		if (!this.isUniqueUsername(value)) {
			let msg = this.translator.instant('CREATE_USER.CONFLICT_MSG')
			alert(msg)
			return false
		}

		return true
	}

	private containsInvalidChars(value) {
		let ret = false

		// 19.04.2018 blocca il carattere '$' nello username, perche' poi verra' usato come replacement per il .
		if (value.indexOf('$') >= 0) {
			ret = true
			let msg = 'Please avoid using character $ on username' // TODO su json
			alert(msg)
		}
		// 17.08.2021 blocchiamo anche lo spazio
		else if (value.indexOf(' ') >= 0) {
			ret = true
			let msg = 'Please avoid using blank character on username' // TODO su json
			alert(msg)
		}

		return ret
	}

	isUniqueUsername(value) {
		let tot = 0
		let isUnique = true

		let doctList = this.session.getDtDoctorList()

		if (doctList != null) {
			tot = doctList.length
		}
		for (let i = 0; i < tot; i++) {
			if (value == doctList[i].username) {
				isUnique = false // username already exists
				break
			}
		}
		return isUnique
	}

	onChangeUsrname(event?) {
		//console.log("(onChangeUsrname) "+event);
		let value = this.miniDoct.username
		this.containsInvalidChars(value)
	}

	// 22.03.2022 spostata sul mini.modal
	// 04.02.2021
	private createMiniB() {
		// blocca il carattere '$' nello username, perche' poi verra' usato come replacement per il .
		if (this.miniDoct.username.indexOf('$') >= 0) {
			console.log('create user: character $ not allowed')
			alert('Please avoid using character $ on username')
			this.fail = true
			this.btnSaveDisabled = false // per poter riprovare
			return
		}

		//this.validatePwd(this.miniDoct.password);
		this.validMiniPwd = Util.validatePwd(this.miniDoct.password)

		if (!this.validMiniPwd) {
			console.log('(createMiniB) pwd invalid')
			var errMsg = this.translator.instant('PROFILE.INVALID_PASSWORD')
			alert(errMsg)
			this.btnSaveDisabled = false // per poter riprovare
			return
		}

		this.btnSaveDisabled = true
		this.fail = false // reset errori precedenti

		// quelli del doctor che li sta creando, poi li forzano le api?
		var currSettings = this.session.getCurrentUser().settings

		this.session
			.createNewMiniB(this.miniDoct, currSettings)
			.then((id) => {
				console.log('createMiniB: initialize success, id ' + id)
				this.success = true

				// 23.03.2022 alert e poi chiude il modal
				let msg = this.translator.instant('CREATE_USER.DESCRIPTION_SUCCESS')
				alert(msg)
				//this.closeNewMini();
				this.activeModal.close(id)
			})
			.catch((error) => {
				this.fail = true
				console.log('createMiniB: initialize error')
				console.log(error)

				this.btnSaveDisabled = false // per poter riprovare

				/*  28.03.2022 uso funzione comune
      if(error.data) 
        alert("[createUser] "+error.data.error);
      else
        alert("[createUser] KO, error "+error.toString());
      */

				let msg = '[createUser] ' + this.session.parseErrorMessage(error, 'alert')
				alert(msg)
			})
	}

	// 20.09.2022
	getReadOnlyCountry() {
		let superB = this.session.getCurrentUser()
		let ret = superB.getCountry()
		let c = this.session.getSingleCountry(ret)
		if (c) {
			ret = c.name + '  (' + ret + ')'
		}
		return ret
	}

	private closeNewMini() {
		//if(this.currentModal)
		//this.currentModal.dismiss();

		this.activeModal.dismiss()
		// this.manageDoctList(); // refresh  TEMP TODO
	}

	// -----------------------------------------------

	validateLogo(): boolean {
		var nomeFile = (<HTMLInputElement>document.getElementById('logo')).files[0]

		if (nomeFile != null) {
			console.log('(validateLogo) file immagine scelto: ' + nomeFile.name)

			// ammessi solo .png per avere bg trasparente
			var isValidImg = false
			var usrFileName = nomeFile.name.toLowerCase()
			if (usrFileName.indexOf('.png') > 0) {
				isValidImg = true
			}

			if (!isValidImg) {
				// TODO, tradurre
				//var msg = "update doctor: ko image format, file: "+nomeFile.name+". Only *.png or *.jpg admitted";
				var msg = 'update doctor: ko image format, file: ' + nomeFile.name + '. Only *.png  admitted'
				console.log(msg)
				alert(msg)
				return false
			} else {
				return true
			}
		} else return true
	}
}

<div class="container-fluid subscription-container">
	<div class="panel panel-default subscription-container__panel position-relative">
		<div class="row">
			<div class="col-12 subscription-container__content">
				<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" (navChange)="onNavChange($event)" class="nav nav-pills">
					<li ngbNavItem="plan" class="nav-item me-3">
						<!-- [hidden]="true" [disabled]="true" -->
						<a ngbNavLink uppercase class="nav-link fw-bold">{{'CREDITS.USR_CREDITS_TITLE' | translate}}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid">
								<div class="row mb-4 position-relative">
									<div class="col" style="min-height: 200px">
										<loader [activateSpinner]="!userPlanReady "></loader>
										<!-- <div class="loader-container">
											<div [hidden]="userPlanReady" class="lds-ring">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div> -->

										<div *ngIf="userPlanReady">
											<app-credits [currUser]="currUser" [saleInfo]="saleInfo"></app-credits>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>

					<li ngbNavItem="balance" class="nav-item me-3">
						<!-- [hidden]="true" [disabled]="true" -->
						<a ngbNavLink uppercase class="nav-link fw-bold">{{'CREDITS.BALANCE_HISTORY' | translate}}</a>
						<ng-template ngbNavContent>
							<div class="container-fluid">
								<div class="row mb-4 position-relative">
									<div class="col" style="min-height: 200px">
										<loader [activateSpinner]="!userBalanceReady "></loader>
										<!-- <div class="loader-container">
											<div [hidden]="userBalanceReady" class="lds-ring">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
										</div> -->
										<div *ngIf="userBalanceReady">
											<balance [currUser]="currUser" [balanceList]="balanceRecords"></balance>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
				</ul>

				<div [ngbNavOutlet]="nav" class="mt-4"></div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit } from '@angular/core'
import { SessionService } from '../service/session.service'

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
	constructor(public session: SessionService) {}

	ngOnInit(): void {
		// if (this.session.isTelerefractEnabled()) {
		// 	this.session.logout();
		// }
	}
}

import { EventEmitter, Injectable, Output } from '@angular/core'
import { Toast } from '../models/toast.model'
//
//
//
//
@Injectable({ providedIn: 'root' })
//
//
//
export class canvasService {
	@Output() open = new EventEmitter<string>()

	private canvasToast: Toast[] = []

	public getCanvasToast() {
		return this.canvasToast.slice()
	}

	getCanvasToastByName(name: string): Toast[] {
		let ret: Toast[] = []

		ret = this.canvasToast.filter((t) => t.options.name == name)

		return ret
	}

	openCanvas() {
		this.open.emit('open')
		// console.log(this.canvasToast)
	}

	addNotification(toast: Toast) {
		this.canvasToast.push(toast)
		this.open.emit('add')
	}

	remove(toast: Toast) {
		this.canvasToast = this.canvasToast.filter((t) => t !== toast)
		this.open.emit('add')
	}

	clear() {
		this.canvasToast.splice(0, this.canvasToast.length)
	}

	getNotificationNumber() {
		return this.canvasToast.length
	}
}

import { Address } from './address.model'
import { AdmSettings, Settings } from './settings.model'
import { Config } from '../../config'
import { DateParser } from './dateParser.model'
import { Util } from './util.model'
import { UserEvents, UserEventsObj } from './userEvents.model'

// 08.08.2018
export class Admin {
	id: string
	name: string // mette insieme
	user_id: number
	firstName: string
	lastName: string
	country: string
	birthDate: Date
	code: string
	//sex: string;
	//race: string;

	mainAddress: Address // 14.06.2021

	note: string

	subscriptionTime: Date
	user_type: string
	username: string

	user_subtype: string // 12.04.2023 Std, Super, ...

	//group: string;
	created_by: number // 08.08.2018

	keybox_distrib: string // per utente Admin qui c'e' la keybox_ns
	key_distrib: string

	settings: Settings
	admSettings: AdmSettings // per utenti manager

	isDeleted: boolean // 14.06.2021
	static idGen = 0

	userEvents: UserEventsObj

	constructor(rawAdm?) {
		this.user_subtype = '-' // "Std";
		this.userEvents = new UserEventsObj()

		if (rawAdm != null) {
			// 12.04.2023 copio i campi con lo stesso nome, poi sistemo gli altri
			var myJsonObj = { ...rawAdm }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			this.id = rawAdm.user_id
			this.name = 'Adm_' + rawAdm.code // altrimenti ko ricerca per nome

			//this.username = rawAdm.username
			//this.firstName = rawAdm.firstName
			//this.lastName = rawAdm.lastName
			//this.country = rawAdm.country
			//this.firstName = this.name; // ??

			// campi in chiaro
			//result.sex = rawAdm.sex;
			//result.race = rawAdm.race;

			//result.address = rawAdm.address_label;
			//this.user_type = rawAdm.user_type
			//this.created_by = rawAdm.created_by
			//if (rawAdm.keybox_distrib != null) this.keybox_distrib = rawAdm.keybox_distrib

			if (rawAdm.is_deleted == 'Y') {
				// 30.03.2017
				this.code = 'DEL_' + rawAdm.code
				this.isDeleted = true
			} else {
				//this.code = rawAdm.code
				this.isDeleted = false
			}

			this.subscriptionTime = DateParser.parseDate(rawAdm.subscription_time)

			if (rawAdm.settings) {
				this.settings = new Settings(rawAdm.settings)
			}

			if (rawAdm.setting_admin) {
				// 14.06.2021
				this.admSettings = new AdmSettings(rawAdm.setting_admin)
				console.log('(createAdm) id:' + this.id + ', models: ' + rawAdm.setting_admin.models)
			}
		}
	}

	public setUserEvents(events: UserEvents[]) {
		this.userEvents = new UserEventsObj(events)
	}

	public updateUserEvents(events: UserEvents[]) {
		let newEvents = new UserEventsObj(events)
		let newArray = this.userEvents.userEvent.concat(newEvents.userEvent)

		this.userEvents.askDate = newEvents.askDate
		this.userEvents.userEvent = newArray
	}

	// 14.06.2021
	isVice() {
		var flag = false
		flag = this.user_type == Config.PR_VICE
		return flag
	}

	isManager() {
		var flag = false
		flag = this.user_type == Config.PR_MANAGER
		return flag
	}

	isStats() {
		var flag = false
		flag = this.user_type == Config.PR_STATS
		return flag
	}

	// 27.12.2022
	isSupport() {
		var flag = false
		flag = this.user_type == Config.PR_SUPPORT
		return flag
	}

	// 13.04.2023
	isSuper() {
		var flag = false
		flag = this.user_subtype == Config.SUB_SUPER
		return flag
	}

	// 15.06.2021
	getModels() {
		var ret = ''
		if (this.isManager()) {
			if (this.admSettings) {
				ret = this.admSettings.models
			}
		}
		return ret
	}

	static createAdmin(rawAdm, cryptoUtils, pwdDist, ignoreCritt?): Promise<Admin> {
		// campi in chiaro
		var result = new Admin(rawAdm)

		var boxDistrib = rawAdm.keybox_distrib
		if (!boxDistrib) {
			//console.log("(createAdm) 1 - solo dati in chiaro per adm "+rawAdm.code);
			return Promise.resolve(result) // solo dati in chiaro
		}

		// 15.11.2017 esco subito
		if (ignoreCritt != null && ignoreCritt == true) {
			console.log('(createAdm) 2 - solo dati in chiaro per adm ' + rawAdm.code)
			return Promise.resolve(result)
		}

		var usrname = result.username.toLowerCase() // 29.04.2020

		// 10.02.2017 senza promise
		var keyDati = cryptoUtils.decryptBoxWithPwdS(pwdDist, boxDistrib, usrname)

		// result.key_distrib = angular.copy(keyDati);
		//result.key_distrib = { ...keyDati };
		result.key_distrib = keyDati

		var bag = cryptoUtils.generateBag()

		bag.firstname = rawAdm.firstname
		bag.lastname = rawAdm.lastname

		if (rawAdm.addresses && rawAdm.addresses.length > 0) {
			bag.address_line1 = rawAdm.addresses[0].address_line1
			bag.province = rawAdm.addresses[0].province
			bag.city = rawAdm.addresses[0].city
			bag.country = rawAdm.addresses[0].country
			bag.phone1 = rawAdm.addresses[0].phone1
			bag.organization = rawAdm.addresses[0].organization
		}

		cryptoUtils.purge(bag)

		// 20.03.2020 usa alias piu' chiaro
		return (
			cryptoUtils
				.decryptDataWithKey(keyDati, bag)
				//return cryptoUtils.decryptFromBase64ToBase64Content(keyDati, bag)

				.then((bag) => {
					result.firstName = bag.firstname
					result.lastName = bag.lastname

					var docName = Util.getFullName(bag.firstname, bag.lastname)

					if (docName) {
						result.name = docName // else tengo il default, con l'id --ls
					}

					// 14.06.2021 uso oggetto
					result.mainAddress = new Address(bag)
					return result
				})
				.catch((err) => {
					console.log('(createAdm) ko decryptBag ')
					console.log(err)
					return result
				})
		)
	}

	// 08.08.2018
	static createAdminList(response: AdminsResponse, cryptoUtils, pwdDistrib, ignoreCritt?) {
		var result = []

		//console.log(response);

		// 28.01.2022 rimasto a vecchio!
		//let rawList = response.data.data;
		let rawList = response.data

		for (var i = 0; i < rawList.length; i++) {
			var admin = rawList[i]
			result.push(Admin.createAdmin(admin, cryptoUtils, pwdDistrib, ignoreCritt))
		}

		console.log('(createAdminList) fine ciclo, tot: ' + i + ' ignoreCritt? ' + ignoreCritt)

		// return cryptoUtils.q.all(result);
		return Promise.all(result) // 28.01.2022
	}
}

export interface AdminJson {
	user_id: number
	firstname: string
	lastname: string
	user_type: string
	subscription_time: string
	code: string
	keybox_distrib: string
	is_deleted: string
	created_by: number
	username: string
}

export interface AdminResponse {
	admin: AdminJson
}

export interface AdminsResponse {
	data: AdminJson[]
}

/*
  // 08.08.2018
export interface AdminsResponse {
  //data: {
      data: [{
          user_id: number;
          firstname: string;
          lastname: string;
          user_type: string;
          subscription_time: string;
          code: string;
          keybox_distrib: string;
          is_deleted: string;
          created_by: number;
          username: string;
          //pathology_group: number;  
      }]
  //}
}
*/

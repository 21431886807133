<div class="update-list-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-10 tab-container">
				<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-pills" [destroyOnHide]="false" (navChange)="onNavChange($event)">
					@for (tab of tabs; track tab){
					<li [ngbNavItem]="tab" class="nav-item me-3">
						<a ngbNavLink uppercase class="nav-link fw-bold">{{tab.label}}</a>
					</li>
					}
				</ul>
			</div>
			<div class="col-2">
				<button (click)="openModal()" class="btn btn-primary float-end" type="button">{{'SW_UPDT.BUTTON_NEW' | translate }}</button>
			</div>
		</div>
		@if(activeTab.label == 'VX610'){
		<div class="row mt-4">
			<div class="col-12">
				<p class="filter-title-section w-50" (click)="showFilters = !showFilters" [ngClass]="showFilters ? '' : 'border-bottom' ">
					<label class="fw-bold uppercase">{{'SW_UPDT.FILTERS.TITLE' | translate }}</label>
					<fa-icon [icon]="faSortDown" faSortDown [ngClass]="showFilters ? 'show' : '' " aria-hidden="true" class="float-end"></fa-icon>

					<span *ngIf="filterSet" class="me-2 fw-light float-end">{{'DISTRIB_LIST.FILTERS.MESSAGE' | translate}}<fa-icon [icon]="faCircleExclamation" faCircleExclamation aria-hidden="true"></fa-icon></span>
				</p>

				<form [formGroup]="filterForm" class="filter-form__container d-flex" [ngClass]="showFilters ? 'show' : '' ">
					<!-- OS -->
					<!-- <div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'SW_UPDT.FILTERS.OS_VERSION' | translate }}</mat-label>
	
								<mat-select name="os" (selectionChange)="filterTableValue()" formControlName="os">
									<mat-option *ngFor="let os of availableOS" [value]="os"> {{os}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('os').value != null ? 'show': '' " (click)="filterForm.get('os').reset(); filterTableValue()"></fa-icon>
						</div> -->

					<!-- BRAND -->
					<!-- <div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
							<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
								<mat-label style="color: #4f0aeb">{{'SW_UPDT.FILTERS.BRAND' | translate }}</mat-label>
	
								<mat-select name="brand" (selectionChange)="filterTableValue()" formControlName="brand">
									<mat-option *ngFor="let brand of availableBrand" [value]="brand"> {{brand}} </mat-option>
								</mat-select>
							</mat-form-field>
							<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('brand').value != null ? 'show': '' " (click)="filterForm.get('brand').reset(); filterTableValue()"></fa-icon>
						</div> -->

					<!-- DEV TYPE ONLY VX610 -->
					<div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
						<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
							<mat-label style="color: #4f0aeb">{{'SW_UPDT.FILTERS.TYPE' | translate }}</mat-label>

							<mat-select name="dev_type" (selectionChange)="filterTableValue()" formControlName="dev_type">
								<mat-option *ngFor="let type of availableDevType" [value]="type"> {{type}} </mat-option>
							</mat-select>
						</mat-form-field>
						<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('dev_type').value != null ? 'show': '' " (click)="filterForm.get('dev_type').reset(); filterTableValue()"></fa-icon>
					</div>
				</form>
			</div>
		</div>
		}

		<div class="row" [ngClass]="activeTab.label != 'VX610' ? 'mt-4' : '' ">
			<div class="col-12">
				<table mat-table [dataSource]="updateList" matSort style="width: 100%" multiTemplateDataRows>
					<!-- DevType Column -->
					<ng-container matColumnDef="dev_type">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.DEV_TYPE' | translate }}</th>
						<td mat-cell *matCellDef="let element" style="font-weight: bold">{{ element.dev_type }}</td>
					</ng-container>

					<!-- OS Column -->
					<ng-container matColumnDef="os">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.OS' | translate }}</th>

						<td mat-cell *matCellDef="let element">{{ element.os_ver }}</td>
					</ng-container>

					<!-- brand Column -->
					<!-- <ng-container matColumnDef="brand">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.BRAND' | translate }}</th>
	
						<td mat-cell *matCellDef="let element">{{ element.branding }}</td>
					</ng-container> -->

					<!-- min_ver Column -->
					<ng-container matColumnDef="min_ver">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.MIN_BUILD' | translate }}</th>

						<td mat-cell *matCellDef="let element">
							<div class="text-truncate">{{ element.min_build_num }}</div>
						</td>
					</ng-container>

					<!-- Build Number Column -->
					<ng-container matColumnDef="build_num">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.BUILD' | translate }}</th>

						<td mat-cell *matCellDef="let element">{{element.build_ver}} ({{ element.build_num }})</td>
					</ng-container>

					<!-- description Column -->
					<ng-container matColumnDef="description">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.DESCRIPTION' | translate }}</th>

						<td mat-cell *matCellDef="let element">
							<div class="text-truncate">{{ element.description }}</div>
						</td>
					</ng-container>

					<!-- Package name only VX610 -->
					<ng-container matColumnDef="package_name">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.PACKAGE_NAME' | translate }}</th>

						<td mat-cell *matCellDef="let element">{{ element.package_name }}</td>
					</ng-container>

					<!-- Release Date Column -->
					<ng-container matColumnDef="release_date">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.UPDATE_DATE' | translate }}</th>

						<td mat-cell *matCellDef="let element">{{ session.formatDate(element.release_date) }}</td>
					</ng-container>

					<!-- filename Column -->
					<ng-container matColumnDef="filename">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'SW_UPDT.FILENAME' | translate }}</th>

						<td mat-cell *matCellDef="let element">
							<div class="text-truncate">{{ element.url }}</div>
						</td>
					</ng-container>

					<!-- Filter and buttons Column -->
					<ng-container matColumnDef="filter">
						<th mat-header-cell *matHeaderCellDef>
							<mat-form-field appearance="outline">
								<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
							</mat-form-field>
						</th>

						<td mat-cell *matCellDef="let element" class="normalcase">
							<!-- <a class="table-button" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="openModalOld(element)"> <fa-icon [icon]="faEdit" aria-hidden="true"></fa-icon> </a>&nbsp;&nbsp; -->

							<a class="table-button" (click)="$event.stopPropagation(); openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}"><fa-icon [icon]="faTrashAlt" faTrashAlt aria-hidden="true"></fa-icon></a>

							<a class="table-button ms-3" (click)="$event.stopPropagation(); pushUpdate(element)" ngbTooltip="{{'SW_UPDT.PUSH_UPDATE' | translate }}"><fa-icon [icon]="faCircleRight" faCircleRight aria-hidden="true"></fa-icon> </a>
						</td>
					</ng-container>

					<ng-container matColumnDef="expand">
						<th mat-header-cell *matHeaderCellDef aria-label="row actions">
							<fa-icon [icon]="faFileCsv" faFileCsv (click)="downloadLsit()" ngbTooltip="{{'SW_UPDT.DOWNLOAD_LIST' | translate }}"></fa-icon>
						</th>
						<td mat-cell *matCellDef="let element">
							<div aria-label="expand row" *ngIf="element.targets.length > 0">
								<fa-icon [icon]="faCaretDown" aria-hidden="true" *ngIf="expandedElement !== element"></fa-icon>
								<fa-icon [icon]="faCaretUp" aria-hidden="true" *ngIf="expandedElement === element"></fa-icon>
							</div>
						</td>
					</ng-container>

					<!-- detail row -->
					<ng-container matColumnDef="expandedDetail">
						<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
							<div class="additional-element-detail" (click)="$event.stopPropagation()" *ngIf="element.targets.length > 0" [ngClass]="{ 'px-5 py-3': expandedElement === element }">
								<table class="table">
									<thead>
										<tr style="font-size: 0.8em">
											<th scope="col">{{'SW_UPDT.EXPANDED_DETAILS.N_PUSH' | translate }}</th>
											<th scope="col">{{'SW_UPDT.EXPANDED_DETAILS.N_DEVICE' | translate }}</th>
											<th scope="col">{{'SW_UPDT.EXPANDED_DETAILS.PUSH_DATE' | translate }}</th>
											<th scope="col">{{'SW_UPDT.EXPANDED_DETAILS.DOWNLOAD' | translate }}</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th>{{element.publishing}}</th>
											<td>{{element.targets.length}}</td>
											<td>{{session.formatDateTime(element.last_publishing)}}</td>
											<td>
												<fa-icon [icon]="faFileCsv" faFileCsv (click)="download(element)"></fa-icon>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>

					<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openDetailRow(row)"></tr>
					<tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row" class="no-border" [class.colapsed-row]="expandedElement != element"></tr>
				</table>
			</div>

			<mat-paginator [pageSizeOptions]="[10, 15, 20, 25, 50]" showFirstLastButtons> </mat-paginator>
		</div>
	</div>
</div>

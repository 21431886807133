import { DateParser } from './dateParser.model'
import { serviceType } from './salePlan.model'

export enum AgrStatus {
	ACCEPTED = 'accepted',
	AVAILABLE = 'available',
	IGNORE = 'ignore',
}

export enum AgrType {
	TERMS = 'terms',
	PRIVACY = 'privacy',
	AUTOREG = 'autoreg',
	CONSENT = 'consent',
	AISERVICE = 'ai_service',
	AIDEVICERENT = 'ai_device_rental',
	AIDEVICERENTFIN = 'ai_device_rental_finance',
	RR = 'RR',
}

//static STATUS = ["accepted", "available", "ignore"]; // 27.05.2022

// 25.05.2022
export class Agreement {
	id: number
	userId: number // dovrebbe arrivare dalla response, ma ho tacconato inserendolo a mano dopo la chiamata
	country: string
	lang: string
	body: string
	url: string
	doc_type: AgrType | null // terms o privacy
	acceptanceDate: Date //21-02-23
	validFrom?: Date
	status: string
	is_valid: string
	target: string
	// is_valid: boolean;

	constructor(rawObj?) {
		this.id = 0
		this.userId = 0
		this.country = ''
		this.lang = ''
		this.body = ''
		this.url = ''
		this.doc_type = null
		this.acceptanceDate = null //non viene fatto un replacement da quell che arriva con la get
		this.validFrom = null
		this.status = ''
		this.is_valid = ''
		this.target

		if (rawObj) {
			// copia tutti i campi con lo stesso nome
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			//21-02-23
			if (rawObj.acceptance_date) {
				this.acceptanceDate = DateParser.parseDate(rawObj.acceptance_date)
			}

			if (rawObj.valid_from) {
				this.validFrom = DateParser.parseDate(rawObj.valid_from)
			}
		}

		// this.is_valid = this.isValid();
	}

	isValid() {
		let ret = this.id > 0 || this.country != '' // 21-02-23 forse meglio vedere se c'é la data?
		// let ret = this.acceptanceDate != null;
		return ret
	}

	// static decodeStatus(status: string): AgrStatus {
	// 	let ret = AgrStatus.IGNORE
	// 	switch (status) {
	// 		case 'accepted':
	// 			ret = AgrStatus.ACCEPTED
	// 			break
	// 		case 'available':
	// 			ret = AgrStatus.AVAILABLE
	// 			break
	// 		case 'ignore':
	// 			ret = AgrStatus.IGNORE
	// 			break
	// 	}
	// 	return ret
	// }
}

// 27.05.2022 mini classe su oggetto user
export class AgreementInfo {
	agreement_id: number[]
	status: AgrStatus
	acceptance_date: Date //date when the agreement is accepted

	constructor() {
		this.agreement_id = []
		this.status = AgrStatus.IGNORE
		this.acceptance_date = null
	}

	// /*
	// setMyStatus(myVal: AgrStatus){
	// 	this.status = myVal;
	// }
	// */

	// setStatus(myVal: string) {
	// 	this.status = Agreement.decodeStatus(myVal)
	// }
}

export class AgreementsStatus {
	doc_type: AgrType | null
	status: AgrStatus
	service: serviceType | string

	constructor(rawObj?) {
		this.doc_type = null
		this.status = AgrStatus.IGNORE
		this.service = ''

		if (rawObj) {
			var myJsonObj = { ...rawObj }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}
		}
	}
}

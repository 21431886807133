import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core'

// MAT TABLE IMPORT
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs, Preferences } from '../../models/settings.model'

import { Util } from 'src/app/models/util.model'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { DataModelService } from 'src/app/service/data-model.service'
import { SessionService } from 'src/app/service/session.service'
import { AppToastService } from 'src/app/service/toast.service'

import { Config } from 'src/config'
import { GraderModal } from './grader.modal'
import { Distrib } from '../../models/specialist.model'
import { ToastOptions } from 'src/app/models/toast.model'
import { User } from 'src/app/models/user.model'

import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-regular-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

@Component({
	selector: 'app-clinics',
	templateUrl: './clinics.component.html',
	styleUrls: ['./clinics.component.scss'],
})
export class ClinicsComponent implements OnInit, OnDestroy {
	graderList: MatTableDataSource<Distrib>
	@ViewChild(MatTable) table: MatTable<any>
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: true }) sort: MatSort
	@ViewChild('filter') input: ElementRef
	displayedColumns: string[]

	currentAction: string
	currentModal

	ignoreCritt: boolean
	isLoading: boolean

	disableG: boolean

	currUser: User
	country: string
	selectedGrader: Distrib

	clinicPref: TablePrefs
	localStorageName: string

	faTrashAlt = faTrashAlt
	faEdit = faEdit
	faEye = faEye
	faSpinner = faSpinner

	constructor(
		public session: SessionService,
		private translator: TranslateService,
		public modalService: NgbModal,
		private toastService: AppToastService,
		private dataService: DataModelService
	) {
		this.currUser = this.session.user
		this.country = this.currUser.getCountry()

		this.disableG = false
		this.selectedGrader = new Distrib()

		this.localStorageName = ''

		this.ignoreCritt = false

		this.displayedColumns = ['username', 'code', 'reports', 'activated', 'disabled', 'filter']

		this.graderList = new MatTableDataSource<Distrib>([])
	}

	ngOnInit(): void {
		// console.log(this.currUser)

		if (this.dataService.hasLoadedDistribList()) {
			setTimeout(() => {
				let list = this.session.getDtDistribList()
				this.manageGraderList(list)
			}, 100) // avendo giá la lista, i viewchild non fanno in tempo ad essere pronti
		} else {
			this.isLoading = true
			this.loadRemoteList()
		}

		// console.log(this.table)
	}

	private manageTablePreferences() {
		Util.debug('(ClinicsComponent) - manage Table preferences')
		this.localStorageName = this.session.getUserId() + ' - clinicsPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.clinicPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.clinicPref = new TablePrefs()

			this.clinicPref.sort = 'username'
			this.clinicPref.dir = 'asc'

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.clinicPref))
		}

		this.applySettings()
	}

	// load table data
	private manageGraderList(data) {
		// this.ignoreCritt = true
		// if (this.session.isAdmin()) {
		// 	this.ignoreCritt = false
		// }
		this.graderList = new MatTableDataSource<Distrib>(data)
		this.graderList.paginator = this.paginator
		this.graderList.sort = this.sort

		this.isLoading = false

		this.manageTablePreferences()
	}

	private loadRemoteList() {
		Util.debug('(ClinicsComponent) - loadRemoteList')

		this.session
			.loadRemotes(this.ignoreCritt)
			.then((data: Distrib[]) => {
				console.log(data)
				this.manageGraderList(data)
			})
			.catch((err) => {
				console.log(err)
				if (!this.session.isExpired(err)) {
					var msg = err.data ? err.data.error : err.toString()
					// alert(msg)

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = msg

					let options = new ToastOptions('error')

					this.toastService.show(header, body, false, options, 'center')
				}
				this.manageGraderList([])
			})
	}

	private applySettings() {
		Util.debug('(ClinicsComponent) - applySettings')
		// print data sort
		this.sort.active = this.clinicPref.sort
		this.sort.direction = this.clinicPref.dir
		this.sort.sortChange.emit()
		// print data paginator
		// this.paginator.pageIndex = this.clinicPref.currentPage
		// this.paginator.pageSize = this.clinicPref.itemsPerPage
		// this.graderList.paginator.page.emit()

		// search
		this.graderList.filter = this.clinicPref.filter
		this.input.nativeElement.value = this.clinicPref.filter

		// listen sort
		this.graderList.sort.sortChange.subscribe(() => {
			// save variables
			this.clinicPref.sort = this.sort.active
			this.clinicPref.dir = this.sort.direction
		})
		// listen paginator
		// this.graderList.paginator.page.subscribe(() => {
		// 	this.clinicPref.itemsPerPage = this.paginator.pageSize
		// 	this.clinicPref.currentPage = this.paginator.pageIndex
		// })
	}

	openGraderModal(grader: Distrib) {
		// console.log(grader)

		if (grader) {
			this.currentAction = Config.ACT_EDIT
		} else {
			this.currentAction = Config.ACT_CREATE
		}
		Util.debug('(ClinicsComponent) - addGrader')

		this.currentModal = this.modalService.open(GraderModal, { size: 'xl', keyboard: false })
		this.currentModal.componentInstance.country = this.country
		this.currentModal.componentInstance.currentAction = this.currentAction

		if (grader) {
			this.currentModal.componentInstance.grader = grader
		}

		this.currentModal.result
			.then((resp) => {
				//console.log("clinic modal closed: "+resp)

				if (resp) {
					let header = this.translator.instant('TOAST.HEADER.SUCCESS')
					let body = this.translator.instant('TOAST.GRADER') + ' id: ' + resp + '. ' + this.translator.instant('TOAST.BODY.CREATED')

					let options = new ToastOptions('success')

					this.toastService.show(header, body, false, options, 'bottom-right')

					this.loadRemoteList()
				}
			})
			.catch(() => {
				Util.debug('Dismissed')
			})
	}

	openDeleteModal(grader: Distrib) {
		Util.debug('(ClinicsComponent) - delete grader')

		let text = this.translator.instant('CLINIC.DELETE_GRADER', { v1: grader.username })

		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false })
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		this.currentModal.result
			.then((resp) => {
				Util.debug('(delDoc) After modal closed: ' + resp) // confirmed = true/false
				if (resp) {
					this.deleteRemote(grader)
				}
			})
			.catch(() => {
				Util.debug('Dismissed')
			})
	}

	private deleteRemote(grader: Distrib) {
		if (!this.session.isClinicAdmin()) {
			Util.debug('DL Utente non abilitato, act: ' + this.currentAction)
			return
		}

		// controllo fatto sul chiamante
		// conferma password dispositiva per delete

		this.session
			.deleteDistrib(grader.id) // doc ?
			.then(() => {
				// alert('OK, user deleted!') // 23.03.2022

				let header = this.translator.instant('TOAST.HEADER.SUCCESS')
				let body = this.translator.instant('TOAST.GRADER') + ' ' + grader.name + ' ' + this.translator.instant('TOAST.BODY.DELETED')
				let options = new ToastOptions('success')

				this.toastService.show(header, body, false, options, 'bottom-right')

				this.loadRemoteList()
			})
	}

	disableGrader(grader: Distrib, event) {
		this.disableG = true

		if (event.target.checked) {
			Util.debug('(disableGrader) going to disable')
			this.session
				.disableGrader(grader.user_id)
				.then(() => {
					grader.isEnabled = !event.target.checked

					let header = this.translator.instant('TOAST.HEADER.SUCCESS')
					let body = this.translator.instant('TOAST.GRADER') + ' ' + grader.username + ' ' + this.translator.instant('TOAST.BODYERR.DISABLED')

					let options = new ToastOptions('success')

					this.toastService.show(header, body, false, options, 'bottom-right')
				})
				.catch((err) => {
					console.log(err)

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.session.parseErrorMessage(err)
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')
				})
		} else {
			Util.debug('(disableGrader) going to re-activate')
			this.session
				.restoreGrader(grader.user_id)
				.then(() => {
					grader.isEnabled = !event.target.checked

					let header = this.translator.instant('TOAST.HEADER.SUCCESS')
					let body = this.translator.instant('TOAST.GRADER') + ' ' + grader.username + ' ' + this.translator.instant('TOAST.BODY.ENABLED')

					let options = new ToastOptions('success')

					this.toastService.show(header, body, false, options, 'bottom-right')
				})
				.catch((err) => {
					console.log(err)

					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.session.parseErrorMessage(err)
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')
				})
		}

		setTimeout(() => {
			this.disableG = false
		}, 1000)
	}

	filterText() {
		this.clinicPref.filter = this.input.nativeElement.value
		this.clinicPref.filter = this.clinicPref.filter.trim().toLocaleLowerCase()
		this.graderList.filter = this.clinicPref.filter
	}

	ngOnDestroy(): void {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.clinicPref))
	}
}

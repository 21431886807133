import { Injectable } from '@angular/core'

import { Exam, ExamImage, FundusExam, ExamReq } from '../models/exam.model'
import { Visit } from '../models/visit.model'
import { SessionService } from './session.service'

import { Config } from '../../config'

import { Util } from '../models/util.model'
import { Subject } from 'rxjs'
import { AiPatient, AiReport, aiBatchId } from '../models/aiReport.model'
import { Patient } from '../models/patient.model'

// interface ReportAiMemoryList {
// 	id: number
// 	aiList: AiReport[]
// }

@Injectable({ providedIn: 'root' })
export class visitListService {
	doctorId: number
	patientId: number
	currentPatient: Patient

	private aiBatchId: string
	private currDevice: string

	private fundusExamList: FundusExam[] //esami in memoria
	private fundusExamListRequested: FundusExam[] //esami che vengono richiesti, nuovi o giá in memoria, che vengono poi ritornati al chiamante

	private reportAiList: AiReport[]
	private reportAiListQueue: aiBatchId[]
	private waitNewAiReport: boolean //quando apro la AiReport list, se questo é true rimane in ascolto per ricevere la lista aggiornata
	// ArrayReportAiList: ReportAiMemoryList[]

	private ReportAiListIstance: any[]

	constructor(private session: SessionService) {
		Util.debug('VisitListService - START')

		this.fundusExamList = []
		this.fundusExamListRequested = []

		this.reportAiList = []
		this.reportAiListQueue = []
		this.waitNewAiReport = false

		this.ReportAiListIstance = []
	}

	// ### inizialization from visitList###
	// solo quando sono nella visitist, solo per test
	public inizialize() {
		Util.debug('VisitListService - inizialize')

		// console.log(this.doctorId)
		// console.log(this.patientId)
		// console.log(this.currentPatient)
	}

	// ### AIReview ####
	// func. per salvare le fundus giá scaricate in memoria e non richiederle nuovamente

	public checkFundusInMemory(examList: ExamReq[]) {
		Util.debug('VisitListService - checkFundusInMemory')

		this.fundusExamListRequested = []

		let examListCopy = examList.slice()

		// console.log(examListCopy)
		// console.log(this.fundusExamList)

		const promise = new Promise<FundusExam[]>((resolve, reject) => {
			if (this.fundusExamList.length == 0) {
				Util.debug('(visitListService - checkFundusInMemory) Nessuna fundus richiesta precedentemente..')
			} else {
				Util.debug('(visitListService - checkFundusInMemory) Ci sono fundus richieste precedentemente..')
				for (let i = 0; i < examList.length; i++) {
					const exam = examList[i]

					let list = this.fundusExamList.filter((d) => d.id == exam.id) //filtro la lista per exam id, se vuota vuol dire che devo richiederlo

					// console.log(list)

					// se lista non vuota, non serve che richiedo quell'esame
					if (list.length > 0) {
						Util.debug('(visitListService - checkFundusInMemory) rimuovo lesame giá scaricato dalla lista')

						let indx = examListCopy.map((i) => i.id).indexOf(list[0].id) //examListCopy potrebbero non essere nello stesso ordine per cui devo cercare l'indice

						examListCopy.splice(indx, 1)

						this.fundusExamListRequested.push(list[0])
					}
				}
			}

			// console.log(this.fundusExamList)
			// console.log(this.fundusExamListRequested)

			// se examListCopy non é vuota vuol dire che ci sono esami da richiedere
			if (examListCopy.length > 0) {
				this.session
					.loadCategoryExams(examListCopy, Config.CAT_FUNDUS)
					.then((list) => {
						for (let i = 0; i < list.length; i++) {
							const exam = list[i]

							this.fundusExamList.push(exam)

							this.fundusExamListRequested.push(exam)
						}
						// console.log(this.fundusExamList)
						// console.log(this.fundusExamListRequested)

						resolve(this.fundusExamListRequested)
					})
					.catch((err) => {
						Util.debug('VisitListService - loadCategoryExams fails')
						console.log(err)
						reject(err)
					})
			} else {
				resolve(this.fundusExamListRequested)
			}
		})

		return promise
	}

	// func per richiedere la quality di una immagine, ritorna la quality
	public getAiQuality(myImage: ExamImage) {
		Util.debug('(visitListService - getAiQuality) ')

		const promise = new Promise<number>((resolve, reject) => {
			this.session
				.getAiQuality(myImage)
				.then((ris) => {
					//Util.debug(ris);
					let qLevel = 0
					if (ris && ris.qualityLevel) {
						qLevel = ris.qualityLevel
					}
					Util.debug('(requireAI) imgId: ' + myImage.imgId + ' ' + myImage.descr + ' level: ' + qLevel)
					myImage.quality = qLevel

					resolve(qLevel)
				})
				.catch((err) => {
					Util.debug('(getAiQuality) ko ')
					// alert(msg)

					reject(err)
					// setTimeout(() => {
					// 	//for test
					// 	resolve(5)
					// }, 8000)
				})
		})

		return promise
	}

	// ## Step finale dopo aver chiesto la quality, se user continua vengono inviate
	public aiReportRequest(myImages: ExamImage[]) {
		Util.debug('(visitListService - aiReportRequest) ')

		// console.log(myImages)

		this.currDevice = this.getDevice(myImages)

		// console.log(this.currDevice)

		const promise = new Promise<string>((resolve, reject) => {
			let options = {
				//channel: this.aiType,
				language: this.session.getLanguage(),
				timezone: Util.getSzTimezoneOffset(), // "+0200" ok per Europa/Rome
				device: this.currDevice,
			}

			// console.log(options)

			if (!options.device) {
				let msg = 'Options parameters without device!'
				reject(msg)
			}

			// chiede la key x crittare i dati paziente, poi invia richiesta grading con tutte img buone
			this.getPatInfoCri()
				.then((patInfoCritted: any) => {
					let patId = this.currentPatient.id
					// console.log(patInfoCritted)

					//let patInfoCritted = "rtbmOESDh6nClPJZIfyxUfQ+egpcvM11Mhxa8NgshG4="
					//this.stepsAI += "<br>Data ready to be uploaded...";
					//parseInt(patId)  // 16.09.2022

					this.session
						.getAiReport(patId, patInfoCritted, this.aiBatchId, myImages, options)
						.then((ris) => {
							Util.debug(ris) // ok, stampa il batchId

							resolve(ris)
						})
						.catch((err) => {
							// let msg = this.session.parseErrorMessage(err, 'alert')
							// alert(msg)

							reject(err)
						})
				})
				.catch((err) => {
					console.log(err)
					let msg = this.session.parseErrorMessage(err, 'alert')
					reject(msg)
					// resolve('gtbrini-5489dnj3-vsdndus') //for test
				})
		})

		return promise
	}

	// TO DO Da fixare nel caso immagini da device diversi
	private getDevice(images: ExamImage[]) {
		let examId

		// fix quando si seleziona solo 1 immagine destra, ritornava device undefined
		if (images[0].examId > 0) {
			examId = images[0].examId
		} else {
			examId = images[1].examId
		}

		for (let i = 0; i < this.fundusExamList.length; i++) {
			const exam = this.fundusExamList[i]

			if (exam.id == examId) {
				return exam.device
			}
		}
	}

	// 21.07.2022 need this to encrypt patient data to be on the report
	private getPatInfoCri() {
		Util.debug('visitListService - getPatInfoCri')
		return this.session.getAiKey().then((ris) => {
			if (ris && ris.keybox) {
				let resp = ris.keybox // contiene sia la keybox che il batchId
				//Util.debug(resp);

				this.aiBatchId = resp.batch
				let keybox = resp.symmetricKey

				Util.debug('(getAiKeybox) batch:' + this.aiBatchId) // ok
				Util.debug('(getAiKeybox) box:' + keybox) // ok

				// patInfoCritted
				return this.crittaPatient(keybox)
			} else {
				Util.debug(ris)
				return Promise.reject('ko ')
			}
		})
	}

	private crittaPatient(aiKeybox?) {
		let myPatInfo: AiPatient // nested json

		let myPat = this.currentPatient

		Util.debug('(crittaPatient) DOB:' + myPat.birthDate + ' toVistel: ' + myPat.getDobForAi())

		myPatInfo = {
			name: myPat.getFullName(),
			gender: myPat.getGenderForAi(),
			birthday: myPat.getDobForAi(), // YYYYMMDD,
			isDiabetes: null,
			isHypertension: null,
			isHighMyopia: null,
			laserPhotocoagulation: {
				exist: null,
				data: [],
			},
			medicalHistory: null,
		}

		return this.session
			.extractAiRedKey(aiKeybox) // with user's RSA private key
			.then((redKey) => {
				// 22.07.2022 forzato stringify
				return this.session.encryptData(redKey, JSON.stringify(myPatInfo))
			})
			.catch((myErr) => {
				Util.debug('(crittaPat) ko!')
				return Promise.reject(myErr)
			})
	}

	// ### AIReview END #####

	// ### loadingFundus ready ###
	public loadingFundus = new Subject<boolean>() //nella visitlist viene chiamato il next quando vengono caricate le immagini

	//#### loadingFundus ENd ###

	// ### Loading ReportAI #####
	public requestAiReports(batchId?: aiBatchId): Promise<AiReport[]> {
		// se batch_id presente, allora avvio un Intervallo che chiede ogni 20 sec per 6 volte la ReportAi list, solo quando status del nuovo report arrivato é >3 allora la rispondo al chiamante
		Util.debug('VisitListService - loadReportAiRequest')

		const promise = new Promise<AiReport[]>((resolve, reject) => {
			if (batchId) {
				// console.log(this.reportAiListQueue)
				this.reportAiListQueue.push(batchId)
				// console.log(this.reportAiListQueue)
				this.waitNewAiReport = true

				let n = 0

				var refreshReportAiList = setInterval(() => {
					Util.debug('VisitListService - loadReportAiRequest - setInterval STARTS n: ' + n)

					n++

					// console.log('reportNum: ' + reportNum + ' < newReportNum : ' + newReportNum)
					// console.log('New request of load report AI n: ' + n)
					this.loadAiReports().then(() => {
						let newReport = this.reportAiList.filter((rep) => rep.batch_id == batchId.batch)

						// console.log(newReport)
						if (newReport) {
							if (newReport.length > 0 && newReport[0].status >= 3) {
								clearInterval(refreshReportAiList) //fermo l'intervallo perché il report é arrivato
								this.waitNewAiReport = false
								resolve(this.reportAiList)
							}
						}

						this.loadingAiReports.next(this.reportAiList)
					})

					if (n == 8) {
						clearInterval(refreshReportAiList) // fermo l'intervallo dopo 6 tentativi
						this.waitNewAiReport = false
						resolve(this.reportAiList)
					}
				}, 20000)

				this.ReportAiListIstance.push(refreshReportAiList)
				//salvo l'intervallo in un array, potrebbe succedere che dne vengono avviati piú di uno ion simultanea, e potrebbe succedere che devo bloccarli tutti
			} else {
				Util.debug('Nessun batch_id chiamata semplice')
				this.loadAiReports().then(() => {
					resolve(this.reportAiList)
				})
			}
		})
		return promise
	}

	private loadAiReports(): Promise<any> {
		// const promise = new Promise<AiReport[]>((resolve, reject)=>{

		return this.session
			.loadAiReports(this.patientId)
			.then((replist) => {
				// console.log(replist)

				if (replist != null) {
					if (replist.length > 0) {
						// this.hasReportsAi = true
						this.reportAiList = replist
						// console.log(this.reportAiList)
					}

					Util.debug('VisitListService - loadReportAi tot: ' + replist.length)
				}

				// resolve(replist)
			})
			.catch((err) => {
				if (!this.session.isExpired(err)) {
					Util.debug('(countNewAiReports) KO ')
					Util.debug(err)
				}
				// reject(false)
			})
		// })
		// return promise
	}

	public getReportAi(): AiReport[] {
		return this.reportAiList
	}

	public isWaitNewAiReport(): boolean {
		return this.waitNewAiReport
	}

	public loadingAiReports = new Subject<AiReport[]>()

	//### Clear func ###

	public clearAllFundusList() {
		// alla logout
		Util.debug('VisitListService - clearAllFundusList')
		this.fundusExamList = []
		this.fundusExamListRequested = []
	}

	public clearAiReportList() {
		Util.debug('VisitListService - clearAiReportList')
		this.reportAiList = []
		this.reportAiListQueue = []
	}

	public clearAllReportAiReq() {
		Util.debug('VisitListService - ClearReportAiReq')

		// console.log(this.ReportAiListIstance)
		// chiamo il clear su ogni istanza
		for (let i = 0; i < this.ReportAiListIstance.length; i++) {
			const interval = this.ReportAiListIstance[i]

			clearInterval(interval)
		}
	}
}

import { Component, OnInit, AfterViewInit, Input } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { faCircleCheck, faCircleXmark, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

export enum stateType {
	SUCCESS,
	FAIL,
	WARNING,
	EXPIRED,
}

@Component({
	selector: 'message-modal',
	templateUrl: './message.modal.html',
	styleUrls: ['./message.modal.scss'],
	providers: [NgbModal],
})
export class MessageModal implements OnInit, AfterViewInit {
	@Input() state: stateType
	@Input() texts: string[]

	show: boolean

	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark
	faTriangleExclamation = faTriangleExclamation

	constructor() {
		this.state = stateType.SUCCESS
		this.show = false
		this.texts = []
	}

	ngOnInit(): void {}

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.show = true
		}, 200)
	}
}

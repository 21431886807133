import * as maplibregl from 'maplibre-gl'
import { Place } from '@aws-amplify/geo'
import { Address } from './address.model'

export class location {
	//Le mappe vengono usate in piú modi, alcuni campi sono usati in tutti i modi, altri solo in alcuni
	//
	//usati ovunque
	marker: maplibregl.Marker //oggetto marker per la mappa
	label: string //usato per salvarsi il nome della location, quando viene selezionata dalla ricerca come clinicAdmin
	selected: boolean
	//
	// usati solo nella dove si cerca un indirizzo
	place: Place // oggetto restituito dalla ricerca
	address: Address // oggetto restituito dalla get dal server e poi usato per salvare/editare
	saved: boolean //usato nella profile e dai clinicAdmin per salvare l'indirizzo
	edit: boolean //usato nella profile e dai clinicAdmin per salvare l'indirizzo
	isMain: boolean //usato nella profile e dai clinicAdmin per salvare l'indirizzo

	// usato per mappe generiche
	id: number // identificativo del marker e location

	constructor() {
		this.id = 0
		this.label = ''
		this.place = { geometry: { point: [0, 0] } }
		this.address = new Address()
		this.marker = new maplibregl.Marker()
		this.selected = false
		this.saved = false
		this.edit = false
		this.isMain = false
	}

	haveAddress(): boolean {
		let ret = false

		if (this.address.id > 0 && this.address.latitude && this.address.longitude) {
			ret = true
		}

		return ret
	}

	isMainAddress(address: Address): boolean {
		//va passato il main address del profilo
		let ret = false
		if (this.address.id == address.id) {
			ret = true
		}
		return ret
	}
}

export class searchOptions {
	countries: string[]
	maxResults: number
	biasPosition: number[]
	searchIndexName: string

	constructor(LatLng: LatLng, country: string, searchIndexName: string) {
		this.countries = [country]
		this.maxResults = 15
		this.biasPosition = [LatLng.longitude, LatLng.latitude]
		this.searchIndexName = searchIndexName
	}
}

export interface LatLng {
	latitude: number
	longitude: number
}

export interface mapConfig {
	mapConfig: {
		defaultIndex: string
		identityPoolId: string
		indexItems: string
		mapName: string
		region: string
	}
}

<div class="panel relation-panel-container position-relative ng-cloak">
	<!-- modal-panel -->

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
			<!-- (click)="activeModal.dismiss('Cross click')" -->
		</button>
	</div>

	<div class="panel-heading">
		<label class="panel-title"> {{"RELATIONS.PAGE_INFO_TITLE" | translate}}</label>
	</div>

	<div class="panel-body">
		<div class="row">
			<div class="full-container fields-container" *ngIf="(currentRel != null)">
				<div class="row">
					<div class="form-group third-container">
						<label for="display">{{"RELATIONS.DISPLAY_NAME_INFO" | translate}}</label>
						<input type="text" class="form-control" readonly name="display" id="display" [ngModel]="currentRel.display_name" />
					</div>

					<div class="form-group sixth-container">
						<label for="oper_id">{{"RELATIONS.OPER_ID" | translate}}</label>
						<input type="text" class="form-control" readonly name="oper_id" id="oper_id" [ngModel]="currentRel.doctor_id" />
					</div>

					<div class="form-group quarter-container">
						<label for="username">{{"DOCTOR_LIST.USERNAME" | translate}}</label>
						<input type="text" class="form-control" readonly name="username" id="username" [ngModel]="currentRel.username" />
					</div>

					<div class="form-group quarter-container">
						<label for="dt">{{"RELATIONS.AFFILIATION_DATE" | translate}}</label>
						<input type="text" class="form-control" readonly name="dt" id="dt" [ngModel]="session.formatDate(currentRel.affiliation_date)" />
					</div>
				</div>

				<div class="row">
					<div class="form-group half-container" *ngIf="currentRel.has_signature">
						<label for="oper_id">{{"RELATIONS.SIGNATURE_INFO" | translate}}</label>
						<img [src]="currentRel.signature" />
					</div>
				</div>

				<div class="row">
					<div class="form-group half-container" *ngIf="!currentRel.has_signature">
						<label for="no_sign"> {{"RELATIONS.SIGNATURE_MISSING" | translate}}</label>
						<br />{{"RELATIONS.SIGNATURE_TODO" | translate}}
					</div>
				</div>
				<div class="row">
					&nbsp;
					<!-- spazio vuoto -->
				</div>
			</div>
		</div>

		<div class="spacer"></div>

		<div class="row fields-container">
			<div class="twothird-container">
				<div class="spacer"></div>
			</div>
			<div class="third-container">
				<div class="row">
					<div class="twothird-container">
						&nbsp;
						<!-- ko float_right ? FIXME -->
					</div>
					<div class="third-container">
						<button type="clear" (click)="activeModal.dismiss('close');" class="btn btn-secondary">{{"BUTTONS.CLOSE" | translate}}</button>
					</div>
				</div>
				<div class="spacer"></div>
			</div>
		</div>
	</div>
</div>

<div class="normalcase zero-padding position-relative">
	<!-- (focusout)="visible = false" -->

	<!-- (click)="visible = true"-->
	<a class="reset-buttton" [ngClass]="{ 'hide' : !countrySelected }" (click)="resetCountry()"><fa-icon [icon]="faXmark"></fa-icon></a>

	<input
		type="text"
		class="form-control normalcase"
		[ngClass]="{ 'has-error' : !countrySelected }"
		(click)="visible = !visible"
		name="drpcountry"
		placeholder="{{ 'DOCTOR.MODAL_COUNTRY_HINT' | translate }}"
		[(ngModel)]="drpcountry"
		(keyup)="filter($event)"
		#country
		#state="ngModel"
		[disabled]="countrySelected" />
	<fa-icon [icon]="faCheck" faCheck [ngClass]="{ 'hide' : !countrySelected }"></fa-icon>
</div>
<div class="drp-fixed" (lostfocus)="visible = false" [ngClass]="{'z-index999': !visible}">
	<ul role="listbox" aria-expanded="false" *ngIf="visible">
		<li *ngFor="let country of dataFiltered; let i = index" [attr.data-index]="i">
			<div #listCountries [ngClass]="{selected: currentValue === country}" (click)="select(country)">
				<span class="fi fi-{{ country.alpha2 }}" tabindex="0"></span>
				<span tabindex="0" [innerHtml]="country.name"></span>
				<!-- [ngClass]="{selected: currentValue === country}" -->
			</div>
		</li>
	</ul>
	<p class="error-message" [hidden]="!(state.invalid && state.touched)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
</div>

import { Component, ViewChild, OnInit, ElementRef, OnDestroy } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, NgbNav, NgbNavChangeEvent, NgbNavItem } from '@ng-bootstrap/ng-bootstrap'

// MAT TABLE IMPORT
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, PageEvent } from '@angular/material/paginator'

import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs, Preferences } from '../../models/settings.model'

import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-regular-svg-icons'
import {
	faRotate,
	faXmark,
	faCaretDown,
	faCaretUp,
	faLink,
	faSortDown,
	faCircleExclamation,
	faCircleCheck,
	faCircleXmark,
	faTriangleExclamation,
	faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DataModelService, DataStatus } from '../../service/data-model.service'

import { Distrib } from '../../models/specialist.model'
import { Util } from '../../models/util.model'
import { ToastOptions } from 'src/app/models/toast.model'

import { SpecialistModal } from './specialist.modal'
import { AppToastService } from 'src/app/service/toast.service'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'
import { FormControl, FormGroup } from '@angular/forms'
import { Subscription } from 'rxjs'
import { RelationsComponent } from '../relations/relations.component'

export interface filterObj {
	descr: string
	value: any
}

export enum graderTabs {
	LOCATIONS = 'locations',
	RELATIONS = 'relations',
	DOCUMENTS = 'documents',
}

@Component({
	selector: 'remotes',
	templateUrl: './remoteList.component.html',
	styleUrls: ['./remoteList.component.scss'],
})
export class RemoteListComponent implements OnInit, OnDestroy {
	@ViewChild(MatTable) table: MatTable<any>
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	// @ViewChild('filter') input: ElementRef
	@ViewChild('nav') nav: NgbNav
	displayedColumns: any

	input: string

	remoteListTable: MatTableDataSource<Distrib>
	remoteListRaw: Distrib[] // é una lista filtrata di tutti i remote senza i miniC
	remoteList: Distrib[] //usata per il filtro, é una copia di remoteListRaw
	miniRemoteList: Distrib[] //lista di solo i miniC
	allRemoteList: Distrib[] // tutti i remotes non filtrati
	doctor_usrname: string
	distrib_usrname: string
	distrib_pwd: string
	display_name: string // 31.05.2017
	doctor_enable_note: string // 11.06.2018

	graderListChanged: Subscription

	expandedElement: Distrib | null

	success = false
	fail = false
	id = ''

	currentDistrib: Distrib // arriva dalla lista, forse non ha tutti i campi ?
	currentAction: string

	currentModal

	reloadEnable: boolean

	filterForm: FormGroup

	// 25.06.2019 salvo su var di appoggio per cfr con vecchio valore, prima di sovrascrivere
	spec_report_type: string
	spec_report_subtype: number

	// 16.11.2017 solo per NS, metto a true per velocizzare la lista
	ignoreCritt: boolean
	distribList: Distrib[] // 25.03.2020

	categChoices: string[] // 25.10.2021 per specialisti

	loadingRemotes: boolean
	loadingRelations: boolean
	loadingAgreements: boolean

	sortStatus: Sort
	pageStatus: PageEvent
	remotePref: TablePrefs
	localStorageName: string

	activateSpinner: boolean
	filterSet: boolean // per capire se é settato un filtro e cambiare la scritta dinamicamente
	isFilterTextEmpty: boolean
	//filters
	isTestOpt: filterObj[]
	status: filterObj[]
	subtypes: string[]
	types: string[]

	showFilters: boolean

	activeTab: graderTabs
	tabs: { id: string; name: string; counter: number; message: string; spinner: boolean }[]

	faEdit = faEdit
	faTrashAlt = faTrashAlt
	faEye = faEye
	faRotate = faRotate
	faXmark = faXmark
	faCaretDown = faCaretDown
	faCaretUp = faCaretUp
	faLink = faLink
	faSortDown = faSortDown
	faCircleExclamation = faCircleExclamation
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark
	faTriangleExclamation = faTriangleExclamation
	faCircleQuestion = faCircleQuestion

	constructor(
		public session: SessionService,
		public translator: TranslateService,
		public modalService: NgbModal,
		public dataService: DataModelService,
		private toastService: AppToastService
	) {
		Util.debug('(distribListComponent) - constructor')
		this.input = ''

		this.session.checkRoute() // controlla se e' loggato, serve per reload ?

		this.ignoreCritt = true // viene poi sovrascritto in manageDistribList

		this.reloadEnable = false
		this.loadingRelations = false
		this.loadingAgreements = false
		this.loadingRemotes = true

		// 20.04.2023 ridotto per support, portata su funzione dedicata
		// per ora uguale per tutti i profili
		//this.displayedColumns = ['username', 'name', 'user_subtype', 'is_test', 'code', 'country', 'created_by', 'doctorCount', 'filter']
		this.initColumns()

		this.distribList = []
		this.remoteListRaw = []
		this.remoteList = []
		this.miniRemoteList = []
		this.allRemoteList = []

		this.clearCurrentDistrib() // lo inizializza vuoto

		this.categChoices = Config.CATEG_NAMES // 25.10.2021

		this.filterForm = new FormGroup({
			isTest: new FormControl(null),
			showDeleted: new FormControl(null),
			status: new FormControl(null),
			subType: new FormControl(null),
			userType: new FormControl(null),
		})

		this.isTestOpt = [
			{ descr: this.translator.instant('MISC.YES'), value: 'Y' },
			{ descr: this.translator.instant('MISC.NO'), value: 'N' },
		]
		this.status = [
			{ descr: this.translator.instant('DISTRIB_LIST.ACTIVE'), value: true },
			{ descr: this.translator.instant('DISTRIB_LIST.INACTIVE'), value: false },
		]

		this.subtypes = []
		this.types = []

		this.filterSet = false

		this.activateSpinner = true
		this.isFilterTextEmpty = false

		this.tabs = [
			{
				id: graderTabs.LOCATIONS,
				name: this.translator.instant('DISTRIB_LIST.TABS.LOCATIONS'),
				counter: 0,
				message: this.translator.instant('DISTRIB_LIST.TABS.LOCATIONS_MSG'),
				spinner: false,
			},
			{
				id: graderTabs.RELATIONS,
				name: this.translator.instant('DISTRIB_LIST.TABS.RELATIONS'),
				counter: 0,
				message: this.translator.instant('DISTRIB_LIST.TABS.RELATIONS_MSG'),
				spinner: true,
			},
			{
				id: graderTabs.DOCUMENTS,
				name: this.translator.instant('DISTRIB_LIST.TABS.DOCUMENTS'),
				counter: 0,
				message: this.translator.instant('DISTRIB_LIST.TABS.DOCUMENTS_MSG'),
				spinner: true,
			},
		]
		this.activeTab = graderTabs.LOCATIONS
		//this.wrongPwd = false;
		//this.filtersEnabled = false;
		//this.brands = this.session.brands;  // 13.11.2019

		// 23.03.2022 erano su init, meglio qui ?
		//this.refreshFlag = 'init_DistribListComponent' // load into table

		this.localStorageName = this.session.getUserId() + ' - remotetPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.remotePref = JSON.parse(saveLocal)
		} else {
			// first time
			this.remotePref = new TablePrefs()
			// this.remotePref.filterTab = graderTabs.LOCATIONS
			this.remotePref.empty = false
			// default data
			this.remotePref.sort = 'username'
			this.remotePref.dir = 'asc'

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.remotePref))
		}

		this.showFilters = false
	}

	ngOnInit(): void {
		Util.debug('(distribListComponent) - ngOnInit')

		// gli disabilito finché non ho la lista dei valori
		this.filterForm.get('subType').disable()
		this.filterForm.get('userType').disable()

		this.filterForm.get('isTest').setValue('N')
		this.filterForm.get('showDeleted').setValue('N')
		this.filterSet = true

		this.graderListChanged = this.dataService.distribListChanged.subscribe((graders: Distrib[]) => {
			this.allRemoteList = graders

			this.remoteListRaw = graders.filter((el) => el.user_subtype != Config.SUB_MINI)
			this.miniRemoteList = graders.filter((el) => el.user_subtype == Config.SUB_MINI)

			this.fillMiniGradersList(this.miniRemoteList)

			this.filterValue()
			this.initTypes(this.remoteListRaw)
		})

		// for page destroying
		window.onbeforeunload = () => this.ngOnDestroy()
	}

	ngAfterViewInit(): void {
		this.manageDistribList(false)
	}

	public filterValue() {
		// Util.debug('(RemoteList) - filterValue start')
		this.filterSet = false
		// console.log(this.filterForm)

		this.remoteList = this.remoteListRaw.slice()

		// IS TEST
		let isTest = this.filterForm.get('isTest').value
		if (isTest) {
			this.filterSet = true
			this.remoteList = this.remoteList.filter((el) => el.is_test == isTest)
		}

		// DELETED USERS
		let delUsrs = this.filterForm.get('showDeleted').value

		if (delUsrs) {
			this.filterSet = true
			this.remoteList = this.remoteList.filter((el) => el.is_deleted == delUsrs)
		}

		// STATUS
		let sts = this.filterForm.get('status').value
		if (sts != null) {
			this.filterSet = true
			this.remoteList = this.remoteList.filter((el) => el.isActive == sts)
		}

		// SUBTYPE
		let subT = this.filterForm.get('subType').value
		if (subT) {
			this.filterSet = true
			this.remoteList = this.remoteList.filter((el) => el.user_subtype == subT)
		}

		// USERTYPE
		let usrT = this.filterForm.get('userType').value
		if (usrT) {
			this.filterSet = true
			this.remoteList = this.remoteList.filter((el) => el.user_type == usrT)
		}

		this.initTable(this.remoteList)

		// se prima filtro con il testo e dopo con filterValue, bisogna alla fine richiamare il filtro solo testo
		if (this.remotePref.filter != '') {
			this.filterText()
		}
	}

	public reloadList() {
		Util.debug('(RemoteList) - reload')
		this.reloadEnable = false
		this.manageDistribList(true)
	}

	// 20.04.2023 portata fuori dal costruttore
	private initColumns() {
		// orig:
		//this.displayedColumns = ['username', 'name', 'user_subtype', 'is_test', 'code', 'country', 'created_by', 'doctorCount', 'filter']

		// comuni a tutti
		// this.displayedColumns = ['subType', 'code', 'username', 'name', 'organization', 'user_type', 'country', 'locationIcon', 'relationIcon', 'filter', 'expand'] //'is_test' removed

		this.displayedColumns = {
			[graderTabs.LOCATIONS]: ['subType', 'code', 'username', 'name', 'organization', 'user_type', 'country', 'locationIcon', 'filter', 'expand'],
			[graderTabs.RELATIONS]: ['subType', 'code', 'username', 'name', 'organization', 'user_type', 'country', 'activeRels', 'relationIcon', 'filter', 'expand'],
			[graderTabs.DOCUMENTS]: ['subType', 'code', 'username', 'name', 'organization', 'user_type', 'country', 'documentIcon', 'filter', 'expand'],
		}
	}

	private setCurrentDistrib(dist) {
		if (dist) {
			this.currentDistrib = dist
		} else {
			this.clearCurrentDistrib()
		}
	}

	private clearCurrentDistrib() {
		this.currentDistrib = new Distrib()
	}

	public getStrCreator(usrId) {
		let ret = '' + usrId
		if (this.session.isLevel3()) {
			ret = this.session.getAdminUsername(usrId) // only for level 3
		}
		return ret
	}

	// load table data
	private manageDistribList(force: boolean) {
		if (this.dataService.hasLoadedDistribList() && !force) {
			Util.debug('Spacialists already loaded')
			this.allRemoteList = this.session.getDtDistribList()
			this.remoteListRaw = this.allRemoteList.filter((el) => el.user_subtype != Config.SUB_MINI)
			this.miniRemoteList = this.allRemoteList.filter((el) => el.user_subtype == Config.SUB_MINI)

			this.fillMiniGradersList(this.miniRemoteList)

			this.filterValue()
			this.initTypes(this.remoteListRaw)
			this.loadingRemotes = false
		} else {
			Util.debug('Spacialists not loaded yet')

			this.ignoreCritt = false
			// if (this.session.isAdmin()) {
			// 	this.ignoreCritt = false
			// } // anche per support

			this.session
				.loadRemotes(this.ignoreCritt)
				.then((data) => {
					// this.activateSpinner = false
					this.loadingRemotes = false
					// console.log(data)
					// this.remoteListRaw = data.filter((el) => el.user_subtype != Config.SUB_MINI)
					// this.miniRemoteList = data.filter((el) => el.user_subtype == Config.SUB_MINI)
					// console.log(this.filterSet)
					// this.filterValue()
					// this.initTable(this.remoteListRaw)
					// this.initTypes(this.remoteListRaw)
				})
				.catch((err) => {
					if (!this.session.isExpired(err)) {
						var msg = err.data ? err.data.error : err.toString()
						alert(msg)
					}
				})
		}
	}

	private initTable(list: Distrib[]) {
		// Util.debug('(RemoteList) - Init table')

		// console.log(list)

		this.remoteListTable = new MatTableDataSource<Distrib>(list)
		this.remoteListTable.paginator = this.paginator
		this.remoteListTable.sort = this.sort

		// automatic sort
		this.ApplySettings(this.remotePref, this.remoteListTable)

		this.initLocationCounter()

		if (this.dataService.acceptedAgreementListStatus == DataStatus.LOADED) {
			this.initAgreementCounter()
		}

		if (this.dataService.relsListStatus == DataStatus.LOADED) {
			this.initRelationCounter()
		}

		setTimeout(() => {
			this.reloadEnable = true
		}, 5000)
	}

	private initLocationCounter() {
		this.tabs[0].counter = 0

		// uso this.remoteList xk é la lista filtrata
		for (let distrib of this.remoteList) {
			if (distrib.isActive && !distrib.isAllAddressesValid()) {
				this.tabs[0].counter++
			}
		}
	}

	private fillMiniGradersList(minis: Distrib[]) {
		for (let distrib of this.remoteListRaw) {
			let graders = minis.filter((el) => el.groupId == distrib.groupId && el.is_deleted == 'N')

			if (graders) {
				distrib.myMiniGraders = graders
			}
		}
	}

	private initRelationCounter() {
		this.tabs[1].counter = 0
		// uso this.remoteList xk é la lista filtrata
		for (let distrib of this.remoteList) {
			if (distrib.isActive && distrib.doctorCount > 0 && !distrib.allRelationsValid().resp) {
				this.tabs[1].counter++
			}
		}
	}

	private initAgreementCounter() {
		this.tabs[2].counter = 0
		// uso this.remoteList xk é la lista filtrata
		for (let distrib of this.remoteList) {
			if (distrib.isActive && !distrib.isAllDocumentAccepted().resp) {
				this.tabs[2].counter++
			}
		}
	}

	private ApplySettings(pref: TablePrefs, list) {
		// this.nav.select(pref.filterTab)
		// this.onNavChange({ activeId: '', nextId: pref.filterTab, preventDefault() {} })
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		// this.input.nativeElement.value = pref.filter
		this.input = pref.filter

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}

	private initTypes(list: Distrib[]) {
		this.subtypes = [...new Set(list.map((item) => item.user_subtype))]
		this.types = [...new Set(list.map((item) => item.user_type))]

		this.filterForm.get('subType').enable()
		this.filterForm.get('userType').enable()
	}

	openDetailRow(row) {
		let canOpen =
			(this.activeTab == graderTabs.LOCATIONS ||
				(this.activeTab == graderTabs.RELATIONS && row.user_type == 'ClinicAdmin') ||
				this.activeTab == graderTabs.DOCUMENTS) &&
			row.isActive
		if (canOpen) {
			this.expandedElement = this.expandedElement

			if (this.expandedElement === row && canOpen) {
				this.expandedElement = null
			} else {
				this.expandedElement = row
			}
		}
	}

	onNavChange(event: NgbNavChangeEvent) {
		this.activeTab = event.nextId
		// this.remotePref.filterTab = event.nextId

		if (event.nextId == graderTabs.RELATIONS) {
			this.loadingRelations = true
			this.loadRelationsList(false).then((res) => {
				this.loadingRelations = false
				this.initRelationCounter()
			})
		}

		if (event.nextId == graderTabs.DOCUMENTS) {
			this.loadingAgreements = true
			this.loadingAcceptedAgreements(false).then((res) => {
				this.loadingAgreements = false
				this.initAgreementCounter()
			})
		}
	}

	private loadRelationsList(force: boolean): Promise<boolean> {
		Util.debug('(RemoteList) - loadRelationsList')

		const promise = new Promise<boolean>(async (resolve, reject) => {
			if (this.dataService.relsListStatus == DataStatus.LOADED && !force) {
				resolve(true)
			} else {
				let promiseArray = []
				for (let distrib of this.allRemoteList) {
					if (distrib.is_deleted == 'N' && distrib.doctorCount > 0) {
						promiseArray.push(
							await this.session.loadRelationsList(distrib.id, true).then(() => {
								distrib.loadRelationsComplete = true
							})
						)
					}
				}
				Promise.all(promiseArray).then((res) => {
					resolve(true)
				})
			}
		})

		return promise
	}

	private loadingAcceptedAgreements(force: boolean): Promise<boolean> {
		Util.debug('(RemoteList) - loadingAcceptedAgreements')

		const promise = new Promise<boolean>(async (resolve, reject) => {
			if (this.dataService.acceptedAgreementListStatus == DataStatus.LOADED && !force) {
				resolve(true)
			} else {
				let promiseArray = []
				for (let distrib of this.allRemoteList) {
					if (distrib.is_deleted == 'N') {
						promiseArray.push(
							await this.session.getAcceptedUserAgreement(distrib.user_id).then((resp) => {
								// console.log(resp)
								distrib.loadAgreementsComplete = true
							})
						)
					}
				}
				Promise.all(promiseArray).then((res) => {
					resolve(true)
				})
			}
		})

		return promise
	}

	needSpinner() {
		this.activateSpinner = this.session.isLoadingRemotes()

		// mentre carica la lista disabilito i fltri, potrebbe succedere che ci sono tanti pazienti da caricare e ci metta un po, cosí evito che si toccano i filtri con lista vuota
		if (this.session.isSpecialist() || this.session.isClinicAdmin() || this.session.isMiniC()) {
			if (this.activateSpinner) {
				this.filterForm.disable()
			} else {
				this.filterForm.enable()
			}
		}

		return this.activateSpinner
	}

	manageReload(event) {
		if (this.dataService.distribListStatus == DataStatus.LOADED) {
			Util.debug('RL (manageReload) ref status changed! ')
			var tot = this.dataService.getTotRemotes()
		}
	}

	openRelationModal(element: Distrib) {
		if (!element.loadRelationsComplete) {
			Util.debug('relations not loaded yet')
			return
		}

		if (this.session.isStats()) {
			Util.debug('(showRels) not enabled yet')
			return // 20.07.2021 ignora, TEMP TODO
		}

		// 28.06.2023 la sola vista serve per capire se ci sono problemi nelle relazioni
		// tolto il controllo per gli admins
		if (!this.session.isAdmin()) {
			if (element.user_subtype == Config.SUB_MINI) {
				Util.debug('(showRels) not enabled for mini')
				return
			}
		}

		// 11.07.2023 fix bug 18870
		if (this.session.isSupport() && element.isDeleted()) {
			let msg = 'Nothing to show on deleted user' // TODO translate on json
			Util.debug('(showRels) ' + msg)
			alert(msg)
			return
		}

		// 20.04.2023
		//Util.debug('(showRels) for ref '+refId);
		//let myRef = this.session.geDistribFromList(refId)
		// 10.05.2023 NO!!! Blocca prima relazione
		//if (myRef && myRef.doctorCount == 0) {
		//	alert('no relations to show!')
		//	return
		//} // else if(myRef){
		//Util.debug('(showRels) ref '+refId+" has "+myRef.doctorCount+" relations.");
		//} else {
		//Util.debug('(showRels) null ref ?! '+refId);
		//}

		// this.session.loadRelationsPage(element.user_id)

		this.currentModal = this.modalService.open(RelationsComponent, { size: 'xl' })
		this.currentModal.componentInstance.currDistrib = element
		this.currentModal.componentInstance.relationListRaw = element.relations
	}

	// 14.10.2021  centralizza anche il setCurrent [ls]
	private loadSingleDistrib(elemId) {
		Util.debug('(loadDistr) going to load: ' + elemId)

		return this.session
			.loadDistrib(elemId)
			.then(() => {
				this.session.getDtDistrib().then((distrib) => {
					// console.log(distrib)
					var origD = distrib
					if (origD != null && origD.id == elemId) {
						this.setCurrentDistrib(origD)
						return true
					}
				})
			})
			.catch((err) => {
				var msg = err.data ? err.data.error : err.toString()
				Util.debug('(loadSingleDistrib) - KO ' + msg)
				alert('Error loading distrib id ' + elemId)
				return Promise.reject(err)
			})
	}

	openEditModal(usrFromList: Distrib) {
		//alert("lev2: edit not available yet");

		if (usrFromList == null) {
			this.currentAction = '-' // disab
			console.log('(openEditModal) edit only')
			return
		}

		// 22.02.2021 nascondo msg ok/error
		this.success = false
		this.fail = false
		this.currentAction = 'modify'

		//this.dispositivePwd = "";
		//this.wrongPwd = false;
		//this.new_nexy_sn = ""; // 27.06.2018 ripulisce da edit precedenti
		//this.force_nexy = 'N'; // per il radio di scelta

		// intanto parto con questo (proveniente dalla lista,senza decrypt), poi lo aggiorno
		this.setCurrentDistrib(usrFromList)

		// richiede info current doctor x display
		this.loadSingleDistrib(usrFromList.id).then((flagDone) => {
			this.currentModal = this.modalService.open(SpecialistModal, {
				size: 'xl',
			})
			this.currentModal.componentInstance.currentRef = this.currentDistrib

			// arriva qui facendo la close del modal
			this.currentModal.result.then(
				(changedUsr: Distrib) => {
					console.log('SpecModal - After modal closed')
					//this.submit(changedDoc); // gia' fatto dalla chiamante

					if (changedUsr) {
						// console.log(changedUsr)
						// 15.09.2022
						Util.debug('going to refresh specialist list...')
						//this.refreshFlag = 'edit_Distrib' // innesca il load della tabella
						// this.manageDistribList(true)

						for (let distrib of this.remoteListRaw) {
							if (distrib.id == changedUsr.id) {
								// console.log(distrib)
								var indx = this.remoteListRaw.indexOf(distrib)
								// console.log(indx)
							}
						}

						if (indx != -1) {
							this.remoteListRaw.splice(indx, 1)
							this.remoteListRaw.push(changedUsr)

							this.filterValue()
							this.initTypes(this.remoteListRaw)
						}

						let header = this.translator.instant('TOAST.HEADER.SUCCESS')
						let body = this.translator.instant('TOAST.REMOTES') + ' id: ' + this.currentDistrib.id + '. ' + this.translator.instant('TOAST.BODY.EDIT')

						let options = new ToastOptions('success')

						this.toastService.show(header, body, false, options, 'bottom-right')
					}
				},
				(reason) => {
					let ris = 'Dismissed ' + Util.getDismissReason(reason)
					console.log(ris)
				}
			)
		})
	}

	openDeleteModal(distrib: Distrib) {
		let text = this.translator.instant('DISTRIB_LIST.DELETE_DISTR', {
			v1: distrib.username,
		})
		this.currentAction = 'delete'

		this.currentModal = this.modalService.open(ConfirmModal, {
			size: 'l',
			keyboard: false,
		})

		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(confirmed) => {
				console.log('(delDoc) After modal closed: ' + confirmed) // confirmed = true/false
				if (confirmed) {
					this.deleteRemote(distrib)
				}
			},
			(reason) => {
				let ris = '(delDoc) Dismissed ' + Util.getDismissReason(reason)
				console.log(ris)
			}
		)

		//this.dispositivePwd = "";
		//this.wrongPwd = false;
	}

	private deleteRemote(distrib: Distrib) {
		if (!(this.session.isGod() || this.session.isVice() || this.session.isSuperB())) {
			console.log('DL Utente non abilitato, act: ' + this.currentAction)
			return
		}

		// controllo fatto sul chiamante
		// conferma password dispositiva per delete

		this.session
			.deleteDistrib(distrib.id) // doc ?
			.then(() => {
				// alert('OK, user deleted!') // 23.03.2022

				let header = this.translator.instant('TOAST.HEADER.SUCCESS')
				let body = this.translator.instant('TOAST.SPECIALIST') + ' ' + distrib.name + ' ' + this.translator.instant('TOAST.BODY.DELETED')
				let options = new ToastOptions('success')

				this.toastService.show(header, body, false, options, 'bottom-right')

				this.manageDistribList(true)
			})
	}

	filterText() {
		this.remotePref.filter = this.input
		this.remotePref.filter = this.remotePref.filter.trim().toLocaleLowerCase()
		this.remoteListTable.filter = this.remotePref.filter

		if (this.remoteListTable.filteredData.length == 0) {
			this.isFilterTextEmpty = true
		} else {
			this.isFilterTextEmpty = false
		}
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.remotePref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.remotePref.itemsPerPage))

		this.graderListChanged.unsubscribe()

		this.toastService.clear()
	}
}

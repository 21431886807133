<ng-template #content let-offcanvas>
	<div class="offcanvas-header">
		<h4 class="offcanvas-title fw-bold">{{ 'TOAST.OFFCANVAS_TITLE' | translate }}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
	</div>
	<div class="offcanvas-body">
		<div>
			<ngb-toast role="alert" class="toast mb-3 {{toast.options.type}} " [autohide]="toast.options.autohide" [animation]="true" [delay]="toast.options.delay || 5000" (hidden)="canvasService.remove(toast)" *ngFor="let toast of canvasService.getCanvasToast()">
				<div class="toast-header">
					<span *ngIf="toast.options.type == 'success'"><fa-icon class="me-2" [icon]="faThumbsUp"></fa-icon></span>
					<span *ngIf="toast.options.type == 'error'"><fa-icon class="me-2" [icon]="faCircleXmark"></fa-icon></span>
					<span *ngIf="toast.options.type == 'info'"><fa-icon class="me-2" [icon]="faCircleInfo"></fa-icon></span>
					<span *ngIf="toast.options.type == 'notification'"><fa-icon class="me-2" [icon]="faTriangleExclamation"></fa-icon></span>
					<span *ngIf="toast.options.type == 'info_blue'"><fa-icon class="me-2" [icon]="faCircleInfo"></fa-icon></span>
					<strong class="me-auto">{{toast.header}}</strong>

					<span>{{session.formatDateTime(toast.date)}}</span>

					<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" (click)="dismiss(toast)"></button>
				</div>
				<div class="toast-body">
					<p class="toast-text" [innerHTML]="toast.body"></p>

					<div (click)="toast.options.showDetail = true" *ngIf="!toast.options.showDetail && !['info_blue', 'info_green'].includes(toast.options.type)" class="show-detail">
						<span><fa-icon class="me-2" [icon]="faChevronDown"></fa-icon></span>
					</div>

					<div [hidden]="!toast.options.showDetail" class="details">
						<div class="toast-cta" *ngIf="toast.canvas">
							<p *ngIf="session.isEcommerceEnabled() && toast.options.cta != '' else contactSupport">
								{{ 'TOAST.NOTIFICATIONS.UPGRADEPLAN' | translate }}<a (click)="onGoToEcommerceClick()"><fa-icon class="ms-2" style="color: #e5491f" [icon]="faUpRightFromSquare"></fa-icon></a>
							</p>

							<ng-template #contactSupport>
								<p *ngIf="toast.options.type != 'info_blue'">{{ 'TOAST.NOTIFICATIONS.CONT_SUPPORT' | translate }}</p>
							</ng-template>
						</div>

						<!-- <div class="toast-hide" style="font-size: 12px" *ngIf="toast.canvas"><input type="checkbox" name="dshow" id="dshow" class="form-check-input me-2" [(ngModel)]="dshow" /><label>Don't show this message anymore</label></div> -->
					</div>
				</div>
			</ngb-toast>
		</div>
	</div>
</ng-template>

import { Util } from './util.model'
import { Config } from '../../config'
import { Exam, RIGHT, LEFT, BOTH, SINGLE } from './exam.model'
import { TranslateService } from '@ngx-translate/core'

// Qui tutte le classi relative alle diagnosi
// SrvDiagnosis, CategDiagnosis, CategExam, ICD, Prescription.

// 28.05.2020 elemento di diagnosi sulla categoria
// vd analogie e differenze con la SrvDiagnosis
export class CategDiagnosis {
	id: number
	category: string

	dtEnabled: boolean

	icd1: ICD
	icd2: ICD
	icd3: ICD

	icdRight: ICD[]
	icdLeft: ICD[]

	details: string // diagnosi
	treatment: string // treatment

	diagn_group: number // 12.05.2022 per identificare gli icds

	//exam_list: CategExam[]; // 03.06.2020 valutare se serve per fare i pdf

	// 24.08.2020 esteso costruttore con parametro, di tipo diverso
	constructor(obj?: SrvDiagnosis) {
		this.id = 0
		this.category = ''

		// 04.02.2022 con angular2, meglio non nullo
		//this.icd1 = null;  // lasciare null cosi' compare il suggerimento nella tendina
		//this.icd2 = null;
		//this.icd3 = null;
		this.icd1 = new ICD()
		this.icd2 = new ICD()
		this.icd3 = new ICD()

		this.icdRight = []
		this.icdLeft = []

		this.details = ''
		this.treatment = ''
		this.dtEnabled = true

		if (obj) {
			this.category = obj.category
			this.details = obj.details
			this.treatment = obj.treatment

			if (obj.icd1 && obj.icd1 != '') {
				//this.icd1 = new ICD();
				//this.icd1.code = obj.icd1;
				this.icd1 = new ICD(null, obj.icd1) // 09.12.2020 fix
			}

			if (obj.icd2 && obj.icd2 != '') {
				//this.icd2 = new ICD();
				//this.icd2.code = obj.icd2;
				this.icd2 = new ICD(null, obj.icd2)
			}

			if (obj.icd3 && obj.icd3 != '') {
				//this.icd3 = new ICD();
				//this.icd3.code = obj.icd3;
				this.icd3 = new ICD(null, obj.icd3)
			}

			if (obj.icdRight) {
				for (let i = 0; i < obj.icdRight.length; i++) {
					const icd = new ICD(obj.icdRight[i])

					this.icdRight.push(icd)
				}
			}

			if (obj.icdLeft) {
				for (let i = 0; i < obj.icdLeft.length; i++) {
					const icd = new ICD(obj.icdLeft[i])

					this.icdLeft.push(icd)
				}
			}

			if (obj.dt_enabled == 'Y') {
				this.dtEnabled = true
			} else {
				this.dtEnabled = false
			}

			if (obj.diagn_group) {
				// 12.05.2022
				this.diagn_group = obj.diagn_group
			}
		}
	}

	// per trace di test
	public getMyIcds() {
		let ret = ''
		if (this.icd1 != null) {
			ret += 'icd1: ' + this.icd1.code + ' e: ' + this.icd1.eye
		}
		if (this.icd2 != null) {
			ret += ' icd2: ' + this.icd2.code + ' e: ' + this.icd2.eye
		}
		if (this.icd3 != null) {
			ret += ' icd3: ' + this.icd3.code + ' e: ' + this.icd3.eye
		}

		return ret
	}

	// 27.08.2020
	public resetDiagnValues() {
		this.details = ''
		this.treatment = ''

		// 04.02.2022 con angular12, meglio oggetto vuoto
		//this.icd1 = null;
		//this.icd2 = null;
		//this.icd3 = null;
		this.icd1 = new ICD()
		this.icd2 = new ICD()
		this.icd3 = new ICD()
		this.icdLeft = []
		this.icdRight = []

		// 19.05.2022
		Util.debug('(resetDiagnValues) ok for ' + this.category)
	}

	// 01.09.2020
	public enableDt(flag) {
		this.dtEnabled = flag
	}

	// 15.04.2022 controllo consistenza notGradable o Healthy
	public validateIcdPerEye() {
		let isOk = true

		let icds = []
		if (this.icd1 != null && !this.icd1.isEmpty()) icds.push(this.icd1)
		if (this.icd2 != null && !this.icd2.isEmpty()) icds.push(this.icd2)
		if (this.icd3 != null && !this.icd3.isEmpty()) icds.push(this.icd3)

		// il controllo sul both va fatto per primo su tutti, per evitare il caso di 3 valori cosi':
		// WNL_left, WNL_right, WNL_both
		let ignoreOthers = false
		let j = 0

		Util.debug('(validateIcdPerEye) tot: ' + icds.length + ' all: ' + this.getMyIcds())

		for (j = 0; j < icds.length; j++) {
			let myIcd = icds[j]
			let isHealthy = myIcd.isHealthy()
			let isNotGrad = myIcd.isNotGradable()

			if (isHealthy || isNotGrad) {
				if (myIcd.eye == BOTH) {
					ignoreOthers = true

					// annullo gli altri, sulla var globale
					if (this.icd1 != null && this.icd1.code != myIcd.code) {
						//this.icd1 = null;  // 19.05.2022 fix, null non va bene!!
						this.icd1 = new ICD()
					}
					if (this.icd2 != null && this.icd2.code != myIcd.code) {
						//this.icd2 = null;
						this.icd2 = new ICD()
					}
					if (this.icd3 != null && this.icd3.code != myIcd.code) {
						//this.icd3 = null;
						this.icd3 = new ICD()
					}
					break
				}
			}
		}

		if (ignoreOthers) {
			Util.debug('(validateIcdPerEye) ignore others: ' + this.getMyIcds())
		} else {
			for (let i = 0; i < icds.length; i++) {
				if (!isOk) {
					// al primo invalid, esco
					break
				}

				let myIcd = icds[i]
				let isHealthy = myIcd.isHealthy()
				let isNotGrad = myIcd.isNotGradable()

				Util.debug('(validateIcdPerEye) i ' + i + ' eye ' + myIcd.eye + ' healthy: ' + isHealthy)

				if (isHealthy || isNotGrad) {
					if (myIcd.eye == RIGHT) {
						// check that the others are only on the left (not on the right nor on the both)
						for (let pos = 0; pos < icds.length; pos++) {
							if (pos != i) {
								isOk = icds[pos].eye == LEFT
								if (!isOk) break
							}
						}
					} else if (myIcd.eye == LEFT) {
						// check that the others are only on the right
						for (let pos = 0; pos < icds.length; pos++) {
							if (pos != i) {
								isOk = icds[pos].eye == RIGHT
								if (!isOk) break
							}
						}
					}
				}
			}
		}

		console.log('(validateIcdPerEye) is ok ? ' + isOk + ' list: ' + this.getMyIcds())
		return isOk
	}
}

// 28.05.2020 trovare modo migliore ?! [ls]
// questo oggetto serve nel post
// come la CategDiagnosis ma solo i campi code per gli icd
// in piu' ha un array con i suoi esami
export class SrvDiagnosis {
	//id: number;
	report_id: string // 01.06.2020 valorizzato solo quando viene ricevuto dal srv
	category: string
	//dtEnabled: boolean;
	dt_enabled: string // {Y, N}
	icd1: string
	icd2: string
	icd3: string

	icdRight: string[]
	icdLeft: string[]

	details: string
	treatment: string
	exam_list: CategExam[]
	diagn_group: number // 12.05.2022 - 08.04.2022 per identificare gli icds

	constructor(obj?: CategDiagnosis) {
		this.icdRight = []
		this.icdLeft = []

		if (obj) {
			this.category = obj.category

			if (obj.icd1) this.icd1 = obj.icd1.code
			if (obj.icd2) this.icd2 = obj.icd2.code
			if (obj.icd3) this.icd3 = obj.icd3.code

			if (obj.icdRight) {
				for (let i = 0; i < obj.icdRight.length; i++) {
					let icd = obj.icdRight[i]

					this.icdRight.push(icd.code)
				}
			}

			if (obj.icdLeft) {
				for (let i = 0; i < obj.icdLeft.length; i++) {
					let icd = obj.icdLeft[i]

					this.icdLeft.push(icd.code)
				}
			}

			this.details = obj.details
			this.treatment = obj.treatment

			if (obj.dtEnabled) this.dt_enabled = 'Y'
			else this.dt_enabled = 'N'

			if (obj.diagn_group) {
				// 12.05.2022
				this.diagn_group = obj.diagn_group
			}
		} else {
			this.report_id = ''
			this.details = ''
			this.treatment = ''
		}
	}

	areDtEnabled(): boolean {
		return this.dt_enabled == 'Y'
	}

	setExamList(exmList) {
		this.exam_list = exmList
	}

	// 01.06.2020
	static createDiagnosi(obj, cryptoUtils, keyDoctor) {
		var result = new SrvDiagnosis()

		if (obj.id) result.report_id = obj.id

		if (obj.category) result.category = obj.category

		if (obj.icd1) result.icd1 = obj.icd1
		if (obj.icd2) result.icd2 = obj.icd2
		if (obj.icd3) result.icd3 = obj.icd3

		if (obj.icdRight) {
			for (let i = 0; i < obj.icdRight.length; i++) {
				let code = obj.icdRight[i]

				result.icdRight.push(code)
			}
		}

		if (obj.icdLeft) {
			for (let i = 0; i < obj.icdLeft.length; i++) {
				let code = obj.icdLeft[i]

				result.icdLeft.push(code)
			}
		}

		if (obj.dt_enabled) result.dt_enabled = obj.dt_enabled

		// 03.06.2020 array degli esami che lo compongono
		if (obj.categ_exams) {
			var examList = []
			for (var i = 0; i < obj.categ_exams.length; i++) {
				var elem = obj.categ_exams[i]

				// 10.07.2020 test   // 08.01.2021 tolta
				//console.log("(createDiagnosi) elem "+i+" :");
				//console.log(elem);

				var el = new CategExam(elem)
				examList.push(el)
			}
			result.setExamList(examList)
		}

		if (!keyDoctor) {
			//console.log("(createDiagnosi) keyDoctor mancante");
			//return cryptoUtils.q.when(result);  // 30.12.2021
			return Promise.resolve(result)
		} else if (obj.details || obj.treatment) {
			//console.log("(createDiagnosi) ok keyDoctor ");

			var bag = cryptoUtils.generateBag()
			bag.details = obj.details
			bag.treatment = obj.treatment

			cryptoUtils.purge(bag)

			//console.log("(createPatient) inizio decrypt bag, nome cri: "+bag.firstname);

			// 20.03.2020 usa alias piu' chiaro
			return cryptoUtils
				.decryptDataWithKey(keyDoctor, bag)
				.then((bag) => {
					result.details = bag.details
					result.treatment = bag.treatment

					return result
				})
				.catch((err) => {
					console.log('(createDiagnosi) ko decrypt ')
					return result
				})
		} else {
			// niente da decrittare
			return Promise.resolve(result)
			//return result;
		}
	}

	// 01.06.2020
	static createDiagnosis(diagnList, cryptoUtils, keyDoctor) {
		var result = []
		for (var i = 0; i < diagnList.length; i++) {
			result.push(SrvDiagnosis.createDiagnosi(diagnList[i], cryptoUtils, keyDoctor))
		}
		return Promise.all(result)
	}
}

// 28.05.2020 corrisponde alla tabella categ_exams sul DB
export class CategExam {
	exam_id: number
	exam_type: string
	//category: string;
	imgEnabled: boolean[] // 10.07.2020 external ne ha 1+6 // prev: max 4
	// 29.09.2020 retina ne ha 8

	/*
  img1: string; // {Y,N}
  img2: string; // {Y,N}
  img3: string; // {Y,N}
  img4: string; // {Y,N}
  */
	constructor(obj?) {
		if (obj) {
			this.exam_id = obj.exam_id
			this.exam_type = obj.exam_type
			this.imgEnabled = []

			// 29.09.2020 obj e' l'oggetto che arriva dalle api, corrisponde alla tabella sul categ_exams DB [ls]

			if (obj.img1 && obj.img1 == 'Y') this.imgEnabled[0] = true
			else this.imgEnabled[0] = false

			if (obj.img2 && obj.img2 == 'Y') this.imgEnabled[1] = true
			else this.imgEnabled[1] = false

			if (obj.img3 && obj.img3 == 'Y') this.imgEnabled[2] = true
			else this.imgEnabled[2] = false

			if (obj.img4 && obj.img4 == 'Y') this.imgEnabled[3] = true
			else this.imgEnabled[3] = false

			if (obj.img5 && obj.img5 == 'Y') this.imgEnabled[4] = true
			else this.imgEnabled[4] = false

			if (obj.img6 && obj.img6 == 'Y') this.imgEnabled[5] = true
			else this.imgEnabled[5] = false

			if (obj.img7 && obj.img7 == 'Y') this.imgEnabled[6] = true
			else this.imgEnabled[6] = false

			if (obj.img8 && obj.img8 == 'Y') this.imgEnabled[7] = true
			else this.imgEnabled[7] = false
		}
	}

	// 31.08.2020 per load da salvataggio su sessionStorage
	setImgEnabled(flags) {
		if (flags != null) {
			this.imgEnabled = [] // resetto prec. e creo vuoto. ATT!!!

			for (var i = 0; i < flags.length; i++) {
				this.imgEnabled.push(flags[i])
			}
		}
	}
}

// 28.05.2020 corrisponde a tabella icds sul DB
export class ICD {
	id: number
	group: number // usi futuri
	code: string // basta questo univoco
	descr: string // renamed 26.11.2020 confonde con "DESC order on DB"
	dotted_code: string // 05.11.2020 per poter cercare anche qui
	eye: string // sul DB  // 08.04.2022 abilitato
	block: string // 30.11.2020
	displDescr: string // 08.04.2022

	static HEALTHY = '§' // inizio del codice ICD
	static NOT_GRAD = '£'
	static SEE_NOTES = '^' // 10.05.2022

	static BLOCK_HEALTHY = '0_healthy'
	static BLOCK_NOTGRAD = '0_notgrad'
	static BLOCK_SEENOTES = '0_see_notes' // 10.05.2022

	static BLOCK_SEP = ' | '

	constructor(myObj?, myCode?, blockTransl?) {
		this.id = 0
		this.group = 0 // default inesistente
		this.code = ''
		this.descr = ''
		this.block = ''
		this.dotted_code = ''
		this.displDescr = ''
		this.eye = ''

		if (myObj) {
			if (myObj.id) {
				this.id = myObj.id
			}

			if (myObj.code) {
				this.code = myObj.code
			}

			if (myObj.group_id) {
				// 08.04.2022 da json
				this.group = myObj.group_id
			} else if (myObj.group) {
				// da oggetto  15.04.2022
				this.group = myObj.group
			}

			if (myObj.title) {
				// 30.11.2020 improvement
				this.descr = myObj.title
			} else if (myObj.description) {
				// 26.11.2020 TODO, already the general + specific fields
				this.descr = myObj.description
			} else if (myObj.desc) {
				// orig name, to be changed [ls]
				this.descr = myObj.desc
			} else if (myObj.descr) {
				// e' gia' un ICD
				this.descr = myObj.descr
			}

			if (myObj.block) {
				// 30.11.2020
				this.block = myObj.block
			}

			if (myObj.eye) {
				// 08.04.2022
				this.eye = myObj.eye
			}

			// 18.05.2022 se arrivo da un altro full obj ?
			if (myObj.dotted_code) {
				this.dotted_code = myObj.dotted_code
			}

			if (myObj.displDescr) {
				this.displDescr = myObj.displDescr
			}

			// solo per test
			/*if(this.id < 30){
            console.log("(loadIcdList) icd "+this.id);
            console.log(myObj);
          }*/
		} else if (myCode) {
			// 09.12.2020
			this.code = myCode
		}

		if (this.dotted_code == '') this.initDottedCode()

		if (this.displDescr == '') {
			// 08.04.2022
			this.initDisplayedDescription(blockTransl)
		}

		// 19.05.2022
		//if(!this.isEmpty())
		//	Util.debug("(ICD) "+this.toString());
	}

	private initDottedCode() {
		if (this.isEmpty()) {
			return
		}

		if (this.code.length > 3) {
			// insert the dot
			this.dotted_code = this.code.substring(0, 3) + '.' + this.code.substring(3)
		} else {
			this.dotted_code = this.code
		}
	}

	// 08.04.2022 serve per le tendine durante la diagnosi
	initDisplayedDescription(blockTransl?, eyeTransl?) {
		if (this.isEmpty()) {
			return
		}

		// 14.04.2022 per gruppo 2 pre-pend il block, decodificato
		if (Util.hideIcdsCodes(this.group)) {
			// 10.05.2022 annullato pre-pend
			/*
			let myBlock = "";
			if(blockTransl != null && blockTransl != ""){
				myBlock = blockTransl.toUpperCase() + ICD.BLOCK_SEP ; // arriva gia' tradotto
			} else {
				myBlock = ""; 
			}
			this.displDescr = myBlock + this.descr;			
			*/
			this.displDescr = this.descr // 10.05.2022 annullato pre-pend

			if (eyeTransl != null && eyeTransl != '') {
				this.displDescr += ' - ' + eyeTransl
			}
		} else {
			this.displDescr = this.dotted_code + ' - ' + this.descr
		}

		// 15.04.2022 solo per ridurre le trace
		//if(this.isNotGradable()){
		//	console.log("(ICD) grp:"+this.group+" code: "+this.code+" displD: "+this.displDescr);
		//}
	}

	// 15.04.2022
	isHealthy() {
		let ret = false

		//13.05.2022 anche emmetropia su refraction
		let isEmmetropia = this.code.indexOf('Z0100') == 0 // inizia per...
		ret = this.code.indexOf(ICD.HEALTHY) == 0 || isEmmetropia

		return ret
	}

	isNotGradable() {
		let ret = false
		ret = this.code.indexOf(ICD.NOT_GRAD) == 0
		return ret
	}

	public isEmpty() {
		return !this.code || this.code == ''
	}

	// 18.05.2022
	public toString() {
		let ret =
			'' + this.group + ', ' + this.code + ', ' + this.eye + ', ' + this.dotted_code + ', ' + this.block + ', ' + this.descr + ', ' + this.displDescr + ','

		return ret
	}

	// 19.05.2022
	static cloneMe(origICD: ICD) {
		//let otherICD = new ICD();
		/*
		if (origICD) {
			otherICD.id = origICD.id;
			otherICD.code = origICD.code;			
			otherICD.group = origICD.group;			
			otherICD.descr = origICD.descr;				
			otherICD.block = origICD.block;	
			otherICD.eye = origICD.eye;
			otherICD.dotted_code = origICD.dotted_code;
			otherICD.displDescr = origICD.displDescr;
			otherICD.descr = origICD.descr;				
		}
		*/

		let otherICD = Object.assign(new ICD(), origICD)
		return otherICD
	}
}

// 15.04.2022 elemento piu' ampio per gestire diversi gruppi di diagnosi,
// sia passato-presente che per lingua e per gli admins. [ls]

export class ICDenv {
	lang: string
	group: number

	blocks: Map<string, string> // traduzioni [chiave - valore] in questa lingua

	private fullList: ICD[]

	// per categoria - TODO, ridurre i duplicati ? tenere descr da una parte sola ?
	// suddivisone in categorie per questo gruppo
	icdListR: ICD[]
	icdListA: ICD[]
	icdListC: ICD[]
	icdListF: ICD[]
	icdListG: ICD[]

	constructor(group, lang) {
		this.lang = lang
		this.group = group

		this.fullList = [] // suddivisone in categorie per questo gruppo
		this.icdListA = []
		this.icdListC = []
		this.icdListF = []
		this.icdListG = []
		this.icdListR = []

		//this.blocks = new Map<string, string>();  // chiave - valore in questa lingua
	}

	setFullList(list: ICD[], translator) {
		this.fullList = list

		this.initBlocksTransl(translator)

		if (list != null && list.length > 0) {
			this.initBlocksCateg()
		}
	}

	public getFullList() {
		return this.fullList
	}

	// 18.05.2022
	public getCategList(catName: string) {
		console.log('E (getCategList) ' + catName)
		let desiredList = null

		switch (catName) {
			case Config.CAT_REFRACTION: {
				desiredList = this.icdListR
				break
			}
			case Config.CAT_ANTERIOR: {
				desiredList = this.icdListA
				break
			}
			case Config.CAT_CORNEA:
				desiredList = this.icdListC
				break

			case Config.CAT_FUNDUS:
				desiredList = this.icdListF
				break

			case Config.CAT_GLC:
				desiredList = this.icdListG
				break

			default:
				console.log('(getCategList) not managed ' + catName)
				desiredList = this.fullList
				break
		}

		console.log('E (getCategList) list for ' + catName + ' with tot: ' + desiredList.length)
		return desiredList
	}

	// suddivide fullList nelle 5 categs
	private initBlocksCateg() {
		// man mano che suddivido, uso i blocks tradotti per le descr

		if (this.fullList == null) {
			Util.debug('ENV (initBlocksCateg) invalid list!')
			return
		} else {
			// 18.05.2022 solo trace
			Util.debug('ENV (initBlocksCateg) grp: ' + this.group + ' full list len: ' + this.fullList.length)
		}

		for (let i = 0; i < this.fullList.length; i++) {
			let currIcd = this.fullList[i]

			// 12.05.2022 annullato pre-pend
			//let myTranslatedBlock = this.blocks.get(currIcd.block);
			let myTranslatedBlock = ''

			// ok, trace pesante!
			//Util.debug("ENV (initBlocksCateg) from "+currIcd.block+" got "+myTranslatedBlock);

			let myEyeTr = ''
			if (currIcd.eye != null && currIcd.eye != '') {
				myEyeTr = this.blocks.get(currIcd.eye) // combina left/right/both con eye/eyes
			}

			currIcd.initDisplayedDescription(myTranslatedBlock, myEyeTr)

			// 09.06.2022 PATCH escludo grp 0 e 1 che non li usa
			if (this.group > 1) {
				this.manageBlocksCateg(currIcd)
			}
		}

		/* solo per test
		if(this.icdListA != null){
			Util.debug("ENV (initBlocksCateg) tot for anterior: "+this.icdListA.length);

			let tmpIcd = this.icdListA[5];
			Util.debug("ENV (initBlocksCateg) random A icd: "+tmpIcd.toString());
		}
		*/
	}

	// nuova, per gruppo2
	// 30.11.2020  gestione dei 13 blocks sulle categorie
	private manageBlocksCateg(myIcdOrig: ICD) {
		if (myIcdOrig == null) return

		let block = myIcdOrig.block
		let myIcd = ICD.cloneMe(myIcdOrig) // 19.05.2022

		switch (block) {
			case ICD.BLOCK_NOTGRAD: // 12.04.2022  notGrad
			case ICD.BLOCK_SEENOTES: // 10.05.2022 see text notes
				//Util.debug("ENV (manageBlocksCateg) special, "+myIcdOrig.code);

				let myIcd1 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListA.push(myIcd1) // anterior
				let myIcd2 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListC.push(myIcd2) // cornea
				let myIcd3 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListF.push(myIcd3) // fundus
				let myIcd4 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListG.push(myIcd4) // GLC
				let myIcd5 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListR.push(myIcd5) // refraction
				break

			case ICD.BLOCK_HEALTHY: // 14.04.2022 tutti tranne refract
				//Util.debug("ENV (manageBlocksCateg) special H, "+myIcdOrig.code);

				// 09.05.2022 no, senza categ
				// 19.04.2022 pre-pend to the healthy value the category name
				//let myTempIcd = this.updateHealthyDescr(myIcd, Config.CAT_ANTERIOR);
				//this.icdListA.push(myTempIcd);  // anterior
				let myIcd9 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListA.push(myIcd9) // anterior

				//myTempIcd = this.updateHealthyDescr(myIcd, Config.CAT_CORNEA);
				//this.icdListC.push(myTempIcd);  // cornea
				let myIcd6 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListC.push(myIcd6) // cornea

				//myTempIcd = this.updateHealthyDescr(myIcd, Config.CAT_FUNDUS);
				//this.icdListF.push(myTempIcd);  // fundus
				let myIcd7 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListF.push(myIcd7) // fundus

				//myTempIcd = this.updateHealthyDescr(myIcd, Config.CAT_GLC);
				//this.icdListG.push(myTempIcd);  // GLC
				let myIcd8 = ICD.cloneMe(myIcdOrig) // 19.05.2022
				this.icdListG.push(myIcd8) // GLC

				//this.icdListR.push(myIcd); // refraction, no, ha emmetropia
				break

			case 'anterior':
			case 'adnexa':
			case 'cornea':
			case 'conjunctiva':
			case 'motility':
			case 'sclera':
			case 'uvea':
			case 'trauma':
			case 'glaucoma': // att, solo 3 valori per acuto...
				this.icdListA.push(myIcd) // anterior
				break

			case 'cornea':
			case 'maps':
				this.icdListC.push(myIcd) // cornea
				break

			//case "glaucoma":  // no, su anterior!
			case 'cataract':
			case 'chamber':
			case 'lens':
				this.icdListG.push(myIcd) // GLC
				break

			case 'fundus':
			case 'findings':
			case 'vitreous':
			case 'disc':
			case 'vessels':
				this.icdListF.push(myIcd) // fundus
				break

			case 'refraction':
			case 'myopia':
			case 'astigmatism':
			case 'hyperopia':
			case 'presbyopia':
				this.icdListR.push(myIcd) // refraction
				break

			default:
				console.log('(manageIcdBlocks) not managed ' + block)
		}
	}

	// 12.05.2022 tolto pre-pend
	// TODO, serve anche al pdf, togliere duplicati!
	// 19.04.2022 pre-pend to the healthy value the category name
	private updateHealthyDescr(myIcd: ICD, categName: string) {
		//let myTempIcd = angular.copy(myIcd);  // NB: faccio un clone xche' cambia per ciascuna
		let myTempIcd = Object.assign({}, myIcd) // 21.04.2022 TOTEST ?!

		//let categA = this.blocks.get(Config.CAT_ANTERIOR);
		let categLab = this.blocks.get(categName)
		let myLabel = ''
		if (categLab != null && categLab != '') {
			myLabel = categLab + ICD.BLOCK_SEP // arriva gia' tradotto
		}

		myTempIcd.displDescr = myLabel + myIcd.displDescr // gia' con l'eye

		return myTempIcd
	}

	// traduce i blocks nella lingua corrente
	initBlocksTransl(translator: TranslateService) {
		//let emme = translator.instant("BLOCKS.refraction");
		//console.log("(initBlocksTransl) emme: "+emme);

		if (this.fullList != null) {
			this.blocks = new Map<string, string>()

			for (let i = 0; i < this.fullList.length; i++) {
				let block = this.fullList[i].block
				let key = 'BLOCKS.' + block
				let val = translator.instant(key)
				this.blocks.set(block, val)
			}

			// 15.04.2022 aggiungo anche decodifica per gli occhi

			let r = translator.instant('MISC.RIGHT_EYE')
			let l = translator.instant('MISC.LEFT_EYE')
			let b = translator.instant('MISC.BOTH_EYES')

			this.blocks.set(RIGHT, r)
			this.blocks.set(LEFT, l)
			this.blocks.set(BOTH, b)
			this.blocks.set(SINGLE, b) // both anche per unspecified...

			// 19.04.2022 aggiungo anche decodifica per le categorie
			let refractionLabel = translator.instant('CATEGORIES.REFR_FULL').toUpperCase()
			let anteriorLabel = translator.instant('CATEGORIES.ANTERIOR_FULL').toUpperCase()
			let corneaLabel = translator.instant('CATEGORIES.CORNEA_FULL').toUpperCase()
			let glcLabel = translator.instant('CATEGORIES.GLC_FULL').toUpperCase()
			let fundusLabel = translator.instant('CATEGORIES.FUNDUS_FULL').toUpperCase()

			this.blocks.set(Config.CAT_REFRACTION, refractionLabel)
			this.blocks.set(Config.CAT_ANTERIOR, anteriorLabel)
			this.blocks.set(Config.CAT_CORNEA, corneaLabel)
			this.blocks.set(Config.CAT_GLC, glcLabel)
			this.blocks.set(Config.CAT_FUNDUS, fundusLabel)

			console.log('(initBlocksTransl) loaded  ' + this.blocks.size)
		}
	}
}

// 14.10.2020 nella diagnosi su refraction, oggetto con campi simili all esame SBJ
export class Prescription {
	id: number
	report_id: number

	eye: string

	sphere: string
	cylinder: string
	axis: string

	prism_h: string
	base_h: string
	prism_v: string
	base_v: string

	add: string

	DVA: string
	NVA: string

	PDm: string // 10.11.2020

	rx_design: number // poi decodificato

	// myObj potrebbe essere un oggetto SbjExam oppure json completo da diagnosi
	constructor(draft?) {
		this.DVA = ''
		this.NVA = ''

		//this.rx_design = -1;  // per forzare a scegliere
		this.rx_design = 1 // default, single

		this.sphere = ''
		this.cylinder = ''
		this.axis = ''
		this.prism_h = ''
		this.base_h = ''
		this.prism_v = ''
		this.base_v = ''
		this.add = ''
		this.PDm = ''

		if (draft != null) {
			// 26.10.2021 funziona se c'e' corrispondenza con i nomi e var
			//console.log(RawUsDev);
			//var myJsonObj = JSON.parse(RawUsDev);  // KO
			// var myJsonObj = angular.copy(draft); // e' gia' parserizzato
			var myJsonObj = { ...draft }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			Util.debug('(prescr) PDm: ' + this.PDm)

			/*              
          // se arriva dalla diagnosi
          this.DVA = ( draft.DVA != null ? draft.DVA : "" );
          this.NVA = ( draft.NVA != null ? draft.NVA : "" );
          this.rx_design = ( draft.rx_design != null ? draft.rx_design : 1 );  // 1 = single
          */

			// 29.04.2022 ripristinato
			// 05.11.2020 se arriva da sbj ?
			if (this.DVA == '' && draft.DVA_REF != null) {
				this.DVA = draft.DVA_REF
			}
			if (this.NVA == '' && draft.NVA_REF != null) {
				this.NVA = draft.NVA_REF
			}
		}
	}

	// 29.04.2022 with units and rounding
	// 20.04.2022 DS with 2 decimals, steps 0.25
	getSphere() {
		// solo per test
		//let val = parseFloat(this.sphere.replace(',', '.'));
		//console.log("Sphere: "+this.sphere+" float val: "+val);

		let ret = Exam.getMySphere(this.sphere) // su Exam
		return ret
	}

	/*
	// 20.05.2022
	private NOTUSED_getSphereNoUnit(){
			
		let val = parseFloat(this.sphere.replace(',', '.'));
		let step = Exam.roundStep(this.sphere);
		let ret = (val != 0) ? ""+ step : "" ;		
		return ret;	
	}
  */

	// 20.04.2022
	getCylinder() {
		let ret = Exam.getMyCylinder(this.cylinder) // su Exam
		return ret
	}

	// 20.04.2022
	getAxis() {
		let ret = Exam.getMyAxis(this.axis) // su Exam
		return ret
	}

	// 12.05.2022 se tutti i campi sono vuoti, a parte il design e eye
	// 26.10.2021 tolte baseH e baseV
	isEmpty() {
		let ret = false

		ret =
			this.sphere == '' &&
			this.cylinder == '' &&
			this.axis == '' &&
			this.prism_h == '' &&
			//this.base_h == "" &&
			this.prism_v == '' &&
			//this.base_v == "" &&
			this.add == '' &&
			this.DVA == '' &&
			this.NVA == '' &&
			this.PDm == ''

		return ret
	}
}

export class followUp {
	val: number
	description: string
	note_mandatory: boolean

	constructor() {
		this.val = 0
		this.description = ''
		this.note_mandatory = false
	}
}

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import * as Sentry from '@sentry/angular-ivy'

import { AppModule } from './app/app.module'
import { Config } from './config'
import { environment } from './environments/environment'

//console.log(environment.apiEndpoint);
Config.load(environment)

// 17.11.2021
if (window.sessionStorage) {
	console.log('(main) sessionStorage supported') // ok
}

let env = 'localhost'
let maskAllTextHide = true
let blockAllMediaHide = true

let tracesSampleRate = 0.25
let replaysSessionSampleRate = 0 // 0.1 we have 50 replay/month before to enable only on production, is necessary to hide all personal infomations
let replaysOnErrorSampleRate = 0 // 0.1 we have 50 replay/month before to enable only on production, is necessary to hide all personal infomations

if (environment.production) {
	env = 'production'
	maskAllTextHide = true
	blockAllMediaHide = true
}

if (environment.staging) {
	env = 'staging'
	maskAllTextHide = true
	blockAllMediaHide = true
}

if (environment.development) {
	env = 'development'
	tracesSampleRate = 1
	replaysSessionSampleRate = 0
	replaysOnErrorSampleRate = 0
	maskAllTextHide = false
	blockAllMediaHide = false
}

if (environment.development || environment.staging || environment.production) {
	//SENTRY
	Sentry.init({
		debug: false,
		dsn: 'https://98180dc12c46cf4b436c38168789485c@o4506858692411392.ingest.us.sentry.io/4506897589338112',
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration({
				maskAllText: maskAllTextHide,
				blockAllMedia: blockAllMediaHide,
			}),
		],
		environment: env,
		// Performance Monitoring
		tracesSampleRate: tracesSampleRate, //  Capture 100% of the transactions

		// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
		tracePropagationTargets: [environment.apiEndpoint, environment.api_bridgeEndpoint, environment.irisEndpoint],

		// Session Replay
		replaysSessionSampleRate: replaysSessionSampleRate, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: replaysOnErrorSampleRate, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
	})

	enableProdMode()
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch((err) => {
		console.log('(main) err:') // 09.06.2022
		console.error(err)
	})

// 28.04.2022
//var tempToken = window.location; // .search().token;
//console.log("(main) url: "+tempToken);  // ok, url + token

//if(tempToken){
//console.log("(main) tempToken: "+tempToken);

// TODO, trovare dove mettere questi controlli generali, se qui o su app
/*
  // 30.04.2018
  var tempToken = $location.search().token;
  if(tempToken){
    //console.log("(main) tempToken: "+tempToken);
    session.setTempToken(tempToken);
  } 

  //if going to a pvt page and not logged then redirect to login
  if (!isLogin && !session.isLogged()) {

    // 06.09.2017 TODO gestire form del recover pwd da puk
    //console.log("not logged, pg: ["+next.$$route.originalPath+"] event "+event);
    //console.log("Forbidden, session expired");
    event.preventDefault();
    $location.url(Config.login);
    $location.replace();
  }
  */

import { Component, OnInit, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { SessionService } from '../../service/session.service'
//import { CategoriesController } from '../../component/categories/categories.controller';

import { Prescription } from '../../models/diagnosis.model'

@Component({
	selector: 'prescription',
	templateUrl: './prescription.component.html',
	styleUrls: ['./prescription.component.scss'],
})
export class PrescriptionComponent implements OnInit {
	@Input() rxFinalR: Prescription
	@Input() rxFinalL: Prescription

	rxDesign = [
		{ code: 1, descr: 'GLASS_DESIGN.1' },
		{ code: 2, descr: 'GLASS_DESIGN.2' },
		{ code: 3, descr: 'GLASS_DESIGN.3' },
		{ code: 4, descr: 'GLASS_DESIGN.4' },
		{ code: 5, descr: 'GLASS_DESIGN.5' },
		{ code: 9, descr: 'GLASS_DESIGN.9' }, // 10.05.2022
	]

	constructor(public session: SessionService, public activeModal: NgbActiveModal) {
		console.log('(prescr) - constructor')
	}

	ngOnInit(): void {
		console.log('(prescr) - ngOnInit')
	}

	ngAfterViewInit() {
		console.log('(prescr) - ngAfterViewInit')
	}
}

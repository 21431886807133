import { Component, OnInit } from '@angular/core'
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'
import { SessionService } from 'src/app/service/session.service'
import { environment } from 'src/environments/environment'

import { Util } from 'src/app/models/util.model'

@Component({
	selector: 'app-trial',
	templateUrl: './trial.component.html',
	styleUrls: ['./trial.component.scss'],
})
export class TrialComponent implements OnInit {
	faCircleCheck = faCircleCheck

	constructor(public session: SessionService) {}

	ngOnInit(): void {}

	trial() {
		Util.debug('Select trial - going to ecomm and profile page')
		this.session.gotoEcomm()

		setTimeout(() => {
			if (this.session.getRoute() !== 'agreement') this.gotoProfile()
		}, 2000)
	}
	// gotoEcomm() {
	// 	var cookieName = 'nexus';
	// 	var cookieValue = sessionStorage.getItem("user");
	// 	var myDate = new Date();
	// 	myDate.setMinutes(myDate.getMinutes() + 5);
	// 	document.cookie = cookieName +"=" + cookieValue + ";expires=" + myDate + ";domain=.nexus.visionix.com;path=/;SameSite=Strict";
	// 	window.open(environment.ecommerce, 'ecommerce').focus()
	// }
	gotoProfile() {
		return this.session.loadProfilePage()
	}
}

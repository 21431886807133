import { Component, OnInit } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
//import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
//import { faDownload } from '@fortawesome/free-solid-svg-icons';

//import { DataModelService, DataStatus } from '../../service/data-model.service';
import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons'

@Component({
	selector: 'verify-puk',
	templateUrl: './verify-puk.component.html',
	styleUrls: ['./verify-puk.component.scss'],
})
export class VerifyPukComponent implements OnInit {
	currentAction: string // 13.12.2017
	//filter: any;    // 19.12.2017 per traduzioni, load da properties
	success = false
	fail = false
	puk = ''
	validPwd: boolean // 17.02.2021
	isLoading: boolean
	showIcon: boolean

	faCircleCheck = faCircleCheck

	constructor(
		public session: SessionService,
		private translator: TranslateService, //public modalService: NgbModal, //private dataService: DataModelService
		private toastService: AppToastService
	) {
		this.currentAction = '' // 13.12.2017
		//this.filter = $filter;
		this.validPwd = false
		this.isLoading = false
		this.showIcon = false
	}

	ngOnInit(): void {}

	/*
  // 04.03.2021 sulla nexus mancano le api [ls]
  // 13.12.2017 nuova funzione di reset puk
  forgotPuk() {    
    this.currentAction = "resetPuk";    
    
    //19.12.2017 prende dalle properties
    var msg = this.filter('translate')('VERIFY_PUK.RESET_CONFIRM_MSG');
    //var msg = "Are you sure to reset your puk and generate a new one? ";
    if(!confirm(msg)) {
      return;
    }

    this.session.resetDoctorsPuk()
      .then((nuovoPuk) => {
        console.log("(reset puk) ok "+nuovoPuk);  // ok arriva qui
        
        // TODO esporre il puk, rimandando alla pagina di firstLogin passando il nuovo puk
        // che gli fa fare anche il download e prosegue con la richiesta di conferma

         this.location.url(Config.firstLogin);

      })
      .catch((error) => {
        console.log("(reset puk) error");
        console.log(error);  
    });
    
  }
  */

	// 17.02.2021 su Util, richiamata anche da altri [ls]
	validatePwd(value) {
		this.validPwd = Util.validatePwd(value)
	}

	submit(form?) {
		this.isLoading = true
		//if (form.$valid)
		{
			if (this.currentAction == 'resetPuk') {
				console.log('(verifyPuk) RESET - function TODO')
			} else {
				// 08.08.2022 tolgo spazi in testa e coda, per aiutare l'utente
				let myUsrPuk = this.puk.trim()
				//this.session.verifyDoctorPuk(this.puk)
				this.session
					.verifyDoctorPuk(myUsrPuk)
					.then((puk) => {
						this.isLoading = false
						console.log('(puk) initialize success')
						this.success = true
						this.fail = false

						// 09.05.2024 questa e' piu' leggera della loadDistribDoctors(...)
						if (this.session.isSpecialist()) {
							this.session.reactivateAllRelations()
							// TODO: verificare che il load relations fatto qui e anche nell'initAfterLogin non dia problemi
						}

						// 20.06.2023 bisogna aggiornare le relazioni, caricare gli icds, etc
						if (this.session.isLevel2() || this.session.isClinicAdmin()) {
							this.session.initAfterLogin().then(() => {
								this.session.initAL = false
								this.showIcon = true
							})
							//this.session.loadDistribDoctors(this.session.getUserId())
							// NB: questo fa anche verifyNewRelations
						} else {
							setTimeout(() => {
								this.showIcon = true
							}, 700)
						}

						// 04.03.2021 c'e' gia' il msg esposto su if success
						// VERIFY_PUK.DESCRIPTION_SUCCESS = "your puk is correct"
						//this.puk = "OK";
						this.puk = ''
					})
					.catch((error) => {
						this.isLoading = false
						this.fail = true
						this.success = false
						this.puk = ''
						console.log('(puk) initialize error')
						console.log(error)

						let header = this.translator.instant('TOAST.HEADER.ERROR')
						let body = this.translator.instant('VERIFY_PUK.PUK_ERROR')
						let options = new ToastOptions('error')

						this.toastService.show(header, body, false, options, 'center')
					})
			}
		}
	}

	goLanding() {
		// if (this.session.isFreeTrialAvailable()) {
		// 	return this.session.loadTrialPage()
		// } else {
		return this.session.loadProfilePage()
		// }
	}
}

<!-- <h3>{{title}}</h3> -->
<div class="nexus-logo-container">
	<!-- <span class="nexus-logo nexus-logo-book">ne</span><span class="nexus-logo nexus-logo-light">xus</span> -->
	<!-- <img src="src/assets/images/visionix_nexus logo_vertical.png" title="Nexus - telemedicine platform" width="90" /> -->
</div>
<header [logged]="session.isLogged()"></header>
<div class="web-container container-custom">
	<!-- app toast notifications container -->
	<app-toast style="position: absolute; z-index: 1060" aria-live="polite" aria-atomic="true"></app-toast>

	<!-- app offcanvas notifications container -->
	<app-offcanvas style="position: absolute"></app-offcanvas>

	<!-- middle container -->
	<div style="width: 100%" *ngIf="session.isLogged()">
		<router-outlet></router-outlet>
	</div>

	<div style="width: 100%" *ngIf="!session.isLogged()">
		<app-login></app-login>
	</div>

	<div class="loader-status-container" *ngIf="activateLoader.activateLoader && session.isLogged()">
		<loader-status [activateLoader]="activateLoader"></loader-status>
	</div>

	<!-- su header
  <div *ngIf="session.isLogged()" >
    <form>
      <button (click)="session.logout();">Logout</button>
    </form>
  </div> -->
	<nav class="navbar-default navbar-bottom footer">
		<a (click)="openAbout()"><p>{{ 'FOOTER.ABOUT' | translate }}</p></a>

		<a><p>{{ 'FOOTER.ALL_RIGHT_RESERVED' | translate }} © 2020-{{ currYear }} Visionix</p></a>
	</nav>
</div>

import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { ActivatedRoute } from '@angular/router'
import { faTrashAlt, faEdit, faEye } from '@fortawesome/free-regular-svg-icons'
import { faCheck, faXmark, faLink, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'

import { Config } from '../../../config'
// import { TableSettings, PageMngr } from '../../service/table.settings'
import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'
// import { RelationModalContent } from './relation.modal'
// import { DeleteModalContent } from '../delete/delete.modal'

//import { Doctor } from '../../models/doctor.model';
import { Relation, Distrib } from '../../models/specialist.model'
// import { Doctor } from '../../models/doctor.model'
import { Util } from '../../models/util.model'
// import { ToastOptions } from 'src/app/models/toast.model'

// import { RelationInfoModal } from './relationInfo.modal'

import { MatTable, MatTableDataSource } from '@angular/material/table' //10.10.22
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator' //10.10.22
import { MatSort, Sort } from '@angular/material/sort' //10.10.22
import { AppToastService } from 'src/app/service/toast.service'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

// const myTableSetting = new TableSettings(); // poi uso la var piu' sotto
// const myProvider = { provide: SmartTable, useFactory: () => of([], myTableSetting), deps: [TableSettings] };

@Component({
	selector: 'relations',
	templateUrl: './relations.component.html',
	styleUrls: ['./relations.component.scss'],
})
export class RelationsComponent implements OnInit, OnDestroy, AfterViewInit {
	// Mat table
	relationList: MatTableDataSource<Relation> //10.10.22
	relationListRaw: Relation[]

	@ViewChild(MatPaginator) paginator: MatPaginator //10.10.22
	@ViewChild(MatSort) sort: MatSort //10.10.22
	@ViewChild('filter') input: ElementRef //10.10.22

	@ViewChild(MatTable) table: MatTable<any> //17.10.22 serve per poter rinfrescare la tabella dopo averla modificata

	filterWord: string

	displayedColumns: string[]

	optId: number
	//end

	currentAction: string
	currentModal
	currRel: Relation
	refId: number
	currDistrib: Distrib // anche specialist

	modalOpen: boolean
	loadRelations: boolean

	//input per la directive
	//refreshFlag: string   // 20.04.2023 non usiamo piu'

	// // per paginazione tabella
	// totElements: number; // 27.01.2022
	// itemsPerPage: number;
	// totalPages = 1;
	// currentPage = 1;
	// usrItemsPerPage;
	// pages: number[];
	// totPat: number;

	faEdit = faEdit // per usarla su html  13.01.2022
	faTrashAlt = faTrashAlt
	faEye = faEye
	faCheck = faCheck
	faXmark = faXmark
	faLink = faLink
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	constructor(
		public session: SessionService,
		public translator: TranslateService,
		public modalService: NgbModal,
		public dataService: DataModelService,
		public activatedRoute: ActivatedRoute,
		public toastService: AppToastService,
		public activeModal: NgbActiveModal
	) {
		Util.debug('(RelationsComponent) - constructor')
		this.refId = 0 // 04.05.2022
		this.currDistrib = new Distrib()
		this.currRel = new Relation()
		this.relationListRaw = []
		// this.loadUrlParameters()

		this.loadRelations = true

		this.modalOpen = false

		this.initColumns()
		// this.loadRelationList();
	}

	ngOnInit(): void {
		Util.debug('(RelationsComponent) - onInit')

		// if (this.activatedRoute != null) {
		// 	// 09.06.2022 added test
		// 	this.activatedRoute.queryParams.subscribe((params) => {
		// 		// mi ricavo id dello specialista/'distributore per poi chiamare la relation list
		// 		//this.optId = params['doctor'];

		// 		let myParam = params['doctor']
		// 		if (myParam != null && parseInt(myParam) > 0) {
		// 			this.optId = myParam
		// 		}

		// 		myParam = null
		// 		myParam = params['distrib']
		// 		if (myParam != null && parseInt(myParam) > 0) {
		// 			this.refId = myParam
		// 			Util.debug('(RelsDirective) - lev2: ' + this.refId)
		// 		}
		// 	})
		// }

		// this.session.getDtDistrib(this.refId).then((myDistrib) => {
		// 	Util.debug('(Rels_init) got full distrib, id: ' + this.currDistrib.user_id + ' grp: ' + this.currDistrib.settings.diagnosis_group)
		// 	this.currDistrib = myDistrib
		// })

		// // chiedo full obj per eventuale edit o new rel
		// this.session.loadDistrib('' + this.refId).then((myDistrib) => {
		// 	Util.debug('(Rels_init) got full distrib, id: ' + myDistrib.user_id + ' grp: ' + myDistrib.settings.diagnosis_group)
		// 	this.setCurrentDistrib(myDistrib)
		// })

		//10.08.2022 chiedo la doctorsList per evidenziare gli optician inactive
		//30-12-22 inserisco qui la loadRelationList, cosí la carico solo dopo aver tutti i dati anche dei doctor per applicare inactive
		// if (!this.dataService.hasLoadedDoctors()) {
		// 	this.session.loadDoctors().then((done) => {
		// 		//this.refreshFlag = 'forzato' // cosi' aggiunge inactive ?!
		// 		this.loadRelationList()
		// 	})
		// } else {
		// 	this.loadRelationList()
		// }

		// 10.08.2022 ho la lista, non una sola
		/*
		let myOpt = this.currRel.doctor_id;
		var myRel = this.session.getDtRelation(myOpt, false); // prende dai dati in memoria
		if(myRel){
			this.setCurrentRelation(myRel);	
			Util.debug("(Rels_init) rel, has_signature: "+myRel.has_signature);
		} else {
			Util.debug("(Rels_init) NULL rel");
		}
    */

		/*
		//TEMP TODO, serve x signtaure
		this.session.loadRelation(""+this.currRel.id)
		.then((myRel2) => {   
			Util.debug("(Rels - init) - got full rel, has_signature: "+myRel2.has_signature);
			this.setCurrentRelation(myRel2);				
		});	
		*/
	}

	ngAfterViewInit(): void {
		let relationDecrypted = this.relationListRaw.filter((el) => el.decrypted == false).length > 0

		if (relationDecrypted) {
			this.loadRelations = true
			this.session.loadRelationsList(this.currDistrib.user_id).then((relations) => {
				this.relationListRaw = relations
				this.loadRelations = false

				this.initTable(this.relationListRaw)
			})
		} else {
			this.loadRelations = false
			this.initTable(this.relationListRaw)
		}

		this.sort.active = 'is_valid'
		this.sort.direction = 'desc'
		this.sort.sortChange.emit()

		// setTimeout(() => {
		// 	this.sort.active = 'id'
		// 	this.sort.direction = 'asc'

		// 	this.relationList.paginator = this.paginator
		// 	this.relationList.sort = this.sort
		// }, 1000)
	}

	initTable(relationsList) {
		this.relationList = new MatTableDataSource<Relation>(relationsList)

		this.relationList.paginator = this.paginator
		this.relationList.sort = this.sort
	}

	//23-11-22
	// loadRelationList() {
	// 	if (this.activatedRoute != null) {
	// 		// 09.06.2022 added test
	// 		this.activatedRoute.queryParams.subscribe((params) => {
	// 			// mi ricavo id dello specialista/'distributore per poi chiamare la relation list
	// 			//this.optId = params['doctor'];

	// 			let myParam = params['doctor']
	// 			if (myParam != null && parseInt(myParam) > 0) {
	// 				this.optId = myParam
	// 			}

	// 			myParam = null
	// 			myParam = params['distrib']
	// 			if (myParam != null && parseInt(myParam) > 0) {
	// 				this.refId = myParam
	// 				Util.debug('(RelsDirective) - lev2: ' + this.refId)
	// 			}
	// 		})
	// 	}

	// 	this.session
	// 		.loadRelationsList(this.refId) //force refresh true
	// 		.then((rels) => {
	// 			// Util.debug(rels);
	// 			Util.debug('(rels) tot: ' + rels.length)

	// 			this.relationListRaw = rels

	// 			this.relationList = new MatTableDataSource<Relation>(this.relationListRaw)

	// 			this.relationList.paginator = this.paginator
	// 			this.relationList.sort = this.sort
	// 		})
	// 		.catch((myErr) => {
	// 			let msg = this.session.parseErrorMessage(myErr, 'trace')
	// 			Util.debug('(relations) KO ' + msg)
	// 		})
	// }

	initColumns() {
		// ok tutto per admins
		//this.displayedColumns = ['username', 'id', 'isOperActive', 'isValid', 'affiliationTime', 'display_name', 'has_signature', 'filter']

		this.displayedColumns = ['username', 'id']

		// il support vede solo quelle valide, campo sarebbe sempre vuoto
		if (!this.session.isSupport()) {
			this.displayedColumns.push('is_valid')
		}

		// per tutti
		this.displayedColumns.push('affiliationTime')
		this.displayedColumns.push('display_name')
		this.displayedColumns.push('has_signature')
		this.displayedColumns.push('filter')
	}

	filterText() {
		this.filterWord = this.input.nativeElement.value

		this.filterWord = this.filterWord.trim().toLocaleLowerCase()

		this.relationList.filter = this.filterWord
	}

	// 23.03.2022
	// private loadUrlParameters() {
	// 	this.refId = 0
	// 	if (this.activatedRoute != null) {
	// 		// 09.06.2022 added test
	// 		this.activatedRoute.queryParams.subscribe((params) => {
	// 			let myParam = params['distrib']
	// 			if (myParam != null && parseInt(myParam) > 0) {
	// 				this.refId = myParam
	// 				Util.debug('(Rels) - lev2: ' + this.refId)
	// 				let myDistrib = this.session.geDistribFromList(this.refId)
	// 				if (myDistrib != null) {
	// 					this.setCurrentDistrib(myDistrib) // intanto dalla lista, ma mancano i settings
	// 				}
	// 			}
	// 		})
	// 	}
	// }

	// private setCurrentRelation(rel) {
	// 	if (rel) {
	// 		this.currRel = rel
	// 	} else {
	// 		this.clearCurrentRelation()
	// 	}
	// }

	private clearCurrentRelation() {
		this.currRel = new Relation()
	}

	// private setCurrentDistrib(distrib) {
	// 	if (distrib) {
	// 		this.currDistrib = distrib
	// 	} else {
	// 		this.clearCurrentDistrib()
	// 	}
	// }

	private clearCurrentDistrib() {
		this.currDistrib = new Distrib()
	}

	// showDetails(myRelId) {
	// 	Util.debug('(Relations) - showDetails');
	// }

	// openCreateRelModal() {
	// 	this.modalOpen = true
	// 	//alert("Relations: create new not available yet");
	// 	this.currentAction = Config.ACT_CREATE // "create";
	// 	this.clearCurrentRelation()
	// 	this.openModal()
	// }

	// openEditModal(rel: Relation) {
	// 	//alert("Relations: edit not available yet");
	// 	this.currentAction = Config.ACT_EDIT // "modify";
	// 	this.setCurrentRelation(rel)

	// 	this.openModal()
	// }

	// 20.04.2023
	// openShowModal(rel: Relation) {
	// 	this.currentAction = Config.ACT_SHOW // readonly
	// 	this.setCurrentRelation(rel)
	// 	//this.openModal()  // questo apre relation.modal
	// 	this.openInfoModal(rel) // questo apre relationInfo.modal
	// }

	// create | edit | restore
	// private openModal(relation?) {
	// 	Util.debug('COMP (RelModal) going to open it... action: ' + this.currentAction)

	// 	this.currentModal = this.modalService.open(RelationModalContent, { size: 'xl' })

	// 	//this.currentModal.componentInstance.parent = this; // 24.11.2021 per poi chiamare session o altre globali
	// 	this.currentModal.componentInstance.currentAction = this.currentAction

	// 	this.currentModal.componentInstance.currDistrib = this.currDistrib
	// 	this.currentModal.componentInstance.currRel = this.currRel

	// 	// close del modal
	// 	this.currentModal.result.then(
	// 		(changedRel) => {
	// 			Util.debug('COMP Rels - After modal closed: ')
	// 			// this.loadRelationList()
	// 			this.modalOpen = false
	// 		},
	// 		(reason) => {
	// 			//Util.debug('Dismissed ' + this.getDismissReason(reason))
	// 			let ris = 'Dismissed ' + Util.getDismissReason(reason)
	// 			Util.debug(ris)

	// 			this.modalOpen = false
	// 		}
	// 	)
	// }

	// private openConfirmModal(text): Promise<boolean> {
	// 	this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false })

	// 	this.currentModal.componentInstance.isExit = false
	// 	this.currentModal.componentInstance.isQuest = true
	// 	this.currentModal.componentInstance.warnText = text

	// 	return this.currentModal.result
	// }

	// // usiamo unica funz su Utils
	// private DISAB_getDismissReason(reason: any): string {
	// 	if (reason === ModalDismissReasons.ESC) {
	// 		return 'by pressing ESC'
	// 	} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
	// 		return 'by clicking on a backdrop'
	// 	} else {
	// 		return 'with: ' + reason
	// 	}
	// }

	// // restore deleted relation
	// openRestRelModal(rel: Relation) {
	// 	Util.debug('(Restore Rel)')

	// 	this.currentAction = Config.ACT_RESTORE
	// 	this.setCurrentRelation(rel)

	// 	// 22.06.2023 mi servono i settings dell'optician per cfr i gruppi
	// 	this.session.loadDoctor(parseInt(rel.doctor_id)).then((flagDone) => {
	// 		// 07.12.2022 control for diagnosis_group
	// 		if (!this.areSameDiagnGroups()) {
	// 			// alert('Relation cannot be restored because they have different diagnosis group.')

	// 			//toast
	// 			let header = this.translator.instant('TOAST.HEADER.INFO')
	// 			let body = 'Relation cannot be restored because they have different diagnosis group.'
	// 			let options = new ToastOptions('info')
	// 			this.toastService.show(header, body, false, options, 'bottom-right')
	// 		} else {
	// 			let text = 'Are you sure, you want to restore the ralation with: <b>' + rel.username + '</b>?'

	// 			this.openConfirmModal(text).then((confirmed) => {
	// 				Util.debug('(Restore Rel - Confirm modal result) - ' + confirmed)

	// 				if (confirmed) {
	// 					this.session
	// 						.restoreRelation(rel.id)
	// 						.then(() => {
	// 							// this.loadRelationList()

	// 							let header = this.translator.instant('TOAST.HEADER.SUCCESS')
	// 							let body = this.translator.instant('TOAST.RELATION') + ' id: ' + rel.id + ' ' + this.translator.instant('TOAST.BODY.RESTORED')
	// 							let options = new ToastOptions('success')
	// 							this.toastService.show(header, body, false, options, 'bottom-right')
	// 						})
	// 						.catch((err) => {
	// 							console.log(err)

	// 							var msg = err.data ? err.data.error : err.toString()
	// 							Util.debug('(DELETE) relation: error ' + msg)

	// 							//toast
	// 							let header = this.translator.instant('TOAST.HEADER.ERROR')
	// 							let body = this.translator.instant('TOAST.RELATION') + ' id: ' + rel.id + ' ' + this.translator.instant('TOAST.BODYERR.RESTORED') + msg
	// 							let options = new ToastOptions('error')
	// 							this.toastService.show(header, body, false, options, 'center')
	// 						})
	// 				}
	// 			})
	// 		}
	// 	})
	// }

	// openDeleteModal(rel: Relation) {
	// 	Util.debug('(Delete Rel)')

	// 	let text = 'Delete Relation for ' + rel.display_name + ' with username: <b>' + rel.username + '</b>?'

	// 	this.openConfirmModal(text).then(
	// 		(confirmed) => {
	// 			Util.debug('(Restore Rel - Confirm modal result) - ' + confirmed)

	// 			if (confirmed) {
	// 				this.session
	// 					.deleteRelation(rel.id)
	// 					.then(() => {
	// 						// this.loadRelationList()

	// 						//toast
	// 						let header = this.translator.instant('TOAST.HEADER.SUCCESS')
	// 						let body = this.translator.instant('TOAST.RELATION') + ' id: ' + rel.id + ' ' + this.translator.instant('TOAST.BODY.DELETED')
	// 						let options = new ToastOptions('success')
	// 						this.toastService.show(header, body, false, options, 'bottom-right')
	// 					})
	// 					.catch((err) => {
	// 						var msg = err.data ? err.data.error : err.toString()
	// 						Util.debug('(DELETE) relation: error ' + msg)

	// 						//toast
	// 						let header = this.translator.instant('TOAST.HEADER.ERROR')
	// 						let body = this.translator.instant('TOAST.RELATION') + ' id: ' + rel.id + ' ' + this.translator.instant('TOAST.BODYERR.DELETED') + msg
	// 						let options = new ToastOptions('error')
	// 						this.toastService.show(header, body, false, options, 'center')
	// 					})
	// 			}
	// 		},
	// 		(reason) => {
	// 			Util.debug('(relationslist) [DELETE] ko user ' + this.session.getUserId() + ' ' + reason)
	// 		}
	// 	)
	// }

	// // 21.01.2022 apre la info, la stessa che vede il ref to see display name and signature for each operator
	// private openInfoModal(rel: Relation) {
	// 	this.currentModal = this.modalService.open(RelationInfoModal, { size: 'xl' })
	// 	this.currentModal.componentInstance.currentRel = rel

	// 	// arriva qui facendo la close del modal
	// 	this.currentModal.result.then(
	// 		(changes) => {
	// 			Util.debug('RelInfo - After modal closed, ' + changes)
	// 		},
	// 		(reason) => {
	// 			let ris = 'Dismissed ' + Util.getDismissReason(reason)
	// 			Util.debug(ris)
	// 		}
	// 	)
	// }

	// 20.10.2021 controllo che abbiano lo stesso diagnosis_group
	// private areSameDiagnGroups() {
	// 	var lev1DiagnGroup = -2
	// 	var lev2DiagnGroup = -1

	// 	if (this.currDistrib.settings) {
	// 		lev2DiagnGroup = this.currDistrib.settings.diagnosis_group
	// 	}

	// 	var currDoct = this.session.getDtDoctor(this.currRel.doctor_id, true)
	// 	if (currDoct && currDoct.settings != null) {
	// 		lev1DiagnGroup = currDoct.settings.diagnosis_group
	// 	}

	// 	if (lev2DiagnGroup != lev1DiagnGroup) {
	// 		Util.debug('(relationslist) ko, grp grader: ' + lev2DiagnGroup + ' grp opt: ' + lev1DiagnGroup)
	// 		return false
	// 	} else {
	// 		return true
	// 	}
	// }

	ngOnDestroy(): void {
		this.toastService.clear()
	}
}

import { Component, OnInit, Input } from '@angular/core'

import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { SessionService } from '../../service/session.service'
//import { AdminListComponent } from './adminList.component';

import { Admin } from '../../models/admin.model'
import { AdmSettings } from '../../models/settings.model'
import { Util } from '../../models/util.model'

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'admin',
	templateUrl: './admin.modal.html',
	styleUrls: ['./admin.modal.scss'],
	providers,
})
export class AdminModal implements OnInit {
	@Input() currentAdmin: Admin
	@Input() currentAction: string

	// dispPassword: string // dispositive admin pwd field, for edit, delete, etc
	// wrongPwd: boolean

	listAvail: string[]
	listSelected: string[]

	faArrowLeft = faArrowLeft
	faArrowRight = faArrowRight

	constructor(public activeModal: NgbActiveModal, public session: SessionService) {
		this.listAvail = []
		this.listSelected = []

		if (this.currentAdmin && this.currentAdmin.isManager()) {
			this.initSelectedModels()
		}
	}

	ngOnInit(): void {
		this.listAvail = this.session.deviceModels.slice()

		if (this.currentAdmin && this.currentAdmin.isManager()) {
			this.initSelectedModels()
		}
		// this.loadCountries() // non serviva a nulla
	}

	// 02.05.2022 if manager, init selected models
	private initSelectedModels() {
		let sel = this.currentAdmin.admSettings.models

		let list = sel.split(',') // tokenizer

		if (list.length != null) {
			Util.debug('(initSelectedModels) tot: ' + list.length)
			for (let j = 0; j < list.length; j++) {
				//Util.debug("(initSelectedModels) j: "+j+" val: "+list[j]);
				let ind = this.listAvail.indexOf(list[j])
				//Util.debug("(initSelectedModels) ind: "+ind+" val: "+list[j]);
				if (ind != null && ind > -1) {
					this.addModel(ind)
				}
			}
		}
	}

	editAdmin() {
		// if (this.session.checkNSPwd(this.dispPassword) == true) {
		// 	this.wrongPwd = false
		// } else {
		// 	this.wrongPwd = true
		// 	return
		// }

		if (!this.session.isGod()) {
			console.log('(editUser) forbidden!')
			return false
		}

		// 15.06.2021
		var newModels = this.getSelectedModels()
		console.log('(editAdm) new: ' + newModels)

		if (newModels == '') {
			alert('Please select at least one model')
			return false
		}

		// passo tutto l'oggetto, per usi futuri
		var admSett = new AdmSettings()
		admSett.models = newModels

		this.session
			.updateAdmin(this.currentAdmin.id, admSett)
			.then(() => {
				Util.debug('(AdminList-Update) done... ')
				let flagDone = true
				return this.activeModal.close(flagDone)
			})
			.catch((err) => {
				console.log(err)
				return false
			})
		//.then(refresh);
	}

	// 02.05.2022 per profilo manager ******************

	// sposta quello checked nella lista 2
	addModel(i) {
		Util.debug('(addModels) ' + i)
		let elem = this.listAvail[i]
		this.listSelected.push(elem)
		this.listAvail.splice(i, 1) // lo toglie
	}

	// sposta quello checked nella lista 1
	removeModel(j) {
		Util.debug('(removeModel) ' + j)
		let elem = this.listSelected[j]
		this.listAvail.push(elem)
		this.listSelected.splice(j, 1) // lo toglie
	}

	getSelectedModels() {
		Util.debug('(getSelectedModels)')
		var myNewModels = ''
		var items = this.listSelected
		var n = items.length
		Util.debug('(getSelModels) list2: ' + n)

		for (let idx = 0; idx < n; idx++) {
			if (idx != 0) myNewModels += ',' // non davanti al primo
			myNewModels += '' + items[idx]
		}

		Util.debug('(getSelModels) ' + myNewModels)
		return myNewModels
	}
	// private loadCountries() {
	// 	// 30.08.2022 richiedo solo una volta per sessione
	// 	let countries = this.session.getDtCountries()

	// 	if (countries != null && countries.length > 0) {
	// 		Util.debug('(adminModal) already loaded tot countries: ' + countries.length)
	// 	} else {
	// 		Util.debug('(adminModal) going to request countries...')
	// 		this.session
	// 			.getCountries()
	// 			.then((list) => {
	// 				countries = list
	// 				Util.debug('(adminModal) tot countries: ' + countries.length)
	// 			})
	// 			.catch((error) => {
	// 				Util.debug('(adminModal) ERR on countries!')
	// 				console.log(error)
	// 			})
	// 	}
	// }
	// 23/01/2023 duplicated in the doctor.modal.ts
	getReadOnlyCountry() {
		let myC = this.currentAdmin.country

		// 14.04.2023 spostata su session
		let ret = this.session.getCountryFullName(myC)
		/*
		let c = this.session.getSingleCountry(ret)
		if (c) {
			ret = c.name + '  (' + ret + ')'
		}
    */
		return ret
	}
}

import { Component, Input } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { SessionService } from 'src/app/service/session.service'

@Component({
	selector: 'device-modal',
	templateUrl: './addDevice.modal.html',
	styleUrls: ['./addDevice.modal.scss'],
})
export class addDeviceModal {
	@Input() currentPatientId: number

	device: {
		model: string
		sn: string
		available_space: number
		gmt_offset: string
		components: any[]
	}

	models: string[]

	constructor(public activeModal: NgbActiveModal, private session: SessionService, private translator: TranslateService) {
		this.device = {
			model: '',
			sn: '',
			available_space: 76648930,
			gmt_offset: '0200',
			components: [],
		}

		this.models = this.session.deviceModels

		// console.log(this.device)
	}

	public dismiss() {
		this.activeModal.dismiss()
	}

	public submit() {
		console.log(this.device)

		this.session
			.saveNewUserDevice(this.device)
			.then((resp) => {
				console.log(resp)
				this.activeModal.close(true)
				alert('ok new device added, id: ' + resp.device_id)
			})
			.catch((err) => {
				console.log(err)
				alert(err.error.error)

				this.device = {
					model: '',
					sn: '',
					available_space: 76648930,
					gmt_offset: '0200',
					components: [],
				}
			})
	}
}

<div class="recap-header container-fluid" style="border: none">
	<!--  [hidden]="!session.hasLoadedPatient()" 30.07.2020 ko, capire xche' [ls]  ng-init="currPat=patient;" gestito dai chiamanti -->

	<div class="row">
		<div class="datafield col-auto">
			<span>{{ 'PATIENT.INFO' | translate }}</span>
		</div>

		<field class="field col-auto" name="{{ 'PATIENT.NAME' | translate }}" value="{{ currPatient.name }}"></field>

		<field class="field col-auto" name="{{ 'PATIENT.CODE' | translate }}" value="{{ currPatient.code }}"></field>

		<field class="field col-auto" name="{{ 'PATIENT.AGE' | translate }}" value="{{ (session.isLevel1() ? currPatient.birthDate : '' + currPatient.birthYear) | age }}"> </field>

		<field class="field col-auto" name="{{ 'PATIENT.SEX' | translate }}" value="{{ 'SEX.' + currPatient.sex | translate }}"></field>

		<field *ngIf="session.isLevel1()" class="field col-auto" name="{{ 'PATIENT.PERSONAL_ID' | translate }}" value="{{ currPatient.personal_id}}"></field>

		<!-- 30.07.2020 added -->
		<!-- <field class="field col-auto" name="{{ 'PATIENT.ETHNICITY' | translate }}" value="{{ 'RACE.' + currPatient.race | translate }}"></field> -->

		<div class="eigth-container"></div>
		<!-- 30.07.2020 temporaneamente disab [ls]
    <div ng-show="session.isSpecialist() || session.isOptician() || session.isGod()"
      class="eigth-container refstatus">
      <p>{{"PATIENT.HAS_REPORT" | translate}}</p>
      <i ng-if="session.getPatientReportFlag()=='U'" class="statusrefU glyphicon glyphicon-ok" tooltip="{{'PATIENT_LIST.REPORT_URGENT' | translate}}" ></i>
      <i ng-if="session.getPatientReportFlag()=='Y'" class="statusrefT glyphicon glyphicon-ok" tooltip="{{'PATIENT_LIST.REPORT_OK' | translate}}" ></i>
      <i ng-if="session.getPatientReportFlag()=='N'" class="statusrefF glyphicon glyphicon-ok" tooltip="{{'PATIENT_LIST.REPORT_TODO' | translate}}"></i>
      <i ng-if="session.getPatientReportFlag()=='H'" class="statusrefH" tooltip="{{'PATIENT_LIST.NOT_VISIBLE' | translate}}"></i>
      <i ng-if="session.getPatientReportFlag()=='E'" class="statusrefE" tooltip="{{'PATIENT_LIST.NO_IMAGES' | translate}}"></i>
    </div>
    -->
	</div>
</div>

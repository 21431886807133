<div class="panel-modal qrcode-modal">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
	</div>

	<div class="container-fluid qrcode-container">
		<div class="row">
			<div class="col-12">
				<div class="row mt-4">
					<div class="col-12 d-flex flex-column align-items-center">
						<h4 class="fw-bold">{{storeName}}</h4>

						<div class="qrcode-container__img position-relative">
							<!-- LOADER -->

							<loader [activateSpinner]="isLoading && !showMessage"></loader>

							<!-- <div class="loader-container" [ngClass]="isLoading && !showMessage ? 'show' : '' ">
								<div [hidden]="false" class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div> -->

							<message-modal *ngIf="showMessage" [texts]="messageText" [state]="messageState"></message-modal>

							<qrcode class="my-4" *ngIf="!isLoading && !showMessage" [qrdata]="qrCodeUrl" (qrCodeURL)="onChangeURL($event)" [width]="400" [errorCorrectionLevel]="'Q'" [allowEmptyString]="true" #qrcode></qrcode>
						</div>

						<div class="text-center" style="line-height: 30px" *ngIf="!showMessage">
							<!-- <p>{{qrCodeUrl}}</p> -->
							<p>{{'QRCODE.MESSAGE1' | translate}}</p>
							<p>{{'QRCODE.MESSAGE2' | translate}}</p>
						</div>

						<div class="text-center">
							<!-- <a (click)="generateNewUUID()" *ngIf="expired">New QrCode</a> -->
							<button class="btn btn-primary" (click)="generateNewUUID()" *ngIf="expired">{{'QRCODE.NEW_QR_BUTTON' | translate}}</button>
						</div>

						<div class="text-center mt-3">
							<hr />
							<p>{{'QRCODE.MESSAGE3' | translate}}</p>
							<button type="button" style="font-size: 0.85rem" class="btn btn-secondary" (click)="activeModal.close('manual')">{{'QRCODE.MANUAL_BUTTON' | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div>
	<div class="topview main-container">
		<div class="row">
			<div class="full-container">
				<div class="panel panel-default">
					<div class="panel-heading">&nbsp;</div>
					<div class="panel-body">
						<div class="row">&nbsp;</div>
						<div class="row">
							<label class="panel-title"><b>Import files from SAP</b></label>
						</div>
						<div class="row">
							<div class="half-container">
								<label for="sapFile">Upload file: &nbsp;</label>
								<input type="file" class="form-group" name="sapFile" id="sapFile" accept=".csv" />
							</div>
							<div class="sixth-container">
								<select class="form-control" [(ngModel)]="filterYear" name="filterYear">
									<option [ngValue]="''+currentYear">only {{currentYear}}</option>
									<option [ngValue]="''+(currentYear - 1)">only {{(currentYear - 1)}}</option>
									<option [ngValue]="'ALL'">ALL</option>
								</select>
							</div>
							<div class="sixth-container">
								<button type="submit" (click)="readSapFile()" class="btn btn-primary">LOAD</button>
							</div>
						</div>
						<div class="row">&nbsp;</div>
						<!-- <div class="row">
							<div class="full-container">Total lines: {{filteredLines.length}}</div>
						</div> -->

						<div class="row">
							<div class="full-container">Response from server: {{srvResponse}}</div>
						</div>

						<div class="row">
							<div class="full-container">
								<label>Click to DOWNLOAD: <a class="underline" [hidden]="(srvResponse == '')" (click)="downloadSapResult()"> result </a></label>
							</div>
						</div>

						<div class="row">&nbsp;</div>
						<hr style="border-color: #e5491f; border-width: 4px" />

						<div class="row"><label class="panel-title">Manual Adjustments - Plans and/or credits and/or fee</label> &nbsp;</div>

						<div class="row">
							<div class="form-group col-2">Opt Username:</div>
							<div class="form-group col-4">
								<input type="text" maxlength="50" class="form-control" name="username" id="username" [(ngModel)]="usernameFilter" />
							</div>
							<div class="form-group col-4">[<a class="underline" (click)="findOptician()">Find user_id</a>] &nbsp;&nbsp; {{ myOpticianInfo }}</div>
						</div>

						<!-- OLD -->
						<!-- <div class="row">
							<div class="form-group col-2">
								user id *
								<input type="number" maxlength="10" class="form-control text-right" name="userId" id="userId" min="0" [(ngModel)]="singlePlan.userId" />
							</div>
							<div class="form-group col-3">
								plan
								<select class="form-control" [(ngModel)]="singlePlan.planId" name="plan">
									<option value="0">Choose...</option>
									<option *ngFor="let plan of salePlanList" [ngValue]="plan.id">{{plan.level}} - {{plan.name}}</option>
								</select>
							</div>

							<div class="form-group col-2">
								
								fee months
								<input type="number" maxlength="10" class="form-control text-right" name="month" id="month" min="0" [(ngModel)]="singlePlan.months" />
							</div>
							<div class="form-group col-2">
								
								credits
								<input type="number" maxlength="10" class="form-control text-right" name="credits" id="credits" min="0" [(ngModel)]="singlePlan.credits" />
							</div>

							<div class="form-group col-2">
								kind
								<select class="form-control" [(ngModel)]="singlePlan.kind" name="kind">
									<option *ngFor="let kind of salePlanKinds" [ngValue]="kind">{{kind}}</option>
								</select>
							</div>
						</div> -->

						<!-- NEW -->
						<form action="" #serviceForm="ngForm" (ngSubmit)="saveNewUserService()">
							<div class="row">
								<div class="form-group col-2">
									username *
									<input type="text" maxlength="30" class="form-control text-right" name="username" id="username" [(ngModel)]="service.username" required />
								</div>
								<div class="form-group col-2">
									SKU
									<select class="form-control" [(ngModel)]="service.items[0].code" name="sku" (change)="addDescription()" required>
										<option *ngFor="let service of skuArticles" [ngValue]="service.sku">{{service.sku}}</option>
									</select>
								</div>

								<div class="form-group col-5">
									<!-- *ngIf="!session.isProduction()" -->
									sku description
									<input type="text" class="form-control text-right" name="description" id="description" [value]="skuDescription" disabled />
								</div>

								<div class="form-group col-1">
									<!-- *ngIf="!session.isProduction()" -->
									quantity
									<input type="number" maxlength="1" class="form-control text-right" name="quantity" id="quantity" min="1" [(ngModel)]="service.items[0].quantity" required />
								</div>

								<div class="form-group col-2">
									kind
									<select class="form-control" [(ngModel)]="service.kind" name="kind">
										<option *ngFor="let kind of salePlanKinds" [ngValue]="kind">{{kind}}</option>
									</select>
								</div>

								<div class="form-group col-2">
									Additions
									<select class="form-control" [(ngModel)]="service.additions" name="additions" required>
										<option *ngFor="let addition of additionsList" [ngValue]="addition.value">{{addition.label}}</option>
									</select>
								</div>

								<div class="form-group col-2">
									<!-- *ngIf="!session.isProduction()" -->
									refId
									<input type="text" maxlength="20" class="form-control text-right" name="refId" id="refId" [(ngModel)]="service.refId" />
								</div>

								<div class="form-group col-2" *ngIf="success">
									<p class="fw-bold" style="color: green">OK service buy success</p>
								</div>
							</div>
							<div class="row">
								<div class="col-12">
									<button type="submit" [disabled]="serviceForm.invalid" class="btn btn-primary">SAVE</button>
								</div>
							</div>
						</form>

						<div class="row"><span>(No checks or other actions will be done on these manual sets.)</span></div>
						<div class="row">&nbsp;</div>

						<!-- <div class="row">
							<div class="sixth-container">
								<button type="submit" (click)="forceSinglePlan()" class="btn btn-primary" [disabled]="singlePlan.planId+singlePlan.credits+singlePlan.months == 0 || singlePlan.userId == 0">SAVE</button>
							</div>
						</div>

						<div class="row">&nbsp;</div>

						<div class="row">
							<div class="full-container">Response: {{srvSingleSetResp}}</div>
						</div>
						<div class="row">&nbsp;</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

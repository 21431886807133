<div class="panel panel-modal panel-default ng-cloak position-relative">
	<div class="panel-heading">
		<label class="panel-title" *ngIf="currentAction== 'create' ">{{'GRADER.MODAL_TITLE_NEW' | translate }}</label>
		<label class="panel-title" *ngIf="currentAction== 'modify' ">{{'GRADER.MODAL_TITLE_UPDATE' | translate }}</label>
	</div>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
	</div>

	<div class="container-fluid mt-4">
		<div class="row">
			<div class="col-12 panel-body position-relative">
				<loader [activateSpinner]="isLoading"></loader>

				<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
					<div [hidden]="false" class="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div> -->

				<form [formGroup]="graderForm" (ngSubmit)="submitForm()" class="container grader-form" [ngClass]="isLoading ? '' : 'show' ">
					<div class="row">
						<!-- username -->
						<div class="form-group col-4">
							<label for="username">{{ 'GRADER.USERNAME' | translate }}</label>
							<div class="input-group">
								<input type="text" name="username" id="username" class="form-control" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" formControlName="username" (keyup)="usernamecreation()" />
								<span *ngIf="currentAction == 'create'" class="input-group-text">{{usernameExtension}}</span>
							</div>
						</div>
						<!-- password -->
						<div class="form-group col-4">
							<div *ngIf="isCreate">
								<label for="password">{{ 'GRADER.PASSWORD' | translate }}</label>
								<input type="text" name="password" id="password" class="form-control" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" formControlName="password" readonly />
							</div>
							<div *ngIf="!isCreate">
								<label for="created"></label>
								<div class="input-group">
									<span class="input-group-text">{{ 'GRADER.CREATED' | translate }}</span>
									<span type="text" name="created" id="created" class="form-control">{{session.formatDateTime(grader.creationDate)}}</span>
								</div>
							</div>
						</div>

						<div class="form-group col-4">
							<div *ngIf="!isCreate">
								<label for="registrated"></label>
								<div class="input-group">
									<span class="input-group-text">{{ 'GRADER.REGISTERED' | translate }}</span>
									<span type="text" name="registrated" id="registrated" class="form-control">{{ grader.subscriptionTime ? session.formatDateTime(grader.subscriptionTime) : 'not registered' }}</span>
									<span class="input-group-text" *ngIf="grader.subscriptionTime"> <fa-icon [icon]="faCheck" aria-hidden="true" class="success"></fa-icon></span>
									<span class="input-group-text" *ngIf="!grader.subscriptionTime"><fa-icon [icon]="faXmark" aria-hidden="true" class="warning"></fa-icon></span>
								</div>
							</div>
						</div>

						<!-- warning messages -->
						<div class="col-4 d-flex align-items-start">
							<div class="mini-modal-warning" *ngIf="graderForm.get('username').invalid">
								<div class="arrow-up"></div>
								<div class="warning-body">
									<span *ngIf="!graderForm.get('username').errors['Invalid $ character'] && !graderForm.get('username').errors['Invalid blank space'] else warning">
										<fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon>
										<span style="font-size: 16px"> {{ 'GRADER.USERNAME_INFO' | translate }}</span>
									</span>

									<ng-template #warning>
										<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning"></fa-icon>
									</ng-template>

									<span style="font-size: 16px" *ngIf="graderForm.get('username').errors['Invalid $ character']"> {{ 'GRADER.USERNAME_WARN1' | translate }}</span>
									<span style="font-size: 16px" *ngIf="graderForm.get('username').errors['Invalid blank space']"> {{ 'GRADER.USERNAME_WARN2' | translate }}</span>
								</div>
							</div>

							<div class="mini-modal-warning" *ngIf="!graderForm.get('username').invalid && currentAction=='create'  ">
								<div class="arrow-up"></div>
								<div class="warning-body">
									<fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon>
									<span style="font-size: 16px"> {{ 'GRADER.USERNAME_VALID' | translate }} <b>{{displayUsername}}</b> </span>
								</div>
							</div>
						</div>

						<div class="col-4 d-flex">
							<div class="mini-modal-warning" *ngIf="isCreate">
								<div class="arrow-up"></div>
								<div class="warning-body">
									<fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon>
									<span style="font-size: 16px"> {{ 'GRADER.TEMPORARY_PASSWORD' | translate }}</span>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div class="row" formGroupName="userData">
						<!-- first name -->
						<div class="col-4 form-group">
							<label for="firstName">{{ 'GRADER.FIRSTNAME' | translate }}</label>
							<input type="text" name="firstName" id="firstName" class="form-control" formControlName="firstName" />
						</div>

						<!-- last name -->
						<div class="col-4 form-group">
							<label for="lastName">{{ 'GRADER.LASTNAME' | translate }}</label>
							<input type="text" name="lastName" id="lastName" class="form-control" formControlName="lastName" />
						</div>

						<!-- adress -->
						<div class="col-4 form-group">
							<label for="adress">{{ 'GRADER.ADRESS' | translate }}</label>
							<input type="text" name="adress" id="adress" class="form-control" formControlName="adress" />
						</div>

						<!-- city -->
						<div class="col-4 form-group">
							<label for="city">{{ 'GRADER.CITY' | translate }}</label>
							<input type="text" name="city" id="city" class="form-control" formControlName="city" />
						</div>

						<!-- province -->
						<div class="col-4 form-group">
							<label for="province">{{ 'GRADER.PROVINCE' | translate }}</label>
							<input type="text" name="province" id="province" class="form-control" formControlName="province" />
						</div>

						<!-- country -->
						<div class="col-4 form-group">
							<label for="country">{{ 'GRADER.COUNTRY' | translate }}</label>
							<input type="text" name="country" id="country" class="form-control" formControlName="country" />
						</div>

						<!-- email -->
						<div class="col-4 form-group">
							<label for="email">{{ 'GRADER.EMAIL' | translate }}</label>
							<input type="text" name="email" id="email" class="form-control" formControlName="email" />
						</div>

						<!-- organization -->
						<div class="col-4 form-group">
							<label for="organization">{{ 'GRADER.ORGANIZATION' | translate }}</label>
							<input type="text" name="organization" id="organization" class="form-control" formControlName="organization" />
						</div>

						<!-- licence number -->
						<div class="col-4 form-group">
							<label for="licenceNumber">{{ 'GRADER.LICENCE_NUM' | translate }}</label>
							<input type="text" name="licenceNumber" id="licenceNumber" class="form-control" formControlName="licenceNumber" />
						</div>
					</div>

					<div class="row mt-4 d-flex align-items-end">
						<div class="col-8">
							<div class="mini-modal-warning" *ngIf="isCreate">
								<!-- <div class="arrow-up"></div> -->
								<div class="warning-body">
									<span>
										<fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon>
										<span style="font-size: 16px">{{ 'GRADER.EMAIL_INFO' | translate }} </span>
									</span>
								</div>
							</div>
						</div>

						<!-- <div class="col-4"></div> -->

						<div class="col-4">
							<div class="row">
								<div class="col-6 text-end">
									<button type="submit" class="btn btn-primary" [disabled]="!graderForm.valid || disableButton">{{ 'GRADER.SAVE' | translate }}</button>
									<!-- <button type="submit" class="btn">Save</button> -->
								</div>

								<div class="col-6 text-end">
									<button type="button" class="btn btn-secondary" (click)="dismiss('undo')">{{ 'GRADER.CANCEL' | translate }}</button>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</div>
</div>

<table class="catInfo refraction z-depth-4">
	<thead>
		<tr>
			<th>{{ 'REFRACTION.FINAL' | translate }}</th>
			<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
			<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
			<th>{{ 'REFRACTION.AXIS' | translate }}</th>
			<th>{{ 'REFRACTION.PRISM_H' | translate }}</th>
			<th>{{ 'REFRACTION.PRISM_V' | translate }}</th>
			<th>{{ 'REFRACTION.ADD' | translate }}</th>
			<th>{{ 'REFRACTION.DVA' | translate }}</th>
			<th>{{ 'REFRACTION.NVA' | translate }}</th>
			<th>{{ 'REFRACTION.PD_MONO' | translate }}</th>
			<th>{{ 'REFRACTION.RX_DESIGN' | translate }}</th>
		</tr>
	</thead>
	<tr>
		<td>{{ 'MISC.RIGHT' | translate }}</td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.sphere" id="sphereR" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.cylinder" id="cylR" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.axis" id="axR" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.prism_h" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.prism_v" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.add" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.DVA" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.NVA" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalR.PDm" /></td>
		<td>
			<select class="form-control" name="designRx1" [(ngModel)]="rxFinalR.rx_design">
				<option value="-1" disabled>{{ 'REFRACTION.RX_DESIGN_HINT' | translate }}</option>
				<option *ngFor="let obj of rxDesign" [ngValue]="obj.code">{{ obj.descr | translate }}</option>
			</select>
		</td>
	</tr>
	<tr>
		<td>{{ 'MISC.LEFT' | translate }}</td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.sphere" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.cylinder" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.axis" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.prism_h" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.prism_v" /></td>
		<!-- <td><input type="text" class="form-control" [(ngModel)]="rxFinalL.base_h" /></td>   -->
		<!-- <td><input type="text" class="form-control" [(ngModel)]="rxFinalL.base_v" /></td> -->
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.add" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.DVA" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.NVA" /></td>
		<td><input type="text" class="form-control text-center" [(ngModel)]="rxFinalL.PDm" /></td>
		<td>
			<select class="form-control" name="designL1" [(ngModel)]="rxFinalL.rx_design">
				<option value="-1" disabled>{{ 'REFRACTION.RX_DESIGN_HINT' | translate }}</option>
				<option *ngFor="let obj of rxDesign" [ngValue]="obj.code">{{ obj.descr | translate }}</option>
			</select>
		</td>
	</tr>
</table>

import { Component, OnInit } from '@angular/core'
import { SessionService } from 'src/app/service/session.service'

import { Util } from 'src/app/models/util.model'
import { User } from 'src/app/models/user.model'
import { SaleInfo, BalanceRec } from '../../models/salePlan.model'

import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import { environment } from 'src/environments/environment'
import { Config } from 'src/config'
import { ActivatedRoute } from '@angular/router'

@Component({
	selector: 'app-subscription',
	templateUrl: './subscription.component.html',
	styleUrls: ['./subscription.component.scss'],
})
export class SubscriptionComponent implements OnInit {
	userPlanReady: boolean
	ecommerceReady: boolean
	userBalanceReady: boolean

	activeTab: string
	currUser: User
	saleInfo: SaleInfo
	balanceRecords: BalanceRec[]

	ecommercelink: string

	faUpRightFromSquare = faUpRightFromSquare

	ar: any // 11.12.2023

	constructor(public session: SessionService, private activatedRoute: ActivatedRoute) {
		this.userPlanReady = false
		this.ecommerceReady = false
		this.userBalanceReady = false

		this.activeTab = 'plan'

		this.ecommercelink = environment.ecommerce

		this.saleInfo = new SaleInfo(null)
		this.balanceRecords = []

		this.initProfileData()
		this.initCreditsTab()
		this.loadUrlParameter()
	}

	ngOnInit(): void {}

	initProfileData() {
		this.currUser = this.session.getCurrentUser()

		if (this.currUser.getBrand() == Config.BR_RDS) {
			this.activeTab = 'balance'
		}
	}

	loadUrlParameter() {
		if (this.activatedRoute != null) {
			this.ar = this.activatedRoute.queryParams.subscribe((params) => {
				let myParam = params['plan']
				if (myParam != null && myParam.length > 0) {
					this.activeTab = 'plan'
					Util.debug('(Subscription) enable tab: ' + myParam)
				}

				myParam = params['balance']
				if (myParam != null && myParam.length > 0) {
					this.activeTab = 'balance'
					Util.debug('(Subscription) enable tab: ' + myParam)
				}
			})
		}
	}

	onNavChange(event) {
		var nextTab = event.nextId // tab di destinazione
		Util.debug('C (onNavChange) event, next: ' + nextTab)
		//Util.debug(event);

		this.activeTab = nextTab
	}

	private initCreditsTab() {
		// per ora solo sui lev1
		if (!this.session.isLevel1()) {
			return
		}

		//this.flagRef = Config.GRAY
		if (this.saleInfo.sale_plan_id == 0) {
			// chiedo al server
			this.session.loadUserPlan(this.currUser.user_id).then((plan: SaleInfo) => {
				if (plan.sale_plan_id > 0) {
					this.saleInfo = plan
				}
				this.userPlanReady = true

				setTimeout(() => {
					this.ecommerceReady = true
				}, 800)
			})
		}

		if (!this.balanceRecords || this.balanceRecords.length == 0) {
			this.session.loadUserBalance(this.currUser.user_id).then((resp) => {
				//Util.debug("(saleBalance) ");
				//Util.debug(resp);
				//console.log(resp)
				let records: BalanceRec[]
				records = []
				if (resp && resp.balance) {
					for (let i = 0; i < resp.balance.length; i++) {
						let rec = new BalanceRec(resp.balance[i])
						if (rec.sku) {
							rec.description = this.session.getSkuDescription(rec.sku)
						}
						records.push(rec)
					}
					this.balanceRecords = records // su var diversa x non sovrapporre le promise su saleInfo
				}

				this.userBalanceReady = true
			})
		}
	}

	goLanding() {
		this.session.getLandingPage()
	}

	// moved on session
	// gotoEcomm() {
	// 	var cookieName = 'nexus';
	// 	var cookieValue = sessionStorage.getItem("user");
	// 	console.log(cookieValue);
	// 	var myDate = new Date();
	// 	myDate.setMinutes(myDate.getMinutes() + 5);
	// 	document.cookie = cookieName +"=" + cookieValue + ";expires=" + myDate + ";domain=.nexus.visionix.com;path=/;SameSite=Strict";
	// 	window.open(environment.ecommerce, 'ecommerce').focus()
	// }

	//
	//
	//
	//
	//
}

import { Config } from 'src/config'
import {
	ChartComponent,
	ApexAxisChartSeries,
	ApexChart,
	ApexXAxis,
	ApexTitleSubtitle,
	ApexNonAxisChartSeries,
	ApexResponsive,
	ApexOptions,
	ApexFill,
	ApexDataLabels,
	ApexPlotOptions,
	ApexYAxis,
	ApexTooltip,
	ApexStroke,
	ApexLegend,
} from 'ng-apexcharts'
// import { graders } from '../component/dashboard/dashboard.component'
import { Util } from './util.model'
import * as _moment from 'moment'
import { SalePlan } from './salePlan.model'

const moment = _moment

export interface customersAddedStat {
	user_id: number
	username: string
	country: string
	origin: string
	by_day?: string
	by_week?: string
	by_month?: string
	by_year?: string
	tot: number
}

export interface conversionStat {
	country: string
	created_by: number
	to_HG: string
	ai_reviewed?: string
	by_day?: string
	by_week?: string
	by_month?: string
	by_year?: string
	tot: number
}

export interface balances {
	amount: number
	buy_date: string
	buy_month: string
	buy_week: string
	country: string
	i_type: string
	notes: string
	ref_id: string
	user_id: number
	username: string
}

export class purchaseHOpt {
	id: number
	username: string
	sold: number
	used: number
	country: string
	purchaseList: purchaseList[]
	prev_balance: number
	final_balance: number

	constructor(objs?: balances[]) {
		this.id = 0
		this.username = ''
		this.sold = 0
		this.used = 0
		this.country = ''
		this.purchaseList = []
		this.prev_balance = 0
		this.final_balance = 0

		if (objs) {
			this.username = objs[0].username
			this.id = objs[0].user_id
			this.country = objs[0].country

			for (let i = 0; i < objs.length; i++) {
				const obj = objs[i]
				this.purchaseList.push(new purchaseList(obj))
			}
		}
	}
}

export class purchaseList {
	date: Date
	buy_date: string
	buy_week: string
	buy_month: string
	i_type: string
	notes: string
	ref_id: string
	amount: number
	buy: boolean
	used: boolean

	constructor(obj?: balances) {
		this.date = null
		this.buy_date = ''
		this.buy_week = ''
		this.buy_month = ''
		this.i_type = ''
		this.notes = ''
		this.ref_id = ''
		this.amount = 0
		this.buy = false
		this.used = false

		if (obj) {
			this.date = new Date(obj.buy_date)
			this.buy_date = obj.buy_date
			this.buy_week = obj.buy_week
			this.buy_month = obj.buy_month
			this.i_type = obj.i_type
			this.notes = obj.notes
			this.ref_id = obj.ref_id
			this.amount = obj.amount

			if (obj.i_type == SalePlan.ITEM_CREDITS) {
				if (obj.amount > 0) {
					this.buy = true
				} else {
					this.used = true
				}
			}
		}
	}
}

export interface gradingsList {
	username: string
	type: string
	gradings_done: number
	gradings_pending: number
	isOnChart: boolean
}

export interface gradingsListData {
	grader_id: number
	username: string
	country: string
	user_subtype: string
	user_group_id: number
	tot_reports: number
	tot_follow_soon: number
	tot_follow_3_months: number
	tot_follow_6_months: number
	tot_follow_1_year: number
	gradings_pending?: number
}

export interface performance {
	country: string
	created_by: number
	creation_date: string
	dt_month: string
	dt_week: string
	due_date: string
	end_lock: string
	grading_request: string
	grading_time: string
	lock_time: string
	patient_id: number
	report_id: number
	start_lock: string
	user_group_id: number
	username: string
	user_subtype: string
}

export class graders {
	username: string
	tot_reports: number
	full_reports: number
	fundus_reports: number
	isEnabled: boolean // grader abilitato/disabilitato
	isOnChart: boolean // grader presente/non presente nel grafico
	isSelected: boolean // quando seleziono il grader nella lista, il graifoc mostra solo i suoi dati
	isDeleted: boolean // se un grader é eliminato
	av_lock_time: number // in secondi
	av_lock_time_s: string // 00:00:00 hh:mm:ss
	av_grading_time: number
	av_grading_time_s: string
	within_24h: number // %
	over_48h: number // %
	user_group_id: number
	reports: report[]
	type: string
	country: string

	constructor(objs?: performance[]) {
		this.username = ''
		this.tot_reports = 0
		this.full_reports = 0
		this.fundus_reports = 0
		this.isEnabled = true
		this.isOnChart = true
		this.isSelected = false
		this.isDeleted = false
		this.av_lock_time = 0
		this.av_lock_time_s = ''
		this.av_grading_time = 0
		this.av_grading_time_s = ''
		this.within_24h = 0
		this.over_48h = 0
		this.user_group_id = 0
		this.reports = []
		this.type = ''
		this.country = ''

		if (objs) {
			this.username = objs[0].username
			this.user_group_id = objs[0].user_group_id
			this.type = objs[0].user_subtype === 'Private' ? 'private' : 'VX'
			this.country = objs[0].country

			for (let i = 0; i < objs.length; i++) {
				const obj = objs[i]
				this.reports.push(new report(obj))
			}
		}
	}
}

export class report {
	creation_date: string
	dt_month: string
	dt_week: string
	due_date: string
	end_lock: string
	grading_request: string
	grading_time: string
	lock_time: string
	patient_id: number
	report_id: number
	start_lock: string

	constructor(obj?: performance) {
		this.creation_date = ''
		this.dt_month = ''
		this.dt_week = ''
		this.due_date = ''
		this.end_lock = ''
		this.grading_request = ''
		this.grading_time = ''
		this.lock_time = ''
		this.patient_id = 0
		this.report_id = 0
		this.start_lock = ''

		if (obj) {
			this.creation_date = obj.creation_date
			this.dt_month = obj.dt_month
			this.dt_week = obj.dt_week
			this.due_date = obj.due_date
			this.end_lock = obj.end_lock
			this.grading_request = obj.grading_request
			this.grading_time = obj.grading_time
			this.lock_time = obj.lock_time
			this.patient_id = obj.patient_id
			this.report_id = obj.report_id
			this.start_lock = obj.start_lock
		}
	}
}

export class Statistics {
	//cadence
	static day = 'day'
	static weekly = 'weekly'
	static bi_weekly = 'bi_weekly'
	static monthly = 'monthly'
	static yearly = 'yearly'

	static cadenceStat = [this.day, this.weekly, this.monthly, this.yearly]

	//options
	// static patients = 'patients' // no more used
	// static visits = 'visits' //Visits done by operators
	// static operators = 'operators' //Exams done by operators
	// static graded = 'graded' //no more used
	static hg_reports = 'hg_reports' //Reports done by specialist or doctors (C or B users)
	// static ai_reports = 'ai_reports' //no more used
	static aireportsfull = 'aireportsfull' //Vistel AI reports (export full list)
	static accounts = 'accounts' //Account created
	static accounts_plan = 'plans' //Account plans
	static funnel_hg = 'funnel_hg'
	static funnel_ai = 'funnel_ai'
	static performances = 'performances'
	static grading_req = 'grading_requests'
	static purchase_h = 'balances'
	static customers = 'patients_cadence'
	static conversion_customers = 'pats_funnel_hg_cadence'
	static conversion_ai = 'pats_funnel_ai_hg_cadence'

	static operator: Statfilter = { filter_name: 'Operator', db_name: Config.PR_OPTICIAN }
	static grader: Statfilter = { filter_name: 'Grader', db_name: Config.PR_SPECIALIST }

	constructor() {}
}

export interface statOptions {
	type: string
	text: string
}

export interface Statfilter {
	filter_name: string
	db_name: string
}

export class StatInMemory {
	fromDate: string
	toDate: string
	profile: string
	option: string
	optList?: string
	response: any

	constructor() {
		this.fromDate = ''
		this.toDate = ''
		this.profile = ''
		this.option = ''
		this.response = null
		this.optList = ''
	}
}

// charths classes

// 1) pie chart type
export class pieChartDatas {
	data: ApexAxisChartSeries
	labels: string[]
	title: string
	colors: string[]

	constructor() {
		this.data = []
		this.labels = []
		this.title = ''
		this.colors = [
			'#81b9f2',
			'#49ae04',
			'#d62115',
			'#e9c00b',
			'#129cb5',
			'#d97f6a',
			'#403da6',
			'#8a3358',
			'#627993',
			'#d8954c',
			'#074522',
			'#bbe6f2',
			'#e82c50',
			'#afec29',
			'#788162',
			'#06004b',
			'#0b01fd',
			'#a78ce6',
			'#450028',
			'#e97103',
		]
	}
}

export class basicPieGraph {
	series: ApexAxisChartSeries
	chart: ApexChart
	labels: any
	responsive: ApexResponsive[]
	fill: ApexFill
	title: ApexTitleSubtitle
	colors: string[]
	legend: ApexLegend

	constructor(obj?: pieChartDatas) {
		this.series = []
		this.chart = {
			width: '100%',
			height: 450,
			type: 'pie',
			toolbar: {
				show: true,
				offsetY: 30,
				export: { csv: { filename: '', headerCategory: 'category;value', columnDelimiter: ';' }, svg: { filename: '' }, png: { filename: '' } },
			},
		}
		this.labels = []
		this.responsive = [{ breakpoint: 1920, options: { legend: { position: 'bottom' } } }]
		this.fill = { type: 'gradient' }
		this.title = { text: '', style: { fontWeight: 400 } }
		this.colors = []
		this.legend = {
			formatter: function (val, opts) {
				return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
			},
			position: 'left',
			// offsetY: 30
		}

		if (obj) {
			this.series = obj.data

			this.chart.toolbar.export.csv.filename = basicPieGraph.formatTitle(obj.title)
			this.chart.toolbar.export.svg.filename = basicPieGraph.formatTitle(obj.title)
			this.chart.toolbar.export.png.filename = basicPieGraph.formatTitle(obj.title)

			this.labels = obj.labels
			this.title.text = basicPieGraph.formatTitle(obj.title)
			this.colors = obj.colors
		}
	}
	//originariamente il titolo lo mostravo nella pagina e non nel grafico, x quello ci sono i tag <b> etc, forse si potrebbero levare
	private static formatTitle(title) {
		let text = title.replaceAll('<b>', '')
		text = text.replaceAll('</b>', '')
		return text
	}
}

// 2) funnel chart

export class funnelChart {
	series: ApexAxisChartSeries
	chart: ApexChart
	plotOptions: ApexPlotOptions
	dataLabels: ApexDataLabels
	xaxis: ApexXAxis
	colors: string[] //
	title: ApexTitleSubtitle
	fill: ApexFill

	constructor(obj?: pieChartDatas) {
		// riutilizzo pieChartDatas
		this.series = []
		this.chart = {
			width: '100%',
			height: 450,
			type: 'bar',
			toolbar: {
				show: true,
				offsetY: 30,
				export: { csv: { filename: '', headerCategory: 'category;value', columnDelimiter: ';' }, svg: { filename: '' }, png: { filename: '' } },
			},
		}
		this.plotOptions = { bar: { borderRadius: 0, horizontal: true, barHeight: '80%', isFunnel: true } }
		this.dataLabels = {
			enabled: true,
			formatter: function (val, opt) {
				return opt.w.globals.labels[opt.dataPointIndex] + ':  ' + val
			},
			style: { colors: ['#fff'], fontWeight: 400 },
			background: { enabled: true, foreColor: '#000' },
			// dropShadow: {
			// 	enabled: true,
			// },
		}
		this.title = {
			text: '',
			align: 'center',
			style: { fontWeight: 400 },
		}
		this.xaxis = { categories: [] }
		this.fill = { gradient: { type: 'vertical' } } // type: 'gradient'

		if (obj) {
			this.series = obj.data
			this.chart.toolbar.export.csv.filename = funnelChart.formatTitle(obj.title)
			this.chart.toolbar.export.svg.filename = funnelChart.formatTitle(obj.title)
			this.chart.toolbar.export.png.filename = funnelChart.formatTitle(obj.title)
			this.title.text = funnelChart.formatTitle(obj.title)
			this.xaxis.categories = obj.labels
		}
	}
	private static formatTitle(title) {
		let text = title.replaceAll('<b>', '')
		text = text.replaceAll('</b>', '')
		return text
	}
}

// 3) column type with stacked and not
export interface ColumnSeries {
	name: string
	data: number[]
	type?: string
}

export class ColumnDatas {
	data: ColumnSeries[]
	xaxis: string[]
	xaxisLength: number
	rotate: boolean
	title: string
	colors: string[]
	stacked: boolean

	constructor() {
		this.data = []
		this.xaxis = []
		this.xaxisLength = 0
		this.rotate = false
		this.title = ''
		this.colors = [
			'#81b9f2',
			'#49ae04',
			'#d62115',
			'#e9c00b',
			'#129cb5',
			'#d97f6a',
			'#403da6',
			'#8a3358',
			'#627993',
			'#d8954c',
			'#074522',
			'#bbe6f2',
			'#e82c50',
			'#afec29',
			'#788162',
			'#06004b',
			'#0b01fd',
			'#a78ce6',
			'#450028',
			'#e97103',
		]
		this.stacked = true
	}
}

export class ColumnGraph {
	series: ApexAxisChartSeries
	chart: ApexChart
	dataLabels: ApexDataLabels
	plotOptions: ApexPlotOptions
	responsive: ApexResponsive[]
	xaxis: ApexXAxis
	yaxis: ApexYAxis | ApexYAxis[]
	colors: string[]
	legend: ApexLegend
	fill: ApexFill
	title: ApexTitleSubtitle
	stroke: ApexStroke

	constructor(obj?: ColumnDatas) {
		this.series = []
		this.chart = {
			type: 'line',
			width: '100%',
			height: 500,
			stacked: false,
			toolbar: {
				show: true,
				export: { csv: { filename: '', headerCategory: 'category;value', columnDelimiter: ';' }, svg: { filename: '' }, png: { filename: '' } },
			},
			zoom: { enabled: true, type: 'x' },
		}
		this.dataLabels = {
			enabled: true,
			// formatter: function (_val, opt) {
			// 	let series = opt.w.config.series
			// 	let idx = opt.dataPointIndex
			// 	const total = series.reduce((total, self) => total + self.data[idx], 0)
			// 	return total
			// }, // serve per mostrare il totale, il problema é che per mostrarlo sopra alla colonna bisogna sapere di ogni colonna quanti elementi ha, ma variano sempre

			formatter: function (val, opt) {
				if (val.toString() == '0') {
					return ''
				} else {
					return val.toString()
				}
			},
			style: { colors: ['#3d3d3d'] },
		}
		this.plotOptions = { bar: { horizontal: false, columnWidth: '70%' } }
		this.responsive = [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }]
		this.xaxis = { categories: [], tickAmount: 'dataPoints', tickPlacement: 'on', labels: { rotate: -45, rotateAlways: false } }
		this.yaxis = {
			labels: {
				formatter: (val) => val.toFixed(0).toString(),
			},
		}
		this.colors = []
		this.legend = { position: 'right', offsetY: 40 }
		this.fill = { type: 'solid' }
		this.stroke = {
			width: 0,
			curve: 'smooth',
		}
		this.title = { text: '', style: { fontWeight: 400 } }

		if (obj) {
			this.series = obj.data
			this.chart.stacked = obj.stacked
			this.chart.toolbar.export.csv.filename = ColumnGraph.formatTitle(obj.title)
			this.chart.toolbar.export.svg.filename = ColumnGraph.formatTitle(obj.title)
			this.chart.toolbar.export.png.filename = ColumnGraph.formatTitle(obj.title)
			this.colors = obj.colors
			this.xaxis.categories = obj.xaxis
			this.xaxis.tickAmount = obj.xaxisLength

			if (!obj.stacked) {
				this.xaxis.tickPlacement = 'between'
			}
			// this.yaxis.title.text = obj.title
			this.title.text = ColumnGraph.formatTitle(obj.title)

			if (obj.rotate) {
				this.xaxis.labels.rotate = -45
				this.xaxis.labels.rotateAlways = true
			}
		}
	}

	private static formatTitle(title) {
		let text = title.replaceAll('<b>', '')
		text = text.replaceAll('</b>', '')
		return text
	}
}

// 4) column with markers

export interface ColumnSeriesWithMarkers {
	name: string
	data: dataWithMarkers[]
}

export class dataWithMarkers {
	x: string
	y: number
	goals: [
		{
			name: string
			value: number
			stroke: number
			strokeColor: string
		}
	]
	constructor(obj: graders) {
		this.x = obj.username
		this.y = obj.av_grading_time
		this.goals = [{ name: '', value: 0, stroke: 5, strokeColor: '#e5491f' }]
	}
}

export class ColumnDatasMarked {
	datas: ColumnSeriesWithMarkers
	rotate: boolean
	title: string

	constructor() {
		this.datas = { name: '', data: [] }
		// this.xaxis = []
		// this.xaxisLength = 0
		this.rotate = false
		this.title = ''
	}
}

export class ColumnGraphWithMarkers {
	series: ApexAxisChartSeries
	chart: ApexChart
	dataLabels: ApexDataLabels
	plotOptions: ApexPlotOptions
	responsive: ApexResponsive[]
	colors: string[]
	legend: ApexLegend
	fill: ApexFill
	title: ApexTitleSubtitle

	constructor(obj?: ColumnDatasMarked) {
		this.series = []
		this.chart = {
			type: 'bar',
			width: '100%',
			height: 500,
			toolbar: {
				show: true,
				export: { csv: { filename: '', headerCategory: 'category;value', columnDelimiter: ';' }, svg: { filename: '' }, png: { filename: '' } },
			},
			zoom: { enabled: true, type: 'x' },
		}
		this.dataLabels = {
			enabled: true,

			formatter: function (val, opt) {
				if (val.toString() == '0') {
					return ''
				} else {
					return val.toString()
				}
			},
			style: { colors: ['#3d3d3d'] },
		}
		this.plotOptions = { bar: { horizontal: false, columnWidth: '70%' } }
		this.responsive = [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }]
		this.colors = ['#81b9f2', '#e5491f']
		this.legend = { show: true, showForSingleSeries: true, customLegendItems: ['', ''] }
		this.fill = { type: 'solid' }
		this.title = { text: '', style: { fontWeight: 400 } }

		if (obj) {
			this.series = [{ name: obj.datas.name, data: obj.datas.data }]
			this.chart.toolbar.export.csv.filename = ColumnGraphWithMarkers.formatTitle(obj.title)
			this.chart.toolbar.export.svg.filename = ColumnGraphWithMarkers.formatTitle(obj.title)
			this.chart.toolbar.export.png.filename = ColumnGraphWithMarkers.formatTitle(obj.title)
			if (obj.datas.data[0]) {
				this.legend.customLegendItems = [obj.datas.name, obj.datas.data[0].goals[0].name]
			}

			this.title.text = ColumnGraphWithMarkers.formatTitle(obj.title)
		}
	}

	private static formatTitle(title) {
		let text = title.replaceAll('<b>', '')
		text = text.replaceAll('</b>', '')
		return text
	}
}

// functions to create x axys arrays
export class createArrays {
	// Array dati asse delle x, non é l'asse delle x
	public static createSeries(xvalues, createTotal?: boolean): ColumnSeries[] {
		let series: ColumnSeries[] = [] // array di serie di dati del grafico, ogni country ha una serie di dati oppure ogni piano, in base all'arrya che gli viene passato

		// console.log(activeCountries)
		for (let i = 0; i < xvalues.length; i++) {
			let serie: ColumnSeries = { data: [], name: xvalues[i], type: 'bar' } // valori per ogni country o altro

			series.push(serie)
		}

		if (createTotal) {
			let serie: ColumnSeries = { data: [], name: 'Total', type: 'line' }
			series.push(serie)
		}
		return series
	}

	// Asse delle x se cadence == days
	public static createDaysArray(fromDate: moment.Moment, diffDays: number): string[] {
		Util.debug('(statistics) - createDaysArray')
		//ogni valore dell'asse delle x é una data, parto dal from date e ad ogni ciclo aggiungo 1 giorno fino al to date con diffDays
		let days = []

		for (let i = 0; i < diffDays; i++) {
			if (i == 0) {
				days.push(moment(fromDate).format('DD-MM-YYYY'))
			} else {
				days.push(moment(fromDate).add(i, 'day').format('DD-MM-YYYY'))
			}
		}
		return days
	}

	// Asse delle x se cadence == weeks
	public static createWeekArray(fromDate: moment.Moment, toDate: moment.Moment): string[] {
		Util.debug('(statistics) - createWeekArray')
		//ogni valore dell'asse delle x é una settimana
		// creo un array di anni e settimane years, perché devo gestire il caso di cavallo fra 2 anni
		// ho usato iso week in quanto dal server arrivano iso, vuol dire che potrebbe essere che ad esempio i primi giorni di gennaio es. venerdí é considerato
		// nell'ultima sett dell'anno prima
		let years = []

		let startWeek = fromDate.isoWeek()
		let endWeek = toDate.isoWeek()
		let startYear = fromDate.isoWeekYear()
		let endYear = toDate.isoWeekYear()

		// creo un array di anni in base alla data selezionata con il n. settimane dell'anno,
		// il n di settimane será uguale a endWeek se stesso anno o ultimo anno in caso di piú anni selezionati
		if (startYear != endYear) {
			// se startYear != endYear vuol dire piú anni selezionati
			for (let n = startYear; n <= endYear; n++) {
				if (n == endYear) {
					years.push({ year: n, weeks: endWeek })
				} else {
					let date = moment.utc(new Date(n + ''))

					years.push({ year: n, weeks: date.isoWeeksInYear() })
				}
			}
		} else {
			years.push({ year: startYear, weeks: endWeek })
		}

		// console.log(years)

		let weeks = [] // lista delle x con tutti i valori possibili, dopo avranno o meno i valori

		for (let a = 0; a < years.length; a++) {
			const y = years[a]

			if (a == 0) {
				// la prima iteraizone avrá come start week quella della data from selezionata
				for (let i = startWeek; i <= y.weeks; i++) {
					weeks.push(i + '_' + y.year)
				}
			} else {
				// le altre come start sempre 1 ma come fine le week calcolate in precedenza, che in questo é quella della endDate
				for (let i = 1; i <= y.weeks; i++) {
					weeks.push(i + '_' + y.year)
				}
			}
		}
		// console.log(weeks)
		return weeks
	}

	// // Asse delle x se cadence == month
	public static createMonthlyArray(fromDate: moment.Moment, toDate: moment.Moment): string[] {
		Util.debug('(statistics) - createMonthlyArray')
		let months: string[] = []
		let years = []
		let startMonth = fromDate.month()
		let endMonth = toDate.month()
		let startYear = fromDate.year()
		let endYear = toDate.year()

		startMonth++ //cominciano da 0, gennaio==0
		endMonth++

		if (startYear != endYear) {
			// se startYear != endYear vuol dire piú anni selezionati
			for (let n = startYear; n <= endYear; n++) {
				if (n == endYear) {
					years.push({ year: n, months: endMonth })
				} else {
					years.push({ year: n, months: 12 })
				}
			}
		} else {
			years.push({ year: startYear, months: endMonth })
		}
		// console.log(years)

		for (let a = 0; a < years.length; a++) {
			const y = years[a]

			if (a == 0) {
				// la prima iteraizone avrá come start week quella della data from selezionata
				for (let i = startMonth; i <= y.months; i++) {
					// months.push(moment().month(i-1).format('MMMM')+'_'+y.year) //piú bello con i nomi dei mesi, ma piú complicata lasegnazione dopo
					months.push(i + '_' + y.year)
				}
			} else {
				// le altre come start sempre 1 ma come fine le week calcolate in precedenza, che in questo é quella della endDate
				for (let i = 1; i <= y.months; i++) {
					months.push(i + '_' + y.year)
				}
			}
		}
		// console.log(months)
		return months
	}

	// Asse delle x se cadence == year
	public static createYearArray(fromDate: moment.Moment, toDate: moment.Moment): string[] {
		Util.debug('(statistics) - createYearArray')

		let years: string[] = []
		let startYear = fromDate.year()
		let endYear = toDate.year()

		for (let i = startYear; i <= endYear; i++) {
			years.push(i + '')
		}

		return years
	}
}

export class serieValues {
	day: string
	week: string
	month: string
	year: string

	constructor(day: string, week: string, month: string) {
		this.day = day
		this.week = week
		this.month = month
		this.year = day
	}
}

<p class="fw-bold">{{ 'ACTIVITIES.TITLE' | translate }}</p>
<p class="fw-bold">{{ 'ACTIVITIES.SUBTITLE' | translate }}</p>

<div class="container-fluid">
	<form class="row" [formGroup]="filterForm">
		<p class="mt-3">{{ 'ACTIVITIES.FILTER_EXPORT' | translate }}</p>
		<div class="col-2">
			<mat-form-field appearance="outline">
				<mat-label style="color: #4f0aeb">{{'ACTIVITIES.YEAR' | translate}}</mat-label>
				<mat-select name="year" (selectionChange)="filterValue()" formControlName="year">
					<mat-option *ngFor="let year of yearsOptions" [value]="year"> {{year}} </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-2">
			<mat-form-field appearance="outline">
				<mat-label style="color: #4f0aeb">{{'ACTIVITIES.MONTHS' | translate}}</mat-label>
				<mat-select name="month" (selectionChange)="filterValue()" formControlName="month">
					<mat-option *ngFor="let mont of months" [value]="mont"> {{mont}} </mat-option>
				</mat-select>
			</mat-form-field>
		</div>
		<div class="col-8 d-flex align-items-center filter-counter">
			<!-- <p>Export to CSV the filtered data <fa-icon [icon]="faFileCsv" faFileCsv class="ms-3" (click)="calcActDateExport()"></fa-icon></p> -->
			<p class="filter-counter__text" [ngClass]="filterSet ? 'show' : '' ">{{"ACTIVITIES.FULL" | translate}}: <span class="ms-1 me-4">{{full}}</span> {{"ACTIVITIES.FUNDUS" | translate}}: <span class="ms-1">{{fundus}}</span></p>
		</div>
	</form>
	<!-- <div class="form-group col-2">
			<fa-icon [icon]="faCalculator" aria-hidden="true" class="text-success ps-4 pe-4 fs-5" ngbTooltip="{{ 'BUTTONS.CALC' | translate }}"></fa-icon>
			<fa-icon [icon]="faXmark" aria-hidden="true" class="text-danger fs-5" ngbTooltip="{{ 'BUTTONS.CLEAR' | translate }}"></fa-icon>
		</div> -->
	<div class="row">
		<div class="col">
			<p>
				{{"ACTIVITIES.EXPORT_DATA" | translate}}
				<span [hidden]="loadCSV"><fa-icon [icon]="faFileCsv" faFileCsv class="mx-3" (click)="calcActDateExport()"></fa-icon></span>
				<span [hidden]="!loadCSV"><fa-icon class="spinner-csv mx-3" [icon]="faSpinner" faSpinner></fa-icon></span>
			</p>
		</div>

		<!-- <span class="badge text-dark p-2 ps-3 pe-3 me-3 fs-6"> full <span class="badge text-bg-secondary fs-6">{{full}}</span> </span>
			<span class="badge text-dark p-2 ps-3 pe-3 fs-6"> fundus <span class="badge text-bg-secondary fs-6">{{fundus}}</span> </span> -->
		<!-- <button class="btn" (click)="calcActDateExport()" [disabled]="activitiesList.length <= 0">{{"ACTIVITIES.EXPORT" | translate}}</button> -->
	</div>
	<div class="row">
		<div class="col-12 position-relative" style="min-height: 35vh">
			<loader [activateSpinner]="isLoading "></loader>

			<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
				<div [hidden]="false" class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div> -->

			<!-- unica table per tutti i profili -->
			<table mat-table [dataSource]="reportListTable" matSort style="width: 100%" class="report-list" [ngClass]="!isLoading ? 'show' : '' ">
				<!-- User NAme Column -->
				<ng-container matColumnDef="id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"ACTIVITIES.REPORT_ID" | translate}}</th>
					<td mat-cell *matCellDef="let element">{{ element.id }}</td>
				</ng-container>

				<ng-container matColumnDef="creation_date">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"ACTIVITIES.REPORT_DATE" | translate}}</th>
					<td mat-cell *matCellDef="let element">{{session.formatDateTime( element.creation_date) }}</td>
				</ng-container>
				<!-- <ng-container matColumnDef="optician_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"RELATIONS.OPER_ID" | translate}}</th>
					<td mat-cell *matCellDef="let element">{{ element.optician_id }}</td>
				</ng-container> -->
				<ng-container matColumnDef="optician_name">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"ACTIVITIES.OPER_NAME" | translate}}</th>
					<td mat-cell *matCellDef="let element">{{ element.optician_name }}</td>
				</ng-container>
				<ng-container matColumnDef="patient_id">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"ACTIVITIES.PATIENT_ID" | translate}}</th>
					<td mat-cell *matCellDef="let element">{{ element.patient_id }}</td>
				</ng-container>
				<ng-container matColumnDef="report_type">
					<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"ACTIVITIES.TYPE" | translate}}</th>
					<td mat-cell *matCellDef="let element">{{ element.report_type }}</td>
				</ng-container>

				<ng-container matColumnDef="filter">
					<th mat-header-cell *matHeaderCellDef class="text-end">
						<mat-form-field appearance="outline">
							<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
						</mat-form-field>
					</th>
					<td mat-cell *matCellDef="let element"></td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
			</table>

			<mat-paginator [ngClass]="!isLoading ? 'show' : '' " [pageSizeOptions]="[ 10, 15, 20]" showFirstLastButtons> </mat-paginator>

			<div class="noresults-banner" [ngClass]="{show : reportListTable.filteredData.length == 0 }">
				<div class="container">
					<div class="row text-center noresults-banner__cont">
						<div class="col-5"></div>
						<div class="col-2 alert alert-secondary" role="alert" *ngIf="!filterSet">{{ 'ACTIVITIES.NODATA' | translate }}</div>
						<div class="col-2 alert alert-secondary" role="alert" *ngIf="filterSet">{{ 'ACTIVITIES.NODATA_FILTERED' | translate }}</div>
						<div class="col-5"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

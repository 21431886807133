import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
	name: 'age',
})
export class AgePipe implements PipeTransform {
	//transform(birthDate: string, ...args: unknown[]): number {
	transform(birthDate: string): number {
		if (!birthDate) {
			return -1
		}

		try {
			// 24.02.2017  per IE non funziona ?!
			var bDate //  = new Date();
			if (birthDate.length > 4) {
				// intera
				bDate = new Date(birthDate) // ko per IE 11 e firefox se mese e gg da 1 cifra
				//bDate = Date.parse(birthDate);
			} else {
				var myYear = parseInt(birthDate)
				bDate = new Date(myYear, 0, 1) // 08.01.2018 gennaio è 0
			}

			return this.calculateAge(bDate)
		} catch (ex) {
			console.log('(age) ex: ' + ex)
			return -1
		}
	}

	// 08.02.2022 copiata da nexy
	private calculateAge(birthday: Date): number {
		var ageDifMs = Date.now() - birthday.getTime()
		var ageDate = new Date(ageDifMs) // miliseconds from epoch

		// return Math.abs(ageDate.getUTCFullYear() - 1970)  // 10-02-2023

		return ageDate.getUTCFullYear() - 1970
	}
}

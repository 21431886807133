import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap'

import { Validators, FormGroup, FormControl } from '@angular/forms'

//import { timer } from 'rxjs'
//import { take } from 'rxjs/operators'

//import { DataModelService } from '../../service/data-model.service';
import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { PukPdf } from '../../models/pdf.model'

import { faCircleXmark, faCircleCheck, faDownload } from '@fortawesome/free-solid-svg-icons'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'

// ex firstLogin controller - dopo questa, passa a verifyPuk

@Component({
	selector: 'activation',
	templateUrl: './activation.component.html',
	styleUrls: ['./activation.component.scss'],
})
export class ActivationComponent implements OnInit {
	firstLoginForm: FormGroup

	myNewPwd: string
	passwordConfirm: string
	success = false
	fail = false
	puk = ''
	btnProceedDisabled = true
	timer: number
	timerStr: string
	errMsg = '' // 02.01.2018 --ls
	isLoading: boolean
	pleaseWait = ''
	charNum: boolean
	uppercase: boolean
	specialChar: boolean

	validPwd: boolean // 03.03.2021

	// fa-icons per usarle su html
	faDownload = faDownload
	faCircleXmark = faCircleXmark
	faCircleCheck = faCircleCheck

	constructor(
		public session: SessionService,
		private translator: TranslateService,
		public modalService: NgbModal, //private dataService: DataModelService
		private toastService: AppToastService
	) {
		//this.interval = $interval;  // ??
		this.timer = 30
		this.timerStr = ' - ' + this.timer
		this.isLoading = false
		//this.scope = $scope; // 07.04.2020

		// 22.04.2020
		this.pleaseWait = '' // "Provide new password, then please wait while generating security keys....";

		this.validPwd = false
		this.fail = false // 02.01.2018 toglie "request failed" davanti al errMsg

		this.myNewPwd = ''
		this.passwordConfirm = ''

		this.charNum = false
		this.uppercase = false
		this.specialChar = false
	}

	ngOnInit(): void {
		this.firstLoginForm = new FormGroup({
			password1: new FormControl(this.myNewPwd, [Validators.required, this.validatorPassword.bind(this), this.invalidBlankSpace.bind(this)]),
			password2: new FormControl(this.passwordConfirm, [Validators.required, this.validatorConfirmPassword.bind(this)]),
		})

		this.firstLoginForm.get('password2').disable()

		// 14.12.2017 se ha fatto reset del puk, salta il cambio pwd e passa al suo salvataggio --ls
		var nuovoPuk = this.session.getNuovoPuk()
		if (nuovoPuk.length > 0) {
			this.success = true // cosi' non chiede il cambio pwd
			this.fail = false // 02.01.2018 toglie "request failed" davanti al errMsg
			this.errMsg = ''
			this.puk = nuovoPuk

			//this.interval(() => { this.timerEnableBtn() }, 1000, 30);

			/*  03.11.2022
			let myCount = 1
			timer(30, 1000)
				.pipe(take(myCount))
				.subscribe((x) => {
					// do here whatever you want to do here
					this.timerEnableBtn()
				})
      */
		}
	}

	validatorPassword(control: FormControl): { [s: string]: boolean } {
		if (!Util.validatePwd(control.value)) {
			return { ['password not valid']: true }
		}

		return null
	}

	validatorConfirmPassword(control: FormControl): { [s: string]: boolean } {
		if (control.value != this.myNewPwd) {
			return { ['password not macth']: true }
		}

		return null
	}

	invalidBlankSpace(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			if (control.value.indexOf(' ') >= 0) {
				return { ['Invalid blank space']: true } // posso cambiare la scritta di errore dinamicamente cercando questo errore
			}
		}
		return null
	}

	// 08.04.2020 per test, qui anziche' su session
	isLoadingKeys() {
		return this.isLoading
	}

	// 14.03.2022 event coincide con il valore digitato nel form
	onChangeNewPwd(event) {
		// console.log(this.firstLoginForm)
		//onChangeNewPwd(val){
		//console.log("(onChangeNewPwd) event:");

		this.myNewPwd = this.firstLoginForm.get('password1').value

		this.validPwd = Util.validatePwd(this.myNewPwd)
		//this.fail = !(this.validPwd);  # 03.05.2022 qui troppe volte, anche mentre scrive la pwd

		// almeno 8 caratteri
		let charRegex = new RegExp('^(?=.*[a-z])(.{8,})$')

		if (charRegex.test(this.myNewPwd)) {
			this.charNum = true
		} else {
			this.charNum = false
		}

		// almeno una lettera maiuscola
		let upperRegex = new RegExp('^(?=.*[A-Z])')

		if (upperRegex.test(this.myNewPwd)) {
			this.uppercase = true
		} else {
			this.uppercase = false
		}

		// almeno una lettera speciale o numero
		let specialRegex = new RegExp('^(?=.*[.!@#$%^&*_0-9])')

		if (specialRegex.test(this.myNewPwd)) {
			this.specialChar = true
		} else {
			this.specialChar = false
		}

		if (this.firstLoginForm.get('password1').valid) {
			this.firstLoginForm.get('password2').enable()
		} else if (this.firstLoginForm.get('password2').enabled) {
			this.firstLoginForm.get('password2').disable()
		}
	}

	onChangeRepeatPwd(event) {
		this.errMsg = ''
		this.fail = false
		// no
		//this.validPwd = Util.validatePwd(this.passwordConfirm);
	}

	/*
  // 03.03.2021 
  validatePwd(){
    this.validPwd = Util.validatePwd(this.myNewPwd);
  }
  */

	/*  03.11.2022 
	timerEnableBtn() {
		this.timer--
		this.timerStr = ' - ' + this.timer
		if (this.timer <= 0) {
			this.btnProceedDisabled = false
			this.timerStr = ''
		}
		//console.log(this.timer);
	}
  */

	submit(form?) {
		//if (form.$valid)

		this.passwordConfirm = this.firstLoginForm.get('password2').value

		this.errMsg = ''

		// 03.05.2022 dovrebbe averlo bloccato prima di qui, cmq...
		this.validPwd = Util.validatePwd(this.myNewPwd)
		this.fail = !this.validPwd
		if (this.fail) {
			console.log('activation - invalid password')
			return
		}

		if (this.myNewPwd != this.passwordConfirm) {
			this.fail = true
			//this.puk = "pwd does not match";  // 02.01.2018 ko, non viene esposto --ls
			//this.errMsg = "passwords do not match";
			this.errMsg = this.translator.instant('FIRST_LOGIN.PWD_ERR_NOT_MATCH')

			//alert(this.errMsg);  // 02.01.2018
			console.log(this.errMsg)

			// 02.01.2018 aggiunto controllo che sia diversa da quella iniziale,
			// cfr analoghe funzioni su profile.ts, TODO usare funzioni comuni  --ls
		} else if (this.session.isCorrectPwd(this.myNewPwd)) {
			//isValid -> coincidono
			this.fail = true
			console.log('(activation) new pwd not different from old one')

			//this.errMsg = "new password not different from old one";
			this.errMsg = this.translator.instant('FIRST_LOGIN.PWD_ERR_NOT_DIFF')

			let header = this.translator.instant('FIRST_LOGIN.DESCRIPTION_FAIL')
			let options = new ToastOptions('error')

			this.toastService.show(header, this.errMsg, false, options, 'center')

			this.myNewPwd = ''
			this.passwordConfirm = ''
			//alert(this.errMsg);
		} else {
			//this.pleaseWait = 'Generating security keys...'
			this.pleaseWait = this.translator.instant('FIRST_LOGIN.GENERATING_KEYS')

			this.isLoading = true

			// 15.03.2022
			/*
      this.interval(() => {    
        this.timerEnableBtn() 
      }, 1000, 30);
      */

			/*
      let myCount = this.timer
      timer(30, 1000)
        .pipe(take(myCount))
        .subscribe((x) => {
          this.timerEnableBtn()
        })
      */

			var doFirstLogin = () => {
				Util.debug('(activation) inizio 2')
				this.session
					.initializeUserAccount(this.myNewPwd)
					.then((puk) => {
						Util.debug('(activation) initialize success')
						this.success = true
						this.fail = false
						this.puk = puk
						this.errMsg = ''
						this.isLoading = false
						this.pleaseWait = ''
					})
					.catch((error) => {
						this.fail = true
						this.puk = ''
						console.log('(activation) initialize error')
						console.log(error)
						this.isLoading = false

						let header = this.translator.instant('TOAST.HEADER.ERROR')
						let body = this.translator.instant('VERIFY_PUK.DESCRIPTION_FAIL')
						let options = new ToastOptions('error')

						this.toastService.show(header, body, false, options, 'center')
					})
			}

			// 03.11.2022 verificare se serve ancora usare il timeout ? [ls]
			setTimeout(doFirstLogin, 1000) // OK con 3 secondi, anche con 1
		}
	}

	goLanding() {
		//this.location.url(this.session.getLandingPage());
		return this.session.getLandingPage() // 14.03.2022
	}

	printPuk(puk) {
		var pukPdf = new PukPdf(this.session, this.translator)
		pukPdf.setPuk(puk)
		pukPdf.generatePdf()

		//18.12.2017 abilita btn proceed
		this.timer = 0
		this.btnProceedDisabled = false
	}
}

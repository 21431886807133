<div class="panel-modal relation-modal position-relative">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
	</div>
	<div class="container-fluid">
		<div class="row">
			<div class="col-12 d-flex flex-column justify-content-center" style="min-height: 30vh">
				<h4 class="uppercase mb-3 fw-bold text-center relation-counter-title" *ngIf="!waitDoctor" [ngClass]="noRelations ? 'no-relation' : '' ">
					{{specialistsRelated.length}} {{specialistsRelated.length > 1 ? ('RELATIONS.OPERATOR_SIDE.TITLE2' | translate) : ('RELATIONS.OPERATOR_SIDE.TITLE' | translate)}}
				</h4>
				<div class="row">
					<div class="col-8">
						<div class="row">
							<!-- OPTICIAN LEFT BOX -->
							<div class="col-6 doctor-section position-relative">
								<!-- LOADER -->

								<loader [activateSpinner]="waitDoctor"></loader>

								<!-- <div class="loader-container" [ngClass]="waitDoctor ? 'show' : '' ">
									<div [hidden]="false" class="lds-ring">
										<div></div>
										<div></div>
										<div></div>
										<div></div>
									</div>
								</div> -->
								<div class="doctor-section__container py-4" [ngClass]="waitDoctor ? '' : 'show' ">
									<div class="row">
										<div class="col-4">
											<div class="logo text-end" *ngIf="!waitDoctor">
												<div class="logo__default">
													<fa-icon [icon]="faUser" [hidden]="docLogo!='' "></fa-icon>
													<img src="{{docLogo}}" [hidden]="docLogo=='' " />
												</div>
											</div>
										</div>
										<div class="col-8 d-flex flex-column justify-content-center">
											<p class="fw-bold username">{{currentDoctor.username}}</p>
											<p><span>{{'RELATIONS.OPERATOR_SIDE.NAME' | translate}} </span><span class="fw-bold">{{currentDoctor.name}}</span></p>
										</div>
									</div>
									<div class="row mt-2">
										<div class="col-4 text-end">
											<p><span>{{'RELATIONS.OPERATOR_SIDE.ORGANIZATION' | translate}} </span></p>
											<p><span>{{'RELATIONS.OPERATOR_SIDE.ADDRESS' | translate}} </span></p>
										</div>
										<div class="col-8 fw-bold">
											<p><span>{{currentDoctor.mainAddress.organization}}</span></p>
											<p><span>{{doctorAddress}}</span></p>
										</div>
									</div>
								</div>
							</div>
							<!-- CENTRAL BOX -->
							<div class="col-6 central-box text-center d-flex flex-column justify-content-center" [ngClass]="waitDistrib ? '' : 'show' ">
								<div class="status" *ngIf="!noRelations">
									<p><span>{{'RELATIONS.OPERATOR_SIDE.STATUS' | translate}} </span><span class="fw-bold text-uppercase" [ngClass]="relationstatus == 'activated' ? 'valid' : 'waiting'">{{relationstatus}}</span></p>
								</div>
								<div class="rel-type" *ngIf="!noRelations">
									<p><span>{{'RELATIONS.OPERATOR_SIDE.REL_TYPE' | translate}} </span><span class="fw-bold">{{relationType}}</span></p>
								</div>

								<div class="distance position-relative d-flex justify-content-between">
									<fa-icon [icon]="faLocationDot" class="location1 delay-1"></fa-icon>
									<span class="result delay-4">
										<fa-icon [icon]="faCircleCheck" faCircleCheck *ngIf="!noRelations" [ngClass]="relationstatus == 'activated' ? 'valid' : 'waiting'"></fa-icon>
										<fa-icon [icon]="faCircleXmark" faCircleXmark *ngIf="noRelations"></fa-icon>
									</span>
									<fa-icon [icon]="faLocationDot" class="location2 delay-2"></fa-icon>
									<div class="line delay-3"></div>
								</div>

								<div class="distance-value delay-5" *ngIf="!noRelations">
									<p>{{'RELATIONS.OPERATOR_SIDE.DISTANCE' | translate}} <span class="fw-bold">{{distance}}</span></p>
								</div>

								<div class="relation-date" *ngIf="!noRelations">
									<p>{{'RELATIONS.OPERATOR_SIDE.REL_DATE' | translate}} <span class="fw-bold">{{session.formatDate(relationDate)}}</span></p>
								</div>
							</div>
						</div>
						<div class="container-fluid">
							<div class="row mt-5">
								<div class="col-12 pe-5 position-relative" [hidden]="!showTable" style="min-height: 20vh">
									<!-- <div class="my-3">
										<div class="form-check">
											<input class="form-check-input" type="checkbox" [(ngModel)]="showAll" id="showAddress" (change)="showAllGraders()" [disabled]="askForGraders || noNearest" />
											<label class="form-check-label ms-3" for="showAddress"> {{'RELATIONS.OPERATOR_SIDE.SHOW_ALL_GR' | translate}} </label>
										</div>
									</div> -->
									<loader [activateSpinner]="askForGraders"></loader>
									<!-- <div class="loader-container" [ngClass]="askForGraders ? 'show' : '' ">
										<div [hidden]="false" class="lds-ring">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div> -->

									<!-- TABELLA -->
									<table mat-table [dataSource]="specialistList" class="specialist-list" matSort style="width: 100%" [ngClass]="askForGraders ? '' : 'show'" multiTemplateDataRows>
										<!-- sub-type -->
										<ng-container matColumnDef="subType">
											<th mat-header-cell *matHeaderCellDef></th>
											<td mat-cell *matCellDef="let element">
												<div *ngIf="element.distributor.getSubType() == 'VX' || element.distributor.getSubType() =='ClinicAdmin' else tablePrivate">
													<div class="logo"></div>
												</div>
												<ng-template #tablePrivate>
													<span class="fw-bold">[PR]</span>
												</ng-template>
											</td>
										</ng-container>

										<!-- username -->
										<ng-container matColumnDef="username">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RELATIONS.OPERATOR_SIDE.TABLE.USERNAME' | translate}}</th>
											<td mat-cell *matCellDef="let element" class="pe-1">{{ element.username }}</td>
										</ng-container>
										<!-- name -->
										<ng-container matColumnDef="name">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RELATIONS.OPERATOR_SIDE.TABLE.NAME' | translate}}</th>
											<td mat-cell *matCellDef="let element">{{ element.name }}</td>
										</ng-container>
										<!-- organization / display name -->
										<ng-container matColumnDef="organization">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>
												<div>
													<span>{{'RELATIONS.OPERATOR_SIDE.TABLE.ORGANIZATION' | translate}}/</span>
													<br />
													<span>{{'RELATIONS.OPERATOR_SIDE.TABLE.DISPLAY_NAME' | translate}}</span>
												</div>
											</th>
											<td mat-cell *matCellDef="let element">{{element.type == 'ClinicAdmin' ? element.organization : element.displayName }}</td>
										</ng-container>
										<!-- type -->
										<ng-container matColumnDef="type">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RELATIONS.OPERATOR_SIDE.TABLE.TYPE' | translate}}</th>
											<td mat-cell *matCellDef="let element">{{ element.type }}</td>
										</ng-container>
										<!-- is test -->
										<ng-container matColumnDef="test">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RELATIONS.OPERATOR_SIDE.TABLE.TEST' | translate}}</th>
											<td mat-cell *matCellDef="let element" class="ps-2">{{ element.distributor.is_test }}</td>
										</ng-container>
										<!-- distance -->
										<ng-container matColumnDef="distance">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RELATIONS.OPERATOR_SIDE.TABLE.DISTANCE' | translate}}</th>
											<!-- <td mat-cell *matCellDef="let element">{{ element.distance ? (element.distance.toFixed(1) + 'Km'): '' }}</td> -->
											<td mat-cell *matCellDef="let element">{{ element.distance == 99999 ? '' : element.distance.toFixed(1)+ ' Km' }}</td>
										</ng-container>
										<!-- city -->
										<ng-container matColumnDef="city">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'RELATIONS.OPERATOR_SIDE.TABLE.CITY' | translate}}</th>
											<td mat-cell *matCellDef="let element">{{ element.city }}</td>
										</ng-container>
										<!-- search -->
										<ng-container matColumnDef="search">
											<th mat-header-cell *matHeaderCellDef>
												<mat-form-field appearance="outline">
													<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
												</mat-form-field>
											</th>
											<td mat-cell *matCellDef="let element">
												<span class="text-center">
													<div id="color-badge" [ngClass]="element.status">{{element.previousRelInfo ? ('RELATIONS.OPERATOR_SIDE.' + element.status.toUpperCase() | translate) : ''}}</div>
												</span>
											</td>
										</ng-container>

										<ng-container matColumnDef="expand">
											<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
											<td mat-cell *matCellDef="let element" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
												<div aria-label="expand row" *ngIf="element.previousRelInfo">
													<fa-icon [icon]="faCaretDown" aria-hidden="true" *ngIf="expandedElement !== element"></fa-icon>
													<fa-icon [icon]="faCaretUp" aria-hidden="true" *ngIf="expandedElement === element"></fa-icon>
												</div>
											</td>
										</ng-container>

										<ng-container matColumnDef="expandedDetail">
											<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
												<div class="additional-element-detail">
													<div class="mt-2">
														<p>
															<span class="fw-bold">{{'RELATIONS.OPERATOR_SIDE.REL_START_DATE' | translate}} </span><span> {{ session.formatDateTime(element.relStartDate) }}</span> <span class="ms-4 fw-bold">{{'RELATIONS.OPERATOR_SIDE.REL_END_DATE' | translate}} </span
															><span> {{ session.formatDateTime(element.relEndDate) }}</span>
														</p>
														<p>
															<span class="fw-bold">{{element.updatedBy!=0 ? ('RELATIONS.OPERATOR_SIDE.REL_TYPE_MANUAL' | translate) : ('RELATIONS.OPERATOR_SIDE.REL_TYPE_AUTO' | translate)}} </span>
															<span *ngIf="element.updatedBy!=0"> {{element.updatedBy == currentDoctor.user_id ? currentDoctor.name : element.updatedBy}}</span>
														</p>
													</div>
												</div>
											</td>
										</ng-container>

										<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
										<tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row" [ngClass]="row.status ? 'no-border' : '' " (click)="changeRelation(row)"></tr>
										<tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row" [ngClass]="element.status ? '' : 'no-border' " [class.colapsed-row]="expandedElement != element"></tr>
									</table>
									<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons aria-label="Select page of periodic elements" [ngClass]="askForGraders ? '' : 'show'"> </mat-paginator>
								</div>
							</div>
						</div>
					</div>

					<!-- GREADERS RIGHT BOX -->
					<div class="col-4 grader-section position-relative" [ngClass]="distribRelated.length == 0 ? 'd-flex flex-column justify-content-center' : '' ">
						<!-- LOADER -->

						<loader [activateSpinner]="waitDoctor"></loader>

						<!-- <div class="loader-container" [ngClass]="waitDoctor ? 'show' : '' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->
						<div class="grader-section-list" [ngClass]="waitDoctor ? '' : 'show' ">
							<div *ngIf="distribRelated.length == 0 && supportHideNew && session.isSupport()">
								<p class="text-center">{{'RELATIONS.OPERATOR_SIDE.NOT_ALLOWED' | translate}}</p>
							</div>
							<div class="grader-section__box py-3 mb-2" *ngFor="let distr of distribRelated" [ngClass]="[distr.selected ? 'selected' : '' , !distr.changeStatus && searchNewGrader ? 'disabled' : '', distr.created_by == -1 ? 'add-grader' : '' ]" (click)="selectDistrib(distr)">
								<div class="grader-section__container px-3" *ngIf="distr.created_by != -1 else addGrader">
									<div class="row" [ngClass]="distr.changeStatus ? 'blink' : '' ">
										<div class="col-4">
											<div class="logo text-end">
												<div class="logo__default">
													<fa-icon [icon]="faUser"></fa-icon>
												</div>
											</div>
										</div>
										<div class="col-8" *ngIf="!distr.not_available">
											<p class="fw-bold username">{{distr.distributor.username}}</p>
											<p class="fw-bold">{{distr.distributor.name}}</p>
											<div class="row">
												<div class="col-6">
													<!-- <p><span>Brand: </span><span class="fw-bold">{{distr.distributor.settings.brand}}</span></p> -->
													<p><span>{{'RELATIONS.OPERATOR_SIDE.CODE' | translate}} </span><span class="fw-bold">{{distr.distributor.code}}</span></p>
													<p><span>{{'RELATIONS.OPERATOR_SIDE.TYPE' | translate}} </span><span class="fw-bold">{{distr.distributor.user_type}}</span></p>
												</div>
												<div class="col-6">
													<p><span>{{'RELATIONS.OPERATOR_SIDE.TEST' | translate}}</span><span class="fw-bold">{{distr.distributor.is_test}}</span></p>
													<div class="position-relative grader-section__container__logo">
														<p class="d-inline">{{'RELATIONS.OPERATOR_SIDE.SUB_TYPE' | translate}}</p>
														<!-- <span>{{'RELATIONS.OPERATOR_SIDE.SUB_TYPE' | translate}} </span> -->
														<div *ngIf="distr.distributor.getSubType() == 'VX' || distr.distributor.getSubType() =='ClinicAdmin' else private" class="d-inline">
															<div class="logo"></div>
														</div>

														<ng-template #private>
															<span class="fw-bold">[PR]</span>
														</ng-template>
													</div>
												</div>
											</div>
										</div>
										<div class="col-8 d-flex flex-column justify-content-center" *ngIf="distr.not_available">
											<p>{{'RELATIONS.OPERATOR_SIDE.NOT_AVAILABLE' | translate}}</p>
										</div>
									</div>

									<div class="row mt-2" [ngClass]="distr.changeStatus ? 'blink' : '' ">
										<div class="col-4 text-end">
											<p><span>{{distr.distributor.user_type=='ClinicAdmin' ? ('RELATIONS.OPERATOR_SIDE.ORGANIZATION' | translate) : ('RELATIONS.OPERATOR_SIDE.DISPLAY_NAME' | translate) }} </span></p>
											<p><span>{{'RELATIONS.OPERATOR_SIDE.ADDRESS' | translate}} </span></p>
										</div>
										<div class="col-8 fw-bold">
											<p><span>{{distr.distributor.user_type=='ClinicAdmin' ? distr.distributor.mainAddress.organization : distr.distributor.display_name }}</span></p>
											<p><span>{{distr.addressLabel}}</span></p>
										</div>
									</div>
									<div class="row" *ngIf="!distr.not_available">
										<div class="col-6">
											<a class="me-4 save-edit-button" *ngIf="!searchNewGrader && !session.isSuperSupport()" (click)="getAvailableGraders(distr);"><fa-icon [icon]="faPenToSquare" class="me-2"></fa-icon><span>{{'RELATIONS.OPERATOR_SIDE.BUTTONS.CHANGE' | translate}}</span></a>
											<a class="me-4 delete-button" *ngIf="!session.isSupport() && !searchNewGrader" (click)="deleteRelation(distr)"><fa-icon [icon]="faTrashCan" class="me-2"></fa-icon><span>{{'RELATIONS.OPERATOR_SIDE.BUTTONS.DELETE' | translate}}</span></a>
											<a class="me-4 discard-button" *ngIf="searchNewGrader && !session.isSuperSupport()" (click)="discardRelation(distr)"><fa-icon [icon]="faRotateLeft" class="me-2"></fa-icon><span>{{'RELATIONS.OPERATOR_SIDE.BUTTONS.DISCARD' | translate}}</span></a>
										</div>
										<div class="col-6">
											<!-- <span class="float-end affiliation-date">{{session.formatDate(distr.affiliation_date)}}</span> -->
										</div>
									</div>
								</div>

								<ng-template #addGrader>
									<div class="grader-section__container add-grader__container px-3" [ngClass]="!distr.changeStatus && searchNewGrader ? 'd-none' : ''   ">
										<div class="row">
											<div class="col-12">
												<div class="d-flex flex-column align-items-center">
													<div class="logo__default" [ngClass]="distr.changeStatus ? 'blink' : '' ">
														<fa-icon [icon]="faUserPlus"></fa-icon>
													</div>

													<button type="button" class="btn btn-primary btn-sm" *ngIf="!searchNewGrader && !session.isSuperSupport()" [disabled]="distr.changeStatus" (click)="getAvailableGraders(distr)">{{'RELATIONS.OPERATOR_SIDE.BUTTONS.ADD_GRADER' | translate}}</button>
													<a class="me-4 discard-button" *ngIf="searchNewGrader && !session.isSuperSupport()" (click)="discardRelation(distr)"><fa-icon [icon]="faRotateLeft" class="me-2"></fa-icon><span>{{'RELATIONS.OPERATOR_SIDE.BUTTONS.DISCARD' | translate}}</span></a>
												</div>
											</div>
										</div>
									</div>
								</ng-template>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

//import { Config } from '../../../config';
import { SessionService } from '../../service/session.service'
// import { Util } from '../../models/util.model';
import { Doctor } from 'src/app/models/doctor.model'

import { UserDevice } from '../../models/user.model'
import { FormControl, Validators } from '@angular/forms'

@Component({
	selector: 'app-user-devices',
	templateUrl: './userDevices.modal.html',
	styleUrls: ['./userDevices.modal.scss'],
})
export class UserDevicesModal implements OnInit {
	psswCtrl: FormControl
	disabledInput: boolean
	hide: boolean
	wrongDevice: boolean

	// dispPassword: string // dispositive admin pwd field, for edit, delete, etc
	// wrongPwd: boolean

	sentSuccess: boolean

	@Input() devicesList: UserDevice[] // TODO, arriva dal parent

	public clickedRow: UserDevice

	constructor(public session: SessionService, public activeModal: NgbActiveModal) {
		this.clickedRow = new UserDevice()
		// this.wrongPwd = false
		this.sentSuccess = false

		this.disabledInput = true
		this.hide = true
		this.wrongDevice = false
	}

	displayedColumns: string[] = ['device id', 'model', 'sn', 'main_build', 'caller ip', 'first_use', 'last_use', 'time_log_request']

	ngOnInit(): void {
		this.psswCtrl = new FormControl({ value: '', disabled: this.disabledInput }) //va inizializzato altrimenti va in errore all'apertura, serve a dire all'input se é abilitato o no

		// console.log(this.devicesList)
	}

	// Selezione elementi dalla lista
	clicked(data: UserDevice) {
		this.clickedRow = data // mi ricavo i dati del dispositivo selezionato

		if (this.clickedRow.time_log_request != null) {
			//se é giá stato richiesto un log disabilito il campo password e il pulsante invio
			this.disabledInput = true
		} else {
			this.disabledInput = false
		}

		this.psswCtrl = new FormControl({ value: '', disabled: this.disabledInput })

		if (this.clickedRow.model != 'VX610') {
			// Se non é VX610, non posso richiedere i log
			this.wrongDevice = true
			this.psswCtrl = new FormControl({ value: '', disabled: this.wrongDevice })
		} else {
			this.wrongDevice = false
		}
	}

	// invio richiesta di log
	sendLogRequest() {
		// if (!this.session.checkNSPwd(this.dispPassword)) {
		// 	this.wrongPwd = true
		// } else {
		this.disabledInput = true // dopo la richiesta disabilito pulsante e input

		this.session.requireDeviceLog(this.clickedRow.user_id, this.clickedRow.device_id).then((resp) => {
			//invio la richiesta di log con i parametri e se va a buon fine
			this.sentSuccess = true //mostro il banner
			this.clickedRow.time_log_request = new Date() //aggiorno la data di richiesta log
		})

		setTimeout(() => {
			this.sentSuccess = false
		}, 1000)
		// }
	}
}

<div>
	<div class="topview main-container">
		<div class="row">
			<div class="full-container">
				<div class="panel panel-default">
					<div class="panel-heading">&nbsp;</div>
					<div class="panel-body">
						<div class="row">
							<div class="col-12 text-center">
								<p class="panel-title"><b>Info server country settings</b></p>
							</div>

							<div class="row mt-3">
								<div class="col-12">
									<table mat-table [dataSource]="countryDBSettingsList" matSort style="width: 100%">
										@for( value of displayedColumns; track value){
										<ng-container [matColumnDef]="value">
											@if(value === 'isEditing'){
											<!-- se colonna is editing non mettere su th scritte e mostra solo icona -->
											<th mat-header-cell *matHeaderCellDef class="size-sx"></th>
											<td mat-cell *matCellDef="let element">
												@if(element.isEditing){
												<fa-icon [icon]="faSave" faSave class="me-3" (click)="save(element)"></fa-icon>
												<fa-icon [icon]="faCircleXmark" faCircleXmark class="red" (click)="undoEdit(element)"></fa-icon>
												} @else {
												<fa-icon [icon]="faEdit" faEdit (click)="edit(element)" [ngClass]="this.isEditing ? 'editing' : ''"></fa-icon>
												}
											</td>
											} @else {
											<!-- se colonne normali mostra il valore tradotto -->
											<th mat-header-cell *matHeaderCellDef class="size-sx">{{ 'DB_SETTINGS.'+ value.toUpperCase() | translate}}</th>

											<td mat-cell *matCellDef="let element" class="text-center">
												@if(element.isEditing){
												<!-- se editing mostrami campi editabili -->

												@if(isTypeString(element[value]) && element[value] !== 'Y' && element[value] !== 'N' && value !== 'country') {
												<input type="text" maxlength="5" class="form-control form-control-sm text-right" [name]="value" minlength="1" [(ngModel)]="editDBSettings[value]" (keyup)="onlyNumbers(value)" />

												} @else if(value === 'country') {
												<!-- non tutti i campi devono essere editabili come la country -->
												{{ element[value] }} } @else {
												<select [name]="value" [(ngModel)]="editDBSettings[value]" class="form-control form-control-sm">
													<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
												</select>
												}
												<!--  -->
												}@else {
												<!-- se non editing mostro il valore della tabella -->
												@if(element[value] === 'Y') {
												<fa-icon [icon]="faCircleCheck" faCircleCheck></fa-icon>
												} @else if(element[value] === 'N'){
												<fa-icon [icon]="faCircleXmark" faCircleXmark></fa-icon>
												} @else { {{ element[value] }} } }
											</td>
											}
										</ng-container>
										}

										<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
										<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
									</table>

									<mat-paginator [pageSizeOptions]="[10, 15, 20, 25]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, NgbActiveModal, ModalDismissReasons, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'

import { FormControl, NgForm, Validators, FormGroup } from '@angular/forms'

import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'

import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'

@Component({
	selector: 'recover-puk',
	templateUrl: './recover-puk.modal.html',
	styleUrls: ['./recover-puk.modal.scss'],
})
export class RecoverPukModal implements OnInit {
	@Input() currentAction: string // per recover pwd da puk

	recoverPukForm: FormGroup

	isLoading: boolean

	username: string
	currentModal
	recoverErrMsg: string
	privateEmail: string

	validPwd: boolean

	password: string
	puk: string
	newpwd: string
	newpwdconfirm: string

	success: boolean
	successTitle: string
	successSubTitle: string
	fail: boolean
	showIcon: boolean

	charNum: boolean
	uppercase: boolean
	specialChar: boolean

	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	constructor(public session: SessionService, public translator: TranslateService, public ActiveModal: NgbActiveModal, private toastService: AppToastService) {
		console.log('(RecoverPukModal) - constructor')
		this.fail = false
		this.success = false
		this.successTitle = ''
		this.successSubTitle = ''
		this.showIcon = false

		this.isLoading = false

		this.username = ''
		this.privateEmail = ''
		this.newpwd = ''
		this.puk = ''
		this.newpwdconfirm = ''

		this.charNum = false
		this.uppercase = false
		this.specialChar = false
	}

	ngOnInit(): void {
		console.log('(RecoverPukModal) - ngOnInit - action: ' + this.currentAction)

		if (this.currentAction == 'recoverPwd') {
			this.recoverPukForm = new FormGroup({
				username: new FormControl(this.username, [Validators.required, Validators.minLength(3)]),
				email: new FormControl(this.privateEmail, [Validators.required, this.emailValidator.bind(this)]),
			})
		} else if (this.currentAction == 'recoverFromPuk') {
			this.recoverPukForm = new FormGroup({
				username: new FormControl(this.username, [Validators.required, Validators.minLength(3)]),
				puk: new FormControl(this.puk, Validators.required),
				newpwd: new FormControl(this.newpwd, [Validators.required, this.validatorPassword.bind(this), this.invalidBlankSpace.bind(this)]),
				newpwdconfirm: new FormControl(this.newpwdconfirm, [Validators.required, this.validatorConfirmPassword.bind(this)]),
			})

			this.recoverPukForm.get('newpwdconfirm').disable()
		}
	}

	private emailValidator(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			var valid = control.value.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			)

			if (valid == null) {
				return { ['Invalid email']: true }
			}
		}
		return null
	}

	validatorPassword(control: FormControl): { [s: string]: boolean } {
		if (!Util.validatePwd(control.value)) {
			return { ['password not valid']: true }
		}

		return null
	}

	invalidBlankSpace(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			if (control.value.indexOf(' ') >= 0) {
				return { ['Invalid blank space']: true } // posso cambiare la scritta di errore dinamicamente cercando questo errore
			}
		}
		return null
	}

	validatorConfirmPassword(control: FormControl): { [s: string]: boolean } {
		if (control.value != this.newpwd) {
			return { ['password not macth']: true }
		}

		return null
	}

	onChangeNewPwd(event) {
		this.newpwd = this.recoverPukForm.get('newpwd').value

		this.validPwd = Util.validatePwd(this.newpwd)
		//this.fail = !(this.validPwd);  # 03.05.2022 qui troppe volte, anche mentre scrive la pwd

		// almeno 8 caratteri
		let charRegex = new RegExp('^(?=.*[a-z])(.{8,})$')

		if (charRegex.test(this.newpwd)) {
			this.charNum = true
		} else {
			this.charNum = false
		}

		// almeno una lettera maiuscola
		let upperRegex = new RegExp('^(?=.*[A-Z])')

		if (upperRegex.test(this.newpwd)) {
			this.uppercase = true
		} else {
			this.uppercase = false
		}

		// almeno una lettera maiuscola
		let specialRegex = new RegExp('^(?=.*[.!@#$%^&*_0-9])')

		if (specialRegex.test(this.newpwd)) {
			this.specialChar = true
		} else {
			this.specialChar = false
		}

		if (this.recoverPukForm.get('newpwd').valid) {
			this.recoverPukForm.get('newpwdconfirm').enable()
		} else if (this.recoverPukForm.get('newpwdconfirm').enabled) {
			this.recoverPukForm.get('newpwdconfirm').disable()
		}
	}

	submit() {
		this.isLoading = true

		if (this.currentAction == 'recoverPwd') {
			this.username = this.recoverPukForm.get('username').value
			this.privateEmail = this.recoverPukForm.get('email').value

			return this.submitStep1()
		} else if (this.currentAction == 'recoverFromPuk') {
			this.username = this.recoverPukForm.get('username').value
			this.puk = this.recoverPukForm.get('puk').value
			this.newpwd = this.recoverPukForm.get('newpwd').value
			this.newpwdconfirm = this.recoverPukForm.get('newpwdconfirm').value

			return this.submitStep2()
		} else {
			console.log('(RecoverPukModal) - submit unrecognized')
		}
	}

	submitStep1() {
		// form semplice solo per richiesta invio mail

		if (!this.username || this.username.length == 0) {
			this.fail = true
			console.log('(recoverPwd) missing username')
			var warnMsg = this.translator.instant('RECOVER_PUK.MISSING_USERNAME')
			//alert("missing username"); // 10.03.2021
			alert(warnMsg)
			return false
		}

		// 18.05.2021 validazione email
		if (!Util.validateEmail(this.privateEmail)) {
			console.log('(recoverPwd) ko invalid mail ' + this.privateEmail)
			//var warnMsg = this.translator.instant('RECOVER_PUK.INVALID_EMAIL'); // TODO
			alert('the private email provided seems not valid')
			return false
		}

		// disabilitare il bottone submit
		// this.btnProceedDisabled = true

		//this.session.recoverPwd(this.username)  // 18.05.2021 mail diretta
		this.session
			.recoverPwd(this.username, this.privateEmail)
			.then((replyData) => {
				console.log('(recoverPwd) OK temporay token, see email')
				this.isLoading = false
				this.success = true

				setTimeout(() => {
					this.showIcon = true
				}, 700)
				// TODO portare su languages
				this.successTitle = this.translator.instant('RECOVER_PUK.REQUEST_SENT')
				this.successSubTitle = this.translator.instant('RECOVER_PUK.REQUEST_SENT1', { v1: this.username })
				// alert(this.recoverErrMsg)

				setTimeout(() => {
					this.ActiveModal.dismiss()
				}, 5000)
			})
			.catch((err) => {
				let msgErr = ''

				if (err.status == 429) {
					// Too many requests
					msgErr = this.translator.instant('RECOVER_PUK.MSG_TOO_MANY_ATTEMPTS')
				} else if (err.status == 403) {
					// profilo sbagliato o first login ancora da fare
					// ok, teniamo il msg dal server
					//msgErr = (err.data)? err.data.error : err.toString();  // 28.03.2022 centralizz
					msgErr = this.session.parseErrorMessage(err, 'alert')
				} else {
					// errore generico, nascondiamo info "doctor not found" per non aiutare haker
					msgErr = this.translator.instant('RECOVER_PUK.MSG_INVALID_PARAMS')
				}

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let options = new ToastOptions('error')

				this.toastService.show(header, msgErr, false, options, 'center')

				// alert(msgErr)
				this.ActiveModal.dismiss()
			})
			.finally(() => {
				//this.currentModal.dismiss();
			})
	}

	submitStep2() {
		//console.log("(recoverFromPuk) inizio ");

		if (this.newpwd != this.newpwdconfirm) {
			this.fail = true
			console.log('(recoverFromPuk) pwds do not match')

			// 02.05.2018 TODO usare FIRST_LOGIN.PWD_ERR_NOT_MATCH
			//var errMsg = "passwords do not match";  // 10.03.2021
			var errMsg = this.translator.instant('FIRST_LOGIN.PWD_ERR_NOT_MATCH')
			this.recoverErrMsg = errMsg // 31.05.2022
			alert(errMsg)
			// this.btnProceedDisabled = false // 31.03.2021 deve poter riprovare, dopo aver corretto

			return false
		}

		this.recoverErrMsg = '' //31.05.2022

		// 31.03.2021 controllo regole su nuova pwd
		this.validatePwd(this.newpwd)

		if (!this.validPwd) {
			console.log('(recoverFromPuk) new pwd invalid')
			var errMsg = this.translator.instant('PROFILE.INVALID_PASSWORD')
			alert(errMsg)
			// this.btnProceedDisabled = false // 31.03.2021 deve poter riprovare, dopo aver corretto
			return false
		}

		// disabilita il bottone submit, per evitare doppie submit
		// this.btnProceedDisabled = true
		var enableRetry = false

		// 10.05.2021 tolgo spazi in testa e coda, per aiutare l'utente
		var myUsrPuk = this.puk.trim()

		this.session
			.recoverPwdFromPuk(this.username, myUsrPuk, this.newpwd)
			.then((msg) => {
				console.log(msg)
				this.success = true
				this.isLoading = false
				//this.recoverErrMsg = "Pwd successfully changed."; // 10.03.2021
				// this.recoverErrMsg = this.translator.instant('FIRST_LOGIN.OK_PWD_CHANGED')
				this.successTitle = this.translator.instant('FIRST_LOGIN.SUCCESS')
				this.successSubTitle = this.translator.instant('FIRST_LOGIN.OK_PWD_CHANGED')

				setTimeout(() => {
					this.showIcon = true
				}, 700)

				setTimeout(() => {
					this.ActiveModal.dismiss()
				}, 5000)

				console.log('(recoverFromPuk) ' + this.recoverErrMsg)
				this.session.setTempToken('') // svuoto
				// alert(this.recoverErrMsg)
			})
			.catch((err) => {
				this.isLoading = false

				this.fail = true

				setTimeout(() => {
					this.fail = false
				}, 2000)

				let msg = this.session.parseErrorMessage(err, 'alert') // 28.03.2022

				this.recoverErrMsg = 'Error: ' + msg

				if (err.status == 412) {
					// precondition_failed, 13.04.2021 meglio test sul codice
					//if(msg == "image not found"){  // 16.12.2019 migliorato msg [ls]
					//this.recoverErrMsg = "Error: image not found. \r\n Without images, it is not possible to validate the puk. \r\n Please ask for a new account." ;
					this.recoverErrMsg = this.translator.instant('RECOVER_PUK.MISSING_IMAGES')
					enableRetry = false
				} else if (err.status == 429) {
					// 01.06.2021 Too many requests
					this.recoverErrMsg = this.translator.instant('RECOVER_PUK.MSG_TOO_MANY_ATTEMPTS')
					enableRetry = false
				} else {
					// msg generico, ok per tutti i 403, 404, per non aiutare eventuali haker
					this.recoverErrMsg = this.translator.instant('RECOVER_PUK.MSG_INVALID_PARAMS')
					// se solo sbagliato e vuole riprovare ?
					enableRetry = true
				}

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let options = new ToastOptions('error')

				this.toastService.show(header, this.recoverErrMsg, false, options, 'center')

				console.log('(L recoverFromPuk) ' + this.recoverErrMsg)
				// alert(this.recoverErrMsg)

				if (!enableRetry) {
					setTimeout(() => {
						this.ActiveModal.dismiss()
					}, 1000)
				}
			})
	}

	// 31.03.2021 portata su Util, richiamata anche da altri [ls]
	validatePwd(value) {
		this.validPwd = Util.validatePwd(value)
	}
}

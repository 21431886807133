// 21.06.2019 riordino codice

import { Config } from 'src/config'

// dati sensibili, arrivano e partono sempre crittati
export class Address {
	id: number
	address_label: number // vale sempre uno
	address_line1: string
	address_line2: string
	zip: string // non usiamo
	city: string
	province: string
	country: string
	phone1: string
	//phone2: string;  // non usiamo
	ref_email: string
	organization: string
	vat?: string

	latitude: number
	longitude: number
	label: string
	role: string

	// 10.03.2021 con dati in chiaro
	// si puo' passare anche la bag con altri elementi, qui prende solo i suoi
	constructor(decrittBag?) {
		this.id = 0
		this.address_label = 1 // fisso
		this.address_line1 = ''
		this.address_line2 = ''
		this.zip = ''
		this.city = ''
		this.province = ''
		this.country = ''
		this.phone1 = ''
		this.ref_email = ''
		this.organization = ''
		this.vat = ''
		this.latitude = null
		this.longitude = null
		this.label = ''
		this.role = ''

		if (decrittBag) {
			var myJsonObj = { ...decrittBag }
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (decrittBag.ref_email != null) {
				this.ref_email = decrittBag.ref_email
			}
			//else if(decrittBag.ref_email != null)   // 10.01.2022 aggiunta gestione multi nome per i campi
			//  this.ref_email = decrittBag.ref_email;

			if (decrittBag.phone1 != null) {
				this.phone1 = decrittBag.phone1
			}
		}
	}

	/* 29.08.2022 tolto doppione */
	static initFromBag(bagCri) {
		var addr = new Address()

		//campi crittati
		let fieldToDecryptAddr = Config.fieldToDecryptAddr
		for (let field of fieldToDecryptAddr) {
			if (bagCri[field] != null) {
				addr[field] = bagCri[field]
			}
		}

		//campi in chiaro arivano in un 2nd step
		let fieldClearAddr = Config.fieldClearAddr
		for (let field of fieldClearAddr) {
			if (bagCri[field] != null) {
				addr[field] = bagCri[field]
			}
		}
		// addr.address_line1 = bagCri['address_line1']
		// addr.city = bagCri['city']
		// addr.zip = bagCri['zip']
		// addr.province = bagCri['province']
		// addr.country = bagCri['country']

		// addr.phone1 = bagCri['phone'] //(bug 211) vd costruttore che gestisce entrambi

		// addr.ref_email = bagCri['email']
		// addr.organization = bagCri['organization']
		// addr.vat = bagCri['vat']
		return addr
	}

	// 24.08.2022 spostata qui per averla in un unico punto
	// 29.04.2020 per il report
	getAddressLine2(): string {
		let addr2 = ''

		if (this.city && this.province) addr2 = this.city + ' - ' + this.province
		else if (this.city) addr2 = this.city
		else if (this.province) addr2 = this.province

		if (this.country) {
			if (addr2.length > 0) addr2 += ' - ' + this.country
			else addr2 = this.country
		}

		return addr2
	}

	public isAddressValid(): boolean {
		let ret = false

		if (this.latitude && this.latitude != 0 && this.longitude && this.longitude != 0) {
			ret = true
		}

		return ret
	}

	public getExtendedAddressLabel(): string {
		let ret = ''

		if (this.address_line1) {
			ret += this.address_line1 + ', '
		}

		if (this.zip) {
			ret += this.zip + ', '
		}

		if (this.city) {
			ret += this.city + ', '
		}

		if (this.province) {
			ret += this.province + ', '
		}
		if (this.country) {
			ret += this.country + ', '
		}

		return ret
	}
}

export class userLocation {
	user_id: number
	latitude: number
	longitude: number
	country: string
	label: string
	role: string
	marker: maplibregl.Marker
	timezone: {name:string; offset: number}
	isValid: boolean

	constructor(loc?) {
		this.user_id = 0
		this.latitude = 0
		this.longitude = 0
		this.country = ''
		this.label = ''
		this.role = ''
		this.marker = null
		this.timezone = null
		this.isValid = false

		if (loc) {
			Object.assign(this, loc)
		}
	}
}

import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core'
import { NgbModal, ModalDismissReasons, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { FormControl, FormGroup } from '@angular/forms'

// 02.09.2022    MAT TABLE IMPORT
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'

import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { Device, deviceUpdateStatus, SwUpdate } from '../../models/device.model'

import { DeviceModal } from './device.modal'

import { faSortDown, faCircleExclamation, faXmark, faRotate, faCircleCheck, faCircleQuestion, faBan, faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { DataModelService, Status } from 'src/app/service/data-model.service'
import { basicPieGraph, pieChartDatas } from 'src/app/models/statistics.model'
import { Subscription, Subject, BehaviorSubject } from 'rxjs'
import { CryptoUtilsService } from 'src/app/service/crypto-utils.service'
import { location, mapConfig, searchOptions } from 'src/app/models/map.model'
import { TranslateService } from '@ngx-translate/core'
import { userLocation } from 'src/app/models/address.model'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'

import { Amplify, ResourcesConfig } from 'aws-amplify'
import { Geo, Place } from '@aws-amplify/geo'

import { MapService } from 'src/app/service/map.service'
import { Coordinates } from 'maplibre-gl-js-amplify/lib/esm/types'

export enum devicesTabs {
	OVERVIEW = 'overview',
	DETAILS = 'details',
}

@Component({
	selector: 'devices',
	templateUrl: './devices.component.html',
	styleUrls: ['./devices.component.scss'],
})
export class DevicesComponent implements OnInit, AfterViewInit, OnDestroy {
	deviceListChanged: Subscription
	// overview
	devicesTypePieChart: basicPieGraph
	devicesVersionPieChart: basicPieGraph
	devicesTypePieChartReady: boolean
	devicesVersionPieChartReady: boolean

	overviewDisplayedColumns: string[]

	loadLocations: boolean
	sendToMap: {
		latitude: number
		longitude: number
		description: string
		color: string
		id: number
	}[]

	sendLocationsToMap: BehaviorSubject<
		{
			latitude: number
			longitude: number
			description: string
			color: string
			id: number
		}[]
	>

	selectMarker: Subject<{ marker: maplibregl.Marker; id: number }>

	// details
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef

	devicesList: MatTableDataSource<Device> //Per usare il paginator deve essere di tipo MatTableDataSource
	displayedColumns: string[]
	filterWord: string //stringa inserita nel campo ricerca

	detailsColumns: string[]
	devicesListRaw: Device[]
	filteredDevicesList: Device[]

	activeTab: string

	showFilters: boolean
	filterSet: boolean
	activateSpinner: boolean
	filename: string

	filterForm: FormGroup
	availableDevices: string[]
	availableCountries: string[]
	updateStatus: { value: deviceUpdateStatus; label: string }[]
	gmtStatus: { value: number; label: string }[]
	devicesWithUpdate: number
	devicesWithPotentialUpdate: number
	devicesWithWrongGMT: number

	reloadEnable: boolean

	currentAction: string
	currentDevice: Device
	// deviceUpdtList: SwUpdate[] // relativa a currentDevice
	// zeroUpdates: boolean
	currentModal: any

	// searchOptions: searchOptions
	amplyConfig: ResourcesConfig = {
		Auth: {
			Cognito: {
				identityPoolId: '',
				allowGuestAccess: true,
			},
		},
		Geo: {
			LocationService: {
				region: '',
				maps: {
					items: {},
					default: '',
				},
				searchIndices: {
					//nel tipo LocationServiceConfig é searchIndices
					items: [],
					default: '',
				},
			},
		},
	}

	// icons
	faSortDown = faSortDown
	faCircleExclamation = faCircleExclamation
	faXmark = faXmark
	faRotate = faRotate
	faCircleCheck = faCircleCheck
	faCircleQuestion = faCircleQuestion
	faBan = faBan
	faFileCsv = faFileCsv

	constructor(
		public session: SessionService,
		public modalService: NgbModal,
		private dataService: DataModelService,
		public cryptoUtils: CryptoUtilsService,
		public translator: TranslateService,
		private toastService: AppToastService,
		private mapService: MapService
	) {
		let pieChart: pieChartDatas = new pieChartDatas()
		this.devicesTypePieChart = new basicPieGraph(pieChart)
		this.devicesVersionPieChart = new basicPieGraph(pieChart)
		this.devicesTypePieChartReady = false
		this.devicesVersionPieChartReady = false

		this.devicesListRaw = []
		this.filteredDevicesList = []

		this.sendToMap = []
		this.loadLocations = true
		this.sendLocationsToMap = new BehaviorSubject<
			{
				latitude: number
				longitude: number
				description: string
				color: string
				id: number
			}[]
		>(null)

		this.selectMarker = new Subject<{ marker: maplibregl.Marker; id: number }>()

		// this.deviceUpdtList = []
		// this.zeroUpdates = false

		this.activeTab = devicesTabs.OVERVIEW

		this.filterSet = false
		this.showFilters = true
		this.activateSpinner = true
		this.filename = ''

		this.filterForm = new FormGroup({
			model: new FormControl(null),
			country: new FormControl(null),
			update_status: new FormControl(null),
			gmt_status: new FormControl(null),
		})

		this.availableDevices = []
		this.availableCountries = []
		this.updateStatus = [
			{ value: deviceUpdateStatus.UNKNOWN, label: this.translator.instant('DEVICE.UPDATE_STATUS_FILTER_0') },
			{ value: deviceUpdateStatus.TARGET, label: this.translator.instant('DEVICE.UPDATE_STATUS_FILTER_1') },
			{ value: deviceUpdateStatus.POTENTIAL, label: this.translator.instant('DEVICE.UPDATE_STATUS_FILTER_2') },
			{ value: deviceUpdateStatus.ALIGNED, label: this.translator.instant('DEVICE.UPDATE_STATUS_FILTER_3') },
		]
		this.gmtStatus = [
			{ value: 1, label: this.translator.instant('DEVICE.GMT_STATUS_FILTER_0') },
			{ value: 2, label: this.translator.instant('DEVICE.GMT_STATUS_FILTER_1') },
		]
		this.devicesWithUpdate = 0
		this.devicesWithPotentialUpdate = 0
		this.devicesWithWrongGMT = 0

		this.filterForm.disable()

		this.detailsColumns = []

		if (this.session.isAdmin()) {
			this.detailsColumns = ['id']
		}
		this.detailsColumns.push(
			'model',
			'sn',
			'username',
			'organization',
			'country',
			'main_build',
			'main_build_num',
			'last_connection',
			'update_state',
			// 'last_update',
			'gmt_offset',
			'gmt_offset_state',
			'filter'
		)
		this.overviewDisplayedColumns = ['sn', 'model', 'update_state', 'gmt_offset', 'gmt_offset_state']

		this.displayedColumns = this.overviewDisplayedColumns
	}

	ngOnInit(): void {
		Util.debug('[deviceList] - onInit')

		this.deviceListChanged = this.dataService.deviceListChanged.subscribe((devices: Device[]) => {
			// console.log(devices)
			this.devicesListRaw = devices
			this.initTable(this.devicesListRaw)
		})
	}

	ngAfterViewInit() {
		Util.debug('[deviceList] - afterViewInit')
		this.loadDevicesList(false)
	}

	private loadDevicesList(force: boolean) {
		this.activateSpinner = true
		if (this.dataService.hasLoadedDevices() && !force) {
			Util.debug('[deviceList] - devices already loaded')

			this.devicesListRaw = this.session.getDtDevices()
			this.initTable(this.devicesListRaw)
		} else {
			this.session
				.loadDevices()
				.then(() => {
					// this.devicesListRaw = this.session.getDtDevices()
					// console.log(this.devicesListRaw)
					// this.initTable(this.devicesListRaw)
				})
				.catch((err) => {
					if (!this.session.isExpired(err)) {
						//  e' un altro errore, va segnalato
						var msg = err.data ? err.data.error : err.toString()
						alert(msg)
					}
				})
		}
	}

	private initTable(list: Device[]) {
		this.activateSpinner = false
		// console.log(list)
		this.availableDevices = [...new Set(this.devicesListRaw.map((item) => item.model))]
		this.availableCountries = [...new Set(this.devicesListRaw.map((item) => item.country))]
		this.filterForm.enable()

		this.devicesList = new MatTableDataSource<Device>(list)
		this.devicesList.paginator = this.paginator // Carico il paginator
		this.devicesList.sort = this.sort // per funzionare matColumnDef deve essere uguale a element.id

		//Implementato un ordinamento automatico al caricamento della pagina
		const sortState: Sort = { active: 'model', direction: 'asc' }
		this.sort.active = sortState.active
		this.sort.direction = sortState.direction
		this.sort.sortChange.emit(sortState)

		this.initCharts(list)

		if (this.activeTab == devicesTabs.DETAILS) {
			this.decrittOrganizations(list)
		}

		// ho adottato questa soluzione in quanto non volevo fare una cascata di promise e aspettare di caricare le location per la configurazione di amplify
		// per cui vanno insieme, quando ho finito entrambe allora controllo il GMT time e setto anche lo stato degli update, che non dipende cmq da queste cose
		Promise.all([this.getDevicesStatus(list), this.loadDevicesLocations(list), this.getAmplifyConfig()])
			.then((res) => {
				// console.log(res)

				let userLocation: userLocation[] = this.session.getUsersLocations()
				// check se restituisce ancora record dei mini

				let allUserId = [...new Set(list.map((item) => item.last_owner_id))]
				let promiseArray = []

				for (let userId of allUserId) {
					let location: userLocation = userLocation.find((item) => item.user_id == userId)
					let devices: Device[] = list.filter((item) => item.last_owner_id == userId)

					if (location && !location.timezone && devices.length > 0) {
						promiseArray.push(
							this.getTimezones(location.longitude, location.latitude).then((resp) => {
								// console.log(resp)
								for (let device of devices) {
									device.location.timezone = resp
								}
							})
						)
					}
				}

				Promise.all(promiseArray).then(() => {
					Util.debug(' loadDevicesLocations & getAmplifyConfig done')
					this.checkTimezones(list)
				})
			})
			.catch((err) => {
				console.log(err)
			})

		setTimeout(() => {
			this.reloadEnable = true
		}, 5000)
	}

	public reloadList() {
		this.reloadEnable = false
		this.filterForm.reset()
		this.filterSet = false
		this.loadDevicesList(true)
	}

	private getAmplifyConfig(): Promise<boolean> {
		const promise = new Promise<boolean>((resolve, reject) => {
			//ho aggiunto una proprietá in questo controllo in modo da essere sicuro ritorni un null
			// se cambio da overview a detail non deve ricaricare la configurazione, ce l'ho giá, infatti dava errori ma funzionava
			// peró getconfig, a volte ritorna un oggetto js vuoto quando non configurato, per cui devo controllare una proprietá
			if (Amplify.getConfig() && Amplify.getConfig().Geo) {
				resolve(true)
			} else {
				let token = this.session.user.token
				this.mapService
					.getAmplifyConfiguration(token)
					.then((res: mapConfig) => {
						// console.log(res)
						// this.mapOptions.region = res.mapConfig.region
						this.amplyConfig.Geo.LocationService.region = res.mapConfig.region
						this.amplyConfig.Auth.Cognito.identityPoolId = res.mapConfig.identityPoolId
						this.amplyConfig.Geo.LocationService.maps.items[res.mapConfig.mapName] = {}
						this.amplyConfig.Geo.LocationService.maps.default = res.mapConfig.mapName
						this.amplyConfig.Geo.LocationService.searchIndices.items = [res.mapConfig.indexItems]
						this.amplyConfig.Geo.LocationService.searchIndices.default = res.mapConfig.defaultIndex

						// this.searchOptions = new searchOptions({ latitude: 0, longitude: 0 }, '', res.mapConfig.indexItems)

						Amplify.configure(this.amplyConfig)
						resolve(true)
					})
					.catch((err) => {
						console.log(err)
						reject(err)
					})
			}
		})

		return promise
	}

	private initCharts(list: Device[]) {
		Util.debug('[deviceList] - initCharts')
		let devList: Device[] = list
		// console.log(list)
		// version type
		let versionsPieChart: pieChartDatas = new pieChartDatas()
		if (this.filterForm.get('model').value != null) {
			versionsPieChart.labels = [...new Set(devList.map((item) => item.main_build))]

			if (versionsPieChart.labels.includes('') || versionsPieChart.labels.includes(null)) {
				let stringToSearch = (element) => element == '' || element == null
				let indx = versionsPieChart.labels.findIndex(stringToSearch)
				versionsPieChart.labels[indx] = this.translator.instant('DEVICE.CHARTS.DEV_NO_VERSIONS')
			}

			let array = []
			for (let build of versionsPieChart.labels) {
				let devices: Device[] = []
				if (build === this.translator.instant('DEVICE.CHARTS.DEV_NO_VERSIONS')) {
					devices = devList.filter((item) => item.main_build == '' || item.main_build == null)
				} else {
					devices = devList.filter((item) => item.main_build == build)
				}
				array.push(devices.length)
				versionsPieChart.data = array

				this.devicesVersionPieChart = new basicPieGraph(versionsPieChart)
				this.devicesVersionPieChart.chart.height = 350
				this.devicesVersionPieChartReady = true
			}
		}
		this.devicesVersionPieChart = new basicPieGraph(versionsPieChart)
		this.devicesVersionPieChart.chart.height = 350
		this.devicesVersionPieChartReady = true
		//####

		//device type
		let devicePieChart: pieChartDatas = new pieChartDatas()
		devicePieChart.labels = [...new Set(devList.map((item) => item.model))]
		let array = []

		for (let model of devicePieChart.labels) {
			let devices: Device[] = devList.filter((item) => item.model == model)
			array.push(devices.length)
			devicePieChart.data = array
		}
		this.devicesTypePieChart = new basicPieGraph(devicePieChart)
		this.devicesTypePieChart.chart.height = 350
		this.devicesTypePieChartReady = true
	}

	private decrittOrganizations(list: Device[]): Promise<boolean> {
		Util.debug('[DevicesComponent] - decrittOrganizations')
		const promise = new Promise<boolean>((resolve, reject) => {
			var goodPwd = this.session.getPwdForKeyPhoto()
			let promiseArray = []
			for (let device of list) {
				// console.log(device)
				if (!device.decritted) {
					promiseArray.push(
						device
							.decrittOrganization(this.cryptoUtils, goodPwd)
							.then((res: boolean) => {
								// se utente deleted, la decritt é false
								// cambiato, decritto anche quelli deleted
								if (res) {
									device.decritted = true
								}
							})
							.catch((err) => {
								console.log(err)
							})
					)
				}
			}
			Promise.all(promiseArray).then((res) => {
				resolve(true)
			})
		})

		return promise
	}

	private loadDevicesLocations(list: Device[]): Promise<boolean> {
		Util.debug('[DevicesComponent] - loadDevicesLocations')
		this.sendToMap = []
		this.loadLocations = true

		const promise = new Promise<boolean>((resolve, reject) => {
			let promiseArray = []

			const listDevicesWithoutLocation = list.filter((item) => !item.location)
			const listDevicesWithLocation = list.filter((item) => item.location)

			// console.log(listDevicesWithoutLocation)
			// console.log(listDevicesWithLocation)

			this.session
				.loadUsersLocations()
				.then((res: boolean) => {
					// console.log(res)
					if (listDevicesWithoutLocation.length > 0) {
						// Potrebbero esserci piú dispositivi per utente, quindi farei la stessa chiamata piú volte e non ha senso
						// faccio le chiamate solo per utenti diversi
						let allUserId = [...new Set(listDevicesWithoutLocation.map((item) => item.last_owner_id))]
						// console.log(allUserId)

						for (let userId of allUserId) {
							if (userId) {
								let devicesId = listDevicesWithoutLocation.filter((item) => item.last_owner_id == userId).map((item) => item.id)
								let devices = listDevicesWithoutLocation.filter((item) => item.last_owner_id == userId)

								promiseArray.push(
									new Promise<boolean>((resolve, reject) => {
										this.session.updateDevicesLocation(devicesId).then((res: userLocation[]) => {
											// console.log(res)
											for (let loc of res) {
												if (loc.isValid) {
													this.sendToMap.push({
														latitude: loc.latitude,
														longitude: loc.longitude,
														color: '',
														id: loc.user_id,
														description: this.createPointDescription(devices, loc.label),
													})
												}
											}

											resolve(true)
										})
									})
								)
							}
						}
					}

					if (listDevicesWithLocation.length > 0) {
						let allUserId = [...new Set(listDevicesWithLocation.map((item) => item.last_owner_id))]

						for (let userId of allUserId) {
							let prom = new Promise<boolean>((resolve, reject) => {
								if (userId) {
									let devices = listDevicesWithLocation.filter((item) => item.last_owner_id == userId)
									// ne prendo uno, se un utente ha piú devices, la location per ogni device sará sempre la stessa
									if (devices[0].location.isValid) {
										this.sendToMap.push({
											latitude: devices[0].location.latitude,
											longitude: devices[0].location.longitude,
											color: '',
											id: devices[0].last_owner_id,
											description: this.createPointDescription(devices, devices[0].location.label),
										})
									}
								}
								resolve(true)
							})
							promiseArray.push(prom)
						}
					}

					Promise.all(promiseArray).then((res) => {
						// console.log(res)
						this.loadLocations = false
						this.sendLocationsToMap.next(this.sendToMap)
						resolve(true)
					})
				})
				.catch((err) => {
					console.log(err)
				})

			// metodo old, una richiesta per user id di device

			// if (listDevicesWithoutLocation.length > 0) {
			// 	let allUserId = [...new Set(listDevicesWithoutLocation.map((item) => item.last_user_id))]
			// 	console.log(allUserId)

			// 	for (let userId of allUserId) {
			// 		if (userId) {
			// 			let devicesId = listDevicesWithoutLocation.filter((item) => item.last_user_id == userId).map((item) => item.id)
			// 			let devices = listDevicesWithoutLocation.filter((item) => item.last_user_id == userId)

			// 			promiseArray.push(
			// 				await this.session.loadLocationsDevices(userId, devicesId).then((res) => {
			// 					// console.log(res)
			// 					if (res.isValid) {
			// 						this.sendToMap.push({
			// 							latitude: res.latitude,
			// 							longitude: res.longitude,
			// 							color: '',
			// 							id: res.user_id,
			// 							description: this.createPointDescription(devices, res.label),
			// 						})
			// 					}
			// 				})
			// 			)
			// 		}
			// 	}
			// }

			// if (listDevicesWithLocation.length > 0) {
			// 	let allUserId = [...new Set(listDevicesWithLocation.map((item) => item.last_user_id))]

			// 	for (let userId of allUserId) {
			// 		let prom = new Promise<boolean>((resolve, reject) => {
			// 			if (userId) {
			// 				let devices = listDevicesWithLocation.filter((item) => item.last_user_id == userId)
			// 				// ne prendo uno, se un utente ha piú devices, la location per ogni device sará sempre la stessa
			// 				if (devices[0].location.isValid) {
			// 					this.sendToMap.push({
			// 						latitude: devices[0].location.latitude,
			// 						longitude: devices[0].location.longitude,
			// 						color: '',
			// 						id: devices[0].last_user_id,
			// 						description: this.createPointDescription(devices, devices[0].location.label),
			// 					})
			// 				}
			// 			}
			// 			resolve(true)
			// 		})
			// 		promiseArray.push(prom)
			// 	}
			// }
		})

		return promise
	}

	private createPointDescription(devices: Device[], label: string): string {
		let description = '<b>' + label + ' - ' + devices[0].username + '</b>' + '</br></br> <ul>'
		for (let dev of devices) {
			description = description + '<li>' + dev.model + ' : ' + dev.sn + '</li>'
		}
		description = description + '</ul>'

		return description
	}

	private getDevicesStatus(list: Device[]): Promise<boolean> {
		Util.debug('[DevicesComponent] - getDevicesStatus start')
		const promise = new Promise<boolean>(async (resolve, reject) => {
			let promiseArray = []
			this.devicesWithUpdate = 0
			this.devicesWithPotentialUpdate = 0

			for (let device of list) {
				if (device.update_available == deviceUpdateStatus.LOADING) {
					if (device.main_build_num) {
						promiseArray.push(
							this.session.getDevicesUpdatesStatus(device.id).then((res) => {
								// console.log(res)
								device.update_available = res.updt_status

								if (device.update_available == deviceUpdateStatus.TARGET) {
									this.devicesWithUpdate++
								}

								if (device.update_available == deviceUpdateStatus.POTENTIAL) {
									this.devicesWithPotentialUpdate++
								}
							})
						)
					} else {
						device.update_available = deviceUpdateStatus.UNKNOWN
					}
				} else if (device.update_available == deviceUpdateStatus.TARGET) {
					this.devicesWithUpdate++
				} else if (device.update_available == deviceUpdateStatus.POTENTIAL) {
					this.devicesWithPotentialUpdate++
				}
			}

			Promise.all(promiseArray).then((res) => {
				resolve(true)
				// console.log(list)
			})
		})

		return promise
	}

	public getMarkers(locations: location[]) {
		// assegno i marker alla lista di device
		Util.debug('[DevicesComponent] - assegno i marker alla lista di device')
		const listDevicesWithLocation = this.devicesListRaw.filter((item) => item.location)

		for (let loc of locations) {
			let devices = listDevicesWithLocation.filter((item) => item.last_owner_id == loc.id)

			devices.forEach((device) => {
				device.location.marker = loc.marker
			})
		}
	}

	private getTimezones(longitude: number, latitude: number): Promise<{ name: ''; offset: 0 }> {
		Util.debug('[DevicesComponent] - getTimezones')
		// i device passati qui hanno tutti lo stesso indirizzo
		const promise = new Promise<{ name: ''; offset: 0 }>((resolve, reject) => {
			let coordinates: Coordinates = [longitude, latitude]
			Geo.searchByCoordinates(coordinates)
				.then((res) => {
					// console.log(res)
					// timezone non c'e' nel tipo Place, ma in realta viene ritornato
					let resp: any = res
					resolve(resp.timeZone)
				})
				.catch((err) => {
					console.log(err)
					reject(err)
				})
		})

		return promise
	}

	private checkTimezones(list: Device[]) {
		Util.debug('[DevicesComponent] - checkTimezones')
		this.devicesWithWrongGMT = 0
		// prima formatto il GMT dei device e poi lo confronto
		this.formatTimezones(list).then((res) => {
			for (let device of list) {
				if (device.location && device.location.timezone && device.gmt_offset_state != 0) {
					if (device.gmt_offset == this.secondsToTime(device.location.timezone.offset)) {
						device.gmt_offset_state = 1
					} else {
						device.gmt_offset_state = 2
						this.devicesWithWrongGMT++
					}
				}
			}
		})
	}

	private formatTimezones(list: Device[]): Promise<boolean> {
		Util.debug('[DevicesComponent] - formatTimezones')
		const promise = new Promise<boolean>(async (resolve, reject) => {
			for (let device of list) {
				if (device.gmt_offset && device.gmt_offset != '') {
					let gmt = device.gmt_offset

					gmt.replace(/[^\d-]/g, '')

					let operator: string = ''
					let hasOperator = gmt.includes('-') || gmt.includes('+')
					let gmt_time = gmt

					if (hasOperator) {
						operator = gmt[0]
						gmt_time = gmt.slice(1)
					}

					if (gmt_time.length < 5) {
						if (gmt_time.length === 1) {
							device.gmt_offset = '0' + gmt_time + ':00'
							device.gmt_offset_state = 1
						} else if (gmt_time.length === 2) {
							device.gmt_offset = gmt_time + ':00'
							device.gmt_offset_state = 1
						} else if (gmt_time.length === 4) {
							device.gmt_offset = gmt_time.slice(0, 2) + ':' + gmt_time.slice(2, 4)
							device.gmt_offset_state = 1
						} else {
							console.log(' not valid timezone' + gmt_time)
							device.gmt_offset_state = 2
						}

						if (hasOperator) {
							device.gmt_offset = operator + device.gmt_offset
						} else {
							device.gmt_offset = '+' + device.gmt_offset
						}
						// console.log(device.gmt_offset)
					}
				}
			}
			resolve(true)
		})
		return promise
	}

	private secondsToTime(seconds: number): string {
		const hours = Math.floor(Math.abs(seconds) / 3600)
		const minutes = Math.floor((Math.abs(seconds) % 3600) / 60)

		const sign = seconds >= 0 ? '+' : '-'
		const hoursString = hours.toString().padStart(2, '0')
		const minutesString = minutes.toString().padStart(2, '0')

		return `${sign}${hoursString}:${minutesString}`
	}

	public onNavChange(event: NgbNavChangeEvent) {
		// console.log(event)
		this.activeTab = event.nextId

		let list: Device[] = []
		if (this.filterSet) {
			list = this.filteredDevicesList
		} else {
			list = this.devicesListRaw
		}

		// TAB DETAILS
		if (event.nextId == devicesTabs.DETAILS) {
			this.showFilters = false

			this.displayedColumns = this.detailsColumns

			this.decrittOrganizations(list)

			// TAB OVERVIEW
		} else {
			this.showFilters = true
			this.sendLocationsToMap.next(this.sendToMap)
			this.displayedColumns = this.overviewDisplayedColumns

			this.initCharts(list)
		}
	}

	public filterTableValue() {
		Util.debug('[devices-details] - filterValue')
		this.filterSet = false
		this.filename = ''

		this.filteredDevicesList = this.devicesListRaw.slice()

		//model filter
		let model = this.filterForm.get('model').value
		if (model) {
			this.filterSet = true
			this.filename = this.filename + '_' + model
			this.filteredDevicesList = this.filteredDevicesList.filter((el) => el.model == model)
		}

		//country filter
		let country = this.filterForm.get('country').value
		if (country) {
			this.filterSet = true
			this.filename = this.filename + '_' + country
			this.filteredDevicesList = this.filteredDevicesList.filter((el) => el.country == country)
		}

		//update status
		let status = this.filterForm.get('update_status').value
		if (status) {
			this.filterSet = true
			this.filename = this.filename + '_' + status
			console.log(status)
			this.filteredDevicesList = this.filteredDevicesList.filter((el) => el.update_available == status)
		}

		//gmt status
		let gmt = this.filterForm.get('gmt_status').value
		if (gmt) {
			this.filterSet = true
			this.filename = this.filename + '_' + gmt
			this.filteredDevicesList = this.filteredDevicesList.filter((el) => el.gmt_offset_state == gmt)
		}
		this.initTable(this.filteredDevicesList)
	}

	// Ricerca parola filtro
	filterText() {
		this.filterWord = this.input.nativeElement.value

		this.filterWord = this.filterWord.trim().toLocaleLowerCase()

		this.devicesList.filter = this.filterWord
	}

	//###############################################################

	showItem(myDev: Device) {
		// console.log(myDev)
		if (this.activeTab == devicesTabs.OVERVIEW) {
			if (myDev.location) {
				let send: { marker: maplibregl.Marker; id: number } = { marker: myDev.location.marker, id: myDev.location.user_id }
				this.selectMarker.next(send)
			} else {
				let header = this.translator.instant('TOAST.HEADER.INFO')
				let body = this.translator.instant('DEVICE.ERR_LOCATION')
				let options = new ToastOptions('info')

				this.toastService.show(header, body, false, options, 'center')

				this.selectMarker.next(null)
			}
		} else {
			this.currentAction = 'view'

			this.session
				.loadDevice(myDev.id)
				.then(() => {
					this.currentModal = this.modalService.open(DeviceModal, { size: 'xl' })

					this.currentModal.componentInstance.parent = this // per poi chiamare session o altre globali
					this.currentModal.componentInstance.currentDevice = this.session.getDtDevice(myDev.id) // oggetto dalla lista

					// arriva qui facendo la close del modal
					this.currentModal.result.then(
						(changedPat) => {
							Util.debug('COMP - After modal closed: ')
							//this.submitForm(changedPat);
						},
						(reason) => {
							let ris = Util.getDismissReason(reason)
							Util.debug('Dismissed ' + ris)

							// 25.02.2022 abandoned because of duplicated patient
							if (ris.indexOf('goto:') == 0) {
								let oldPat = reason.substring(5)
								//this.showVisits(oldPat);  //go to single page
							}
						}
					)

					// if (this.currentModal && this.currentModal.componentInstance) {
					// 	this.currentModal.componentInstance.currentDevice = this.currentDevice // full info
					// 	Util.debug('devices - update with full object, ' + this.currentDevice.id)
					// } else {
					// 	Util.debug('devices - no update')
					// }
				})
				.catch((err) => {
					// 14.09.2021
					if (!this.session.isExpired(err)) {
						//  e' un altro errore, va segnalato
						var msg = err.data ? err.data.error : err.toString()
						alert(msg)
					}
				})
		}
	}

	formatSize(av_space) {
		return Util.formatBytes(av_space, 2) // 2 = cifre dopo la virgola
	}

	public downloadLsit() {
		let filename = 'DeviceList' + this.filename + '.csv'
		let listToExport = []
		let array: Device[] = []
		if (this.filterSet) {
			array = this.filteredDevicesList
		} else {
			array = this.devicesListRaw
		}

		for (let device of array) {
			let el = {
				id: device.id,
				model: device.model,
				sn: device.sn,
				owner: device.username,
				organization: device.organization,
				country: device.country,
				main_build: device.main_build,
				build_number: device.main_build_num,
				last_used: this.session.formatDateTime(device.last_used),
				update_status: device.update_available,
				gmt_offset: device.gmt_offset,
				wrong_gmt_offset: device.gmt_offset_state == 2 ? 'Y' : '',
				// country_timezone_offset: device.location?.timezone.offset,
			}

			listToExport.push(el)
		}
		Util.downloadCSV(listToExport, filename)
	}

	ngOnDestroy(): void {
		if (this.deviceListChanged) {
			this.deviceListChanged.unsubscribe()
		}
	}
}

import { AfterViewInit, Component, ElementRef, input, Input, OnInit, Renderer2, ViewChild } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal, NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap'
import { DevicesType, SwUpdate } from 'src/app/models/device.model'
import { SessionService } from 'src/app/service/session.service'

import { faTriangleExclamation, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import { TranslateService } from '@ngx-translate/core'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'
import { Util } from 'src/app/models/util.model'
import { ComponentType } from 'src/app/models/device.model'

@Component({
	selector: 'wizard-modal',
	templateUrl: './wizard.modal.html',
	styleUrls: ['./wizard.modal.scss'],
})
export class WizardModal implements OnInit, AfterViewInit {
	@Input() device: DevicesType | string
	@Input() alreadyLoadedFiles: string[]
	@ViewChild('nav') tabset: NgbNav

	@ViewChild('binary') binary: ElementRef
	@ViewChild('tab1') tab1: ElementRef
	@ViewChild('tab2') tab2: ElementRef
	// @ViewChild('tab3') tab3: ElementRef
	// @ViewChild('tab4') tab4: ElementRef
	@ViewChild('line1') line1: ElementRef
	@ViewChild('line2') line2: ElementRef
	@ViewChild('line3') line3: ElementRef

	tabs: { id: number; isDone: boolean; form: FormGroup; title: string; subtitle: string }[]
	activeTab: number
	filterForm1: FormGroup
	filterForm2: FormGroup
	filterForm3: FormGroup

	devices: string[]
	// availableBrands: string[]
	availableComponents: string[] | ComponentType[]
	availableOs: string[]
	availableVersions: number[]
	fileReader: FileReader
	SwUpdate: SwUpdate
	osNotAvailable: boolean
	minVersionNotAvailable: boolean

	enablePackageName: boolean

	savingUpdate: { state: boolean; result: boolean; message: string }
	savingUpdateResult: string

	// icons
	faTriangleExclamation = faTriangleExclamation
	faXmark = faXmark
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	constructor(
		public activeModal: NgbActiveModal,
		private renderer: Renderer2,
		private session: SessionService,
		private translator: TranslateService,
		private toastService: AppToastService
	) {
		Util.debug('[WizardModal] - constructor')
		this.activeTab = 0
		this.alreadyLoadedFiles = []

		this.filterForm1 = new FormGroup({
			device: new FormControl('', Validators.required),
			// brand: new FormControl(null),
		})

		// this.filterForm1.get('brand').disable()

		this.filterForm2 = new FormGroup({
			type: new FormControl('', Validators.required),
			file: new FormControl('', [Validators.required, this.validatorsCheckBinary.bind(this)]),
			build: new FormControl(null, [Validators.required, this.setBuild_int.bind(this)]),
			build_int: new FormControl('', Validators.required),
			package_name: new FormControl('', Validators.required),
		})

		this.filterForm2.disable()

		this.filterForm3 = new FormGroup({
			description: new FormControl('', Validators.required),
			os: new FormControl(null),
			min_version: new FormControl(null),
		})

		this.filterForm3.get('os').disable()
		this.filterForm3.get('min_version').disable()

		this.tabs = [
			{
				id: 0,
				isDone: false,
				form: this.filterForm1,
				title: this.translator.instant('SW_UPDT.WIZARD.TITLE1'),
				subtitle: this.translator.instant('SW_UPDT.WIZARD.SUBTITLE1'),
			},
			{
				id: 1,
				isDone: false,
				form: this.filterForm2,
				title: this.translator.instant('SW_UPDT.WIZARD.TITLE2'),
				subtitle: this.translator.instant('SW_UPDT.WIZARD.SUBTITLE2'),
			},
			{
				id: 2,
				isDone: false,
				form: this.filterForm3,
				title: this.translator.instant('SW_UPDT.WIZARD.TITLE3'),
				subtitle: this.translator.instant('SW_UPDT.WIZARD.SUBTITLE3'),
			},
			{
				id: 3,
				isDone: false,
				form: this.filterForm1,
				title: this.translator.instant('SW_UPDT.WIZARD.TITLE4'),
				subtitle: this.translator.instant('SW_UPDT.WIZARD.SUBTITLE4'),
			},
		]

		this.devices = this.session.deviceModels

		// this.availableBrands = []
		this.availableComponents = []
		this.availableOs = []
		this.availableVersions = []
		this.osNotAvailable = false
		this.minVersionNotAvailable = false
		this.enablePackageName = false

		this.savingUpdate = { state: false, result: false, message: '' }

		this.savingUpdateResult = ''
	}

	ngOnInit(): void {
		Util.debug('[WizardModal] - ngOnInit')
		window.addEventListener('resize', () => {
			this.calculateLines()
		})

		this.filterForm1.get('device').setValue(this.device)
		// console.log(this.filterForm1)
		this.filterChange()
	}

	ngAfterViewInit(): void {
		this.calculateLines()
	}

	private calculateLines() {
		// le linee che congiungono i vari step possonon avere lunghezze diverse in base alla dimensione della finestra, per cui lo calcolo ogni volta
		const rect1 = this.tab1.nativeElement.getBoundingClientRect()
		const rect2 = this.tab2.nativeElement.getBoundingClientRect()

		const distanza = rect2.left - rect1.right
		const elementWidth = rect2.left - rect2.right

		this.renderer.setStyle(this.line1.nativeElement, 'width', distanza + 'px')
		this.renderer.setStyle(this.line1.nativeElement, 'transform', 'translateX(' + elementWidth + 'px)')

		this.renderer.setStyle(this.line2.nativeElement, 'width', distanza + 'px')
		this.renderer.setStyle(this.line2.nativeElement, 'transform', 'translateX(' + elementWidth + 'px)')

		this.renderer.setStyle(this.line3.nativeElement, 'width', distanza + 'px')
		this.renderer.setStyle(this.line3.nativeElement, 'transform', 'translateX(' + elementWidth + 'px)')
	}

	public onNavChange(id: number) {
		// console.log(id)
		let tab = this.tabs.find((t) => t.id == id)
		tab.isDone = true
	}

	public filterChange() {
		Util.debug('[WizardModal] - filterChange')
		let device = this.filterForm1.get('device').value

		switch (this.activeTab) {
			case 0: {
				// this.session
				// 	.loadAvailableDeviceBrands(device)
				// 	.then((brands) => {
				// 		// console.log(brands)
				// 		this.availableBrands = brands.brands
				// 		this.filterForm1.get('brand').enable()
				// 	})
				// 	.catch((err) => {
				// 		console.log(err)
				// 	})
				break
			}

			case 1: {
				if (device === DevicesType.VX610) {
					this.enablePackageName = true
				} else {
					this.enablePackageName = false
				}

				let components = this.session.userDevices.filter((d) => d.model == device).map((d) => d.update_app_types)

				if (components && components[0].length > 0) {
					this.availableComponents = components[0]
				} else {
					this.availableComponents = [ComponentType.APP]
				}

				this.filterForm2.get('type').enable()

				// console.log(this.availableComponents)
				break
			}

			case 2: {
				// let brand = null
				// if (this.filterForm1.get('brand').value && this.filterForm1.get('brand').value != '') {
				// 	brand = this.filterForm1.get('brand').value
				// }

				let os = null
				if (this.filterForm3.get('os').value && this.filterForm3.get('os').value != '') {
					os = this.filterForm3.get('os').value
				}

				let min_version = null
				if (this.filterForm3.get('min_version').value && this.filterForm3.get('min_version').value != '') {
					min_version = this.filterForm3.get('min_version').value
				}

				if (!os) {
					this.osNotAvailable = false
					this.filterForm3.get('os').disable()
					// add min version?
					this.session
						.loadAvailableOsVersions(device, min_version) // , brand)
						.then((os) => {
							// console.log(os)
							let options: { os_ver: string; dev_type: string; branding: string }[] = os.options

							if (options.length > 0) {
								this.availableOs = [...new Set(options.map((o) => o.os_ver))]
								this.filterForm3.get('os').enable()
							} else {
								this.osNotAvailable = true
							}
						})
						.catch((err) => {
							console.log(err)
						})
				}

				if (!min_version) {
					this.minVersionNotAvailable = false
					this.filterForm3.get('min_version').disable()
					this.session
						.loadAvailableMinVersions(device, os) // , brand, os)
						.then((versions) => {
							// console.log(versions.builds)
							let builds: { build_num: number; dev_type: string; branding: string }[] = versions.builds

							if (builds.length > 0) {
								this.filterForm3.get('min_version').enable()
								this.availableVersions = [...new Set(builds.map((o) => o.build_num))]
							} else {
								this.minVersionNotAvailable = true
							}
						})
						.catch((err) => {
							console.log(err)
						})
				}

				break
			}

			case 3: {
				this.createSwUpdate()
				break
			}
		}
	}

	private createSwUpdate() {
		this.SwUpdate = new SwUpdate()
		this.SwUpdate.build_ver = this.filterForm2.get('build').value
		this.SwUpdate.build_num = this.filterForm2.get('build_int').value
		// this.SwUpdate.branding = this.filterForm1.get('brand').value
		this.SwUpdate.dev_type = this.filterForm2.get('type').value
		this.SwUpdate.package_name = this.filterForm2.get('package_name').value
		this.SwUpdate.model = this.filterForm1.get('device').value
		this.SwUpdate.os_ver = this.filterForm3.get('os').value
		this.SwUpdate.description = this.filterForm3.get('description').value
		this.SwUpdate.url = this.binary.nativeElement.files[0].name
		this.SwUpdate.min_build_num = this.filterForm3.get('min_version').value
		// console.log(this.SwUpdate)
	}

	public next() {
		if (this.activeTab < 3) {
			this.activeTab++
			this.tabset.select(this.activeTab)
			this.filterChange()
		} else {
			this.activeTab++
			this.savingUpdate = { state: true, result: false, message: '' }

			var binaryPack: any // ArrayBuffer;
			var r = new FileReader()
			r.readAsDataURL(this.binary.nativeElement.files[0])

			r.addEventListener('loadend', (e) => {
				// console.log(e)

				binaryPack = String(e.target.result).split(',', 2)[1] // 03.02.2022 risolve warning su split

				this.session
					.createSwUpdate(this.SwUpdate, binaryPack)
					.then((resp) => {
						// console.log(resp)
						this.savingUpdate = { state: false, result: true, message: this.translator.instant('SW_UPDT.WIZARD.RESULT_MESS') }

						setTimeout(() => {
							this.activeModal.close(resp)
							let header = this.translator.instant('TOAST.HEADER.SUCCESS')
							let body = this.savingUpdate.message
							let options = new ToastOptions('success')

							this.toastService.show(header, body, false, options, 'bottom-right')
						}, 2000)
					})
					.catch((err) => {
						var msg = err ? err.message : err.toString()
						console.log('(Updt) KO ' + err.error.error)
						this.savingUpdate = { state: false, result: false, message: this.translator.instant('SW_UPDT.WIZARD.RESULT_MESS_FAIL') + msg }

						setTimeout(() => {
							this.activeModal.dismiss(err)

							let header = this.translator.instant('TOAST.HEADER.ERROR')
							let body = this.savingUpdate.message
							let options = new ToastOptions('error')

							this.toastService.show(header, body, false, options, 'center')
						}, 2000)

						// alert('Error: ' + msg)
					})
			})
		}
	}

	public back() {
		this.activeTab--
		this.tabset.select(this.activeTab)
	}

	validatorsCheckBinary(control: FormControl): { [s: string]: boolean } {
		// console.log(control)
		if (this.binary) {
			let name = this.binary.nativeElement.value

			if (control.value != '') {
				//prima controllo che sia una estensione ammessa
				if (name.indexOf('.exe') > 0 || name.indexOf('.apk') > 0 || name.indexOf('.gz') > 0 || name.indexOf('.zip') > 0) {
					// se giá inserito fra quelli in lista fermo
					if (this.checkFileAlreadyLoaded(name)) {
						return { ['file already loaded']: true }
					} else {
						//inserisco automaticamente la versione stringa
						// console.log(name)
						const regex = /[0-9](.)[0-9](.)[0-9]/g
						const version = name.match(regex)
						// const version = name.match(/\d+/g)
						// console.log(version)
						if (version) {
							this.filterForm2.get('build').setValue(version[0])

							// const regexn = /[0-9]/g
							// const number = version[0].match(regexn)
							// let value = number[0] + 0 + 0 + number[1] + 0 + 0 + number[2]
							// this.filterForm2.get('build_int').setValue(Number(value))

							// this.setBuild_int(version[0])
						}
						this.filterForm2.get('build').enable()
						this.filterForm2.get('build_int').enable()
						if (this.enablePackageName) {
							this.filterForm2.get('package_name').enable()
						}

						return null
					}
				} else {
					return { ['invalid file']: true }
				}
			} else {
				if (this.filterForm2) {
					this.filterForm2.get('build').setValue('')
					this.filterForm2.get('build').disable()
					this.filterForm2.get('build_int').setValue('')
					this.filterForm2.get('build_int').disable()
					this.filterForm2.get('package_name').setValue('')
					this.filterForm2.get('package_name').disable()
				}
				return { ['no file selected']: true }
			}
		}
	}

	private checkFileAlreadyLoaded(filename: string): boolean {
		let name = filename.toString().split('\\').pop()
		return this.alreadyLoadedFiles.includes(name)
	}

	private setBuild_int(control: FormControl): { [s: string]: boolean } {
		if (control.value) {
			let result = ''

			var numbers = control.value.split('.')
			var version = ''
			var middle = ''
			var sub1 = ''
			var sub2 = ''
			for (var i = 0; i < numbers.length; i++) {
				var number = numbers[i]

				switch (i % 4) {
					case 0:
						version = number
						break
					case 1:
						number = number.toString().padStart(3, 0)
						middle = number
						break
					case 2:
						number = number.toString().padStart(3, 0)
						sub1 = number
						break
					case 3:
						number = number.toString().padStart(4, 0)
						sub2 = number
						break
				}
			}
			result = `${version}${middle}${sub1}${sub2}`
			// console.log(result)
			this.filterForm2.get('build_int').setValue(Number(result))
			return null
		}
	}

	public dismiss() {
		this.activeModal.dismiss()
	}
}

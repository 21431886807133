<div>
	<div class="telerefract-container topview z-depth-2">
		<div class="row">
			<div class="full-container">
				<div class="panel panel-default">
					<div class="panel-head">
						<div>
							<label class="panel-title text-uppercase">{{ 'TELEREFRACT.TELEREF_TITLE' | translate }}</label>
						</div>
					</div>
					<div class="panel-body">
						<form [formGroup]="teleRefrForm" (ngSubmit)="openTelePage('POST')">
							<div class="full-container fields-container">
								<div class="row">
									<div class="full-container">
										{{ 'TELEREFRACT.TELEREF_DESCRIPTION_1' | translate }}<br />
										{{ 'TELEREFRACT.TELEREF_DESCRIPTION_2' | translate }}<br />
										{{ 'TELEREFRACT.TELEREF_DESCRIPTION_3' | translate }}<br />&nbsp;
										<!-- The Live EyeCare service allows you to connect to a Visionix device and perform an exam remotely.
										<br />the GO button will open a new window. <br />Please ensure that the browser is not blocking the page pop-up. <br />&nbsp;-->
									</div>
								</div>

								<div>
									<div class="row" style="margin-top: 10px" [hidden]="this.session.isOptician() || session.isStaging() || session.isProduction()">
										<div class="col-1" style="width: 1%">
											<input type="radio" name="rbUrl" value="URL" formControlName="rbUrl" style="position: relative; top: 25%" />
										</div>
										<div class="col-1" style="width: 10%">
											<label for="url" style="position: relative; top: 25%">Telerefract URL *</label>
										</div>
										<div class="col-10">
											<input type="text" class="form-control" id="url" name="url" formControlName="url" style="position: relative; top: 25%" />
										</div>
									</div>
									<div class="row" style="margin-top: 10px" [hidden]="this.session.isDoctor() || this.session.isInstaller()">
										<div class="col-1" style="width: 1%">
											<input type="radio" name="rbUrl" value="CHAT" formControlName="rbUrl" style="position: relative; top: 25%" />
										</div>
										<div class="col-1" style="width: 10%">
											<label for="chat" style="position: relative">{{ 'TELEREFRACT.CHAT' | translate }}</label>
										</div>
										<div class="col-1" [hidden]="session.isStaging() || session.isProduction()">
											<label for="chat">Chat url</label>
										</div>
										<div class="col-9" [hidden]="session.isStaging() || session.isProduction()">
											<input type="text" class="form-control" id="chat" name="chat" formControlName="chaturl" />
										</div>
									</div>
									<div class="row" style="margin-top: 10px" [hidden]="this.session.isDoctor() || this.session.isInstaller()">
										<div class="col-1" style="width: 1%">
											<input type="radio" name="rbUrl" value="BOOTH" formControlName="rbUrl" style="position: relative; top: 25%" />
										</div>
										<div class="col-1" style="width: 10%">
											<label for="booth" style="position: relative; top: 25%">{{ 'TELEREFRACT.BOOTH' | translate }}</label>
										</div>
										<div class="col-1" [hidden]="session.isStaging() || session.isProduction()">
											<label for="booth">Booth url</label>
										</div>
										<div class="col-9" [hidden]="session.isStaging() || session.isProduction()">
											<input type="text" class="form-control" id="booth" name="booth" formControlName="boothurl" />
										</div>
									</div>
									<br />&nbsp;
								</div>

								<div class="row" [hidden]="(session.isProduction() || session.isStaging())">
									<div class="form-group sixth-container">
										<label for="name">NAME</label>
										<input type="text" class="form-control" maxlength="100" id="name" name="name" readonly formControlName="name" />
									</div>

									<div class="form-group sixth-container">
										<label for="org">ORGANIZATION</label>
										<input type="text" class="form-control" maxlength="100" id="org" name="org" formControlName="org" readonly />
									</div>

									<div class="form-group sixth-container">
										<label for="usrid">ID</label>
										<input type="text" class="form-control" maxlength="10" id="usrid" name="usrid" formControlName="usrid" readonly />
									</div>

									<div class="form-group sixth-container">
										<label for="role">Role</label>
										<input type="text" class="form-control" maxlength="50" id="role" name="role" formControlName="role" readonly />
									</div>

									<div class="form-group sixth-container">
										<label for="groupId">Group_ID</label>
										<input type="text" class="form-control" maxlength="10" id="groupId" name="groupId" formControlName="groupId" readonly />
									</div>
									<div class="form-group sixth-container">
										<label for="subtype">SubType</label>
										<input type="text" class="form-control" maxlength="10" id="subtype" name="subtype" formControlName="subtype" readonly />
									</div>

									<div class="form-group sixth-container">
										<label for="targetOrganization">Target Organization</label>
										<input type="text" class="form-control" maxlength="100" id="targetOrganization" name="targetOrganization" formControlName="targetOrganization" />
									</div>
									<div class="form-group sixth-container">
										<label for="tGroupId">Target Group_ID</label>
										<input type="text" class="form-control" maxlength="50" id="tGroupId" name="tGroupId" formControlName="tGroupId" />
									</div>
								</div>

								<div class="row" [hidden]="(session.isProduction() || session.isStaging())">
									<div class="full-container">
										* The form will be hidden after tests.
										<br />&nbsp;
									</div>
								</div>

								<div class="row">
									<div class="third-container">&nbsp;</div>
									<div class="third-container">
										<div class="row">
											<div class="half-container">
												<button type="submit" class="btn btn-primary">{{ 'TELEREFRACT.GO_BUTTON' | translate }}</button>
											</div>
										</div>
									</div>
									<div class="third-container">&nbsp;</div>
								</div>

								<div class="row">
									<div class="full-container">&nbsp;</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

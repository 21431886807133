import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core'

import { FormControl, FormGroup } from '@angular/forms'
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

import { faChevronDown, faXmark, faCheck, faCirclePlus } from '@fortawesome/free-solid-svg-icons'

import { TranslateService } from '@ngx-translate/core'

import { SessionService } from '../../service/session.service'

import { CategDiagnosis, Prescription, ICD, CategExam } from '../../models/diagnosis.model'
import { Util } from '../../models/util.model'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

// Per creare un array di mat-autocomplete, ho creato una classe contenente tutte le cose necessarie, fra cui anche l'oggeto filter, che é un observable
export class diagnosisGroup {
	myControl: FormControl
	formValid: boolean
	filter: Observable<ICD[]>
	list: ICD[]

	constructor(obj: ICD[]) {
		this.myControl = new FormControl('')
		this.list = obj
		this.filter = this.myControl.valueChanges.pipe(
			startWith(''),
			map((value) => {
				const descr = typeof value === 'string' ? value : value?.descr

				let filt = this.list.filter((list) => list.displDescr.toLowerCase().includes(value))

				return descr ? filt : this.list.slice()
			})
		)
		this.formValid = false
	}
}

@Component({
	selector: 'diagnosi',
	templateUrl: './diagnosi.component.html',
	styleUrls: ['./diagnosi.component.scss'],
	encapsulation: ViewEncapsulation.None, // necessario per fare in modo di sovrascrivere le classi della material
})
export class DiagnosiComponent implements OnInit, AfterViewInit {
	//fix scroll
	// @ViewChild('autoCompleteInput1', { read: MatAutocompleteTrigger }) autoComplete1!: MatAutocompleteTrigger

	currentModal

	//fix scroll
	@ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) autoComplete!: MatAutocompleteTrigger

	@Input() activeTab: string

	// come renderlo anche output ?
	@Input() currDiagnosi: CategDiagnosis

	// refraction ha anche 2 strutture aggiuntive, Final1 e Final2, per ciascun occhio
	@Input() rxFinal1R: Prescription
	@Input() rxFinal1L: Prescription
	@Input() rxFinal2R: Prescription
	@Input() rxFinal2L: Prescription

	// @Input() parent: CategoriesController; // the parent
	@Input() catName: string
	@Output() resetEvent = new EventEmitter<string>()
	@Output() resetFields = new EventEmitter<string>()

	controlsLeft: diagnosisGroup[] // Oggetto left per creare un array di gruppo di diagnosi per usarlo nel ciclo for nel'html
	controlsRight: diagnosisGroup[] // Oggetto right per creare un array di gruppo di diagnosi per usarlo nel ciclo for nel'html

	freeTextMax: number
	displayTwoFreeText: boolean // 11.05.2022

	isHealthy: boolean
	isNotGrad: boolean
	enabledRight: boolean
	enabledLeft: boolean
	enabledBoth: boolean

	icdList: ICD[] // lista ICD from session

	icdListRight: ICD[] // lista ICD occhio destro
	icdListLeft: ICD[] // Lista ICD occhio sinistro

	rightSelected: boolean // variabile che mi applica una classe al pulsante aggiungi diagnosi right
	leftSelected: boolean // variabile che mi applica una classe al pulsante aggiungi diagnosi left

	quickHealtyLeft: ICD // ICD healty left
	quickHealtyRight: ICD // ICD healty right

	quickNotGradLeft: ICD // ICD not gradable left
	quickNotGradRight: ICD // ICD not grdable right

	rightForm: FormGroup
	leftForm: FormGroup

	addDiagTextEnable: string
	addDiagTextDisable: string

	icdTitleMessage: string // 08.04.2022
	ICD_hint: string // 22.04.2022

	faXmark = faXmark
	faChevronDown = faChevronDown
	faCheck = faCheck
	faCirclePlus = faCirclePlus

	constructor(
		public session: SessionService,
		public translator: TranslateService, //private cdr: ChangeDetectorRef //public activeModal: NgbActiveModal
		public modalService: NgbModal
	) {
		this.controlsRight = []
		this.controlsLeft = []

		this.rightSelected = false
		this.leftSelected = false

		this.addDiagTextEnable = this.translator.instant('DIAGNOSI.BUTTON_EN_ADD_DIAG')
		this.addDiagTextDisable = this.translator.instant('DIAGNOSI.BUTTON_DIS_ADD_DIAG')

		this.rightForm = new FormGroup({
			// creo il form right in modalitá reactive con i 2 checkbox
			quickHealty: new FormControl(null),
			quickNotGrad: new FormControl(null),
		})

		this.leftForm = new FormGroup({
			// creo il form left in modalitá reactive con i 2 checkbox
			quickHealty: new FormControl(null),
			quickNotGrad: new FormControl(null),
		})
	}
	ngOnInit(): void {
		Util.debug('(diagnosi) ngOnInit - catName: ' + this.catName + ' valid diagn ? ' + (this.currDiagnosi != null)) // true

		// console.log(this.currDiagnosi)

		this.displayTwoFreeText = true // 11.05.2022

		// 04.02.2022
		if (!this.session.userIcdsEnabled()) {
			this.freeTextMax = 1000
		} else {
			this.freeTextMax = 500

			// Update scroll position 23-11-22 (solo se ci sono ICD)
			// 22-05-2023 spostato tutto sulla directive scroll.directive
			// window.addEventListener('scroll', this.scrollEvent, true)

			this.icdList = this.session.getIcdList(this.catName)
			Util.debug('(diagnosi) ngOnInit - [' + this.catName + '] tot icd: ' + this.icdList.length)

			// console.log(this.icdList)

			/** -------- creo la lista left e right -------- */
			//
			this.icdListLeft = this.icdList.filter((icd) => icd.eye == 'left')
			this.icdListRight = this.icdList.filter((icd) => icd.eye == 'right')

			// console.log(this.icdListLeft)
			// console.log(this.icdListRight)

			/** -------- mi ricavo dalle liste gli ICD healty e not gradable -------- */
			//
			let qHLeft = this.icdListLeft.filter((icd) => icd.code.indexOf(ICD.HEALTHY) == 0 || icd.code.indexOf('Z0100') == 0)

			this.quickHealtyLeft = new ICD(qHLeft[0])

			let qHRight = this.icdListRight.filter((icd) => icd.code.indexOf(ICD.HEALTHY) == 0 || icd.code.indexOf('Z0100') == 0)

			this.quickHealtyRight = new ICD(qHRight[0])

			let qNGLeft = this.icdListLeft.filter((icd) => icd.code.indexOf(ICD.NOT_GRAD) == 0)

			this.quickNotGradLeft = new ICD(qNGLeft[0])

			let QNGRight = this.icdListRight.filter((icd) => icd.code.indexOf(ICD.NOT_GRAD) == 0)

			this.quickNotGradRight = new ICD(QNGRight[0])

			/** -------- levo dalle liste gli ICD healty e not gradable -------- */
			//

			this.icdListLeft = this.icdListLeft.filter((icd) => icd.code.indexOf(ICD.HEALTHY) && icd.code.indexOf('Z0100') && icd.code.indexOf(ICD.NOT_GRAD))

			this.icdListRight = this.icdListRight.filter((icd) => icd.code.indexOf(ICD.HEALTHY) && icd.code.indexOf('Z0100') && icd.code.indexOf(ICD.NOT_GRAD))

			// console.log(this.icdListLeft)
			// console.log(this.icdListRight)

			/** -------- creo due diagnosi una left e una right in modo da vedere 2 select disponibili left e right -------- */
			//
			this.addDiagnosisField('both')

			this.verifyICDs()
		}

		// this.myControl1.setErrors({ incorrect: true });

		// 08.04.2022
		this.icdTitleMessage = ''

		let grp = this.session.userDiagnosisGroup()
		//let grp = this.session.getUserDiagnGroup();

		let hideIcdsCodes = this.session.hideIcdsCodes(grp) // group 2

		if (hideIcdsCodes) {
			//this.icdTitleMessage = "SELECT up to 3 items";
			this.icdTitleMessage = this.translator.instant('DIAGNOSI.HIDDEN_ICD_LABEL')
			this.ICD_hint = this.translator.instant('DIAGNOSI.HIDDEN_ICD_HINT')
			this.displayTwoFreeText = false // 11.05.2022
		} else {
			//this.icdTitleMessage = "SELECT up to 3 ICD10 values";
			this.icdTitleMessage = this.translator.instant('DIAGNOSI.ICD_LABEL')
			this.ICD_hint = this.translator.instant('DIAGNOSI.ICD_HINT')
		}
	}

	/*23-11-22
	Update scroll mat-autocomplete */
	// 22-05-2023 spostato su directive
	// scrollEvent = (): void => {
	// 	if (this.autoComplete.panelOpen) {
	// 		this.autoComplete.updatePosition()
	// 	}
	// }

	ngAfterViewInit() {
		Util.debug('(diagnosi) - ngAfterViewInit ' + this.catName)
	}

	resetAll() {
		//resetto tutti i campi, viene chiamata con il pulsante reset form

		//
		this.disableList(this.controlsRight) // sfrutto questa func per svuotare l'array di controls e resettare
		this.disableList(this.controlsLeft) // sfrutto questa func per svuotare l'array di controls e resettare

		this.controlsRight[0].myControl.enable() // con la funzione di prima usata per i checkbox, mi disabilita la lista, per cui riabilito l'unico elemento
		this.controlsLeft[0].myControl.enable() // con la funzione di prima usata per i checkbox, mi disabilita la lista, per cui riabilito l'unico elemento

		this.rightSelected = false //disabilito il pulsante aggiungi diagnosi
		this.leftSelected = false //disabilito il pulsante aggiungi diagnosi

		this.currDiagnosi.icdLeft = [] // svuoto l'array
		this.currDiagnosi.icdRight = [] // svuoto l'array

		// resetto tutte le checkbox
		this.rightForm.reset()
		this.rightForm.enable()
		this.leftForm.reset()
		this.leftForm.enable()

		this.resetFields.emit(this.catName) //Richiamo la funzione del categories tramite @output
	}

	// Alla selezione della voce nella lista, viene salvata
	selectOption(data: MatAutocompleteSelectedEvent, diagnosi: diagnosisGroup, eye: string, id: number, fromSession?: boolean) {
		let myIcdCode = data.option.value.code

		// console.log(data)

		if (myIcdCode != null) {
			Util.debug('D (setIcd) ' + this.catName + ' eye ' + eye + ' choice: ' + myIcdCode)
		} else {
			Util.debug('D (setIcd) ' + this.catName + ' null for eye ' + eye)
		}

		if (!this.currDiagnosi) {
			Util.debug('D (setIcd) ' + this.catName + ' undefined diagnosis! ' + this.catName)
		}

		let msg = this.translator.instant('DIAGNOSI.IDENTICAL_ICDS') //let msg = "There are two identical ICDS values: do you want to proceed anyway ?";

		let duplicateICD = this.checkICDs(eye, data.option.value) // controllo se ci sono duplicati, spostato qui dalla categories
		//
		// se carico dalla session non controllo duplicati, il messaggio era giá stato vis prima
		if (fromSession) {
			duplicateICD = false
		}
		//
		// nella categories venivano controllati anche i conflitti (se venivano selezionati within normal limit o not gradable e altre voci), ma non piú necessario in quanto adesso ci sono le checkbox

		if (duplicateICD) {
			// se duplicati apro il modal
			let res = this.openConfirmModal(msg, eye)

			res.result
				.then((result) => {
					console.log(result)
					// ok voglio inserirli uguali flag true
					if (result) {
						if (eye == 'left') {
							this.currDiagnosi.icdLeft[id] = data.option.value

							this.leftSelected = true
						} else if (eye == 'right') {
							this.currDiagnosi.icdRight[id] = data.option.value

							this.rightSelected = true
						}

						diagnosi.myControl.disable()
						diagnosi.formValid = true
					}
				})
				.catch(() => {
					// non voglio inserirli uguali
					diagnosi.myControl.setValue('') // cancello il campo
				})
		} else {
			// se non duplicati aggiungo semplicemente
			if (eye == 'left') {
				this.currDiagnosi.icdLeft[id] = data.option.value

				this.leftSelected = true
			} else if (eye == 'right') {
				this.currDiagnosi.icdRight[id] = data.option.value

				this.rightSelected = true
			}

			diagnosi.myControl.disable()
			diagnosi.formValid = true
		}

		if (this.currDiagnosi != null) {
			// console.log(this.currDiagnosi)
			Util.debug('D (' + eye + ')' + myIcdCode)

			// Util.debug('D (setIcd) 1 ' + this.currDiagnosi.icd1.code)
			// Util.debug('D (setIcd) 2 ' + this.currDiagnosi.icd2.code)
			// Util.debug('D (setIcd) 3 ' + this.currDiagnosi.icd3.code)
		}
	}

	checkICDs(eye: string, icd: ICD) {
		var result: boolean = false
		var array

		if (eye == 'left') {
			array = this.currDiagnosi.icdLeft
		} else if (eye == 'right') {
			array = this.currDiagnosi.icdRight
		}

		for (let i = 0; i < array.length; i++) {
			const element = array[i]

			if (element.code == icd.code) {
				result = true
			}
		}

		return result
	}

	openConfirmModal(text: string, eye: string) {
		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		return this.currentModal
	}

	displayFn(icd: ICD): string {
		return icd && icd.descr ? icd.descr : ''
	}

	/** -------- Richiamata dal pulsante add new diagnosi e da verify icd-------- */
	//
	addDiagnosisField(eye: string) {
		// console.log(eye)
		let left = new diagnosisGroup(this.icdListLeft)
		let right = new diagnosisGroup(this.icdListRight)

		if (eye == 'both') {
			//
			this.controlsRight.push(right)
			this.controlsLeft.push(left)
			//
		} else if (eye == 'left' && this.leftSelected) {
			//
			this.controlsLeft.push(left)
			this.leftSelected = false // ho aggiunto una diagnosi quindi disabilito la possibilitá di agiungerne altre
			//
		} else if (eye == 'right' && this.rightSelected) {
			//
			this.controlsRight.push(right)
			this.rightSelected = false // ho aggiunto una diagnosi quindi disabilito la possibilitá di agiungerne altre
			//
		}

		// console.log(this.controlsRight)
		// console.log(this.controlsLeft)
	}

	// passa di qui al change delle textarea
	// TODO
	// quando ci scrive, deve resettare il flag x risalvarla poi
	resetDiagnosis() {
		//var catName = this.parent.currCategName;
		Util.debug('(diagnosi) changed Diagn for cat: ' + this.catName) // ok, troppe volte
		// su categories.ts
		//this.parent.resetDiagnosis(this.catName);
		this.resetEvent.emit(this.catName)
	}

	/** -------- Richiamata dal pulsante x cancellando una diagnosi -------- */
	// viene cancellata la diagnosi dall'array di mat-select e dal currDiagnosi
	deleteICD(eye: string, id: number) {
		Util.debug('(diagnosi) delete ICD eye: ' + eye)

		if (eye == 'left') {
			console.log('left here')
			this.currDiagnosi.icdLeft.splice(id, 1)

			if (this.controlsLeft.length == 1) {
				this.controlsLeft[0].myControl.setValue('')

				this.controlsLeft[0].myControl.enable()
				this.leftSelected = false
			} else {
				this.controlsLeft.splice(id, 1)

				this.leftSelected = true
			}
		} else if (eye == 'right') {
			console.log('right here')
			this.currDiagnosi.icdRight.splice(id, 1)

			if (this.controlsRight.length == 1) {
				this.controlsRight[0].myControl.setValue('')

				this.controlsRight[0].myControl.enable()
				this.rightSelected = false
			} else {
				this.controlsRight.splice(id, 1)

				this.rightSelected = true
			}
		}

		// console.log(this.currDiagnosi)
		// console.log(this.controlsRight)
		// console.log(this.controlsLeft)
	}

	// 20.05.2022 al load, per ciascuno dei 3, se sono pre-valorizzati, faccio il controllo x disab altri
	private verifyICDs() {
		Util.debug('(diagnosi) - verifyICDs')
		let icdRight = this.currDiagnosi.icdRight
		let icdLeft = this.currDiagnosi.icdLeft

		if (icdRight.length > 0) {
			Util.debug('(diagnosi) - verifyICDs - icdRight.length: ' + icdRight.length)
			for (let i = 0; i < icdRight.length; i++) {
				const tmpIcd: ICD = icdRight[i]

				let str = ''

				if (tmpIcd.isHealthy()) {
					str = 'quickHealty'
				} else if (tmpIcd.isNotGradable()) {
					str = 'quickNotGrad'
				}

				if (i != 0) {
					// il primo select material ce l'ho sempre, gli altri devo crearli
					this.addDiagnosisField('right')
				}
				this.controlsRight[i].myControl.setValue(tmpIcd)
				this.controlsRight[i].myControl.disable()
				this.controlsRight[i].formValid = true
				this.rightSelected = true

				// ATTENZIONE controlli healty e notGrad, fatti qui dopo e non all'inizio del ciclo, cosí non serve mettere molte condizioni, all'inixio aggiungerá un halty fra le selezioni, poi lo rimuve e selexiona la checkbox
				if (tmpIcd.isHealthy() || tmpIcd.isNotGradable()) {
					// se é checkbox healty disabilito l'altra checkbox, disabilito la lista e esco
					this.rightForm.disable()
					this.rightForm.get(str).enable()
					this.rightForm.get(str).setValue(true)
					this.controlsRight[0].myControl.setValue('')
					this.controlsRight[0].myControl.disable()
					this.controlsRight[0].formValid = false
					this.rightSelected = false
				}
			}
		}

		if (icdLeft.length > 0) {
			Util.debug('(diagnosi) - verifyICDs - icdLeft.length: ' + icdLeft.length)
			for (let i = 0; i < icdLeft.length; i++) {
				const tmpIcd: ICD = icdLeft[i]

				let str = ''

				if (tmpIcd.isHealthy()) {
					str = 'quickHealty'
				} else if (tmpIcd.isNotGradable()) {
					str = 'quickNotGrad'
				}

				if (i != 0) {
					// il primo select material ce l'ho sempre, gli altri devo crearli
					this.addDiagnosisField('left')
				}
				this.controlsLeft[i].myControl.setValue(tmpIcd)
				this.controlsLeft[i].myControl.disable()
				this.controlsLeft[i].formValid = true
				this.leftSelected = true

				// ATTENZIONE controlli healty e notGrad, fatti qui dopo e non all'inizio del ciclo, cosí non serve mettere molte condizioni, all'inixio aggiungerá un halty fra le selezioni, poi lo rimuve e selexiona la checkbox
				if (tmpIcd.isHealthy() || tmpIcd.isNotGradable()) {
					// se é checkbox disabilito l'altra checkbox, disabilito la lista e esco
					this.leftForm.disable()
					this.leftForm.get(str).enable()
					this.leftForm.get(str).setValue(true)
					this.controlsLeft[0].myControl.setValue('')
					this.controlsLeft[0].myControl.disable()
					this.controlsLeft[0].formValid = false
					this.leftSelected = false
				}
			}
		}
	}

	/** -------- Richiamata dalle checkbox -------- */
	// deve cancellare tutte le diagnosi inserite per occhio, disabilitare la selezione, disabilitare l'altra checkbox, svuotare l'array currDiagnosis e inserire l'ICD corrispondente

	quickSelect(event, formname: string, eye: string) {
		Util.debug('(diagnosi) - quickSelect')
		// console.log(event)
		// console.log(formname)

		let value = event.target.value

		if (event.target.checked) {
			if (eye == 'right') {
				this.rightForm.disable()
				this.rightForm.get(formname).enable()

				this.disableList(this.controlsRight)

				this.currDiagnosi.icdRight = []

				this.rightSelected = false

				if (value.indexOf(ICD.HEALTHY) == 0 || value.indexOf('Z0100') == 0) {
					this.currDiagnosi.icdRight.push(this.quickHealtyRight)
				} else if (value.indexOf(ICD.NOT_GRAD) == 0) {
					this.currDiagnosi.icdRight.push(this.quickNotGradRight)
				}
			} else {
				this.leftForm.disable()
				this.leftForm.get(formname).enable()

				this.disableList(this.controlsLeft)

				this.currDiagnosi.icdLeft = []

				this.leftSelected = false

				if (value.indexOf(ICD.HEALTHY) == 0 || value.indexOf('Z0100') == 0) {
					this.currDiagnosi.icdLeft.push(this.quickHealtyLeft)
				} else if (value.indexOf(ICD.NOT_GRAD) == 0) {
					this.currDiagnosi.icdLeft.push(this.quickNotGradLeft)
				}
			}
		} else {
			if (eye == 'right') {
				this.rightForm.enable()
				this.rightSelected = true
				this.controlsRight[0].myControl.enable()
				this.currDiagnosi.icdRight = []
				this.rightSelected = false
			} else {
				this.leftForm.enable()
				this.leftSelected = true
				this.controlsLeft[0].myControl.enable()
				this.currDiagnosi.icdLeft = []
				this.leftSelected = false
			}
		}

		// console.log(this.currDiagnosi)
	}

	disableList(group: diagnosisGroup[]) {
		let tot = group.length

		while (tot > 1) {
			group.pop()

			tot--
		}

		group[0].myControl.setValue('')
		group[0].myControl.disable()
		group[0].formValid = false
	}
}

import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, Output } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { faSave } from '@fortawesome/free-regular-svg-icons'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'
import { VisitListComponent } from '../visits/visitList.component'
import { CategoriesController } from '../categories/categories.controller'

import { DateParser } from '../../models/dateParser.model'
import { Patient } from '../../models/patient.model'
import { Util } from '../../models/util.model'
import { FullReport, Report } from '../../models/report.model'
import { DiagnosisReport } from '../../models/pdf.model'
import { SrvDiagnosis, CategExam } from '../../models/diagnosis.model'
import { ExamImage, ExamType } from '../../models/exam.model'

import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs } from '../../models/settings.model'
import { ActivatedRoute } from '@angular/router'
import { AppToastService } from 'src/app/service/toast.service'
import { AnamnesisService } from 'src/app/service/anamnesis.service'

//import myPdf = require("../models/diagnosisReport");

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'reports-modal',
	templateUrl: './reports.modal.html',
	styleUrls: ['./reports.modal.scss'],
	providers,
})
export class ReportList implements OnInit, AfterViewInit {
	reportListTable: MatTableDataSource<Report>
	@Input() parent: VisitListComponent
	@Input() reportList: Report[] // Arriva dal parent
	@Input('replist-changed') refreshFlag: string
	@Input() targetReport: number // per RDS   23.08.2022
	@Input() targetPatient: number // per RDS   23.08.2022

	patientId: number
	optId: number

	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef
	displayedColumns: string[]
	sortStatus: Sort
	pageStatus: PageEvent
	@Output() reportPref: TablePrefs
	localStorageName: string

	currentPatient: Patient

	modalWaitPdf
	isGeneratingPdf: boolean
	updatedRepStatus: boolean // 28.04.2022

	faSave = faSave // per usarla su html  13.01.2022

	constructor(
		public session: SessionService,
		public anamnesisService: AnamnesisService,
		public activeModal: NgbActiveModal,
		public translator: TranslateService,
		public dataService: DataModelService,
		public myMatPagIntl: MatPaginatorIntl,
		public modalService: NgbModal,
		private activatedRoute: ActivatedRoute,
		private toastService: AppToastService
	) {
		Util.debug('[ReportList] constructor')
		// qui non ho ancora session ?

		this.modalWaitPdf = null
		this.isGeneratingPdf = false
		this.updatedRepStatus = false // 28.04.2022
		this.targetReport = 0
		this.targetPatient = 0

		// saving preferences
		this.localStorageName = this.session.getUserId() + ' - reportPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.reportPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.reportPref = new TablePrefs()

			this.reportPref.empty = false
			// default data
			this.reportPref.sort = 'creation_date'

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportPref))
		}
	}

	ngOnInit(): void {
		Util.debug('[ReportList] onInit')

		this.initColumns()

		// 30.12.2021
		this.currentPatient = this.session.getDtPatient()

		this.loadUrlParameters()

		// 20.01.2022 TODO carico la relation per avere il display name poi quando apro un pdf
		// 04.08.2022
		//if(this.session.isLevel1()){
		//let currUserId = this.session.getUserId();
		//this.session.loadRelationsList(currUserId); // TODO, le proprie
		//}

		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')
	}

	ngAfterViewInit() {
		this.initTable() // don't touch -- the NG0100 error is normal *only in development*
		Util.debug('[ReportList] afterInit, required: ' + this.targetReport)
		if (this.targetReport > 0) {
			if (this.currentPatient) {
				Util.debug('[ReportList] - afterInit - patient code: ' + this.currentPatient.code)
			} else {
				this.currentPatient = this.session.getDtPatient() // riprova

				if (this.currentPatient) {
					Util.debug('[ReportList] - afterInit - missing patient, now code: ' + this.currentPatient.code)
				} else {
					Util.debug('[ReportList] - afterInit - KO, missing patient')
				}
			}

			if (this.currentPatient) {
				Util.debug('[ReportList] - afterInit - 2, ok patient')
				this.downloadReport(this.targetReport)
			} else {
				this.session.loadPatient('' + this.targetPatient).then((done) => {
					this.currentPatient = this.session.getDtPatient(this.targetPatient)
					if (this.currentPatient) {
						Util.debug('[ReportList] - afterInit - 3, ok patient')
						this.downloadReport(this.targetReport)
					}
				})
			}
		}
	}

	//Inizializzazione colonne tabella
	initColumns() {
		// 27.12.2022 no download per il support, si diagn_grp
		//this.displayedColumns = ['report_id', 'creation_date', 'diagnosis', 'created_by', 'download']
		this.displayedColumns = ['report_id', 'creation_date', 'diagnosis', 'created_by']

		if (this.session.isLevel1() || this.session.isLevel3()) {
			this.displayedColumns.push('status')
		}

		if (this.session.isLevel3() || this.session.isSupport()) {
			this.displayedColumns.push('diagn_group')
		}

		if (!this.session.isSupport()) {
			this.displayedColumns.push('download')
		}

		this.displayedColumns.push('filter')
	}

	private ApplySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		this.input.nativeElement.value = pref.filter

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}

	private initTable() {
		if (this.reportList != null) {
			this.reportListTable = new MatTableDataSource<Report>(this.reportList)
			this.reportListTable.paginator = this.paginator
			this.reportListTable.sort = this.sort
			// automatic sort
			this.ApplySettings(this.reportPref, this.reportListTable)
		} else {
			Util.debug('(Reports) null rep list!')
		}
	}

	// From directive
	loadUrlParameters() {
		if (this.activatedRoute != null) {
			// 09.06.2022 added test
			this.activatedRoute.queryParams.subscribe((params) => {
				let myParam = params['doctor']
				if (myParam != null && parseInt(myParam) > 0) {
					this.optId = myParam
				}

				myParam = params['patient']
				if (myParam != null && parseInt(myParam) > 0) {
					this.patientId = myParam
				}
			})
		}
	}

	// 20.12.2021 serve per la paginazione
	manageReload(event, currPg) {
		if (this.dataService.hasLoadedReportList()) {
			Util.debug('R COMP (manageReload) rep list loaded! ')
			let totRep = 0
			totRep = this.dataService.getTotReports()
			Util.debug('[ReportList] manageReload - tot: ' + totRep + ' currPg: ' + currPg)
		}
	}

	downloadReport(repId, repDate?) {
		Util.debug('[ReportList](downloadReport) inizio')

		// 20.05.2022 anticipato qui [ls]
		this.isGeneratingPdf = true // abilita spinner con css

		// 01.06.2020
		this.session
			.getReport(repId)
			.then((myRep: FullReport) => {
				// console.log(myRep)

				if (myRep == null) {
					alert('[ReportList](downloadReport) KO !')
					return
				} else {
					//if(myRep != null){
					//this.openWaitPdfModal();

					//Util.debug("[ReportList] valid translator ?"+(this.translator != null)); // true

					// 03.06.2020 scaricare gli esami/immagini che lo compongono
					var reportPdf = new DiagnosisReport(this.session, this.dataService, this.translator, this.currentPatient)

					reportPdf.setReportType('diagnosis') // 24.08.2022

					reportPdf.setReportInfo(myRep) // 03.06.2020

					if (repDate) {
						repDate = DateParser.formatSqlDateLong(repDate)
					} else {
						// patch ?
						let adesso = new Date()
						repDate = DateParser.formatSqlDateLong(adesso)
					}

					this.anamnesisService.getPatientAnamnesisAnswers(this.patientId, repDate).then((patAnam) => {
						if (patAnam) {
							Util.debug('[ReportList] ok pat anamnesis, tot: ' + patAnam.length) // 10.07.2023
							reportPdf.setAnamnesisAnswers(patAnam)

							this.anamnesisService.getPatientVA(this.patientId, repDate).then((patVa) => {
								reportPdf.setVaAnswers(patVa)
							})
						} else {
							Util.debug('[ReportList] KO pat anamnesis') // 10.07.2023
							reportPdf.setAnamnesisAnswers(false)
						}

						// 10.07.2023 Deve stare qui dentro per aspettare l'anamnesis
						//})

						//alert("[ReportList](downloadReport) TODO !");

						var categoryRep = new CategoriesController(
							this.session,
							this.anamnesisService,
							this.translator,
							this.dataService,
							this.parent.activatedRoute,
							this.modalService,
							this.activeModal,
							this.toastService
						)

						categoryRep.setVisit(0) // reset da prima

						// 24.08.2022 meglio altro, con diagnosis attiva anche il wizard
						//categoryRep.setAction("diagnosis");  // 09.07.2020 serve questo o altro ? [ls]
						categoryRep.setAction('printHgReport')

						// svuota precedenti array e prepara per nuova
						// da richiamare solo la prima volta, dopo che e' stata fatta una selezione degli esami
						this.session.initCategories()

						// 30.12.2021
						// 07.07.2020 ciclo su tutte le categorie di questo report
						// 09.07.2020 portato fuori x gestire bene la promise  [ls]

						this.loadCategs(myRep)
							.then((allCategLoaded) => {
								// console.log(allCategLoaded)
								Util.debug('R[PDF] loaded tot categ: ' + allCategLoaded.length)

								for (let i = 0; i < allCategLoaded.length; i++) {
									// qui ho perso il categName ! altro ciclo sotto...

									Util.debug('R[PDF] loaded categ ' + (i + 1))

									var currentCategoryExams = allCategLoaded[i]
									//console.log(currentCategoryExams); // ok

									// 07.07.2020 in aggiunta per ciascuna categoria, non replace,
									// usa metodi "add", non "set"

									// imposta i moduli scelti
									//categoryRep.setExamChoice(currentCategoryExams);
									categoryRep.addExamChoice(currentCategoryExams)

									//reportPdf.setExamList(currentCategoryExams);
									reportPdf.addExamList(currentCategoryExams)
								}

								// dopo che le ha caricate
								var totCateg = 0
								if (myRep != null && myRep.diagnosis != null) totCateg = myRep.diagnosis.length

								// 24.07.2020 per test
								Util.debug('R [PDF] inizio filtro immagini, tot categ ' + totCateg)

								var repActiveCats = [] // 11.11.2020 fix, costruisco da qui
								for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
									// inizializzo
									var name = Config.CATEG_NAMES[i]
									repActiveCats[name] = false
								}

								for (let i = 0; i < totCateg; i++) {
									var catName = myRep.diagnosis[i].category

									var diagnSingola: SrvDiagnosis
									diagnSingola = myRep.getDiagnosis(catName)
									if (diagnSingola != null) {
										var examlistD = diagnSingola.exam_list

										// 24.07.2020 test
										//console.log("R [PDF] examList in diagnosi:");
										//console.log(examlistD);

										if (catName == Config.CAT_CORNEA) {
											// 07.07.2020 portata fuori la funzione
											var toPrintC = this.getPrintableCorneaImages(categoryRep, examlistD)
											reportPdf.setImagesCornea(toPrintC[0], toPrintC[1])
											//reportPdf.setImagesCornea(showOnlyR, showOnlyL);
										} else if (catName == Config.CAT_ANTERIOR) {
											var toPrintA = this.getPrintableAnteriorImages(categoryRep, examlistD)
											reportPdf.setImagesAnterior(toPrintA[0], toPrintA[1])
										} else if (catName == Config.CAT_GLC) {
											var toPrintG = this.getPrintableGlcImages(categoryRep, examlistD)
											reportPdf.setImagesGlaucoma(toPrintG[0], toPrintG[1])
										} else if (catName == Config.CAT_FUNDUS) {
											var toPrintF = this.getPrintableFundusImages(categoryRep, examlistD)
											reportPdf.setImagesFundus(toPrintF[0], toPrintF[1])
										} else if (catName == Config.CAT_REFRACTION) {
											// 26.10.2020

											var flagCombi = categoryRep.refrCombinedData() // 03.11.2020
											//console.log("R (Pdf) set flag combi: "+flagCombi);
											reportPdf.setCombinedRefraction(flagCombi)
										}

										repActiveCats[catName] = true // 11.11.2020
									} else {
										// non passa mai di qui
										console.log('R [PDF] ko diagn singola ' + i + ' ' + catName)
										repActiveCats[catName] = false // 11.11.2020
									}
								}

								Util.debug('R [PDF] load completed - all categs for pdf')
								//console.log(allCategLoaded);

								// 11.11.2020 FIX, prendere per le diagnosi dal report, ciclo for appena sopra, non da categories
								//var repActiveCats = categoryRep.getActiveCategories();
								reportPdf.setActiveCategories(repActiveCats)
								//console.log(repActiveCats);  // 11.11.2020 test

								// FIXME: 10.07.2023 deve aspettare di avere la risposta delle anamnesis

								if (!this.currentPatient) {
									this.currentPatient = this.session.getDtPatient()
								}
								// crea il pdf e apre la richiesta di download
								reportPdf.printPdf(this.currentPatient)

								// 12.06.2020 cambio stato -> downloaded  // usare dei codici numerici pro traduzioni
								this.updateReportStatus(repId, Report.STATUS_READ)

								// 30.12.2021 terminato, ferma lo spinner
								//this.modalWaitPdf.dismiss();
								this.isGeneratingPdf = false
							})
							.catch((error) => {
								// 30.12.2021
								console.log('(pdf) error!')
								console.log(error)
								alert('pdf error!')
								this.isGeneratingPdf = false
							})
					}) // chiude then di anamnesis
				} // chiude if myRep
			})
			.catch((error) => {
				// 20.05.2022
				this.isGeneratingPdf = false
				console.log('(getpdf) error!')
				console.log(error)
				alert('error on report request!')
			})
	}

	passBack() {
		Util.debug('[ReportList] modal closed!')
		this.activeModal.close(this.updatedRepStatus)
	}

	// 09.07.2020 per il pdf, portata fuori x gestire meglio le promise ? [ls]
	private loadCategs(myRep) {
		// 07.07.2020 ciclo su tutte le categorie di questo report
		var totCateg = 0

		if (myRep != null && myRep.diagnosis != null) totCateg = myRep.diagnosis.length

		var allCategLoaded = []

		for (let i = 0; i < totCateg; i++) {
			var catName = myRep.diagnosis[i].category

			Util.debug('(R) loadCategs [PDF] report made of categ [' + (i + 1) + '/' + totCateg + '] ' + catName)

			var myList = []
			var diagnSingola: SrvDiagnosis
			diagnSingola = myRep.getDiagnosis(catName)

			if (diagnSingola == null) {
				Util.debug('(R) loadCategs [PDF] ko diagnosis ?! ' + i + ' ' + catName) // 09.07.2020
			} else {
				var myReportExams: CategExam[]
				myReportExams = diagnSingola.exam_list

				// costruisco myList da myReportExams (strutture diverse)
				for (var j = 0; j < myReportExams.length; j++) {
					var elem = myReportExams[j]
					Util.debug('(R) loadCategs [PDF] ' + j + ' adding exam ' + elem.exam_id)
					myList.push(new ExamType(elem))
				}
				//allCategLoaded.push([catName, this.managePdfCateg(myList, catName)]);
				allCategLoaded.push(this.managePdfCateg(myList, catName))
			}
		}

		// 07.07.2020
		return Promise.all(allCategLoaded)
	}

	// 07.07.2020
	private managePdfCateg(myList, catName) {
		// la myList contiene coppie id ed exam_type, quello che serve per la get singolo esame
		return this.session
			.loadCategoryExams(myList, catName, true)
			.then((doneCtg) => {
				var currentCategoryExams = null
				if (this.dataService.hasLoadedCategory(catName)) {
					var currentCategory = this.dataService.getCategory(catName)
					currentCategoryExams = currentCategory.examList
					Util.debug('(R) [managePdfCateg] ok load ' + catName)
					//Util.debug(doneCtg);
				} else {
					alert('(R) ko loading report images')
				}
				return currentCategoryExams
			})
			.catch((error) => {
				// 30.12.2021
				console.log('(managePdfCateg) error!')
				//console.log(error);
				throw error
			})
	}

	// 07.07.2020  su cornea
	private getPrintableCorneaImages(categoryRep, examlist) {
		var imagesR = categoryRep.getCorneaImages('right')
		var imagesL = categoryRep.getCorneaImages('left')

		var printable = this.filtraImmagini(examlist, imagesR, imagesL, Config.CAT_CORNEA)

		return printable
	}

	// 07.07.2020 max 3 external + 1 dryEye
	getPrintableAnteriorImages(categoryRep, examlist) {
		var imagesR = categoryRep.getAnteriorImages('right')
		var imagesL = categoryRep.getAnteriorImages('left')

		// 11.05.2022 solo per test
		if (examlist == null) {
			Util.debug('[R](getPrintableAnteriorImages) null examList !? ')
		} //else {
		//console.log("[R](getPrintableAnteriorImages)");
		//console.log(examlist);
		//}

		// 11.05.2022 FIX, ripristinato senza copy [ls]
		// 24.07.2020 test
		let printable = this.filtraImmagini(examlist, imagesR, imagesL, Config.CAT_ANTERIOR)
		//var printable = this.filtraImmagini(angular.copy(examlist), imagesR, imagesL, Config.CAT_ANTERIOR);
		//var printable = this.filtraImmagini({...examlist}, imagesR, imagesL, Config.CAT_ANTERIOR);

		return printable
	}

	// 26.08.2020 singola wf grid, tono high, pachy
	getPrintableGlcImages(categoryRep, examlist) {
		var imagesR = categoryRep.getGlcImages('right')
		var imagesL = categoryRep.getGlcImages('left')

		// 11.05.2022 FIX, ripristinato senza copy [ls]
		//var printable = this.filtraImmagini({...examlist}, imagesR, imagesL, Config.CAT_GLC);
		var printable = this.filtraImmagini(examlist, imagesR, imagesL, Config.CAT_GLC)

		return printable
	}

	// 28.09.2020 max 4 per occhio
	getPrintableFundusImages(categoryRep, examlist) {
		var imagesR: ExamImage[]
		var imagesL: ExamImage[]

		imagesR = categoryRep.getFundusImages('right')
		imagesL = categoryRep.getFundusImages('left')

		// 11.05.2022 FIX, ripristinato senza copy [ls]
		//var printable = this.filtraImmagini({...examlist}, imagesR, imagesL, Config.CAT_FUNDUS);
		var printable = this.filtraImmagini(examlist, imagesR, imagesL, Config.CAT_FUNDUS)

		return printable
	}

	// 10.07.2020 parametrica su immagini e categorie
	//private filtraImmagini(examlist, imagesR, imagesL, catName){
	//private filtraImmagini(examlist, imagesROrig: ExamImage[], imagesLOrig: ExamImage[], catName: string){
	private filtraImmagini(examlist: CategExam[], imagesR: ExamImage[], imagesL: ExamImage[], catName: string) {
		var totR = 0
		var totL = 0

		/* 30.12.2021 tolto la copia
    // 24.07.2020 copio gli array passati come param, test ?!?
    var imagesR :ExamImage[] = null;
    var imagesL :ExamImage[] = null;

    imagesR = {...imagesROrig};  // prev: angular.copy 
    imagesL = {...imagesLOrig};
    */

		if (imagesR != null) totR = imagesR.length
		if (imagesL != null) totL = imagesL.length

		Util.debug('(filtraImmagini) ' + catName + ' tot img R:' + totR + ' L:' + totL)

		// in base a catName sceglie l'array delle descrizioni da usare

		// ATT: mantenere stesso ordine di categories.ts
		// 1  "axial map";
		// 2  "elevation map";
		// 3  "tangential map";
		// 1  "pachy multi"

		var descrImgM = [] // immagini multiple, es Topo, External
		var descrImgS = [] // singola immagine, es pachyMult o Dryeye

		// 28.02.2022 problema: il pachy ha multiImmagini, ma ne esponiamo solo una

		// 10.06.2020 passare solo quelle che vanno stampate, annullo le altre
		// trovare modo meno macchinoso [ls]
		// prendo le immagini e le filtro in base a quelle scelte dal refertatore
		// TODO test veloce: se nessuna abilitata o tutte -> salto i cicli

		// 24.07.2020 BUG: se due esami con stesso id, quando cicla sul primo azzera le immagini del secondo!
		let isMulti = false // 24.07.2020 fix per probl. di cui sopra

		if (!examlist) {
			// 11.05.2022
			console.log('[R](filtraImmagini) null examList !? ') // non qui
		} else {
			//console.log("[R](filtraImmagini) tot: "+examlist.length);

			for (let i = 0; i < examlist.length; i++) {
				// di solito sono 4 esami, 2 dx e due sin
				//let ex: CategExam;
				let ex = examlist[i]

				//console.log("[R](filtraImmagini) pos. "+i);
				//console.log(ex);

				if (ex != null) {
					// qui non ho eye
					//console.log("[R](filtraImmagini) "+i+" exam "+ex.exam_id+" type: "+ex.exam_type);
					//console.log(ex);  // 10.07.2020   // 08.01.2021 tolta trace

					// 24.07.2020 patch, valorizzo qui le descr delle immagini, non sopra per categoria
					// annullo le descr delle immagini che non riguardano questo esame
					if (ex.exam_type == Config.EXM_DRYEYE) {
						isMulti = false
						descrImgS = Config.descrImgDryEye
						descrImgM = [] // vuoto
					} else if (ex.exam_type == Config.EXM_EXT) {
						descrImgS = []
						isMulti = true
						descrImgM = Config.descrImgExternal
					} else if (ex.exam_type == Config.EXM_TOPO) {
						descrImgS = []
						isMulti = true
						descrImgM = Config.descrImgTopo
					} else if (ex.exam_type == Config.EXM_PACHYMULT) {
						descrImgM = []
						isMulti = false
						descrImgS = Config.descrImgPachyMult

						// 30.09.2020 fix, mancavano questi 3 per GLC
					} else if (ex.exam_type == Config.EXM_WF) {
						descrImgM = []
						isMulti = false
						descrImgS = Config.descrImgWf
					} else if (ex.exam_type == Config.EXM_RETRO) {
						//30.05.2022 fix, ne mostriamo solo una ma sono 2 -> multi
						//descrImgM = [];
						//isMulti = false;
						//descrImgS = Config.descrImgRetro;
						descrImgS = []
						isMulti = true
						descrImgM = Config.descrImgRetro
					} else if (ex.exam_type == Config.EXM_PACHY) {
						// 28.02.2022 fix (bug 134), ne mostriamo solo una ma sono 2 -> multi
						//descrImgM = [];
						//isMulti = false;
						//descrImgS = Config.descrImgPachyS;
						descrImgS = []
						isMulti = true
						descrImgM = Config.descrImgPachyS
					} else if (ex.exam_type == Config.EXM_FUNDUS) {
						descrImgS = []
						isMulti = true
						descrImgM = Config.descrImgFundus
					}

					// il caso zero e' separato dagli altri perche' gestisce anche le singole
					if (ex.imgEnabled[0] == false) {
						for (var m = 0; m < totR; m++) {
							if (imagesR[m] != null && imagesR[m].examId == ex.exam_id) {
								//if(imagesR[m].descr == descrImgM[0] || imagesR[m].descr == descrImgS[0]){
								if ((isMulti && imagesR[m].descr == descrImgM[0]) || (!isMulti && imagesR[m].descr == descrImgS[0])) {
									//console.log("(R) exam "+i+" (sel0) ["+m+"] nascondo imgR "+imagesR[m].descr+" id "+ex.exam_id+" pos: "+imagesR[m].position);
									imagesR[m] = null
								}
							}
						}
						for (var j = 0; j < totL; j++) {
							if (imagesL[j] != null && imagesL[j].examId == ex.exam_id) {
								//if(imagesL[j].descr == descrImgM[0] || imagesL[j].descr == descrImgS[0]){
								if ((isMulti && imagesL[j].descr == descrImgM[0]) || (!isMulti && imagesL[j].descr == descrImgS[0])) {
									//console.log("(R) exam "+i+" (sel0) ["+j+"] nascondo imgL "+imagesL[j].descr+" id "+ex.exam_id+" pos: "+imagesL[j].position);
									imagesL[j] = null
								}
							}
						}
					} else {
						// 24.07.2020 test
						var descr = isMulti ? descrImgM[0] : descrImgS[0]
						//console.log("(R) la 0 e' enabled, exam_id "+ex.exam_id+" descr: "+descr);
					}

					// caso da 1 a 6 riguarda solo esami con foto multiple
					// per retine ho fino ad 8 fissazioni
					//var maxNum = 8;  // 29.09.2020 era 7
					var len = ex.imgEnabled.length
					//console.log("(R) max 8, len: "+len); // ok 8

					if (isMulti) {
						// 24.07.2020 aggiunto test
						//for(var sel=1; sel<6; sel++){ // 24.07.2020 fix ?!
						for (var sel = 1; sel < len; sel++) {
							if (ex.imgEnabled[sel] == false) {
								for (var m = 0; m < totR; m++) {
									if (imagesR[m] != null && imagesR[m].examId == ex.exam_id && imagesR[m].descr == descrImgM[sel]) {
										//console.log("(R) exam "+i+" (sel"+sel+") "+m+" nascondo img R "+imagesR[m].descr+" id "+ex.exam_id+" pos: "+imagesR[m].position);
										imagesR[m] = null
									}
								}
								for (var j = 0; j < totL; j++) {
									if (imagesL[j] != null && imagesL[j].examId == ex.exam_id && imagesL[j].descr == descrImgM[sel]) {
										//console.log("(R) exam "+i+" (sel"+sel+") "+j+" nascondo img L "+j+" "+imagesL[j].descr+" id "+ex.exam_id+" pos: "+imagesL[j].position);
										imagesL[j] = null
									}
								}
							} else {
								// 24.07.2020 test
								//console.log("(R) la "+sel+" e' enabled, exam_id "+ex.exam_id+" descr: "+descrImgM[sel]);
							}
						}
					}
				} else {
					Util.debug('[R](filtraImmagini) null elem examList[' + i + '] !? ') // 11.05.2022 non qui
				}
			}
		}

		// ricompatta gli array
		let showOnlyR = []
		let showOnlyL = []

		for (let m = 0; m < totR; m++) {
			if (imagesR[m] != null) {
				showOnlyR.push(imagesR[m])
			}
		}
		for (let j = 0; j < totL; j++) {
			if (imagesL[j] != null) {
				showOnlyL.push(imagesL[j])
			}
		}

		let printable = []
		printable[0] = showOnlyR
		printable[1] = showOnlyL

		// 29.09.2020 solo per test
		Util.debug('(R) imgs toShow R:' + showOnlyR.length + ' L:' + showOnlyL.length)

		return printable
	}

	// 12.06.2020 aggiorno lo stato del report, se era "new" -> "downloaded"
	updateReportStatus(repId, newStatus) {
		if (!this.reportList) return

		// per ora aggiorno solo in base all'optician, non se lo scaricano admin o ref
		if (!this.session.isLevel1()) return

		// intanto cambio la pg, poi invio al server, cosi' non serve refresh solo per un campo [ls]
		var myReport = null
		for (let i = 0; i < this.reportList.length; i++) {
			if (this.reportList[i].id == repId) {
				//console.log("(R) updateReportStatus:"+repId+" new status: "+newStatus+" prev: "+this.reportList[i].status);

				// 03.09.2020 se e' gia' uguale, non serve aggiornarlo
				if (this.reportList[i].status != newStatus) {
					this.reportList[i].status = newStatus
					myReport = this.reportList[i]
				} else {
					Util.debug('(R) updateReportStatus: nothing to do for ' + repId)
					myReport = null
				}
				break
			}
		}

		if (myReport != null) {
			// 28.04.2022
			//alert("(updateReportStatus) TODO");  // TEMP TODO 30.12.2021

			this.session.updateReport(myReport).then((response) => {
				//console.log(response);  // 14.10.2020 tolta trace
				// 28.04.2022 se arriva qui e' ok, altrimenti cade nel catch
				//if(response.status == 204 || response.status == 200) {
				Util.debug('(updateReportStatus) OK done for rep ' + myReport.id)
				this.updatedRepStatus = true
				// poi lo passo a visits nella close del modal, x cambiare il newReportCount

				//}
				//}
			})
		}
	}
	filterText() {
		this.reportPref.filter = this.input.nativeElement.value

		this.reportPref.filter = this.reportPref.filter.trim().toLocaleLowerCase()

		this.reportListTable.filter = this.reportPref.filter
	}
	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportPref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.reportPref.itemsPerPage))
	}
}

import { Config } from '../../config'

import * as forge from 'node-forge' // per le keybox

import { AdmSettings, Settings } from './settings.model'
import { Address } from './address.model'
import { CsvLine } from './csvLine.model'
import { Util } from './util.model'
import { DateParser } from './dateParser.model'
import { Agreement } from './agreement.model'
import { CryptoBag, CryptoUtilsService, dataType, postMessageToWorker, workerFunctionType } from '../service/crypto-utils.service'

// 13.05.2022 todo rinominare come "remotes"
// e poi semmai fare extend per la class specialist, vd sotto ?!

// 02.08.2023 TODO estendere CoreUser per uniformare i metodi [ls]
// level2 user, anche per i refertatori, specialist
export class Distrib {
	id: string // sul DB e' user_id
	user_id: number
	name: string // mette insieme
	user_type: string
	firstname: string
	lastname: string

	code: string

	// TODO, usare oggetto Address, come per class doctor
	mainAddress: Address // 29.08.2022
	addresses: Address[]
	country: string

	// address: string
	// city: string
	// email: string // sul server, ref_email
	// phone: string
	// organization: string

	subscriptionTime: Date // in teoria viene valorizzata con la prima login...
	creationDate: Date // 30.08.2022

	doctorCount: number
	created_by: number // 08.08.2018
	is_deleted: string // 23.08.2018

	user_subtype: string // sottotipo dal DB, ATT a non confondere i campi!
	is_test: string // 21.02.2022  Y or N

	super_salt: string // 30.05.2023 per le cliniche
	groupId: number

	isActive: boolean // first login done or not or deleted
	status: string // 19.06.2023 vale 'active' o 'disabled'
	isEnabled: boolean

	username: string
	keybox_admin: string
	key_distrib: forge.util.ByteStringBuffer // 22.06.2022

	order_reg_num: string // 15.05.2018
	licence_num: string // xx.05.2024
	display_name: string // 07.06.2023
	signature: string
	signature_name: string

	settings: Settings // 07.03.2019 aggiunti settings, serve il brand
	admSettings: AdmSettings // 25.10.2021 per specialisti, categories

	public_key: string // 07.06.2023

	tot_reports_done: number

	isDecrypted: boolean

	myMiniGraders: Distrib[] //usato nella remotelist
	relations: Relation[]
	loadRelationsComplete: boolean

	acceptedAgreements: Agreement[]
	loadAgreementsComplete: boolean

	//static idGen = 0  // 07.06.2023 a cosa serve ?

	constructor() {
		this.id = ''
		this.user_id = 0
		this.username = ''
		this.user_type = ''
		this.name = ''
		this.firstname = ''
		this.lastname = ''
		this.code = ''

		this.country = ''

		// this.address = ''
		// this.city = ''
		// this.email = ''
		// this.phone = ''
		// this.organization = ''

		this.order_reg_num = '' // 31.08.2022

		this.is_test = 'N'

		this.isActive = false

		//this.user_subtype = Config.SUB_STD // 30.08.2022
		this.user_subtype = Config.SUB_UNKNOWN // 18.01.2023 meglio generico, se non si sa

		this.super_salt = ''
		this.groupId = 0

		this.mainAddress = new Address()
		this.addresses = []
		this.settings = new Settings()
		this.admSettings = new AdmSettings()

		this.public_key = '' // meglio null ?
		this.status = 'active'
		this.isEnabled = true

		this.display_name = ''
		this.signature = null
		this.signature_name = ''
		this.tot_reports_done = 0

		this.isDecrypted = false

		this.myMiniGraders = []
		this.relations = []
		this.loadRelationsComplete = false

		this.acceptedAgreements = []
		this.loadAgreementsComplete = false
	}

	// 23.09.2021
	getName() {
		var ret = this.name // gia' combinato
		if (ret == '- -') {
			//ret = "usr_" + this.code;
			if (this.user_type == Config.PR_SPECIALIST) ret = 'Spec_' + this.code
			else ret = 'Distr_' + this.code
		}
		return ret
	}

	public isSpecialist() {
		return this.user_type == Config.PR_SPECIALIST
	}

	public isDistributor() {
		return this.user_type == Config.PR_DISTRIB
	}

	// 14.06.2023
	isClinicAdmin() {
		return this.user_type == Config.PR_CLINIC // ClinicAdmin
	}

	// 19.10.2021
	public getSzDiagnosisGrp(): string {
		var ret = '-'
		if (this.settings) {
			var grp = this.settings.diagnosis_group
			ret = Util.formatDiagnosisGroup(grp)
		}
		return ret
	}

	public getDiagnosisGrp(): number {
		var ret = 0
		if (this.settings) {
			ret = this.settings.diagnosis_group
		}
		return ret
	}

	// 15.06.2022
	public getBrand() {
		let ret = ''
		if (this.settings) {
			ret = this.settings.brand
		}
		return ret
	}

	// 30.05.2023
	public getMail() {
		let ret = ''
		if (this.mainAddress && this.mainAddress.ref_email) {
			ret = this.mainAddress.ref_email
		}
		return ret
	}

	// 30.05.2023
	public getPhone() {
		let ret = ''
		if (this.mainAddress) {
			ret = this.mainAddress.phone1 ? this.mainAddress.phone1 : ''
		}
		return ret
	}

	public getCity() {
		let ret = ''
		if (this.mainAddress) {
			ret = this.mainAddress.city ? this.mainAddress.city : ''
		}
		return ret
	}

	// 31.08.2022
	public getCountry(): string {
		let ret = ''

		// 06.03.2023 precedenza al campo in chiaro
		if (this.country && this.country != '') {
			ret = this.country
		} else if (this.mainAddress) {
			ret = this.mainAddress.country
		}
		//if (this.mainAddress) ret = this.mainAddress.country
		//else if (this.country && this.country.length > 0) ret = this.country

		return ret
	}

	//15.01.2024
	public getOrganization() {
		let ret = ''
		if (this.mainAddress) {
			ret = this.mainAddress.organization ? this.mainAddress.organization : ''
		}
		return ret
	}

	public getDisplayName() {
		let ret = ''
		if (this.display_name) {
			ret = this.display_name
		}
		return ret
	}

	// 25.10.2021 solo per specialisti
	getEnabledCategories() {
		var ret = ''

		if (this.isDistributor()) return ''

		if (this.admSettings != null) {
			ret = this.admSettings.categories
		}

		return ret
	}

	// 07.06.2023
	public hasPublicKey() {
		return this.public_key != null && this.public_key != ''
	}

	// 30.05.2023 esteso per gestire i clinicAdmin
	// 16.11.2017 aggiunto parametro per ignore critt
	static createDistrib(rawDistrib, cryptoUtils: CryptoUtilsService, goodPwd: string, ignoreCritt: boolean, superKeyBox?): Promise<Distrib> {
		const promise = new Promise<Distrib>((resolve, reject) => {
			var result = new Distrib()

			//16.03.2020 solo per test
			// console.log(rawDistrib)

			// 30.05.2023 copia i campi con nomi uguali, poi sistemo le date e i campi con nomi diversi
			if (rawDistrib) {
				var myJsonObj = { ...rawDistrib }
				if (myJsonObj != null) {
					Object.assign(result, myJsonObj)
				}
			}

			result.id = rawDistrib.user_id // NB nomi campi diversi! id e' stringa, user_id e' number

			//Util.debug('(createDistrib) '+result.id+' status: ' + result.status)
			result.isEnabled = result.status == 'active'

			// 07.06.2023 aggiunto dalle api
			// Access counter non sembra esistere qua
			let myNum = 9
			if (rawDistrib.access_counter != null) {
				myNum = parseInt(rawDistrib.access_counter)

				//console.log(rawDistrib.access_counter)
				//console.log(myNum)
			}

			if (myNum < 2) {
				result.isActive = false
			} else {
				result.isActive = true
			}

			if (rawDistrib.is_deleted == 'Y') {
				// 30.03.2017
				result.code = 'DEL_' + rawDistrib.code
				result.isActive = false
			} else {
				result.code = rawDistrib.code
			}

			result.doctorCount = rawDistrib.doctors_count

			// 25.05.2019  es: 2017-02-23T12:34:05.000Z
			result.subscriptionTime = DateParser.parseDate(rawDistrib.subscription_time)

			// 30.08.2022
			if (rawDistrib.creation_date) {
				result.creationDate = DateParser.parseDate(rawDistrib.creation_date)
			}

			//Util.debug('(createDistrib) - order_reg_num: ' + rawDistrib.order_reg_num)

			// if (rawDistrib.username) {
			// 	// passa di qui quando fa la lista
			// 	result.username = rawDistrib.username

			// 	if (rawDistrib.keybox_admin) {
			// 		// 09.03.2021
			// 		result.keybox_admin = rawDistrib.keybox_admin
			// 	}
			// } else

			// passa di qui con il get singolo
			if (rawDistrib.user_access) {
				result.username = rawDistrib.user_access.username
				result.keybox_admin = rawDistrib.user_access.keybox_admin
				result.public_key = rawDistrib.user_access.public_key // 07.06.2023

				//Util.debug('(createDistrib) id ' + result.id + ' public key ? ' + result.hasPublicKey())
			}

			if (result.isSpecialist()) {
				// user_type == Config.PR_SPECIALIST) {
				// 'Specialist'
				result.name = 'Spec_' + rawDistrib.code
			} else if (result.isClinicAdmin()) {
				result.name = 'ClAdm_' + rawDistrib.code // 14.06.2023
			} else {
				result.name = 'Distr_' + rawDistrib.code
			}

			// 12.10.2017
			if (rawDistrib.setting) {
				//result.settings = rawDistrib.setting;      // 07.03.2019
				result.settings = new Settings(rawDistrib.setting) // 15.09.2022 meglio
			}

			if (rawDistrib.setting_admin) {
				// 25.10.2021
				result.admSettings = new AdmSettings(rawDistrib.setting_admin)
				//console.log('(createSpec) enabled categs: ' + rawDistrib.setting_admin.categories)
			}

			if (!goodPwd) {
				//console.log('(createDistrib) missing pwd, cannot decrypt')
				resolve(result)
				return
			}

			// 30.05.2023 esteso per gestione clinicAdmin
			if (!result.keybox_admin && !superKeyBox) {
				Util.debug('(createDistrib) missing keys, ignore was ? ' + ignoreCritt) // passa sempre di qui quando fa la lista
				resolve(result)
				return
			}

			// 16.11.2017 esco subito, carichera' le key dopo - TODO
			if (ignoreCritt != null && ignoreCritt == true) {
				Util.debug('(createDistrib) - solo dati in chiaro per distr ' + rawDistrib.code)
				resolve(result)
				return
			}

			// 15.06.2023 patch per i deleted, la username e' alterata -> fallisce il decrypt
			if (result.isDeleted()) {
				Util.debug('(createDistrib) id: ' + result.user_id + ' is deleted, no decrypt')
				resolve(result)
				return
			}

			// 30.05.2023 ******* gestione miniC ***************************************************
			let mySalt = ''
			let keyBoxDati = null

			if (superKeyBox) {
				// by clinicAdmin
				keyBoxDati = superKeyBox
			} else {
				// by admin
				keyBoxDati = result.keybox_admin
			}

			if (result.isMini()) {
				//  per i miniC, devo usare come salt la username del clinicAdmin
				mySalt = result.super_salt.toLowerCase()
			} else {
				// per typeC ordinari
				mySalt = result.username.toLowerCase() // 29.04.2020 per salare la pwd_key
			}

			if (result.username.indexOf('DEL') == 0) {
				mySalt = result.username.split('_')[2]
			}

			//Util.debug('(createDistrib) id: ' + result.id + ' is clinic ? ' + result.isClinic() + ' salt: ' + mySalt)

			// **********************************************************

			// TEST
			var myBagArray: CryptoBag[] = []

			let fieldToDecrypt: string[] = Config.fieldToDecrypt // 'firstname', 'lastname', 'display_name', 'signature', 'signature_name', 'logo'
			let bag = cryptoUtils.generateBag()

			for (let field of fieldToDecrypt) {
				if (rawDistrib[field]) {
					bag[field] = rawDistrib[field]
				}
			}
			//aggiungo anche l'address nella stessa bag

			myBagArray.push(bag)
			let addrArray: Address[]
			if (rawDistrib.addresses && rawDistrib.addresses[0]) {
				addrArray = rawDistrib.addresses

				let fieldToDecryptAddr: string[] = Config.fieldToDecryptAddr //'address_line1', 'city', 'province', 'zip', 'country', 'phone1', 'ref_email', 'organization', 'vat'

				for (let addr of addrArray) {
					let myBagAddr = cryptoUtils.generateBag()

					for (let field of fieldToDecryptAddr) {
						if (addr[field]) {
							myBagAddr[field] = addr[field]
						}
					}
					cryptoUtils.purge(myBagAddr)
					myBagArray.push(myBagAddr)
				}
			}
			// console.log(myBagArray)

			let promises = []
			for (let bag of myBagArray) {
				let data: postMessageToWorker = new postMessageToWorker()
				data.type = workerFunctionType.decryptDataAllInOne
				data.password = goodPwd
				data.keyBox = keyBoxDati
				data.mySalt = mySalt
				data.dataToDecrypt = bag
				data.dataType = dataType.CryptoBag
				data.dataToDecrypt = bag
				promises.push(cryptoUtils.decryptDataAllInOneWorker(data))
			}
			// console.log(promises)
			Promise.all(promises)
				.then((bags) => {
					for (let field of fieldToDecrypt) {
						if (bags[0][field]) {
							result[field] = bags[0][field]
						}
					}

					if (result.licence_num) {
						result.order_reg_num = result.licence_num
					}

					var docName = Util.getFullName(bags[0]['firstname'], bags[0]['lastname'])
					if (docName) {
						//console.log("(createDistrib) ok decrypt, name: "+docName);
						result.name = docName // else tengo il default, con l'id --ls
					}

					result.addresses = []

					if (rawDistrib.addresses && rawDistrib.addresses[0]) {
						let fieldClearAddr: string[] = Config.fieldClearAddr

						for (let n = 0; n < bags.length - 1; n++) {
							const bag = bags[n + 1]

							for (let i = 0; i < fieldClearAddr.length; i++) {
								const field = fieldClearAddr[i]

								if (addrArray[n][field]) {
									bag[field] = addrArray[n][field]
								}
							}
							// console.log(bag)
							result.addresses.push(Address.initFromBag(bag))
						}
					}
					result.mainAddress = result.addresses[0]
					result.isDecrypted = true
					// // console.log(result)
					resolve(result)
					return
				})
				.catch((err) => {
					console.log(err)
					console.log('(createDistrib) ko decryptDataAllInOneWorker user ' + result.id)
					reject(err)
					return
				})

			// let data: postMessageToWorker = new postMessageToWorker()
			// data.type = workerFunctionType.decryptDataAllInOne
			// data.password = goodPwd
			// data.keyBox = keyBoxDati
			// data.mySalt = mySalt
			// data.dataToDecrypt = bag
			// data.dataType = dataType.CryptoBag

			// cryptoUtils
			// 	.decryptDataAllInOneWorker(data)
			// 	.then((bag: CryptoBag) => {
			// 		console.log(bag)

			// 		for (let field of fieldToDecrypt) {
			// 			if (bag[field]) {
			// 				result[field] = bag[field]
			// 			}
			// 		}

			// 		if (result.licence_num) {
			// 			result.order_reg_num = result.licence_num
			// 		}

			// 		var docName = Util.getFullName(bag['firstname'], bag['lastname'])
			// 		if (docName) {
			// 			//console.log("(createDistrib) ok decrypt, name: "+docName);
			// 			result.name = docName // else tengo il default, con l'id --ls
			// 		}

			// 		result.addresses = [] // con objassign viene compiato addresses crittato dal rawDistrib

			// 		let addrArray: Address[]

			// 		if (rawDistrib.addresses && rawDistrib.addresses[0]) {
			// 			addrArray = rawDistrib.addresses

			// 			let fieldToDecryptAddr: string[] = Config.fieldToDecryptAddr //'address_line1', 'city', 'province', 'zip', 'country', 'phone1', 'ref_email', 'organization', 'vat'

			// 			var myBagAddrArray: CryptoBag[] = []
			// 			for (let addr of addrArray) {
			// 				let myBagAddr = cryptoUtils.generateBag()

			// 				for (let field of fieldToDecryptAddr) {
			// 					if (addr[field]) {
			// 						myBagAddr[field] = addr[field]
			// 					}
			// 				}
			// 				cryptoUtils.purge(myBagAddr)
			// 				myBagAddrArray.push(myBagAddr)
			// 			}
			// 		}

			// 		if (myBagAddrArray && myBagAddrArray.length > 0) {
			// 			let promises = []
			// 			for (let bagAddr of myBagAddrArray) {
			// 				data.dataToDecrypt = bagAddr
			// 				promises.push(cryptoUtils.decryptDataAllInOneWorker(data))
			// 			}

			// 			Promise.all(promises).then((bags) => {
			// 				console.log(bags)

			// 				let fieldClearAddr: string[] = Config.fieldClearAddr

			// 				for (let n = 0; n < bags.length; n++) {
			// 					const bag = bags[n]

			// 					for (let i = 0; i < fieldClearAddr.length; i++) {
			// 						const field = fieldClearAddr[i]

			// 						if (addrArray[n][field]) {
			// 							bag[field] = addrArray[n][field]
			// 						}
			// 					}
			// 					result.addresses.push(Address.initFromBag(bag))
			// 				}
			// 				result.mainAddress = result.addresses[0]

			// 				result.isDecrypted = true
			// 				// console.log(result)
			// 				resolve(result)
			// 			})
			// 		} else {
			// 			Util.debug('(createDistrib) no address array')
			// 			result.addresses.push(new Address(bag))

			// 			result.mainAddress = new Address(bag)
			// 			result.isDecrypted = true
			// 			console.log(result)
			// 			resolve(result)
			// 		}
			// 	})
			// 	.catch((err) => {
			// 		console.log('(createDistrib) ko decryptDataAllInOneWorker user ' + result.id)
			// 		console.log(err)
			// 		reject(err)
			// 	})

			// END TEST

			// cryptoUtils
			// 	.decryptDataWithPwdS(goodPwd, keyBoxDati, mySalt)
			// 	.then((myKeyPhoto) => {
			// 		// var localKeyPhoto = angular.copy(myKeyPhoto);
			// 		var localKeyPhoto = new forge.util.ByteStringBuffer(myKeyPhoto)

			// 		// ha lunghezza 32 anche con vecchio modo  = { ...myKeyPhoto } boh
			// 		// if (!localKeyPhoto || localKeyPhoto.data.length < 64) {
			// 		// 	console.log('(createDistrib) ko keyPhoto')
			// 		// 	resolve(result)
			// 		// 	return
			// 		// }

			// 		let fieldToDecrypt: string[] = Config.fieldToDecrypt // 'firstname', 'lastname', 'display_name', 'signature', 'signature_name', 'logo'
			// 		var bag = cryptoUtils.generateBag()

			// 		for (let field of fieldToDecrypt) {
			// 			if (rawDistrib[field]) {
			// 				bag[field] = rawDistrib[field]
			// 			}
			// 		}

			// 		let addrArray: Address[]

			// 		if (rawDistrib.addresses && rawDistrib.addresses[0]) {
			// 			addrArray = rawDistrib.addresses

			// 			let fieldToDecryptAddr: string[] = Config.fieldToDecryptAddr //'address_line1', 'city', 'province', 'zip', 'country', 'phone1', 'ref_email', 'organization', 'vat'

			// 			var myBagAddrArray: CryptoBag[] = []
			// 			for (let addr of addrArray) {
			// 				let myBagAddr = cryptoUtils.generateBag()

			// 				for (let field of fieldToDecryptAddr) {
			// 					if (addr[field]) {
			// 						myBagAddr[field] = addr[field]
			// 					}
			// 				}
			// 				cryptoUtils.purge(myBagAddr)
			// 				myBagAddrArray.push(myBagAddr)
			// 			}
			// 		}

			// 		cryptoUtils.decryptDataWithKey(localKeyPhoto, bag).then((bag) => {
			// 			for (let field of fieldToDecrypt) {
			// 				if (bag[field]) {
			// 					result[field] = bag[field]
			// 				}
			// 			}

			// 			if (result.licence_num) {
			// 				result.order_reg_num = result.licence_num
			// 			}

			// 			var docName = Util.getFullName(bag['firstname'], bag['lastname'])
			// 			if (docName) {
			// 				//console.log("(createDistrib) ok decrypt, name: "+docName);
			// 				result.name = docName // else tengo il default, con l'id --ls
			// 			}

			// 			result.addresses = [] // con objassign viene compiato addresses crittato dal rawDistrib

			// 			if (myBagAddrArray && myBagAddrArray.length > 0) {
			// 				let promises = []
			// 				for (let bagAddr of myBagAddrArray) {
			// 					promises.push(cryptoUtils.decryptDataWithKey(myKeyPhoto, bagAddr))
			// 				}

			// 				Promise.all(promises)
			// 					.then((bags) => {
			// 						// console.log(bags)

			// 						let fieldClearAddr: string[] = Config.fieldClearAddr

			// 						for (let n = 0; n < bags.length; n++) {
			// 							const bag = bags[n]

			// 							for (let i = 0; i < fieldClearAddr.length; i++) {
			// 								const field = fieldClearAddr[i]

			// 								if (addrArray[n][field]) {
			// 									bag[field] = addrArray[n][field]
			// 								}
			// 							}
			// 							result.addresses.push(Address.initFromBag(bag))
			// 						}
			// 						result.mainAddress = result.addresses[0]

			// 						result.isDecrypted = true
			// 						// console.log(result)
			// 						resolve(result)
			// 					})
			// 					.catch((err) => {
			// 						console.log(err)
			// 						resolve(result)
			// 					})
			// 			} else {
			// 				Util.debug('(createDistrib) no address array')
			// 				result.addresses.push(new Address(bag))

			// 				result.mainAddress = new Address(bag)
			// 				result.isDecrypted = true

			// 				resolve(result)
			// 			}
			// 		})
			// 	})
			// 	.catch((err) => {
			// 		console.log('(createDistrib) ko decryptBag user ' + result.id)
			// 		console.log(err)
			// 		resolve(result)
			// 	})
		})

		return promise
	}

	// 16.11.2017 aggiunto parametro per ignorare decrypt
	// non piú usata
	static createDistribList(response: DistributorsResponse, cryptoUtils, goodPwd, ignoreCritt, superKeyBox, decrittMiniC?): Promise<Distrib[]> {
		const promise = new Promise<Distrib[]>(async (resolve, reject) => {
			var result = []
			//let myList =  response.data.data;
			let myList = response.data // .distributors;  // 03.05.2022

			Util.debug('(createDistribList) ... valid box ? ' + (superKeyBox != null))

			//console.log("(createDistribList)");

			for (var i = 0; i < myList.length; i++) {
				var dist = myList[i]
				result.push(await Distrib.createDistrib(dist, cryptoUtils, goodPwd, ignoreCritt, superKeyBox))
				//console.log("(createDistribList) doc "+i+" ok");
			}

			console.log('(createDistribList) fine ciclo, tot: ' + i)
			//return cryptoUtils.q.all(result);
			Promise.all(result).then((distribList) => {
				resolve(distribList)
			})
		})
		return promise
	}

	isAllAddressesValid(): boolean {
		let ret = false
		for (let addr of this.addresses) {
			ret = addr.isAddressValid()
		}
		return ret
	}

	allRelationsValid(): { resp: boolean; msg: string } {
		let ret: { resp: boolean; msg: string } = { resp: false, msg: '' }
		ret.resp = false
		// se clinic admin, questi controlli devo farli sui mini
		// se specialist normale, questi controlli devo farli sulle sue relationi

		if (this.isClinicAdmin()) {
			ret.msg = 'RELATIONS.SPEC_MODEL.REL_WRONG1'

			let activeMinis = this.myMiniGraders.filter((el) => el.isActive == true)

			if (this.myMiniGraders.length == 0 && this.relations.length > 0) {
				ret.msg = 'RELATIONS.SPEC_MODEL.REL_WRONG2'
				return ret
			}

			for (let mini of activeMinis) {
				if (mini.doctorCount == this.doctorCount) {
					if (mini.display_name && mini.display_name != '' && mini.order_reg_num && mini.order_reg_num != '') {
						for (let minirel of mini.relations) {
							if (minirel.is_valid == 'Y' && minirel.display_name && minirel.display_name != '') {
								ret.resp = true
								ret.msg = 'RELATIONS.SPEC_MODEL.REL_OK1'
							}
						}
					}
				} else {
					ret.resp = false
					ret.msg = 'RELATIONS.SPEC_MODEL.REL_WRONG3'
					break
				}
			}
		} else {
			ret.msg = 'RELATIONS.SPEC_MODEL.REL_WRONG4'
			//controllo che per ogni relazione ci sia la firma, e che ci sia a livello distrib il display_name e licence number
			if (this.display_name && this.display_name != '' && this.order_reg_num && this.order_reg_num != '') {
				if (this.relations.length == 0) {
					ret.msg = 'RELATIONS.SPEC_MODEL.REL_WRONG5'
					return ret
				}

				for (let rel of this.relations) {
					if (rel.is_valid == 'Y' && rel.display_name && rel.display_name != '') {
						ret.resp = true
						ret.msg = 'RELATIONS.SPEC_MODEL.REL_OK1'
					}
				}
			} else {
				ret.msg = 'RELATIONS.SPEC_MODEL.REL_WRONG6'
			}
		}

		return ret
	}

	isAllDocumentAccepted(): { resp: boolean; msg: string } {
		let ret: { resp: boolean; msg: string } = { resp: false, msg: '' }
		ret.resp = false
		ret.msg = 'RELATIONS.SPEC_MODEL.DOC_WRONG1'

		if (this.isClinicAdmin()) {
			ret.resp = this.acceptedAgreements.filter((el) => el.is_valid == 'Y' && el.doc_type == Config.AGR_PRIVACY && el.target == 'clinicAdmin').length > 0 // ATTENZIONE NON SI PUÓ USARE Config.PR_CLINIC XK Config.PR_CLINIC == ClinicAdmin mentre qui arriva clinicAdmin

			if (this.acceptedAgreements.filter((el) => el.is_valid == 'Y' && el.target != 'clinicAdmin').length > 0) {
				ret.resp = false
				ret.msg = 'RELATIONS.SPEC_MODEL.DOC_WRONG2'
			}
		} else {
			ret.resp = this.acceptedAgreements.filter((el) => el.is_valid == 'Y' && (el.doc_type == Config.AGR_PRIVACY || el.doc_type == Config.AGR_TERMS)).length > 1
		}

		if (ret.resp) {
			ret.msg = 'RELATIONS.SPEC_MODEL.DOC_OK'
		}

		return ret
	}

	// 23.08.2018
	public isDeleted() {
		return this.is_deleted == 'Y'
	}

	// 08.2022
	public isTest() {
		return this.is_test == 'Y'
	}

	// per specialisti, scelto dall'optician, non partner visionix
	public isPrivate() {
		return this.user_subtype == Config.SUB_PRIVATE
	}

	public isVX() {
		return this.user_subtype == Config.SUB_STD
	}

	// 02.08.2023
	public isCompany() {
		return this.user_subtype == Config.SUB_COMPANY
	}

	public isMini() {
		//Util.debug('(distrib) - subtype: ' + this.user_subtype);
		return this.user_subtype == Config.SUB_MINI
	}

	// un clinc Grader ha il subtype mini e il gruppo non zero
	public isClinic() {
		return this.isMini() // || this.group > 0)
	}

	// 05.10.2022 TODO tradurre
	public getSubType() {
		let ret = '-'
		if (this.isPrivate()) {
			ret = 'Private'
		} else if (this.isVX()) {
			ret = 'VX'
		} else if (this.isMini()) {
			ret = 'Clinic'
		} else if (this.isCompany()) {
			// 02.08.2023
			ret = 'Company'
		}

		// 14.06.2023 patch
		if (this.isClinicAdmin()) {
			ret = 'ClinicAdmin'
		}

		return ret
	}

	// 21.02.2022 ritorna T solo se true
	getTestLabel() {
		let ret = ''
		if (this.is_test == 'Y') {
			ret = '[T]'
		}
		return ret
	}

	// 10.08.2022 per specialist activation coincide con creation ? NO
	public getCreationDt(): string {
		let ret = ' '

		if (this.creationDate)
			// 31.08.2022 miglioria
			ret = DateParser.formatSqlDate(this.creationDate)

		return ret
	}

	// 31.08.2022 activation e' la first login, su web
	public getSubscriptionDt(): string {
		let ret = ' '

		if (this.subscriptionTime) ret = DateParser.formatSqlDate(this.subscriptionTime)

		return ret
	}

	// 09.03.2021
	getCsvLine(type: string) {
		var ret = ''

		// export da pg statistiche
		if (!type || type == 'anagr') {
			// prettier-ignore
			ret =
				this.id + CsvLine.SEP +
				this.username + CsvLine.SEP +
				this.user_type + CsvLine.SEP +
				this.user_subtype + CsvLine.SEP +
				//this.email + CsvLine.SEP +   // 31.08.2022 no per GDPR
				this.getCountry() + CsvLine.SEP +
				//' ' + CsvLine.SEP + // space - not for specialist
				this.getCreationDt() + CsvLine.SEP +
				this.getSubscriptionDt() + CsvLine.SEP +
				(this.isTest() ? 'test' : ' ') + CsvLine.SEP; // 04.08.2022
		}
		return ret
	}

	// used for export anagr from statistics, also for lev1 users, TODO move
	static getCsvTitle(type?) {
		let ret = ''
		if (!type || type == 'anagr') {
			// prettier-ignore
			ret =
				"ID" + CsvLine.SEP +
				"username" + CsvLine.SEP +
				"type" + CsvLine.SEP +
				"subtype" + CsvLine.SEP +
				//"email" + CsvLine.SEP +   // not GDPR compliant  30.08.2022
				"country" + CsvLine.SEP +
				//"space" + CsvLine.SEP +    // 13.09.2022
				//"space in MB" + CsvLine.SEP + // 13.09.2022
				"created" + CsvLine.SEP +
				"activated" + CsvLine.SEP +
				"flag_test" + CsvLine.SEP; // 04.08.2022
		}
		return ret
	}

	// 13.05.2022
	getMeAsReferrer(currRel: Relation): Referrer {
		if (!this.isSpecialist() || currRel == null) {
			return null
		}

		let myId = parseInt(this.id)
		let relId = parseInt(currRel.distrib_id)
		if (relId != myId) {
			//relazione sbagliata
			return null
		}

		let meAsRef = new Referrer(myId)

		meAsRef.order_num = this.order_reg_num
		meAsRef.display_name = currRel.display_name

		if (currRel.signature != null && currRel.signature.length > 3) {
			meAsRef.signature = currRel.signature
			meAsRef.hasSignature = true
		}

		return meAsRef
	}

	getCloserLocation(docMainAddress: Address): {
		indx: number
		distance: number
		city: string
	} {
		// console.log('(SpecialistModel) - getCloserLocation')

		let docLat = docMainAddress.latitude
		let docLng = docMainAddress.longitude

		let ret = { indx: 0, distance: -1, city: '' }

		if (this.addresses.length == 1) {
			ret.indx = 0

			if (this.addresses[0].isAddressValid() && docMainAddress.isAddressValid()) {
				ret.distance = this.getDistanceFromLatLonInKm(docLat, docLng, this.addresses[0].latitude, this.addresses[0].longitude)
				ret.city = this.addresses[0].city
			}
		} else {
			for (let i = 0; i < this.addresses.length; i++) {
				const addr = this.addresses[i]

				if (addr.isAddressValid() && docMainAddress.isAddressValid()) {
					let distance = this.getDistanceFromLatLonInKm(docLat, docLng, addr.latitude, addr.longitude)

					if (ret.distance == -1 || distance < ret.distance) {
						ret.distance = distance
						ret.indx = i
						ret.city = addr.city
					}
				}
			}
		}

		return ret
	}

	// https://stackoverflow.com/questions/18883601/function-to-calculate-distance-between-two-coordinates
	private getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2): number {
		var R = 6371 // Radius of the earth in km
		var dLat = this.deg2rad(lat2 - lat1) // deg2rad below
		var dLon = this.deg2rad(lon2 - lon1)
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
		var d = R * c // Distance in km
		return d
	}

	private deg2rad(deg) {
		return deg * (Math.PI / 180)
	}
}

// 29.05.2017 info specialist per report farmacista
export class Specialist {
	relation_id: number
	distributor_id: string
	display_name: string // 20.09.2021 al momento e' legato alla relazione con lev1, TODO spostare
	affiliation_date: string
	affiliationDate: Date // 02.03.2023
	created_by: number
	user_type: string
	signature: string // 12.07.2017 e' l'immagine della firma, crittata con keyPhoto del farmacista
	rel_status: string
	//valid_dn : boolean;
	order_reg_num: string // 16.05.2018
	user_subtype: string
	username: string // 17.10.2022 serve su export crediti
	vacancies: {
		id: number
		user_id: number
		from_date: string
		to_date: string
	}[]

	// 13.05.2019 - 29.09.2022 not used
	//report_type: string;  // uno dei valori in Config.reportTypes
	//report_subtype: number;

	// 15.07.2020 esteso costruttore
	//constructor() {}
	constructor(myId?) {
		this.relation_id = 0
		this.distributor_id = '0'
		this.created_by = 0
		this.user_type = Config.PR_SPECIALIST // "Specialist";
		this.display_name = ''
		this.order_reg_num = ''
		this.user_subtype = Config.SUB_STD // 29.09.2022
		this.username = ''
		this.vacancies = []
		this.rel_status = ''
		this.affiliation_date = ''

		if (myId) {
			this.distributor_id = myId
			this.display_name = 'Specialist ' + myId
		}
	}

	// 15.06.2017
	static createSpecialist(rawSpecialist, cryptoUtils, goodKey): Promise<Specialist> {
		// console.log(rawSpecialist)
		let specialist = new Specialist(rawSpecialist.distributor_id)

		// 02.03.2023
		let myJsonObj = { ...rawSpecialist }
		if (myJsonObj != null) {
			Object.assign(specialist, myJsonObj)
		}

		if (rawSpecialist.affiliation_date) {
			specialist.affiliationDate = DateParser.parseDate(rawSpecialist.affiliation_date)
		}

		if (rawSpecialist.display_name) {
			if (rawSpecialist.user_type) {
				if (rawSpecialist.user_type == Config.PR_SPECIALIST) {
					specialist.display_name = 'Specialist ' + rawSpecialist.distributor_id // se ko decritt
				} else if (rawSpecialist.user_type == Config.PR_DISTRIB) {
					specialist.display_name = '-'
				}
			} else {
				// mai qui, ci sono solo 2 tipi
				specialist.display_name = 'Spec. ' + rawSpecialist.distributor_id // se ko decritt
			}
		} else {
			specialist.display_name = ''
		}

		Util.debug('(createSpecialist) step 1')

		// 20.10.2022 patch per lista pro credits
		if (!cryptoUtils) {
			return Promise.resolve(specialist)
		}

		let bag = cryptoUtils.generateBag()
		bag['display_name'] = rawSpecialist.display_name
		bag['order_reg_num'] = rawSpecialist.licence_num

		cryptoUtils.purge(bag) // 08.05.2024 added purge --ls

		return cryptoUtils
			.decryptDataWithKey(goodKey, bag)
			.then((decrittData) => {
				// ok qui nella load profile iniziale, per utenti lev1
				if (decrittData) {
					Util.debug(
						'(createSpecialist) ok displName: ' + decrittData['display_name'] + ' id: ' + specialist.distributor_id + ' order_num: ' + decrittData['order_reg_num']
					)
					specialist.display_name = decrittData['display_name'] // in chiaro
					specialist.order_reg_num = decrittData['order_reg_num'] // in chiaro
					//specialist.valid_dn = true;
				}

				if (rawSpecialist.signature != null) {
					//Util.debug("(createSpecialist) has sign.");
					return cryptoUtils
						.decryptDataWithKey(goodKey, rawSpecialist.signature)
						.then((imgSign) => {
							if (imgSign) {
								Util.debug('(createSpecialist) ok sign for specId ' + specialist.distributor_id)
								specialist.signature = imgSign // in chiaro
								// trace pesante
								//console.log(imgSign);
							} else {
								console.log('(createSpecialist) ko signature per id ' + specialist.distributor_id)
							}
							return specialist
						})
						.catch((ex) => {
							console.log('(createSpecialist) id: ' + specialist.distributor_id + ' signature decrypt err: ' + ex.message) // 04.09.2017
							return specialist // anche se non decrittato
						})
				} else {
					Util.debug('(createSpecialist) spec id ' + specialist.distributor_id + ' no img_sign')
				}

				return specialist
			})
			.catch((ex2) => {
				console.log('(createSpecialist) err2: ' + ex2.message) // 04.09.2017
				return specialist // anche se non decrittato
			})
	}

	// 15.06.2017
	static createSpecialistList(response, cryptoUtils, goodKey) {
		var result = []
		//var result: Specialist[] = [];

		for (var i = 0; i < response.length; i++) {
			var rawSpecialist = response[i]
			//console.log("(createSpecialist) giro "+i);  // 18.08.2021
			//if(rawSpecialist != null) {
			result.push(Specialist.createSpecialist(rawSpecialist, cryptoUtils, goodKey))
			//}
		}
		//return cryptoUtils.q.all(result);
		return Promise.all(result) // 17.11.2021
	}

	// 04.04.2023
	static createFromReferrer(referrer: Referrer): Specialist {
		let me = new Specialist(referrer.user_id)

		me.order_reg_num = referrer.order_num
		me.display_name = referrer.display_name

		//if (referrer.signature != null && referrer.signature.length > 3) {
		//  me.signature = referrer.signature
		//  me.hasSignature = true
		//}

		return me
	}

	// per specialisti, scelto dall'optician, non partner visionix
	public isPrivate() {
		return this.user_subtype == Config.SUB_PRIVATE
	}

	public isCompany() {
		return this.user_subtype == Config.SUB_COMPANY
	}

	// 16.01.2024
	public isMini() {
		return this.user_subtype == Config.SUB_MINI
	}

	// 02.08.2023 c'e' anche Company
	// 05.10.2022
	public getSubType() {
		let ret = 'VX' // Std, default
		if (this.isPrivate()) {
			ret = 'Private'
		} else if (this.isCompany()) {
			ret = 'Company'
		}
		return ret
	}
}

export class Relation {
	id: number // 23.06.2020 id della relazione, solo info interna
	distrib_id: string // 22.05.2017
	doctor_id: string

	//name: string;         // del doctor // ?? 10.08.2022 commentato [ls]
	is_valid: string // riferito alla relazione
	username: string // del doctor, non mostrare al ref!
	affiliation_date: Date // string;  // data della relazione ? verificare
	display_name: string // del liv2

	licence_num: string // 08.05.2024 nuovo campo, riferito al grader, arriva crittato come il display_name

	//keybox_distributor: string  // nome originario sul DB, poi sostituito con docKeyBoxPhoto ...
	docKeyBoxPhoto: string // sul DB e' keybox_distributor, contiene la key_photo dell'optician collegato
	keybox_public: string // 07.06.2023 presente per le nuove relazioni, contiene la key_photo dell'optician collegato, chiusa con public_key del grader

	// 08.06.2023 e' la keyPhoto da usare per vedere le immagini di questo optician,
	// equivale al campo doctor.key_distrib ma nella modalita' flat conviene tenerla qui sulla rel
	docKeyPhoto: forge.util.ByteStringBuffer

	signature: string // 13.07.2017 in realta' solo flag boolean, Y/N
	has_signature: boolean // 13.09.2019 TODO, usare questo

	decrypted: boolean // 25.01.2022

	constructor(rawRelation?) {
		this.id = 0
		this.doctor_id = '0'
		this.decrypted = false
		this.is_valid = 'Y'
		this.has_signature = false
		this.docKeyPhoto = null
		this.licence_num = ''

		if (rawRelation != null) {
			this.id = rawRelation.id // 23.06.2020
			this.doctor_id = rawRelation.doctor_id

			if (rawRelation.distributor_id != null) {
				// arriva dalle API
				this.distrib_id = rawRelation.distributor_id // 22.05.2017
			} else if (rawRelation.distrib_id != null) {
				// arriva da oggetto json parziale
				this.distrib_id = rawRelation.distrib_id
			}

			if (this.is_valid) {
				// solo se arriva il campo, altrimenti assumo che sia Y
				this.is_valid = rawRelation.is_valid
			}

			//console.log("[D] createRel "+rawRelation.id);
			//console.log(rawRelation);  // 22.02.2021 tolta trace

			if (rawRelation.docUsername) {
				this.username = rawRelation.docUsername
			} else if (rawRelation.username) {
				this.username = rawRelation.username
			} else {
				this.username = 'Operator_' + this.doctor_id
			}

			//this.affiliation_date = rawRelation.affiliation_date;  // 28.01.2022
			if (rawRelation.affiliation_date != null) {
				//console.log("[R] affiliation_date "+rawRelation.affiliation_date);
				this.affiliation_date = DateParser.parseDate(rawRelation.affiliation_date)
			}

			if (rawRelation.docKeyBoxPhoto != null) {
				this.docKeyBoxPhoto = rawRelation.docKeyBoxPhoto // 05.06.2017
			}

			// 09.05.2025 mi serve salvarla sempre
			if (rawRelation.keybox_public != null) {
				this.keybox_public = rawRelation.keybox_public
			}

			// 07.06.2023 posso avere la keybox_public sia con che senza la docKeyBoxPhoto
			// ma e' nuova solo se la docKeyBoxPhoto manca.
			if (rawRelation.keybox_public != null && !rawRelation.docKeyBoxPhoto) {
				// nuova relazione, da attivare, vd session.verifyNewRelations
				console.log('(createRelation) new REL with opt: ' + this.username)
			}

			// poi lo aggiorna dopo decrypt
			if (rawRelation.signature) {
				// 05.08.2022 potrebbe esserci per intero, crittata, oppure solo Y/N
				this.has_signature = rawRelation.signature != 'N'
			}
			//this.has_signature = (rawRelation.signature && rawRelation.signature == "Y");  // 13.09.2019

			//this.enable_note = rawRelation.enable_note;  // 18.05.2018 Y/N

			// 05.08.2022 ripristinato, tengo i campi crittati, per dopo
			if (this.has_signature) this.signature = rawRelation.signature // 13.07.2017 in realta' solo flag boolean, Y/N, per gli admins
			if (rawRelation.display_name) this.display_name = rawRelation.display_name // 01.06.2017 crittato
			if (rawRelation.licence_num) this.licence_num = rawRelation.licence_num // 08.05.2024 crittato
		}
	}

	// 04.05.2022
	isValid() {
		return this.is_valid == 'Y'
	}

	// come admin, passa di qui nella pagina di relations, quando si sceglie un lev2
	// e presenta la lista dei doctors a dx
	// 02.05.2017
	static createRelation(rawRelation: RelationJson, cryptoUtils, goodPwd, ignoreCritt: boolean, doctUsername?): Promise<Relation> {
		var result = new Relation(rawRelation) // 21.01.2022 carica i campi in chiaro

		// console.log(rawRelation) // solo per test  10.05.2022

		/*
    result.docKeyBoxPhoto = rawRelation.docKeyBoxPhoto; // 05.06.2017
    result.signature = rawRelation.signature;  // 13.07.2017 in realta' solo flag boolean, Y/N
    result.has_signature = (rawRelation.signature == 'Y');  // 13.09.2019   
    */

		/* 05.08.2022 gestito nel costruttore
		// 06.05.2022 se arrivo da lista, vale solo Y/N
    // 10.05.2022 solo per gli admins
		if(rawRelation.signature){  // && (rawRelation.signature.length < 2
			result.has_signature = (rawRelation.signature == 'Y'); 
			//if(result.has_signature){
			//	rawRelation.signature = null; // svuoto
			//}
		} 
    */

		Util.debug('(createRelation) (short) has_sign: ' + result.has_signature)

		if (rawRelation.docKeyBoxPhoto == null || goodPwd == null || ignoreCritt) {
			if (ignoreCritt) {
				Util.debug('(createRelation) keep encrypted, ignoreCritt: ' + ignoreCritt)
			} else {
				Util.debug('(createRelation) missing keyBoxPhoto or pwd! - keep encrypted')
			}

			result.decrypted = false
			//result.display_name = "Ref_" + result.doctor_id;

			//campi ancora crittati, gia' fatto dal costruttore
			//result.display_name = rawRelation.display_name
			//result.signature = rawRelation.signature;

			return Promise.resolve(result)
		}

		// 05.06.2017 decrittare il display_name   *************
		if (rawRelation.display_name) {
			// 05.06.2017 Bisogna aprire la doctorKeyBoxPhoto e poi usare quella come key
			//console.log("(createRelation) decrypt keyPhoto for doctor "+result.doctor_id);

			var mySalt = '' // 29.04.2020 per salare la pwd_key
			if (doctUsername != null) {
				mySalt = doctUsername.toLowerCase()
			} else {
				mySalt = result.username.toLowerCase() // 21.01.2022
			}

			return cryptoUtils.decryptDataWithPwdS(goodPwd, rawRelation.docKeyBoxPhoto, mySalt).then((doctKeyPhoto: forge.util.ByteStringBuffer) => {
				//console.log("(createRelation) ok keyPhoto for doctor "+result.doctor_id+" ora display_name...");

				// 08.06.2023 me la salvo cosi' non serve riaprirla ogni volta
				result.docKeyPhoto = doctKeyPhoto

				// 21.01.2022 estendo con signature
				let bag = cryptoUtils.generateBag()

				// 08.05.2024 aggiunto anche licence_num
				bag['display_name'] = rawRelation.display_name
				bag['licence_num'] = rawRelation.licence_num

				// 06.05.2022 se arrivo da lista, vale solo Y/N
				if (rawRelation.signature) {
					if (rawRelation.signature.length > 2) {
						Util.debug('(createRelation) going to decrypt the sign too...')
						bag['signature'] = rawRelation.signature
					} else {
						Util.debug('(createRelation) sign is only a flag: ' + rawRelation.signature) // 11.05.2022
					}
				}

				cryptoUtils.purge(bag)

				// 22.06.2022 decryptDataWithKey sul pat gestisce anche caratteri accentati nel displayname,
				// 21.01.2022 uso decryptAll per l'immagine
				return (
					cryptoUtils
						.decryptDataWithKey(doctKeyPhoto, bag)
						//return cryptoUtils.decryptAll(doctKeyPhoto, bag)    //  // 21.01.2022
						.then((bagClear) => {
							/*
									// 11.05.2022 ciclo senza enumerarle tutte [ls]
									for(var prop in bagClear) {
										if(bagClear.hasOwnProperty(prop)){
											result[prop] = bagClear[prop]; 
										}            
									} 
                  */
							// 22.06.2022 test senza ciclo
							result.display_name = bagClear['display_name']
							result.licence_num = bagClear['licence_num'] // 08.05.2024
							if (bagClear['signature']) {
								result.signature = bagClear['signature']
								// 04.08.2022 ripristinata ? non e' qui
								//result.has_signature = (result.signature != null);
							}

							Util.debug('(createRelation) displ name: ' + result.display_name + ' has_sign: ' + result.has_signature + ' valid sign ? ' + (result.signature != null))
							// quando arrivo da lista rels, ho has_sign true e valid false.
							result.decrypted = true
							return result
						})
						.catch((ex) => {
							// 15.06.2022 patch per caratteri non ammessi - bug 182

							console.log(ex) // 22.06.2022 solo per test

							Util.debug('(createRelation) ko decrypt! refId: ' + result.distrib_id + ' optId: ' + result.doctor_id)
							result.decrypted = false
							return result
						})
				)
			})
		} else {
			//console.log("(createRelsList) doc displ nullo,  "+myRel.username);
			return Promise.resolve(result)
		}
		// *******************
	}

	// 13.09.2019 vd analoga sopra, la' e' senza signature
	static createFullRelation(rawRelation, cryptoUtils, goodKey) {
		//console.log(rawRelation);

		var result = new Relation(rawRelation)

		console.log('(createFullRelation) rel between :' + result.distrib_id + ' and ' + rawRelation.doctor_id)

		// campi crittati, parto vuoti
		result.display_name = ''
		result.signature = null

		//result.display_name = rawRelation.display_name;
		//result.docKeyBoxPhoto = rawRelation.docKeyBoxPhoto;

		// 25.01.2022
		return Relation.decryptFields(rawRelation, cryptoUtils, goodKey)
	}

	// 25.01.2022 test con bag
	static decryptFields(myRelation: Relation, cryptoUtils, myKey): Promise<Relation> {
		const promise = new Promise<Relation>((resolve, reject) => {
			var result = new Relation(myRelation)

			console.log('(decryptFields) rel between :' + result.distrib_id + ' and ' + result.doctor_id)

			// campi crittati, parto vuoti
			result.display_name = ''
			result.signature = null

			// 08.05.2024 aggiunto licence_num
			// 21.01.2022 estendo con signature
			let bag = cryptoUtils.generateBag()
			bag['display_name'] = myRelation.display_name
			bag['licence_num'] = myRelation.licence_num // 08.05.2024
			bag['signature'] = myRelation.signature

			cryptoUtils.purge(bag)

			// 21.01.2022
			//return cryptoUtils.decryptDataWithKey(doctKeyPhoto, bag)
			cryptoUtils.decryptAll(myKey, bag).then((bag) => {
				// 11.05.2022 ciclo senza enumerarle tutte [ls]
				for (var prop in bag) {
					if (bag.hasOwnProperty(prop)) {
						result[prop] = bag[prop]
					}
				}

				//result.has_signature = (bag.signature != null);
				Util.debug('(decryptFields) displ name: ' + result.display_name + ' hasSign? ' + result.has_signature + ' valid? ' + (result.signature != null))
				result.decrypted = true

				// 04.08.2022 TESTTTTTTTTTTTTTTTTT
				if (result.has_signature && !result.signature) {
					//alert('conflict hasSign? ' + result.has_signature + ' valid? ' + (result.signature != null));
					console.log('conflict hasSign? ' + result.has_signature + ' valid? ' + (result.signature != null)) // meglio evitare alert di trace [ls]
					result.has_signature = result.signature != null && result.signature.length > 4 // 28.06.2023
				}

				resolve(result)
			})
		})
		return promise
	}

	// 02.05.2017
	//static createRelsList(response: RelationsResponse, cryptoUtils, goodPwd) {
	static createRelsList(response: RelationsResponse, cryptoUtils, goodPwd, ignoreCritt: boolean) {
		var result = []

		//var myRelsList = response.data.relations;
		var myRelsList = response.relations

		//console.log(response.data);

		if (myRelsList != null) {
			for (var i = 0; i < myRelsList.length; i++) {
				var rel = myRelsList[i]
				var mySalt = rel.docUsername // 29.04.2020
				var myRel = Relation.createRelation(rel, cryptoUtils, goodPwd, ignoreCritt, mySalt)
				result.push(myRel)
			}
		} else {
			console.log('(createRelsList) null respose ')
		}
		//console.log("(createRelsList) fine ciclo, tot: "+i);
		//return cryptoUtils.q.all(result);
		return Promise.all(result) // 21.01.2022
	}
}

// TODO
// 13.05.2022 classe che server per il pdf
// devono esserci tutti i campi da esporre del liv2 o typeB
export class Referrer {
	user_id: number
	optician_id: number
	display_name: string
	hasSignature: boolean
	signature: string
	order_num: string // licence #

	constructor(usrId: number) {
		this.user_id = usrId
		this.optician_id = 0
		this.display_name = ''
		this.hasSignature = false
		this.signature = null
		this.order_num = ''
	}
}

export interface DistribJson {
	user_id: number
	firstname: string
	lastname: string
	user_type: string
	user_subtype: string
	subscription_time: string
	code: string
	keybox_distrib: string // 07.02.2017 per la decodifica dei campi critt
	is_valid: string
	pathology_group: number // 19.06.2017
	addresses: Address[]
	setting: Settings
}

export interface DistributorResponse {
	//data: {
	distributor: DistribJson
	//}
}

export interface DistributorsResponse {
	data: DistribJson[]
}

export interface ClinicsJson {
	user_id: number
	access_counter: number
	code: string
	country: string
	created_by: number
	creation_date: string
	addresses: Address[]
	firstname: string
	lastname: string
	order_reg_num: string
	display_name: string
	signature: string
	status: string
	group_id: number
	is_deleted: string
	is_test: string
	subscripton_time: string
	super_salt: string
	user_subtype: string
	user_type: string
}

export interface ClinicsResponse {
	clinics: ClinicsJson[]
}

export interface RelationJson {
	id: number
	doctor_id: number
	distributor_id: number
	affiliation_date: string
	is_valid: string
	//username: string;
	docUsername: string // salt
	docKeyBoxPhoto
	display_name: string
	signature: string // 21.01.2022 nella lista vale Y/N, per gli admins
	licence_num: string // 08.05.2024
}

export interface RelationsResponse {
	//data: {
	relations: RelationJson[]
	//}
}

export class RelationDetail {
	distributor: Distrib
	relation_id: number
	affiliation_date: Date
	relStatus: string
	created_by: number
	distance: number
	selected: boolean
	changeStatus: boolean
	addressIndx: number
	addressLabel: string
	not_available: boolean // used for support, puó capitarte che ci siano relazioni con grader che il support non puó vedere (altre country etc). in questo caso va a true e viene mostrato un messaggio appropriato al support

	constructor(distributor?: Distrib, docMainAddress?: Address) {
		this.distributor = new Distrib()
		this.relStatus = ''
		this.created_by = 0 // se -1 allora é il box di aggiungere nuovo paziente
		this.selected = false
		this.changeStatus = false
		this.distance = 0
		this.addressIndx = 0
		this.addressLabel = ''
		this.relation_id = 0
		this.affiliation_date = null
		this.not_available = false

		if (distributor && docMainAddress) {
			this.distributor = distributor
			let obj = this.distributor.getCloserLocation(docMainAddress)

			this.addressIndx = obj.indx
			this.distance = obj.distance
			this.addressLabel = this.distributor.addresses[this.addressIndx].getExtendedAddressLabel()
			// this.getCloserLocation(this.distributor.addresses, docMainAddress)
		}
	}
}

export interface nearestRel {
	id: number
	oper_id: number
	status: string
	grader_id: number
	creation_date: string
}

export interface relationsStatus {
	id: number
	doctor_id: number
	distributor_id: number
	rel_type: string
	is_valid: string
	affiliation_date: string
	cessation_date: string
	updated_by: number
}

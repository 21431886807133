import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import { SessionService } from 'src/app/service/session.service'
import { Util } from 'src/app/models/util.model'

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { NgForm } from '@angular/forms'

export enum itemType {
	PATIENT = 'patient',
	VISIT = 'visit',
	OPERATOR = 'operator',
	GRADER = 'grader',
	EXAM = 'exam',
	USER = 'user',
	UPDATES = 'updates',
}

@Component({
	selector: 'delete-modal',
	templateUrl: './delete.modal.html',
	styleUrls: ['./delete.modal.scss'],
})
export class DeleteModalContent implements OnInit {
	@Input() itemDescription: string // ciascun controller passa patient.name o visit.id o qls altro
	@Input() itemType: itemType
	@Input() requirePsw: boolean
	@ViewChild('confirmFrom') confirmFrom: NgForm

	title: string
	subtitle: string

	password: string
	wrongPwd: boolean

	faTriangleExclamation = faTriangleExclamation

	constructor(public activeModal: NgbActiveModal, private session: SessionService, private translator: TranslateService) {
		this.title = ''
		this.subtitle = ''
		this.password = ''

		this.requirePsw = false
		this.wrongPwd = false
	}

	ngOnInit(): void {
		Util.debug('delete modal - ngOnInit')

		this.title = this.translator.instant('DELETE.MODAL_TITLE.' + this.itemType.toUpperCase())
		this.subtitle = this.translator.instant('DELETE.MODAL_SUBTITLE.' + this.itemType.toUpperCase())

		/*
    if(this.myTitle == ""){
      this.myTitle = "Delete Item";  // {{"DELETE.MODAL_TITLE" | translate}}
      this.myTitle = this.translator.instant('DELETE.MODAL_TITLE');
    }
    */
		// this.requireDispPwd = this.session.isGod() || this.session.isVice()
	}

	checkPsw() {
		let valid = this.session.isCorrectPwd(this.password)

		if (valid) {
			this.wrongPwd = false
			this.passBack(true)
		} else {
			this.wrongPwd = true
			this.confirmFrom.form.get('confirm').setErrors({ invalid: true })
			setTimeout(() => {
				this.wrongPwd = false
			}, 1000)
		}
	}

	passBack(resp) {
		Util.debug('delete modal, resp: ' + resp)
		this.activeModal.close(resp)
	}

	// 29.08.2022 close without doing anything
	dismiss() {
		Util.debug('delete modal, dismiss, no action')
		this.activeModal.close(false)
	}
}

<div class="dashboard-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-4">
				<div class="row">
					<div class="col-12 my-2 p-3 custom-shadow date-container rounded">
						<p class="fw-bold">{{'DASHBOARD.DATETITLE' | translate}}</p>
						<form [formGroup]="dashboardTime" class="row">
							<div class="col-xxl-6 col-sm-12">
								<mat-form-field appearance="outline" style="font-size: 12px; width: 100%" class="me-3">
									<mat-label style="color: #4f0aeb">{{'DASHBOARD.DATERANGE' | translate}}</mat-label>
									<mat-date-range-input [rangePicker]="picker">
										<input matStartDate placeholder="{{'DASHBOARD.FROMDATE' | translate}}" formControlName="fromDate" />
										<input matEndDate placeholder="{{'DASHBOARD.TODATE' | translate}}" (dateChange)="changeDate()" formControlName="toDate" />
									</mat-date-range-input>
									<!-- <mat-hint style="color: #4f0aeb">MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
									<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
									<mat-date-range-picker #picker></mat-date-range-picker>
								</mat-form-field>
							</div>

							<div class="col-xxl-6 col-sm-12 position-relative">
								<mat-form-field appearance="outline" style="font-size: 12px; width: 80%">
									<mat-label style="color: #4f0aeb">{{'DASHBOARD.CADENCE' | translate}}</mat-label>
									<mat-select name="grading" formControlName="cadence" (selectionChange)="buildCadenceChart()">
										<mat-option *ngFor="let cad of cadence" [value]="cad"> {{cad}} </mat-option>
									</mat-select>
								</mat-form-field>
								<fa-icon [icon]="faXmark" style="color: #ff3b2d" class="ms-2" [ngClass]="dashboardTime.get('cadence').value != null ? 'show': '' " (click)="dashboardTime.get('cadence').reset(); dashboardTime.get('cadence').setValue(null); buildCharts(); chartCadenceReady= false"></fa-icon>
							</div>
						</form>

						<div class="row mt-3">
							<div style="font-size: 12px" class="col-12">
								<input class="form-check-input" type="checkbox" class="me-2" id="deleted" (click)="showDeletedGraders($event)" />
								<label class="form-check-label" for="deleted" style="font-size: 12px">{{'DASHBOARD.SHOWDELETED' | translate}}</label>
							</div>
						</div>
					</div>

					<!-- Reports -->
					<div class="col-12 my-2 p-3 custom-shadow reports-container rounded">
						<p class="fw-bold">{{'DASHBOARD.TITLE' | translate}}</p>
						<div class="row">
							<div class="col-4 text-center reports-container__total rounded">
								<p>{{'DASHBOARD.TOTAL' | translate}}</p>
								<span>{{totReports}}</span>
							</div>
							<div class="col-4 text-center reports-container__full rounded">
								<p>{{'DASHBOARD.FULL' | translate}}</p>
								<span>{{totFull}}</span>
							</div>
							<div class="col-4 text-center reports-container__fundus rounded">
								<p>{{'DASHBOARD.FUNDUS' | translate}}</p>
								<span>{{totFundus}}</span>
							</div>
						</div>
					</div>

					<!-- Performance -->
					<div class="col-12 my-2 p-3 custom-shadow performance-container rounded">
						<p class="fw-bold">{{'DASHBOARD.PERFORMANCE' | translate}}</p>
						<div class="row d-flex justify-content-center">
							<div class="col-8 text-center performance-container__grading rounded">
								<p>{{'DASHBOARD.GRADINGTIME' | translate}}</p>
								<span class="grading-time" [ngClass]="avGradingTimeExceed ? 'warning' : ''"><span>{{avGradingTime}}</span> <span style="font-size: 14px"> {{avGradingTimeUnit}}</span></span>
							</div>
						</div>
					</div>

					<!-- GradersList -->
					<div class="col-12 my-2 p-3 custom-shadow graderList-container rounded position-relative">
						<p class="fw-bold">{{'DASHBOARD.GRADERS' | translate}}</p>

						<loader [activateSpinner]="isLoading"></loader>

						<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->

						<div class="table-container">
							<table mat-table [dataSource]="graderList" matSort style="width: 100%" class="grader-list" [ngClass]="isLoading ? '' : 'show' ">
								<ng-container matColumnDef="addremove">
									<th mat-header-cell *matHeaderCellDef></th>
									<td mat-cell *matCellDef="let element; let i = index" class="fw-bold ps-0">
										<fa-icon
											style="opacity: 0.5"
											[icon]="element.isOnChart ? faCircleMinus : faCirclePlus"
											[ngClass]="element.isOnChart ? 'disable' : 'enable' "
											[ngbTooltip]="element.isOnChart ? ('DASHBOARD.REMOVEGR' | translate): ('DASHBOARD.ADDGR' | translate) "
											[hidden]="oneSelected"
											(click)="$event.stopPropagation(); buildCharts(element) "></fa-icon>
										<fa-icon [icon]="faXmark" style="color: #ff3b2d" [hidden]="!element.isSelected" (click)="$event.stopPropagation(); element.isSelected=false; buildCharts(); oneSelected=false"></fa-icon>
									</td>
								</ng-container>

								<ng-container matColumnDef="username">
									<th mat-header-cell *matHeaderCellDef>{{'DASHBOARD.USERNAME' | translate}}</th>
									<td mat-cell *matCellDef="let element" class="fw-bold" [ngStyle]="{'opacity': element.isOnChart ? '1' : '0.5'}">{{ element.username }}</td>
								</ng-container>

								<ng-container matColumnDef="tot_reports_done">
									<th mat-header-cell *matHeaderCellDef class="text-center">{{'DASHBOARD.TOTAL' | translate}}</th>
									<td mat-cell *matCellDef="let element" class="text-center">{{ element.tot_reports }}</td>
								</ng-container>

								<!-- <ng-container matColumnDef="full">
									<th mat-header-cell *matHeaderCellDef class="text-center">{{'DASHBOARD.FULL' | translate}}</th>
									<td mat-cell *matCellDef="let element" class="text-center">{{element.full_reports}}</td>
								</ng-container>

								<ng-container matColumnDef="fundus">
									<th mat-header-cell *matHeaderCellDef class="text-center">{{'DASHBOARD.FUNDUS' | translate}}</th>
									<td mat-cell *matCellDef="let element" class="text-center">{{element.fundus_reports}}</td>
								</ng-container> -->

								<ng-container matColumnDef="grading_time">
									<th mat-header-cell *matHeaderCellDef class="text-center">{{'DASHBOARD.DELIVERY' | translate}}</th>
									<td mat-cell *matCellDef="let element" class="text-center" [ngClass]="element.av_grading_time>24 ? 'alert' : '' ">{{element.av_grading_time}}</td>
								</ng-container>

								<ng-container matColumnDef="icon">
									<th mat-header-cell *matHeaderCellDef></th>
									<td mat-cell *matCellDef="let element">
										<fa-icon [icon]="element.isEnabled ? faUserCheck : faUserXmark " aria-hidden="true" [ngClass]="element.isEnabled ? 'enable' : 'disable' " [ngbTooltip]="element.isEnabled ? ('DASHBOARD.GRENABLED' | translate) : ('DASHBOARD.GRDISABLED' | translate) "></fa-icon>
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns" style="cursor: pointer" (click)="onClickGraderChart(row); row.isSelected = true" [ngClass]="row.isSelected ? 'selected': '' "></tr>
							</table>
						</div>
					</div>
				</div>
			</div>
			<div class="col-8">
				<div class="row">
					<!-- GRADFERS REPORTS NUMBER -->
					<div class="col-12 p-3 my-2 mx-2 custom-shadow rounded position-relative" style="min-height: 15vh">
						<loader [activateSpinner]="!chartReady"></loader>

						<!-- <div class="loader-container" [ngClass]="chartReady ? '' : 'show' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->
						<div class="column-chart" [ngClass]="chartReady ? 'show' : '' ">
							<apx-chart
								*ngIf="chartReady"
								[series]="columnChart.series"
								[title]="columnChart.title"
								[chart]="columnChart.chart"
								[dataLabels]="columnChart.dataLabels"
								[plotOptions]="columnChart.plotOptions"
								[responsive]="columnChart.responsive"
								[fill]="columnChart.fill"
								[stroke]="columnChart.stroke"
								[xaxis]="columnChart.xaxis"
								[colors]="columnChart.colors"
								[noData]="{text: 'No data to display'}">
							</apx-chart>
						</div>
					</div>

					<!-- GRADFERS DELIVERY TIME -->

					<div class="performance-pie-charts-container row align-items-center p-3 my-2 mx-2 custom-shadow rounded position-relative" style="min-height: 45vh" *ngIf="!chartCadenceReady">
						<loader [activateSpinner]="!chartReady"></loader>

						<!-- <div class="loader-container" [ngClass]="chartReady ? '' : 'show' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->
						<div class="w-auto" *ngFor="let pieChart of performancePieCharts">
							<apx-chart [fill]="pieChart.fill" [title]="pieChart.title" [legend]="pieChart.legend" [series]="pieChart.series" [chart]="pieChart.chart" [labels]="pieChart.labels" [responsive]="pieChart.responsive" [colors]="pieChart.colors" [noData]="{text: 'No data to display'}"> </apx-chart>
						</div>
					</div>

					<!-- GRADFERS REPORTS CADENCE -->
					<div class="col-12 p-3 my-2 mx-2 custom-shadow rounded position-relative" style="min-height: 15vh" *ngIf="chartCadenceReady">
						<loader [activateSpinner]="!chartCadenceReady"></loader>

						<!-- <div class="loader-container" [ngClass]="chartCadenceReady ? '' : 'show' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->
						<div class="column-chart" [ngClass]="chartCadenceReady ? 'show' : '' ">
							<apx-chart
								*ngIf="chartCadenceReady"
								[series]="columnChartCadence.series"
								[title]="columnChartCadence.title"
								[chart]="columnChartCadence.chart"
								[dataLabels]="columnChartCadence.dataLabels"
								[plotOptions]="columnChartCadence.plotOptions"
								[responsive]="columnChartCadence.responsive"
								[fill]="columnChartCadence.fill"
								[stroke]="columnChartCadence.stroke"
								[xaxis]="columnChartCadence.xaxis"
								[colors]="columnChartCadence.colors"
								[noData]="{text: 'No data to display'}">
							</apx-chart>
						</div>
					</div>
				</div>

				<!-- <div class="row">
					<div class="col-12 p-3 my-3 mx-2 custom-shadow rounded position-relative" style="min-height: 20vh" *ngIf="graderChartReady">
						<apx-chart
							[series]="graderChart.series"
							[title]="graderChart.title"
							[chart]="graderChart.chart"
							[dataLabels]="graderChart.dataLabels"
							[plotOptions]="graderChart.plotOptions"
							[responsive]="graderChart.responsive"
							[fill]="graderChart.fill"
							[xaxis]="graderChart.xaxis"
							[colors]="graderChart.colors"
							[noData]="{text: 'No data to display'}">
						</apx-chart>
					</div>
				</div> -->
			</div>
		</div>
	</div>
</div>

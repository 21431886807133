import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { VA, visus } from 'src/app/models/anamnesis.model'
import { SessionService } from 'src/app/service/session.service'

@Component({
	selector: 'app-pat-va',
	templateUrl: './pat-va.component.html',
	styleUrl: './pat-va.component.scss',
})
export class PatVaComponent implements OnInit {
	@Input() thisVa: VA
	@Output('out-va') outVa = new EventEmitter<VA>()
	@Output('uncorrected-populated') uncorrectedValuesPopulated = new EventEmitter<boolean>()

	visusArray: visus[]
	vis: visus
	areUncorrectedValuesPopulated: boolean = false

	constructor(public session: SessionService, private translator: TranslateService) {
		this.visusArray = []
		this.thisVa = null
	}

	ngOnInit(): void {
		this.vis = { name: this.translator.instant('VA.UNCORRECT'), value: { right: this.thisVa.va_right, left: this.thisVa.va_left, bino: this.thisVa.va_bino } }
		this.visusArray.push(this.vis)
		this.vis = {
			name: this.translator.instant('VA.OLD_CORRECTION'),
			value: { right: this.thisVa.old_right, left: this.thisVa.old_left, bino: this.thisVa.old_bino },
		}
		this.visusArray.push(this.vis)
		this.vis = {
			name: this.translator.instant('VA.NEW_CORRECTION'),
			value: { right: this.thisVa.new_right, left: this.thisVa.new_left, bino: this.thisVa.new_bino },
		}
		this.visusArray.push(this.vis)

		this.checkUncorrectedValues()
	}

	copyVa(index: number, eye: string) {
		let value: string

		if (eye == 'right') {
			value = this.visusArray[index].value.right
		} else if (eye == 'left') {
			value = this.visusArray[index].value.left
		} else {
			value = this.visusArray[index].value.bino
		}

		let pattern = value.replace(/[0-9/,.]/g, '')

		value = value.replace(pattern, '')

		switch (index) {
			case 0:
				if (eye == 'right') {
					this.thisVa.va_right = value
				} else if (eye == 'left') {
					this.thisVa.va_left = value
				} else {
					this.thisVa.va_bino = value
				}

				break

			case 1:
				if (eye == 'right') {
					this.thisVa.old_right = value
				} else if (eye == 'left') {
					this.thisVa.old_left = value
				} else {
					this.thisVa.old_bino = value
				}

				break

			case 2:
				if (eye == 'right') {
					this.thisVa.new_right = value
				} else if (eye == 'left') {
					this.thisVa.new_left = value
				} else {
					this.thisVa.new_bino = value
				}
				break
		}
		this.outVa.emit(this.thisVa)
		this.checkUncorrectedValues()
	}

	checkUncorrectedValues() {
		if (this.visusArray[0].value.right && this.visusArray[0].value.left && this.visusArray[0].value.bino) {
			this.areUncorrectedValuesPopulated = true
		} else {
			this.areUncorrectedValuesPopulated = false
		}

		this.uncorrectedValuesPopulated.emit(this.areUncorrectedValuesPopulated)
	}
}

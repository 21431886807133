<div class="update-wizard-container panel-modal position-relative">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
	</div>

	<div class="container-fluid">
		<div class="row mt-3">
			<div class="col-12 tab-container">
				<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav justify-content-around" [destroyOnHide]="false" (activeIdChange)="onNavChange($event)">
					<!--  STEP 1 -->
					<li [ngbNavItem]="0" class="nav-item me-3" [ngClass]="{'active': tabs[0].id === activeTab}" [disabled]="true" #tab1>
						<a ngbNavLink uppercase class="nav-link fw-bold">1</a>

						<ng-template ngbNavContent>
							<div>
								<form [formGroup]="filterForm1" class="filter-form__container d-flex flex-column align-items-center py-4">
									<!-- DEVICE -->
									<div class="d-flex align-items-center">
										<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
											<mat-label>{{'SW_UPDT.WIZARD.SELECTIONS.DEVICE' | translate}}</mat-label>

											<mat-select name="device" formControlName="device" (selectionChange)="filterChange()">
												<mat-option *ngFor="let device of devices" [value]="device"> {{device}} </mat-option>
											</mat-select>
										</mat-form-field>
										<!-- used only to align both select -->
										<fa-icon class="ms-2 cancel-filter-icon" [icon]="faXmark"></fa-icon>
									</div>

									<!-- BRAND -->
									<!-- <div class="d-flex align-items-center mt-3 position-relative">
										<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
											<mat-label>{{'SW_UPDT.WIZARD.SELECTIONS.BRAND' | translate}}</mat-label>
											<mat-select name="brand" formControlName="brand">
												<mat-option *ngFor="let brand of availableBrands" [value]="brand"> {{brand}} </mat-option>
											</mat-select>
										</mat-form-field>
										<fa-icon class="ms-2 cancel-filter-icon" [icon]="faXmark" [ngClass]="filterForm1.get('brand').value != null ? 'show': '' " (click)="filterForm1.get('brand').reset()"></fa-icon>
										<div class="spinner">
											<div class="spinner-border" role="status" *ngIf="filterForm1.get('brand').disabled">
												<span class="visually-hidden">Loading...</span>
											</div>
										</div>
									</div> -->
								</form>
							</div>
						</ng-template>
					</li>

					<!--  STEP 2 -->
					<li [ngbNavItem]="1" class="nav-item me-3 position-relative" [ngClass]="{'disabled': !tabs[1].isDone, 'active': tabs[1].id === activeTab}" [disabled]="true" #tab2>
						<a ngbNavLink uppercase class="nav-link fw-bold">2</a>

						<div class="line" #line1 [ngClass]="{'active': tabs[1].id === activeTab || tabs[1].isDone}"></div>

						<ng-template ngbNavContent>
							<form [formGroup]="filterForm2" class="filter-form__container d-flex flex-column align-items-center py-4">
								<!-- DEVICE -->
								<div class="d-flex align-items-center">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label>{{'SW_UPDT.WIZARD.SELECTIONS.TYPE' | translate}}</mat-label>

										<mat-select name="type" formControlName="type" (selectionChange)="filterForm2.get('file').enable(); filterForm2.get('file').setValue('')">
											<mat-option *ngFor="let comp of availableComponents" [value]="comp"> {{comp}} </mat-option>
										</mat-select>
									</mat-form-field>
								</div>

								<!-- BINARY -->
								<div class="form-group position-relative mt-4">
									<label for="binary" class="form-label">{{'SW_UPDT.WIZARD.SELECTIONS.FILE' | translate}}</label>
									<input type="file" class="form-control file-select" name="binary" id="binary" #binary formControlName="file" accept=".zip,.gz,.apk,.exe" />
									@if(filterForm2.get('file').invalid){ @if(filterForm2.get('file').errors['invalid file']){
									<div class="error-message-container"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon> <span class="ms-2">{{'SW_UPDT.WIZARD.SELECTIONS.FILE_ERR' | translate}}</span></div>

									} @if(filterForm2.get('file').errors['file already loaded']){
									<div class="error-message-container"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon> <span class="ms-2">{{'SW_UPDT.WIZARD.SELECTIONS.FILE_ERR2' | translate}}</span></div>

									} }
								</div>

								<!-- BUILD -->
								<div class="form-group position-relative">
									<label for="build" class="form-label">{{'SW_UPDT.WIZARD.SELECTIONS.BUILD' | translate}}</label>
									<input type="text" class="form-control" name="build" id="build" formControlName="build" pattern="^\d+(?:\.\d+)*$" oninput="this.value = this.value.replace(/[^0-9.]/g, '')" />
								</div>

								<!-- BUILD_INT -->
								<div class="form-group position-relative">
									<label for="build_int" class="form-label">{{'SW_UPDT.WIZARD.SELECTIONS.BUILD_INT' | translate}}</label>
									<input type="text" maxlength="20" class="form-control" name="build_int" id="build_int" formControlName="build_int" oninput="this.value = this.value.replace(/[^0-9]/g, '')" />
								</div>

								<!-- PACKAGE NAME -->
								@if(enablePackageName){
								<div class="form-group position-relative">
									<label for="package_name" class="form-label">{{'SW_UPDT.WIZARD.SELECTIONS.PACKAGE_NAME' | translate}}</label>
									<input type="text" maxlength="40" class="form-control" name="package_name" id="package_name" formControlName="package_name" />
								</div>
								}
							</form>
						</ng-template>
					</li>

					<!--  STEP 3 -->

					<li [ngbNavItem]="2" class="nav-item me-3 position-relative" [ngClass]="{'disabled': !tabs[2].isDone, 'active': tabs[2].id === activeTab}" [disabled]="true" #tab3>
						<a ngbNavLink uppercase class="nav-link fw-bold">3</a>

						<div class="line" #line2 [ngClass]="{'active': tabs[2].id === activeTab || tabs[2].isDone}"></div>

						<ng-template ngbNavContent>
							<form [formGroup]="filterForm3" class="filter-form__container d-flex flex-column align-items-center pb-4">
								<!-- description -->
								<div class="form-group position-relative" style="width: 70%">
									<!-- <label for="build" class="form-label">Description</label> -->
									<input type="text" class="form-control file-select" name="description" id="description" formControlName="description" />
								</div>

								<p>{{'SW_UPDT.WIZARD.2SUBTITLE3' | translate}}</p>

								<!-- OS -->
								<div class="d-flex align-items-center position-relative">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label>{{'SW_UPDT.WIZARD.SELECTIONS.OS' | translate}}</mat-label>

										<mat-select name="os" formControlName="os" (selectionChange)="filterChange()">
											<mat-option *ngFor="let os of availableOs" [value]="os"> {{os}} </mat-option>
										</mat-select>
									</mat-form-field>
									<fa-icon class="ms-2 cancel-filter-icon" [icon]="faXmark" [ngClass]="filterForm3.get('os').value != null ? 'show': '' " (click)="filterForm3.get('os').reset(); filterChange()"></fa-icon>
									<div class="spinner">
										<div class="spinner-border" role="status" *ngIf="filterForm3.get('os').disabled && !osNotAvailable">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>
								</div>
								@if(osNotAvailable){
								<div class="info-message-container"><span class="ms-2">{{'SW_UPDT.WIZARD.SELECTIONS.NO_FILTERS' | translate}}</span></div>

								}

								<!-- min version -->
								<div class="d-flex align-items-center mt-3 position-relative">
									<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
										<mat-label>{{'SW_UPDT.WIZARD.SELECTIONS.MIN_VERS' | translate}}</mat-label>

										<mat-select name="min_version" formControlName="min_version" (selectionChange)="filterChange()">
											<mat-option *ngFor="let ver of availableVersions" [value]="ver"> {{ver}} </mat-option>
										</mat-select>
									</mat-form-field>
									<fa-icon class="ms-2 cancel-filter-icon" [icon]="faXmark" [ngClass]="filterForm3.get('min_version').value != null ? 'show': '' " (click)="filterForm3.get('min_version').reset(); filterChange()"></fa-icon>
									<div class="spinner">
										<div class="spinner-border" role="status" *ngIf="filterForm3.get('min_version').disabled && !minVersionNotAvailable">
											<span class="visually-hidden">Loading...</span>
										</div>
									</div>
								</div>
								@if(minVersionNotAvailable){
								<div class="info-message-container"><span class="ms-2">{{'SW_UPDT.WIZARD.SELECTIONS.NO_FILTERS' | translate}}</span></div>

								}
							</form>
						</ng-template>
					</li>

					<!--  STEP 4 -->
					<li [ngbNavItem]="3" class="nav-item me-3 position-relative" [ngClass]="{'disabled': !tabs[3].isDone, 'active': tabs[3].id === activeTab}" [disabled]="true" #tab4>
						<a ngbNavLink uppercase class="nav-link fw-bold">4</a>

						<div class="line" #line3 [ngClass]="{'active': tabs[3].id === activeTab || tabs[3].isDone}"></div>

						<ng-template ngbNavContent>
							<p>OPT TO DO</p>
						</ng-template>
					</li>
				</ul>
			</div>
		</div>

		<div class="row">
			<div class="col-12 py-4 text-center position-relative" style="min-height: 25vh">
				@if(this.activeTab < 4){
				<h3 class="fw-bold mb-4">{{tabs[activeTab].title}}</h3>
				<p>{{tabs[activeTab].subtitle}}</p>
				} @else {
				<loader [activateSpinner]="savingUpdate.state"></loader>

				<div class="update-result-messages" style="min-height: 25vh">
					<h4 class="fw-bold mb-4" [ngClass]="!savingUpdate.state ? 'show': '' ">{{savingUpdate.message}}</h4>
					<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true" [ngClass]="!savingUpdate.state && savingUpdate.result ? 'show': '' "></fa-icon>
					<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true" [ngClass]="!savingUpdate.state && !savingUpdate.result ? 'show': '' "></fa-icon>
				</div>
				}

				<!-- se metto [ngbNavOutlet] dentro un if alla prima apertura non lo vedo quindi per nascondere faccio cosí -->
				<div [ngbNavOutlet]="nav" class="pt-3"></div>
			</div>
		</div>

		<div class="row">
			<div class="col-12 text-center">
				@if(!savingUpdate.state && this.activeTab < 4){ @if(activeTab != 0){
				<button class="btn btn-secondary shadow me-3" (click)="back()">{{'SW_UPDT.WIZARD.BUTTON_BACK' | translate}}</button>
				}

				<button class="btn btn-primary shadow" (click)="next()" [disabled]="tabs[activeTab].form.invalid">{{'SW_UPDT.WIZARD.BUTTON_NEXT' | translate}}</button>
				}
			</div>
		</div>
	</div>
</div>

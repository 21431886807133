<div class="loading-container" [hidden]="!dataService.isLoadingReportList()">
	<div class="tail-spin">&nbsp;</div>
</div>

<div class="topview report-list-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<!-- <div class="loading-container-small" *ngIf="isGeneratingPdf">
					<div>
						<h2 class="text-center">Generating pdf...</h2>
					</div>
					<div class="tail-spin">&nbsp;</div>
				</div> -->

				<div class="panel panel-default position-relative">
					<div class="btn-close-container-pos">
						<button type="button" class="btn-close" aria-label="Close" (click)="passBack()">
							<!-- (click)="activeModal.dismiss('Cross click')" -->
						</button>
					</div>

					<div class="row mt-3">
						<div class="col-12">
							<div class="row" style="padding: 10px 50px">
								<div class="col-4 d-flex align-items-end">
									<button type="button" class="btn btn-primary btn-sm reload" (click)="reloadList()" [disabled]="!reloadEnable || waitNewAiReport">
										{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
									</button>
								</div>
								<div class="col-4 text-center">
									<div *ngIf="waitNewAiReport">
										<p>{{ 'AI_REPORTS.WAIT_TITLE' | translate }}</p>
									</div>
									<div *ngIf="waitNewAiReport">
										<div class="lds-ring-small">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div>
								</div>

								<div class="col-4"></div>
							</div>
							<div class="row">
								<div class="col-12">
									<div class="panel-body position-relative">
										<loader [activateSpinner]="loadingList || isGeneratingPdf "></loader>

										<p *ngIf="isGeneratingPdf">{{ 'AI_REPORTS.GEN_PDF' | translate }}</p>

										<!-- <div class="loader-container text-center" [ngClass]="loadingList || isGeneratingPdf ? 'show' : '' ">
											<div [hidden]="false" class="lds-ring">
												<div></div>
												<div></div>
												<div></div>
												<div></div>
											</div>
											<p *ngIf="isGeneratingPdf">{{ 'AI_REPORTS.GEN_PDF' | translate }}</p>
										</div> -->

										<div class="report-list" [ngClass]="loadingList || isGeneratingPdf ? '' : 'show' ">
											<!-- [hidden]="session.isLoadingAiRepList()" -->

											<table mat-table [dataSource]="dataSource" class="row-border hover" matSort>
												<!-- *ngIf="session.hasLoadedPatients()" -->

												<ng-container matColumnDef="id">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
													<td mat-cell *matCellDef="let row">{{ row.id }}</td>
												</ng-container>

												<ng-container matColumnDef="creation_date">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AI_REPORTS.REQUESTED' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{ session.formatDateTime(row.creation_date) }}</td>
												</ng-container>

												<ng-container matColumnDef="last_update">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AI_REPORTS.UPDATED' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{ session.formatDateTime(row.last_update) }}</td>
												</ng-container>

												<ng-container matColumnDef="received">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AI_REPORTS.RECEIVED' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{ session.formatDateTime(row.received) }}</td>
												</ng-container>

												<ng-container matColumnDef="channel">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'AI_REPORTS.TYPE' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{getChannel(row)}}</td>
													<!-- funzione  -->
												</ng-container>

												<!-- <ng-container matColumnDef="lang">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.LANG' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{ row.lang }}</td>
												</ng-container> -->

												<ng-container matColumnDef="device">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.DEVICE' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{ row.device }}</td>
												</ng-container>

												<ng-container matColumnDef="status">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MISC.STATUS' | translate }}</th>
													<td mat-cell *matCellDef="let row">{{ row.getStatus() }}</td>
												</ng-container>

												<ng-container matColumnDef="batch">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>batch</th>
													<td mat-cell *matCellDef="let row">{{ row.batch_id }}</td>
												</ng-container>

												<ng-container matColumnDef="TL">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>
														<span ngbTooltip="{{ 'AI_REPORTS.TL_FULL' | translate }}" placement="bottom-start">{{ 'AI_REPORTS.TL' | translate }}</span>
													</th>
													<td mat-cell *matCellDef="let row">
														<!-- {{row.getTrafficLight()}} -->
														<span class="text-center pointer" (click)="showGrading(row)">
															<div id="color-badge" [ngClass]="row.getTrafficLight()">{{getTlFaIcon(row)}}</div>
														</span>
														<!-- 
													<ng-container *ngIf="{dR: getTlDotReport(row)} as variable" >
													<span><i class="status-icon nomargin {{variable.dR.class}}" ngbTooltip='{{variable.dR.tooltip}}' placement="right"><b>{{variable.dR.label}}</b></i></span>                
													</ng-container>
													--></td>
												</ng-container>

												<ng-container matColumnDef="grading">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>grading</th>
													<td mat-cell *matCellDef="let row">
														<a (click)="showGrading(row)" ngbTooltip="show..." placement="left">
															<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
														</a>
													</td>
												</ng-container>

												<!-- Actions Column -->
												<ng-container matColumnDef="actions">
													<th mat-header-cell *matHeaderCellDef>&nbsp;{{ 'BUTTONS.DOWNLOAD' | translate }}</th>
													<td mat-cell *matCellDef="let row">
														<a class="table-button ms-4" *ngIf="session.isLevel1() && row.status >= 3" (click)="downloadPdf(row)" ngbTooltip="{{ 'BUTTONS.DOWNLOAD' | translate }}" placement="right" style="font-size: 20px; color: #64a8f7">
															<fa-icon [icon]="faSave" aria-hidden="true"></fa-icon>
														</a>
													</td>
												</ng-container>

												<ng-container matColumnDef="filter">
													<th mat-header-cell *matHeaderCellDef class="text-end">
														<mat-form-field appearance="outline">
															<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
														</mat-form-field>
													</th>
													<td mat-cell *matCellDef="let element"></td>
												</ng-container>

												<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
												<tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="row.status == 3 ? 'fw-bold' : ''"></tr>

												<!-- Row shown when there is no matching data. -->
												<!-- <tr class="mat-row" *matNoDataRow>
													<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
												</tr> -->
											</table>

											<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons> </mat-paginator>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

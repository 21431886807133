<div class="panel panel-modal panel-default ng-cloak position-relative">
	<div class="container">
		<div class="panel-heading">
			<p class="panel-title text-center" *ngIf="isExit; else attention"><b>{{"CONFIRM.EXIT_TITLE" | translate}}</b></p>

			<ng-template #attention>
				<p class="panel-title text-center" style="color: #ff3b2d"><fa-icon class="me-3" [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon><b>{{"CONFIRM.CONFIRM_TITLE" | translate}}</b></p>
			</ng-template>

			<div class="btn-close-container-pos">
				<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
			</div>
		</div>

		<div class="panel-body">
			<form class="row" #confirmFrom="ngForm" *ngIf="isExit; else confirm">
				<div class="form-group col-8">
					<label for="confirm">{{"MISC.PASSWORD" | translate}}</label>
					<br />
					<input type="text" class="form-control" maxlength="20" id="confirm" name="confirm" [ngClass]="{'has-error': (psw.touched && !psw.valid)}" [(ngModel)]="password" #psw="ngModel" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" #pwd required />
					<p class="error-message" style="text-align: right" [hidden]="!wrongPwd">{{"MESSAGE.WRONG_PWD" | translate}}</p>
				</div>
				<div class="form-group col-4 d-flex flex-column justify-content-end">
					<button class="btn btn-primary" [disabled]="confirmFrom.invalid || submitDisable" (click)="exit()">{{"CONFIRM.EXIT_BUTTON" | translate}}</button>
				</div>
			</form>

			<ng-template #confirm>
				<div class="row">
					<div class="col-12 text-center">
						<p [innerHTML]="warnText"></p>
					</div>
					<div class="form-group col-12 text-center">
						<button class="btn btn-primary shadow" (click)="close()">{{"CONFIRM.OK" | translate}}</button>

						<button class="btn btn-secondary shadow ms-3" *ngIf="isQuest" (click)="dismiss()">{{"CONFIRM.CANCEL" | translate}}</button>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>

import { Component, Input, OnInit } from '@angular/core'

import { Config } from '../../../config'
import { DataModelService } from '../../service/data-model.service'
import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'

import { AgreementModal } from 'src/app/elements/agreement-modal/agreement-modal.modal'

@Component({
	selector: 'agreement',
	templateUrl: './agreement.component.html',
	styleUrls: ['./agreement.component.scss'],
})
export class AgreementComponent implements OnInit {
	currentModal: NgbModalRef

	constructor(public session: SessionService, public modalService: NgbModal, public dataService: DataModelService) {}

	ngOnInit(): void {
		Util.debug('(agreem) onInit')

		this.currentModal = this.modalService.open(AgreementModal, { size: 'xl', keyboard: false, backdrop: 'static' })

		this.currentModal.result
			.then(() => {
				Util.debug('Agreements accepted')
				this.session.loadMyPage(Config.activation)
			})
			.catch(() => {
				Util.debug('Agreements not accepted')
				this.session.getLandingPage()
			})
	}
}

import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, Output } from '@angular/core'
import { FormGroup, FormControl } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'
import { faSave } from '@fortawesome/free-regular-svg-icons'
import { faCalculator, faXmark, faFileCsv, faSpinner } from '@fortawesome/free-solid-svg-icons'

import { SessionService } from '../../service/session.service'

import { Relation } from 'src/app/models/specialist.model'

import { Util } from '../../models/util.model'
import { Report } from '../../models/report.model'
import { CsvLine } from '../../models/csvLine.model'

import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { TablePrefs } from '../../models/settings.model'

export interface toCSV {
	report_id: number
	patient_id: number
	optician_name: string
	report_type: string
	creation_date: string
}

@Component({
	selector: 'activities',
	templateUrl: './activities.component.html',
	styleUrls: ['./activities.component.scss'],
})
export class ActivitiesList implements OnInit, AfterViewInit {
	reportListTable: MatTableDataSource<Report>
	@Input() activitiesList: Report[] // Arriva dal parent

	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef
	displayedColumns: string[]
	months: string[]

	@Output() reportPref: TablePrefs
	localStorageName: string

	isLoading: boolean
	loadCSV: boolean

	filterForm: FormGroup
	filterSet: boolean

	faSave = faSave // per usarla su html  13.01.2022
	faCalculator = faCalculator
	faXmark = faXmark
	faFileCsv = faFileCsv
	faSpinner = faSpinner

	// calcolate date
	year: number
	month: number
	full: number
	fundus: number
	yearsOptions: number[]

	constructor(
		public session: SessionService,
		public translator: TranslateService,
		private myMatPagIntl: MatPaginatorIntl // private activatedRoute: ActivatedRoute
	) {
		Util.debug('[ActivitiesList] constructor')

		this.filterSet = false

		this.isLoading = true
		this.loadCSV = false

		// calcolate date
		this.year = null
		this.month = null
		this.full = 0
		this.fundus = 0
		this.yearsOptions = [this.translator.instant('ACTIVITIES.ALL')]

		this.months = [
			this.translator.instant('ACTIVITIES.ALL'),
			this.translator.instant('MONTHS.JANUARY'),
			this.translator.instant('MONTHS.FEBRUARY'),
			this.translator.instant('MONTHS.MARCH'),
			this.translator.instant('MONTHS.APRIL'),
			this.translator.instant('MONTHS.MAY'),
			this.translator.instant('MONTHS.JUNE'),
			this.translator.instant('MONTHS.JULY'),
			this.translator.instant('MONTHS.AUGUST'),
			this.translator.instant('MONTHS.SEPTEMBER'),
			this.translator.instant('MONTHS.OCTOBER'),
			this.translator.instant('MONTHS.NOVEMBER'),
			this.translator.instant('MONTHS.DECEMBER'),
		]

		this.filterForm = new FormGroup({
			year: new FormControl(this.yearsOptions[0]),
			month: new FormControl(this.months[0]),
		})

		// this.ActivitiesListTemp = []
		this.activitiesList = []
		this.reportListTable = new MatTableDataSource(this.activitiesList)

		// saving preferences
		this.localStorageName = this.session.getUserId() + ' - activitiesPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.reportPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.reportPref = new TablePrefs()

			this.reportPref.empty = false
			// default data
			this.reportPref.sort = 'creation_date'

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportPref))
		}

		// console.log(this.months)
	}

	ngOnInit(): void {
		Util.debug('[ActivitiesList] onInit')

		// console.log(this.activitiesList)

		this.initColumns()

		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')
	}

	ngAfterViewInit() {
		Util.debug('[ActivitiesList] afterInit')
		this.initTable() // don't touch -- the NG0100 error is normal *only in development*
	}

	initColumns() {
		Util.debug('[ActivitiesList] initColumns')
		// 27.12.2022 no download per il support, si diagn_grp
		//this.displayedColumns = ['report_id', 'creation_date', 'diagnosis', 'created_by', 'download']
		this.displayedColumns = ['id', 'creation_date', 'optician_name', 'patient_id', 'report_type', 'filter']
	}

	private ApplySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		if (this.input) {
			this.input.nativeElement.value = pref.filter
		} else {
			console.log('dead') //??
		}

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}

	private initTable() {
		Util.debug('(ActivitiesList) - initTable')
		if (this.activitiesList) {
			this.reportListTable = new MatTableDataSource(this.activitiesList)
			this.reportListTable.paginator = this.paginator
			this.reportListTable.sort = this.sort

			this.initYearsOptions()

			// automatic sort
			this.ApplySettings(this.reportPref, this.reportListTable)

			let id = this.session.getUserId()
			this.loadDistribDoc(id)
		} else {
			Util.debug('(Reports) null activities list!')
		}

		this.isLoading = false
	}

	private loadDistribDoc(id) {
		Util.debug('(ActivitiesList) - loadDistribDoc start')

		this.session
			.loadRelationsList(id)
			.then((resp) => {
				// this.relations = resp

				// console.log(resp)

				this.operatorNameAssignment(resp)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	// Assegno i nomi degli operatori invece di avere l'id
	private operatorNameAssignment(resp: Relation[]) {
		Util.debug('(ActivitiesList) - operatorNameAssignment start')
		let actList = this.activitiesList

		// console.log(actList)

		for (let i = 0; i < actList.length; i++) {
			const act = actList[i]

			for (let n = 0; n < resp.length; n++) {
				const oper = resp[n]

				if (act.optician_id == +oper.doctor_id) {
					act.optician_name = oper.username
				}
			}

			if (act.optician_name == '') {
				act.optician_name = act.optician_id.toString()
			}
		}

		// console.log(this.patientListRaw)
	}

	// called once
	initYearsOptions() {
		Util.debug('[ActivitiesList] initYearsOptions')
		let init = this.activitiesList
		for (let i = 0; i < init.length; i++) {
			if (!this.yearsOptions.includes(init[i].creation_date.getFullYear())) {
				this.yearsOptions.push(init[i].creation_date.getFullYear())
			}
		}
	}

	public filterValue() {
		Util.debug('(ActivitiesList) - filter called')
		this.filterSet = false

		let activities = this.activitiesList

		let yearFilter = this.filterForm.get('year').value
		let monthFilter = this.filterForm.get('month').value

		this.year = yearFilter
		this.month = monthFilter

		// Year filter
		if (yearFilter != this.translator.instant('ACTIVITIES.ALL')) {
			this.filterSet = true

			activities = activities.filter((d) => d.creation_date.getFullYear() == yearFilter)
		}

		// Month filter
		if (monthFilter != this.translator.instant('ACTIVITIES.ALL')) {
			this.filterSet = true

			let index = this.months.indexOf(monthFilter) - 1 // in month ho All che é = 0
			activities = activities.filter((d) => d.creation_date.getMonth() == index)
		}

		// console.log(activities)

		this.reportListTable = new MatTableDataSource(activities)
		this.reportListTable.paginator = this.paginator
		this.reportListTable.sort = this.sort

		if (this.filterSet) {
			this.calcExams(activities)
		}
	}

	private calcExams(list: Report[]) {
		this.full = 0
		this.fundus = 0

		for (let i = 0; i < list.length; i++) {
			const rep = list[i]

			if (rep.report_type == 'full') {
				this.full++
			}

			if (rep.report_type == 'fundus') {
				this.fundus++
			}
		}

		Util.debug('(ActivitiesList) - full: ' + this.full + ' fundus: ' + this.fundus)
	}

	public calcActDateExport() {
		Util.debug('(ActivitiesList) - calcActDateExport start')

		this.loadCSV = true

		let filterList = this.reportListTable.filteredData.slice()

		let exportFilterList: toCSV[]
		let el: toCSV

		exportFilterList = []

		// let newfilterList = filterList.map(({ optician_id, diagn_group, refDisplayName, status, created_by, ...item }) => item) // levo le colonne che non servono, ma poi non posso formattare la data

		for (let i = 0; i < filterList.length; i++) {
			const act = filterList[i]

			el = {
				report_id: act.id,
				patient_id: act.optician_id,
				optician_name: act.optician_name,
				report_type: act.report_type,
				creation_date: this.session.formatDateTime(act.creation_date),
			}

			exportFilterList.push(el)
		}

		let tmp = Util.convertToCSV(exportFilterList)

		let strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)
		if (this.month) {
			var filename = 'export' + '_' + this.year + '_' + this.month + '_' + Date.now() + '.csv'
		} else {
			var filename = 'export_all' + '_' + Date.now() + '.csv'
		}

		let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })

		setTimeout(() => {
			Util.mySaveAs(filename, blob)
			this.loadCSV = false
		}, 600)
	}

	filterText() {
		this.reportPref.filter = this.input.nativeElement.value

		this.reportPref.filter = this.reportPref.filter.trim().toLocaleLowerCase()

		this.reportListTable.filter = this.reportPref.filter
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportPref))
		// localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.reportPref.itemsPerPage))
	}
}

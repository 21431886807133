import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { Anamnesis, GroupedQuestions, VA, visus } from 'src/app/models/anamnesis.model'

// import { patternVisusDirective } from './pattern-visus.directive'

import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'

import { faArrowRight, faCheck, faDownload, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'

interface QuestionDependency {
	q: string[]
	r: {
		[key: string]: number[]
	}
}

@Component({
	selector: 'app-pat-anamnesis',
	templateUrl: './pat-anamnesis.component.html',
	styleUrls: ['./pat-anamnesis.component.scss'],
})
export class PatAnamnesisComponent implements OnInit {
	@Input() thisAnamnesis: Anamnesis[]
	@Input() currentStep: number = 1 // Input to track the current step
	@Output('completed') anamnesisCompleted = new EventEmitter<boolean>()
	@Output('out-anamnesis') outAnamnesis = new EventEmitter<Anamnesis[]>()
	@Output('out-va') outVa = new EventEmitter<VA>()
	@Output() stepsFetched = new EventEmitter<{ totalSteps: number }>()

	@ViewChild('anamnesis') anamnesisBody: ElementRef

	@HostListener('keydown', ['$event'])
	onKeydown(event: KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault() // Impedisce il comportamento predefinito del tasto Tab
		}
	}

	haveAnamesis: boolean

	anamnesisDate: Date

	thisAnamnesisCopy: Anamnesis[]

	formValid: { id: number; reply: boolean }[]

	groupedQuestions: GroupedQuestions[]
	steps: string[]

	faDownload = faDownload
	faArrowRight = faArrowRight
	faCheck = faCheck
	faQuestion = faQuestionCircle
	
	anamesisEntryText: string

	constructor(public session: SessionService, private translator: TranslateService) {
		// this.thisAnamnesis = new Anamnesis()
		// console.log(this.thisAnamnesis)

		Util.debug('(PatAnamnesisComponent) - Constructor ')

		this.haveAnamesis = false
		this.anamnesisDate = null
		this.anamesisEntryText = translator.instant('ANAMNESIS.ENTRY')
	}

	ngOnInit(): void {
		Util.debug('(PatAnamnesisComponent) - ngOnInit ')
		this.groupedQuestions = []
		this.steps = []
		this.formValid = []

		// console.log(this.thisAnamnesis)

		if (this.thisAnamnesis && this.thisAnamnesis.length > 0) {
			this.haveAnamesis = true

			// creo una deep copy, in modo da non modificare l'array originale, ma non funziona se ci sono array annidati, in quelli rimangono le reference
			this.thisAnamnesisCopy = this.thisAnamnesis
				.map((x) => Object.assign({}, x))
				.sort((a, b) => {
					// Split the level strings into arrays of numbers
					const aLevels = a.level.split('.').map(Number)
					const bLevels = b.level.split('.').map(Number)

					// Compare each level sequentially
					for (let i = 0; i < aLevels.length; i++) {
						if (aLevels[i] !== bLevels[i]) {
							return aLevels[i] - bLevels[i]
						}
					}

					// If all levels are equal, return 0 (this case is rare and means they're equal)
					return 0
				})
			this.anamnesisDate = this.thisAnamnesisCopy[0].answered
			this.groupedQuestions = Object.values(
				this.thisAnamnesisCopy.reduce((acc, question) => {
					const levelParts = question.level.split('.')
					const substep = levelParts[1] // Get the substep (second number of level)

					if (!acc[substep]) {
						acc[substep] = {
							substep,
							questions: [],
						}
					}

					if (levelParts[2] !== '0') {
						acc[substep].questions.push(question)
					}

					return acc
				}, {} as { [key: string]: GroupedQuestions })
			)

			// Convert the grouped object into an array of GroupedQuestions
			this.steps = this.groupedQuestions.map((el) => this.translator.instant(`ANAMNESIS.SUBSTEP_TITLE_${el.substep}`))
			this.stepsFetched.emit({ totalSteps: this.steps.length })

			this.groupedQuestions.forEach((group) => {
				group.questions.forEach((question) => {
					let ansLen = question.answers

					// Make a deep copy of answers to avoid references
					question.answers = this.thisAnamnesis.find((q) => q.question_id === question.question_id).answers.map((x) => Object.assign({}, x))

					let obj = { id: question.question_id, reply: false }

					// Check if any answers are already checked
					ansLen.forEach((ans) => {
						if (ans.checked) {
							obj = { id: question.question_id, reply: true }
						}
					})

					this.formValid.push(obj)
				})
			})

			this.anamnesisCompleted.emit(this.allQuestionsAnswered())
		}
	}

	toggle(obj: Anamnesis, n: number) {
		// console.log(obj)
		let answers = obj.answers

		//quando ne seleziono una, devo deselezionare le altre risposte per evitare risposte multiple
		if (obj.input_type === 'radio') {
			for (let i = 0; i < answers.length; i++) {
				answers[i].checked = false
			}
		}

		//imposto true solo quella selezionata
		answers[n].checked = !answers[n].checked

		this.anamnesisCompleted.emit(this.allQuestionsAnswered())

		this.outAnamnesis.emit(this.thisAnamnesisCopy)
		// console.log(this.thisAnamnesisCopy)
	}

	onSliderInputChange(event: Event, question: Anamnesis) {
		const val = (event.target as HTMLInputElement).value
		let answers = question.answers

		for (let i = 0; i < answers.length; i++) {
			answers[i].checked = false
		}

		//imposto true solo quella selezionata
		answers[val].checked = !answers[val].checked

		let count = 0

		for (let i = 0; i < this.formValid.length; i++) {
			const element = this.formValid[i]

			if (element.id == question.question_id) {
				element.reply = true // mi segno che é stata risposta
			}

			if (element.reply) {
				count++

				// se sono tutti true allora posso inviare
				if (count == this.formValid.length) {
					this.anamnesisCompleted.emit(true)
				} else {
					this.anamnesisCompleted.emit(false)
				}
			}
		}

		this.outAnamnesis.emit(this.thisAnamnesisCopy)
	}

	getSliderDefaultValue(question: Anamnesis): number {
		const checkedAnswerIndex = question.answers.findIndex((answer) => answer.checked)
		return checkedAnswerIndex >= 0 ? checkedAnswerIndex : 0 // Fallback to 0 if none is checked
	}

	questionDependenciesSatisfied(question: Anamnesis): boolean {
		try {
			const dependency: QuestionDependency = JSON.parse(question.depends_on)

			let flag = true

			dependency.q.forEach((depQuestionLevel) => {
				const depQuestion = this.thisAnamnesisCopy.find((anamQuestion) => anamQuestion.level === depQuestionLevel)

				if (depQuestion) {
					// Check if any of the specified replies are checked
					const isAnyChecked = dependency.r[depQuestionLevel].some((replyIndex) => {
						const replyId = depQuestion.answers[replyIndex - 1].reply_id
						const replyElement = document.getElementById(`btn-check-${depQuestion.question_id}-${replyId}`) as HTMLInputElement
						return replyElement && replyElement.checked
					})

					if (!isAnyChecked) {
						flag = false
					}
				}
			})

			// If dependencies are not satisfied, uncheck all inputs of the current question
			if (!flag) {
				question.answers.forEach((answer) => {
					const replyElement = document.getElementById(`btn-check-${question.question_id}-${answer.reply_id}`) as HTMLInputElement
					if (replyElement) {
						answer.checked = false
						replyElement.checked = false
					}
				})
			}

			return flag
		} catch (error) {
			console.log('There was an error checking the dependency question(s):', error)
		}
	}

	public allQuestionsAnswered() {
		const allQuestionsAnswered = this.groupedQuestions.every((group) =>
			group.questions.every((question) => question.answers.some((answer) => answer.checked === true))
		)

		return allQuestionsAnswered
	}
}

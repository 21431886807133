import { Component, OnInit, Input, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

//import { BrowserModule } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule

import { faCalendarAlt } from '@fortawesome/free-regular-svg-icons'

import { SessionService } from '../../service/session.service'
import { DevicesComponent } from './devices.component'

import { Device, SwUpdate } from '../../models/device.model'

import { Util } from '../../models/util.model'
//import { DateParser } from '../../models/dateParser.model';

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'device-modal',
	templateUrl: './device.modal.html',
	styleUrls: ['./device.modal.scss'],
	providers,
})
export class DeviceModal implements OnInit {
	@Input() parent: DevicesComponent
	@Input() currentDevice: Device

	deviceUpdtList: SwUpdate[] // relativa a currentDevice
	zeroUpdates: boolean

	constructor(public activeModal: NgbActiveModal, public session: SessionService, public translator: TranslateService) {
		// qui non ho ancora session dal parent ?
		this.zeroUpdates = false
	}

	ngOnInit(): void {
		this.deviceUpdtList = []
		if (this.currentDevice) {
			Util.debug('(device) id ' + this.currentDevice.id)
		} else {
			Util.debug('(device) not loaded yet... ')
		}
	}

	getCurrentPacksInfo() {
		let info = ''
		//Util.debug("(device) pack info...");
		if (this.currentDevice != null && this.currentDevice.packages != null) {
			for (let i = 0; i < this.currentDevice.packages.length; i++) {
				let up = this.currentDevice.packages[i]
				info += '[' + (i + 1) + '] ' + up.name + ' (build: ' + up.build + ' N: ' + up.build_num + ') \n'
			}
		} else {
			Util.debug('(device) PACKS - not loaded yet... ')
		}

		return info
	}

	passBack() {
		// todo
		if (this.validateNewDevice()) {
			// ok, proceed
			this.activeModal.close(this.currentDevice)
		} else {
			Util.debug('(passBack) stay here...')
		}
	}

	// 02.09.2022 close without doing anything
	dismiss() {
		Util.debug('(device) dismiss, no action')
		this.activeModal.close(false)
	}

	// 23.03.2021 richiamato dal modal aperto su un device
	checkUpdates() {
		var deviceId = this.currentDevice.id
		//alert('TODO - checkUpdates')

		// 13.04.2023
		this.session
			.getDevicesUpdates(deviceId)
			.then((myList) => {
				// Display shortly in the modal
				this.deviceUpdtList = myList

				for (let i = 0; i < this.deviceUpdtList.length; i++) {
					console.log(i + ' - ' + this.deviceUpdtList[i].toStringShort())
				}

				if (!this.deviceUpdtList || this.deviceUpdtList.length == 0) {
					this.zeroUpdates = true
				}
			})
			.catch((err) => {
				if (!this.session.isExpired(err)) {
					//  e' un altro errore, va segnalato
					//var msg = (err.data)? err.data.error : err.toString();
					let msg = this.session.parseErrorMessage(err)
					alert(msg)
				}
			})
	}

	// 24.02.2022
	validateNewDevice(): boolean {
		let flag = false
		/*
    Util.debug("(validateNewDevice) sex: "+this.currDevice.sex);  

    let oldPat = this.getDuplicated();   
    if(oldPat>0){
      
      //let msg = "A device with same Name, Surname, Gender and Date of Birth already exists! \n";
      //   msg += "Press OK to abandon this form and see the existing device's page.";

      let msg = this.translator.instant('PATIENT.MESSAGE_EXISTING_PATIENT'); 
      
      if(confirm(msg) == true){             
        this.activeModal.dismiss("goto:"+oldPat);   // close modal
        flag = false;
        //this.showVisits(oldPat);  //go to single page, done on the controller      
      } else {
        // nothing...
        Util.debug("(validateNewDevice) stay here...");
        flag = true;
      } 
    } else {
      flag = true;
    }  
    */
		return flag
	}
}

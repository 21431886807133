<div class="panel panel-modal panel-default ng-cloak position-relative">
	<!-- modal-panel -->
	<div class="panel-heading">
		<label [hidden]="currentAction != 'support'" class="panel-title">{{"MISC.VIEW_TITLE" | translate}}</label>
		<label [hidden]="currentAction != 'modify'" class="panel-title"> {{"DOCTOR.MODAL_TITLE_UPDATE" | translate}}</label>
		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>
	</div>
	<div class="panel-body">
		<form #adminForm="ngForm" name="adminForm">
			<div class="row">
				<div class="full-container fields-container">
					<div class="row">
						<div class="form-group col-4">
							<label for="username">{{"DISTRIB.USERNAME" | translate}}</label>
							<input type="text" class="form-control" readonly name="username" id="username" [(ngModel)]="currentAdmin.username" />
						</div>
						<div class="form-group col-4">
							<label for="profile">{{"DOCTOR.TYPE" | translate}}</label>
							<input type="text" class="form-control" readonly name="profile" id="profile" [(ngModel)]="currentAdmin.user_type" />
						</div>

						<div class="form-group col-2">
							<label for="subtype">{{"DOCTOR.SUBTYPE" | translate}}</label>
							<input type="text" class="form-control" readonly name="subtype" id="subtype" [(ngModel)]="currentAdmin.user_subtype" />
						</div>

						<div class="form-group col-2">
							<label for="code">{{"DOCTOR.MODAL_CODE" | translate}}</label>
							<input type="text" class="form-control" readonly name="code" id="code" [(ngModel)]="currentAdmin.code" />
						</div>
					</div>

					<div class="row">
						<div class="form-group col-4">
							<label for="firstname">{{ 'CREATE_USER.FIRSTNAME' | translate }}</label>
							<input type="text" name="firstname" class="form-control" readonly id="firstname" [(ngModel)]="currentAdmin.firstName" />
						</div>
						<div class="form-group col-4">
							<label for="lastname">{{ 'CREATE_USER.LASTNAME' | translate }}</label>
							<input type="text" class="form-control" name="lastname" id="lastname" [(ngModel)]="currentAdmin.lastName" readonly />
						</div>

						<div class="form-group col-4">
							<label for="country">{{ 'PATIENT.MODAL_COUNTRY' | translate }} </label>
							<input type="text" name="country" class="form-control" readonly id="country" value="{{getReadOnlyCountry()}}" />
						</div>
					</div>

					<div class="row">
						<div class="form-group col-4">
							<label for="country">{{ 'DOCTOR_LIST.CLIENT_SINCE' | translate }} </label>
							<input type="text" name="subscriptionTime" class="form-control" readonly id="subscriptionTime" value="{{session.formatDate(currentAdmin.subscriptionTime)}}" />
						</div>
					</div>

					<div class="row">&nbsp;</div>

					<div class="row">
						<div class="form-group third-container" *ngIf="currentAdmin.isManager()">
							<span class="normalcase"> Actual list of Models: {{currentAdmin.admSettings.models}}</span>
						</div>
					</div>

					<div class="row" *ngIf="currentAdmin.isManager()">
						<div class="form-group third-container">
							<ul class="list-group" id="list1">
								<li class="list-group-item active">AVAILABLE</li>
								<!-- dinamico: getModels() -->
								<li *ngFor="let model of listAvail; index as i" class="list-group-item"><input type="checkbox" name="{{i}}_av" (change)="addModel(i)" id="{{model}}" /> {{model}}</li>
							</ul>
						</div>

						<div class="form-group sixth-container v-center">
							<br />
							<label class="center-block"> choose </label>
							<br />
							<fa-icon [icon]="faArrowRight" aria-hidden="true"></fa-icon>
							<br />
							<fa-icon [icon]="faArrowLeft" aria-hidden="true"></fa-icon>
						</div>
						<div class="form-group third-container">
							<ul class="list-group" id="list2">
								<li class="list-group-item active">SELECTED</li>
								<!-- <input title="toggle all" type="checkbox" class="all pull-right"> -->
								<li *ngFor="let model of listSelected; index as j" class="list-group-item"><input type="checkbox" name="{{j}}_sel" (change)="removeModel(j)" id="{{j}}_{{model}}" /> {{model}}</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

			<div class="spacer"></div>

			<div class="row fields-container" *ngIf="currentAdmin.isManager()">
				<!-- <div class="twothird-container">
					<div class="row">
						// password per conferma edit by admins
						<div class="third-container">
							<input
								type="password"
								class="form-control"
								class="form-control"
								name="password"
								#fn="ngModel"
								id="password"
								placeholder="{{'MESSAGE.MNGR_PWD_HINT' | translate}}"
								[(ngModel)]="dispPassword"
								required />
							<div class="error-wrapper error-message-container">
								<p class="error-message" [hidden]="!(fn.touched && fn.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
								&nbsp;
								<p class="error-message text-danger" [hidden]="!(wrongPwd)">{{'MESSAGE.WRONG_PWD' | translate}}</p>
							</div>
						</div>
					</div>
				</div> -->

				<div class="third-container">
					<div class="row">
						<div class="half-container">
							<button type="submit" [disabled]="adminForm.invalid" (click)="editAdmin()" class="btn btn-primary">{{'BUTTONS.SAVE' | translate}}</button>
						</div>
						<div class="half-container">
							<button type="clear" (click)="activeModal.dismiss('undo');" class="btn btn-secondary">{{"BUTTONS.UNDO" | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<div class="panel-auto-relation container-fluid p-3">
	<div class="row">
		<div class="col-12 panel-auto-relation__header fw-bold pb-2">
			<span><fa-icon class="me-2" [icon]="faCircleInfo"></fa-icon></span>
			<span>{{'RELATIONS.CLOSER_CLINIC_MODAL_TITLE' | translate}}</span>
		</div>
	</div>

	<div class="row">
		<div class="col-12 panel-auto-relation__body py-4">
			<p>{{hasMultipleRelations ? ('RELATIONS.CLOSER_CLINIC_MODAL_BODY_MG' | translate: { city, distance }) : ('RELATIONS.CLOSER_CLINIC_MODAL_BODY_SG' | translate: { city, distance })}}</p>
		</div>
	</div>

	<div class="row">
		<div class="col-12 panel-auto-relation__buttons">
			<div *ngIf="hasMultipleRelations; else singleGraderButtons" class="d-flex justify-content-center">
				<button class="btn btn-primary" (click)="refuseGraderSwitch('pending')">{{'MISC.OK' | translate}}</button>
			</div>

			<ng-template #singleGraderButtons>
				<div *ngIf="confirmGraderSwitchFlag" class="warning mb-3">
					<fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon>
					<b> {{'RELATIONS.CLOSER_CLINIC_MODAL_CONFIRMATION_MSG' | translate }}</b>
				</div>

				<div class="d-flex justify-content-around">
					<button class="btn btn-primary" (click)="acceptGraderSwitch()">{{'MISC.YES' | translate}}</button>
					<button class="btn btn-secondary" (click)="refuseGraderSwitch('rejected')">{{'MISC.NO' | translate}}</button>
				</div>
			</ng-template>
		</div>
	</div>

	<!-- <h4 class="mb-3">{{'RELATIONS.CLOSER_CLINIC_MODAL_TITLE' | translate}}</h4>
	<p>{{hasMultipleRelations ? ('RELATIONS.CLOSER_CLINIC_MODAL_BODY_MG' | translate: { city, distance }) : ('RELATIONS.CLOSER_CLINIC_MODAL_BODY_SG' | translate: { city, distance })}}</p>

	<div *ngIf="hasMultipleRelations; else singleGraderButtons" class="d-flex justify-content-center">
		<button class="btn" (click)="refuseGraderSwitch('pending')">{{'MISC.OK' | translate}}</button>
	</div>

	<ng-template #singleGraderButtons>
		<div *ngIf="confirmGraderSwitchFlag" class="warning mb-3">
			<fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon>
			<b> {{'RELATIONS.CLOSER_CLINIC_MODAL_CONFIRMATION_MSG' | translate | uppercase}}</b>
		</div>

		<div class="d-flex justify-content-around">
			<button class="btn bg-dark border-dark" (click)="refuseGraderSwitch('rejected')">{{'MISC.NO' | translate}}</button>
			<button class="btn" (click)="acceptGraderSwitch()">{{'MISC.YES' | translate}}</button>
		</div>
	</ng-template> -->
</div>

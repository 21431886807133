<div class="panel panel-modal position-relative">
	<div class="panel-heading">
		<span class="panel-title">New Device</span>
	</div>

	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
	</div>

	<div class="panel-body">
		<form name="deviceForm" class="container" #deviceForm="ngForm">
			<div class="row">
				<div class="form-group col-2">
					<label for="patient_id">PATIENT ID</label>
					<input type="text" class="form-control" id="patient_id" readonly value="{{currentPatientId}}" />
				</div>

				<div class="form-group col-5">
					<label for="device">DEVICE</label>
					<select name="device" class="form-select" id="device-model" [(ngModel)]="device.model" required>
						<option *ngFor="let model of models" [value]="model">{{model}}</option>
						<!-- ['VX650', 'VX600', 'VX130', 'VX120', 'ER', 'VX40', 'DEM', 'NEXY'] -->
					</select>
				</div>
				<div class="form-group col-5">
					<label for="name">Serial Number</label>
					<input type="text" minlength="4" maxlength="8" uppercase class="form-control" id="sn" name="sn" [(ngModel)]="device.sn" required />
				</div>
			</div>

			<div class="row mt-4">
				<div class="col-12 d-flex justify-content-around">
					<div class="text-end">
						<button type="button" class="btn btn-primary" [disabled]="deviceForm.invalid" (click)="submit()">{{"BUTTONS.SUBMIT" | translate}}</button>
					</div>
					<div class="text-end">
						<button type="button" class="btn btn-secondary" (click)="dismiss();">{{'BUTTONS.UNDO' | translate}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

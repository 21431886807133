import { Component, OnInit, ViewChild } from '@angular/core'

import { SessionService } from 'src/app/service/session.service'
import { Util } from '../../models/util.model'
import { TranslateService } from '@ngx-translate/core'
import {
	ColumnDatas,
	ColumnGraph,
	ColumnGraphWithMarkers,
	ColumnSeries,
	ColumnSeriesWithMarkers,
	Statistics,
	basicPieGraph,
	createArrays,
	dataWithMarkers,
	graders,
	performance,
	pieChartDatas,
	serieValues,
} from 'src/app/models/statistics.model'
import { User } from 'src/app/models/user.model'
import { DataModelService } from 'src/app/service/data-model.service'

import { FormControl, FormGroup, Validators } from '@angular/forms'

import { ToastOptions } from 'src/app/models/toast.model'
import { Distrib } from 'src/app/models/specialist.model'
import { AppToastService } from 'src/app/service/toast.service'

import * as _moment from 'moment'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'

import { faUserCheck, faUserXmark, faEllipsisVertical, faCircleMinus, faCirclePlus, faXmark } from '@fortawesome/free-solid-svg-icons'
import { ChartComponent } from 'ng-apexcharts'

const moment = _moment

export class DataInMemory {
	fromDate: string
	toDate: string
	examCount: any
	performance: performance[]

	constructor() {
		this.fromDate = ''
		this.toDate = ''
		this.examCount = null
		this.performance = []
	}
}

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
	graderList: MatTableDataSource<graders>
	@ViewChild(MatTable) table: MatTable<any>
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator
	@ViewChild(MatSort, { static: true }) sort: MatSort

	@ViewChild('chart1', { static: false }) chart: ChartComponent

	displayedColumns: string[]

	fromDate: moment.Moment
	toDate: moment.Moment

	totReports: number
	totFull: number
	totFundus: number
	avGradingTime: number
	avGradingTimeUnit: string
	avGradingTimeExceed: boolean

	isLoading: boolean

	currUser: User
	distribList: Distrib[] //lista distrib scaricata dal server
	distribListDeleted: Distrib[] // lista di quelli deleted
	showdeleted: boolean
	examCount: any // from statistics
	performance: performance[]
	graders: graders[] // lista custom creata per la dashboard
	gradersCopy: graders[]
	gradersName: string[]
	cadence: string[]
	diffDays: number // differenza in giorni tra fromDate e toDate

	columnChart: ColumnGraph // grafico report generati per grader
	columnChartCadence: ColumnGraph // grafico report generati con cadenza
	columnChartMarked: ColumnGraphWithMarkers // grafico performance tempo dalla richiesta di grading al report generato

	series: ColumnSeries[]
	seriesWithMarkers: ColumnSeriesWithMarkers

	chartReady: boolean
	chartCadenceReady: boolean

	oneSelected: boolean

	dashboardTime: FormGroup

	statInMemory: DataInMemory[] //array di report salvati
	statistic: DataInMemory // se quando chiedo la statistica. l'ho giá fatta, la copio dalla memoria e la metto qui

	performancePieCharts: basicPieGraph[]

	faUserCheck = faUserCheck
	faUserXmark = faUserXmark
	faEllipsisVertical = faEllipsisVertical
	faCircleMinus = faCircleMinus
	faCirclePlus = faCirclePlus
	faXmark = faXmark

	constructor(
		private session: SessionService,
		private translator: TranslateService,
		private dataService: DataModelService,
		private toastService: AppToastService
	) {
		Util.debug('(DashboardComponent) - constructor')

		this.displayedColumns = ['addremove', 'username', 'tot_reports_done', 'grading_time', 'icon']
		this.graderList = new MatTableDataSource<graders>([])

		this.isLoading = true
		this.currUser = this.session.user
		// console.log(this.currUser)

		this.totReports = 0
		this.totFull = 0
		this.totFundus = 0
		this.avGradingTime = 0
		this.avGradingTimeUnit = this.translator.instant('DASHBOARD.HOURS')
		this.avGradingTimeExceed = false

		this.distribList = []
		this.distribListDeleted = []
		this.showdeleted = false
		this.examCount = []
		this.performance = []
		this.gradersName = []
		this.cadence = []
		this.diffDays = 0

		this.graders = []
		this.gradersCopy = []
		this.series = createArrays.createSeries(['Total', 'Complete', 'Fundus'])
		this.seriesWithMarkers = { name: '', data: [] }

		this.chartReady = false
		this.chartCadenceReady = false
		this.oneSelected = false

		this.statInMemory = []
		this.statistic = null

		this.performancePieCharts = []

		this.fromDate = moment.utc(new Date(new Date().setDate(new Date().getDate() - 30)))
		this.toDate = moment.utc(new Date())

		this.dashboardTime = new FormGroup({
			fromDate: new FormControl(null, Validators.required),
			toDate: new FormControl(null, Validators.required),
			cadence: new FormControl(null),
		})

		this.dashboardTime.get('fromDate').setValue(this.fromDate)
		this.dashboardTime.get('toDate').setValue(this.toDate)

		this.setCadence()
	}

	ngOnInit(): void {
		// this.getPerformance()
	}

	private checkMemoryReport(): boolean {
		Util.debug('(statistics) - checkMemoryReport')
		let resp = false

		for (let i = 0; i < this.statInMemory.length; i++) {
			const stat = this.statInMemory[i]

			if (stat.fromDate == this.fromDate.format('YYYY-MM-DD') && stat.toDate == this.toDate.format('YYYY-MM-DD')) {
				this.statistic = stat

				return (resp = true)
			}
		}
		return resp
	}

	private saveReportMemory() {
		Util.debug('(statistics) - saveReportMemory start')

		// viene chiamato in 2 promise diverse ma devono salvare nello stesso oggetto, per cui la seconda che arriva, deve salvare nello stesso, e skippa il punto dove crea un nuovo oggetto
		let skip = false

		if (this.statInMemory.length > 0) {
			for (let i = 0; i < this.statInMemory.length; i++) {
				const stat = this.statInMemory[i]

				if (stat.fromDate == this.fromDate.format('YYYY-MM-DD') && stat.toDate == this.toDate.format('YYYY-MM-DD')) {
					stat.examCount = this.examCount
					stat.performance = this.performance

					skip = true
				}
			}
		}
		if (!skip) {
			let report: DataInMemory = new DataInMemory()

			report.fromDate = this.fromDate.format('YYYY-MM-DD')
			report.toDate = this.toDate.format('YYYY-MM-DD')
			report.examCount = this.examCount
			report.performance = this.performance

			this.statInMemory.push(report)
		}
		// console.log(this.statInMemory)
	}

	public setCadence() {
		Util.debug('(Statistics) - Set cadence')

		this.cadence = []
		this.dashboardTime.get('cadence').reset()
		this.dashboardTime.get('cadence').setValue(null)
		this.dashboardTime.get('cadence').disable()

		this.chartCadenceReady = false

		let toD = this.dashboardTime.get('toDate').value
		let fromD = this.dashboardTime.get('fromDate').value

		if (toD && fromD) {
			this.diffDays = toD.diff(fromD, 'days')

			// console.log(this.diffDays)

			if (this.diffDays <= 21) {
				// <=21 giorni solo days (3 settimane)
				this.cadence.push(Statistics.day)
				//
			} else if (this.diffDays > 21 && this.diffDays <= 84) {
				// da 3 settimane a 12 settimane days e weekly
				//
				this.cadence.push(Statistics.day, Statistics.weekly)
				//
			} else if (this.diffDays > 84 && this.diffDays <= 365) {
				// da 12 setimane a 1 anno weekly e monthly
				//
				this.cadence.push(Statistics.weekly, Statistics.monthly)
				//
			} else if (this.diffDays > 365 && this.diffDays <= 730) {
				// da 1 anno a 2 anni monthly e yearly
				//
				this.cadence.push(Statistics.monthly, Statistics.yearly)
				//
			} else {
				// sopra i 2 anni solo yearly
				//
				this.cadence.push(Statistics.yearly)
				//
			}
			this.dashboardTime.get('cadence').enable()
			// this.dashboardTime.get('cadence').setValue(this.cadence[0])
		}

		this.buildGraderList()
	}

	changeDate() {
		let fromdt = this.dashboardTime.get('fromDate').value
		let todt = this.dashboardTime.get('toDate').value

		if (fromdt && todt) {
			this.fromDate = fromdt
			this.toDate = todt

			this.setCadence()

			this.buildGraderList()

			Util.debug('(Statistics) - date changed from: ' + this.fromDate.format('YYYY-MM-DD') + ' to: ' + this.toDate.format('YYYY-MM-DD'))
		}
	}

	public showDeletedGraders(event) {
		this.showdeleted = event.target.checked
		this.buildGraderList()
	}

	private getPerformance(): Promise<boolean> {
		Util.debug('(DashboardComponent) - getPerformance')

		let fromDate = this.fromDate.format('YYYY-MM-DD')
		let toDate = this.toDate.format('YYYY-MM-DD')

		let opticians = null

		const promise = new Promise<boolean>((resolve, reject) => {
			let alreadyReq = this.checkMemoryReport()

			if (alreadyReq) {
				this.performance = this.statistic.performance

				resolve(true)
			} else {
				this.session
					.getStatistics(fromDate, toDate, Statistics.performances, opticians, null)
					.then((resp) => {
						// console.log(resp)
						this.performance = resp.performances

						this.saveReportMemory()

						resolve(true)
					})
					.catch((err) => {
						console.log(err)

						reject(false)
					})
			}
		})

		return promise
	}

	private getRemoteStatistics(): Promise<boolean> {
		Util.debug('(DashboardComponent) - getRemoteStatistics')

		let fromDate = this.fromDate.format('YYYY-MM-DD')
		let toDate = this.toDate.format('YYYY-MM-DD')

		// console.log(fromDate)
		// console.log(toDate)

		const promise = new Promise<boolean>((resolve, reject) => {
			let alreadyReq = this.checkMemoryReport()

			if (alreadyReq) {
				this.examCount = this.statistic.examCount
				resolve(true)
			} else {
				this.session
					.getExamsCount(fromDate, toDate, Statistics.hg_reports)
					.then((resp) => {
						this.examCount = resp
						// console.log(this.examCount)
						this.saveReportMemory()

						resolve(true)
					})
					.catch((err) => {
						console.log(err)

						reject(false)
					})
			}
		})

		return promise
	}

	private loadRemotes(): Promise<boolean> {
		Util.debug('(DashboardComponent) - loadRemotes')

		const promise = new Promise<boolean>((resolve, reject) => {
			if (this.dataService.hasLoadedDistribList()) {
				this.distribList = this.session.getDtDistribList()

				resolve(true)
			} else {
				this.session
					.loadRemotes(false)
					.then((data: Distrib[]) => {
						// console.log(data)
						this.distribList = data

						resolve(true)
					})
					.catch((err) => {
						console.log(err)
						if (!this.session.isExpired(err)) {
							var msg = err.data ? err.data.error : err.toString()
							// alert(msg)
							let header = this.translator.instant('TOAST.HEADER.ERROR')
							let body = msg
							let options = new ToastOptions('error')
							this.toastService.show(header, body, false, options, 'center')
						}

						reject(false)
					})
			}
		})

		return promise
	}

	//only when both promise are ended this promise goes on then
	public buildGraderList(): Promise<any> {
		return Promise.all([this.getRemoteStatistics(), this.loadRemotes(), this.getPerformance()])
			.then((res) => {
				this.distribListDeleted = this.distribList.filter((el) => el.is_deleted == 'Y')
				// console.log(this.distribListDeleted)

				this.distribList = this.distribList.filter((el) => el.subscriptionTime != null && el.is_deleted == 'N') // rimuovo i deleted e i non attivi

				// ordino per chi fa piú report
				this.distribList.sort((a, b) => b.tot_reports_done - a.tot_reports_done)

				let array = this.distribList

				if (this.showdeleted) {
					array = array.concat(this.distribListDeleted)
				}

				this.initVar()

				for (let i = 0; i < array.length; i++) {
					const distrib = array[i]

					let spec = this.examCount.filter((el) => el.username == distrib.username)

					let performances = this.performance.filter((el) => el.username == distrib.username)

					if (performances.length > 0) {
						let grader = new graders(performances)

						grader.isEnabled = distrib.isEnabled

						if (distrib.is_deleted == 'Y') {
							grader.username = distrib.code
							grader.isDeleted = true
						} else {
							grader.username = distrib.username
							grader.isDeleted = false
						}
						// report type
						if (spec.length > 0) {
							for (let n = 0; n < spec.length; n++) {
								const ref = spec[n]

								grader.tot_reports = grader.tot_reports + ref.tot
								grader.full_reports = grader.full_reports + ref.tot_full
								grader.fundus_reports = grader.fundus_reports + ref.tot_fundus
							}
						}
						//report performance
						if (performances.length > 0) {
							let grading = 0

							for (let x = 0; x < performances.length; x++) {
								const performance = performances[x]

								if (performance.grading_time && performance.grading_time != '') {
									grader.av_grading_time += parseFloat(performance.grading_time)
									grading++
								}
							}

							if (grader.av_grading_time > 0) {
								grader.av_grading_time = grader.av_grading_time / grading //media
								grader.av_grading_time = grader.av_grading_time / 60 //minuti
								grader.av_grading_time = grader.av_grading_time / 60 //ore
								grader.av_grading_time = parseFloat(grader.av_grading_time.toFixed(0))
							}

							for (let x = 0; x < grader.reports.length; x++) {
								const report = grader.reports[x]

								if (report.grading_time && report.grading_time != '') {
									let gr_time = parseFloat(report.grading_time)
									let gr_time_h = gr_time / 3600
									if (gr_time_h < 24 && gr_time_h > 0) {
										grader.within_24h++
									} else if (gr_time_h > 48) {
										grader.over_48h++
									}
								}
							}
						}

						this.totReports = this.totReports + grader.tot_reports
						this.totFull = this.totFull + grader.full_reports
						this.totFundus = this.totFundus + grader.fundus_reports

						this.avGradingTime += grader.av_grading_time

						this.graders.push(grader)
					}
				}

				let gradingDivider = this.graders.filter((el) => el.av_grading_time > 0)

				if (this.avGradingTime != 0) {
					this.avGradingTime = parseFloat((this.avGradingTime / gradingDivider.length).toFixed(0)) //media
				}

				if (this.avGradingTime > 48) {
					this.avGradingTime = parseInt((this.avGradingTime / 24).toFixed(0))
					this.avGradingTimeUnit = this.translator.instant('DASHBOARD.DAYS')
					this.avGradingTimeExceed = true
				}

				// console.log(this.graders)

				this.gradersCopy = this.graders.slice()

				this.graderList = new MatTableDataSource<graders>(this.graders)

				this.isLoading = false

				this.buildCharts()
			})
			.catch((err) => {
				console.log(err)
				alert(err)
			})
	}

	private initVar() {
		this.graders = []
		this.totReports = 0
		this.totFull = 0
		this.totFundus = 0
		this.avGradingTime = 0
	}

	public buildCharts(grader?: graders) {
		Util.debug('(Dashboard) - buildCharts ')
		const fromDate = this.fromDate.format('YYYY-MM-DD')
		const toDate = this.toDate.format('YYYY-MM-DD')
		this.performancePieCharts = []
		// console.log(grader)

		if (!this.dashboardTime.get('cadence').pristine) {
			this.buildCadenceChart()
		}

		//funzione chiamata dal template quando rimuovo la cadenza, ma se c'é un grader selezionato deve rimanere selezionato
		let gr = this.gradersCopy.filter((el) => el.isSelected == true)
		if (gr.length > 0) {
			this.onClickGraderChart(gr[0])

			return
		}

		// COLUMN CHART
		//se grader presente funzione chiamata dal template per aggiungere o rimuovere grader dal grafico
		if (grader) {
			this.chartReady = false
			if (grader.isOnChart) {
				let index = this.gradersCopy.indexOf(grader)

				this.gradersCopy.splice(index, 1)
				// console.log(this.gradersCopy)
				grader.isOnChart = false
			} else {
				this.gradersCopy.push(grader)
				grader.isOnChart = true

				this.gradersCopy.sort((a, b) => b.tot_reports - a.tot_reports)
			}
		}

		this.gradersName = [...new Set(this.gradersCopy.map((item) => item.username))]

		// console.log(this.gradersName)

		let ColumnData: ColumnDatas = new ColumnDatas()

		ColumnData.title = this.translator.instant('DASHBOARD.CHART1TITLE', { fromDate, toDate })
		ColumnData.stacked = false
		ColumnData.data = this.series
		ColumnData.xaxis = this.gradersName
		ColumnData.xaxisLength = this.gradersName.length
		ColumnData.colors = ['#81b9f2', '#49ae04', '#2f603d']
		if (this.gradersName.length > 8) {
			ColumnData.rotate = true
		}

		for (let i = 0; i < this.gradersCopy.length; i++) {
			const grader = this.gradersCopy[i]

			if (i == 0) {
				//fix per quando viene richiamata la funzione per aggiungere o rimuovere grader dal grafico
				this.series[0].data = []
				this.series[1].data = []
				this.series[2].data = []
			}

			this.series[0].data.push(grader.tot_reports)
			this.series[1].data.push(grader.full_reports)
			this.series[2].data.push(grader.fundus_reports)

			this.buildPerformancePieChart(grader)
		}

		this.columnChart = new ColumnGraph(ColumnData)
		// console.log(this.columnChart)

		this.chartReady = true
	}

	public buildPerformancePieChart(grader: graders) {
		Util.debug('(Dashboard) - buildPerformancePieChart ')

		let list: graders[] = []

		let pieChart: pieChartDatas = new pieChartDatas()

		pieChart.labels = ['In time', 'Out of time']
		pieChart.colors = ['#28b32e', '#ff0000']
		pieChart.data = []

		list.push(grader)
		pieChart.title = grader.username + ' - ' + 'performance'

		const limitH = 172800 // 48h
		let inTime = 0
		let outTime = 0
		let array = []

		for (let i = 0; i < list.length; i++) {
			const grader = list[i]

			for (let x = 0; x < grader.reports.length; x++) {
				const report = grader.reports[x]

				if (report.grading_time && report.grading_time != '') {
					let grTime = parseFloat(report.grading_time)

					if (grTime < limitH && grTime > 0) {
						inTime++
					} else {
						outTime++
					}
				}
			}
		}
		array.push(inTime, outTime)
		pieChart.data = array

		if (inTime > 0 && outTime > 0) this.performancePieCharts.push(new basicPieGraph(pieChart))
	}

	public buildCadenceChart(grader?: graders) {
		Util.debug('(Dashboard) - buildCadenceChart ')

		let graderList: graders[] = []

		if (grader) {
			graderList.push(grader)
		} else {
			// se io seleziono la cadenza dopo aver selezionato un grader nella lista, la cadenza era del totale
			// qui quindi controllo se ci solo selezionati, in caso si prendo solo quello
			let fil = this.gradersCopy.filter((el) => el.isSelected == true)

			if (fil.length > 0) {
				graderList.push(fil[0])
			} else {
				graderList = this.gradersCopy
			}
		}

		let reportArray = []

		for (let i = 0; i < graderList.length; i++) {
			const reports = graderList[i].reports

			if (reports.length > 0) {
				for (let n = 0; n < reports.length; n++) {
					const report = reports[n]
					reportArray.push({ ...report, username: graderList[i].username })
				}
			}
		}

		let fromDate = this.dashboardTime.get('fromDate').value
		let toDate = this.dashboardTime.get('toDate').value

		let fromDt = fromDate.format('YYYY-MM-DD')
		let toDt = toDate.format('YYYY-MM-DD')
		let cadence = this.dashboardTime.get('cadence').value

		const possibleEntryTypes = [...new Set(reportArray.map((item) => item.username))]

		let seriesTot: ColumnSeries[] = createArrays.createSeries(possibleEntryTypes, true)

		let columnData: ColumnDatas = new ColumnDatas()
		columnData.title = this.translator.instant('DASHBOARD.CHART3TITLE', { fromDt, toDt, cadence })

		columnData.data = seriesTot
		columnData.stacked = true
		let values: serieValues = new serieValues('creation_date', 'dt_week', 'dt_month')
		let chart = this.buildSeriesColumnChart(reportArray, fromDate, toDate, seriesTot, values, 'username')

		columnData.rotate = true
		columnData.data = chart.series
		columnData.xaxis = chart.xaxis
		columnData.xaxisLength = chart.xaxis.length

		this.columnChartCadence = new ColumnGraph(columnData)
		this.columnChartCadence.stroke.width = Array(seriesTot.length).fill(0)
		this.columnChartCadence.stroke.width[seriesTot.length - 1] = 4
		this.columnChartCadence.dataLabels.enabledOnSeries = [seriesTot.length - 1]
		this.chartCadenceReady = true
	}

	private buildSeriesColumnChart(
		array,
		fromDate: moment.Moment,
		toDate: moment.Moment,
		seriesTot: ColumnSeries[],
		values: serieValues,
		serieValue?: string
	): { series: ColumnSeries[]; xaxis: string[] } {
		const filter = this.dashboardTime.get('cadence').value
		let xaxis: string[]

		switch (filter) {
			// DAY CADENCE
			case Statistics.day:
				xaxis = createArrays.createDaysArray(fromDate, this.diffDays)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						// console.log(val)
						let record = []

						if (serieValue) {
							record = array.filter((el) => moment.utc(new Date(el[values.day])).format('DD-MM-YYYY') == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => moment.utc(new Date(el[values.day])).format('DD-MM-YYYY') == val)
						}
						if (serie.name === 'Total') {
							record = array.filter((el) => moment.utc(new Date(el[values.day])).format('DD-MM-YYYY') == val)
						}
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break
			// WEEKLY CADENCE
			case Statistics.weekly:
				xaxis = createArrays.createWeekArray(fromDate, toDate) //array di tutte le settimane comprese nell'intervallo, che sará l'asse delle x

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						let record = []

						if (serieValue) {
							record = array.filter((el) => el[values.week] == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => el[values.week] == val)
						}
						if (serie.name === 'Total') {
							record = array.filter((el) => el[values.week] == val)
						}
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break

			// MONTHLY CADENCE
			case Statistics.monthly:
				xaxis = createArrays.createMonthlyArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						let record = []
						if (serieValue) {
							record = array.filter((el) => el[values.month] == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => el[values.month] == val)
						}

						if (serie.name === 'Total') {
							record = array.filter((el) => el[values.month] == val)
						}
						// console.log(record.length)
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break

			// YEARLY CADENCE
			case Statistics.yearly:
				xaxis = createArrays.createYearArray(fromDate, toDate)

				for (let x = 0; x < seriesTot.length; x++) {
					const serie = seriesTot[x]

					for (let i = 0; i < xaxis.length; i++) {
						const val = xaxis[i]

						let record = []

						if (serieValue) {
							record = array.filter((el) => moment.utc(new Date(el[values.year])).year() + '' == val && el[serieValue] == serie.name)
						} else {
							record = array.filter((el) => moment.utc(new Date(el[values.year])).year() + '' == val)
						}
						if (serie.name === 'Total') {
							record = array.filter((el) => moment.utc(new Date(el[values.year])).year() + '' == val)
						}
						// console.log(record.length)
						serie.data[i] = record.length // il record sará il numero
					}
				}

				break
		}

		return { series: seriesTot, xaxis: xaxis }
	}

	onClickGraderChart(grader: graders) {
		Util.debug('(Dashboard) - onClickGraderChart ')

		this.chartReady = false
		this.oneSelected = true

		if (!this.dashboardTime.get('cadence').pristine) {
			this.buildCadenceChart(grader)
		}

		// deseleziono gli altri se ce ne erano selezionati
		for (let i = 0; i < this.graders.length; i++) {
			const grad = this.graders[i]

			if (grad.username != grader.username) {
				grad.isSelected = false
			}
		}

		this.columnChart = new ColumnGraph()

		let statCopy = this.examCount.slice()

		let statCopyFiltered = statCopy.filter((el) => el.username == grader.username)
		let operator: any[] = [...new Set(statCopyFiltered.map((item) => item.opt_username))]

		// console.log(statCopyFiltered)
		// console.log(operator)

		let ColumnData: ColumnDatas = new ColumnDatas()

		ColumnData.title = grader.username + ' reports from: ' + this.fromDate.format('YYYY-MM-DD') + ' to: ' + this.toDate.format('YYYY-MM-DD')
		ColumnData.stacked = false
		ColumnData.data = this.series
		ColumnData.xaxis = operator
		ColumnData.xaxisLength = operator.length
		ColumnData.colors = ['#81b9f2', '#49ae04', '#2f603d']

		if (this.gradersName.length > 5) {
			ColumnData.rotate = true
		}

		if (statCopyFiltered.length > 0) {
			for (let i = 0; i < statCopyFiltered.length; i++) {
				const stat = statCopyFiltered[i]

				if (i == 0) {
					//fix per quando viene richiamata la funzione per aggiungere o rimuovere grader dal grafico
					this.series[0].data = []
					this.series[1].data = []
					this.series[2].data = []
				}

				this.series[0].data.push(stat.tot)
				this.series[1].data.push(stat.tot_full)
				this.series[2].data.push(stat.tot_fundus)
			}
		} else {
			this.series[0].data = []
			this.series[1].data = []
			this.series[2].data = []
		}

		this.columnChart = new ColumnGraph(ColumnData)

		this.chartReady = true
	}
}

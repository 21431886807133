import { Component, OnInit, Input, ViewChild, AfterViewInit, ElementRef, Output } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { NgForm } from '@angular/forms'
// MAT TABLE IMPORT
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'

import { Util } from '../../models/util.model'
import { SessionService } from '../../service/session.service'
import { BalanceRec, SalePlan } from '../../models/salePlan.model'
import { User } from '../../models/user.model'
import { Doctor } from 'src/app/models/doctor.model'
// localstorage
import { TablePrefs } from '../../models/settings.model'

interface Excl {
	value: number
	viewValue: string
}

@Component({
	selector: 'balance',
	templateUrl: './balance.component.html',
	styleUrls: ['./balance.component.scss'],
})
export class BalanceComponent implements OnInit, AfterViewInit {
	balanceListTable: MatTableDataSource<BalanceRec>
	@Input() balanceList: BalanceRec[]
	@Input() currUser: User
	@Input() currDoc: Doctor
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('mat-table') dataTable: MatTable<BalanceRec>
	displayedColumns: string[]
	@ViewChild('filter') input: ElementRef
	sortStatus: Sort
	pageStatus: PageEvent

	@ViewChild('filters') myFilter: NgForm

	@Output() balancePref: TablePrefs
	localStorageName: string

	currentProfile: string

	movements: Excl[]
	types: Excl[]
	grading: Excl[]

	user: any

	mainList

	constructor(public session: SessionService, public translator: TranslateService, public myMatPagIntl: MatPaginatorIntl) {
		Util.debug('(balance) - constructor')
		this.balanceList = []
		this.mainList = []

		this.currentProfile = ''

		this.movements = [
			{ value: 0, viewValue: this.translator.instant('CREDITS.REMOVE_NEGATIVE_BASIC') },
			{ value: 1, viewValue: this.translator.instant('CREDITS.REMOVE_NEGATIVE_NEGATIVE') },
			{ value: 2, viewValue: this.translator.instant('CREDITS.REMOVE_NEGATIVE_POSITIVE') },
		]

		this.types = [
			{ value: 0, viewValue: this.translator.instant('CREDITS.REMOVE_NEGATIVE_BASIC') },
			{ value: 1, viewValue: this.translator.instant('CREDITS.FILTER_TYPES_1') },
			{ value: 2, viewValue: this.translator.instant('CREDITS.FILTER_TYPES_2') },
		]

		this.grading = [
			{ value: 0, viewValue: this.translator.instant('CREDITS.REMOVE_NEGATIVE_BASIC') },
			{ value: 1, viewValue: this.translator.instant('CREDITS.FILTER_GRADING_1') },
			{ value: 2, viewValue: this.translator.instant('CREDITS.FILTER_GRADING_2') },
		]
	}

	ngOnInit(): void {
		Util.debug('(balance) - ngOnInit')
		this.initColumns()

		// translation for the mat-paginator
		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')
	}

	ngAfterViewInit() {
		Util.debug('(balance) - ngAfterViewInit')

		if (this.currUser != null) {
			// setto il current profile in base se sto accedendo dalla lista doc o dalla profile
			this.currentProfile = 'is_user'
		} else if (this.currDoc != null) {
			this.currentProfile = 'is_doc'
		}

		this.initUser()

		this.sessionPreferences()

		this.initTable()
	}

	initUser() {
		if (this.currentProfile === 'is_user') {
			this.user = this.currUser
			// console.log(this.user.user_type);
		} else if (this.currentProfile === 'is_doc') {
			this.user = this.currDoc
			// console.log(this.user)
		}
	}

	decodeBalanceType(myKey: string) {
		let descr = ''
		if (myKey == SalePlan.ITEM_CREDITS || myKey == SalePlan.ITEM_SPACEHG || myKey == SalePlan.ITEM_AI) {
			let myTag = 'CREDITS.ITEM_' + myKey
			descr = this.translator.instant(myTag)
		} else {
			descr = myKey
		}
		return descr
	}

	sessionPreferences() {
		// saving preferences
		this.localStorageName = this.session.getUserId() + ' - balancePref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)

		if (saveLocal) {
			this.balancePref = JSON.parse(saveLocal)
			// set page to 0 because we don't want to change pages between admins
			this.balancePref.currentPage = 0
		} else {
			// first time

			this.balancePref = new TablePrefs()

			this.balancePref.empty = false

			this.balancePref.sort = 'buy_date'
			this.balancePref.itemsPerPage = 10
			// if (this.currentProfile === 'is_user') {
			// 	this.balancePref.itemsPerPage = 10
			// } else if (this.currentProfile === 'is_doc') {
			// 	this.balancePref.itemsPerPage = 3
			// }

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.balancePref))
		}
	}

	private ApplySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		this.input.nativeElement.value = pref.filter

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}

	// table material
	initColumns() {
		this.displayedColumns = ['buy_date', 'sku', 'description', 'i_type', 'amount', 'ref_id']
		// options
		if (!this.session.isLevel1()) {
			this.displayedColumns.push('channel')
			this.displayedColumns.push('kind') // 06.05.2024 #22000
			this.displayedColumns.push('notes')
			//this.displayedColumns.push('ref_id')  // 26.01.2023 abilitato anche al liv 1
		}
		this.displayedColumns.push('filter')

		// set mainList for filterNegatives function
		this.mainList = this.balanceList
	}
	private initTable() {
		//console.log(this.balanceList)
		if (this.balanceList != null) {
			if (this.session.isOptician()) {
				this.balanceList = this.balanceList.filter((x) => x.amount != 0)
			}

			this.balanceListTable = new MatTableDataSource<BalanceRec>(this.balanceList)
			this.balanceListTable.paginator = this.paginator
			this.balanceListTable.sort = this.sort

			// automatic sort
			if (this.balanceList.length != 0) {
				// console.log('this.balanceList.length != 0')

				this.ApplySettings(this.balancePref, this.balanceListTable)
			}
		} else {
			Util.debug('(Balance) null rep list!')
		}
	}

	// filterNegatives(val) {
	// 	console.log(val)
	// 	// remove all negatives in the balance list
	// 	Util.debug('filterNegatives() balance list start with val = ' + val)
	// 	switch (val) {
	// 		case 1:
	// 			// this.balanceList = this.mainList
	// 			this.balanceList = this.balanceList.filter((d) => d.amount < 0)
	// 			break
	// 		case 2:
	// 			// this.balanceList = this.mainList
	// 			this.balanceList = this.balanceList.filter((d) => d.amount > 0)
	// 			break
	// 		default:
	// 			this.balanceList = this.mainList
	// 	}

	// 	this.initTable()
	// }

	filterValue(filters: NgForm) {
		let movements = filters.controls.movements.value
		let types = filters.controls.types.value
		let grading = filters.controls.grading.value

		var array = [movements, types, grading]

		this.balanceList = this.mainList

		for (let i = 0; i < array.length; i++) {
			const element = array[i]

			if (element != 0) {
				if (i == 0 && element != 0) {
					// console.log('movements')

					switch (element) {
						case 1:
							// this.balanceList = this.mainList
							this.balanceList = this.balanceList.filter((d) => d.amount < 0)
							break
						case 2:
							// this.balanceList = this.mainList
							this.balanceList = this.balanceList.filter((d) => d.amount > 0)
							break
						default:
					}
				}
				if (i == 1 && element != 0) {
					// console.log('types')

					switch (element) {
						case 1:
							// this.balanceList = this.mainList
							this.balanceList = this.balanceList.filter((d) => d.i_type == SalePlan.ITEM_CREDITS)
							break
						case 2:
							// this.balanceList = this.mainList
							this.balanceList = this.balanceList.filter((d) => d.i_type == SalePlan.ITEM_SPACEHG)
							break
						default:
					}
				}

				if (i == 2 && element != 0) {
					// console.log('grading')

					switch (element) {
						case 1:
							// this.balanceList = this.mainList
							this.balanceList = this.balanceList.filter((d) => d.ref_id == 'reserved')
							break
						case 2:
							// this.balanceList = this.mainList
							this.balanceList = this.balanceList.filter((d) => d.ref_id != null && d.ref_id.includes('id'))
							break
						default:
					}
				}
			}
		}
		this.initTable()
	}

	filterText() {
		let filterWord = this.input.nativeElement.value

		filterWord = filterWord.trim().toLocaleLowerCase()
		this.balanceListTable.filter = filterWord
	}
	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.balancePref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.balancePref.itemsPerPage)) // credo vada levato, non viene mai usato e non ha senso
	}
}

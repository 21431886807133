//import { Config } from "src/config";
import { DateParser } from './dateParser.model'
import { Util } from './util.model'
import { CsvLine } from './csvLine.model'
import { CryptoUtilsService } from '../service/crypto-utils.service'

//import *  as forge from 'node-forge';

// 15.07.2022 models for the AI grading request and result

export class AiReport {
	static DSS = 'dss'
	static MCS = 'mcs'

	static STATUS_QA = 0 // not used
	static STATUS_KEY = 1
	static STATUS_REQ = 2
	static STATUS_DWN = 3
	static STATUS_CLI = 4

	static TL_RED = 'red'
	static TL_GREEN = 'green'
	static TL_YELLOW = 'yellow'
	static TL_EMPTY = 'gray' // 31.08.2022

	// TODO spostare su json
	static descrStatus = [
		'ko receive', // 0   // 27.10.2022  ERROR from vistel rx
		'rcv key', // 1
		'req. grading', // 2
		'received pdf', // 3
		'downloaded', // 4
	]

	id: number
	patient_id: number
	batch_id: string
	traffic_light_left: string
	traffic_light_right: string
	channel: string

	lang: string
	device: string

	keybox: string // in base64
	pdf_encr: string
	pdf_clear: any // ArrayBuffer; // ByteStringBuffer;

	grading: JSON // Json obj
	// solo uno dei due, nested json
	gradingDSS: AiDSS // DR
	gradingMCS: AiMCS // multiple

	creation_date: Date
	last_update: Date
	received: Date // 23.08.2022
	created_by: number
	status: number

	// solo per i campi in chiaro
	constructor(myObj?) {
		this.id = 0
		this.patient_id = 0
		this.batch_id = ''
		this.status = 0

		this.lang = ''
		this.device = ''

		this.grading = null
		this.gradingDSS = null
		this.gradingMCS = null

		this.keybox = ''
		this.pdf_encr = null
		this.pdf_clear = null

		this.creation_date = null
		this.last_update = null
		this.received = null
		this.created_by = 0

		if (myObj != null) {
			var myJsonObj = { ...myObj } // copia tutti quelli con ugual nome
			if (myJsonObj != null) {
				Object.assign(this, myJsonObj)
			}

			if (myObj.creation_date != null) {
				this.creation_date = DateParser.parseDate(myObj.creation_date)
			}

			if (myObj.last_update != null) {
				this.last_update = DateParser.parseDate(myObj.last_update)
			}

			// 23.08.2022
			if (myObj.received != null) {
				this.received = DateParser.parseDate(myObj.received)
			}

			// 27.10.2022 tolta patch [ls]
			//else if (this.last_update) { // patch per pregresso
			//this.received = this.last_update
			//}

			// 12.08.2022
			if (myObj.grading) {
				//console.log(myObj.grading);
				//let szVal = JSON.stringify(myObj.grading);
				//Util.debug("[AIRep] id: "+this.id+" gr:"+szVal);

				if (this.channel == AiReport.DSS) {
					//Object.assign(this.gradingDSS, myObj.grading);  // ko
					this.gradingDSS = <AiDSS>myObj.grading // ok
					//Util.debug("[AIRep] id: "+this.id+" DSS gr:"+this.gradingDSS.toString());  // ko
					//Util.debug("[AIRep] id: "+this.id+" DSS gr:"+JSON.stringify(this.gradingDSS)); // ok
				} else if (this.channel == AiReport.MCS) {
					this.gradingMCS = <AiMCS>myObj.grading
				}
			} else {
				Util.debug('[AIRep] id: ' + this.id + ' null grading.')
			}
		}
	}

	// 26.07.2022
	static createAiReport(myObj: any, cryptoUtils: CryptoUtilsService, redKey: any): Promise<AiReport> {
		//console.log("(createPatient) inizio "+rawPatient.code);

		let result = new AiReport(myObj) // salva i dati in chiaro

		if (!myObj.pdf_base64) {
			// the pdf stream to decrypt
			return Promise.reject(result)
		}

		Util.debug('[AIRep] going to decrypt the pdf stream...')

		//result.pdf_encr = cryptoUtils.base64ToBytes(myObj.pdf_base64);

		// decrypt with redKey and iv
		//let aiKeybox = myObj.keybox;  // da aprire, contiene la redKey
		let ivB = myObj.iv // in base64

		return cryptoUtils
			.decryptAiReport(redKey, myObj.pdf_base64, ivB)
			.then((pdf: ArrayBuffer) => {
				result.pdf_clear = pdf

				let testArray = pdf

				let beginTag = '%PDF-'
				let iOff = 0

				if (testArray) {
					if (testArray[iOff] == '%' && testArray[iOff + 1] == 'P' && testArray[iOff + 2] == 'D' && testArray[iOff + 3] == 'F' && testArray[iOff + 4] == '-') {
						Util.debug('[AiRep] - pdf code is valid, it starts with ' + beginTag)
						//result.pdf_clear = pdf;
					} else {
						Util.debug('[AiRep] - pdf code not valid, does not start with ' + beginTag)
						//alert("invalid pdf code !");
						//return Promise.reject("invalid pdf !");
						//result.pdf_clear = "";
					}
				}

				return result
			})
			.catch((ex) => {
				return Promise.reject(ex)
			})
	}

	basicInfo() {
		let ret = ''

		if (this.id > 0) {
			ret =
				'id: ' +
				this.id +
				CsvLine.SEP +
				'channel: ' +
				this.channel +
				CsvLine.SEP +
				'TL R: ' +
				this.traffic_light_right +
				CsvLine.SEP +
				'TL L: ' +
				this.traffic_light_left +
				CsvLine.SEP +
				'created by: ' +
				this.created_by +
				CsvLine.SEP +
				'creation date: ' +
				this.creation_date.toISOString() +
				CsvLine.SEP +
				'updated: ' +
				this.last_update.toISOString()
		}
		return ret
	}

	// 27.07.2022 ritorna decodifica descrittiva
	getStatus() {
		let ret = '' + this.status
		if (this.status >= 0 && this.status < AiReport.descrStatus.length) {
			ret = AiReport.descrStatus[this.status]
		}
		return ret
	}

	// 12.08.2022 merge dei due occhi
	getTrafficLight() {
		// 01.09.2022 FIX, se non c'e' ancora, risultava verde, dovrebbe essere vuoto
		//let ret = "";
		let ret = AiReport.TL_EMPTY

		if (this.status >= AiReport.STATUS_DWN) {
			// test in cascata cosi' vince la gravita' [ls]
			if (this.traffic_light_left == AiReport.TL_RED || this.traffic_light_right == AiReport.TL_RED) {
				ret = AiReport.TL_RED
			} else if (this.traffic_light_left == AiReport.TL_YELLOW || this.traffic_light_right == AiReport.TL_YELLOW) {
				ret = AiReport.TL_YELLOW
			} else {
				ret = AiReport.TL_GREEN
			}
		}

		return ret
	}
}

export class AiDSS {
	// Diabetic Report, basic
	od: AiGrad
	os: AiGrad
}

export class AiMCS {
	// Multiple Pathologies, 13
	od: AiDiagn
	os: AiDiagn
}

export class AiGrad {
	// for dss only
	grading: AiG
}

export class AiDiagn {
	//  for mcs only
	diagnosis: AiD[]
}

export class AiG {
	// for dss only
	gradingLevel: number
	hasOtherDisorder: boolean
}

export class AiD {
	// for mcs only
	key: string
}

// **********************************************************

// for grading request
export interface AiPatient {
	name: string
	gender: string
	birthday: string // YYYYMMDD
	isDiabetes: boolean
	isHypertension: boolean
	isHighMyopia: boolean
	laserPhotocoagulation: LaserInfo
	medicalHistory: string
}

export interface LaserInfo {
	exist: boolean
	data: string[]
}

// **********************************************************

// for grading request
export interface AiImage {
	name: string
	base64: string
	extraData: AiExtraInfo

	//constructor(myObj?) {}
}

export interface AiExtraInfo {
	imageFixation: string
	laterality: string

	//constructor(myObj?) {}
}

// ***************************************

export interface aiBatchId {
	batch: string
}

import { Pipe, PipeTransform } from '@angular/core'

//import { SessionService } from '../service/session.service';
import { UserType, SessionStatus } from '../models/user.model'
import { Section } from '../models/navbar.model'

@Pipe({
	name: 'authenticationLevel',
})
export class AuthenticationLevelPipe implements PipeTransform {
	/*
  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
  }

  // filter
  //authenticationLevelFilter() {
    authenticationLevel() {
*/
	transform(input: Section[], status: SessionStatus, type: UserType, subProfile: UserType): Section[] {
		var output: Section[]
		output = []

		//angular.forEach(input, (item) => {
		//input.forEach((item, index) => {
		for (let item of input) {
			if (item.visibleBy.indexOf(UserType.NONE) != -1) {
				output.push(item)
			} else if (status == SessionStatus.LOGGED) {
				//angular.forEach(item.visibleBy, (innerItem) => {
				for (let innerItem of item.visibleBy) {
					//console.log("(authenticationLevel) item:"+innerItem+ " type: "+type+" subP: "+subProfile);
					if (innerItem == type) {
						// cfr con il campo type dell'utente loggato, vd navbar.html
						output.push(item)
					} else if (type == UserType.OPERATOR && innerItem == subProfile) {
						// 09.02.2021
						output.push(item)
					}
				}
			}
		}
		return output
	}
}

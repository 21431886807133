import { DataStatus } from '../service/data-model.service'

// 16.04.2020
export class Category {
	position: number
	name: string
	status: DataStatus
	expected: number // numero di esami che dovra' avere, per gestire fase di loading
	examList: any[] // sono Exam "estesi"

	// 23.06.2020 per display e print [ls]
	hasMiddleInfo: boolean
	hasImagesR: boolean
	hasImagesL: boolean

	hasDataR: boolean // 11.10.2021 esami su occhio singolo
	hasDataL: boolean

	hasReportDone: boolean // 07.07.2020 per il report

	constructor(name?) {
		this.position = 0
		this.name = ''
		this.status = DataStatus.NOT_LOADED
		this.examList = []
		this.expected = -1 // valutare cosa e' meglio

		this.hasMiddleInfo = false
		this.hasImagesL = false
		this.hasImagesR = false

		this.hasReportDone = false

		if (name != null) {
			// 30.06.2020 aggiunto param nel costruttore
			this.name = name
		}
	}

	public getStatus() {
		return this.status
	}
}

<div class="panel panel-modal panel-default ng-cloak position-relative">
	<loader [activateSpinner]="isLoading"></loader>

	<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
		<div [hidden]="false" class="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div> -->

	<div class="recoverpuk_panel" [ngClass]="isLoading ? '' : 'show' ">
		<!-- modal-panel  -->
		<div class="panel-heading text-center" [hidden]="success">
			<label class="panel-title">{{"RECOVER_PUK.PAGE_TITLE" | translate}}</label>
		</div>
		<!-- <div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
	</div> -->
		<div class="panel-body">
			<form name="recoverForm" [formGroup]="recoverPukForm" [hidden]="success">
				<div class="container-fluid fields-container">
					<div class="row">
						<div class="form-group col-12">
							<label for="username">{{"LOGIN.USERNAME" | translate}} *</label>
							<input formControlName="username" class="form-control normalcase" name="username" id="user_name" placeholder="{{'LOGIN.USERNAME_HINT' | translate}}" />
							<!-- <div class="error-wrapper" [hidden]="!(usrname.touched && usrname.invalid)">
							<p class="error-message ng-hide">{{'MESSAGE.REQUIRED' | translate}}</p>
						</div> -->
						</div>
					</div>

					<div class="row" *ngIf="currentAction == 'recoverPwd' ">
						<div class="form-group col-12">
							<label for="email">{{"DOCTOR.EMAIL" | translate}} *</label>
							<input formControlName="email" class="form-control normalcase" name="email" id="email" placeholder="{{'DOCTOR.MODAL_EMAIL_HINT' | translate}}" />
							<!-- <div class="error-wrapper" [hidden]="!(usremail.touched && usremail.invalid && !fail)">
							<p class="error-message ng-hide">{{'MESSAGE.REQUIRED' | translate}}</p>
						</div> -->
						</div>
					</div>

					<div class="row">&nbsp;</div>

					<!-- step iniziale, aggiunta info  20.01.2021  -->
					<div *ngIf="currentAction == 'recoverPwd' ">
						<p>{{'RECOVER_PUK.STEP1_MSG_LINE1' | translate}}</p>

						<p>{{'RECOVER_PUK.STEP1_MSG_LINE2' | translate}}</p>
					</div>

					<!-- step 2, dopo aver ricevuto il link -->
					<div *ngIf="currentAction == 'recoverFromPuk' ">
						<div class="row">
							<div class="form-group col-12">
								<label for="puk">{{"RECOVER_PUK.PUK" | translate}} *</label>
								<input type="text" class="form-control normalcase" formControlName="puk" name="puk" id="puk" required autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
								<!-- <div class="error-wrapper" [hidden]="!(myPuk.touched && myPuk.invalid)">
									<p class="error-message ng-hide" [hidden]="!myPuk.invalid">{{'MESSAGE.REQUIRED' | translate}}</p>
								</div> -->
							</div>

							<div class="form-group col-12">
								{{'RECOVER_PUK.DESCRIPTION' | translate}}
								<br />
								{{'RECOVER_PUK.DESCRIPTION_LINE2' | translate}}
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 position-relative newpwd-field">
								<label for="newpwd">{{"RECOVER_PUK.NEWPWD" | translate}} *</label>
								<input type="text" class="form-control normalcase" formControlName="newpwd" name="newpwd" id="newpwd" (ngModelChange)="onChangeNewPwd($event)" required autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
								<fa-icon [ngClass]="recoverPukForm.get('newpwd').valid ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
								<!-- <p class="error-message mt-2" [hidden]="validPwd">{{ 'PROFILE.INVALID_PASSWORD' | translate }}</p> -->
								<p class="error-msg" *ngIf="recoverPukForm.get('newpwd').invalid">
									<span [ngClass]="recoverPukForm.get('newpwd').errors['Invalid blank space'] ? 'show' : '' ">{{ 'FIRST_LOGIN.VALIDATOR_BLANK_CHAR' | translate }}</span>
								</p>
								<!-- ng-change="validatePwd(newpwd)" validatePwd -->
								<!--
							<div class="error-wrapper" [hidden]="(!newPwd.touched || !newPwd.invalid)">
								<p class="error-message ng-hide">{{'MESSAGE.REQUIRED' | translate}}</p>
							</div>-->
								<!-- <div class="error-wrapper" [hidden]="!newPwd.invalid && validPwd">
									<p class="error-message ng-hide">{{"PROFILE.INVALID_PASSWORD" | translate}}</p>
								</div> -->
							</div>
						</div>

						<div class="col-12 form-group">
							<p class="fw-bold">{{ 'FIRST_LOGIN.VALIDATOR_TITLE' | translate }}</p>
							<div class="mandatory-points">
								<div class="position-relative" [ngClass]="charNum ?  'valid': 'invalid' ">
									<p><fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon> <fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_CHAR' | translate }}</span></p>
								</div>
								<div class="position-relative" [ngClass]="uppercase ?  'valid': 'invalid' ">
									<p><fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon> <fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_UPP' | translate }}</span></p>
								</div>
								<div class="position-relative" [ngClass]="specialChar ?  'valid': 'invalid' ">
									<p><fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon> <fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_SPEC' | translate }}</span></p>
								</div>
							</div>
						</div>

						<div class="row">
							<div class="form-group col-12 position-relative newpwdconfirm-field">
								<label for="newpwdconfirm">{{"RECOVER_PUK.CONFIRM_PASSWORD" | translate}} *</label>
								<input type="text" class="form-control normalcase" formControlName="newpwdconfirm" name="newpwdconfirm" id="newpwdconfirm" required autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" />
								<fa-icon [ngClass]="recoverPukForm.get('newpwdconfirm').valid ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
								<p class="error-msg">
									<span [ngClass]="recoverPukForm.get('newpwdconfirm').invalid ? 'show' : ''">{{ ('FIRST_LOGIN.PWD_ERR_NOT_MATCH' | translate )}}</span>
								</p>
								<!-- <div class="error-wrapper" [hidden]="!(confPwd.touched && confPwd.invalid)">
									<p class="error-message ng-hide" [hidden]="!confPwd.invalid">{{'MESSAGE.REQUIRED' | translate}}</p>
								</div> -->
							</div>
						</div>
					</div>

					<!-- <div class="error-wrapper test"><p class="error-message ng-hide">{{recoverErrMsg}}</p></div> -->
					<!-- <div class="confirm-wrapper test">
				<p class="confirm-message ng-hide">{{recoverErrMsg}}</p>
				</div> -->

					<div class="row mt-4">
						<div class="col-12 d-flex justify-content-evenly">
							<!-- valutare se meglio label submit o proceed -->

							<button type="submit" name="button" class="btn btn-primary" id="submitPuk" [disabled]="recoverPukForm.invalid || fail" (click)="submit()">{{'BUTTONS.PROCEED' | translate}}</button>

							<!-- <input type="submit" class="btn btn-login" id='submitPuk'
								[disabled]="btnProceedDisabled"
								(click)="submit()"
								value="{{'BUTTONS.PROCEED' | translate}}" /> -->
							<button type="clear" name="button" class="btn btn-secondary" (click)="ActiveModal.dismiss('undo');">{{'BUTTONS.CLOSE' | translate}}</button>
						</div>
					</div>

					<div class="row">&nbsp;</div>
				</div>
			</form>
			<div class="container-fluid" [hidden]="!success">
				<div class="row">
					<div class="col-12 text-center success-container">
						<h3 class="fw-bold mb-4">{{ successTitle}}</h3>
						<p [innerHTML]="successSubTitle"></p>
						<fa-icon style="color: #28b32e; font-size: 100px" [ngClass]="showIcon ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="panel panel-modal position-relative aireview-container">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()" *ngIf="!hiddenSelections"></button>
	</div>

	<div class="container-fluid">
		<div class="row">
			<div class="col-12 text-center">
				<h3 class="fw-bold">{{'AI_REVIEW.TITLE' | translate}}</h3>
				<p>{{'AI_REVIEW.SUBTITLE' | translate}}</p>

				<div class="row mb-2">
					<div class="col-6 pe-5">
						<p class="eye-title">{{'AI_REVIEW.RIGHT' | translate}}</p>
						<div class="position-relative">
							<!-- loader -->

							<loader [activateSpinner]="loading "></loader>
							<!-- 
							<div class="loader-container" [ngClass]="loading ? 'show' : '' ">
								<div [hidden]="false" class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div> -->

							<div class="row img-container d-flex justify-content-center" style="height: 50vh" [ngClass]="loading ? '' : 'show' ">
								<div class="col-3 d-flex flex-column border img-container__list p-3 h-100 rounded" *ngIf="examImagesRight.length>1" [ngClass]="hiddenSelections ? 'hidden' : '' ">
									<div *ngFor="let img of examImagesRight" class="mb-3 p-2 rounded" [ngClass]="img.selected ? 'selected' : '' ">
										<div class="position-relative">
											<img src="{{img.image}}" alt="{{img.descr}}" class="w-100 rounded" (click)="imageSelection(img)" />
											<fa-icon [icon]="faCircleCheck" style="color: #28b32e" *ngIf="img.quality > 0 && img.quality < 4"></fa-icon>
											<fa-icon [icon]="faCircleCheck" style="color: #cccc00" *ngIf="img.quality == 4"></fa-icon>
											<fa-icon [icon]="faCircleXmark" style="color: #ff3b2d" *ngIf="img.quality == 5"></fa-icon>
										</div>

										<span>{{session.formatDateTime(img.date) }}</span>
									</div>
								</div>

								<div class="col-9 h-100">
									<div class="img-container__selected">
										<img src="{{rightSelected.image}}" alt="{{rightSelected.descr}}" class="rounded" />

										<p *ngIf="rightSelected.imgId == 0">{{rightString}}</p>
										<!-- <album id="left" *ngIf="examImagesRight.length>0" [images]="examImagesRight" [isWizard]="true"> </album> -->
									</div>
								</div>
							</div>
							<div class="row d-flex justify-content-center">
								<div class="col-3" *ngIf="examImagesRight.length>1"></div>
								<div class="col-9 image-details-data" [ngClass]="rightSelected.imgId == 0 ? '' : 'show' ">
									<div class="d-flex justify-content-center text-uppercase mt-3">
										<!-- <div class="pe-3"><p class="fw-bold text-uppercase">Image detail</p></div> -->
										<div class="pe-3"><label>{{'AI_REVIEW.EXAM_DATE' | translate}}: </label><span class="ms-1">{{session.formatDateTime(rightSelected.date)}}</span></div>
										<div class="px-3"><label>{{'AI_REVIEW.ID' | translate}}: </label><span class="ms-1">{{rightSelected.examId}}</span></div>
										<div class="px-3"><label>{{'AI_REVIEW.TYPE' | translate}}: </label><span class="ms-1">{{rightSelected.descr}}</span></div>
										<div class="px-3 position-relative quality">
											<label>{{'AI_REVIEW.QUALITY' | translate}}: </label>
											<span class="ms-1" *ngIf="rightSelected.quality == 0; else qualityright"> - </span>
											<ng-template #qualityright>
												<fa-icon [icon]="rightSelected.quality > 4 ? faCircleXmark : faCircleCheck" aria-hidden="true" ngbTooltip="{{rightSelected.quality}}" [ngClass]="rightSelected.quality > 4 ? 'faCircleXmark' : 'faCircleCheck'" [ngStyle]="{'color' : rightSelected.quality == 4  ? '#e9e919' : '' }"></fa-icon>
											</ng-template>
										</div>
										<!-- <div class="px-3"><label>Quality: </label><span class="ms-1">{{leftSelected.quality}}</span></div> -->
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-6 ps-5">
						<p class="eye-title">{{'AI_REVIEW.LEFT' | translate}}</p>
						<div class="position-relative">
							<!-- loader -->

							<loader [activateSpinner]="loading "></loader>
							<!-- 
							<div class="loader-container" [ngClass]="loading ? 'show' : '' ">
								<div [hidden]="false" class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>
							</div> -->

							<div class="row img-container d-flex justify-content-center" style="height: 50vh" [ngClass]="loading ? '' : 'show' ">
								<div class="col-9 h-100">
									<div class="img-container__selected">
										<!-- <album id="right" *ngIf="examImagesLeft.length>0" [images]="examImagesLeft" [isWizard]="true"> </album> -->
										<img src="{{leftSelected.image}}" alt="{{leftSelected.descr}}" class="rounded" />

										<p *ngIf="leftSelected.imgId == 0">{{leftString}}</p>
									</div>
								</div>

								<div class="col-3 d-flex flex-column border img-container__list p-3 h-100 rounded" *ngIf="examImagesLeft.length>1" [ngClass]="hiddenSelections ? 'hidden' : '' ">
									<div *ngFor="let img of examImagesLeft" class="mb-3 p-2 rounded" [ngClass]="img.selected ? 'selected' : '' ">
										<div class="position-relative">
											<img src="{{img.image}}" alt="{{img.descr}}" class="w-100 rounded" (click)="imageSelection(img)" />
											<fa-icon [icon]="faCircleCheck" style="color: #28b32e" *ngIf="img.quality > 0 && img.quality < 4"></fa-icon>
											<fa-icon [icon]="faCircleCheck" style="color: #cccc00" *ngIf="img.quality == 4"></fa-icon>
											<fa-icon [icon]="faCircleXmark" style="color: #ff3b2d" *ngIf="img.quality == 5"></fa-icon>
										</div>

										<p>{{session.formatDateTime(img.date) }}</p>
									</div>
								</div>
							</div>
							<div class="row d-flex justify-content-center">
								<div class="col-9 image-details-data" [ngClass]="leftSelected.imgId == 0 ? '' : 'show' ">
									<div class="d-flex justify-content-center text-uppercase mt-3">
										<!-- <div class="pe-3"><p class="fw-bold text-uppercase">Image detail</p></div> -->
										<div class="pe-3"><label>{{'AI_REVIEW.EXAM_DATE' | translate}}: </label><span class="ms-1">{{session.formatDateTime(leftSelected.date)}}</span></div>
										<div class="px-3"><label>{{'AI_REVIEW.ID' | translate}}: </label><span class="ms-1">{{leftSelected.examId}}</span></div>
										<div class="px-3"><label>{{'AI_REVIEW.TYPE' | translate}}: </label><span class="ms-1">{{leftSelected.descr}}</span></div>
										<div class="px-3 position-relative quality">
											<label>{{'AI_REVIEW.QUALITY' | translate}}: </label>
											<span class="ms-1" *ngIf="leftSelected.quality == 0; else qualityleft"> - </span>
											<ng-template #qualityleft>
												<fa-icon [icon]="leftSelected.quality > 4 ? faCircleXmark : faCircleCheck" aria-hidden="true" ngbTooltip="{{leftSelected.quality}}" [ngClass]="leftSelected.quality > 4 ? 'faCircleXmark' : 'faCircleCheck'" [ngStyle]="{'color' : leftSelected.quality == 4  ? '#e9e919' : '' }"></fa-icon>
											</ng-template>
										</div>
										<!-- <div class="px-3"><label>Quality: </label><span class="ms-1">{{leftSelected.quality}}</span></div> -->
									</div>
								</div>
								<div class="col-3" *ngIf="examImagesLeft.length>1"></div>
							</div>
						</div>
					</div>
				</div>

				<div class="row d-flex justify-content-center" *ngIf="fundusExamNotCentral.length > 0">
					<div class="col-12 text-center mt-3">
						<p class="fw-bold text-uppercase" style="font-size: 0.8rem; color: #ff3b2d">{{'AI_REVIEW.WRONG_FIXATION' | translate}}</p>
					</div>
				</div>

				<div class="row mb-3 d-flex justify-content-center" *ngIf="requestReviewStart">
					<!-- <div class="col-12 text-center">
						<p class="fw-bold text-uppercase mt-2" [ngClass]="requestReviewStopped ? '' : 'pulse' ">{{statusString}}</p>
					</div> -->
					<div class="col-12 position-relative progressbar-container py-3 px-5 text-center" [ngClass]="requestReviewStart ? 'show' : '' ">
						<!-- <p class="title">Request process</p> -->
						<p class="fw-bold mt-2 text-uppercase" [ngClass]="requestReviewStopped ? '' : 'pulse' ">{{statusString}}</p>
						<p><ngb-progressbar [value]="progressbarValue" [type]="progressbarType">{{progressbarValue + '%'}}</ngb-progressbar></p>
					</div>
				</div>

				<div class="row">
					<div class="col-12 d-flex flex-column flex-column align-items-center justify-content-center">
						<button type="button" class="btn btn-primary add-button" *ngIf="!requestReviewEnd" (click)="requestReview()" [disabled]="(leftSelected.imgId + rightSelected.imgId == 0) || requestReviewStart">
							<span>{{'AI_REVIEW.BUTTON_START' | translate}}</span>
						</button>

						<p class="ending-text mt-4" *ngIf="requestReviewEnd">{{'AI_REVIEW.REFRESH_LIST_HINT' | translate}}</p>

						<!-- <button type="button" class="btn btn-default add-button" (click)="test()">
							<span>test</span>
						</button> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

type reply = {
	reply_id: number
	reply: string
	checked?: boolean
}

export interface ChangeGraderFormQuestion {
	priority: number
	question: string
	question_id: number
	replies: reply[]
}

export class supportTask {
	id: number
	country: string
	creation_date: string
	req_type: string
	status: string
	updated_by: string // chi l'ha eseguita
	user_id: number //chi ha fatto la richiesta

	constructor(rawObj) {
		Object.assign(this, rawObj)
	}
}

// TO implement in future
export enum relType {
	CHANGEGRADER = 'change_grader',
}

// TO implement in future
export enum status {
	NEW = 'new',
	COMPLETED = 'completed',
}

<div class="topview visit-list-container">
	<!-- panel-head -->

	<!-- panel-body -->
	<div class="container-full">
		<div class="row">
			<div class="col-12">
				<div class="text-center my-2">
					<h4 class="panel-title">{{ 'CREATE_USER.PAGE_TITLE' | translate }}</h4>
				</div>

				<div class="panel-body position-relative">
					<div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
						<div [hidden]="false" class="lds-ring">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
					<!-- <p [hidden]="!success">{{ 'CREATE_USER.DESCRIPTION_SUCCESS' | translate }}</p> -->

					<form [hidden]="success" name="createUserForm" #createUserForm="ngForm" id="createUserForm" #UserForm validate [ngClass]="!isLoading ? 'show' : '' ">
						<!-- ng-submit="submit(createUserForm)" -->

						<div class="row">
							<!-- USER TYPE -->
							<div class="col-8">
								<p class="uppercase fw-bold">{{ 'CREATE_USER.TYPE' | translate }} *</p>

								<!-- LIVELLO 1 -->
								<div class="row">
									<div class="col-1 d-flex align-items-center">
										<div>Lv.1 <fa-icon [icon]="faArrowRight" aria-hidden="true"></fa-icon></div>
									</div>
									<div class="col-4">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Optician' }">
											<div>
												<input type="radio" name="user_type" id="user_typeA" value="Optician" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_A_DESCR' | translate }} (type A) </label>
											</div>
										</div>
									</div>
									<div class="col-4">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Doctor' }">
											<div>
												<input type="radio" name="user_type" id="user_typeB" value="Doctor" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_B_DESCR' | translate }} (type B)</label>
											</div>
										</div>
									</div>
								</div>

								<!-- LIVELLO 2 -->
								<div class="row mt-2">
									<div class="col-1 d-flex align-items-center">
										<div>Lv.2 <fa-icon [icon]="faArrowRight" aria-hidden="true"></fa-icon></div>
									</div>
									<div class="col-4">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Specialist' }">
											<div>
												<input type="radio" name="user_type" id="user_typeC" value="Specialist" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_S_DESCR' | translate }} &nbsp; (type C)</label>
											</div>
										</div>
									</div>
									<div class="col-4">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='ClinicAdmin' }">
											<div>
												<input type="radio" name="user_type" id="user_typeC" value="ClinicAdmin" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_CA_DESCR' | translate }}</label>
											</div>
										</div>
									</div>
								</div>

								<!-- LIVELLO 3 -->
								<div class="row mt-2 flex-nowrap" *ngIf="session.isGod()">
									<!-- 
																<input type="radio" name="user_type" id="user_typeV" value="Vice" [(ngModel)]="draftUser.role" (change)="changeProfile($event)" />
																{{ 'CREATE_USER.TYPE_V_DESCR' | translate }} &nbsp;
																-->

									<div class="col-1 d-flex align-items-center">
										<div>Lv.3 <fa-icon [icon]="faArrowRight" aria-hidden="true"></fa-icon></div>
									</div>
									<div class="col-3">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Manager' }">
											<div>
												<input type="radio" name="user_type" id="user_typeN" value="Manager" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_N_DESCR' | translate }}</label>
											</div>
										</div>
									</div>
									<div class="col-3">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Statistician' }">
											<div>
												<input type="radio" name="user_type" id="user_typeT" value="Statistician" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2"> {{ 'CREATE_USER.TYPE_Q_DESCR' | translate }}</label>
											</div>
										</div>
									</div>
									<div class="col-3">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Support' }">
											<div>
												<input type="radio" name="user_type" id="user_typeU" value="Support" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_U_DESCR' | translate }} </label>
											</div>
										</div>
									</div>
									<div class="col-3">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Installer' }">
											<div>
												<input type="radio" name="user_type" id="user_typeU" value="Installer" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_I_DESCR' | translate }} </label>
											</div>
										</div>
									</div>
									<div class="col-2" [hidden]="!session.isFirstGod()">
										<div class="select-type-container d-flex align-items-center" [ngClass]="{'type-selected' : draftUser.role=='Admin' }">
											<div>
												<input type="radio" name="user_type" id="user_typeG" value="Admin" [(ngModel)]="draftUser.role" (change)="changeProfile()" />
												<label class="ms-2">{{ 'CREATE_USER.TYPE_G_DESCR' | translate }} </label>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- SUBTYPE -->
							<div class="col-2" *ngIf="enableSubtype()">
								<div class="form-group">
									<label for="subtype">{{ 'CREATE_USER.SUBTYPE' | translate }}</label><br />
									<select name="subtype" [(ngModel)]="draftUser.user_subtype" (change)="onChangeSubtype()" class="form-control">
										<option *ngFor="let opt of usrSubtypes" [ngValue]="opt">{{ opt }}</option>
									</select>
								</div>
							</div>

							<!-- IS TEST -->
							<div class="col-2" *ngIf="enableTestFlag()">
								<div class="form-group">
									<label for="istest">{{ 'CREATE_USER.TEST' | translate }}</label><br />
									<select name="istest" [(ngModel)]="draftUser.is_test" class="form-control">
										<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
									</select>
								</div>
							</div>
						</div>

						<div>
							<hr />
						</div>

						<div class="row">
							<div class="col-9">
								<!-- USERNAME AND PASSWORD -->
								<div class="row">
									<div class="form-group col-6">
										<label for="username">{{ 'CREATE_USER.USERNAME' | translate }} *</label>
										<!-- [ngClass]="{'has-error': un.touched && !un.valid}" -->
										<div class="input-group">
											<input
												type="text"
												maxlength="100"
												class="form-control normalcase"
												[ngClass]="{'has-error': !validUsername}"
												(change)="onChangeUsrname()"
												(keyup)="hiddenExtension()"
												name="username"
												id="username"
												#username
												placeholder="{{ 'CREATE_USER.USERNAME_HINT' | translate }}"
												[(ngModel)]="draftUser.username"
												#un="ngModel"
												required />
											<span class="input-group-text" [hidden]="usernameExtension == '' ">{{usernameExtension}}</span>
										</div>

										<div class="error-wrapper">
											<p class="error-message" [hidden]="!(un.touched && un.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
											<!-- <p class="error-message" [hidden]="validUsername">{{ 'MESSAGE.INVALID_FIELD' | translate }}</p> -->
										</div>
									</div>

									<div class="form-group col-6">
										<!-- richiesta NS, default visibile -->
										<label for="password">{{ 'CREATE_USER.PASSWORD' | translate }} *</label>

										<div class="position-relative">
											<fa-icon [icon]="showPwd ? faEyeOpen : faEyeClosed" (click)="clickTogglePassword()" aria-hidden="true" class="show-psw-icon"></fa-icon>
											<input
												[type]="showPwd ? 'text' : 'password'"
												maxlength="50"
												class="form-control normalcase"
												name="password"
												id="password"
												#password
												placeholder="{{ 'CREATE_USER.PASSWORD_HINT' | translate }}"
												[ngClass]="{'has-error': (pwd.touched && !pwd.valid) || !validPassword}"
												[(ngModel)]="draftUser.password"
												#pwd="ngModel"
												required
												[readOnly]="(draftUser.role == 'Optician') || (draftUser.role == 'Doctor')|| (draftUser.role == 'Specialist')" />
										</div>

										<p class="error-message" [hidden]="!(pwd.touched && pwd.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
									</div>
									<!-- Error message -->
									<div class="col-6 d-flex mt-2">
										<div class="mini-modal-warning" [hidden]="validUsername">
											<div class="arrow-up"></div>
											<div class="warning-header warning"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning"></fa-icon> {{ 'MESSAGE.INVALID_FIELD' | translate }}</div>
											<div class="warning-body"><span> {{alertMessage}}</span></div>
										</div>
									</div>
									<div class="col-6 d-flex align-items-start mt-2">
										<div class="mini-modal-warning" *ngIf="(draftUser.role == 'Optician') || (draftUser.role == 'Doctor')|| (draftUser.role == 'Specialist'); else passwordNotSafe">
											<div class="arrow-up"></div>
											<div class="warning-body">
												<fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon>
												<span> {{ 'CREATE_USER.TEMPORARY_PWD_MSG' | translate }}</span>
											</div>
										</div>

										<ng-template #passwordNotSafe>
											<div class="mini-modal-warning" [hidden]="validPassword">
												<div class="arrow-up"></div>
												<div class="warning-header warning"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning"></fa-icon> Alert</div>
												<div class="warning-body"><span> {{alertPswMessage}}</span></div>
											</div>
										</ng-template>
									</div>

									<div>
										<hr />
									</div>
								</div>
								<div class="row">
									<div class="form-group col-6">
										<label for="firstname">{{ 'CREATE_USER.FIRSTNAME' | translate }} *</label>
										<input type="text" maxlength="100" class="form-control" name="firstname" id="firstname" placeholder="{{ 'CREATE_USER.FIRSTNAME_HINT' | translate }}" [ngClass]="{'has-error': fn.touched && !fn.valid}" [(ngModel)]="draftUser.firstname" #fn="ngModel" required />
										<p class="error-message" [hidden]="!(fn.touched && fn.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
									</div>

									<div class="form-group col-6">
										<label for="lastname">{{ 'CREATE_USER.LASTNAME' | translate }} *</label>
										<input type="text" maxlength="100" class="form-control" name="lastname" id="lastname" placeholder="{{ 'CREATE_USER.LASTNAME_HINT' | translate }}" [ngClass]="{'has-error': ln.touched && !ln.valid}" [(ngModel)]="draftUser.lastname" #ln="ngModel" required />
										<p class="error-message" [hidden]="!(ln.touched && ln.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-6">
										<!-- half-container"  -->
										<label for="address">{{ 'PATIENT.MODAL_ADDRESS' | translate }} *</label>
										<input type="text" maxlength="100" class="form-control normalcase" name="address" id="address" placeholder="{{ 'PATIENT.MODAL_ADDRESS_HINT' | translate }}" [ngClass]="{'has-error': addr.touched && !addr.valid}" [(ngModel)]="mainAddr.address_line1" #addr="ngModel" required />
										<p class="error-message" [hidden]="!(addr.touched && addr.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
									</div>

									<div class="form-group col-6">
										<label for="city">{{ 'PATIENT.MODAL_CITY' | translate }} *</label>
										<input type="text" maxlength="100" class="form-control normalcase" id="city" name="city" placeholder="{{ 'PATIENT.MODAL_CITY_HINT' | translate }}" [ngClass]="{'has-error': city.touched && !city.valid}" [(ngModel)]="mainAddr.city" #city="ngModel" required />
										<p class="error-message" [hidden]="!(city.touched && city.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-6">
										<label for="province">{{ 'PATIENT.MODAL_PROVINCE' | translate }} *</label>
										<input type="text" maxlength="100" class="form-control normalcase" id="province" name="province" placeholder="{{ 'PATIENT.MODAL_PROVINCE_HINT' | translate }}" [ngClass]="{'has-error': pr.touched && !pr.valid}" [(ngModel)]="mainAddr.province" #pr="ngModel" required />
										<p class="error-message" [hidden]="!(pr.touched && pr.invalid)">{{ 'MESSAGE.REQUIRED' | translate }}</p>
									</div>

									<!-- tendina con le sigle COUNTRY - circa 200...  -->

									<div class="form-group col-6">
										<label for="country">{{ 'PATIENT.MODAL_COUNTRY' | translate }} * </label>
										<searchdropdown *ngIf="countries != null && countries.length > 0" class="normalcase" style="padding: 0" [countries]="countries" #searchCountry (outcountry)="selCountry($event)" (cancelCountry)="resetCountry($event)" aria-required="true"> </searchdropdown>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-6">
										<label for="ref_email">{{ 'CREATE_USER.REF_EMAIL' | translate }}</label>
										<input type="text" maxlength="100" class="form-control normalcase" name="ref_email" id="ref_email" placeholder="{{ 'CREATE_USER.REF_EMAIL_HINT' | translate }}" [(ngModel)]="mainAddr.ref_email" email required />
									</div>

									<div class="form-group col-6">
										<label for="phone">{{ 'CREATE_USER.PHONE' | translate }}</label>
										<input type="text" maxlength="100" class="form-control" name="phone" id="phone" placeholder="{{ 'CREATE_USER.PHONE_HINT' | translate }}" [(ngModel)]="mainAddr.phone1" />
									</div>
								</div>

								<div class="row">
									<div class="form-group col-6">
										<label for="group">{{ 'CREATE_USER.ORGANIZATION' | translate }} <span [hidden]="draftUser.role != 'Optician'">{{ 'CREATE_USER.ORGANIZ_REQ_MSG' | translate }} *</span></label>
										<input type="text" maxlength="100" class="form-control normalcase" name="organization" id="organization" placeholder="{{ 'CREATE_USER.ORGANIZATION_HINT' | translate }}" [(ngModel)]="mainAddr.organization" required *ngIf="draftUser.role == 'Optician'; else orgNotReq" />

										<ng-template #orgNotReq>
											<input type="text" maxlength="100" class="form-control normalcase" name="organization" id="organization" placeholder="{{ 'CREATE_USER.ORGANIZATION_HINT' | translate }}" [(ngModel)]="mainAddr.organization" />
										</ng-template>
									</div>

									<div class="form-group col-6" *ngIf="draftUser.role == 'Optician' || draftUser.role == 'Doctor'">
										<label for="vat">{{"PROFILE.VAT" | translate}} </label>
										<div class="vat-input-container">
											<div class="country-code">
												<p *ngIf="mainAddr.country">{{countryIso2.toUpperCase()}}</p>
											</div>
											<input type="text" class="form-control vat" id="vat" name="vat" [ngClass]="{ 'has-error': vatError || internalVatCheckerError }" (keyup)="onChangeVat($event)" [disabled]="isValidatingVat || disableVat" [(ngModel)]="mainAddr.vat" />
										</div>
										<p class="error-message" [hidden]="!(mainAddr.country && vatError) || internalVatCheckerError || isValidatingVat">{{ "PROFILE.VAT_ERROR" | translate }}</p>
										<p class="error-message" [hidden]="!internalVatCheckerError || isValidatingVat">{{ "PROFILE.INTERNAL_VAT_ERROR" | translate }}</p>
										<div [hidden]="!(mainAddr.country && isValidatingVat)" class="info-message">
											<div class="spinner-border spinner-border-sm text-primary" role="status">
												<span class="sr-only">Loading...</span>
											</div>
											<p>{{ "PROFILE.VAT_EXISTENCE_CHECK" | translate }}</p>
										</div>
									</div>

									<div class="form-group col-6" *ngIf="draftUser.role == 'Doctor' || draftUser.role == 'Specialist' || draftUser.role == 'ClinicAdmin'">
										<label for="order_reg_num">{{"PROFILE.LICENCE_NUM" | translate}} </label>
										<input type="text" class="form-control" id="order_reg_num" name="order_reg_num" placeholder="{{'DISTRIB.MODAL_ORDER_REG_NUM_HINT' | translate}}" [(ngModel)]="draftUser.order_reg_num" />
									</div>

									<div class="col-6 d-flex align-items-center justify-content-center">
										<div class="alert mb-0 text-center" [ngClass]="{'show' : showBanner, 'alert-success' : typeBanner==1, 'alert-danger': typeBanner==2}" role="alert">
											<div><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning" [hidden]="typeBanner==1"></fa-icon> {{bannerMsg}}</div>
										</div>
									</div>
								</div>

								<!-- 31.08.2021
														<div class="row" *ngIf="draftUser.enablePlans()"> 
															<div class="form-group full-container">
															<label for="plan">{{"CREATE_USER.SALE_PLAN" | translate}} *</label>                             
															<br>
															<span ng-repeat="opt in draftUser.session.salePlans">   
															<input type="radio" name="salePlan" id="plan_{{opt.id}}" value="{{opt.id}}"                    
																[disabled]="(opt.name != 'basic')"
																[(ngModel)]="draftUser.salePlanId" > {{opt.name}} [{{opt.description}}] 
															<br>
															</span>
															</div>
														</div>
														-->
							</div>

							<div class="col-3">
								<!-- settings fields style="float:right;" -->

								<div class="settings" *ngIf="enableSettings()">
									<div class="container">
										<div class="row mb-2">
											<div class="col header-settings">
												<p>SETTINGS</p>
											</div>
										</div>

										<!-- 07.03.2019 test if per campo brand required -->

										<!-- molti sono solo per livelli 1 -->
										<div *ngIf="draftUser.role == 'Optician' || draftUser.role == 'Doctor'">
											<div class="row">
												<div class="col-6">
													<label for="upload">{{ 'DOCTOR.MODAL_SETT_UPL_EXAMS' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="upload" [(ngModel)]="settings.upload_exams" class="form-control">
														<option *ngFor="let opt of uploadOptions" [ngValue]="opt.val">{{ opt.descr }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="cup_to_disc">Cup to disc</label>
												</div>
												<div class="col-6">
													<select name="cup_to_disc" [(ngModel)]="settings.cup_to_disc" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="download">{{ 'SETTINGS.SYNCR_PATS' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="download" [(ngModel)]="settings.syncr_patients" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="download">{{ 'SETTINGS.UPDATES' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="download" [(ngModel)]="settings.updates_enabled" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="group">{{ 'DOCTOR.ANAMN_GROUP' | translate }}</label>
												</div>
												<div class="col-6">
													<input type="number" maxlength="5" class="form-control text-right" name="group" id="group" min="0" [(ngModel)]="settings.anamnesis_group" />
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="days">{{ 'DOCTOR.MODAL_SETT_DAYS' | translate }}</label>
												</div>
												<div class="col-6">
													<input type="number" maxlength="10" class="form-control text-right" name="days" id="days" min="0" [(ngModel)]="settings.offline_days" required />
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="share_folder">{{ 'DOCTOR.MODAL_SETT_SHARE_FOLDER' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="download" [(ngModel)]="settings.shared_folder" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="remote">{{ 'SETTINGS.REMOTE_SUPPORT' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="remote" [(ngModel)]="settings.remote_support" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="telerefr">{{ 'SETTINGS.TELEREFRACT' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="telerefr" [(ngModel)]="settings.telerefract" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="recovery">{{ 'SETTINGS.RECOVERY' | translate }}</label>
												</div>
												<div class="col-6">
													<!-- <input type="text" readonly class="form-control" name="recovery" [(ngModel)]="settings.recovery" /> -->
													<select name="recovery" [(ngModel)]="settings.recovery" class="form-control">
														<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
													</select>
												</div>
											</div>

											<!-- 12.04.2023 not used ? 
											<div class="row">
												<div class="col-6">
													<label for="mosaic">{{ 'SETTINGS.MOSAIC' | translate }}</label>
												</div>
												<div class="col-6">
													<input type="text" readonly class="form-control" name="mosaic" [(ngModel)]="settings.mosaic" />
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="cupToDisc">{{ 'SETTINGS.CUP_TO_DISC' | translate }}</label>
												</div>
												<div class="col-6">
													<input type="text" readonly class="form-control" name="cupToDisc" [(ngModel)]="settings.cup_to_disc" />
												</div>
											</div>
                    						-->

											<div class="row" [hidden]="draftUser.user_subtype != 'Super'">
												<div class="col-6">
													<label for="maxB">{{ 'DOCTOR.MODAL_SETT_MAX_COLLEAGUES' | translate }}</label>
												</div>
												<div class="col-6">
													<input type="text" maxlength="10" class="form-control text-right" name="maxB" id="maxB" placeholder="{{ 'DOCTOR.MODAL_SETT_MAX_COLLEAGUES' | translate }}" [(ngModel)]="settings.max_colleagues" />
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="dicom_level">{{ 'SETTINGS.DICOM_LEVEL' | translate }}</label>
												</div>
												<div class="col-6">
													<select name="dicom_level" [(ngModel)]="settings.dicom_level" class="form-control">
														<option *ngFor="let opt of session.dicomLevels" [ngValue]="opt.val">{{ opt.descr }}</option>
													</select>
													<!-- ['No', 'Find&Store','MWL'] -->
												</div>
											</div>

											<div class="row">
												<div class="col-6">
													<label for="ai_type">AI Type</label>
												</div>
												<div class="col-6">
													<select name="ai_type" [(ngModel)]="settings.ai_type" class="form-control">
														<option *ngFor="let opt of session.aiTypes" [ngValue]="opt.val">{{ opt.descr }}</option>
													</select>
												</div>
											</div>
										</div>

										<div class="row" *ngIf="draftUser.role == 'Optician' || draftUser.role == 'Doctor' || draftUser.role == 'Specialist' || draftUser.role == 'ClinicAdmin'">
											<div class="col-6">
												<label for="diagn_grp">{{ 'SETTINGS.DIAGNOSIS_GRP' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="diagn_grp" [(ngModel)]="settings.diagnosis_group" class="form-control">
													<option *ngFor="let opt of session.diagnosisGroups" [ngValue]="opt.val">{{ opt.descr }}</option>
												</select>
												<!-- ['No', 'ICD10'] -->
											</div>
										</div>

										<div class="row">
											<!-- brand x tutti ? -->
											<div class="col-6">
												<label for="brand">{{ 'DOCTOR.MODAL_SETT_BRAND' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="brand" [(ngModel)]="settings.brand" class="form-control">
													<!-- ng-required="opt!=''" -->
													<option *ngFor="let opt of session.brands" [ngValue]="opt">{{ opt }}</option>
												</select>
											</div>
										</div>

										<div class="row">
											<!-- ECOMMERCE -->
											<div class="col-6">
												<label for="ecommerce">{{ 'SETTINGS.ECOMMERCE' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="ecommerce" [(ngModel)]="settings.ecommerce" class="form-control">
													<!-- ng-required="opt!=''" -->
													<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
												</select>
											</div>
										</div>

										<div class="row" *ngIf="(draftUser.role == 'Optician')">
											<div class="col-6">
												<label for="free_trial_available">{{ 'SETTINGS.FREE_TRIAL_ABAILABLE' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="free_trial_available" [(ngModel)]="settings.free_trial_available" class="form-control">
													<option *ngFor="let opt of session.freeTrialOptions" [ngValue]="opt">{{ opt }}</option>
												</select>
											</div>

											<div class="col-6">
												<label>{{ 'SETTINGS.SELF_REGISTRATION' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="sel_reg_available" [(ngModel)]="settings.p_self_reg" class="form-control">
													<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
												</select>
											</div>
										</div>

										<div class="row" *ngIf="draftUser.role == 'Specialist' || draftUser.role == 'ClinicAdmin'">
											<!-- 21.02.2022 -->
											<div class="col-6">
												<label for="share_folder">{{ 'SETTINGS.DIAGONAL' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="diagonal" [(ngModel)]="admSettings.diagonal" class="form-control">
													<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
												</select>
											</div>
										</div>

										<div class="row" *ngIf="draftUser.role == 'Specialist' || draftUser.role == 'ClinicAdmin'">
											<!-- 21.02.2022 -->
											<div class="col-6">
												<label for="share_folder">{{ 'SETTINGS.LINTEL' | translate }}</label>
											</div>
											<div class="col-6">
												<select name="lintel" [(ngModel)]="admSettings.lintel" class="form-control">
													<option *ngFor="let opt of yesNoOptions" [ngValue]="opt">{{ opt }}</option>
												</select>
											</div>
										</div>

										<!-- models per i manager  -->
										<div class="row" *ngIf="draftUser.role == 'Manager'">
											<div class="col-6">
												<label>{{ 'DEVICE.MODELS' | translate }} *</label>
											</div>
										</div>

										<div class="row" *ngIf="draftUser.role == 'Manager'">
											<div class="form-group col-5">
												<ul class="list-group" id="list1">
													<li class="list-group-item active">AVAILABLE</li>
													<!-- dinamico: getModels() -->
													<li *ngFor="let model of listAvail; index as i" class="list-group-item"><input type="checkbox" name="{{ i }}_av" (change)="addModel(i)" id="{{ model }}" /> {{ model }}</li>
												</ul>
											</div>

											<div class="form-group col-2 text-center">
												<br />
												<!-- <label class="center-block"> choose </label> -->
												<br />
												<fa-icon [icon]="faArrowRight" aria-hidden="true"></fa-icon>
												<br />
												<fa-icon [icon]="faArrowLeft" aria-hidden="true"></fa-icon>
											</div>
											<div class="form-group col-5">
												<ul class="list-group" id="list2">
													<li class="list-group-item active">SELECTED</li>
													<!-- <input title="toggle all" type="checkbox" class="all pull-right"> -->
													<li *ngFor="let model of listSelected; index as j" class="list-group-item"><input type="checkbox" name="{{ j }}_sel" (change)="removeModel(j)" id="{{ model }}" /> {{ model }}</li>
												</ul>
											</div>
										</div>
									</div>

									<!-- chiude div solo livelli 1 -->

									<!-- END models per i manager  -->
								</div>
							</div>
							<!-- end settings -->
						</div>

						<!-- <div class="row">
							<div class="col-4"><p>&nbsp;</p></div>

							<div class="col-4 d-flex align-items-center justify-content-center">
								<div class="alert mb-0 text-center" [ngClass]="{'show' : showBanner, 'alert-success' : typeBanner==1, 'alert-danger': typeBanner==2}" role="alert">
									<div><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning" [hidden]="typeBanner==1"></fa-icon> {{bannerMsg}}</div>
								</div>
							</div>

							<div class="col-4"></div>
						</div> -->

						<div class="row">
							<div class="col-12 py-4">
								<button type="submit" class="btn btn-primary" [disabled]="createUserForm.invalid || mainAddr.country === '' || vatError || internalVatCheckerError || isValidatingVat" (click)="createUser(createUserForm)">{{ 'BUTTONS.SUBMIT' | translate }}</button>
							</div>
						</div>
					</form>

					<div class="row py-3" [hidden]="!success">
						<div class="col align-self-center">
							<div class="alert mb-0 text-center alert-success show" role="alert">
								<div>{{ 'CREATE_USER.DESCRIPTION_SUCCESS' | translate }}</div>
							</div>
						</div>
					</div>

					<div class="row mb-4 text-center">
						<div [hidden]="!success" class="col align-self-center">
							<p>Assigned User Id: {{ assignedUserId }}</p>
							<button type="button" class="btn btn-primary" (click)="restartForm()">CREATE ANOTHER</button>
							<!-- <button type="button" class="btn btn-primary btn-block" ng-click="goLanding()">{{"BUTTONS.PROCEED" | translate}}</button> -->
						</div>
					</div>
				</div>
			</div>
			<!-- panel-body -->
		</div>
	</div>

	<footer></footer>
</div>

<div class="loading-container" [hidden]="!dataService.isLoadingAdmins()">
	<div class="tail-spin">&nbsp;</div>
</div>

<div>
	<div class="topview doctor-list-container" [hidden]="!dataService.hasLoadedAdmins()">
		<div class="row">
			<div class="full-container">
				<div class="panel panel-default">
					<div class="panel-heading">
						<!-- <div class="row">
							<div class="third-container">
								<label class="panel-title">{{ 'ADMIN_LIST.PAGE_TITLE' | translate }}</label>
							</div>
						</div> -->
					</div>
					<div class="panel-body">
						<table mat-table [dataSource]="adminList" matSort style="width: 100%">
							<!-- User NAme Column -->
							<ng-container matColumnDef="username">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCTOR_LIST.USERNAME' | translate }}</th>
								<td mat-cell *matCellDef="let element" [ngStyle]="{'color': element.isDeleted ? 'rgb(70, 81, 82)' : 'black', 'fontWeight': element.isDeleted ? '100' : '900', 'fontStyle': element.isDeleted ? 'italic' : 'normal'}">{{ element.username }}</td>
							</ng-container>

							<ng-container matColumnDef="user_type">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCTOR.TYPE' | translate }}</th>
								<td mat-cell *matCellDef="let element">{{ element.user_type }}</td>
							</ng-container>

							<ng-container matColumnDef="code">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DOCTOR.CODE' | translate }}</th>
								<td mat-cell *matCellDef="let element">{{ element.code }}</td>
							</ng-container>

							<ng-container matColumnDef="subscriptionTime">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>SINCE</th>
								<td mat-cell *matCellDef="let element">{{ session.formatDate(element.subscriptionTime) }}</td>
							</ng-container>

							<ng-container matColumnDef="created_by">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.CREATED_BY' | translate }}</th>
								<td mat-cell *matCellDef="let element" title="{{ element.created_by }}">{{ getStrCreator(element.created_by) }}</td>
							</ng-container>

							<ng-container matColumnDef="notes">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>Notes</th>
								<td mat-cell *matCellDef="let element">{{ element.getModels()}}</td>
							</ng-container>

							<ng-container matColumnDef="filter">
								<th mat-header-cell *matHeaderCellDef>
									<mat-form-field appearance="outline"> <input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter /> </mat-form-field>&nbsp;
								</th>

								<td mat-cell *matCellDef="let element">
									<a class="table-button ms-1" *ngIf="canBeDeleted(element)" (click)="openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}"> <fa-icon [icon]="faTrashAlt" aria-hidden="true"></fa-icon> </a>

									<a class="table-button ms-1" *ngIf="canBeUpdated(element)" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="openEditModal(element,'modify')">
										<fa-icon [icon]="faEdit" aria-hidden="true"></fa-icon>
									</a>
									<a class="table-button ms-1" *ngIf="!canBeUpdated(element) && !element.isDeleted" ngbTooltip="{{ 'BUTTONS.SHOW' | translate }}" (click)="openEditModal(element,'support')">
										<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
									</a>

									<a class="table-button ms-1" ngbTooltip="events log" (click)="openUserEvents(element)">
										<fa-icon [icon]="faRectangleList" aria-hidden="true"></fa-icon>
									</a>
								</td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
						</table>

						<mat-paginator [pageSizeOptions]="[10, 15, 20, 25, 50]" showFirstLastButtons> </mat-paginator>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="container-fluid" [ngClass]="!isProfile? 'locations-container': '' ">
	<div class="row py-3">
		<div [ngClass]="isProfile ? 'col-12' : 'col-8' ">
			<p *ngIf="infoString.length>0" [ngClass]="warningAddr ? 'warning': '' ">
				<fa-icon [icon]="faInfoCircle" faInfoCircle aria-hidden="true" class="me-2" *ngIf="!warningAddr"></fa-icon>
				<fa-icon [icon]="faTriangleExclamation" faTriangleExclamation aria-hidden="true" class="me-2 warning error-blink" *ngIf="warningAddr"></fa-icon>
				<span>{{infoString}}</span>
			</p>
			<div class="change-address-container" *ngIf="changeAddress">
				<p (click)="changeAddress=!changeAddress" style="display: inline-block"><fa-icon [icon]="faPlus" *ngIf="!isProfile" class="me-2"></fa-icon><span>{{ isProfile ? ('MAP_EL.CHANGE_ADDRESS' | translate) : ('MAP_EL.ADD_CLINIC_ADDRESS' | translate) }}</span></p>
			</div>
			<div class="search-address-container my-3 position-relative" *ngIf="!changeAddress">
				<input type="text" #input class="form-control ng-valid w-50 d-inline" placeholder="{{'MAP_EL.PLACEHOLDER_ADDR' | translate}}" [disabled]="waitSave" />
				<button class="btn btn-primary ms-4" (click)="search()" [disabled]="isLoadingMap || isSearchingLocation || waitSave">
					<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="isSearchingLocation"></span>
					{{ isSearchingLocation ? ('MAP_EL.LOADING_BUTTON' | translate) : ('MAP_EL.SEACH_BUTTON' | translate) }}
				</button>
				<button type="button" class="btn btn-secondary ms-3" *ngIf="!changeAddress" [hidden]="isProfile" [disabled]="isLoadingMap || isSearchingLocation || waitSave" (click)="closeSearch()">{{'MAP_EL.LOCATION_SAVED.CLOSE' | translate}}</button>
				<span class="ms-4" *ngIf="addressListReady && isProfile" [ngClass]="addressList.length == 0 ? 'warning': 'main-grey' ">{{seachString}}</span>
				<div class="position-relative">
					<div class="responses shadow" *ngIf="addressListReady && isProfile">
						<div *ngFor="let address of addressList" class="responses__line">
							<div (click)="addLocation(address)">
								<span>{{ address.place.label }}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="mini-modal-warning" *ngIf="!addressListReady">
					<div class="arrow-up"></div>
					<div class="warning-header fw-bold"><fa-icon class="error-blink" [icon]="faInfoCircle" aria-hidden="true"></fa-icon> {{ 'MISC.ATTENTION' | translate }}</div>
					<div class="warning-body mt-2" [innerHTML]="attentionBodyAddress">
						<span></span>
					</div>
				</div>
			</div>
		</div>
		<div [ngClass]="isProfile ? 'col-12' : 'col-8' ">
			<div class="maps-container" [ngClass]="isProfile ? 'isprofile' : '' ">
				<!-- LOADER -->

				<loader [activateSpinner]="isSearchingLocation"></loader>

				<!-- <div class="loader-container" [ngClass]="isSearchingLocation ? 'show' : '' ">
				<div [hidden]="false" class="lds-ring">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
			</div> -->
				<!-- STRING IF BRIDGE NOT AVAILABLE -->
				<div class="loader-container show text-center" *ngIf="!session.infoBridgeAvailable">
					<p>{{'MAP_EL.BRIDGE_MAP_NOT_AVAIL' | translate}}</p>
				</div>
				<!-- MAPPA -->
				<div id="map" [ngClass]="isLoadingMap ? '' : 'show' "></div>
			</div>
		</div>
		<div class="col-4" *ngIf="!isProfile">
			<div *ngIf="addressListReady">
				<h4 class="fw-bold text-center">{{'MAP_EL.RESULTS_TITLE' | translate}}</h4>
				<p class="fw-bold findings">{{'MAP_EL.ADDRESS_FIND'| translate}} {{addressList.length}}</p>
			</div>

			<div *ngIf="!addressListReady" class="text-center">
				<h4 class="fw-bold">{{'MAP_EL.LOCATIONS_TITLE' | translate}}</h4>
			</div>
			<div class="responses-clinic" id="responsesClinic">
				<div *ngIf="addressListReady">
					<div *ngFor="let address of addressList" class="responses-clinic__line">
						<div (click)="viewPlace(address.marker); address.selected = true" [ngClass]="address.selected ? 'selected' : ''">
							<span>{{ address.place.label }}</span>
						</div>
						<button class="btn btn-outline-primary btn-sm" (click)="selectLocation(address)">{{'BUTTONS.ADD' | translate}}</button>
					</div>
				</div>

				<div *ngIf="!addressListReady">
					<div *ngIf="selectedLocations.length==0" class="text-center">
						<p>{{'MAP_EL.NO_LOCATIONS' | translate}}</p>
						<p>{{'MAP_EL.NO_LOCATIONS_SUB' | translate}}</p>
					</div>

					<div *ngFor="let loc of selectedLocations" class="saved-location mb-3" (click)="selectMarker(loc)" [ngClass]="loc.selected ? 'selected' : '' ">
						<div class="saved-location__container position-relative" [ngClass]="loc.edit ? 'edit' : ''">
							<p class="main-address-tag" *ngIf="loc.isMain">{{'MAP_EL.LOCATION_SAVED.MAIN_ADDRESS_LABEL' | translate}}</p>
							<label>{{'MAP_EL.LOCATION_SAVED.NAME' | translate}}</label>
							<input type="text" class="form-control form-control-sm mb-3 w-75" maxlength="60" [(ngModel)]="loc.label" *ngIf="!loc.saved" required (click)="$event.stopPropagation()" />
							<p class="fw-bold" *ngIf="loc.saved">{{ loc.label }}</p>
							<label>{{'MAP_EL.LOCATION_SAVED.ADDRESS' | translate}}</label>
							<p class="fw-bold">{{ loc.place.label }}</p>
							<div class="saved-location__button-container" [ngClass]="loc.saved  ? '' : 'edit'">
								<a (click)="addLocation(loc)" class="me-4 save-edit-button"><fa-icon [icon]="loc.saved ? faPenToSquare : faSave" class="me-2"></fa-icon><span>{{loc.saved ? ('MAP_EL.LOCATION_SAVED.EDIT' | translate): ('MAP_EL.LOCATION_SAVED.SAVE' | translate)}}</span></a>

								<a (click)="removeLocation(loc); $event.stopPropagation()" class="discard-delete-button" *ngIf="!loc.saved || !loc.isMain"
									><fa-icon [icon]="loc.edit ? faRotateLeft : faTrashCan" faTrashCan class="me-2"></fa-icon> <span>{{loc.edit ? ('MAP_EL.LOCATION_SAVED.DISCARD' | translate): ('MAP_EL.LOCATION_SAVED.DELETE' | translate)}}</span></a
								>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

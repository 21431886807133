import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { SessionService } from 'src/app/service/session.service'

import { QRCodeComponent } from 'angularx-qrcode'
import { Config } from 'src/config'
import { SafeUrl } from '@angular/platform-browser'

import { stateType } from '../message-modal/message.modal'

import { v4 as uuidv4 } from 'uuid'
import { QrCodeService } from 'src/app/service/qrCode.service'
import { Subscription, interval } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'

@Component({
	selector: 'qr-code',
	templateUrl: './qrCode.modal.html',
	styleUrls: ['./qrCode.modal.scss'],
})
export class QRCode implements OnInit, OnDestroy {
	@ViewChild('qrcode') qrcodeComponent: QRCodeComponent

	qrCodeUrl: string
	qrCodeImg: string
	userId: number
	groupId: number
	anamId: number
	country: string
	storeName: string

	myUuid: string

	showMessage: boolean
	isLoading: boolean
	messageState: stateType
	messageText: string[]
	expired: boolean
	scanned: boolean

	scannedQRCodeSubscription: Subscription

	// timerSubscription: Subscription

	constructor(public activeModal: NgbActiveModal, private session: SessionService, private QRService: QrCodeService, private translator: TranslateService) {
		this.showMessage = false
		this.messageState = stateType.SUCCESS
		this.isLoading = true
		this.expired = false
		this.scanned = false

		this.messageText = [this.translator.instant('QRCODE.QR_SCANNED')]

		this.userId = this.session.user.user_id
		this.groupId = this.session.user.groupId
		this.anamId = this.session.user.settings.anamnesis_group
		this.country = this.session.user.country
		this.storeName = this.session.user.getOrganization()

		this.generateNewUUID()
	}

	ngOnInit(): void {
		// faccio in modo che il servizio resti in ascolto per eventuali qr scansionati
		// quando esco dal qrcode gli faccio fare la unsubscribe in modo che non rimanaga appeso li ad ascoltare per niente
		this.QRService.listenForScannedQRCode()

		this.scannedQRCodeSubscription = this.QRService.scannedQRCode.subscribe((uuid: string) => {
			// console.log(uuid)
			if (this.myUuid === uuid) {
				this.scanned = true
				this.showMessage = true
				this.expired = true
				this.messageState = stateType.SUCCESS
				this.messageText = [this.translator.instant('QRCODE.QR_SCANNED')]
			}
		})
	}

	//genero un nuovo uuid, ma potrebbe essere che sia giá presente nella nexus, per cui provo a generarlo n volte e ad inviarlo con un post se mi viene ritornato
	async generateNewUUID() {
		this.showMessage = false
		this.expired = false
		this.scanned = false

		let uuidValid = false
		let count = 0
		let error

		while (!uuidValid) {
			// this.myUuid = '8496f20e-cfe8-4917-9474-be8af71f0b66' //for fail test
			this.myUuid = uuidv4()
			count++
			// console.log(this.myUuid)
			if (count < 4) {
				await this.QRService.newUUID(this.myUuid)
					.then((resp: { uuid_id: number }) => {
						uuidValid = true
						// console.log(resp)
						this.buildQRCodeUrl()
						this.isLoading = false
						this.QRService.setUUIDList(this.myUuid, resp.uuid_id)
						this.startTimer()
					})
					.catch((err) => {
						// console.log(err)
						error = err
					})
			} else {
				console.log(error)
				this.showMessage = true
				this.messageState = stateType.FAIL

				if (error.state == 409) {
					this.messageText = [this.translator.instant('QRCODE.UUID_ERROR1')]
				} else {
					this.messageText = [this.translator.instant('QRCODE.UUID_ERROR2')]
				}

				break
			}
		}
	}

	private startTimer() {
		let time = Config.QRExpireTimer
		time = time * 60 * 1000

		setTimeout(() => {
			this.expired = true
			this.showMessage = true
			this.messageState = stateType.EXPIRED
			this.messageText = [this.translator.instant('QRCODE.QR_EXPIRED')]
		}, time)
	}

	private buildQRCodeUrl() {
		this.qrCodeUrl =
			Config.autoregUrl +
			'?userId=' +
			this.userId +
			'&groupId=' +
			this.groupId +
			'&anamId=' +
			this.anamId +
			'&country=' +
			this.country +
			'&uuid=' +
			this.myUuid +
			'#qrcode'

		// console.log(this.qrCodeUrl)
	}

	onChangeURL(url: SafeUrl) {
		// console.log(url)
		this.qrCodeImg = this.qrcodeComponent.qrcElement.nativeElement.querySelector('canvas').toDataURL('image/png')

		// console.log(this.qrCodeImg)
		// per scaricare solo la immagine png
		// if (img) {
		// 	// converts base 64 encoded image to blobData
		// 	let blobData = this.convertBase64ToBlob(img)
		// 	// saves as image
		// 	const blob = new Blob([blobData], { type: 'image/png' })
		// 	const url = window.URL.createObjectURL(blob)
		// 	const link = document.createElement('a')
		// 	link.href = url
		// 	// name of the file
		// 	link.download = 'angularx-qrcode'
		// 	link.click()
		// }
	}

	ngOnDestroy(): void {
		this.scannedQRCodeSubscription.unsubscribe()
		this.QRService.unsubscribeForScannedQRCode()
	}
}

import { Component, Input, OnInit } from '@angular/core'

import { Config } from '../../../config'
import { DataModelService } from '../../service/data-model.service'
import { SessionService } from '../../service/session.service'
import { Util } from '../../models/util.model'
import { Agreement, AgrStatus, AgrType } from '../../models/agreement.model'
import { User } from 'src/app/models/user.model'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'

import * as Sentry from '@sentry/angular-ivy'

@Component({
	selector: 'agreement-modal',
	templateUrl: './agreement-modal.modal.html',
	styleUrls: ['./agreement-modal.modal.scss'],
})
export class AgreementModal implements OnInit {
	@Input() fromPairing: boolean
	@Input() pairingServicesType: AgrType[]

	myAgreement: Agreement
	accepted: boolean

	agreementsAvailable: AgrType[]
	needToAcceptAgr: boolean

	user: User

	termsUrl: string
	privacyUrl: string

	agreementsDisplayList: {
		url: string
		label: string
		date: Date
		id: number
		status: string
	}[]
	form: FormGroup

	constructor(public session: SessionService, public dataService: DataModelService, private fb: FormBuilder, private activeModal: NgbActiveModal) {
		this.fromPairing = false
		this.pairingServicesType = []

		this.accepted = false
		this.myAgreement = new Agreement() // empty

		let agrStatus = this.session.getUserAgreementStatus(AgrStatus.AVAILABLE)
		// console.log(agrStatus)
		this.agreementsAvailable = []
		this.needToAcceptAgr = agrStatus.res

		for (let agr of agrStatus.agreements) {
			this.agreementsAvailable.push(agr.doc_type)
		}

		this.termsUrl = ''
		this.privacyUrl = ''
		this.agreementsDisplayList = []

		this.user = this.session.user

		this.form = this.fb.group({
			checkArray: this.fb.array([], [Validators.required]),
		})
	}

	ngOnInit(): void {
		Util.debug('(agreement modal) onInit')

		if (this.needToAcceptAgr) {
			this.session
				.loadAvailableAgreements()
				.then((agrs) => {
					// console.log(agrs)

					if (agrs.length != 0) {
						if (this.fromPairing) {
							for (let agr of agrs) {
								if (this.pairingServicesType.includes(agr.doc_type)) {
									this.agreementsDisplayList.push({
										label: `AGREEMENT.${agr.doc_type.toUpperCase()}_LINK`,
										date: agr.acceptanceDate,
										url: agr.url,
										id: agr.id,
										status: agr.status,
									})
								}
							}
						} else {
							this.agreementsDisplayList = agrs.map((agreement) => {
								return {
									label: `AGREEMENT.${agreement.doc_type.toUpperCase()}_LINK`,
									date: agreement.acceptanceDate,
									url: agreement.url,
									id: agreement.id,
									status: agreement.status,
								}
							})
						}
					}
					// non dovrebbe mai succedere che this.agreementsDisplayList.length, ma in caso di disallineamento fra doc che dovrei firmare e quelli che vengono giú effettivamente esco e faccio continuare
					if (agrs.length == 0 || this.agreementsDisplayList.length == 0) {
						Util.debug('(agreement modal) skip the agreement page, proceed with the activation')

						let error = new Error(
							'Wrong Agreement flow, fromParinig: ' +
								this.fromPairing +
								', agreementsAvailable: ' +
								this.agreementsAvailable +
								', and from loadAvailableAgreements agreementsDisplayList: ' +
								this.agreementsDisplayList.length
						)
						Sentry.setUser({
							username: this.user.username,
						})
						Sentry.captureException(error)

						this.activeModal.close()

						return
					}
				})
				.catch((error) => {
					console.log('(agreement modal) loadAvailableAgreements ko!', error)

					this.activeModal.dismiss()

					return
				})
			return
		} else {
			this.activeModal.close()
		}
	}

	public dismiss() {
		this.activeModal.dismiss()
	}

	activate() {
		Util.debug('(agreement modal) activate')

		// 1. verify the checkbox has been checked
		if (!this.accepted) return false

		let idList: number[]

		idList = []

		for (let i = 0; i < this.agreementsDisplayList.length; i++) {
			const agrId = this.agreementsDisplayList[i].id

			idList.push(agrId)
		}

		// console.log(idList)

		this.session.saveUserAgreement(idList).then(async () => {
			this.setAgreementsStatus(this.agreementsAvailable, AgrStatus.ACCEPTED)

			if (this.fromPairing) {
				this.activeModal.close()
			} else {
				if (this.session.isDoctorFirstTime()) {
					this.activeModal.close()
				} else {
					this.activeModal.dismiss()
				}
			}
		})
	}

	private setAgreementsStatus(agreements: AgrType[], status: AgrStatus) {
		for (let agr of agreements) {
			this.user.setAgreementStatus(agr, status)
		}
	}

	onCheckboxChange(e) {
		const checkArray: FormArray = this.form.get('checkArray') as FormArray
		if (e.target.checked) {
			checkArray.push(new FormControl(e.target.value))
		} else {
			let i: number = 0
			checkArray.controls.forEach((item) => {
				if (item.value == e.target.value) {
					checkArray.removeAt(i)
					return
				}
				i++
			})
		}
		this.accepted = checkArray.controls.length === this.agreementsDisplayList.length
	}
}

<div class="diagForm py-3" *ngIf="currDiagnosi != null">
	<div *ngIf="session.userIcdsEnabled()">
		<div class="diagnosi-title mb-4 text-center">
			<h4 class="fw-bold text-uppercase">{{ icdTitleMessage }}</h4>
			<p>{{'DIAGNOSI.ICD_SUBTITLE' | translate}}</p>

			<p class="">{{currDiagnosi.icdLeft.length + currDiagnosi.icdRight.length}} {{'DIAGNOSI.ICD_COUNTER' | translate}}</p>
		</div>

		<!-- NEW  -->

		<div class="row">
			<div class="col-6 right-eye-diagnosis">
				<div class="px-4">
					<p class="diagnosis-title mb-3">{{'ALBUM.RIGHT' | translate}}</p>

					<form class="right-eye-quick-choice my-2 py-2" [formGroup]="rightForm">
						<div>
							<input class="form-check-input" type="checkbox" [value]="quickHealtyRight.code" id="quickHealtyRight-{{currDiagnosi.category}}" formControlName="quickHealty" (change)="quickSelect($event, 'quickHealty', 'right')" />
							<label class="form-check-label" for="quickHealtyRight-{{currDiagnosi.category}}"> {{quickHealtyRight.descr }} </label>
						</div>

						<div>
							<input class="form-check-input" type="checkbox" [value]="quickNotGradRight.code" id="quickNotGradRight-{{currDiagnosi.category}}" formControlName="quickNotGrad" (change)="quickSelect($event, 'quickNotGrad', 'right')" />
							<label class="form-check-label" for="quickNotGradRight-{{currDiagnosi.category}}"> {{quickNotGradRight.descr}} </label>
						</div>
					</form>

					<div *ngFor="let control of controlsRight; let i = index;" class="right-eye-diagnosis__container position-relative mb-3">
						<mat-form-field style="width: 100%" appearance="outline" [ngClass]="{'is-valid': control.formValid}">
							<mat-label>{{ICD_hint}}</mat-label>

							<input type="text" placeholder="Type..." aria-label="FilterRight" matInput [formControl]="control.myControl" [matAutocomplete]="auto" #autoCompleteInput scrollCheck />

							<fa-icon [icon]="faCheck" faCheck></fa-icon>
							<fa-icon [icon]="faChevronDown" faChevronDown></fa-icon>

							<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectOption($event, control, 'right', i)" [displayWith]="displayFn">
								<mat-option *ngFor="let option of control.filter | async" [value]="option"> {{option.descr}} </mat-option>
							</mat-autocomplete>
						</mat-form-field>

						<a class="reset-button" [ngClass]="{'show': control.formValid}" (click)="$event.stopPropagation(); control.formValid=false; deleteICD('right', i);">
							<fa-icon [icon]="faXmark"></fa-icon>
						</a>
					</div>

					<div class="add-diagnosis-button" *ngIf="(controlsLeft.length + controlsRight.length) < 6">
						<fa-icon [icon]="faCirclePlus" (click)="addDiagnosisField('right')" [ngClass]="rightSelected ? '': 'disabled' " ngbTooltip="{{rightSelected ? addDiagTextEnable : addDiagTextDisable }}"></fa-icon>
					</div>
				</div>
			</div>

			<div class="col-6 left-eye-diagnosis">
				<div class="px-4">
					<p class="diagnosis-title mb-3">{{'ALBUM.LEFT' | translate}}</p>

					<form class="left-eye-quick-choice my-2 py-2" [formGroup]="leftForm">
						<div>
							<input class="form-check-input" type="checkbox" [value]="quickHealtyLeft.code" id="quickHealtyLeft-{{currDiagnosi.category}}" formControlName="quickHealty" (change)="quickSelect($event, 'quickHealty', 'left')" />
							<label class="form-check-label" for="quickHealtyLeft-{{currDiagnosi.category}}"> {{quickHealtyLeft.descr}} </label>
						</div>

						<div>
							<input class="form-check-input" type="checkbox" [value]="quickNotGradLeft.code" id="quickNotGradLeft-{{currDiagnosi.category}}" formControlName="quickNotGrad" (change)="quickSelect($event, 'quickNotGrad', 'left')" />
							<label class="form-check-label" for="quickNotGradLeft-{{currDiagnosi.category}}"> {{quickNotGradLeft.descr}} </label>
						</div>
					</form>

					<div *ngFor="let control of controlsLeft; let i = index;" class="left-eye-diagnosis__container position-relative mb-3">
						<mat-form-field style="width: 100%" appearance="outline" [ngClass]="{'is-valid': control.formValid}">
							<mat-label>{{ICD_hint}}</mat-label>

							<input type="text" placeholder="Type..." aria-label="FilterLeft" matInput [formControl]="control.myControl" [matAutocomplete]="auto" #autoCompleteInput scrollCheck />

							<fa-icon [icon]="faCheck" faCheck></fa-icon>
							<fa-icon [icon]="faChevronDown" faChevronDown></fa-icon>

							<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectOption($event, control, 'left', i)" [displayWith]="displayFn">
								<mat-option *ngFor="let option of control.filter | async" [value]="option"> {{option.descr}} </mat-option>
							</mat-autocomplete>
						</mat-form-field>
						<a
							class="reset-button"
							[ngClass]="{'show': control.formValid}"
							(click)="$event.stopPropagation(); 
						control.formValid=false;
						deleteICD('left', i);
						">
							<fa-icon [icon]="faXmark"></fa-icon>
						</a>
					</div>

					<div class="add-diagnosis-button" *ngIf="(controlsLeft.length + controlsRight.length) < 6">
						<fa-icon [icon]="faCirclePlus" (click)="addDiagnosisField('left')" [ngClass]="leftSelected ? '': 'disabled' " ngbTooltip="{{leftSelected ? addDiagTextEnable : addDiagTextDisable }}"></fa-icon>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="px-4">
	<div *ngIf="currDiagnosi.icdLeft.length > 0 && currDiagnosi.icdRight.length > 0">
		<textarea class="form-control" rows="3" maxlength="{{ freeTextMax }}" *ngIf="displayTwoFreeText" [(ngModel)]="currDiagnosi.details" (change)="resetDiagnosis()" placeholder="{{ 'DIAGNOSI.SCREENING_HINT' | translate }} - Max {{ freeTextMax }} characters"></textarea>
		<p></p>
		<textarea class="form-control" rows="3" maxlength="{{ freeTextMax }}" [(ngModel)]="currDiagnosi.treatment" (change)="resetDiagnosis()" placeholder="{{ 'DIAGNOSI.RECOMMENDATION_HINT' | translate }} - {{ 'MESSAGE.MAX_CHARS' | translate: {v1: freeTextMax} }} "></textarea>
	</div>

	<div class="button'container my-3">
		<button [hidden]="(activeTab == 'END')" class="btn btn-secondary" (click)="resetAll()">{{'BUTTONS.CLEAR' | translate}}</button>
	</div>
</div>

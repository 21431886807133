<div
	class="loading-container"
	*ngIf="
		dataService.isLoadingCategory('anamnesis') ||
		dataService.isLoadingCategory('refraction') ||
		dataService.isLoadingCategory('anterior') ||
		dataService.isLoadingCategory('cornea') ||
		dataService.isLoadingCategory('glaucoma') ||
		dataService.isLoadingCategory('fundus') ||
		isGeneratingPdf">
	<div class="tail-spin">&nbsp;</div>
</div>
<div class="ng-cloak categories" id="categCont">
	<!-- ng-controller="categories" -->

	<div class="row">
		<div class="col-2 categories-navbar">
			<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" (navChange)="onNavChange($event)" class="nav-pills" orientation="vertical">
				<!-- non usato: (activeIdChange)="onNavActiveChange($event)" -->

				<li ngbNavItem="anamnesis" [disabled]="!isCatEnabled('anamnesis')" (tabChange)="loadCategory('anamnesis')">
					<span class="text-nowrap">
						<a ngbNavLink>
							{{"CATEGORIES.ANAM_SHORT" | translate}}
							<fa-icon *ngIf="isAnamnesiComplete" [icon]="faCheck" style="color: #64a8f7" aria-hidden="true"></fa-icon>
						</a>
					</span>
					<ng-template ngbNavContent *ngIf="currentPatient.anamnesis && currentPatient.anamnesis.length > 0">
						<!-- <app-pat-anamnesis [thisAnamnesis]="currentPatient.anamnesis"></app-pat-anamnesis> -->
						<div class="row mb-3 text-center">
							<h4><span class="fw-bold">{{'ANAMNESIS.DATE_CAT' | translate}} : </span><span>{{session.formatDate(AnamDate)}}</span></h4>
						</div>
						<div class="row">
							<div class="col-8">
								<ul>
									<div *ngFor="let anamnesi of currentPatient.anamnesis; let i=index">
										<li class="d-flex flex-column align-items-start" *ngIf="anamnesi.isAnswered">
											<div class="fw-bold">{{anamnesi.question}}</div>
											<div class="ms-2">
												<ng-container *ngIf="anamnesi.answers.length > 0">
													<span *ngFor="let answer of anamnesi.answers; let e=index">
														<ng-container *ngIf="answer.checked">
															<label class="checked" for="btn-check-{{anamnesi.question_id}}-{{answer.reply_id}}"> {{answer.reply}} </label>
															<ng-container *ngIf="!isLastCheckedAnswer(anamnesi.answers, e)"><span class="checked">; </span></ng-container>
														</ng-container>
													</span>
												</ng-container>
											</div>
										</li>
									</div>
								</ul>
							</div>
							<div class="col-4">
								<table class="table" *ngIf="haveVa">
									<thead>
										<tr class="text-center">
											<th scope="col"></th>
											<th scope="col">{{"VA.RIGHT" | translate}}</th>
											<th scope="col">{{"VA.LEFT" | translate}}</th>
											<th scope="col">{{"VA.BINO" | translate}}</th>
										</tr>
									</thead>
									<tbody>
										<tr *ngFor="let vis of visusArray; let ind=index">
											<th scope="row">{{vis.name}}</th>
											<td class="text-center">{{vis.value.right}}</td>
											<td class="text-center">{{vis.value.left}}</td>
											<td class="text-center">{{vis.value.bino}}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="refraction" [disabled]="!isCatEnabled('refraction')" (tabChange)="loadCategory('refraction')">
					<!-- select="loadCategory('refraction')" -->
					<span class="text-nowrap">
						<a ngbNavLink>
							{{"CATEGORIES.REFR_SHORT" | translate}}
							<fa-icon *ngIf="isRefractionComplete" [icon]="faCheck" style="color: #64a8f7" aria-hidden="true"></fa-icon>
						</a>
					</span>
					<ng-template ngbNavContent>
						<div *ngIf="dataService.hasLoadedCategory('refraction') && hasLoadedRefraction" class="tabData sb-style">
							<div class="row nomargin catInfo">
								<refraction [modal]="categRef" [topoR]="topoR" [topoL]="topoL" [wfR]="wfR" [wfL]="wfL" [sbjR]="sbjR" [sbjL]="sbjL" [sbjB]="sbjB" [lsmR]="lsmR" [lsmL]="lsmL" [lsmB]="lsmB"> </refraction>

								<div *ngIf="isWizard">
									<div class="full-container">
										<!--  29.08.2022 always on 
                    <div class="checkboxSel">
                      <div class="form-check">
                        <input class="form-check-input me-2" type="checkbox" id="flexCheckDefault" [(ngModel)]="diagnRefraction.dtEnabled" />
                        <label class="form-check-label" for="flexCheckDefault"> {{"REPORT.DISPLAY_ON_REPORT" | translate}} </label>
                      </div>
                    </div>
                    -->

										<hr style="border-color: #717ace; border-width: 2px" />

										<div class="divider-lg">
											<div class="checkboxSel">
												<div class="form-check">
													<input class="form-check-input me-2" type="checkbox" [(ngModel)]="final1RxEnabled" />
													<label class="form-check-label" for="flexCheckDefault"> {{"REPORT.PRESCRIPTION" | translate}} [{{"REFRACTION.INFO_TITLE" | translate}}] </label>
												</div>
											</div>
											<!-- {{"REPORT.PRESCRIPTION" | translate}} [{{"REFRACTION.INFO_TITLE" | translate}}] -->
										</div>

										<!-- qui tabella di Final prescription, simile alla SBJ con tutti campi editabili,
                      pre-valorizzati con i valori sbj, se ci sono
                    -->
										<div class="tblWrapper">
											<prescription id="final1" [hidden]="!final1RxEnabled" [rxFinalR]="rxFinal1R" [rxFinalL]="rxFinal1L"> </prescription>

											<p>&nbsp;</p>

											<div class="checkboxSel" [hidden]="!final1RxEnabled">
												<div class="form-check">
													<input class="form-check-input me-2" type="checkbox" value="" [(ngModel)]="final2RxEnabled" />
													<label class="form-check-label" for="flexCheckDefault"> {{"REFRACTION.ENABLE_F2" | translate}} </label>
												</div>
											</div>

											<prescription id="final2" [hidden]="!(final2RxEnabled && final1RxEnabled)" [rxFinalR]="rxFinal2R" [rxFinalL]="rxFinal2L"> </prescription>
										</div>
									</div>

									<div class="full-container" (resetEvent)="changedDiagnosis($event, 'refraction')">
										<diagnosi class="full-container" id="diagnRefr" #diagnR [currDiagnosi]="diagnRefraction" [catName]="currCategName" [activeTab]="activeTab" (resetFields)="resetFormDiagnosis()"> </diagnosi>
										<!-- [parent]="categRef" -->
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="anterior" [disabled]="!isCatEnabled('anterior')" (tabChange)="loadCategory('anterior')">
					<span class="text-nowrap">
						<a ngbNavLink
							>{{"CATEGORIES.ANTERIOR_SHORT" | translate}}
							<fa-icon *ngIf="isAnteriorComplete" [icon]="faCheck" style="color: #64a8f7" aria-hidden="true"></fa-icon>
						</a>
					</span>
					<ng-template ngbNavContent>
						<div *ngIf="dataService.hasLoadedCategory('anterior')" class="tabData sb-style">
							<div class="row nomargin">
								<div class="fifth-container photo"><p>{{"MISC.RIGHT" | translate}}</p></div>
								<div class="fifth-container photo"></div>
								<div class="fifth-container photo"><p>{{"MISC.LEFT" | translate}}</p></div>
							</div>

							<div class="row nomargin">
								<div class="fifth-container photo">
									<album *ngIf="hasExternalImagesRight" class="full-container" id="rightE" [images]="getExternalImages('right')" [isWizard]="isWizard"> </album>
								</div>

								<div class="fifth-container photo">
									<label>{{"CATEGORIES.ANTERIOR_FULL" | translate}}</label>
								</div>

								<div class="fifth-container photo">
									<album *ngIf="hasExternalImagesLeft" class="full-container" id="leftE" [images]="getExternalImages('left')" [isWizard]="isWizard"> </album>
								</div>
							</div>

							<div class="row nomargin">
								<div class="fifth-container photo">
									<album *ngIf="hasDryEyeImageRight" class="full-container" id="rightA" [images]="getDryEyeImages('right')" [isWizard]="isWizard"> </album>
								</div>
								<div class="fifth-container photo">
									<div class="dataWrapper" *ngIf="hasMiddleInfo('anterior')">
										<label>{{"EXAM.DATE" | translate}}: {{dryEyeExamsDate}}</label>
										<table class="catInfo">
											<tr>
												<td ng-class="dryEyeR.first_breakup ? 'value' : 'novalue'">{{dryEyeR.first_breakup}}</td>
												<td>{{"DRYEYE.FIRST_BREAKUP" | translate}}</td>
												<td ng-class="dryEyeL.first_breakup ? 'value' : 'novalue'">{{dryEyeL.first_breakup}}</td>
											</tr>
											<tr>
												<td ng-class="dryEyeR.average_breakup ? 'value' : 'novalue'">{{dryEyeR.average_breakup}}</td>
												<td>{{"DRYEYE.AVERAGE_BREAKUP" | translate}}</td>
												<td ng-class="dryEyeL.average_breakup ? 'value' : 'novalue'">{{dryEyeL.average_breakup}}</td>
											</tr>
											<tr>
												<td ng-class="dryEyeR.percentage ? 'value' : 'novalue'">{{dryEyeR.percentage}}</td>
												<td>{{"DRYEYE.PERCENTAGE" | translate}}</td>
												<td ng-class="dryEyeL.percentage ? 'value' : 'novalue'">{{dryEyeL.percentage}}</td>
											</tr>
											<tr>
												<td ng-class="dryEyeR.tear_meniscus ? 'value' : 'novalue'">{{dryEyeR.getTear_meniscus()}}</td>
												<td>{{"DRYEYE.TEAR_MENISCUS" | translate}}</td>
												<td ng-class="dryEyeL.tear_meniscus ? 'value' : 'novalue'">{{dryEyeL.getTear_meniscus()}}</td>
											</tr>
										</table>
									</div>
								</div>
								<div class="fifth-container photo">
									<album *ngIf="hasDryEyeImageLeft" class="full-container" id="leftA" [images]="getDryEyeImages('left')" [isWizard]="isWizard"> </album>
								</div>
							</div>

							<div *ngIf="isWizard">
								<hr style="border-color: #717ace; border-width: 2px" />

								<div class="full-container" (resetEvent)="changedDiagnosis($event, 'anterior')">
									<diagnosi class="full-container" id="diagnAnt" [currDiagnosi]="diagnAnterior" [catName]="currCategName" [activeTab]="activeTab" (resetFields)="resetFormDiagnosis()"> </diagnosi>
								</div>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="cornea" [disabled]="!isCatEnabled('cornea')" (tabChange)="loadCategory('cornea')">
					<span class="text-nowrap">
						<a ngbNavLink
							>{{"CATEGORIES.CORNEA_SHORT" | translate}}
							<fa-icon *ngIf="isCorneaComplete" [icon]="faCheck" style="color: #64a8f7" aria-hidden="true"></fa-icon>
						</a>
					</span>
					<ng-template ngbNavContent>
						<div *ngIf="dataService.hasLoadedCategory('cornea')" class="tabData sb-style">
							<div class="row nomargin">
								<div class="fifth-container photo">
									{{"MISC.RIGHT" | translate}}
									<album *ngIf="hasImages('cornea','right')" class="full-container" id="rightC" [images]="getCorneaImages('right')" [isWizard]="isWizard"> </album>
								</div>

								<div class="fifth-container photo">
									<p>&nbsp;</p>
									<div class="dataWrapper" *ngIf="!hasMiddleInfo('cornea')">
										<label> {{"CATEGORIES.CORNEA_FULL" | translate}}</label>
										<!-- {{"MISC.NO_DATA" | translate}} No Topography data -->
									</div>
									<label> {{"EXAMS.TOPOGRAPHY" | translate}}</label>
									<div class="dataWrapper" *ngIf="hasMiddleInfo('cornea')">
										<label>{{"EXAM.DATE" | translate}}: {{topoExamsDate}}</label>
										<table class="catInfo" *ngIf="(topoR != null) && (topoL != null)">
											<tr>
												<td [ngClass]="topoR.KPI ? 'value' : 'novalue'">{{topoR.getKPI()}}</td>
												<td>{{"TOPO.KPI" | translate}}</td>
												<td [ngClass]="topoL.KPI ? 'value' : 'novalue'">{{topoL.getKPI()}}</td>
											</tr>
											<tr>
												<td [ngClass]="topoR.SI ? 'value' : 'novalue'">{{topoR.getSI()}}</td>
												<td>{{"TOPO.SI_FULL" | translate}}</td>
												<td [ngClass]="topoL.SI ? 'value' : 'novalue'">{{topoL.getSI()}}</td>
											</tr>
											<tr>
												<td [ngClass]="topoR.eccentricity ? 'value' : 'novalue'">{{topoR.getEccentr()}}</td>
												<td>{{"TOPO.ECCENTRICITY" | translate}}</td>
												<td [ngClass]="topoL.eccentricity ? 'value' : 'novalue'">{{topoL.getEccentr()}}</td>
											</tr>
											<!-- gli ultimi 4 dati sono su refraction    18.08.2020 -->
										</table>
										<br />&nbsp;<br />
										<label *ngIf="topoR!=null || topoL!=null" class="normalcase">* {{"TOPO.MAPS_NOTE" | translate}}</label>
									</div>
								</div>

								<div class="fifth-container photo">
									{{"MISC.LEFT" | translate}}
									<album *ngIf="hasImages('cornea','left')" class="full-container" [images]="getCorneaImages('left')" [isWizard]="isWizard" id="leftC"> </album>
								</div>
							</div>

							<div *ngIf="isWizard">
								<hr style="border-color: #717ace; border-width: 2px" />

								<div class="full-container" (resetEvent)="changedDiagnosis($event, 'cornea')">
									<diagnosi class="full-container" id="diagnCor" [currDiagnosi]="diagnCornea" [catName]="currCategName" [activeTab]="activeTab" (resetFields)="resetFormDiagnosis()"> </diagnosi>
								</div>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="glaucoma" [disabled]="!isCatEnabled('glaucoma')" (tabChange)="loadCategory('glaucoma')">
					<span class="text-nowrap"
						><a ngbNavLink>{{"CATEGORIES.GLC_SHORT" | translate}} <fa-icon *ngIf="isCatGlcComplete" [icon]="faCheck" style="color: #64a8f7" aria-hidden="true"></fa-icon> </a
					></span>
					<!-- [ngClass]="{'catComplete': isCatGlcComplete}"  class="catComplete" non funziona sulla fa-icon -->
					<ng-template ngbNavContent>
						<div *ngIf="dataService.hasLoadedCategory('glaucoma')" class="tabData sb-style">
							<div class="row nomargin">
								<div class="fifth-container photo"><p>{{"MISC.RIGHT" | translate}}</p></div>
								<div class="fifth-container photo"></div>
								<div class="fifth-container photo"><p>{{"MISC.LEFT" | translate}}</p></div>
							</div>

							<div class="row nomargin">
								<!-- 30.05.2022 NB: different between view and wizard -->
								<div class="fifth-container photo" *ngIf="!isWizard">
									<album *ngIf="hasPachyImage('right')" class="full-container" id="rightP" [images]="getPachyImages('right')" [isWizard]="isWizard"> </album>
								</div>
								<div class="fifth-container photo" *ngIf="isWizard">
									<album *ngIf="hasImages('glaucoma', 'right')" class="full-container" id="rightP" [images]="getGlcImages('right')" [isWizard]="isWizard"> </album>
								</div>

								<div class="fifth-container photo">
									<label>{{"CATEGORIES.GLC_FULL" | translate}}</label>

									<div class="dataWrapper" *ngIf="hasMiddleInfo('glaucoma')">
										<label>{{"EXAM.DATE" | translate}}: {{glcExamsDate}}</label>
										<table class="catInfo">
											<tr [hidden]="!pachyR.hasNumericData && !pachyL.hasNumericData">
												<td [ngClass]="pachyR.central ? 'value' : 'novalue'">{{pachyR.getCCT()}}</td>
												<td>{{"PACHY.CENTRAL" | translate}}</td>
												<td [ngClass]="pachyL.central ? 'value' : 'novalue'">{{pachyL.getCCT()}}</td>
											</tr>

											<!-- temporary patch -->
											<tr [hidden]="true">
												<td [ngClass]="'value'">{{pachyR.getACD()}}</td>
												<td>{{"PACHY.ACD" | translate}}</td>
												<td [ngClass]="'value'">{{pachyL.getACD()}}</td>
											</tr>

											<tr [hidden]="!pachyR.hasNumericData && !pachyL.hasNumericData">
												<td [ngClass]="'value'">{{pachyR.getNasalAngle()}}</td>
												<td>{{"PACHY.NASAL_ANGLE" | translate}}</td>
												<td [ngClass]="'value'">{{pachyL.getNasalAngle()}}</td>
											</tr>
											<tr [hidden]="!pachyR.hasNumericData && !pachyL.hasNumericData">
												<td [ngClass]="'value'">{{pachyR.getTemporalAngle()}}</td>
												<td>{{"PACHY.TEMPORAL_ANGLE" | translate}}</td>
												<td [ngClass]="'value'">{{pachyL.getTemporalAngle()}}</td>
											</tr>

											<tr [hidden]="!(tonoR.hasNumericData || tonoL.hasNumericData)">
												<td [ngClass]="'value'">{{tonoR.getIOP()}}</td>
												<td>{{"TONO.IOP" | translate}}</td>
												<td [ngClass]="'value'">{{tonoL.getIOP()}}</td>
											</tr>
											<tr [hidden]="!(tonoR.hasNumericData || tonoL.hasNumericData)">
												<td [ngClass]="'value'">{{tonoR.getIOPc()}}</td>
												<td class="normalcase">{{"TONO.IOPc" | translate}} *</td>
												<td [ngClass]="'value'">{{tonoL.getIOPc()}}</td>
											</tr>
										</table>

										<br />&nbsp;<br />
										<label class="normalcase" [hidden]="(!tonoR.hasNumericData && !tonoL.hasNumericData)"> {{ iopc_note }}</label>
									</div>
								</div>

								<div class="fifth-container photo" *ngIf="!isWizard">
									<album *ngIf="hasPachyImage('left')" class="full-container" id="leftP" [images]="getPachyImages('left')" [isWizard]="isWizard"> </album>
								</div>
								<div class="fifth-container photo" *ngIf="isWizard">
									<album *ngIf="hasImages('glaucoma','left')" class="full-container" id="leftP" [images]="getGlcImages('left')" [isWizard]="isWizard"> </album>
								</div>
							</div>

							<div class="row nomargin" *ngIf="!isWizard && (wfR != null || wfL != null)">
								<div class="fifth-container photo">
									<!-- hasImages('wf','right') or hasWfImageRight -->
									<album [hidden]="!hasWfImageRight" class="full-container" id="rightW" [images]="getWfImages('right', 'grid')" [isWizard]="isWizard"></album>
								</div>
								<div class="fifth-container photo"></div>
								<div class="fifth-container photo">
									<album [hidden]="!hasWfImageLeft" class="full-container" id="leftW" [images]="getWfImages('left', 'grid')" [isWizard]="isWizard"></album>
								</div>
							</div>

							<div class="row nomargin" *ngIf="!isWizard && (retroR != null || retroL != null)">
								<div class="fifth-container photo">
									<album *ngIf="hasRetroImageRight" class="full-container" id="rightR" [images]="getRetroImages('right')" [isWizard]="isWizard"></album>
								</div>
								<div class="fifth-container photo">&nbsp;</div>
								<div class="fifth-container photo">
									<album *ngIf="hasRetroImageLeft" class="full-container" id="leftR" [images]="getRetroImages('left')" [isWizard]="isWizard"></album>
								</div>
							</div>

							<div *ngIf="isWizard">
								<hr style="border-color: #717ace; border-width: 2px" />

								<div class="full-container" (resetEvent)="changedDiagnosis($event, 'glaucoma')">
									<diagnosi class="full-container" id="diagnGlc" [currDiagnosi]="diagnGlaucoma" [catName]="currCategName" [activeTab]="activeTab" (resetFields)="resetFormDiagnosis()"> </diagnosi>
								</div>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="fundus" [disabled]="!isCatEnabled('fundus')" (tabChange)="loadCategory('fundus')">
					<span class="text-nowrap"
						><a ngbNavLink>{{"CATEGORIES.FUNDUS_SHORT" | translate}} <fa-icon *ngIf="isFundusComplete" [icon]="faCheck" style="color: #64a8f7" aria-hidden="true"></fa-icon> </a
					></span>
					<ng-template ngbNavContent>
						<div *ngIf="dataService.hasLoadedCategory('fundus')" class="tabData sb-style">
							<div class="row nomargin">
								<div class="fifth-container photo">
									{{"MISC.RIGHT" | translate}}
									<album class="full-container" id="rightF" *ngIf="hasImages('fundus','right')" [images]="getFundusImages('right')" [isWizard]="isWizard"> </album>
								</div>

								<div class="fifth-container photo">
									<label>{{"CATEGORIES.FUNDUS_FULL" | translate}} </label>
									<div class="dataWrapper" *ngIf="hasMiddleInfo('fundus')">
										<label>{{"EXAM.DATE" | translate}}: {{fundusExamsDate}}</label>
										<table class="catInfo">
											<!-- *ngIf="(fundusR != null) && (fundusL != null)" -->
											<tr>
												<td [ngClass]="fundusR.CDR ? 'value' : 'novalue'">{{fundusR.getCDR('')}}</td>
												<td>{{"FUNDUS.CDR" | translate}}</td>
												<td [ngClass]="fundusL.CDR ? 'value' : 'novalue'">{{fundusL.getCDR('')}}</td>
											</tr>
											<!-- 											
                        <tr *ngIf="(fundusR.vCDR != null || fundusL.vCDR != null)">
												<td [ngClass]="fundusR.vCDR ? 'value' : 'novalue'">{{fundusR.getVertCDR('')}}</td>
												<td>{{"FUNDUS.vCDR" | translate}}</td>
												<td [ngClass]="fundusL.vCDR ? 'value' : 'novalue'">{{fundusL.getVertCDR('')}}</td>
											</tr> 
                      -->
										</table>
										<br />
										<label *ngIf="!session.isSpecialist()">Device: {{fundusL.id>0 ? fundusL.device : fundusR.device}} SN: {{fundusL.id>0 ? fundusL.device_sn : fundusR.device_sn}}</label>
									</div>
								</div>

								<div class="fifth-container photo">
									{{"MISC.LEFT" | translate}}
									<album class="full-container" id="leftF" *ngIf="hasImages('fundus','left')" [images]="getFundusImages('left')" [isWizard]="isWizard"> </album>
								</div>
							</div>

							<div class="row nomargin" *ngIf="hasAdditions('any')">
								<div class="fifth-container photo">
									&nbsp;<!-- {{"MISC.RIGHT" | translate}} -->
									<album class="full-container" id="rightAddsF" *ngIf="getTotAdditions('right')>0" [images]="getAdditionImages('right')" [isWizard]="isWizard"> </album>
								</div>
								<div class="fifth-container photo">
									<label>ADDITIONAL FILTERS</label>
								</div>
								<div class="fifth-container photo">
									&nbsp;
									<!-- {{"MISC.LEFT" | translate}} -->
									<album class="full-container" id="leftAddsL" *ngIf="getTotAdditions('left')>0" [images]="getAdditionImages('left')" [isWizard]="isWizard"> </album>
								</div>
							</div>

							<div *ngIf="isWizard">
								<hr style="border-color: #717ace; border-width: 2px" />

								<div class="full-container" (resetEvent)="changedDiagnosis($event, 'fundus')">
									<diagnosi class="full-container" id="diagnFund" [currDiagnosi]="diagnFundus" [catName]="currCategName" [activeTab]="activeTab" (resetFields)="resetFormDiagnosis()"> </diagnosi>
								</div>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="END" [disabled]="!allCatDone" *ngIf="isWizard">
					<span class="text-nowrap"><a ngbNavLink>{{"DIAGNOSI.FINAL_TAB" | translate}}</a></span>
					<ng-template ngbNavContent>
						<div class="categories-container__confirmall ps-5">
							<div *ngIf="allCatDone" class="row">
								<p>{{"DIAGNOSI.FINAL_DIAGN_MSG" | translate}} <b>{{"DIAGNOSI.FINAL_DIAGN_MSG_SEC" | translate}}</b></p>
							</div>

							<div *ngIf="!allCatDone" class="row">
								<p>{{"DIAGNOSI.FINAL_DIAGN_ERR" | translate}}</p>
							</div>
							<div class="form-group">
								<p class="form-label">{{ 'FOLLOW_UP_MON.TITLE' | translate }} *</p>

								<form [formGroup]="followUpForm">
									<div class="d-flex">
										<div class="follow-up-form" *ngFor="let fUp of followUpArray">
											<input type="radio" [value]="fUp" formControlName="followUpMonth" (change)="followUpSelected()" />
											<span class="ms-2 me-3 fw-bold">{{ fUp.description }}</span>
										</div>
									</div>

									<div class="mt-3">
										<p>{{'FOLLOW_UP_MON.FOLLOWUP_NOTES'| translate}}</p>
										<textarea name="followUpNote" cols="70" rows="4" formControlName="followUpNote"></textarea>
									</div>
								</form>
							</div>
						</div>
					</ng-template>
				</li>
			</ul>
		</div>

		<div class="col-10 categories-container__content" [ngClass]="{end_tab : activeTab == 'END' }">
			<div class="panel-body position-relative" style="padding-left: 20px; padding-right: 20px">
				<div class="btn-close-container-pos">
					<button type="button" class="btn-close" aria-label="Close" (click)="dismissModal()"></button>
				</div>

				<div>
					<div class="panel-body__title">
						<label class="panel-title">{{getPanelTitle()}}</label>
						<!--<i class="glyphicon glyphicon-remove closeMedicalModal" ng-click="dismissModal()"></i>-->
					</div>

					<div *ngIf="(dataService.hasLoadedPatient())" class="panel-patient_info">
						<patient [currPatient]="currentPatient"></patient>
					</div>
				</div>

				<div class="row">
					<div class="col-12">
						<!-- class="full-container tabs tabs-left" ng-init="indextab = getFirstActiveCategory()" -->
						<!-- where currently active nav content will be displayed: -->
						<div [ngbNavOutlet]="nav" class="mt-3"></div>
						<!-- class="mt-2" class="ml-4" -->
					</div>
				</div>

				<!-- <pre>Active: {{activeTab}}</pre> -->

				<div class="container">
					<!-- bottomRow -->

					<div *ngIf="!isWizard" class="bottom-container">
						<div>
							<button class="btn btn-primary" (click)="passBack('pdf')" *ngIf="activeTab!='anamnesis'">{{"BUTTONS.PRINT_PDF" | translate}}</button>
							<!-- ng-click="generatePdf()" <span class="glyphicon glyphicon-print"></span>
                TODO usare la fa-icon print
              -->
						</div>

						<div>
							<button class="btn btn-secondary" (click)="dismissModal()">{{'BUTTONS.CLOSE' | translate}}</button>
						</div>
					</div>

					<div *ngIf="isWizard" class="bottom-container">
						<div>
							<!-- <button [hidden]="(activeTab == 'END')" class="btn" (click)="resetFormDiagnosis()">{{'BUTTONS.CLEAR' | translate}}</button> -->
						</div>

						<!-- <div>
							<button class="btn" (click)="saveDiagnosis()" [disabled]="disableSend">
								{{btnSaveDiagnosis}}<fa-icon
									class="ms-2"
									*ngIf="btnSaveDiagnosis===translator.instant('BUTTONS.GENERATE_REPORT')"
									[icon]="faArrowUpFromBracket"></fa-icon>
							</button>
						</div> -->
						<div *ngIf="isWizard && btnSaveDiagnosis != translator.instant('BUTTONS.GENERATE_REPORT')" class="bottom-container">
							<div></div>
							<button class="btn btn-primary" (click)="saveDiagnosis()" [disabled]="disableSend">{{btnSaveDiagnosis}}</button>
						</div>
						<div *ngIf="isWizard && btnSaveDiagnosis === translator.instant('BUTTONS.GENERATE_REPORT') && !disableSend">
							<button class="btn btn-primary" (click)="saveDiagnosis()" [disabled]="followUpForm.invalid">{{btnSaveDiagnosis}}<fa-icon class="ms-2" [icon]="faArrowUpFromBracket"></fa-icon></button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit, Input, Output } from '@angular/core'

import { NgbModal, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule

import { SessionService } from '../../service/session.service'
//import { DoctorListComponent } from '../doctors/doctorList.component';

//import { Doctor } from 'src/app/models/doctor.model';
import { Relation } from 'src/app/models/specialist.model'
//import { Address } from 'src/app/models/address.model';
import { Util } from '../../models/util.model'

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'relationInfo',
	templateUrl: './relationInfo.modal.html',
	styleUrls: ['./relationInfo.modal.scss'],
	providers,
})
export class RelationInfoModal implements OnInit {
	@Input() currentRel: Relation
	myDiagnGroup: any

	public yesNoOptions = ['Y', 'N']

	// usata dalla doctorList by specialist per vedere i propri dati (firma, etc)

	constructor(public activeModal: NgbActiveModal, public session: SessionService) {
		//console.log("relationModal - constructor - valid ? "+(this.currentRel != null)); //qui false
	}

	ngOnInit(): void {
		console.log('relationModal - ngOnInit')
		this.myDiagnGroup = 0 // this.currentDoctor.getSzDiagnosisGrp();  // 25.01.2022
		//faccio bk dei campi per gestire eventuale delete per cfr [ls]
		//this.currOrderN = this.currentDoctor.order_reg_num;
		//console.log("relationModal - ngOnInit - valid ? "+(this.currentRel != null));  // qui true
	}
}

import { Component, HostListener, OnDestroy, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'

import { SessionService } from './service/session.service'
import { Config } from '../config'
import { AboutModal } from './component/about/about.modal'
import { QrCodeService } from './service/qrCode.service'
import { SignalR } from './service/signalR.service'
import { DataModelService } from './service/data-model.service'
import { Subscription } from 'rxjs'
import { LoaderStatus } from './elements/loader-status/loader-status.component'
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'Visionix platform'
	currYear = new Date().getFullYear()
	loaderStatusSubscription: Subscription

	activateLoader: LoaderStatus
	constructor(
		public session: SessionService,
		public modalService: NgbModal,
		private QrCodeService: QrCodeService,
		private SignalRService: SignalR,
		private dataModelService: DataModelService
	) {
		// patch in caso di errori ?!
		this.currYear < new Date().getFullYear() ? (this.currYear = new Date().getFullYear()) : ''
		this.activateLoader = {
			activateLoader: false,
			max: 100,
			current: 0,
			label: '',
		}
	}

	ngOnInit(): void {
		// console.log('AppComponent ngOnInit')
		let units: number = 0
		this.loaderStatusSubscription = this.dataModelService.loaderStatusChanges.subscribe((loaderStatus: LoaderStatus) => {
			// console.log(loaderStatus)
			units = 100 / loaderStatus.max
			this.activateLoader.current = Number((loaderStatus.current * units).toFixed(0))
			this.activateLoader.max = loaderStatus.max
			this.activateLoader.activateLoader = loaderStatus.activateLoader
			this.activateLoader.label = loaderStatus.label
		})
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeUnloadHander() {
		if (this.session.isLogged() && this.session.getUserBrand() != Config.BR_RDS) {
			return false
		}
	}

	openAbout() {
		this.modalService.open(AboutModal, { size: 'lg' }) // lg  md
	}
}

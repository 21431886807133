import { Component, OnInit, Input, Output } from '@angular/core'

import { NgbModal, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { DoctorListComponent } from './doctorList.component'

import { Doctor } from 'src/app/models/doctor.model'
import { Address } from 'src/app/models/address.model'
import { Util } from '../../models/util.model'
import { Country } from '../../models/countries.models'

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'doctor',
	templateUrl: './doctor.modal.html',
	styleUrls: ['./doctor.modal.scss'],
	providers,
})
export class DoctorModal implements OnInit {
	@Input() currentDoctor: Doctor
	@Input() currentAction: string
	@Input() doctorsFilterEdit
	myDiagnGroup: any
	alpha2Country: string

	// dispPassword: string // dispositive admin pwd field, for edit, delete, etc
	// wrongPwd: boolean
	//info_logo: string = "already loaded";

	// 26.06.2018
	new_nexy_sn = '' // per force valore da remoto
	force_nexy = 'N' // per il radio di scelta

	//created_by;

	//reportTypes; // = ['None', 'StandAlone']; nel costruttore

	//usrSort = ""; // 22.10.2019
	//currDicomSrvList: DicomServer[];  // 25.10.2019
	currOrderN: string // per gestire la delete   24.02.2021

	// 14.11.2017 solo per NS, metto a true per velocizzare la lista
	ignoreCritt = false
	countries: Country[] // 30.08.2022

	// 09.08.2022 spostata su session
	//public yesNoOptions = ['Y', 'N'];

	constructor(public activeModal: NgbActiveModal, public session: SessionService) {
		console.log('doctorModal - constructor')
		this.countries = []
		this.alpha2Country = ''
	}

	ngOnInit(): void {
		console.log('doctorModal - ngOnInit')
		this.myDiagnGroup = this.currentDoctor.getSzDiagnosisGrp() // 25.01.2022
		//faccio bk dei campi per gestire eventuale delete per cfr [ls]
		this.currOrderN = this.currentDoctor.order_reg_num

		// 12.04.2023 anche support
		// 20.09.2022 solo se admin, non per superB
		//if (!this.session.isSuperB()) {
		if (this.session.isAdmin() || this.session.isSupport()) {
			// 30.08.2022 richiedo solo una volta per sessione
			this.countries = this.session.getDtCountries()

			this.alpha2Country = this.getAlpha2Country().toUpperCase()

			// console.log(this.countries)

			// if (this.countries && this.countries.length > 0) {
			// 	Util.debug('(doctorModal) already loaded tot countries: ' + this.countries.length)
			// } else {
			// 	Util.debug('(doctorModal) going to request countries...')
			// 	this.session
			// 		.getCountries()
			// 		.then((list) => {
			// 			this.countries = list
			// 			Util.debug('(doctorModal) tot countries: ' + this.countries.length)

			// 		})
			// 		.catch((error) => {
			// 			Util.debug('(doctorModal) ERR on countries!')
			// 			console.log(error)
			// 		})
			// }

			//Util.debug('(doctorModal) test 2 - country ? '+this.currentDoctor.getCountry()); // 19.10.2022
		}
	}

	validateLogo(): boolean {
		var nomeFile = (<HTMLInputElement>document.getElementById('logo')).files[0]

		if (nomeFile != null) {
			Util.debug('(validateLogo) file immagine scelto: ' + nomeFile.name)

			// ammessi solo .png per avere bg trasparente
			var isValidImg = false
			var usrFileName = nomeFile.name.toLowerCase()
			if (usrFileName.indexOf('.png') > 0) {
				isValidImg = true
			}

			if (!isValidImg) {
				// TODO, tradurre
				//var msg = "update doctor: ko image format, file: "+nomeFile.name+". Only *.png or *.jpg admitted";
				var msg = 'update doctor: ko image format, file: ' + nomeFile.name + '. Only *.png  admitted'
				console.log(msg)
				alert(msg)
				return false
			} else {
				return true
			}
		} else return true
	}

	// 27.01.2022 spostato qui da doctorlist
	private editDoctor(): Promise<any> {
		// 06.09.2022 edit country solo in alcune condizioni
		let addrCountry = this.currentDoctor.mainAddress.country

		console.log('(editDoct) clear country: ' + this.currentDoctor.country + ', new addr. country: ' + addrCountry)

		if (this.currentDoctor.country != addrCountry) {
			if (addrCountry.length == 3 && this.currentDoctor.country == '') {
				// viene da scelta tendina, anche precedente
				// in chiaro, andra' su tabella users
				this.currentDoctor.country = addrCountry
			} else {
				// 15.09.2022 patch per pregresso
				if (addrCountry != '' && addrCountry != '-' && this.currentDoctor.country == '') {
					this.currentDoctor.country = Country.getCodeFromDescr(addrCountry)
					Util.debug('(editDoct) id: ' + this.currentDoctor.id + ' overwrite clear from addr, ' + this.currentDoctor.country)
				} //else {
				//console.log("(editDoct) bad country value!");
				//}
			}
		}

		let nomeFile = (<HTMLInputElement>document.getElementById('logo')).files[0]
		// validazione fatta dalla chiamante

		if (nomeFile != null) {
			return Util.readFileAsDataUrl(nomeFile).then((logoImage) => {
				console.log('(UpdateDoctor) [okLogo] sending to the server...')
				return this.session.updateDoctor(this.currentDoctor, logoImage, null, nomeFile.name)
			})
		} else {
			// no immagine con logo

			// gestione delete logo esistente
			var delLogo = ''
			var myCkBox = <HTMLInputElement>document.getElementById('delete_logo')
			if (myCkBox != null && myCkBox.checked == true) {
				console.log('(UpdateDoctor) DELETE old logo')
				delLogo = Config.DELETE_ME
			}

			console.log('(UpdateDoctor) [noLogo] sending to the server...') // 07.10.2021 solo per test
			// updateDoctor(doctorDraft, logoImage, deleteExistingLogo ?, logoName?)
			return this.session.updateDoctor(this.currentDoctor, null, delLogo, null)
		}
	}

	//passBack() {
	editCurrentDoct() {
		//console.log("doctorModal - passBack");

		// 24.02.2021 anticipato test qui
		// if (!this.session.checkNSPwd(this.dispPassword) && !this.session.isSuperB()) {
		// 	console.log('Doctor - pwd dispositiva non corretta')
		// 	this.wrongPwd = true
		// 	return false
		// }
		// this.wrongPwd = false

		if (!this.validateLogo()) {
			return false
		}

		// gestione delete reg_num
		if (this.currOrderN != '' && this.currentDoctor.order_reg_num == '') {
			// e' stato cancellato
			console.log('(UpdateDoctor) DELETE old order_reg_num, ' + this.currOrderN)
			this.currentDoctor.order_reg_num = Config.DELETE_ME
		}

		console.log('doctorModal - passBack - edit doct')

		this.editDoctor()
			.then(() => {
				console.log('(UpdateDoctor) done... ')
				alert('OK, updated!')
				let flagDone = true // 15.09.2022
				return this.activeModal.close(flagDone)
				//return true;
			})
			.catch((err) => {
				console.log(err)
				var msg = '' // (err.data)? err.data.error : err.toString();
				console.log('(updtDoctor) KO 2 - ' + msg)
				alert('Error: ' + msg)
				return false
			})
	}

	// 19.10.2021
	canEditDiagnGroup() {
		var ret = false
		if (this.currentDoctor.isOptician()) {
			ret = this.currentDoctor.getSpecialistNum() == 0
		} else if (this.currentDoctor.isMini()) {
			ret = false // eredita dal suo super
		} else {
			ret = true // ok per B e superB
		}
		return ret
	}

	// 16.09.2021 subtypes disponibili x cambio, su doct corrente
	getCurrentSubtypes() {
		if (this.currentDoctor.isOptician()) {
			//console.log("(getCurrentSubtypes) for opticians: "+this.usrSubtypesOpts);
			return this.session.usrSubtypesOpts
		} else {
			// e' un doctor
			//console.log("(getCurrentSubtypes) for doctors: "+this.usrSubtypesNoMini);
			return this.session.usrSubtypesNoMini
		}
	}

	// 30.08.2022
	isCountryReadonly() {
		let ret = true
		let currCountry = ''

		// 19.10.2022 problema: se country gia' impostata, non si riesce a settare la searchdropdown
		//if (!this.currentDoctor.isActive) {
		//	ret = false; // non ancora attivato, si puo' cambiare
		//} else {
		currCountry = this.currentDoctor.getCountry()
		//}

		// 06.09.2022 estesi i casi
		if (currCountry == null || currCountry == '-' || currCountry == '') {
			ret = false // si puo' cambiare
		}

		return ret
	}

	// Aggiunta per recuperare la country selezionata
	selCountry(selCountry: { countryIso2: string; countryIso3: string }) {
		console.log('(editDoct) select country: ' + selCountry.countryIso3 + ' model: ' + this.currentDoctor.mainAddress.country)
		this.currentDoctor.country = selCountry.countryIso3
	}

	// 06.09.2022
	getReadOnlyCountry() {
		let myC = this.currentDoctor.getCountry()

		// 14.04.2023 spostata su session
		let ret = this.session.getCountryFullName(myC)
		/*
		let c = this.session.getSingleCountry(myC)
		if (c) {
			ret = c.name + '  (' + ret + ')'
		}
    */
		return ret
	}

	getAlpha2Country() {
		return this.session.getSingleCountry(this.currentDoctor.getCountry()).alpha2
	}
}

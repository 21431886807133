import { Component, OnInit, Input } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms'

import { SessionService } from '../../service/session.service'
import { Patient } from '../../models/patient.model'
import { Util } from '../../models/util.model'
import {
	faCaretDown,
	faCaretUp,
	faCircleCheck,
	faCircleXmark,
	faDatabase,
	faPersonWalkingArrowRight,
	faTriangleExclamation,
	faUserCheck,
} from '@fortawesome/free-solid-svg-icons'

var providers = [NgbModal, FormsModule]

@Component({
	selector: 'pendingPatientConflict-modal',
	templateUrl: './pendingPatientConflict.modal.html',
	styleUrls: ['./pendingPatientConflict.modal.scss'],
	providers,
})
export class PendingPatientConflictModalContent implements OnInit {
	@Input() public pendingPatient: Patient
	@Input() public conflictingCurrentPatients: Patient[]
	@Input() public possibleConflictingFullNames: string[]
	@Input() public exactMatchConflictPatient?: Patient
	selectedPatientIndex: number
	selectedExistingPatient: Patient
	selectedPatientData: Patient
	step: number
	exactMatchNewLastName: string
	conflictingPatientDetailSwitches: boolean[]

	faTriangleExclamation = faTriangleExclamation
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark
	faUserCheck = faUserCheck
	faCaretDown = faCaretDown
	faCaretUp = faCaretUp
	faPersonWalkingArrowRight = faPersonWalkingArrowRight
	faDatabase = faDatabase

	constructor(public activeModal: NgbActiveModal, public session: SessionService, public modalService: NgbModal) {}

	ngOnInit(): void {
		Util.debug('(ngOninit - pendingPatientConflictModal)')

		this.selectedPatientIndex = -1
		this.selectedExistingPatient = null
		this.selectedPatientData = null
		this.step = 1
		this.exactMatchNewLastName = this.pendingPatient.lastName
		this.conflictingPatientDetailSwitches = new Array(this.conflictingCurrentPatients.length).fill(false)
	}

	handleStep(step: number) {
		this.step = step
	}

	exactMatchHandleSamePatient() {
		// TODO: handle save anamnesis/agreement of same patient
		// this.session.updateExistingPatient(this.pendingPatient, this.exactMatchConflictPatient.id, this.pendingPatient.pending_id)
		this.activeModal.close({ event: 'update', patient: this.pendingPatient, id: this.exactMatchConflictPatient.id, pendingId: this.pendingPatient.pending_id })
	}

	onSaveNewPatient() {
		this.pendingPatient.lastName = this.exactMatchNewLastName ? this.exactMatchNewLastName : this.pendingPatient.lastName
		// this.session.saveNewPendingPatient(this.pendingPatient)
		this.activeModal.close({ event: 'new', patient: this.pendingPatient, id: 0, pendingId: 0 })
	}

	onUpdateExistingPatientData() {
		// this.session.updateExistingPatient(this.selectedPatientData, this.selectedExistingPatient.id, this.pendingPatient.pending_id)
		this.activeModal.close({ event: 'update', patient: this.selectedPatientData, id: this.selectedExistingPatient.id, pendingId: this.pendingPatient.pending_id })
	}

	onUpdatePatient() {
		this.selectedExistingPatient = this.conflictingCurrentPatients[this.selectedPatientIndex]
		this.handleStep(2)
	}

	onSelectPatientData(patient: Patient) {
		this.selectedPatientData = patient
		this.handleStep(3)
	}

	handlePatientDetailSwitch(i: number) {
		this.conflictingPatientDetailSwitches[i] = !this.conflictingPatientDetailSwitches[i]
	}

	formatDate(date: string) {
		return this.session.formatDate(new Date(date))
	}
}

<div class="balance-container">
	<div class="row" *ngIf="viewReady">
		<div class="col-4">
			<div class="balance_header">
				<div class="balance__profile-name"><fa-icon [icon]="faCircleUser"></fa-icon><span>{{user.username}}</span></div>
			</div>
			<!-- [ngClass]="{attention : saleInfo.flagCredits=='red', warning : saleInfo.flagCredits=='yellow'}" -->
			<div class="row">
				<div class="balance_box">
					<div class="col-12">
						<p>
							<span class="fw-bold" style="color: #212529">{{"CREDITS.ACTIVATION" | translate}}</span>
							<span class="ps-3">{{session.formatDate(user.subscriptionTime)}}</span>
						</p>
					</div>

					<div class="col-12">
						<p>
							<span class="fw-bold">{{"CREDITS.USED_SPACE" | translate}}</span>
							<span class="used-space ps-3">{{usedSpace}}</span>
						</p>
					</div>

					<div class="col-12">
						<p>
							<span class="fw-bold" style="color: #212529">{{"CREDITS.AVAILABLE_CREDITS" | translate}}:</span>
							<span class="available-credits ps-3" [ngClass]="{attention : saleInfo.flagCredits=='red', warning : saleInfo.flagCredits=='yellow'}">{{credits}}</span>
						</p>
					</div>

					<div class="col-12" style="color: #212529">
						<span class="fw-bold" *ngIf="currentProfile == 'is_doc'">{{"CREDITS.SPECIALISTS" | translate}}</span>
						<span class="fw-bold" *ngIf="currentProfile == 'is_user'">{{"CREDITS.REF_DOC" | translate}}</span>
						<div *ngIf="specialistList.length != 0">
							<div *ngFor="let spec of specialistList" class="mt-2">
								<div class="logo" *ngIf="spec.getSubType() == 'VX'; else privateType "></div>
								<ng-template #privateType>
									<span style="font-weight: bold; font-size: 10px">[ PR ] </span>
								</ng-template>
								<span *ngIf="spec.display_name; else assigned">{{spec.display_name}}</span>
								<ng-template #assigned>
									<span class="assigned-grader" ngbTooltip="{{ 'CREDITS.ASSIGNED_GRADER_HINT' | translate }}">{{ 'CREDITS.ASSIGNED_GRADER' | translate }}</span>
								</ng-template>
								<span *ngIf="currentProfile == 'is_doc'">- id: {{spec.distributor_id}}</span>
							</div>
						</div>
						<p *ngIf="specialistList.length == 0 ">{{"CREDITS.NO_SPECIALISTS" | translate}}</p>
						<p class="request-grader-link mt-3" *ngIf="canReqNewGrader" (click)="onRequestNewGraderClick()">{{"CREDITS.NEW_GRADER_REQUEST_BUTTON" | translate}}</p>
					</div>
				</div>
			</div>

			@if(session.isAdmin()){
			<div class="row mt-3">
				<div class="col-12">
					<p>Only for tests</p>
					<p><span>salePlan level: </span><span class="fw-bold">{{saleInfo.salePlan.level}}</span> <span class="ms-2"> salePlan name:</span><span class="fw-bold"> {{saleInfo.salePlan.name}}</span></p>
				</div>
			</div>
			}
		</div>

		<div class="col-1 position-relative">
			<div class="v-spacer"></div>
		</div>

		<div class="col-6">
			<div class="row">
				<!-- Account services -->

				<div class="col-12">
					<div class="balance_header fw-bold">
						<p class="title border-bottom">{{"CREDITS.ACCOUNT_SERVICES" | translate}}</p>
					</div>
					@if (accountServices.length > 0) {
					<div>
						@for (service of accountServices; track service) {
						<div class="balance_box service_box">
							<div class="row">
								<div class="col-10">
									<p class="fw-bold">{{service.service}} {{"CREDITS.SERVICE" | translate}}</p>
									<div><span class="fw-bold"> {{"CREDITS.TYPE" | translate}}: </span><span>{{service.service_mode}}</span> <span class="ms-3 fw-bold">{{"CREDITS.EXPIRY_DATE" | translate}} </span><span>{{session.formatDate(service.end_date)}}</span></div>
								</div>
								<div class="col-2 service_box_status d-flex">
									<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{'CREDITS.TOOLTIPS.SERVICE_GREEN' | translate}}" *ngIf="service.status == 'active'"></fa-icon>
									<!-- ACCOUNT FROZEN CON CREDITI -->
									<fa-icon [icon]="faCircleXmark" class="p-3 yellow" faCircleXmark ngbTooltip="{{'CREDITS.TOOLTIPS.HG_SERVICE_YELLOW' | translate}}" *ngIf="service.status == 'purchased'"></fa-icon>
									<!-- GRACE PERIOD -->
									<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark ngbTooltip="{{'CREDITS.TOOLTIPS.SERVICE_RED' | translate}}" *ngIf="service.status == 'expired'"></fa-icon>
								</div>
							</div>
						</div>
						}
					</div>
					}@else {
					<p>{{"CREDITS.NO_ACCOUNT_SERVICES" | translate}}</p>
					}
				</div>

				<!-- purchased package services -->

				@if(purchasePackService.length > 0){
				<div class="col-12 mt-4 purchased-package-services">
					<div class="balance_header fw-bold">
						<p class="title border-bottom">
							{{"CREDITS.PURCHASED_SERVICES" | translate}} <span class="ms-3"><fa-icon class="ms-3 warning-icon" [icon]="faCircleExclamation" faCircleExclamation></fa-icon> </span>
						</p>
					</div>

					@for (pack of purchasePackService; track pack) {

					<div class="balance_box package_box position-relative mb-2" style="min-height: 50px">
						<loader [activateSpinner]="pack.loading "></loader>

						<!-- <div class="loader-container">
							<div *ngIf="pack.loading" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->

						<div class="row" *ngIf="!pack.loading">
							<div class="col-8">
								<p><span class="fw-bold">{{pack.package_name}}</span> <span class="ms-3 fw-bold">{{"CREDITS.BUY_DATE" | translate}} </span><span>{{session.formatDate(pack.buy_date)}}</span></p>

								<p>{{pack.description}}</p>

								@if(currentProfile === 'is_doc'){ @for(service of pack.services; track service) {
								<p>{{"CREDITS.PACK_SERVICES" | translate}}</p>

								<div class="package_box__service">
									<span class="fw-bold">{{"CREDITS.PACK_SERVICE_TYPE" | translate}}</span><span>{{service.service}} </span> <span class="ms-3 fw-bold"> {{"CREDITS.TYPE" | translate}}: </span><span>{{service.service_mode}}</span>
									@if (service.additions) {
									<span class="ms-3 fw-bold"> {{"CREDITS.ADDITIONS" | translate}}: </span><span>{{service.additions}}</span>
									}
								</div>
								} }
							</div>
							@if(currentProfile === 'is_user'){
							<div class="d-flex align-items-center col-4" [ngClass]="pack.assignEnabled ? 'justify-content-center': 'justify-content-end' ">
								@if(pack.availableDevices.length > 0){ @if(pack.assignEnabled ){
								<div class="d-flex flex-column">
									<p>{{"CREDITS.PACK_ASSIGN_TO" | translate}}</p>

									<div class="d-flex">
										<select class="form-select form-select-sm" [(ngModel)]="deviceToUpdate" (change)="assignService(pack)" required>
											<option [ngValue]="null" selected></option>
											@for(device of pack.availableDevices; track device) {
											<option [ngValue]="device">{{device.model}} - {{ device.sn}}</option>
											}
										</select>

										<fa-icon class="ms-3" [icon]="faXmark" faXmark (click)="pack.assignEnabled = !pack.assignEnabled; deviceToUpdate = null "></fa-icon>
									</div>

									<button class="btn btn-primary mt-2" [disabled]="deviceToUpdate == null " (click)="checkForAvailableAgreement(pack)">{{"CREDITS.PACK_BUTTON" | translate}}</button>
								</div>
								} @else {
								<fa-icon [icon]="faCirclePlus" faCirclePlus (click)="pack.assignEnabled = !pack.assignEnabled" ngbTooltip="{{'CREDITS.PACK_ASSIGN_TO' | translate}}"></fa-icon>
								} } @else {
								<span>{{"CREDITS.NOT_ASSIGNABLE" | translate}}</span>
								}
							</div>

							}
						</div>
					</div>

					}
				</div>
				}

				<!-- go to ecommerce -->

				@if(currentProfile === 'is_user' && session.isEcommerceEnabled()){

				<div class="col-12 mt-4 ecommerce-section">
					<div class="balance_header fw-bold">
						<p class="title border-bottom" (click)="showEcommerce = !showEcommerce">
							<label>{{'CREDITS.ECOMMERCE' | translate}}</label>
							<fa-icon [icon]="faSortDown" [ngClass]="showEcommerce ? 'show' : '' " aria-hidden="true" class="float-end"></fa-icon>
						</p>
					</div>
					<div class="ecommerce-section__body row" [ngClass]="showEcommerce ? 'show' : '' ">
						<div class="col-12 ecommerce-section__content">
							<div class="position-relative">
								<h3 class="fw-bold">{{'SUBSCRIPTION.ECOMMERCE_TITLE' | translate}}</h3>

								<p>{{'SUBSCRIPTION.ECOMMERCE_SUBTITLE' | translate}}</p>

								<a class="fw-bold" (click)="session.gotoEcomm()"> {{'SUBSCRIPTION.ECOMMERCE_LINK' | translate}}<fa-icon class="ms-2" [icon]="faUpRightFromSquare"></fa-icon> </a>
							</div>
						</div>
					</div>
				</div>
				}
			</div>
		</div>

		<!-- DEVICES TABLE -->

		@if(this.userDevices.length > 0){
		<div class="col-12 mt-5">
			<div class="row mt-3">
				<div class="col-12">
					<table mat-table [dataSource]="userDevicesTable" matSort style="width: 100%" class="userdevice-list-container__table" multiTemplateDataRows>
						<!-- model -->
						<ng-container matColumnDef="model">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"CREDITS.TABLE.DEVICE" | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ element.model }}</td>
						</ng-container>
						<!-- sn -->
						<ng-container matColumnDef="sn">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"CREDITS.TABLE.SERIAL_NUM" | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ element.sn }}</td>
						</ng-container>
						<!-- services -->
						<ng-container matColumnDef="services_names">
							<th mat-header-cell *matHeaderCellDef>{{"CREDITS.TABLE.SERVICE" | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ element.services.length }}</td>
						</ng-container>

						<!-- status -->
						<ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef>{{"CREDITS.TABLE.STATUS" | translate}}</th>
							<td mat-cell *matCellDef="let element">
								<fa-icon
									[icon]="faCircleCheck"
									class="p-3"
									faCircleCheck
									[ngClass]="element.services_status == 'yellow'  ? 'yellow' : '' "
									[ngbTooltip]="element.services_status == 'yellow' ? ('CREDITS.TOOLTIPS.DEVICE_YELLOW' | translate) : ('CREDITS.TOOLTIPS.DEVICE_GREEN' | translate) "
									*ngIf="element.services.length > 0 && (element.services_status == 'green' || element.services_status == 'yellow' ) "></fa-icon>
								<fa-icon
									[icon]="faCircleXmark"
									class="p-3"
									faCircleXmark
									[ngbTooltip]="element.services.length == 0 ? ('CREDITS.TOOLTIPS.DEVICE_NO_SERVICE' | translate) : ('CREDITS.TOOLTIPS.DEVICE_RED' | translate) "
									*ngIf="element.services.length == 0 || (element.services_status == 'red' && element.services.length > 0) "></fa-icon>
							</td>
						</ng-container>

						<ng-container matColumnDef="expand">
							<th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
							<td mat-cell *matCellDef="let element">
								<div aria-label="expand row">
									<fa-icon [icon]="faCaretDown" aria-hidden="true" *ngIf="expandedElement !== element"></fa-icon>
									<fa-icon [icon]="faCaretUp" aria-hidden="true" *ngIf="expandedElement === element"></fa-icon>
								</div>
							</td>
						</ng-container>

						<!-- detail row -->
						<ng-container matColumnDef="expandedDetail">
							<td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
								<div class="additional-element-detail" (click)="$event.stopPropagation()">
									<div class="mt-2 p-3">
										<!-- <p><span class="fw-bold">{{"CREDITS.TABLE.SERVICES" | translate}}: </span><span>{{element.services.length}}</span></p> -->

										@if(element.services.length > 0){
										<table class="table">
											<thead>
												<tr style="font-size: 0.8em">
													<th scope="col">&nbsp;</th>
													<th scope="col">{{"CREDITS.TABLE.SERVICE" | translate}}</th>
													<th scope="col">{{"CREDITS.TABLE.DESCRIPTION" | translate}}</th>
													@if(currentProfile === 'is_doc'){
													<th scope="col">{{"CREDITS.TABLE.TYPE" | translate}}</th>
													}
													<th scope="col">{{"CREDITS.TABLE.PACK" | translate}}</th>

													<th scope="col">{{"CREDITS.TABLE.END_DATE" | translate}}</th>
													<th scope="col">{{"CREDITS.TABLE.DEVICE_STATUS" | translate}}</th>
												</tr>
											</thead>
											@for (serv of element.services; track serv) {

											<tbody [ngClass]="serv.status == 'scheduled' || serv.status == 'renewed' ? 'scheduled' : '' ">
												<tr>
													<th>{{$index+1}}</th>
													<td>{{serv.service}}</td>
													<td>{{serv.service_description}}</td>
													@if(currentProfile === 'is_doc'){
													<td>{{serv.service_mode}}</td>
													}
													<td>{{serv.pack}}</td>

													<td>{{session.formatDate(serv.end_date)}}</td>
													<td>
														@if(serv.status == 'active'){
														<fa-icon
															[icon]="faCircleCheck"
															class="p-3"
															faCircleCheck
															[ngbTooltip]="serv.status_flag == 'yellow' ? ('CREDITS.TOOLTIPS.SERVICE_YELLOW' | translate) : ('CREDITS.TOOLTIPS.SERVICE_GREEN' | translate) "
															[ngClass]="serv.status_flag == 'yellow' && serv.service == 'NDM' ? 'yellow' : '' "></fa-icon>
														} @else if (serv.status == 'expired' || serv.status == 'renewed' || serv.status == 'frozen'){
														<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark ngbTooltip="{{'CREDITS.TOOLTIPS.SERVICE_RED' | translate}}"></fa-icon>
														} @else if (serv.status == 'scheduled'){
														<fa-icon [icon]="faClock" class="p-3" faClock ngbTooltip="scheduled"></fa-icon>
														}
													</td>
												</tr>
											</tbody>

											}
										</table>
										}
									</div>
								</div>
							</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<!-- <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr> -->
						<tr mat-row *matRowDef="let row; columns: displayedColumns" (click)=" expandedElement = expandedElement === row ? null : row"></tr>
						<tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="detail-row" class="no-border" [class.colapsed-row]="expandedElement != element"></tr>
					</table>
					@if (userDevices.length > 4){
					<mat-paginator #paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons> </mat-paginator>
					}
				</div>
			</div>
		</div>
		}
	</div>
</div>

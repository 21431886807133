<div class="panel-modal user-event-modal position-relative">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
	</div>

	<loader [activateSpinner]="isloading"></loader>

	<!-- <div class="loader-container" [ngClass]="isloading ? 'show' : '' ">
		<div [hidden]="false" class="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div> -->

	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<h3 class="text-center fw-bold">{{'USER_EVENTS.TITLE' | translate}}</h3>
			</div>
		</div>

		<div class="row my-4">
			<div class="col-12 text-end">
				<p>
					{{'USER_EVENTS.EXPORT_DATA' | translate}}
					<span [hidden]="loadCSV"><fa-icon [icon]="faFileCsv" faFileCsv class="mx-3" (click)="exportToCSV()"></fa-icon></span>
					<span [hidden]="!loadCSV"><fa-icon class="spinner-csv mx-3" [icon]="faSpinner" faSpinner></fa-icon></span>
				</p>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<div class="user-event__container" [ngClass]="isloading ? '' : 'show' ">
					<table mat-table [dataSource]="eventList" matSort style="width: 100%">
						<ng-container matColumnDef="creation_date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_EVENTS.CREATION_DATE' | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ session.formatDateTime(element.creation_date) }}</td>
						</ng-container>

						<ng-container matColumnDef="event_type">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_EVENTS.EVENT' | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ 'USER_EVENTS.'+ element.event_type | translate }}</td>
						</ng-container>

						<ng-container matColumnDef="details">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_EVENTS.DETAILS' | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ element.details }}</td>
						</ng-container>

						<ng-container matColumnDef="created_by">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_EVENTS.CREATED_BY' | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ ((element.created_by == 0) ? ('USER_EVENTS.CREATED_BY_AUTO' | translate) : element.created_by == userId ? ('USER_EVENTS.CREATED_BY_MANUAL' | translate) : element.created_by ) }}</td>
						</ng-container>

						<ng-container matColumnDef="user_id">
							<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'USER_EVENTS.USER_ID' | translate}}</th>
							<td mat-cell *matCellDef="let element">{{ element.user_id }}</td>
						</ng-container>

						<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
						<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
					</table>

					<mat-paginator [pageSizeOptions]="[10, 15, 20, 25]" showFirstLastButtons aria-label="Select page of periodic elements"> </mat-paginator>
				</div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core'

import { TranslateService } from '@ngx-translate/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
// import { SmartTable, of } from 'smart-table-ng';
import { faTrashAlt, faEdit, faEye, faRectangleList } from '@fortawesome/free-regular-svg-icons'

import { Config } from '../../../config'
import { TableSettings, PageMngr } from '../../service/table.settings'
import { SessionService } from '../../service/session.service'
import { DataModelService, DataStatus } from '../../service/data-model.service'

import { Admin } from '../../models/admin.model'
import { User, UserType } from '../../models/user.model'
import { Address } from '../../models/address.model'
import { Util } from '../../models/util.model'

import { AdminModal } from './admin.modal'
import { DeleteModalContent, itemType } from '../delete/delete.modal'

import { MatTable, MatTableDataSource } from '@angular/material/table' //02.11.22
import { MatPaginator } from '@angular/material/paginator' //02.11.22
import { MatSort, Sort } from '@angular/material/sort' //02.11.22
import { TablePrefs } from '../../models/settings.model'
import { UserEventsModal } from 'src/app/elements/userEvents/userEvent.modal'
import { ToastOptions } from 'src/app/models/toast.model'
import { AppToastService } from 'src/app/service/toast.service'
import { UserEvents } from 'src/app/models/userEvents.model'

// const myTableSetting = new TableSettings();
// const myProvider = { provide: SmartTable, useFactory: () => of([], myTableSetting) };

@Component({
	selector: 'admins',
	templateUrl: './adminList.component.html',
	styleUrls: ['./adminList.component.scss'],
})
export class AdminListComponent implements OnInit {
	adminList: MatTableDataSource<Admin>
	adminListRaw: Admin[]

	@ViewChild(MatPaginator) paginator: MatPaginator //02.11.22
	@ViewChild(MatSort) sort: MatSort //02.11.22
	@ViewChild('filter') input: ElementRef //02.11.22

	filterWord: string

	displayedColumns: string[]
	adminPref: TablePrefs
	localStorageName: string

	currentAction: string
	currentModal

	success: boolean
	fail: boolean

	// 29.08.2022 non le ho qui ma sul delete modal
	//dispositivePwd :string;
	//wrongPwd : boolean;

	// 14.11.2017 solo per NS, metto a true per velocizzare la lista
	ignoreCritt = false

	currentAdmin: Admin // arriva dalla lista, non ha tutte le tabelle figlie
	//adminList: Admin[];  // 25.03.2020

	//filtersEnabled; // 12.11.2019
	// 13.11.2019 usare brands su session, centralizzato [ls]
	//brands = [];
	//filterBrand: string;

	//input per la directive
	refreshFlag: string

	// per paginazione tabella
	// itemsPerPage: number;
	// totalPages = 1;
	// currentPage = 1;
	// pages: number[];
	// totElements: number; // 27.01.2022

	// fa-icons per usarle su html
	faEdit = faEdit
	faTrashAlt = faTrashAlt
	faEye = faEye
	faRectangleList = faRectangleList

	constructor(
		public session: SessionService,
		public translator: TranslateService,
		public modalService: NgbModal,
		public dataService: DataModelService,
		private toastService: AppToastService
	) {
		this.ignoreCritt = true // this.getDefaultCrittVal();

		//this.adminList = []; // 14.01.2022
		this.clearCurrentAdmin() // lo inizializza vuoto

		//this.filtersEnabled = false;
		//this.brands = this.session.brands;  // 13.11.2019
		this.initColumns()
		this.loadAdminList()
		// presets
		this.manageTablePreferences()
	}

	private manageTablePreferences() {
		this.localStorageName = this.session.getUserId() + ' - adminPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.adminPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.adminPref = new TablePrefs()
			this.adminPref.empty
			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.adminPref))
		}
	}

	ngOnInit(): void {
		//this.session.checkRoute(); // serve x verificare expired ?

		// if (myTableSetting) {
		// 	// 09.06.2022
		// 	this.itemsPerPage = myTableSetting.getSliceSize();
		// } else {
		// 	this.itemsPerPage = 10;
		// }

		this.refreshFlag = 'init_AdmListComponent' // innesca il load della tabella
	}

	loadAdminList() {
		let ignoreCritt = true

		this.session
			.loadAdminList(ignoreCritt)
			.then((admins) => {
				// console.log(admins)
				this.adminListRaw = admins
				this.adminList = new MatTableDataSource<Admin>(this.adminListRaw)

				this.adminList.paginator = this.paginator
				this.adminList.sort = this.sort
				// automatic sort
				this.applySettings(this.adminPref, this.adminList)
			})
			.catch((myErr) => {
				let msg = this.session.parseErrorMessage(myErr, 'trace')
				console.log('(admin Component) KO ' + msg)
				console.log(myErr)
			})
	}

	initColumns() {
		this.displayedColumns = ['username', 'user_type', 'code', 'subscriptionTime', 'created_by', 'notes', 'filter']
	}

	clearCurrentAdmin() {
		this.currentAdmin = new Admin()
	}

	setCurrentAdmin(adm) {
		if (adm != null) {
			this.currentAdmin = adm
			// qui troppo presto, non ha ancora aperto il modal
			// this.initUserModels(3);
		} else {
			this.clearCurrentAdmin()
		}
	}
	private applySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		list.filter = pref.filter
		this.input.nativeElement.value = pref.filter

		// listen sort
		list.sort.sortChange.subscribe(() => {
			// save variables
			pref.sort = this.sort.active
			pref.dir = this.sort.direction
		})
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			pref.currentPage = this.paginator.pageIndex
		})
	}
	// 30.09.2021 vd simile fatto per i doctors
	// metto la username del creator al posto del created by, per maggior chiarezza
	getStrCreator(usrId) {
		let ret = this.session.getAdminUsername(usrId) // visibile solo per i livelli 3
		return ret
	}

	// 14.06.2021
	canBeDeleted(item: Admin) {
		var flag = false

		if (item.isDeleted) {
			flag = false
		} else if (this.session.isFirstGod()) {
			// solo Sauron puo' fare tutto
			flag = true
		} else if (this.session.isAdmin()) {
			if (item.isVice() || item.isStats() || item.isManager() || item.isSupport()) {
				flag = true
			}
		}

		return flag
	}

	canBeUpdated(item: Admin) {
		var flag = false

		if (item.isDeleted) {
			flag = false
			//} else if(this.session.isFirstGod()){
			//  flag = true;  // per ora non c'e' alcun campo
		} else if (this.session.isGod() && item.isManager()) {
			// TODO: anche support per -> super/std o cambio country ?
			flag = true
		}

		return flag
	}

	// 14.06.2021 abilitato
	// ex openAdminModal ?
	openEditModal(admin, action?) {
		if (!this.session.isGod()) {
			return
		}

		this.currentAction = action

		// ripulisco e nascondo msg ok/error
		this.success = false
		this.fail = false

		//this.dispositivePwd = "";
		//this.wrongPwd = false;

		// intanto salvo oggetto ricevuto dalla lista
		this.setCurrentAdmin(admin)

		// 15.06.2021 per ora bastano le info dalla lista
		// serve un po' di attesa per inizializz. le liste
		// richiedere info current admin x display ed edit
		this.session
			.loadAdmin(admin.id)
			.then((flagDone) => {
				var fullAdmin = this.session.getDtAdmin(admin.id)

				if (fullAdmin != null) this.setCurrentAdmin(fullAdmin)
				this.initUserModels(5) // qui ok

				this.currentModal = this.modalService.open(AdminModal, { size: 'xl' })
				this.currentModal.componentInstance.currentAdmin = this.currentAdmin
				this.currentModal.componentInstance.currentAction = this.currentAction

				// arriva qui facendo la close del modal
				this.currentModal.result.then(
					(changedUsr) => {
						console.log('AdmList - After modal closed')
						//this.submit(changedDoc); // gia' fatto dalla chiamante

						this.loadAdminList()

						// 19.11.2019  ripristino default [ls]
						this.ignoreCritt = false // this.getDefaultCrittVal();
					},
					(reason) => {
						let ris = 'Dismissed ' + Util.getDismissReason(reason)
						console.log(ris)
					}
				)
			})
			.catch((err) => {
				if (!this.session.isExpired(err)) {
					//  e' un altro errore, va segnalato
					//var msg = (err.data)? err.data.error : err.toString();
					let msg = this.session.parseErrorMessage(err, 'alert') // 28.03.2022
					alert(msg)
				}
			})
	}

	openDeleteModal(admin) {
		if (admin.id == 1) {
			alert('The superAdmin cannot be deleted.')
			return
		}

		this.setCurrentAdmin(admin)
		this.currentAction = 'delete'

		this.currentModal = this.modalService.open(DeleteModalContent, { size: 'l' })
		this.currentModal.componentInstance.itemType = itemType.USER
		this.currentModal.componentInstance.itemDescription = 'Admin: ' + admin.username

		// arriva qui facendo la close del modal
		this.currentModal.result.then(
			(resp) => {
				console.log('(delAdm) After modal closed: ' + resp)
				if (resp) {
					// confirmed and ok disp pwd   29.08.2022
					this.deleteUser(admin)
					this.loadAdminList()
				}
			},
			(reason) => {
				let ris = '(delAdm) Dismissed ' + Util.getDismissReason(reason)
				console.log(ris)
			}
		)
	}

	private deleteUser(usr?) {
		//if (this.currentAction == "delete") {
		// 25.05.2017 conferma password per delete

		// 29.08.2022 fatta dal modal, qui non ho la dispPwd
		Util.debug('(deleteUser) curr id : ' + this.currentAdmin.id + ' param usr: ' + usr.id) // 29.08.2022

		//if (this.session.checkNSPwd(this.dispositivePwd)==true) {  // ko 29.08.2022
		//this.wrongPwd = false;
		return this.session.deleteAdmin(this.currentAdmin.id).then((flagDone) => {
			// 29.08.2022
			//Util.debug("(deleteUser) resp:"+flagDone); // null
			this.refreshFlag = 'changed_AdmListComp'
		})

		/*
      } else {        
        Util.debug("(deleteUser) ko disp pwd"); 
        this.wrongPwd = true;
        return false;
      }
      */
		//}
	}

	public openUserEvents(user: Admin) {
		this.currentModal = this.modalService.open(UserEventsModal, { size: 'xl' })

		const promise = new Promise<boolean>((resolve, reject) => {
			const afterDate = user.userEvents.askDate
			Util.debug('afterDate: ' + afterDate)
			// se ho una data passata, la passo alla chiamata, che mi ritornerá solo gli ultimi eventi
			// quindi poi devo chiamare un update e non un set degli eventi
			this.session
				.getUserEvents(user.user_id, true, afterDate)
				.then((events: UserEvents[]) => {
					if (afterDate) {
						user.updateUserEvents(events)
					} else {
						user.setUserEvents(events)
					}

					this.currentModal.componentInstance.list = user.userEvents.userEvent
					this.currentModal.componentInstance.userId = user.user_id
					this.currentModal.componentInstance.userName = user.name

					resolve(true)
				})
				.catch((err) => {
					console.log(err)
					let header = this.translator.instant('TOAST.HEADER.ERROR')
					let body = this.translator.instant(err.error.error)
					let options = new ToastOptions('error')
					this.toastService.show(header, body, false, options, 'center')

					reject()
				})
		})

		this.currentModal.componentInstance.isLevel1 = false
		this.currentModal.componentInstance.loadEvents = promise
	}

	private disabSubmit(form) {
		/* TEMP TODO
    form.$submitted = true;  // 16.06.2021
    //console.log("(AL) submit has been called!");

    if (form.$valid) {

      var refresh = ()=>{
        this.currentModal.dismiss();
        this.ignoreCritt = false;       
        this.manageAdminList();  // 25.03.2020
      };

      // 26.06.2018 anticipato qui il test --ls
      if (!(this.session.isGod())) {
        console.log("solo NS abilitato, act: "+this.currentAction);
        return false;
      } 

      if (this.currentAction == "modify") {      
        // 15.06.2021
        var newModels = this.getSelectedModels();
        console.log("(editAdm) new: "+newModels);

        if(newModels == ""){
          alert("Please select at least one model");
          return false;
        }

        // passo tutto l'oggetto, per usi futuri
        var admSett = new data.AdmSettings();
        admSett.models = newModels;       

        this.session.updateAdmin(this.currentAdmin.id, admSett)
        .then(refresh);

      }
      else if (this.currentAction == "delete") {
        // 25.05.2017 conferma password per delete               
        if (this.session.checkNSPwd(this.dispositivePwd)==true) {
          this.wrongPwd = false;
          this.session.deleteAdmin(this.currentAdmin.id).then(refresh);
        } else {
         
          this.wrongPwd = true;
        }                 
      }

    }
    */
	}

	// ******** models x managers *************

	// 15.06.2021
	initUserModels(param?) {
		if (this.currentAdmin.admSettings == null || this.currentAdmin.admSettings.models == '') {
			return
		}

		// seleziono i suoi dalla lista 1, poi faccio add
		var myModels = this.currentAdmin.admSettings.models

		//console.log("(initUsrModels) usr: "+myModels);
		/* TEMP TODO
    var items = $("#list1 input:checkbox");
    var n = items.length;

    console.log("(initUsrModels) ("+param+") usr: "+myModels+" list1: "+n);

    if (n > 0) {
      items.each(function(idx,item){
        var choice = $(item);        
        //console.log("(initUsrModels) "+item.id );         
        if(myModels.indexOf(item.id)>=0){
          choice.parent().appendTo("#list2");
        }
      });
    } 
    */
	}

	private disabGetSelectedModels() {
		var myNewModels = ''
		/* TEMP TODO
    var items = $("#list2 input:checkbox");
    var n = items.length;
    //console.log("(getSelModels) list2: "+n);
    if (n > 0) {
      items.each(function(idx,item){            
        //console.log("(getSelModels) "+item.id);         
        if(idx != 0) myNewModels += ",";  // non davanti al primo
        myNewModels += ""+item.id;
      });
    }   
    */
		//console.log("(getSelModels) "+myNewModels);
		return myNewModels
	}

	// per dual list box picker
	// https://www.codeply.com/go/xLgFDFdP5U/bootstrap-dual-lists-(list-to-list)

	addModels() {
		//event.preventDefault();  // non attiva il submitted del form ??
		// sposta quelli checked nella lista 2
		//$('.all').prop("checked",false);
		//var items = $("#list1 input:checked:not('.all')");
		/* TEMP TODO
  var items = $("#list1 input:checked");
  var n = items.length;
  if (n > 0) {
    items.each(function(idx,item){
      var choice = $(item);
      choice.prop("checked",false);
      choice.parent().appendTo("#list2");
    });
  }
  else {
    alert("Choose an item from the available list.");
  }
  */
	}

	removeModels() {
		// sposta quelli checked nella lista 1
		/* TEMP TODO
  var items = $("#list2 input:checked");
  items.each(function(idx,item){
    var choice = $(item);
    choice.prop("checked",false);
    choice.parent().appendTo("#list1");
  });
  */
	}

	// ************************************

	manageReload(event) {
		if (this.dataService.adminListStatus == DataStatus.LOADED) {
			console.log('COMP (manageReload) admins status changed! ')
			var tot = this.dataService.getTotAdmins()
			// if (tot > 0) this.totElements = tot;

			// this.initPages(tot);

			// 13.12.2021 non serve, ci rimane gia'!
			//this.pager.selectPage(this.currentPage);  // 13.12.2021 la pg in cui l'utente era prima del reload
		}
	}
	public filterText() {
		this.adminPref.filter = this.input.nativeElement.value
		this.adminPref.filter = this.adminPref.filter.trim().toLocaleLowerCase()
		this.adminList.filter = this.adminPref.filter
	}

	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.adminPref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.adminPref.itemsPerPage))
	}
}

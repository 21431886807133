import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'field',
	templateUrl: './field.component.html',
	styleUrls: ['./field.component.scss'],
})
export class FieldComponent implements OnInit {
	@Input() name: string
	@Input() value: string
	//editable: boolean;

	constructor() {}

	ngOnInit(): void {}
}

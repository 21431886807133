import { Component, OnInit } from '@angular/core'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { buildResponse } from '../login/login.component'

@Component({
	selector: 'about',
	templateUrl: './about.modal.html',
	styleUrls: ['./about.modal.scss'],
})
export class AboutModal implements OnInit {
	nexusBuilds: buildResponse[]
	constructor(public session: SessionService) {
		this.nexusBuilds = this.session.nexusBuils
	}

	ngOnInit(): void {}

	// 27.05.2021
	getBuild() {
		return Config.BUILD
	}
}

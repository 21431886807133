<div class="graders-list-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="panel-head">
					<div class="container-fluid">
						<div class="row">
							<div class="col-12">
								<button type="button" class="btn btn-primary" (click)="openGraderModal(null)">{{ 'CLINIC.ADD_GRADER' | translate }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="panel-body position-relative col-12" style="min-height: 30vh">
				<loader [activateSpinner]="isLoading"></loader>

				<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
					<div [hidden]="false" class="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
					</div>
				</div> -->

				<table mat-table [dataSource]="graderList" matSort style="width: 100%" class="grader-list" [ngClass]="isLoading ? '' : 'show' ">
					<!-- first Column -->
					<ng-container matColumnDef="username">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.USERNAME' | translate }}</th>
						<td mat-cell *matCellDef="let element" [ngClass]="element.isActive ? 'fw-bold' : 'notActive' ">{{ element.username }}</td>
					</ng-container>

					<ng-container matColumnDef="code">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DISTRIB_LIST.CODE' | translate }}</th>
						<td mat-cell *matCellDef="let element">{{ element.code }}</td>
					</ng-container>

					<ng-container matColumnDef="reports">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLINIC.REPORTS' | translate }}</th>
						<td mat-cell *matCellDef="let element">{{element.tot_reports_done}}</td>
					</ng-container>

					<ng-container matColumnDef="activated">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLINIC.REGISTRATED' | translate }}</th>
						<td mat-cell *matCellDef="let element">{{session.formatDate(element.subscriptionTime)}}</td>
					</ng-container>

					<ng-container matColumnDef="disabled">
						<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'CLINIC.DISABLED' | translate }}</th>
						<td mat-cell *matCellDef="let element">
							<div class="checkbox position-relative" placement="right" *ngIf="element.isActive">
								<input class="form-check-input" ngbTooltip="Disable grader" type="checkbox" [value]="element.isEnabled" id="flexCheckDefault" (change)="disableGrader(element, $event)" [checked]="element.isEnabled ? false : true" *ngIf="!disableG" />
								<div class="loading-spinner">
									<fa-icon [icon]="faSpinner" *ngIf="disableG"></fa-icon>
								</div>
							</div>
						</td>
					</ng-container>

					<!-- last Column - block bouble click view - only click the buttons -->
					<ng-container matColumnDef="filter">
						<th mat-header-cell *matHeaderCellDef class="text-end">
							<mat-form-field appearance="outline">
								<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
							</mat-form-field>
						</th>
						<td mat-cell *matCellDef="let element">
							&nbsp;&nbsp;
							<a class="table-button" [hidden]="element.isDeleted()" (click)="openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}">
								<fa-icon [icon]="faTrashAlt" aria-hidden="true"></fa-icon>
							</a>
							&nbsp;&nbsp;
							<a class="table-button" [hidden]="element.isDeleted()" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="openGraderModal(element)">
								<fa-icon [icon]="faEdit" aria-hidden="true"></fa-icon>
							</a>
						</td>
					</ng-container>

					<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
				</table>

				<mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons [ngClass]="isLoading ? '' : 'show' "> </mat-paginator>
			</div>
		</div>
	</div>
</div>

<div class="topview device-list-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="device-list-container__content">
					<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-pills" [destroyOnHide]="false" (navChange)="onNavChange($event)">
						<!-- non usato: (activeIdChange)="onNavActiveChange($event)" -->

						<!-- ################ OVERVIEW ######################### -->

						<li ngbNavItem="overview" class="nav-item me-3">
							<a ngbNavLink uppercase class="nav-link fw-bold">{{'DEVICE.OVERVIEW' | translate}}</a>

							<ng-template ngbNavContent>
								<div class="row overview-container" style="min-height: 35vh">
									<div class="col-xxl-6 col-sm-12 overview-container__chart position-relative" [ngClass]="!activateSpinner ? 'show' : '' ">
										<p class="filter-title-section border-bottom">
											<label class="fw-bold uppercase">{{'DEVICE.CHARTS.DEV_TYPE' | translate}}</label>
										</p>
										@if(devicesTypePieChartReady){
										<apx-chart
											[fill]="devicesTypePieChart.fill"
											[title]="devicesTypePieChart.title"
											[legend]="devicesTypePieChart.legend"
											[series]="devicesTypePieChart.series"
											[chart]="devicesTypePieChart.chart"
											[labels]="devicesTypePieChart.labels"
											[responsive]="devicesTypePieChart.responsive"
											[colors]="devicesTypePieChart.colors"
											[noData]="{text: 'No data to display'}">
										</apx-chart>
										} @else {
										<loader [activateSpinner]="!devicesTypePieChartReady"></loader>
										}
									</div>
									<div class="col-xxl-6 col-sm-12 overview-container__chart position-relative" [ngClass]="!activateSpinner ? 'show' : '' ">
										<p class="filter-title-section border-bottom">
											<label class="fw-bold uppercase">{{'DEVICE.CHARTS.DEV_VERSIONS' | translate}}</label>
										</p>

										@if(devicesVersionPieChartReady){
										<apx-chart
											[fill]="devicesVersionPieChart.fill"
											[title]="devicesVersionPieChart.title"
											[legend]="devicesVersionPieChart.legend"
											[series]="devicesVersionPieChart.series"
											[chart]="devicesVersionPieChart.chart"
											[labels]="devicesVersionPieChart.labels"
											[responsive]="devicesVersionPieChart.responsive"
											[colors]="devicesVersionPieChart.colors"
											[noData]="{text: 'Select at least one model to show this chart'}">
										</apx-chart>
										} @else {
										<loader [activateSpinner]="!devicesVersionPieChartReady"></loader>
										}
									</div>
								</div>
								<div class="row mt-5">
									<div class="col-12 mb-4">
										<p class="filter-title-section border-bottom">
											<label class="fw-bold uppercase">{{'DEVICE.DEV_STATUS_LOC' | translate}}</label>
										</p>
									</div>
									<div class="col-12 mb-2">
										<div class="d-flex">
											<div class="pe-3">
												<p>
													<label class="fw-bold me-2">{{'DEVICE.DEV_UPDATES' | translate}}</label>
													<span class="fw-bold" [ngClass]="devicesWithUpdate>0 ? 'red' : ''">{{devicesWithUpdate}}</span>
												</p>
											</div>

											<div class="pe-3">
												<p>
													<label class="fw-bold me-2">{{'DEVICE.DEV_TARGETS' | translate}}</label>
													<span class="fw-bold" [ngClass]="devicesWithPotentialUpdate>0 ? 'red' : ''">{{devicesWithPotentialUpdate}}</span>
												</p>
											</div>
											<div>
												<p>
													<label class="fw-bold me-2">{{'DEVICE.DEV_GMT' | translate}}</label>
													<span class="fw-bold" [ngClass]="devicesWithWrongGMT>0 ? 'red' : ''">{{devicesWithWrongGMT}}</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</ng-template>
						</li>

						<!-- ################ DETAILS ######################### -->

						<li ngbNavItem="details" class="nav-item me-3">
							<a ngbNavLink uppercase class="nav-link fw-bold">{{'DEVICE.DETAILS' | translate}}</a>

							<ng-template ngbNavContent> </ng-template>
						</li>
					</ul>
					<div class="row">
						<div class="col-12">
							<div class="details-container__filters mt-4">
								<div class="row">
									<div class="col-11">
										<p class="filter-title-section w-50" (click)="showFilters = !showFilters" [ngClass]="showFilters ? '' : 'border-bottom' ">
											<label class="fw-bold uppercase">{{'DEVICE.FILTERS_TITLE' | translate}}</label>
											<fa-icon [icon]="faSortDown" faSortDown [ngClass]="showFilters ? 'show' : '' " aria-hidden="true" class="float-end"></fa-icon>

											<span *ngIf="filterSet" class="me-2 fw-light float-end">{{'DISTRIB_LIST.FILTERS.MESSAGE' | translate}}<fa-icon [icon]="faCircleExclamation" faCircleExclamation aria-hidden="true"></fa-icon></span>
										</p>

										<form [formGroup]="filterForm" class="filter-form__container d-flex" [ngClass]="showFilters ? 'show' : '' ">
											<!-- COUNTRY -->
											<div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
												<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
													<mat-label style="color: #4f0aeb">{{'DEVICE.FILTERS.COUNTRY' | translate}}</mat-label>

													<mat-select name="country" (selectionChange)="filterTableValue()" formControlName="country">
														<mat-option *ngFor="let country of availableCountries" [value]="country"> {{country}} </mat-option>
													</mat-select>
												</mat-form-field>
												<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('country').value != null ? 'show': '' " (click)="filterForm.get('country').reset(); filterTableValue()"></fa-icon>
											</div>

											<!-- MODEL -->
											<div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
												<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
													<mat-label style="color: #4f0aeb">{{'DEVICE.FILTERS.MODEL' | translate}}</mat-label>

													<mat-select name="model" (selectionChange)="filterTableValue()" formControlName="model">
														<mat-option *ngFor="let model of availableDevices" [value]="model"> {{model}} </mat-option>
													</mat-select>
												</mat-form-field>
												<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('model').value != null ? 'show': '' " (click)="filterForm.get('model').reset(); filterTableValue()"></fa-icon>
											</div>

											<!-- UPDATE STATUS -->
											<div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
												<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
													<mat-label style="color: #4f0aeb">{{'DEVICE.FILTERS.UPDATE_STATUS' | translate}}</mat-label>

													<mat-select name="update_status" (selectionChange)="filterTableValue()" formControlName="update_status">
														<mat-option *ngFor="let state of updateStatus" [value]="state.value"> {{state.label}} </mat-option>
													</mat-select>
												</mat-form-field>
												<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('update_status').value != null ? 'show': '' " (click)="filterForm.get('update_status').reset(); filterTableValue()"></fa-icon>
											</div>

											<!-- GMT STATUS -->
											<div class="col-xxl-2 col-sm-3 d-flex align-items-center pe-2">
												<mat-form-field appearance="outline" style="font-size: 14px; width: 95%">
													<mat-label style="color: #4f0aeb">{{'DEVICE.FILTERS.GMT_STATUS' | translate}}</mat-label>

													<mat-select name="gmt_status" (selectionChange)="filterTableValue()" formControlName="gmt_status">
														<mat-option *ngFor="let state of gmtStatus" [value]="state.value"> {{state.label}} </mat-option>
													</mat-select>
												</mat-form-field>
												<fa-icon class="ms-2" [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm.get('gmt_status').value != null ? 'show': '' " (click)="filterForm.get('gmt_status').reset(); filterTableValue()"></fa-icon>
											</div>
										</form>
									</div>

									<div class="col-1 d-flex align-items-end">
										@if(activeTab=='details'){
										<button type="button" class="btn btn-primary btn-sm reload shadow" (click)="reloadList()" [disabled]="!reloadEnable">
											{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
										</button>
										}
									</div>
								</div>
							</div>
							<div [ngbNavOutlet]="nav" class="pt-3"></div>
						</div>

						<!-- tabella condivisa -->
						<div class="row">
							<div [ngClass]="activeTab == 'overview' ? 'col-xxl-4 col-sm-12 mb-3' : 'col-12' ">
								<div class="table-container position-relative mt-3" style="min-height: 35vh">
									<loader [activateSpinner]="activateSpinner"></loader>

									<table mat-table [dataSource]="devicesList" matSort style="width: 100%" [ngClass]="!activateSpinner ? 'show' : '' ">
										<!-- Id Column -->
										<ng-container matColumnDef="id">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.ID' | translate }}</th>
											<td mat-cell *matCellDef="let element">{{ element.id }}</td>
										</ng-container>

										<!-- Model Column -->
										<ng-container matColumnDef="model">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.MODEL' | translate }}</th>
											<td mat-cell *matCellDef="let element">{{ element.model }}</td>
										</ng-container>

										<!-- SN Column -->
										<ng-container matColumnDef="sn">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.SN' | translate }}</th>
											<td mat-cell *matCellDef="let element">{{ element.sn }}</td>
										</ng-container>

										<!-- username -->
										<ng-container matColumnDef="username">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.OWNER' | translate }}</th>
											<td mat-cell *matCellDef="let element">{{ element.username }}</td>
										</ng-container>

										<!-- organization -->
										<ng-container matColumnDef="organization">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.ORGANIZATION' | translate }}</th>
											<td mat-cell *matCellDef="let element">@if(element.decritted){ {{ element.organization }} } @else {<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion></fa-icon>}</td>
										</ng-container>

										<!-- country -->
										<ng-container matColumnDef="country">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.COUNTRY' | translate }}</th>
											<td mat-cell *matCellDef="let element" class="normalcase">{{ element.country }}</td>
										</ng-container>

										<!-- main_build -->
										<ng-container matColumnDef="main_build">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.MAIN_BUILD' | translate }}</th>
											<td mat-cell *matCellDef="let element" class="normalcase">
												<div>{{ element.main_build }}</div>
											</td>
										</ng-container>

										<!-- main_build_num-->
										<ng-container matColumnDef="main_build_num">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.MAIN_BUILD_NUM' | translate }}</th>
											<td mat-cell *matCellDef="let element" class="normalcase">
												<div>{{element.main_build_num }}</div>
											</td>
										</ng-container>

										<!-- last_connection -->
										<ng-container matColumnDef="last_connection">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.LAST_USED' | translate }}</th>
											<td mat-cell *matCellDef="let element">{{session.formatDateTime(element.last_used)}}</td>
										</ng-container>

										<!-- last_update -->
										<!-- <ng-container matColumnDef="last_update">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.LAST_UPDATE' | translate }}</th>
											<td mat-cell *matCellDef="let element"></td>
										</ng-container> -->

										<!-- update_state -->
										<ng-container matColumnDef="update_state">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.UPDATE_STATUS' | translate }}</th>
											<td mat-cell *matCellDef="let element">
												<fa-icon [icon]="faBan" class="p-3" faBan ngbTooltip="{{ 'DEVICE.UPDATE_STATE_0' | translate }}" *ngIf="element.update_available ==  'unknown' "></fa-icon>
												<fa-icon class="p-3 warning-icon" [icon]="faCircleExclamation" faCircleExclamation ngbTooltip="{{ 'DEVICE.UPDATE_STATE_1' | translate }}" *ngIf="element.update_available == 'potential' "></fa-icon>
												<fa-icon class="p-3 orange-light" [icon]="faCircleExclamation" faCircleExclamation ngbTooltip="{{ 'DEVICE.UPDATE_STATE_2' | translate }}" *ngIf="element.update_available == 'target' "></fa-icon>
												<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="{{ 'DEVICE.UPDATE_STATE_3' | translate }}" *ngIf="element.update_available == 'aligned' "></fa-icon>
												<fa-icon [icon]="faCircleQuestion" class="p-3" faCircleQuestion ngbTooltip="{{ 'DEVICE.UPDATE_STATE_4' | translate }}" *ngIf="element.update_available == 'loading' "></fa-icon>
											</td>
										</ng-container>

										<!-- gmt_offset -->
										<ng-container matColumnDef="gmt_offset">
											<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEVICE.GMT_OFFSET' | translate }}</th>
											<td mat-cell *matCellDef="let element">
												@if(element.gmt_offset_state == 0){
												<fa-icon [icon]="faBan" class="p-3" faBan ngbTooltip="{{ 'DEVICE.GMT_NOT_AVAILABLE' | translate }}"></fa-icon>
												} @else { {{ element.gmt_offset }} }
											</td>
										</ng-container>

										<!-- gmt_offset state-->
										<ng-container matColumnDef="gmt_offset_state">
											<th mat-header-cell *matHeaderCellDef mat-sort-header></th>
											<td mat-cell *matCellDef="let element">
												<fa-icon [icon]="faCircleExclamation" class="p-3" faCircleExclamation ngbTooltip="{{ 'DEVICE.WRONG_GMT' | translate }}" *ngIf="element.gmt_offset_state == 2"></fa-icon>
											</td>
										</ng-container>

										<ng-container matColumnDef="filter">
											<th mat-header-cell *matHeaderCellDef class="d-flex flex-row align-items-center">
												<div>
													<mat-form-field appearance="outline">
														<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
													</mat-form-field>
												</div>

												<div>
													<fa-icon class="ms-3" [icon]="faFileCsv" faFileCsv (click)="downloadLsit()" ngbTooltip="{{ 'DEVICE.DOWNLOAD_LIST' | translate }}"></fa-icon>
												</div>
											</th>
											<td mat-cell *matCellDef="let element"></td>
										</ng-container>

										<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
										<tr mat-row (click)="showItem(row)" *matRowDef="let row; columns: displayedColumns"></tr>
									</table>

									<mat-paginator [pageSizeOptions]="[10, 20]" showFirstLastButtons> </mat-paginator>
								</div>
							</div>

							@if(activeTab == 'overview'){
							<div class="col-xxl-8 col-sm-12">
								<div class="maps-container position-relative">
									<loader [activateSpinner]="loadLocations"></loader>

									<app-map [getElementsToDisplay]="sendLocationsToMap" (sendMarkers)="getMarkers($event)" [selectMarker]="selectMarker"></app-map>
								</div>
							</div>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

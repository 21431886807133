import { AdmSettings, Settings } from './settings.model'
import { Address } from './address.model'
import { DoctorInfo } from './doctor.model'
// import { userDevice } from './device.model'
import { UserDevice } from './user.model'
import { AgreementInfo, AgreementsStatus, AgrStatus } from './agreement.model'

export interface ProfilesResponse {
	profile: {
		birthday_year: string
		birthday_date: string
		firstname: string
		is_deleted: string // inutile, non dovrebbe proprio rispondere ?!
		access_counter: number
		lastname: string
		race: string
		sex: string
		subscription_time: string
		code: string
		user_type: string
		user_subtype: string // 17.04.2020
		is_test: string // 13.10.2021
		super_salt: string // 08.02.2021 per utenti miniB, la username del suo superB
		country: string // 06.09.2022
		anamnesis_group: number
		groupId: number
		pending_pats: number
		vacancies: { id: number; from_date: string; to_date: string }[]
		devices: UserDevice[]
		// Aggiunti 06.06.2023
		display_name: string
		signature: string
		signature_name: string
		logo: string
		logo_name: string
		status: string

		user_id: number
		last_visit: string

		user_group: number // 21.06.2019
		order_reg_num: string // 13.09.2019 in chiaro
		licence_num: string // 09.05.2024 crittato

		//credits: number;   // non usato, 21.06.2019
		client_ip: string

		agreements_status: AgreementsStatus[]
		patients_agreement_status: AgrStatus //07.03.23

		sale_plan_id: number // 19.08.2021

		addresses: Address[]

		user_access: {
			username: string
			keybox_admin: any
			keybox_distributor: any
			keybox_doctor: any
			keybox_photo: any
			keybox_vice: any // 06.08.2018
			private_keybox: any // 19.03.2020 per i livelli1, contiene la privateKey
			public_key: any // 09.05.2024
			puk_data: any
			puk_metadata: any
		}

		settings: Settings //  04.05.2018 per non duplicare --ls
		settings_admin: AdmSettings // 27.05.2021 solo per manager

		doctor_info: DoctorInfo // 03.10.2018 --ls

		specialists: [
			// 29.05.2017  solo per livelli 1
			{
				distributor_id: string
				display_name: string
				affiliation_date: string
				cessation_date: string
			}
		]
	}
}

// non servono ?

// usata per edit profile proprio
export interface ProfileRequest {
	data: ProfileJson
}

export class ProfileJson {
	username: string
	firstname: string
	lastname: string
	order_reg_num: string // solo per doctors e specialists
	licence_num: string
	licence_hashed: string
	addresses: Address[]
	// Aggiunti 06.06.2023
	display_name: string
	signature: string
	signature_name: string // 16.06.2023
	logo: string
	logo_name: string
	//sale_plan_id: string;   // 19.08.2021

	constructor() {}
}

<div class="pagenotfound-container">
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div class="text-center">
					<h2>Page not found</h2>
					<h1>404</h1>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="panel panel-modal panel-default ng-cloak position-relative">
	<!-- modal-panel -->
	<div class="panel-heading">
		<label class="panel-title"> &nbsp; {{"DOCTOR.COLL_TITLE_NEW" | translate}}</label>
		<p [hidden]="!success">{{"CREATE_USER.DESCRIPTION_SUCCESS" | translate}}</p>
		<p [hidden]="!fail">{{"CREATE_USER.DESCRIPTION_FAIL" | translate}}</p>

		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
		</div>

		<!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 add-container float_right">         
      <button type="button" class="close closeMedicalModal" aria-label="Close" 
          (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>        
    </div> -->
	</div>

	<div class="panel-body">
		<form name="doctorForm" #doctorForm="ngForm" novalidate class="container">
			<div class="row mb-4 mt-3">
				<div class="col-4 form-group">
					<label for="type" class="form-label">{{ 'CREATE_USER.TYPE' | translate }} *</label>
					<div class="d-flex flex-column">
						<div class="mb-2">
							<input type="radio" name="user_type" id="user_typeA" value="Optician" [(ngModel)]="miniDoct.role" />
							<span class="ms-2">Technician</span>
						</div>
						<div><input type="radio" name="user_type" id="user_typeB" value="Doctor" [(ngModel)]="miniDoct.role" /> <span class="ms-2">Doctor</span></div>
					</div>
				</div>

				<div class="col-8 d-flex flex-column justify-content-center">
					<div class="mini-modal-psw-warning" style="font-size: 16px; padding: 15px">
						<div class="arrow"></div>
						<div class="psw-warning-body"><fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon> {{"CREATE_USER.USERNAME_MINI_HINT" | translate}}</div>
					</div>
				</div>

				<div class="col-4 form-group">
					<label for="username" class="form-label">{{"CREATE_USER.USERNAME" | translate}} *</label>
					<input type="text" class="form-control" name="username" id="username" placeholder="{{'CREATE_USER.USERNAME_HINT' | translate}}" #un="ngModel" (change)="validateUsername()" [(ngModel)]="miniDoct.username" required />
					<div class="error-wrapper">
						<p class="error-message" [hidden]="!(un.touched && un.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
					</div>
				</div>

				<div class="col-4 form-group position-relative">
					<!-- visibile to avoid errors -->
					<label for="password" class="form-label">{{"CREATE_USER.PASSWORD" | translate}} *</label>
					<input type="text" class="form-control normalcase" name="pwd" id="pwd" placeholder="{{'CREATE_USER.PASSWORD_HINT' | translate}}" (ngModelChange)="validatePwd($event)" #pwd="ngModel" [(ngModel)]="miniDoct.password" [ngClass]="{'psw-valid' : !validMiniPwd}" required />
					<!-- <p class="error-message ng-hide" [hidden]="!(pwd.touched && pwd.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p> -->
					<p class="error-message" [hidden]="validMiniPwd">{{"PROFILE.INVALID_PASSWORD" | translate}}</p>
				</div>

				<div class="col-4 d-flex flex-column justify-content-end">
					<div class="mini-modal-psw-warning">
						<div class="arrow"></div>
						<div class="psw-warning-header warning"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning"></fa-icon> {{'MISC.ATTENTION' | translate}}</div>
						<div class="psw-warning-body">{{"CREATE_USER.PASSWORD_DISCL" | translate}}</div>
					</div>
				</div>
			</div>

			<hr />

			<div class="row mt-3">
				<div class="col-12">
					<fa-icon [icon]="faInfoCircle" aria-hidden="true" class="info"></fa-icon> &nbsp;
					<span>{{"CREATE_USER.INFO_CONTACTS" | translate}}</span>
				</div>

				<div class="col-4 form-group">
					<label for="firstname">{{"DOCTOR.MODAL_FIRST_NAME" | translate}} *</label>
					<input type="text" class="form-control" name="firstname" id="firstname" [(ngModel)]="miniDoct.firstname" #fn="ngModel" required />
					<p class="error-message ng-hide" [hidden]="!(fn.touched && fn.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
				</div>

				<div class="col-4 form-group">
					<label for="lastName">{{"DOCTOR.MODAL_LAST_NAME" | translate}} *</label>
					<input type="text" class="form-control" name="lastName" id="lastName" [(ngModel)]="miniDoct.lastname" #ln="ngModel" required />
					<p class="error-message ng-hide" [hidden]="!(ln.touched && ln.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
				</div>

				<div class="col-4 form-group">
					<label for="address">{{"PATIENT.MODAL_ADDRESS" | translate}} *</label>
					<input type="text" class="form-control" id="address" name="address" [(ngModel)]="miniDoct.mainAddress.address_line1" #addr="ngModel" required />
					<p class="error-message ng-hide" [hidden]="!(addr.touched && addr.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
				</div>

				<div class="col-4 form-group">
					<label for="city">{{"PATIENT.MODAL_CITY" | translate}} *</label>
					<input type="text" class="form-control" id="city" name="city" #city="ngModel" [(ngModel)]="miniDoct.mainAddress.city" required />
					<p class="error-message ng-hide" [hidden]="!(city.touched && city.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
				</div>

				<div class="col-4 form-group">
					<label for="province">{{"PATIENT.MODAL_PROVINCE" | translate}} *</label>
					<input type="text" class="form-control" id="province" name="province" #prov="ngModel" [(ngModel)]="miniDoct.mainAddress.province" required />
					<p class="error-message ng-hide" [hidden]="!(prov.touched && prov.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
				</div>

				<!-- TODO readonly, same of the superB -->
				<!-- 
							<div class="col-4 form-group"> 
								<label for="country">{{"PATIENT.MODAL_COUNTRY" | translate}} *</label>
								<input type="text" class="form-control" id="country"  name="country" #ctry="ngModel"
									[(ngModel)]="miniDoct.mainAddress.country" required /> 
								<p class="error-message ng-hide" [hidden]="!(ctry.touched && ctry.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>          
							</div>
							-->

				<div class="col-4 form-group">
					<!-- readonly, same of the superB -->
					<label for="country">{{"DOCTOR.MODAL_COUNTRY" | translate}}</label>
					<input type="text" class="form-control" readonly name="country" id="country" [value]="getReadOnlyCountry()" />
				</div>

				<div class="col-4 form-group">
					<label for="organization">{{"CREATE_USER.ORGANIZATION" | translate}}</label>
					<input type="text" class="form-control" id="organization" name="organization" [(ngModel)]="miniDoct.mainAddress.organization" />
				</div>
				<div class="col-4 form-group">
					<label for="order_reg_num">{{"PROFILE.LICENCE_NUM" | translate}}</label>
					<input type="text" class="form-control" id="order_reg_num" name="order_reg_num" placeholder="{{'DISTRIB.MODAL_ORDER_REG_NUM_HINT' | translate}}" [disabled]="(miniDoct.role == 'Optician')" [(ngModel)]="miniDoct.order_reg_num" />
				</div>
			</div>

			<div class="row">
				<div class="twothird-container">&nbsp;</div>
				<div class="third-container">
					<div class="row">
						<div class="half-container text-end">
							<button type="submit" [disabled]="doctorForm.invalid" (click)="submit()" class="btn btn-primary">{{'BUTTONS.SAVE' | translate}}</button>
						</div>
						<div class="half-container text-end">
							<button type="clear" (click)="activeModal.dismiss('undo');" class="btn btn-secondary">{{"BUTTONS.UNDO" | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

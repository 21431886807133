import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core'
import { SessionService } from 'src/app/service/session.service'
import { FormControl, FormGroup } from '@angular/forms'
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { CandidateDevices, Device, SwUpdate, deviceTargetUpdate } from 'src/app/models/device.model'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'

import { Util } from 'src/app/models/util.model'

import { faXmark, faCircleQuestion, faCircleMinus, faSortDown, faCircleExclamation, faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { CryptoUtilsService } from 'src/app/service/crypto-utils.service'
import { TranslateService } from '@ngx-translate/core'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'
import { ToastOptions } from 'src/app/models/toast.model'
import { AppToastService } from 'src/app/service/toast.service'

@Component({
	selector: 'push-update-modal',
	templateUrl: './push-update.modal.html',
	styleUrls: ['./push-update.modal.scss'],
})
export class PushUpdateModal implements OnInit, AfterViewInit {
	devicesList: MatTableDataSource<CandidateDevices>
	devicesListRaw: CandidateDevices[]
	filteredList: CandidateDevices[]

	@ViewChild('paginator1') paginator1: MatPaginator
	@ViewChild('sort1') sort1: MatSort
	@ViewChild('filter') input: ElementRef

	targetDevicesList: MatTableDataSource<CandidateDevices>
	targetDevicesListRaw: CandidateDevices[]
	targetDevicesListMap: Map<number, CandidateDevices>

	@ViewChild('paginator2') paginator2: MatPaginator
	@ViewChild('sort2') sort2: MatSort

	displayedColumns: string[]
	displayedColumns2: string[]
	activateSpinner: boolean
	filterForm: FormGroup

	all: boolean
	pushToAllDevicesCheckbox: boolean
	pushing: { state: boolean; result: boolean; message: string }

	@Input() update: SwUpdate

	availableVersions: { label: string; value: number }[]
	availableCountry: string[]
	availableOS: string[]
	availableOrganization: string[]

	currentModal: NgbModalRef

	showFilters: boolean
	filterSet: boolean

	faXmark = faXmark
	faCircleQuestion = faCircleQuestion
	faCircleMinus = faCircleMinus
	faSortDown = faSortDown
	faCircleExclamation = faCircleExclamation
	faCircleCheck = faCircleCheck
	faCircleXmark = faCircleXmark

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		private cryptoUtils: CryptoUtilsService,
		private translator: TranslateService,
		public modalService: NgbModal,
		private toastService: AppToastService
	) {
		Util.debug(' (PushUpdateModal) - constructor')
		this.all = false
		this.pushToAllDevicesCheckbox = false
		this.pushing = { state: false, result: false, message: '' }
		this.activateSpinner = true
		this.displayedColumns = ['select', 'sn', 'main_build', 'country', 'os_ver', 'organization'] //, 'filter']
		this.displayedColumns2 = ['select', 'sn', 'main_build', 'country', 'os_ver', 'organization']

		this.filterForm = new FormGroup({
			version: new FormControl(null),
			country: new FormControl(null),
			os: new FormControl(null),
			// brand: new FormControl(null),
			organization: new FormControl(null),
		})

		this.availableVersions = []
		this.availableCountry = []
		this.availableOS = []
		this.availableOrganization = []

		this.showFilters = false
		this.filterSet = false

		this.update = new SwUpdate()

		this.filterForm.disable()
		this.devicesListRaw = []
		this.targetDevicesList = new MatTableDataSource<CandidateDevices>()
		this.targetDevicesListRaw = []
		this.targetDevicesListMap = new Map<number, CandidateDevices>()
	}

	ngOnInit(): void {
		Util.debug(' (PushUpdateModal) - ngOnInit')
	}

	ngAfterViewInit(): void {
		this.loadTargetDevices()
	}

	private loadTargetDevices() {
		this.activateSpinner = true

		this.session.loadCandidateDevices(this.update.id).then((res) => {
			// console.log(res)

			this.devicesListRaw = res
			this.initTable(this.devicesListRaw)

			this.decrittOrganizations(this.devicesListRaw).then(() => {
				this.initFilters(this.devicesListRaw)
			})
		})
	}

	private initTable(list: CandidateDevices[]) {
		this.activateSpinner = false

		this.devicesList = new MatTableDataSource<CandidateDevices>(list)
		this.devicesList.paginator = this.paginator1
		this.devicesList.sort = this.sort1
	}

	private decrittOrganizations(list: CandidateDevices[]): Promise<boolean> {
		Util.debug('[DevicesComponent] - decrittOrganizations')
		const promise = new Promise<boolean>(async (resolve, reject) => {
			var goodPwd = this.session.getPwdForKeyPhoto()
			let promiseArray = []
			for (let device of list) {
				// console.log(device)

				if (!device.decritted) {
					promiseArray.push(
						await device
							.decrittOrganization(this.cryptoUtils, goodPwd)
							.then((res: boolean) => {
								// se utente deleted, la decritt é false
								// cambiato, decritto anche quelli deleted
								if (res) {
									device.decritted = true
								}
							})
							.catch((err) => {
								console.log(err)
							})
					)
				}
			}

			Promise.all(promiseArray).then((res) => {
				resolve(true)
				// console.log(this.devicesListRaw)
			})
		})

		return promise
	}

	private initFilters(list: CandidateDevices[]) {
		this.availableCountry = [...new Set(list.map((item) => item.country))]
		this.availableOS = [...new Set(list.map((item) => item.os_ver))]
		this.availableOrganization = [...new Set(list.map((item) => item.organization))]

		let versions = [...new Set(list.map((item) => item.main_build))]
		for (let ver of versions) {
			let value = list.find((item) => item.main_build == ver).main_build_num

			this.availableVersions.push({ label: ver, value: value })
		}

		this.filterForm.enable()
	}

	public filterTableValue() {
		Util.debug('[PushUpdateModal] - filterTableValue')

		this.filterSet = false
		let list = this.devicesListRaw.slice()

		//version filter
		let version = this.filterForm.get('version').value
		if (version) {
			this.filterSet = true
			list = list.filter((el) => el.main_build_num == version)
		}

		//country filter
		let country = this.filterForm.get('country').value
		if (country) {
			this.filterSet = true
			list = list.filter((el) => el.country == country)
		}

		//os status
		let os = this.filterForm.get('os').value
		if (os) {
			this.filterSet = true
			list = list.filter((el) => el.os_ver == os)
		}

		//organization status
		let organization = this.filterForm.get('organization').value
		if (organization) {
			this.filterSet = true
			list = list.filter((el) => el.organization == organization)
		}
		this.initTable(list)
	}

	public filterText() {
		let filterWord = this.input.nativeElement.value

		filterWord = filterWord.trim().toLocaleLowerCase()

		this.devicesList.filter = filterWord
	}

	public AddRemoveItem(device: CandidateDevices) {
		if (device.selected) {
			if (!this.targetDevicesListMap.has(device.id)) {
				this.targetDevicesListMap.set(device.id, device)
				this.targetDevicesListRaw = Array.from(this.targetDevicesListMap.values())
				this.targetDevicesList = new MatTableDataSource<CandidateDevices>(this.targetDevicesListRaw)
				this.targetDevicesList.paginator = this.paginator2
				this.targetDevicesList.sort = this.sort2
			}
		} else {
			if (this.targetDevicesListMap.has(device.id)) {
				this.targetDevicesListMap.delete(device.id)
				this.targetDevicesListRaw = Array.from(this.targetDevicesListMap.values())
				this.targetDevicesList = new MatTableDataSource<CandidateDevices>(this.targetDevicesListRaw)
				this.targetDevicesList.paginator = this.paginator2
				this.targetDevicesList.sort = this.sort2
			}
			if (this.all) {
				this.all = false
			}
		}
	}

	public pushToAll() {
		if (!this.pushToAllDevicesCheckbox) {
			if (this.targetDevicesListRaw.length > 0) {
				this.all = false
				this.uncheckAll()
			}
		}
	}

	public push() {
		let request: deviceTargetUpdate = {
			updt_id: this.update.id,
			sn_list: [],
			filters: '',
		}

		if (this.pushToAllDevicesCheckbox) {
			request.sn_list = ['ALL']
		} else {
			request.sn_list = this.targetDevicesListRaw.map((item) => item.sn)
		}

		// console.log(request)

		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal
		this.currentModal.componentInstance.isExit = false
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = this.translator.instant('SW_UPDT.PUBLISH_UPDATE_MODAL.CONFIRM_MSG')

		this.currentModal.result
			.then((resp) => {
				this.activateSpinner = true
				this.pushing = { state: true, result: false, message: '' }

				setTimeout(() => {}, 4000)
				this.session
					.pushSWUpdate(request)
					.then((res) => {
						// console.log(res)
						this.activateSpinner = false
						this.pushing = { state: true, result: true, message: this.translator.instant('SW_UPDT.PUBLISH_UPDATE_MODAL.SUCCESS') }

						setTimeout(() => {
							this.activeModal.close(res)

							let header = this.translator.instant('TOAST.HEADER.SUCCESS')
							let body = this.pushing.message
							let options = new ToastOptions('success')

							this.toastService.show(header, body, false, options, 'bottom-right')
						}, 2000)
					})
					.catch((err) => {
						console.log(err)
						this.activateSpinner = false
						this.pushing = { state: true, result: false, message: this.translator.instant('SW_UPDT.PUBLISH_UPDATE_MODAL.FAIL') }

						var msg = err ? err.message : err.toString()

						setTimeout(() => {
							this.activeModal.close()

							let header = this.translator.instant('TOAST.HEADER.SUCCESS')
							let body = this.pushing.message + ' ' + msg
							let options = new ToastOptions('error')

							this.toastService.show(header, body, false, options, 'center')
						}, 2000)
					})
			})
			.catch(() => {})
	}

	public checkAll() {
		for (let device of this.devicesListRaw) {
			device.selected = true
			this.AddRemoveItem(device)
		}
	}

	public uncheckAll() {
		for (let device of this.devicesListRaw) {
			device.selected = false
			this.AddRemoveItem(device)
		}
	}

	public dismiss() {
		this.activeModal.close()
	}
}

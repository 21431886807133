<nav class="navbar navbar-expand-lg navbar-top">
	<!-- navbar-default navbar-fixed-top -->
	<div class="container-fluid">
		<!-- Brand and toggle get grouped for better mobile display -->
		<div class="navbar-header">
			<!-- TODO (click)="navCollapsed = !navCollapsed" -->
			<div class="navbar-logo">
				<!-- <img src="/assets/logo.png" alt="LuneauTech-NextSight-Visionix" >  era nel css -->
			</div>
		</div>
		<!-- Collect the nav links, forms, and other content for toggling // bootstrap -->
		<div class="collapse navbar-collapse" id="navbarSupportedContent">
			<!-- class="collapse navbar-collapse" [ngClass]="{'in': navCollapsed}" -->
			<!-- Tabs -->
			<ul class="navbar-nav tablinks me-auto">
				<!--  02-05-23 tomas-17734 when user is on profile, the menu item are hidden. refers to [hidden]="session.getRoute() == 'profile' "-->
				<li
					*ngFor="let section of sections | authenticationLevel: session.getStatus():session.getType():session.getSubProfile(); let i = index"
					class="nav-item"
					[hidden]="(!isProfileComplete && (this.session.getRoute() == 'profile' || this.session.getRoute() == 'subscription' || this.session.getRoute() == 'trial') && (this.session.isLevel1() || this.session.isLevel2() || this.session.isClinicAdmin() || this.session.isSupport())) || (this.session.getRoute() == 'agreement')">
					<!--[ngClass]="{ 'li-admin': (session.isLevel3()) }"  | authenticationLevel:session.getStatus(): session.getType(): session.getSubProfile() -->
					<a (click)="section.callback()" id="{{ section.key }}" [routerLink]="section.key" [routerLinkActive]="'selected'" [routerLinkActiveOptions]="currentSection">
						<!--[ngClass]="isCurrent(section.key)? 'selected': '' "  [ngClass]="{selected: isCurrent(section.key)}"  ng-click = $parent.navCollapsed = false; -->
						<div>
							<fa-icon [icon]="section.icon" aria-hidden="true"></fa-icon>
							<br />
							<!-- <span [ngClass]="section.glyphClass" aria-hidden="true"></span>-->
							<span class="section-name">{{ section.name | translate }}</span>
						</div>
						<!-- <div class="indicator"></div>-->
					</a>
				</li>
			</ul>

			<ul class="nav nav-right navbar-nav my-2 my-sm-0 mx-2">
				<li class="nav-item dropdown" *ngIf="!session.isLogged()">
					<select class="form-control" [(ngModel)]="session.lang" name="lang" #lang (change)="session.onLanguageClick($event, lang.value)">
						<option *ngFor="let lang of session.languages" value="{{ lang.short }}" id="{{ lang.label }}">{{ lang.name }}</option>
					</select>
				</li>

				<li class="nav-item" *ngIf="session.isLogged() && (session.isOptician() || session.isSupport()) " [hidden]="(!isProfileComplete) || (this.session.getRoute() == 'agreement')">
					<a class="nav-link" (click)="canvasService.openCanvas()" id="notification"
						><fa-icon [icon]="faBell" aria-hidden="true" ngbTooltip="Notifications"></fa-icon>
						<span class="badge blink rounded-pill bg-danger" *ngIf="counter>0">{{counter}}</span>
					</a>
				</li>

				<li class="nav-item" *ngIf="session.isLogged() && session.isOptician()" [hidden]="(!isProfileComplete ) || (this.session.getRoute() == 'agreement')">
					<a class="nav-link" (click)="onSectionClick('subscription')" id="subscription">
						<fa-icon [icon]="faWallet" aria-hidden="true"></fa-icon>
						<span class="section-name">{{ 'NAVBAR.SUBSCRIPTION' | translate }}</span>
						<span class="badge rounded-pill bg-danger" *ngIf="serviceCounter>0">{{serviceCounter}}</span>
					</a>
				</li>

				<li class="nav-item" *ngIf="session.isLogged()" [hidden]="this.session.getRoute() == 'agreement'">
					<a class="nav-link" (click)="onSectionClick('profile')" id="profile">
						<fa-icon [icon]="faUserCircle" aria-hidden="true"></fa-icon>
						<span class="section-name">{{ 'NAVBAR.PROFILE' | translate }}</span>
						<!-- <span class="section-name">{{ session.getUserInitials() }}</span> -->
					</a>
				</li>

				<li class="nav-item" *ngIf="session.isLogged()">
					<a class="nav-link" (click)="logout()" title="logout">
						<fa-icon [icon]="faRightFromBracket" aria-hidden="true"></fa-icon>
						<span class="section-name">{{ 'BUTTONS.LOGOUT' | translate }}</span>
					</a>
				</li>
			</ul>

			<!--
        <div *ngIf="session.isLogged()" class="my-2 my-lg-0" >
          <label class="mr-sm-2">USER - TODO</label>
          <div (click)="session.logout()" class="my-2 my-sm-0" title="logout" >
            <fa-icon [icon]="faUnlock" aria-hidden="true"></fa-icon>
          </div>
        </div>
      -->
		</div>
		<!-- /.navbar-collapse -->
	</div>
	<!-- /.container-fluid -->
</nav>

<div class="modal-panel py-2 px-5">
	<!-- <div class="panel-heading">
		<label class="panel-title">&nbsp;&nbsp;{{"FOOTER.ABOUT" | translate}}</label>
	</div> -->

	<div class="full-container about">
		<div class="row">
			<div class="full-container">
				<p class="about_logo navicon">&nbsp;</p>
				<!--						<img src="/assets/Visionix_RVB_horizontal_orange_baseline.png" alt="Visionix" width="230px" /> 	-->
			</div>
		</div>

		<h3 class="text-center mt-4">NEXUS Platform</h3>

		<div class="row mt-5">
			<div class="col-4"></div>
			<div class="col-2">
				<div class="about_farm navicon"></div>
			</div>
			<div class="col-4 ltg_farm">
				<div class="address">
					<p>
						<span>Visionix</span><br />
						<span>2 Rue Roger Bonnet</span><br />
						<span>27340 Pont-de-l'Arche</span><br />
						<span>France</span>
					</p>
				</div>
			</div>
			<div class="col-2"></div>
		</div>

		<div class="mt-5 text-center">
			<p>LocalFe Build: {{getBuild()}}</p>
			<div *ngIf="!session.isProduction()">
				<div class="mt-1" *ngFor="let line of nexusBuilds"><span>{{line.item}} : </span> <span class="fw-bold">{{line.build}}</span></div>
			</div>
		</div>
	</div>
</div>

import { Component, OnInit, Input } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { CategoriesController } from '../../component/categories/categories.controller'
import { TopoExam, WfExam, SubjectiveExam, LensmeterExam } from '../../models/exam.model'

@Component({
	selector: 'refraction',
	templateUrl: './refraction.component.html',
	styleUrls: ['./refraction.component.scss'],
})
export class RefractionComponent implements OnInit {
	@Input() topoR: TopoExam
	@Input() topoL: TopoExam
	@Input() wfR: WfExam
	@Input() wfL: WfExam
	@Input() sbjR: SubjectiveExam
	@Input() sbjL: SubjectiveExam
	@Input() sbjB: SubjectiveExam
	@Input() lsmR: LensmeterExam
	@Input() lsmL: LensmeterExam
	@Input() lsmB: LensmeterExam

	@Input('modal') modal: CategoriesController // the parent

	constructor(public translator: TranslateService) {
		/*
    this.topoR = new TopoExam();
    this.topoL= new TopoExam();
    this.wfR = new WfExam();
    this.wfL = new WfExam;
    this.sbjR = new  SubjectiveExam();
    this.sbjL = new  SubjectiveExam();
    this.sbjB = new SubjectiveExam();
    this.lsmR = new  LensmeterExam();
    this.lsmL = new  LensmeterExam();
    this.lsmB = new  LensmeterExam();
    */
	}

	ngOnInit(): void {}
}

import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2 } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { NgbModal, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule, NgForm } from '@angular/forms'

import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { UpdatesComponent } from './updates.component'

import { Device, SwUpdate } from '../../models/device.model'
import { Util } from '../../models/util.model'

var providers = [NgbModal, FormsModule]

@Component({
	selector: 'updates-modal',
	templateUrl: './updates.modal.html',
	styleUrls: ['./updates.modal.scss'],
})
export class UpdatesModalContent implements OnInit {
	@Input() parent: UpdatesComponent
	@Input() currUpdate: SwUpdate
	@ViewChild('binary') binary: ElementRef
	@ViewChild('pwd') pwd: ElementRef
	@ViewChild('build_num') build_num: ElementRef

	flagDone: boolean
	submitDisable: boolean

	fileInvalid: boolean

	// dispPassword: string
	// wrongPwd: boolean

	snChoice: string
	snList: string
	SN_NONE: string
	SN_ALL: string
	SN_SINGLE: string
	SN_LIST: string

	SEP1: string
	SEP2: string

	faTriangleExclamation = faTriangleExclamation

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		private translator: TranslateService,
		private renderer: Renderer2
	) {
		this.flagDone = false
		// this.wrongPwd = false
		this.fileInvalid = true
		this.submitDisable = false

		this.SN_NONE = 'NONE'
		this.SN_ALL = 'all'
		this.SN_SINGLE = 'single'
		this.SN_LIST = 'list'

		this.SEP1 = ';'
		this.SEP2 = ','

		this.snList = ''
		this.snChoice = 'NONE'
	}

	ngOnInit(): void {
		// console.log(this.currUpdate);

		if (this.parent.currentAction == 'create') {
			Util.debug('create')
		} else if (this.parent.currentAction == 'modify') {
			Util.debug('modify')

			if (this.currUpdate.sn != null) {
				if (this.currUpdate.sn.indexOf(this.SEP1) > 0 || this.currUpdate.sn.indexOf(this.SEP2) > 0) {
					this.snChoice = this.SN_LIST
					this.snList = this.currUpdate.sn
				} else if (this.currUpdate.sn == '') {
					this.snChoice = this.SN_ALL
				} else if (this.currUpdate.sn == this.SN_NONE) {
					this.snChoice = this.SN_NONE
				} else {
					this.snChoice = this.SN_SINGLE
					this.snList = this.currUpdate.sn
				}
			}

			Util.debug(this.snChoice)
		}
	}

	deleteSN() {
		this.currUpdate.sn = null
		this.snChoice = ''
	}

	submit(form) {
		// this.wrongPwd = false
		// console.log(form.valid);

		if (form.valid) {
			var refresh = () => {
				// this.wrongPwd = false
				this.flagDone = true
				this.activeModal.close(this.flagDone)
				alert('Request submit success')
			}

			var loaderOff = () => {
				this.submitDisable = false
			}

			if (!(this.session.isGod() || this.session.isManager())) {
				console.log('(updates) forbidden, action: ' + this.parent.currentAction)
				return
			}

			// if (!this.session.checkNSPwd(this.dispPassword)) {
			// 	Util.debug('UpdatesComp - pwd dispositiva non corretta')
			// 	this.alert(this.pwd)
			// 	this.wrongPwd = true
			// 	this.submitDisable = false
			// 	return false
			// }

			if (this.parent.currentAction == 'create') {
				var filename = this.binary.nativeElement.files[0]

				if (filename == null) {
					// alert('please provide a file to upload');

					this.alert(this.binary)

					return false
				}

				this.submitDisable = true

				console.log('(Updt) got file: ' + filename.name)

				this.currUpdate.url = filename.name

				var isValid = false

				var usrFileName = filename.name.toLowerCase()
				if (
					usrFileName.indexOf('.exe') > 0 ||
					usrFileName.indexOf('.apk') > 0 ||
					usrFileName.indexOf('.gz') > 0 ||
					usrFileName.indexOf('.zip') > 0
				) {
					isValid = true
				}

				if (!isValid) {
					// TODO, tradurre
					var msg = 'update: ko file: ' + filename.name + '. Only .apk, .exe, .gz or .zip are admitted'

					console.log(msg)
					alert(msg)
					this.alert(this.binary)

					return false
				}

				var binaryPack: any // ArrayBuffer;
				var r = new FileReader()
				r.readAsDataURL(filename)

				// console.log(r)

				r.onloadend = (function (currSession, currUpdt) {
					return function (e) {
						//binaryPack = e.target.result;
						//binaryPack = e.target.result.split(",", 2)[1]; // 18.05.2021 fix, you clean it up // bonifica
						binaryPack = String(e.target.result).split(',', 2)[1] // 03.02.2022 risolve warning su split

						// console.log(binaryPack)

						currSession
							.createSwUpdate(currUpdt, binaryPack)
							.then(refresh)
							.catch((err) => {
								var msg = err ? err.message : err.toString()
								console.log('(Updt) KO ' + err.error.error)
								// console.log(err)

								//this.btnDisabled = false;  // spegne il loader  // no, non funziona
								//   stopLoader;  // 03.02.2022

								alert('Error: ' + msg)
							})
							.finally(loaderOff)
					}
				})(this.session, this.currUpdate)
			} else if (this.parent.currentAction == 'modify') {
				console.log(this.currUpdate)

				this.session
					.updateSwPack(this.currUpdate)
					.then(refresh)
					.catch((err) => {
						this.submitDisable
						console.log(err)
					})
			}
		}
	}

	dismiss() {
		this.flagDone = true
		this.activeModal.close(this.flagDone)
	}

	checkBinary() {
		// console.log(this.binary.nativeElement.value);

		if (this.binary.nativeElement.value != '') {
			this.fileInvalid = false

			//inserisco automaticamente la versione stringa
			let name = this.binary.nativeElement.value
			const regex = /[0-9](.)[0-9](.)[0-9]/g
			const version = name.match(regex)
			this.currUpdate.build_ver = version[0]

			const regexn = /[0-9]/g
			const number = version[0].match(regexn)
			this.currUpdate.build_num = number[0] + 0 + 0 + number[1] + 0 + 0 + number[2]
			// console.log(number[0] + 0 + 0 + number[1] + 0 + 0 + number[2]);
		} else {
			this.fileInvalid = true
		}
	}

	alert(elem) {
		this.renderer.addClass(elem.nativeElement, 'headshake')

		setTimeout(() => {
			this.renderer.removeClass(elem.nativeElement, 'headshake')
		}, 1000)
	}
}

<div class="panel panel-modal panel-default ng-cloak">
	<!-- modal-panel -->

	<div class="container">
		<table mat-table #table [dataSource]="devicesList" style="width: 100%" *ngIf="devicesList.length">
			<ng-container matColumnDef="device id">
				<th mat-header-cell *matHeaderCellDef>Device ID</th>
				<td mat-cell *matCellDef="let element">{{element.device_id}}</td>
			</ng-container>

			<ng-container matColumnDef="model">
				<th mat-header-cell *matHeaderCellDef>Model</th>
				<td mat-cell *matCellDef="let element">{{element.model}}</td>
			</ng-container>

			<ng-container matColumnDef="sn">
				<th mat-header-cell *matHeaderCellDef>SN</th>
				<td mat-cell *matCellDef="let element">{{element.sn}}</td>
			</ng-container>

			<ng-container matColumnDef="main_build">
				<th mat-header-cell *matHeaderCellDef>Main build</th>
				<td mat-cell *matCellDef="let element">{{element.main_build}}</td>
			</ng-container>

			<ng-container matColumnDef="caller ip">
				<th mat-header-cell *matHeaderCellDef>Caller IP</th>
				<td mat-cell *matCellDef="let element">{{element.caller_ip}}</td>
			</ng-container>

			<ng-container matColumnDef="first_use">
				<th mat-header-cell *matHeaderCellDef>First use</th>
				<td mat-cell *matCellDef="let element">{{session.formatDate(element.first_use)}}</td>
			</ng-container>

			<ng-container matColumnDef="last_use">
				<th mat-header-cell *matHeaderCellDef>Last use</th>
				<td mat-cell *matCellDef="let element">{{session.formatDate(element.last_use)}}</td>
			</ng-container>

			<ng-container matColumnDef="time_log_request">
				<th mat-header-cell *matHeaderCellDef>Log Request</th>
				<td mat-cell *matCellDef="let element">{{session.formatDate(element.time_log_request)}}</td>
			</ng-container>

			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row (click)="clicked(row)" [class.demo-row-is-clicked]="row.device_id== clickedRow.device_id" mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		</table>

		<div *ngIf="!devicesList.length">
			<h4>{{ 'USER_DEVICE.NO_DEVICES_AV' | translate }}</h4>
		</div>

		<div class="mt-3">
			<h4>{{ 'USER_DEVICE.REQ_LOG_TITLE' | translate }}</h4>
		</div>

		<div *ngIf="clickedRow.device_id===0">
			<p>{{ 'USER_DEVICE.NO_DEVICES_SEL' | translate }}</p>
		</div>

		<div>
			<p *ngIf="!disabledInput && !wrongDevice">{{ 'USER_DEVICE.REQ_LOG_Q' | translate }} <b>{{clickedRow.model}}</b> {{ 'USER_DEVICE.DEVICE_SN' | translate }} <b>{{clickedRow.sn}}</b> ?</p>
		</div>

		<div *ngIf="disabledInput && clickedRow.device_id!=0">
			<p>{{ 'USER_DEVICE.LOGS' | translate }} <b>{{clickedRow.model}}</b> {{ 'USER_DEVICE.DEVICE_SN' | translate }} <b>{{clickedRow.sn}}</b> {{ 'USER_DEVICE.REQ_Y' | translate }}</p>
		</div>

		<div *ngIf="wrongDevice">
			<p>{{ 'USER_DEVICE.LOGS' | translate }} <b>{{clickedRow.model}}</b> {{ 'USER_DEVICE.DEVICE_SN' | translate }} <b>{{clickedRow.sn}}</b> {{ 'USER_DEVICE.NOT_AVAL' | translate }}</p>
		</div>

		<div>
			<form>
				<div class="row">
					<!-- <div class="col-sm-4 position-relative">
						<mat-form-field class="example-full-width" appearance="fill" [ngClass]="{ 'error' : wrongPwd}">
							<mat-label>{{ 'MESSAGE.MNGR_PWD_HINT' | translate }} </mat-label>
							<input
								matInput
								placeholder="Type the password"
								[type]="hide ? 'password' : 'text'"
								[formControl]="psswCtrl"
								[(ngModel)]="dispPassword" />
						</mat-form-field>

						<p class="position-absolute error-message" [ngClass]="{ 'error' : wrongPwd}">
							<span>{{ 'MESSAGE.WRONG_PWD' | translate }}</span>

							<span *ngIf="false">Password is <strong>required</strong></span>
						</p>
					</div> -->

					<div class="col-sm-1 d-flex align-items-center">
						<button type="submit" class="btn btn-primary" (click)="sendLogRequest()" [disabled]="disabledInput || wrongDevice">{{ 'BUTTONS.SUBMIT' | translate }}</button>
					</div>

					<div class="col-sm-6 d-flex align-items-center justify-content-center">
						<div class="alert alert-success mb-0" [ngClass]="{ 'show' : sentSuccess }" role="alert">
							<div>{{ 'USER_DEVICE.SENT_SUCCESS' | translate }}</div>
						</div>
					</div>

					<div class="col-sm-1 d-flex align-items-center justify-content-end">
						<button type="clear" class="btn btn-secondary" (click)="activeModal.dismiss('undo')">{{ 'BUTTONS.CLOSE' | translate }}</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>

<div *ngIf="!loading; else loadingSpinner">
	<p class="fw-bold">{{ 'ACTIVITIES.AVAILABILITY_HANDLER.SECTION_TITLE' | translate | uppercase }}</p>
	<p class="fst-italic">{{ 'ACTIVITIES.AVAILABILITY_HANDLER.SECTION_SUBTITLE' | translate }}</p>

	<div *ngIf="unavailabilityRanges; else unavailabilityPicker">
		<div class="d-flex align-items-center mb-3" *ngFor="let unavailabilityRange of unavailabilityRangesForDatePicker">
			<fa-icon class="faCalendarXmark me-3" [icon]="faCalendarXmark" aria-hidden="true"></fa-icon>
			<p class="fw-bold mb-0 me-3">{{ 'ACTIVITIES.AVAILABILITY_HANDLER.UNAVAILABLE' | translate | uppercase }}</p>
			<p class="fw-bold mb-0 me-3">{{ 'MISC.FROM' | translate }}</p>
			<input
				class="calendar-input form-control me-3"
				[readonly]="isInUnavailableRange()"
				(click)="!isInUnavailableRange() && d1.toggle()"
				type="text"
				ngbDatepicker
				#c1="ngModel"
				#d1="ngbDatepicker"
				placeholder="yyyy-mm-dd"
				[(ngModel)]="fromDateChosen"
				[minDate]="isInUnavailableRange() ? null : tomorrowDateNgb" />
			<p class="fw-bold mb-0 me-3">{{ 'MISC.TO' | translate }}</p>
			<input class="calendar-input form-control me-3" (click)="d2.toggle()" type="text" ngbDatepicker #c2="ngModel" #d2="ngbDatepicker" placeholder="yyyy-mm-dd" [(ngModel)]="toDateChosen" [minDate]="isInUnavailableRange() ? tomorrowDateNgb : fromDateChosen || tomorrowDateNgb" />
			<button class="btn btn-primary me-3" [disabled]="(getEquality(fromDateChosen, unavailabilityRange.startDate) && getEquality(toDateChosen, unavailabilityRange.endDate)) || c1.invalid || c2.invalid" (click)="onSaveUnavailability(unavailabilityRange.id)">{{ 'BUTTONS.SAVE' | translate }}</button>
			<button class="btn btn-primary" [disabled]="getEquality(unavailabilityRange.endDate, tomorrowDateNgb)" (click)="onDeleteUnavailability(unavailabilityRange.id)">{{isInUnavailableRange() ? ('BUTTONS.INTERRUPT' | translate) : ('BUTTONS.DELETE' | translate)}}</button>
		</div>
	</div>

	<ng-template #unavailabilityPicker>
		<div class="d-flex align-items-center mb-3">
			<fa-icon class="faCalendarXmark me-3" [icon]="faCalendarXmark" aria-hidden="true"></fa-icon>
			<p class="fw-bold mb-0 me-3">{{ 'ACTIVITIES.AVAILABILITY_HANDLER.UNAVAILABLE' | translate | uppercase }}</p>
			<p class="fw-bold mb-0 me-3">{{ 'MISC.FROM' | translate }}</p>
			<input class="calendar-input form-control me-3" (click)="d1.toggle()" type="text" ngbDatepicker #c1="ngModel" #d1="ngbDatepicker" placeholder="yyyy-mm-dd" [(ngModel)]="fromDateChosen" [minDate]="tomorrowDateNgb" />
			<p class="fw-bold mb-0 me-3">{{ 'MISC.TO' | translate }}</p>
			<input class="calendar-input form-control me-3" (click)="d2.toggle()" type="text" ngbDatepicker #c2="ngModel" #d2="ngbDatepicker" placeholder="yyyy-mm-dd" [(ngModel)]="toDateChosen" [minDate]="fromDateChosen || tomorrowDateNgb" />
			<button class="btn btn-primary me-3" [disabled]="(!fromDateChosen || c1.invalid || !toDateChosen || c2.invalid)" (click)="onSaveUnavailability()">{{ 'BUTTONS.SAVE' | translate }}</button>
		</div>
	</ng-template>
</div>

<ng-template #loadingSpinner>
	<div class="loading-spinner">
		<fa-icon [icon]="faSpinner"></fa-icon>
	</div>
</ng-template>

<div class="loading-container" [hidden]="!dataService.isLoadingReportList() ">
	<div class="tail-spin">&nbsp;</div>
</div>

<div>
	<!-- <div class="topview container-fluid" [hidden]="!dataService.isLoadingReportList()">
  <mat-spinner></mat-spinner>{{"MISC.LOADING" | translate}}
</div> -->
	<div class="topview report-list-container">
		<!-- <div class="modal-panel ng-cloak">-->
		<div class="row">
			<div class="full-container">
				<div class="loading-container-small" *ngIf="isGeneratingPdf">
					<div>
						<h2 class="text-center">Generating pdf...</h2>
					</div>
					<div class="tail-spin">&nbsp;</div>
				</div>

				<div class="panel panel-default position-relative">
					<div class="btn-close-container-pos">
						<button type="button" class="btn-close" aria-label="Close" (click)="passBack()">
							<!-- (click)="activeModal.dismiss('Cross click')" -->
						</button>
					</div>

					<div class="panel-heading">
						<label>{{"REPORT.TITLE_LIST" | translate}} - {{parent.patientInfo}}</label>
						<!-- patient {{parent.currentPatient.code}} -->
					</div>
					<div class="panel-body" [hidden]="!dataService.hasLoadedReportList()">
						<!-- unica table per tutti i profili -->

						<table mat-table [dataSource]="reportListTable" matSort style="width: 100%">
							<!-- User NAme Column -->
							<ng-container matColumnDef="report_id">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"REPORT.REPORT_ID" | translate}}</th>
								<td mat-cell *matCellDef="let element">{{ element.id }}</td>
							</ng-container>

							<ng-container matColumnDef="status">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"REPORT.STATUS" | translate}}</th>
								<td mat-cell *matCellDef="let element">{{ element.getStatusDescr() }}</td>
							</ng-container>

							<ng-container matColumnDef="creation_date">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"REPORT.REPORT_DATE" | translate}}</th>
								<td mat-cell *matCellDef="let element">{{session.formatDateTime( element.creation_date) }}</td>
							</ng-container>

							<ng-container matColumnDef="diagn_group">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>DIAGN. GROUP</th>
								<td mat-cell *matCellDef="let element">{{ element.getDiagnGroupDescr() }}</td>
							</ng-container>

							<ng-container matColumnDef="diagnosis">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"REPORT.SECTIONS" | translate}}</th>
								<td mat-cell *matCellDef="let element">{{ element.getSections() }}</td>
							</ng-container>

							<ng-container matColumnDef="created_by">
								<th mat-header-cell *matHeaderCellDef mat-sort-header>{{"REPORT.SPECIALIST" | translate}}</th>
								<td mat-cell *matCellDef="let element">{{ element.refDisplayName }}</td>
							</ng-container>

							<ng-container matColumnDef="download">
								<th mat-header-cell *matHeaderCellDef>{{ 'BUTTONS.DOWNLOAD' | translate }}</th>
								<td mat-cell *matCellDef="let element">
									<a (click)="downloadReport(element.id, element.creation_date)" ngbTooltip="{{ 'BUTTONS.DOWNLOAD' | translate }}">
										<fa-icon [icon]="faSave" style="color: #717ace"></fa-icon>
									</a>
								</td>
							</ng-container>

							<ng-container matColumnDef="filter">
								<th mat-header-cell *matHeaderCellDef class="text-end">
									<mat-form-field appearance="outline">
										<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
									</mat-form-field>
								</th>
								<td mat-cell *matCellDef="let element"></td>
							</ng-container>

							<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
							<tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{isNew : (session.isLevel1() === true || session.isLevel3() === true) && row.status === 1}"></tr>
						</table>

						<mat-paginator [pageSizeOptions]="[ 10, 15, 20]" showFirstLastButtons> </mat-paginator>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

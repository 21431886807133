import { Component } from '@angular/core'
import { faCalendarXmark, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NgbDateStruct, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModal } from '../../confirm/confirm.modal'
import { TranslateService } from '@ngx-translate/core'
import { SessionService } from 'src/app/service/session.service'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'
import { Util } from 'src/app/models/util.model'
const isEqual = require('lodash.isequal')

@Component({
	selector: 'availability-handler',
	templateUrl: './availability-handler.component.html',
	styleUrls: ['./availability-handler.component.scss'],
})
export class AvailabilityHandlerComponent {
	vacancies: {
		id?: number
		from_date: string
		to_date: string
	}[]
	fromDateChosen: NgbDateStruct
	toDateChosen: NgbDateStruct
	tomorrowDate: Date
	tomorrowDateNgb: NgbDateStruct
	unavailabilityRanges: { startDate: Date; endDate: Date; id: number }[]
	unavailabilityRangesForDatePicker: { startDate: NgbDateStruct; endDate: NgbDateStruct; id: number }[]
	currentModal: NgbModalRef
	loading: boolean
	faCalendarXmark = faCalendarXmark
	faSpinner = faSpinner

	constructor(public modalService: NgbModal, public translator: TranslateService, public session: SessionService, private toastService: AppToastService) {
		this.tomorrowDate = new Date(new Date().valueOf() + 1000 * 3600 * 24)
		this.tomorrowDateNgb ={ year: this.tomorrowDate.getFullYear(), month: this.tomorrowDate.getMonth() + 1, day: this.tomorrowDate.getDate() }
		this.initVacancies()
		this.loading = false
	}

	initVacancies() {
		this.unavailabilityRanges = null
		this.vacancies = this.session.getVacancies()
		if (this.vacancies.length > 0) {
			const unavailabilityStartDate = new Date(this.vacancies[0].from_date)
			const unavailabilityEndDate = new Date(this.vacancies[0].to_date)
			const id = this.vacancies[0].id

			this.unavailabilityRanges = [{ startDate: unavailabilityStartDate, endDate: unavailabilityEndDate, id: this.vacancies[0].id }]
			this.unavailabilityRangesForDatePicker = [
				{
					startDate: { year: unavailabilityStartDate.getFullYear(), month: unavailabilityStartDate.getMonth() + 1, day: unavailabilityStartDate.getUTCDate() },
					endDate: { year: unavailabilityEndDate.getFullYear(), month: unavailabilityEndDate.getMonth() + 1, day: unavailabilityEndDate.getUTCDate() },
					id,
				},
			]

			this.fromDateChosen = this.unavailabilityRanges !== null && this.unavailabilityRangesForDatePicker[0].startDate
			this.toDateChosen = this.unavailabilityRanges !== null && this.unavailabilityRangesForDatePicker[0].endDate
		}
	}

	onSaveAvailability() {
		const tomorrowDateFormattedNotification = this.session.formatDate(this.tomorrowDate)

		const text = this.translator.instant('ACTIVITIES.AVAILABILITY_HANDLER.CONFIRM_AVAILABLE_MSG', { tomorrowDate: tomorrowDateFormattedNotification })

		this.openConfirmModal(text).result.then(() => {
			this.loading = true
			const fromDateFormattedRequest = Util.ngbToString(this.fromDateChosen)
			const toDateFormattedRequest = Util.ngbToString({ year: this.tomorrowDate.getFullYear(), month: this.tomorrowDate.getMonth() + 1, day: this.tomorrowDate.getDate() })

			this.session
				.updateVacancies({ from_date: fromDateFormattedRequest, to_date: toDateFormattedRequest, id: this.unavailabilityRanges[0].id }, true)
				.then((res) => {
					this.showToastNotification('success')
				})
				.catch((err) => {
					this.showToastNotification('error')
				})
				.finally(() => {
					this.initVacancies()
					this.loading = false
				})
		})
	}

	onSaveUnavailability(id?: number) {
		const fromDateFormattedRequest = Util.ngbToString(this.fromDateChosen)
		const toDateFormattedRequest = Util.ngbToString(this.toDateChosen)
		const fromDateFormattedNotification = this.session.formatDate(
			new Date(this.fromDateChosen.year, this.fromDateChosen.month - 1, this.fromDateChosen.day, 0, 0, 0, 0)
		)
		const toDateFormattedNotification = this.session.formatDate(new Date(this.toDateChosen.year, this.toDateChosen.month - 1, this.toDateChosen.day, 0, 0, 0, 0))
		const text = this.translator.instant('ACTIVITIES.AVAILABILITY_HANDLER.CONFIRM_UNAVAILABLE_MSG', {
			fromDate: fromDateFormattedNotification,
			toDate: toDateFormattedNotification,
		})

		this.openConfirmModal(text).result.then(() => {
			this.loading = true
			const vacancyObj = id
				? { from_date: fromDateFormattedRequest, to_date: toDateFormattedRequest, id }
				: { from_date: fromDateFormattedRequest, to_date: toDateFormattedRequest }
			this.session
				.updateVacancies(vacancyObj, id ? true : false)
				.then((res) => {
					this.showToastNotification('success')
				})
				.catch((err) => {
					this.showToastNotification('error')
				})
				.finally(() => {
					this.initVacancies()
					this.loading = false
				})
		})
	}

	onDeleteUnavailability(id: number) {
		if (this.isInUnavailableRange()) {
			return this.onSaveAvailability()
		}

		const text = this.translator.instant('ACTIVITIES.AVAILABILITY_HANDLER.DELETE_UNAVAILABILITY_MSG')
		this.openConfirmModal(text).result.then(() => {
			this.loading = true
			this.session
				.deleteVacancy(id)
				.then((res) => {
					this.fromDateChosen = null
					this.toDateChosen = null
					this.showToastNotification('success')
				})
				.catch((err) => {
					this.showToastNotification('error')
				})
				.finally(() => {
					this.initVacancies()
					this.loading = false
				})
		})
	}

	isInUnavailableRange() {
		if (!this.unavailabilityRanges) return
		const today = new Date()
		return today >= this.unavailabilityRanges[0].startDate && today <= this.unavailabilityRanges[0].endDate
	}

	openConfirmModal(text: string) {
		this.currentModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' })
		this.currentModal.componentInstance.isQuest = true
		this.currentModal.componentInstance.warnText = text

		return this.currentModal
	}

	showToastNotification(type: 'success' | 'error') {
		const header = this.translator.instant(`TOAST.HEADER.${type === 'success' ? 'SUCCESS' : 'ERROR'}`)
		const body = this.translator.instant(`TOAST.NOTIFICATIONS.${type === 'success' ? 'AVAILABILITY_UPDATED' : 'SOMETHING_WRONG'}`)
		const options = new ToastOptions(type === 'success' ? 'success' : 'error')

		this.toastService.show(header, body, false, options, 'bottom-right')
	}

	getEquality(obj1: Object, obj2: Object) {
		return isEqual(obj1, obj2)
	}
}

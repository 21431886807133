import { Component, Input, OnInit } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
//import { TranslateService} from '@ngx-translate/core';

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { User } from '../../models/user.model'

import { Util, TeleParams } from '../../models/util.model'

@Component({
	selector: 'telerefract',
	templateUrl: './telerefract.component.html',
	styleUrls: ['./telerefract.component.scss'],
})
export class TelerefractComponent implements OnInit {
	teleParams: TeleParams
	teleRefrForm: FormGroup

	constructor(public session: SessionService, private fb: FormBuilder) {
		let user = this.session.getCurrentUser()

		this.teleParams = new TeleParams()

		// 07.10.2022
		//let singleUrl = 'https://tele-viz.com:8000/Auth/TransferLoginFromNexus'
		let singleUrl = '/Auth/TransferLoginFromNexus' // 02.11.2022 spostata la parte fissa di url sul config

		//this.teleParams.url = 'http://dev-api.nextsight.info/refraction/refraction.php';
		//this.teleParams.chatUrl = 'http://dev-api.nextsight.info/refraction/refraction.php?pg=chat';
		//this.teleParams.boothUrl = 'http://dev-api.nextsight.info/refraction/refraction.php?pg=booth';

		this.teleParams.url = Config.telerefractUrl + singleUrl
		this.teleParams.chatUrl = Config.telerefractUrl + singleUrl
		this.teleParams.boothUrl = Config.telerefractUrl + singleUrl + '?typeOfUser=Booth' // 19.12.2022 aggiunto parametro

		//this.teleParams.token = user.token; // c'e' anche il Bearer mmqxuG_qOLAsvZHIU8HtIW-eQ9-7mUxwWFkB1Yhdy-c
		let shortTok = user.token.substring(7)
		this.teleParams.token = shortTok

		this.teleParams.name = user.getFullName()
		this.teleParams.organization = user.getMainAddress().organization
		this.teleParams.user_id = user.user_id
		this.teleParams.role = user.role // 07.09.2022

		this.teleParams.groupId = user.groupId // 13.03.2023
		this.teleParams.subtype = user.user_subtype

		this.teleParams.targetOrganization = ''
		this.teleParams.targetGroupId = ''

		this.teleRefrForm = new FormGroup({
			rbUrl: new FormControl(this.session.isDoctor() ? 'URL' : 'CHAT'),
			chaturl: new FormControl(this.teleParams.chatUrl, Validators.required),
			boothurl: new FormControl(this.teleParams.boothUrl, Validators.required),
			url: new FormControl(this.teleParams.url, Validators.required),
			name: new FormControl(this.teleParams.name),
			org: new FormControl(this.teleParams.organization),
			usrid: new FormControl(this.teleParams.user_id),
			role: new FormControl(this.teleParams.role),
			groupId: new FormControl(this.teleParams.groupId),
			subtype: new FormControl(this.teleParams.subtype),
			targetOrganization: new FormControl(''),
			tGroupId: new FormControl(''),
		})
	}

	ngOnInit(): void {
		// 23.09.2022 set a radio default option
		/*
    if(this.session.isDoctor()){
      this.teleRefrForm.value.rbUrl = "URL";
    } else {
      this.teleRefrForm.value.rbUrl = "CHAT";
    }
    */
	}

	openTelePage(type) {
		let teleUrl = this.teleParams.url
		let chatUrl = this.teleParams.chatUrl
		let boothUrl = this.teleParams.boothUrl

		let targetName = '_blankTeleRefr' // usata nel mapForm e nella window.open

		if (this.teleRefrForm.value.rbUrl == '') {
			alert('Please fill the url field with a valid value.')
			return
		}

		switch (this.teleRefrForm.value.rbUrl) {
			case 'URL': {
				teleUrl = this.teleRefrForm.value.url
				break
			}
			case 'CHAT': {
				teleUrl = this.teleRefrForm.value.chaturl
				break
			}
			case 'BOOTH': {
				teleUrl = this.teleRefrForm.value.boothurl
				break
			}
			default: {
				//alert('Current configuration DEFAUILT')
				break
			}
		}

		//    if (teleUrl == '') {
		//      alert('Please fill the url field with a valid value.');
		//      return;
		//    }

		/*
		if (type == 'GET') {
			let fullUrl =
				teleUrl +
				'?token=' +
				this.teleParams.token +
				'&name=' +
				this.teleParams.name +
				'&organization=' +
				this.teleParams.organization +
				'&role=' +
				this.teleParams.role +
				'&user_id=' +
				this.teleParams.user_id

			window.open(fullUrl, targetName).focus()
		} else 
    */
		{
			// 02.11.2022 TODO, usare session.myExternalPost() ?

			var mapForm = document.createElement('form')
			mapForm.target = targetName // "Map";
			mapForm.method = 'post' // or "POST" if appropriate
			mapForm.action = teleUrl

			let mapInput0 = document.createElement('input')

			mapInput0.type = 'text' // oppure
			mapInput0.setAttribute('type', 'text')

			mapInput0.name = 'token'
			mapInput0.value = this.teleParams.token
			mapForm.appendChild(mapInput0)

			let mapInput1 = document.createElement('input')
			mapInput1.type = 'text'
			mapInput1.name = 'name'
			mapInput1.value = this.teleParams.name
			mapForm.appendChild(mapInput1)

			let mapInput2 = document.createElement('input')
			mapInput2.type = 'text'
			mapInput2.name = 'organization'
			mapInput2.value = this.teleParams.organization
			mapForm.appendChild(mapInput2)

			let mapInput3 = document.createElement('input')
			mapInput3.type = 'text'
			mapInput3.name = 'userId'
			mapInput3.value = '' + this.teleParams.user_id
			mapForm.appendChild(mapInput3)

			// 08.09.2022
			let mapInput4 = document.createElement('input')
			mapInput4.type = 'text'
			mapInput4.name = 'role'
			mapInput4.value = '' + this.teleParams.role
			mapForm.appendChild(mapInput4)

			// 13.03.2023
			let mapInput5 = document.createElement('input')
			mapInput5.type = 'text'
			mapInput5.name = 'groupId'
			mapInput5.value = '' + this.teleParams.groupId
			mapForm.appendChild(mapInput5)

			// 13.03.2023
			let mapInput6 = document.createElement('input')
			mapInput6.type = 'text'
			mapInput6.name = 'subtype'
			mapInput6.value = '' + this.teleParams.subtype
			mapForm.appendChild(mapInput6)

			let mapInput7 = document.createElement('input')
			mapInput7.type = 'text'
			mapInput7.name = 'targetOrganization'
			mapInput7.value = '' + this.teleRefrForm.get('targetOrganization').value
			mapForm.appendChild(mapInput7)

			let mapInput8 = document.createElement('input')
			mapInput8.type = 'text'
			mapInput8.name = 'targetGroupId'
			mapInput8.value = '' + this.teleRefrForm.get('tGroupId').value
			mapForm.appendChild(mapInput8)

			document.body.appendChild(mapForm)

			let map = window.open('', targetName).focus()

			if (map != null) {
				Util.debug('(telerefract) test post 1...')
				mapForm.submit()
			} else {
				Util.debug('(telerefract) test post 2...')
				mapForm.submit() // ok lo stesso :)
			}

			document.body.removeChild(mapForm)
		}
	}
}

import { environment } from 'src/environments/environment'

export class Toast {
	header: string
	body: string
	canvas: boolean // if true the toast goes on cavas
	options: ToastOptions // these options are used on toast html
	date?: Date // opzionale, solo per i toast all'interno del canvas

	constructor(header: string, body: string, canvas: boolean, options: ToastOptions, date?: Date) {
		this.header = header
		this.body = body
		this.canvas = canvas
		this.options = options

		if (date) {
			this.date = date
		} else {
			this.date = new Date()
		}
	}
}

export class ToastOptions {
	type: string
	delay: number
	autohide: boolean
	cta: string
	hide: boolean
	showDetail: boolean
	onClose?: () => void
	name: string // used for save localStorage, for don't show notification

	// carateristics for every type of toast
	constructor(req: string, onClose?: () => void, cta = '') {
		if (onClose) {
			this.onClose = onClose
		}
		switch (req) {
			case 'success': //notification on bottom-right green small
				this.type = req
				this.delay = 3000
				this.autohide = true
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break

			case 'error': //notification on center red small
				this.type = req
				this.delay = 5000
				this.autohide = true
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break

			case 'info': //notification on bottom-right small
				this.type = req
				this.delay = 5000
				this.autohide = false
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break

			case 'notification': // notification border center red for credits, grader, plan expiry ect... small
				this.type = req
				this.delay = 0
				this.autohide = false
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break

			case 'notification_s': // notification on user expirence center border red big
				this.type = req
				this.delay = 10000
				this.autohide = true
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break
			case 'info_blue': // notification on user expirence center border blue big
				this.type = req
				this.delay = 0
				this.autohide = false
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break
			case 'info_green': // notification on user expirence center border green big
				this.type = req
				this.delay = 0
				this.autohide = false
				this.cta = cta
				this.hide = false
				this.showDetail = false

				break

			default:
				break
		}
	}
}

export class locStorageNotify {
	header: string
	status: string
	hide: boolean
	date: Date

	constructor(header: string, status: string, hide: boolean) {
		this.header = header
		this.status = status
		this.hide = hide
		this.date = new Date()
	}
}

<div class="row justify-content-center">
	<div class="col-sm-6">
		<div class="card">
			<div class="card-body p-5 text-center">
				<h2 class="card-title mb-3">{{ 'TRIAL.CARD_TITLE' | translate }}</h2>
				<p class="card-text">{{ 'TRIAL.CARD_TEXT' | translate }}</p>
			</div>
			<div class="button-card shadow rounded m-5">
				<h5 class="mb-4">{{ 'TRIAL.SUB_T' | translate }}</h5>
				<div class="ps-2">
					<div class="types">
						<p>
							<fa-icon [icon]="faCircleCheck" aria-hidden="true" class="me-3"></fa-icon>
							<b>{{ 'TRIAL.FIRST_OPT' | translate }}</b>
						</p>
					</div>
					<div class="types">
						<p>
							<fa-icon [icon]="faCircleCheck" aria-hidden="true" class="me-3"></fa-icon>
							<b>{{ 'TRIAL.SECOND_OPT' | translate }}</b>
						</p>
					</div>
				</div>
				<hr />
				<div class="footer-bottom">
					<div class="buttons-container mb-3 mt-3 text-center d-md-flex justify-content-between" *ngIf="session.isEcommerceEnabled();else noEcommerceBlock">
						<button type="button" (click)="trial()" class="btn btn-primary mb-3 p-3">{{ 'TRIAL.BUTTON_ONE' | translate }}</button>
						<button type="button" (click)="gotoProfile()" class="btn btn-primary pt-3 pb-3">
							{{ 'TRIAL.BUTTON_TWO' | translate }}<br />
							{{ 'TRIAL.BUTTON_TWO_SEC' | translate }}
						</button>
					</div>
					<ng-template #noEcommerceBlock>
						<div>
							<h5 class="text-center">{{ 'TRIAL.FOOTER_TITLE' | translate }}</h5>
						</div>
					</ng-template>
					<p class="mt-3 mb-3"><b>{{ 'TRIAL.FOOTER' | translate }}</b></p>
					<div class="d-flex justify-content-center align-items-center mb-3" *ngIf="!session.isEcommerceEnabled()">
						<button type="button" (click)="gotoProfile()" class="btn btn-primary pt-3 pb-3">{{ 'TRIAL.BUTTON_TWO_SEC' | translate }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div>
	<div class="topview ralation-list-container position-relative" [hidden]="dataService.isLoadingRels()">
		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
		</div>

		<div class="container-full">
			<div class="row">
				<div class="col-12">
					<div class="panel-head">
						<div>
							<a> {{ currDistrib.username }} &rArr; </a>
							<label>{{ 'RELATIONS.PAGE_LIST_TITLE' | translate }}</label>
						</div>
						<!-- <div>
							<button *ngIf="session.isLevel3()" (click)="openCreateRelModal()" class="btn add-button" ng-controller="RelationsController" type="button">{{ 'RELATIONS_LIST.NEW_REL' | translate }}</button>
						</div> -->
					</div>

					<div class="panel-body position-relative" style="min-height: 25vh">
						<loader [activateSpinner]="loadRelations"></loader>
						<!-- 
						<div class="loader-container" [ngClass]="loadRelations ? 'show' : '' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->

						<div class="row">
							<table mat-table [dataSource]="relationList" matSort class="col-12 ralation-list-container__table" [ngClass]="loadRelations ? '' : 'show' ">
								<!-- User NAme Column -->
								<ng-container matColumnDef="username">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RELATIONS_LIST.USERNAME' | translate }}</th>
									<td mat-cell *matCellDef="let element" style="font-weight: bold">{{ element.username }}</td>
								</ng-container>

								<!-- id Column -->
								<ng-container matColumnDef="id">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RELATIONS.DOCTOR_ID' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ element.doctor_id }}</td>
								</ng-container>

								<!-- isValid Column -->
								<ng-container matColumnDef="is_valid">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RELATIONS.STATUS' | translate }}</th>
									<td mat-cell *matCellDef="let element">
										<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck ngbTooltip="Relation active" *ngIf="element.isValid()"></fa-icon>
										<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark ngbTooltip="Relation deleted" *ngIf="!element.isValid()"></fa-icon>
									</td>
								</ng-container>

								<!-- affiliationTime Column -->
								<ng-container matColumnDef="affiliationTime">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RELATIONS.AFFILIATION_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ session.formatDate(element.affiliation_date) }}</td>
								</ng-container>

								<!-- display_name Column -->
								<ng-container matColumnDef="display_name">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RELATIONS_LIST.DISPLAY_NAME' | translate }}</th>
									<td mat-cell *matCellDef="let element">{{ element.display_name }}</td>
								</ng-container>

								<!-- has_signature Column -->
								<ng-container matColumnDef="has_signature">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'RELATIONS_LIST.SIGNATURE' | translate }}</th>
									<td mat-cell *matCellDef="let element">
										<fa-icon [icon]="faCircleCheck" class="p-3" faCircleCheck *ngIf="element.has_signature"></fa-icon>
										<fa-icon [icon]="faCircleXmark" class="p-3" faCircleXmark *ngIf="!element.has_signature"></fa-icon>
									</td>
								</ng-container>

								<!-- Filter and buttons Column -->
								<ng-container matColumnDef="filter">
									<th mat-header-cell *matHeaderCellDef>
										<mat-form-field> <input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter /> </mat-form-field>&nbsp;
									</th>

									<td mat-cell *matCellDef="let element">
										<!-- <a class="table-button me-4" *ngIf="session.isSupport() || session.isLevel3()" ngbTooltip="{{ 'BUTTONS.SHOW' | translate }}" placement="right" (click)="openShowModal(element)">
											<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
										</a> -->

										<!-- <a class="table-button" *ngIf="session.isLevel3()" [hidden]="!element.isValid()" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" placement="right" (click)="openEditModal(element)">
											<fa-icon [icon]="faEdit" aria-hidden="true"></fa-icon>
										</a> -->

										<!-- <a class="table-button me-4" *ngIf="session.isLevel3() && !element.isValid()" ngbTooltip="{{ 'RELATIONS_LIST.REST_REL' | translate }}" placement="left" (click)="openRestRelModal(element)">
											<fa-icon [icon]="faLink" aria-hidden="true"></fa-icon>
										</a>

										<a class="table-button" *ngIf="session.isLevel3()" [hidden]="!element.isValid()" (click)="openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}" placement="left">
											<fa-icon [icon]="faTrashAlt" aria-hidden="true"></fa-icon>
										</a> -->
									</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
							</table>

							<mat-paginator [pageSizeOptions]="[10, 15, 20, 50]" showFirstLastButtons [ngClass]="loadRelations ? '' : 'show' "> </mat-paginator>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

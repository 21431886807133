<div class="full-container catInfo">
	<div class="dataWrapper" *ngIf="!modal.hasMiddleInfo('refraction')">
		<label>{{ 'MISC.NO_DATA' | translate }}</label>
	</div>

	<div class="dataWrapper" *ngIf="modal.hasMiddleInfo('refraction')">
		<!-- modalita' Combi, con SBJ, scenario 2 e 3 -->
		<div class="tblWrapper" *ngIf="modal.refrCombinedData()">
			<table class="catInfo refraction z-depth-4" *ngIf="lsmR != null || wfR != null || sbjR != null">
				<thead>
					<tr>
						<th>{{ 'MISC.RIGHT' | translate }}</th>

						<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
						<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
						<th>{{ 'REFRACTION.AXIS' | translate }}</th>
						<th>{{ 'REFRACTION.PX' | translate }}</th>
						<!-- <th>{{"REFRACTION.BASE_H" | translate}}</th> -->
						<th>{{ 'REFRACTION.PY' | translate }}</th>
						<!-- <th>{{"REFRACTION.BASE_V" | translate}}</th> -->
						<th>{{ 'REFRACTION.ADD' | translate }}</th>
					</tr>
				</thead>
				<tr *ngIf="lsmR != null">
					<td>{{ 'EXAMS.LSM' | translate }}</td>
					<td [ngClass]="lsmR.sphere ? 'value' : 'novalue'">{{ lsmR.getSphere() }}</td>
					<td [ngClass]="lsmR.cylinder ? 'value' : 'novalue'">{{ lsmR.getCylinder() }}</td>
					<td [ngClass]="lsmR.axis ? 'value' : 'novalue'">{{ lsmR.getAxis() }}</td>
					<td [ngClass]="lsmR.prism_h ? 'value' : 'novalue'">{{ lsmR.prism_h }}</td>
					<!-- <td [ngClass]="lsmR.base_h ?   'value' : 'novalue'">{{lsmR.base_h}}</td> -->
					<td [ngClass]="lsmR.prism_v ? 'value' : 'novalue'">{{ lsmR.prism_v }}</td>
					<!-- <td [ngClass]="lsmR.base_v ?   'value' : 'novalue'">{{lsmR.base_v}}</td> -->
					<td [ngClass]="lsmR.add ? 'value' : 'novalue'">{{ lsmR.add }}</td>
				</tr>
				<tr *ngIf="wfR != null">
					<td>{{ wfR.getExamLabel() }}</td>
					<!-- {{"EXAMS.WF" | translate}} -->
					<td [ngClass]="wfR.sphere_day ? 'value' : 'novalue'">{{ wfR.getSphereDay() }}</td>
					<td [ngClass]="wfR.cylinder_day ? 'value' : 'novalue'">{{ wfR.getCylDay() }}</td>
					<td [ngClass]="wfR.axis_day ? 'value' : 'novalue'">{{ wfR.getAxisDay() }}</td>

					<!-- <td colspan='5'>&nbsp;</td>  -->
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<!-- <td>&nbsp;</td>
          <td>&nbsp;</td> -->
				</tr>
				<tr *ngIf="sbjR != null">
					<td>{{ 'EXAMS.RX' | translate }}</td>
					<td [ngClass]="sbjR.sphere ? 'value' : 'novalue'">{{ sbjR.getSphere() }}</td>
					<td [ngClass]="sbjR.cylinder ? 'value' : 'novalue'">{{ sbjR.getCylinder() }}</td>
					<td [ngClass]="sbjR.axis ? 'value' : 'novalue'">{{ sbjR.getAxis() }}</td>
					<td [ngClass]="sbjR.prism_h ? 'value' : 'novalue'">{{ sbjR.prism_h }}</td>
					<!-- <td [ngClass]="sbjR.base_h ?   'value' : 'novalue'">{{sbjR.base_h}}</td>  -->
					<td [ngClass]="sbjR.prism_v ? 'value' : 'novalue'">{{ sbjR.prism_v }}</td>
					<!-- <td [ngClass]="sbjR.base_v ?   'value' : 'novalue'">{{sbjR.base_v}}</td> -->
					<td [ngClass]="sbjR.add ? 'value' : 'novalue'">{{ sbjR.add }}</td>
				</tr>
			</table>

			<div class="spacer"></div>
			<table class="catInfo refraction z-depth-4" *ngIf="lsmL != null || wfL != null || sbjL != null">
				<thead>
					<tr>
						<th>{{ 'MISC.LEFT' | translate }}</th>

						<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
						<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
						<th>{{ 'REFRACTION.AXIS' | translate }}</th>
						<th>{{ 'REFRACTION.PX' | translate }}</th>
						<!-- <th>{{"REFRACTION.BASE_H" | translate}}</th>  -->
						<th>{{ 'REFRACTION.PY' | translate }}</th>
						<!--  <th>{{"REFRACTION.BASE_V" | translate}}</th>  -->
						<th>{{ 'REFRACTION.ADD' | translate }}</th>
					</tr>
				</thead>
				<tr *ngIf="lsmL != null">
					<td>{{ 'EXAMS.LSM' | translate }}</td>
					<td [ngClass]="lsmL.sphere ? 'value' : 'novalue'">{{ lsmL.getSphere() }}</td>
					<td [ngClass]="lsmL.cylinder ? 'value' : 'novalue'">{{ lsmL.getCylinder() }}</td>
					<td [ngClass]="lsmL.axis ? 'value' : 'novalue'">{{ lsmL.getAxis() }}</td>
					<td [ngClass]="lsmL.prism_h ? 'value' : 'novalue'">{{ lsmL.prism_h }}</td>
					<!--  <td [ngClass]="lsmL.base_h ?   'value' : 'novalue'">{{lsmL.base_h}}</td>  -->
					<td [ngClass]="lsmL.prism_v ? 'value' : 'novalue'">{{ lsmL.prism_v }}</td>
					<!--  <td [ngClass]="lsmL.base_v ?   'value' : 'novalue'">{{lsmL.base_v}}</td> -->
					<td [ngClass]="lsmL.add ? 'value' : 'novalue'">{{ lsmL.add }}</td>
				</tr>
				<tr *ngIf="wfL != null">
					<td>{{ wfL.getExamLabel() }}</td>
					<!-- {{"EXAMS.WF" | translate}} -->
					<td [ngClass]="wfL.sphere_day ? 'value' : 'novalue'">{{ wfL.getSphereDay() }}</td>
					<td [ngClass]="wfL.cylinder_day ? 'value' : 'novalue'">{{ wfL.getCylDay() }}</td>
					<td [ngClass]="wfL.axis_day ? 'value' : 'novalue'">{{ wfL.getAxisDay() }}</td>
					<!-- <td colspan='5'>&nbsp;</td> -->
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<td>&nbsp;</td>
					<!-- <td>&nbsp;</td>
          <td>&nbsp;</td>    -->
				</tr>
				<tr *ngIf="sbjL != null">
					<td>{{ 'EXAMS.RX' | translate }}</td>
					<td [ngClass]="sbjL.sphere ? 'value' : 'novalue'">{{ sbjL.getSphere() }}</td>
					<td [ngClass]="sbjL.cylinder ? 'value' : 'novalue'">{{ sbjL.getCylinder() }}</td>
					<td [ngClass]="sbjL.axis ? 'value' : 'novalue'">{{ sbjL.getAxis() }}</td>
					<td [ngClass]="sbjL.prism_h ? 'value' : 'novalue'">{{ sbjL.prism_h }}</td>
					<!-- <td [ngClass]="sbjL.base_h ?   'value' : 'novalue'">{{sbjL.base_h}}</td> -->
					<td [ngClass]="sbjL.prism_v ? 'value' : 'novalue'">{{ sbjL.prism_v }}</td>
					<!-- <td [ngClass]="sbjL.base_v ?   'value' : 'novalue'">{{sbjL.base_v}}</td> -->
					<td [ngClass]="sbjL.add ? 'value' : 'novalue'">{{ sbjL.add }}</td>
				</tr>
			</table>

			<div class="spacer"></div>

			<label>[{{ 'EXAM.DATE' | translate }}: {{ modal.refractionExamsDate }}]</label>

			<div class="row nomargin">
				<div class="twothird-container">
					<table class="catInfo refraction z-depth-4">
						<thead>
							<tr>
								<th>{{ 'REFRACTION.VA' | translate }}</th>
								<th>{{ 'EXAMS.LSM' | translate }}</th>
								<th>{{ 'EXAMS.WF' | translate }}</th>
								<th>{{ 'EXAMS.RX' | translate }}</th>
								<th>{{ 'REFRACTION.NV' | translate }}</th>
								<!--<th>Unaided</th>  -->
							</tr>
						</thead>

						<tr *ngIf="sbjR != null">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td>{{ sbjR.getDVA('LM') }}</td>
							<td>{{ sbjR.getDVA('WF') }}</td>
							<td>{{ sbjR.getDVA() }}</td>
							<td>{{ sbjR.getNVA() }}</td>
						</tr>
						<tr *ngIf="sbjL != null">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td>{{ sbjL.getDVA('LM') }}</td>
							<td>{{ sbjL.getDVA('WF') }}</td>
							<td>{{ sbjL.getDVA() }}</td>
							<td>{{ sbjL.getNVA() }}</td>
						</tr>
						<tr *ngIf="sbjB != null">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td>{{ sbjB.getDVA('LM') }}</td>
							<td>{{ sbjB.getDVA('WF') }}</td>
							<td>{{ sbjB.getDVA() }}</td>
							<td>{{ sbjB.getNVA() }}</td>
						</tr>
						<tr *ngIf="sbjB == null">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
							<td>&nbsp;</td>
						</tr>
					</table>
				</div>
				<div class="third-container">
					<table
						class="catInfo refraction z-depth-4"
						*ngIf="lsmR != null || lsmL != null || lsmB != null || sbjR != null || sbjL != null || sbjB != null">
						<thead>
							<tr>
								<th>{{ 'REFRACTION.PD' | translate }}</th>
								<th>&nbsp;</th>
								<!--  25.10.2021
                <th *ngIf="lsmR != null || lsmL != null || lsmB != null">{{"EXAMS.LSM" | translate}}</th>
                <th *ngIf="(lsmR == null && lsmL == null && lsmB == null) && (sbjR != null || sbjL != null || sbjB != null)">{{"EXAMS.RX" | translate}}</th>
                -->
							</tr>
						</thead>

						<tr *ngIf="sbjR != null || lsmR != null">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td *ngIf="lsmR != null" [ngClass]="lsmR.PDm ? 'value' : 'novalue'">{{ lsmR.PDm }}</td>
							<td *ngIf="lsmR == null && sbjR != null" [ngClass]="sbjR.PDm ? 'value' : 'novalue'">{{ sbjR.PDm }}</td>
						</tr>
						<tr *ngIf="sbjL != null || lsmL != null">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td *ngIf="lsmL != null" [ngClass]="lsmL.PDm ? 'value' : 'novalue'">{{ lsmL.PDm }}</td>
							<td *ngIf="lsmL == null && sbjL != null" [ngClass]="sbjL.PDm ? 'value' : 'novalue'">{{ sbjL.PDm }}</td>
						</tr>
						<tr *ngIf="sbjB != null || lsmB != null">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td *ngIf="lsmB != null" [ngClass]="lsmB.PDm ? 'value' : 'novalue'">{{ lsmB.PDm }}</td>
							<td *ngIf="lsmB == null && sbjB != null" [ngClass]="sbjB.PDm ? 'value' : 'novalue'">{{ sbjB.PDm }}</td>
						</tr>
						<tr *ngIf="sbjB == null && lsmB == null">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td [ngClass]="'novalue'">&nbsp;</td>
						</tr>
					</table>
				</div>
			</div>

			<div class="spacer"></div>
		</div>

		<!-- modalita' LSM e WF singoli, scenario 1 e 4 -->
		<div class="tblWrapper" *ngIf="!modal.refrCombinedData() && (lsmR != null || lsmL != null || wfR != null || wfL != null)">
			<div class="row nomargin">
				<div class="fivesixth-container">
					<table *ngIf="lsmR != null || lsmL != null" class="catInfo refraction z-depth-4">
						<thead>
							<tr>
								<th>{{ 'EXAMS.LSM' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								<th>{{ 'REFRACTION.PX' | translate }}</th>
								<th>{{ 'REFRACTION.PY' | translate }}</th>
								<th>{{ 'REFRACTION.ADD' | translate }}</th>
								<!-- <th>{{"REFRACTION.BASE_V" | translate}}</th>  -->
								<!-- <th>{{"REFRACTION.BASE_H" | translate}}</th> -->
							</tr>
						</thead>
						<tr *ngIf="lsmR != null">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td [ngClass]="lsmR.sphere ? 'value' : 'novalue'">{{ lsmR.getSphere() }}</td>
							<td [ngClass]="lsmR.cylinder ? 'value' : 'novalue'">{{ lsmR.getCylinder() }}</td>
							<td [ngClass]="lsmR.axis ? 'value' : 'novalue'">{{ lsmR.getAxis() }}</td>
							<td [ngClass]="lsmR.prism_h ? 'value' : 'novalue'">{{ lsmR.prism_h }}</td>
							<td [ngClass]="lsmR.prism_v ? 'value' : 'novalue'">{{ lsmR.prism_v }}</td>
							<td [ngClass]="lsmR.add ? 'value' : 'novalue'">{{ lsmR.add }}</td>
						</tr>
						<tr *ngIf="lsmL != null">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td [ngClass]="lsmL.sphere ? 'value' : 'novalue'">{{ lsmL.getSphere() }}</td>
							<td [ngClass]="lsmL.cylinder ? 'value' : 'novalue'">{{ lsmL.getCylinder() }}</td>
							<td [ngClass]="lsmL.axis ? 'value' : 'novalue'">{{ lsmL.getAxis() }}</td>
							<td [ngClass]="lsmL.prism_h ? 'value' : 'novalue'">{{ lsmL.prism_h }}</td>
							<td [ngClass]="lsmL.prism_v ? 'value' : 'novalue'">{{ lsmL.prism_v }}</td>
							<td [ngClass]="lsmL.add ? 'value' : 'novalue'">{{ lsmL.add }}</td>
						</tr>
					</table>
				</div>
				<div class="sixth-right-container">
					<!-- sixth-byside-container -->
					<table *ngIf="lsmR != null || lsmL != null" class="catInfo refraction z-depth-4">
						<thead>
							<tr>
								<th>{{ 'REFRACTION.PD' | translate }}</th>
								<th>{{ 'EXAMS.LSM' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="lsmR != null">
							<td>{{ 'MISC.RIGHT' | translate }}</td>
							<td [ngClass]="lsmR.PDm ? 'value' : 'novalue'">{{ lsmR.PDm }}</td>
						</tr>
						<tr *ngIf="lsmL != null">
							<td>{{ 'MISC.LEFT' | translate }}</td>
							<td [ngClass]="lsmL.PDm ? 'value' : 'novalue'">{{ lsmL.PDm }}</td>
						</tr>
						<tr *ngIf="lsmB != null">
							<td>{{ 'MISC.BINO' | translate }}</td>
							<td [ngClass]="lsmB.PDm ? 'value' : 'novalue'">{{ lsmB.PDm }}</td>
						</tr>
					</table>
				</div>
			</div>

			<div class="spacer"></div>

			<label>[{{ 'EXAM.DATE' | translate }}: {{ modal.refractionExamsDate }}]</label>

			<div class="row nomargin">
				<div class="col-sm-12 col-md-12 col-lg-6">
					<table class="catInfo refraction z-depth-4">
						<thead>
							<tr *ngIf="wfR != null">
								<th>{{ 'MISC.RIGHT' | translate }}</th>
								<th *ngIf="wfR.getRealPupilSizeDay() != ''">{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								<th *ngIf="wfR.getVertex() != ''">{{ 'REFRACTION.VERTEX' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="wfR != null">
							<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
							<td *ngIf="wfR.getRealPupilSizeDay() != ''">{{ wfR.getRealPupilSizeDay() }}</td>
							<td>{{ wfR.getPupilDay() }}</td>
							<td>{{ wfR.getSphereDay() }}</td>
							<td>{{ wfR.getCylDay() }}</td>
							<td>{{ wfR.getAxisDay() }}</td>
							<td *ngIf="wfR.getVertex() != ''">{{ wfR.getVertex() }}</td>
						</tr>
						<tr *ngIf="wfR != null">
							<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
							<td *ngIf="wfR.getRealPupilSizeDay() != ''">{{ wfR.getRealPupilSizeNight() }}</td>
							<td>{{ wfR.getPupilNight() }}</td>
							<td>{{ wfR.getSphereNight() }}</td>
							<td>{{ wfR.getCylNight() }}</td>
							<td>{{ wfR.getAxisNight() }}</td>
							<td *ngIf="wfR.getVertex() != ''">{{ wfR.getVertex() }}</td>
						</tr>
					</table>
				</div>
				<div class="col-sm-12 col-md-12 col-lg-6">
					<table class="catInfo refraction z-depth-4">
						<thead>
							<tr *ngIf="wfL != null">
								<th>{{ 'MISC.LEFT' | translate }}</th>
								<th *ngIf="wfL.getRealPupilSizeDay() != ''">{{ 'REFRACTION.REAL_PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.PUPIL_SIZE' | translate }}</th>
								<th>{{ 'REFRACTION.SPHERE' | translate }}</th>
								<th>{{ 'REFRACTION.CYLINDER' | translate }}</th>
								<th>{{ 'REFRACTION.AXIS' | translate }}</th>
								<th *ngIf="wfL.getVertex() != ''">{{ 'REFRACTION.VERTEX' | translate }}</th>
							</tr>
						</thead>
						<tr *ngIf="wfL != null">
							<td>{{ 'EXAMS.DAY_PUPIL' | translate }}</td>
							<td *ngIf="wfL.getRealPupilSizeDay() != ''">{{ wfL.getRealPupilSizeDay() }}</td>
							<td>{{ wfL.getPupilDay() }}</td>
							<td>{{ wfL.getSphereDay() }}</td>
							<td>{{ wfL.getCylDay() }}</td>
							<td>{{ wfL.getAxisDay() }}</td>
							<td *ngIf="wfL.getVertex() != ''">{{ wfL.getVertex() }}</td>
						</tr>
						<tr *ngIf="wfL != null">
							<td>{{ 'EXAMS.NIGHT_PUPIL' | translate }}</td>
							<td *ngIf="wfL.getRealPupilSizeDay() != ''">{{ wfL.getRealPupilSizeNight() }}</td>
							<td>{{ wfL.getPupilNight() }}</td>
							<td>{{ wfL.getSphereNight() }}</td>
							<td>{{ wfL.getCylNight() }}</td>
							<td>{{ wfL.getAxisNight() }}</td>
							<td *ngIf="wfL.getVertex() != ''">{{ wfL.getVertex() }}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>

		<div class="spacer"></div>

		<div class="tblWrapper" *ngIf="topoR != null || topoL != null">
			<label>[{{ 'EXAM.DATE' | translate }}: {{ modal.topoExamsDate }}]</label>
			<table class="catInfo refraction z-depth-4" name="KERATOMETRY">
				<thead>
					<tr>
						<th>{{ 'EXAMS.KERATOMETRY' | translate }}</th>
						<th>{{ 'TOPO.K1' | translate }}</th>
						<th>{{ 'TOPO.K2' | translate }}</th>
						<th>{{ 'TOPO.CYL' | translate }}</th>
						<th>{{ 'TOPO.AVG' | translate }}</th>
					</tr>
				</thead>
				<tr *ngIf="topoR != null">
					<td>{{ 'MISC.RIGHT' | translate }}</td>
					<td [ngClass]="topoR.getK1() ? 'value' : 'novalue'">{{ topoR.getK1() }}</td>
					<td [ngClass]="topoR.getK2() ? 'value' : 'novalue'">{{ topoR.getK2() }}</td>
					<td [ngClass]="topoR.getCYL() ? 'value' : 'novalue'">{{ topoR.getCYL() }}</td>
					<td [ngClass]="topoR.getAVG() ? 'value' : 'novalue'">{{ topoR.getAVG() }}</td>
				</tr>

				<tr *ngIf="topoL != null">
					<td>{{ 'MISC.LEFT' | translate }}</td>
					<td [ngClass]="topoL.getK1() ? 'value' : 'novalue'">{{ topoL.getK1() }}</td>
					<td [ngClass]="topoL.getK2() ? 'value' : 'novalue'">{{ topoL.getK2() }}</td>
					<td [ngClass]="topoL.getCYL() ? 'value' : 'novalue'">{{ topoL.getCYL() }}</td>
					<td [ngClass]="topoL.getAVG() ? 'value' : 'novalue'">{{ topoL.getAVG() }}</td>
				</tr>
			</table>
		</div>
	</div>
</div>

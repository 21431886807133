<div class="container-fluid profile-container">
	<div class="panel panel-default statistics profile-container__panel position-relative">
		<!-- <div class="panel-heading">
          <label class="panel-title">{{"PROFILE.PAGE_TITLE" | translate}}</label>
        </div> -->

		<div class="row">
			<div class="col-12 profile-container__content">
				<!-- profile-navbar -->
				<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-pills" [destroyOnHide]="false" (navChange)="onNavChange($event)">
					<!-- non usato: (activeIdChange)="onNavActiveChange($event)" -->

					<!-- ################ ACCOUNT ######################### -->

					<li ngbNavItem="account" class="nav-item me-3">
						<a ngbNavLink uppercase class="nav-link fw-bold">{{ 'USER.ACCOUNT' | translate }}</a>

						<ng-template ngbNavContent>
							<div class="catInfo">
								<!-- class="tabData sb-style" -->

								<div class="row">
									<!-- ACCOUNT INFO -->
									<div class="border-end" [ngClass]="session.user.isMiniC() ? 'col-6' : 'col-5'">
										<p class="section-title fw-bold border-bottom"><label>{{ 'PROFILE.GENERAL_INFO' | translate }}</label></p>
										<form id="generalInfo" name="generalInfo" #generalInfo="ngForm" novalidate>
											<div class="row form-group">
												<div class="half-container">
													<label for="usrname">{{ 'LOGIN.USERNAME' | translate }}</label>
													<br />
													<input type="text" class="form-control" readonly id="usrname" name="usrname" [ngModel]="currUser.username" />
												</div>
												<div class="half-container">
													<label for="code">{{ 'USER.CODE' | translate }}</label>
													<br />
													<input type="text" class="form-control" readonly id="code" name="code" [ngModel]="currUser.code" />
												</div>
											</div>

											<div class="row form-group">
												<div class="half-container">
													<label for="first-name">{{ 'PROFILE.FIRST_NAME' | translate }} *</label><br />
													<input type="text" class="form-control" maxlength="100" name="firstName" id="first-name" placeholder="{{ 'PROFILE.FIRST_NAME_HINT' | translate }}" [(ngModel)]="editsInfo.firstName" #fn="ngModel" required />
													<div class="error-wrapper" [hidden]="!fn.invalid">
														<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
													</div>
												</div>

												<div class="half-container">
													<label for="last-name">{{ 'PROFILE.LAST_NAME' | translate }} *</label><br />
													<input type="text" class="form-control" maxlength="100" name="lastName" id="last-name" placeholder="{{ 'PROFILE.LAST_NAME_HINT' | translate }}" [(ngModel)]="editsInfo.lastName" #ln="ngModel" required />
													<div class="error-wrapper" [hidden]="!ln.invalid">
														<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
													</div>
												</div>
											</div>

											<div class="row form-group">
												<div class="half-container position-relative">
													<div class="mini-modal-warning" [hidden]="email.valid" *ngIf="session.isLevel1() || session.isLevel2() || session.isClinicAdmin() || session.isSupport()">
														<div class="arrow-up"></div>
														<div class="warning-header warning"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning error-blink"></fa-icon> {{ 'MISC.ATTENTION' | translate }}</div>
														<div class="warning-body" [innerHTML]="attentionBodyEmail">
															<span></span>
														</div>
													</div>

													<label for="email">{{ 'CONTACTS.EMAIL' | translate }} *</label>
													<br />
													<input type="text" class="form-control" maxlength="50" name="email" placeholder="{{ 'CONTACTS.EMAIL_HINT' | translate }}" [(ngModel)]="editsInfo.ref_email" #email="ngModel" />
												</div>

												<div class="half-container">
													<label for="phone">{{ 'CONTACTS.PHONE' | translate }}</label>
													<br />
													<input type="text" class="form-control" maxlength="20" name="phone" placeholder="{{ 'CONTACTS.PHONE_HINT' | translate }}" [(ngModel)]="editsInfo.phone1" #phone="ngModel" />
												</div>
											</div>

											<div class="row form-group">
												<div class="half-container" *ngIf="session.isDoctor() || session.isClinicAdmin()">
													<label for="organization">{{ 'PROFILE.ORGANIZATION' | translate }}</label>
													<br />
													<input type="text" class="form-control" maxlength="100" name="organization" placeholder="{{ 'PROFILE.ORGANIZATION_HINT' | translate }}" [(ngModel)]="editsInfo.organization" #org="ngModel" />
												</div>

												<div class="half-container" *ngIf="session.isOptician()">
													<label for="organization">{{ 'PROFILE.ORGANIZATION' | translate }} *</label><br />
													<input type="text" class="form-control" maxlength="100" id="organization" name="organization" #org="ngModel" placeholder="{{ 'PROFILE.ORGANIZATION_HINT' | translate }}" [(ngModel)]="editsInfo.organization" required />
													<div class="error-wrapper" [hidden]="!org.invalid">
														<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
													</div>
												</div>

												<!-- <div class="half-container" *ngIf="session.isDoctor()">
													<label for="order">{{ 'PROFILE.LICENCE_NUM' | translate }}</label>
													<br />
													<input type="text" class="form-control" maxlength="100" name="order" id="order" placeholder="{{ 'DISTRIB.MODAL_ORDER_REG_NUM_HINT' | translate }}" [(ngModel)]="editsInfo.order_reg_num" required />
												</div> -->

												<div class="half-container position-relative" *ngIf="(session.isOptician() || session.isDoctor() )&& showVat">
													<div class="mini-modal-warning vat-warning" *ngIf="session.isLevel1() && shouldFillVat && !isFillingVat">
														<div class="arrow-up"></div>
														<div class="warning-header warning"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning error-blink"></fa-icon> {{ 'MISC.ATTENTION' | translate }}</div>
														<div class="warning-body" [innerHTML]="attentionBodyVat">
															<span></span>
														</div>
													</div>

													<label for="vat">{{ 'PROFILE.VAT' | translate }}{{shouldFillVat ? ' *' : ''}}</label><br />

													<div class="vat-input-container">
														<div class="country-code" *ngIf="!(session.isLevel1() && !shouldFillVat) || (editsInfo.vat && editsInfo.vat !== '')">
															<p>{{countryIso2.toUpperCase()}}</p>
														</div>
														<input type="text" class="form-control vat" maxlength="100" name="vat" id="vat" [(ngModel)]="editsInfo.vat" #vat="ngModel" (keyup)="onChangeVat($event)" [disabled]="(session.isLevel1() && !shouldFillVat) || isValidatingVat" />
													</div>
													<p class="error-message" [hidden]="!vatError || internalVatCheckerError || isValidatingVat">{{ "PROFILE.VAT_ERROR" | translate }}</p>
													<p class="error-message" [hidden]="!internalVatCheckerError || isValidatingVat">{{ "PROFILE.INTERNAL_VAT_ERROR" | translate }}</p>
													<div [hidden]="!isValidatingVat" class="info-message">
														<div class="spinner-border spinner-border-sm text-primary" role="status">
															<span class="sr-only">Loading...</span>
														</div>
														<p>{{ "PROFILE.VAT_EXISTENCE_CHECK" | translate }}</p>
													</div>
												</div>
											</div>
											<div class="row form-group">
												<div class="col-12 info-container">
													<div *ngIf="session.isLevel1()">
														<fa-icon [icon]="faInfoCircle" aria-hidden="true"></fa-icon>
														<span class="glyphicon glyphicon-info-sign"> * </span>
														{{ 'PROFILE.REQUIRED_INFO' | translate }}
													</div>
												</div>

												<div class="col-12 mt-3" *ngIf="generalInfo.invalid || !generalInfo.pristine">
													<!-- Add pristine to enable the button, only if there are changes -->
													<button type="submit" class="btn btn-primary add-button" [disabled]="generalInfo.invalid || generalInfo.pristine || vatError || internalVatCheckerError || isValidatingVat || (shouldFillVat && (!editsInfo.vat || editsInfo.vat === ''))" (click)="editProfileInfo()">
														{{ 'BUTTONS.SAVE' | translate }}
													</button>
												</div>
											</div>
										</form>
										<!-- PASSWORD CHANGE -->
										<div class="row mt-4" [hidden]="!session.canChangePwd() || !isProfileComplete">
											<div class="col-12">
												<!-- <span class="fw-bold change-password-button">Change password</span> -->
												<p class="section-title change-password fw-bold border-bottom" (click)="showPwdForm = !showPwdForm">
													<label>{{ 'PROFILE.PASSWORD_TITLE' | translate }}</label>
													<fa-icon [icon]="faSortDown" [ngClass]="showPwdForm ? 'show' : '' " aria-hidden="true" class="float-end"></fa-icon>
												</p>
											</div>
											<!-- #collapse="ngbCollapse" [(ngbCollapse)]="showPwdForm" -->
											<form class="col-12" class="password-form position-relative" name="changePasswordForm" [formGroup]="changePasswordForm" (ngSubmit)="submitChangePwd()" [ngClass]="showPwdForm ? 'show' : '' ">
												<loader [activateSpinner]="passwordChanging"></loader>

												<!-- <div class="loader-container" [ngClass]="passwordChanging ? 'show' : '' ">
													<div [hidden]="false" class="lds-ring">
														<div></div>
														<div></div>
														<div></div>
														<div></div>
													</div>
												</div> -->

												<div>
													<!-- <label for="disclaimer"> <fa-icon [icon]="faInfoCircle" aria-hidden="true"></fa-icon> {{ 'MESSAGE.WARNING' | translate }}:</label>
													<br /> -->
													<span id="disclaimer">{{ disclaimer }}</span>
												</div>
												<div class="row form-group">
													<div class="col-5">
														<label for="password">{{ 'PROFILE.PASSWORD' | translate }}</label>
														<input type="password" class="form-control" id="oldPassword" name="oldPassword" formControlName="oldPassword" placeholder="{{ 'PROFILE.PASSWORD_HINT' | translate }}" />
														<div class="error-wrapper" [hidden]="changePasswordForm.get('oldPassword').valid">
															<p class="error-message">{{ changePwdError }}</p>
														</div>
													</div>
													<!-- <div class="col-5">
														<p class="error-message">{{ changePwdError }}</p>
													</div> -->
												</div>
												<div class="row d-flex justify-content-between align-items-center">
													<div class="col-5">
														<div class="row form-group">
															<div class="col-12 position-relative password1-field">
																<label for="password">{{ 'PROFILE.NEW_PASSWORD' | translate }}</label>
																<fa-icon [icon]="showPwd1 ? faEye : faEyeSlash" (click)="showPwd1 = !showPwd1" aria-hidden="true" class="show-psw-icon"></fa-icon>
																<input [type]="showPwd1 ? 'text' : 'password'" class="form-control" id="password1" name="password1" formControlName="password1" placeholder="{{ 'PROFILE.NEW_PASSWORD_HINT' | translate }}" (ngModelChange)="onChangeNewPwd($event)" />
																<p class="error-msg" *ngIf="changePasswordForm.get('password1').invalid">
																	<span [ngClass]="changePasswordForm.get('password1').errors['Invalid blank space'] ? 'show' : '' ">{{ 'FIRST_LOGIN.VALIDATOR_BLANK_CHAR' | translate }}</span>
																</p>
															</div>
														</div>

														<div class="row form-group">
															<div class="col-12 password2-field position-relative">
																<label for="password-confirm">{{ 'PROFILE.CONFIRM_PASSWORD' | translate }}</label>
																<fa-icon [icon]="showPwd2 ? faEye : faEyeSlash" (click)="showPwd2 = !showPwd2" aria-hidden="true" class="show-psw-icon"></fa-icon>
																<input [type]="showPwd2 ? 'text' : 'password'" class="form-control" id="password2" name="password2" formControlName="password2" placeholder="{{ 'PROFILE.CONFIRM_PASSWORD_HINT' | translate }}" />
																<p class="error-msg">
																	<span [ngClass]="changePasswordForm.get('password2').invalid ? 'show' : ''">{{ ('FIRST_LOGIN.PWD_ERR_NOT_MATCH' | translate )}}</span>
																</p>
															</div>
														</div>
													</div>

													<div class="col-6">
														<p class="fw-bold">{{ 'FIRST_LOGIN.VALIDATOR_TITLE' | translate }}</p>
														<div class="mandatory-points">
															<div class="position-relative" [ngClass]="charNum ?  'valid': 'invalid' ">
																<p>
																	<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
																	<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_CHAR' | translate }}</span>
																</p>
															</div>
															<div class="position-relative" [ngClass]="uppercase ?  'valid': 'invalid' ">
																<p>
																	<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
																	<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_UPP' | translate }}</span>
																</p>
															</div>
															<div class="position-relative" [ngClass]="specialChar ?  'valid': 'invalid' ">
																<p>
																	<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
																	<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_SPEC' | translate }}</span>
																</p>
															</div>
														</div>
													</div>
												</div>
												<div class="row form-group">
													<div class="col-12">
														<button type="submit" class="btn btn-primary add-button" [disabled]="changePasswordForm.invalid || passwordChanging">{{ 'BUTTONS.SAVE' | translate }}</button>
													</div>
												</div>
											</form>
										</div>
										<!-- REPORT INFO SECTION -->
										<!-- i mini non hanno l'indirizzo, peró se lo tolgo e basta rimane la colonna a destra vuota, per cui la sezione report info la "importo" dove mi serve all'occorrenza, qui a sinistra o a destra nascondendo l'indirizzo -->
										<div *ngIf="session.user.isMiniC(); else reportInfoSection"></div>
									</div>

									<!-- ADRESS SECTION -->
									<div [ngClass]="session.user.isMiniC() ? 'col-6' : 'col-7'">
										<form name="addressFields" id="addressFields" [formGroup]="addressFields" *ngIf="!session.user.isMiniC(); else reportInfoSection">
											<p class="section-title fw-bold border-bottom"><label>{{"PROFILE.ADDRESS_TITLE" | translate}}</label></p>
											<p *ngIf="!isAddressValid && !isPrivate"><fa-icon [icon]="faInfoCircle" faInfoCircle aria-hidden="true" class="me-2"></fa-icon><span style="font-weight: 300">{{addressMissed}}</span></p>
											<div class="row form-group">
												<div class="col-2">
													<label for="country">{{ 'CONTACTS.COUNTRY' | translate }}</label>
													<br />
													<input type="text" class="form-control" id="country" name="country" formControlName="country" />
												</div>

												<div class="col-10">
													<label for="address">{{ 'CONTACTS.ADDRESS' | translate }}</label><br />
													<input type="text" class="form-control" maxlength="100" name="address" id="address" formControlName="address" placeholder="{{ 'CONTACTS.ADDRESS_HINT' | translate }}" />
													<!-- <div class="error-wrapper" [hidden]="!addr.invalid">
														<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
													</div> -->
												</div>
											</div>

											<div class="row form-group">
												<div class="col-3">
													<label for="zip">{{ "CREATE_USER.ZIP" | translate }}</label>
													<!-- <input
													type="text"
													maxlength="{{ editAddress.zipMax }}"
													minlength="{{ editAddress.zipMin }}"
													class="form-control"
													name="zip"
													id="zip"
													pattern="{{ zipPattern }}"
													[ngClass]="{ 'has-error':!zip.valid }"
													[(ngModel)]="editAddress.zip"
													#zip="ngModel"
													(keyup)="zipCheck($event)"
													[required]="session.isOptician()" /> -->
													<input type="text" class="form-control" name="zip" id="zip" formControlName="zip" />
													<!-- <div class="error-wrapper" [hidden]="!(zip.invalid)">
														<p class="error-message" *ngIf="zipError">{{ 'MESSAGE.REQUIRED' | translate }}</p>
														<p class="error-message" *ngIf="!zipError">{{ 'MESSAGE.ZIP_PORTUGAL' | translate }}</p>
													</div> -->
												</div>

												<div class="col-5">
													<label for="city">{{ 'CONTACTS.CITY' | translate }}</label><br />
													<input type="text" class="form-control" maxlength="100" id="city" name="city" formControlName="city" placeholder="{{ 'CONTACTS.CITY_HINT' | translate }}" />
													<!-- <div class="error-wrapper" [hidden]="!city.invalid">
														<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
													</div> -->
												</div>

												<div class="col-4">
													<label for="province">{{ 'CONTACTS.PROVINCE' | translate }}</label><br />
													<input type="text" class="form-control" maxlength="100" id="province" name="province" formControlName="province" placeholder="{{ 'CONTACTS.PROVINCE_HINT' | translate }}" />
													<!-- <div class="error-wrapper" [hidden]="!prov.invalid">
														<p class="error-message">{{ 'MESSAGE.REQUIRED' | translate }}</p>
													</div> -->
												</div>
											</div>
											<!-- pulsante save solo per i miniA miniB e miniC che per ora non vedono la mappa e l'indirizzo non é importante avere le coordinate -->
											<div class="row form-group" *ngIf="!showMap">
												<div class="col-auto" *ngIf="addressFields.invalid || !addressFields.pristine">
													<button type="button" class="btn btn-primary add-button" (click)="saveAddress()" [disabled]="addressFields.invalid || addressFields.pristine">{{ 'BUTTONS.SAVE' | translate }}</button>
												</div>
											</div>
											<!-- ADDESS ROLE FOR CLICNIC ADMIN -->
											<div class="row form-group address-role-form align-items-end position-relative" *ngIf="this.session.isClinicAdmin() || this.session.isSuperB()">
												<!-- <div class="col-auto">
													<label>This addess is relative at:</label>
												</div> -->
												<div class="col-auto">
													<label>{{"PROFILE.ADDRESS_ROLE_TITLE" | translate}} <fa-icon [icon]="faInfoCircle" faInfoCircle aria-hidden="true" class="ms-2" (click)="showRoleInfo = !showRoleInfo"></fa-icon> </label>
													<select class="form-select" aria-label="Role selection" formControlName="role" (change)="changeRole()">
														<option *ngFor="let role of dropDownRoles" [value]="role.role">{{role.description}}</option>
													</select>
												</div>

												<div class="col-auto" *ngIf="(this.session.isClinicAdmin() || this.session.isSuperB() )&& !addressFields.get('role').pristine && isAddressValid ">
													<button type="button" class="btn btn-primary add-button" (click)="saveRole()">{{ 'BUTTONS.SAVE' | translate }}</button>
												</div>
												<div class="col-auto">
													<div class="address-role-form__info mt-2" [ngClass]="showRoleInfo ? 'show' : '' ">
														<span>{{'PROFILE.ADDRESS_ROLE_INFO'| translate}}</span>
													</div>
												</div>
											</div>

											<div class="row form-group address-role-form align-items-end position-relative" *ngIf="this.session.isOptician() && !session.isMini()">
												<div class="col-auto">
													<div class="form-check">
														<input class="form-check-input" type="checkbox" formControlName="showOnReport" id="showAddress" (change)="saveShowOnReport()" />
														<label class="form-check-label ms-3" for="showAddress"> {{'PROFILE.SHOW_ADDRESS_ON_REP' | translate}} </label>
													</div>
												</div>
												<div class="col-auto" *ngIf="!addressFields.get('showOnReport').pristine ">
													<button type="button" class="btn btn-primary add-button" (click)="setOptions()">{{ 'BUTTONS.SAVE' | translate }}</button>
												</div>
											</div>

											<!-- <div class="row" *ngIf="showMap">
												<div class="col-12"> -->
											<app-location *ngIf="showMap"></app-location>
											<!-- </div>
											</div> -->
										</form>
									</div>
								</div>
							</div>
						</ng-template>
					</li>

					<ng-template #reportInfoSection>
						<!-- REPORT INFO -->
						<form class="row" id="reportFields" name="reportFields" #reportFields="ngForm" novalidate [ngClass]="session.user.isMiniC() ? '': 'mt-5' ">
							<div class="col 12" *ngIf="session.isSpecialist()">
								<p class="section-title fw-bold border-bottom"><label>{{ 'PROFILE.REPORT_TITLE' | translate }}</label></p>
								<p style="line-height: 26px">{{ 'PROFILE.MANDATORY_GRADER' | translate }}</p>
							</div>
							<div class="col-12" *ngIf="session.isSpecialist()">
								<div class="row">
									<div class="col-6 form-group">
										<label for="order">{{ 'PROFILE.LICENCE_NUM' | translate }}*</label>
										<input type="text" class="form-control" maxlength="50" name="order" id="order" placeholder="{{ 'DISTRIB.MODAL_ORDER_REG_NUM_HINT' | translate }}" [(ngModel)]="editReport.order_reg_num" required />
									</div>

									<div class="col-6 form-group">
										<label for="display">{{"RELATIONS.DISPLAY_NAME_INFO" | translate}}*</label>
										<input type="text" class="form-control" maxlength="100" name="display" id="display" [(ngModel)]="editReport.display_name" required />
									</div>

									<div class="col-11 form-group img-form">
										<label for="display">{{"PROFILE.SIGNATURE_TITLE" | translate}}</label>
										<div [hidden]="editReport.signature_name">
											<input type="file" class="form-control" [ngClass]="{invalid : fileInvalid}" name="signature" #signature id="signature" accept=".png,.jpg" (change)="checkSignature($event)" />
										</div>

										<div class="input-group mt-2" [hidden]="!editReport.signature_name">
											<span class="input-group-text" id="basic-addon1">{{ 'PROFILE.SIGNATURE_NAME' | translate }}</span>
											<input type="text" class="form-control" name="signature_name" [(ngModel)]="editReport.signature_name" [disabled]="!fileInvalid || editReport.signature_name" />
										</div>
										<fa-icon [icon]="faCheck" faCheck [ngClass]="{ 'show' : !fileInvalid }"></fa-icon>
										<a (click)="resetSignature()"><fa-icon [icon]="faXmark" faXmark [ngClass]="{ 'show' : !fileInvalid }"></fa-icon></a>
									</div>

									<div class="col-12 position-relative">
										<div class="mini-modal-warning mini-modal-warning-signature" [ngClass]="fileInvalid ? 'show' : '' ">
											<div class="arrow-up"></div>
											<div class="warning-header warning"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true" class="warning error-blink"></fa-icon> {{ ('MISC.ATTENTION' | translate)}}</div>
											<div class="warning-body" [innerHTML]="signatureMandatoryText">
												<span></span>
											</div>
										</div>
									</div>

									<div class="col-12">
										<div class="p-2 preview-body">
											<p><span>{{ 'PROFILE.GRADER' | translate }}</span>: <span class="fw-bold">{{editReport.display_name}}</span></p>
											<p><span>{{ 'PROFILE.ORDER_NUM' | translate }}</span>: <span class="fw-bold">{{editReport.order_reg_num}}</span></p>
											<span>{{ 'PROFILE.SIGNATURE' | translate }}</span>
											<div style="max-width: 90%" class="position-relative">
												<loader [activateSpinner]="loadSignature"></loader>

												<!-- <div class="loader-container" [ngClass]="loadSignature ? 'show' : '' ">
													<div [hidden]="false" class="lds-ring">
														<div></div>
														<div></div>
														<div></div>
														<div></div>
													</div>
												</div> -->

												<div *ngIf="fileInvalid" class="mt-3">
													<p class="warning" style="font-size: 0.9rem">{{ 'PROFILE.SIGNATURE_VALID' | translate }}</p>
												</div>

												<img src="{{editReport.signature}}" alt="" style="max-height: 140px" />
											</div>
										</div>
									</div>
								</div>
							</div>
							<!-- LOGO SECTION -->
							<div class="col-12" *ngIf="session.isOptician()">
								<div class="row">
									<div class="col-12">
										<p class="section-title fw-bold border-bottom"><label>{{"PROFILE.SHOP_LOGO_TITLE" | translate}}</label></p>
										<p>{{"PROFILE.SHOP_LOGO_SUBTITLE" | translate}}</p>
									</div>
									<div class="col-10 img-form">
										<div [hidden]="editLogo.logo_name">
											<input type="file" class="form-control" name="logo" #logo id="logo" accept=".png,.jpg" (change)="checkLogo($event)" />
										</div>

										<div class="input-group mt-2" [hidden]="!editLogo.logo_name">
											<span class="input-group-text" id="basic-addon1">{{"PROFILE.LOGO_NAME" | translate}}</span>
											<input type="text" class="form-control" name="logo_name" [(ngModel)]="editLogo.logo_name" [disabled]="!logoFileInvalid || editLogo.logo_name" />
										</div>
										<fa-icon [icon]="faCheck" faCheck [ngClass]="{ 'show' : !logoFileInvalid }"></fa-icon>
										<a (click)="resetLogo()"><fa-icon [icon]="faXmark" faXmark [ngClass]="{ 'show' : !logoFileInvalid }"></fa-icon></a>
									</div>
									<div class="col-12 mt-3">
										<div class="row">
											<div class="col-5">
												<div class="row" style="height: 100%">
													<div class="col-12 d-flex">
														<div class="position-relative preview-body">
															<loader [activateSpinner]="loadLogo"></loader>

															<!-- <div class="loader-container" [ngClass]="loadLogo ? 'show' : '' ">
																<div [hidden]="false" class="lds-ring">
																	<div></div>
																	<div></div>
																	<div></div>
																	<div></div>
																</div>
															</div> -->

															<div *ngIf="logoFileInvalid" style="padding: 5px">
																<p class="warning" style="font-size: 0.9rem">{{ 'PROFILE.LOGO_VALID' | translate }}</p>
															</div>

															<img src="{{editLogo.logo}}" alt="" style="height: auto; width: 100%; max-height: 160px" />
														</div>
													</div>
												</div>
											</div>

											<div class="col-7">
												<p class="my-0 pt-3">{{"PROFILE.SHOP_LOGO_ACKNOWLEDGMENT" | translate}}</p>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="spacer"></div>

							<div class="row mt-3" *ngIf="reportFields.invalid || !reportFields.pristine">
								<div class="third-container">
									<!-- Add pristine to enable the button, only if there are changes -->
									<button type="submit" class="btn btn-primary add-button" [disabled]="reportFields.invalid || reportFields.pristine || invalidSize" (click)="editReportInfo()">{{ 'BUTTONS.SAVE' | translate }}</button>
								</div>
							</div>
						</form>
					</ng-template>

					<!-- ################ OPTIONS ######################### -->

					<li ngbNavItem="options" [disabled]="!session.canAccessOptions() || !isProfileComplete" class="nav-item me-3">
						<a ngbNavLink class="nav-link fw-bold">{{ 'USER.OPTIONS' | translate }}</a>
						<ng-template ngbNavContent>
							<div class="catInfo">
								<div class="row">
									<form name="prefForm" #prefForm="ngForm">
										<div class="full-container fields-container">
											<div class="row">&nbsp;</div>

											<div class="row">
												<div class="half-container">
													<label for="date">{{ 'MISC.LANGUAGE' | translate }}</label>
													<br />
													<!-- FIXME, dropdown non funziona
														<button type="button" class="btn btn-secondary dropdown-toggle"
															data-toggle="dropdown" data-bs-toggle="dropdown"
															id="dropdownMenuButton"
															aria-haspopup="true" aria-expanded="false" >
															&nbsp; {{session.getLanguage() | translate}} &nbsp;
														</button>
														<div class="dropdown-menu" aria-labelledby="dropdownMenuButton" >
														<a class="dropdown-item" (click)="test(1)">test 1</a>
														<a class="dropdown-item" (click)="test(2)">test 2</a>
														</div>
														-->
													<select class="form-control" [(ngModel)]="prefs.lang" name="lang" #lang (change)="setOptions($event, lang.value)">
														<option *ngFor="let lang of session.languages" value="{{ lang.short }}" id="{{ lang.label }}">{{ lang.name }}</option>
													</select>
												</div>
												<div class="half-container"><br /><span>{{ 'PROFILE.LANG_CHANGE_MSG1' | translate }} </span></div>
											</div>

											<hr />

											<div class="row">
												<div class="half-container">
													<label for="date">{{ 'PROFILE.DATE_FORMAT_TITLE' | translate }}</label>
													<br />
													<input type="radio" name="date_format" id="US" value="MM/DD/YYYY" [(ngModel)]="prefs.date_format" />
													MM/DD/YYYY &nbsp; (30 June: 06/30/2022)&nbsp;
													<br />
													<input type="radio" name="date_format" id="EU" value="DD/MM/YYYY" [(ngModel)]="prefs.date_format" />
													DD/MM/YYYY &nbsp; (30 June: 30/06/2022)&nbsp;
													<br />&nbsp; <br />{{ 'PROFILE.DATE_LEGEND' | translate }}
												</div>
												<div class="half-container">
													<label for="pdf">{{ 'PROFILE.PDF_FORMAT_TITLE' | translate }}</label>
													<br />
													<input type="radio" name="pdf_format" id="A4" value="A4" [(ngModel)]="prefs.pdf_format" /> A4 &nbsp;&nbsp;
													<br />
													<input type="radio" name="pdf_format" id="letter" value="LETTER" [(ngModel)]="prefs.pdf_format" /> Letter &nbsp;
												</div>
											</div>

											<div class="spacer"></div>
											<hr />

											<div class="spacer"></div>

											<div class="row">
												<div class="save-container third-right-container">
													<div class="row justify-content-end">
														<div class="third-container">
															<button type="submit" class="btn btn-primary" [disabled]="prefForm.invalid" (click)="setOptions()">{{ 'BUTTONS.SAVE' | translate }}</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</ng-template>
					</li>

					<!-- ################ DEVICES ######################### -->

					<li ngbNavItem="devices" [hidden]="!session.isLevel1()" [disabled]="true" class="nav-item me-3">
						<a ngbNavLink class="nav-link fw-bold">{{ 'NAVBAR.DEVICES' | translate }}</a>
						<ng-template ngbNavContent>
							<div class="row">
								<div class="catInfo">
									<div class="row">
										<div class="full-container">
											<label>Your Device(s)</label>
											<br />
											<p>available soon</p>
										</div>
									</div>
									<div class="row">
										<p>&nbsp;</p>
										<p>&nbsp;</p>
									</div>
								</div>
							</div>
						</ng-template>
					</li>

					<!-- ################ MANUALS ######################### -->

					<li ngbNavItem="manuals" [hidden]="true" [disabled]="true" class="nav-item me-3">
						<a ngbNavLink class="nav-link fw-bold">{{ 'USER.MANUALS' | translate }}</a>
						<ng-template ngbNavContent>
							<div class="row">
								<div class="catInfo">
									<div class="row">
										<div class="full-container">
											<label>{{ 'USER.MANUALS' | translate }}</label>
											<br />
											<p>available soon</p>
										</div>
									</div>
									<div class="row">
										<p>&nbsp;</p>
										<p>&nbsp;</p>
									</div>
								</div>
							</div>
						</ng-template>
					</li>

					<!-- ################ CONTRACTS ######################### -->

					<li ngbNavItem="contracts" *ngIf="(session.isLevel1() || session.isLevel2() || session.isClinicAdmin()) && !session.user.isGroupB()" [disabled]="!session.hasEmail()" class="nav-item me-3">
						<a ngbNavLink class="nav-link fw-bold">{{ 'USER.DOCUMENTS' | translate }}</a>
						<ng-template ngbNavContent>
							<div class="loading-container" [hidden]="!dataService.isLoadingAcceptedAgreements()">
								<div class="tail-spin">&nbsp;Loading the agreement...</div>
							</div>

							<div class="row" [hidden]="dataService.isLoadingAgreement()">
								<div class="sectagreement col-12">
									<p style="padding-left: 24px"><label>{{ 'USER.YOUR_DOCUMENTS' | translate }}</label></p>

									<div class="mb-5">
										<table mat-table [dataSource]="agreementsList" style="width: 100%" *ngIf="agreementsList.length > 0; else unavailableAgreementsBlock">
											<ng-container matColumnDef="document_type">
												<th mat-header-cell *matHeaderCellDef>{{"AGREEMENT.DOCUMENT_TYPE" | translate}}</th>
												<td mat-cell *matCellDef="let element" style="width: 40%">{{element.label | translate}}</td>
											</ng-container>
											<ng-container matColumnDef="status">
												<th mat-header-cell *matHeaderCellDef class="p-3 text-center">{{"MISC.STATUS" | translate | titlecase}}</th>
												<td mat-cell *matCellDef="let element" style="width: 15%">
													<span class="text-center">
														<div class="agreement-status">{{ element.status | titlecase }}</div>
													</span>
												</td>
											</ng-container>
											<ng-container matColumnDef="date">
												<th mat-header-cell *matHeaderCellDef class="text-center">{{"MISC.DATE" | translate | titlecase}}</th>
												<td mat-cell *matCellDef="let element" class="text-center" style="width: 15%">{{session.formatDate(element.date) }}</td>
											</ng-container>
											<ng-container matColumnDef="id">
												<th mat-header-cell *matHeaderCellDef class="text-center">{{"MISC.ID" | translate}}</th>
												<td mat-cell *matCellDef="let element" class="text-center" style="width: 15%">{{element.id}}</td>
											</ng-container>
											<ng-container matColumnDef="actions">
												<th mat-header-cell *matHeaderCellDef>{{"AGREEMENT.ACTIONS" | translate}}</th>
												<td mat-cell *matCellDef="let element" style="width: 15%">
													<a href="{{element.url}}" target="_blank">
														<button class="btn btn-primary btn-block">
															<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
															{{"AGREEMENT.VIEW" | translate}}
														</button>
													</a>
												</td>
											</ng-container>

											<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
											<tr mat-row *matRowDef="let row; columns: displayedColumns" class="py-3"></tr>
										</table>
									</div>
									<div *ngIf="showPatientDocuments">
										<div class="row">
											<label>{{ 'USER.PATIENT_DOCUMENTS' | translate }}</label>
										</div>
										<div class="row">
											<table mat-table [dataSource]="patientAgreement" style="width: 100%" *ngIf="patientAgreement.length > 0; else unavailableAgreementsBlock">
												<ng-container matColumnDef="document_type">
													<th mat-header-cell *matHeaderCellDef>{{"AGREEMENT.DOCUMENT_TYPE" | translate}}</th>
													<td mat-cell *matCellDef="let element" style="width: 40%">{{element.label | translate}}</td>
												</ng-container>
												<!-- <ng-container matColumnDef="status">
													<th mat-header-cell *matHeaderCellDef class="p-3">{{"MISC.STATUS" | translate | titlecase}}</th>
													<td mat-cell *matCellDef="let element" style="width: 15%"></td>
												</ng-container> -->
												<ng-container matColumnDef="date">
													<th mat-header-cell *matHeaderCellDef>{{"MISC.DATE" | translate | titlecase}}</th>
													<td mat-cell *matCellDef="let element" style="width: 15%">{{session.formatDate(element.date) }}</td>
												</ng-container>
												<ng-container matColumnDef="id">
													<th mat-header-cell *matHeaderCellDef>{{"MISC.ID" | translate}}</th>
													<td mat-cell *matCellDef="let element" style="width: 15%">{{element.id}}</td>
												</ng-container>
												<ng-container matColumnDef="actions">
													<th mat-header-cell *matHeaderCellDef>{{"AGREEMENT.ACTIONS" | translate}}</th>
													<td mat-cell *matCellDef="let element" style="width: 15%">
														<a href="{{element.url}}" target="_blank">
															<button class="btn btn-primary btn-block">
																<fa-icon [icon]="faEye" aria-hidden="true"></fa-icon>
																{{"AGREEMENT.VIEW" | translate}}
															</button>
														</a>
													</td>
												</ng-container>

												<tr mat-header-row *matHeaderRowDef="displayedPatColumns"></tr>
												<tr mat-row *matRowDef="let row; columns: displayedPatColumns" class="py-3"></tr>
											</table>
										</div>
									</div>
									<ng-template #unavailableAgreementsBlock>
										<p class="my-3">{{ 'USER.UNAVAIBLE_DOCUMENTS' | translate }}</p>
									</ng-template>
								</div>
							</div>
						</ng-template>
					</li>

					<!-- ################ ACTIVITY ######################### -->

					<li ngbNavItem="activity" [hidden]="!enableActivityTab " class="nav-item me-3">
						<a ngbNavLink class="nav-link fw-bold">{{ 'USER.ACTIVITY' | translate }}</a>
						<ng-template ngbNavContent>
							<div class="row">
								<div class="full-container">
									<div class="sectagreement">
										<!-- <div class="row pb-3">
											<label>{{ 'USER.REPORTS' | translate }}</label>
										</div> -->
										<div class="row pb-3">
											<div *ngIf="session.isSpecialist() && !session.isMiniC()">
												<availability-handler></availability-handler>
												<hr />
											</div>
											<activities [activitiesList]="ActivitiesListR" *ngIf="hasReports"></activities>
										</div>
									</div>
								</div>
							</div>
						</ng-template>
					</li>
				</ul>
				<!-- where currently active nav content will be displayed: -->

				<div [ngbNavOutlet]="nav" class="pt-3"></div>
				<!-- class="mt-2" class="ml-4" -->
			</div>
		</div>
	</div>
</div>

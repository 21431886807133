// *****  fase 2  *********
// per export su csv dei conteggi esami per utente, su range di date
export class CsvLine {
	lineType: string
	user_id: number
	username: string
	//provider: string;
	profileType: string // {opticians, specialists}

	fromDay: string
	toDay: string
	//clientSince: string;
	//residualCredits : number;

	examType: string
	tot: number
	used_bytes: number // 28.07.2021
	device: string
	operId: string

	// per report su AI
	status: number
	channel: string

	notes: string // 08.07.2021

	//static SEP = ",";
	static SEP = ';' // piu' diffuso, non da problemi con separatori decimali

	static OPT = 'operators' // "opticians";
	static REF = 'graded' // "specialists";
	static HG = 'hg_reports'
	static AI = 'ai_reports'

	static USAGE_TOT = 'count_usr_visits' // 08.07.2021

	constructor(draft, profileType?) {
		if (profileType) this.profileType = profileType

		//console.log("(CsvLine) profType: "+profileType);

		if (draft.user_id) this.user_id = draft.user_id

		if (draft.username) this.username = draft.username

		if (draft.fromDay) this.fromDay = draft.fromDay

		if (draft.toDay) this.toDay = draft.toDay

		if (draft.exam_type) this.examType = draft.exam_type

		if (draft.tot) this.tot = draft.tot

		if (draft.used_bytes)
			// 28.07.2021
			this.used_bytes = draft.used_bytes

		if (draft.device) this.device = draft.device

		if (draft.optician_id) this.operId = draft.optician_id

		// 11.08.2022
		if (draft.status) this.status = draft.status

		if (draft.channel) this.channel = draft.channel

		// 04.11.2020
		//if(draft.credits != null)  // 0 e' valore accettabile
		//  this.residualCredits = draft.credits;
	}

	// tenere allineata con i campi del csv
	static getTitle(profType) {
		var ret = ''

		if (profType == this.USAGE_TOT) {
			// 08.07.2021

			ret =
				'user_id' +
				this.SEP +
				'username' +
				this.SEP +
				'tot_visits' +
				this.SEP +
				'used_bytes' +
				this.SEP + // 28.07.2021
				'notes' +
				this.SEP
		} else {
			ret = 'user_id' + this.SEP + 'username' + this.SEP + 'from_day' + this.SEP + 'to_day' + this.SEP

			if (profType == this.OPT || profType == this.REF) {
				ret += 'exam_type' + this.SEP
			}

			// 11.08.2022
			if (profType == this.AI) {
				ret += 'status' + this.SEP
				ret += 'channel' + this.SEP
			}

			ret += 'tot' + this.SEP

			if (profType == this.OPT) {
				ret += 'device' + this.SEP
			} else if (profType == this.REF || profType == CsvLine.HG) {
				ret += 'oper_id' + this.SEP
			}
		}
		//"residual_credits" + this.SEP +
		//"elab_time" + this.SEP ;
		return ret
	}

	getCsvLine() {
		var ret = ''

		if (this.profileType == CsvLine.USAGE_TOT) {
			// 08.07.2021

			ret =
				this.user_id +
				CsvLine.SEP +
				this.username +
				CsvLine.SEP +
				this.tot +
				CsvLine.SEP +
				this.used_bytes +
				CsvLine.SEP + // 28.07.2021
				this.notes +
				CsvLine.SEP
		} else {
			//var now = new Date();
			//var today = DateParser.formatSqlDate(now);  // solo YYYY-MM-DD

			ret = this.user_id + CsvLine.SEP + this.username + CsvLine.SEP + this.fromDay + CsvLine.SEP + this.toDay + CsvLine.SEP

			if (this.profileType == CsvLine.OPT || this.profileType == CsvLine.REF) {
				ret += this.examType + CsvLine.SEP
			}

			// 11.08.2022
			if (this.profileType == CsvLine.AI) {
				ret += this.status + CsvLine.SEP
				ret += this.channel + CsvLine.SEP
			}

			ret += this.tot + CsvLine.SEP

			if (this.profileType == CsvLine.OPT) {
				ret += this.device + CsvLine.SEP
			} else if (this.profileType == CsvLine.REF || this.profileType == CsvLine.HG) {
				ret += this.operId + CsvLine.SEP
			}

			//today + CsvLine.SEP;
		}

		return ret
	}
}

<div class="album" *ngIf="hasImages">
	<div class="imgOuterWrapper">
		<div class="imgWrapper">
			<img id="imgEye-{{ current }}" src="{{ images[current].image }}" (click)="showFullscreen(current)" />
			<label>{{ 'EXAM.DATE' | translate }}: {{ session.formatDateTime(images[current].date) }}</label>
			<label>{{ 'ALBUM.NAME' | translate }}: {{ images[current].descr }}</label>
			<label *ngIf="images[current].quality > 0">AI Quality: {{ images[current].quality }}</label>
		</div>
	</div>

	<div [hidden]="images.length == 1">
		<!-- 25.08.2020 tolte frecce se solo un elemento -->
		<span class="selector">
			<span class="show-previous" (click)="showPrevious()"> &laquo; </span>
			<span class="current">{{ current + 1 }} </span>
			<span class="of"> {{ 'ALBUM.OF' | translate }} </span>
			<span class="total"> {{ images.length }}</span>
			<span class="show-next" (click)="showNext()"> &raquo; </span>
		</span>
	</div>

	<div class="checkboxSel" *ngIf="isWizard && !areAdditions">
		<div class="form-check">
			<input class="form-check-input me-2" type="checkbox" value="" id="flexCheckDefault" [(ngModel)]="images[current].selected" />
			<label class="form-check-label" for="flexCheckDefault"> {{ 'REPORT.DISPLAY_ON_REPORT' | translate }} </label>
		</div>
	</div>

	<div class="" *ngIf="!isWizard && !session.isClalit()">
		<a class="table-button" (click)="downloadImg(images[current])" ngbTooltip="{{ 'BUTTONS.DOWNLOAD' | translate }}" placement="right">
			<fa-icon [icon]="faSave" aria-hidden="true"></fa-icon>
		</a>
	</div>
</div>

<canvas id="myCanvas" #myCanvas class="zoom-container" [hidden]="true"></canvas>

<div class="zoom-container" [hidden]="!zoomActive" (keyup)="closeFullscreen()" *ngIf="hasImages">
	<div class="btn-close-container-pos">
		<button class="btn-close" type="button" (click)="closeFullscreen()"></button>
	</div>
	<lib-ngx-image-zoom
		[thumbImage]="resampledImage.src"
		[fullImage]="images[current].image"
		[zoomMode]="'click'"
		[magnification]="1"
		[circularLens]="true"
		[enableLens]="true"
		[lensWidth]="400"
		[lensHeight]="400"
		[enableScrollZoom]="true">
	</lib-ngx-image-zoom>
</div>

export interface Events {
	created_by: number
	creation_date: string
	details: string
	event_type: string
	user_id: number
}

export class UserEvents {
	created_by: number
	creation_date: Date
	details: string
	event_type: string
	user_id: number

	constructor(rawObj: Events) {
		this.created_by = rawObj.created_by
		this.creation_date = new Date(rawObj.creation_date)
		this.details = rawObj.details
		this.user_id = rawObj.user_id

		if (userEventype[rawObj.event_type]) {
			this.event_type = userEventype[rawObj.event_type]
		} else {
			this.event_type = rawObj.event_type
		}
	}
}

export class UserEventsObj {
	askDate: Date // used to get only events after that datetime
	userEvent: UserEvents[]

	constructor(rawObj?: UserEvents[]) {
		this.askDate = null
		this.userEvent = []

		if (rawObj) {
			this.askDate = new Date()
			this.userEvent = rawObj
		}
	}
}

export enum userEventype {
	account_created = 'EVENT_ACC_CREATED',
	account_activated = 'EVENT_ACC_ACTIVATED',
	account_deleted = 'EVENT_ACC_DELETED',
	address_changed = 'EVENT_ADDR_CHANGE',
	relation_created = 'EVENT_REL_CREATED',
	relation_changed = 'EVENT_REL_CHANGED',
	relation_deleted = 'EVENT_REL_DELETED',
	plan_changed = 'EVENT_PLAN_CHANGED',
	purchase = 'EVENT_PURCHASE',
	free_trial = 'EVENT_FREE_TRIAL',
}

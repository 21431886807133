<div class="col m-4 d-flex justify-content-center">
	<div *ngIf="!loading; else loadingSpinner">
		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
		</div>

		<h2 class="text-center my-3 fw-bold">{{'CHANGE_GRADER_MODAL.TITLE' | translate}}</h2>
		<p class="text-center">{{'CHANGE_GRADER_MODAL.SUBTITLE' | translate}}</p>

		<form #requestChangeGraderForm="ngForm">
			<div class="container-fluid questions-container">
				<div class="row">
					<div class="col-12" *ngFor="let question of formQuestions; let i=index">
						<div class="row p-4">
							<div class="d-flex align-items-center">
								<div>
									<span><input type="checkbox" class="form-check-input" [name]="question.question" (change)="toggle(question, question.replies[0].checked ? 0 : 1)" /></span>
								</div>

								<span class="ms-4 fw-bold">{{question.question}}</span>
							</div>

							<!-- <div class="col-6 d-flex align-items-end">
								<div class="me-1" *ngFor="let reply of question.replies; let e=index">
									<input type="checkbox" class="btn-check" id="btn-check-{{question.question_id}}-{{reply.reply_id}}" [(ngModel)]="reply.checked" #btncheck="ngModel" name="btn-check-{{question.question_id}}-{{reply.reply_id}}" [disabled]="reply.checked" autocomplete="off" />
									<label class="btn btn-questions" for="btn-check-{{question.question_id}}-{{reply.reply_id}}" (click)="toggle(question, e)">{{reply.reply}}</label>
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</form>

		<div class="container mt-5">
			<div class="row">
				<div class="col-12">
					<div class="d-flex justify-content-end">
						<button class="btn btn-primary" [disabled]="!canSendForm" (click)="sendForm()">{{'BUTTONS.SAVE' | translate}}</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<ng-template #loadingSpinner>
		<div class="loading-spinner">
			<fa-icon [icon]="faSpinner"></fa-icon>
		</div>
	</ng-template>
</div>

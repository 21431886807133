// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	staging: false,
	development: true,
	qrCodeEnabled: true,
	apiOldEndpoint: 'http://172.105.77.45:81',
	apiEndpoint: 'https://dev-api.nexus.visionix.com',
	irisEndpoint: 'https://dev-iris.nexus.visionix.com',
	api_bridgeEndpoint: 'https://dev-br.nexus.visionix.com',
	brand: 'visionix',
	telerefractUrl: 'https://eyecare.internal.nexus.visionix.com',
	ecommerce: 'https://visionix--b2b.sandbox.my.site.com/VisionixOnNexus2/',
	autoregUrl: 'https://dev-sub.nexus.visionix.com',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

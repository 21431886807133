import { Injectable } from '@angular/core'

//import { forge, util, cipher, random, pkcs5, pki, md, crypto } from 'node-forge';
import * as forge from 'node-forge'
import { Util } from '../models/util.model'

// ************************

export class CryptoBag {
	// qls campo ?!
}

export class postMessageToWorker {
	messageId: string //usato per parallelizzare multiple chiamate al worker
	type: workerFunctionType | null
	password: string
	key: forge.util.ByteStringBuffer
	keyBox: Array<string> | CryptoBag | string
	mySalt: string
	dataToDecrypt: Array<string> | CryptoBag | string
	dataToEncrypt: Array<string> | CryptoBag | string
	dataType: dataType | null

	constructor() {
		this.type = null
		this.password = ''
		this.key = null
		this.keyBox = ''
		this.mySalt = ''
		this.dataToDecrypt = ''
		this.dataToEncrypt = ''
		this.dataType = null
	}
}

export enum dataType {
	ArrayString = 'ArrayString',
	CryptoBag = 'CryptoBag',
	string = 'string',
}

export enum operationType {
	encryptAesCBC = 'encryptAesCBC',
	decryptAesCBC = 'decryptAesCBC',
	bytesToBase64 = 'bytesToBase64',
	applyableToString = 'applyableToString',
}

export enum workerFunctionType {
	decryptBoxWithPwdS = 'decryptBoxWithPwdS',
	decryptDataWithKey = 'decryptDataWithKey',
	decryptDataAllInOne = 'decryptDataAllInOne',
}

// ************************

@Injectable({
	providedIn: 'root',
})
export class CryptoUtilsService {
	static AES_TYPE = 'AES-CBC' // 30.04.2020 nuovo, era 'AES-ECB' su nexy
	static AES_CBC = 'AES-CBC' // 30.04.2020 nuovo

	static PBKDF2_ITERATIONS_MASTER = 5000
	static HASH_BYTES = 32
	static DIGEST = 'sha256'

	static JPEG_BASE64 = 'data:image/jpeg;base64,' // 15.07.2021

	private worker: Worker

	//q: any; //Promise<any>; //angular.IQService;  // 25.08.2022 tolto

	constructor() {
		console.log('(CryptoUtilsService) constructor OK.')

		if (typeof Worker !== 'undefined') {
			this.worker = new Worker(new URL('./service.worker', import.meta.url), { type: 'module' })
		} else {
			console.error('Web Workers are not supported in this environment.')
		}
	}

	private postMessageToWorker(message: postMessageToWorker): Promise<any> {
		const promise = new Promise<any>((resolve, reject) => {
			if (this.worker) {
				// Aggiungo un messageId unico per ogni messaggio inviato
				const messageId = Date.now() + Math.random().toString(36).slice(2, 11)
				message.messageId = messageId

				// Creo un handler generale che gestisca i messaggi in base all'ID
				const handleMessage = ({ data }) => {
					if (data.messageId === messageId) {
						resolve(data.payload)
						this.worker.removeEventListener('message', handleMessage) // Rimuovo l'event listener per evitare accumuli
					}
				}
				// Aggiungo l'event listener senza sovrascrivere onmessage
				this.worker.addEventListener('message', handleMessage)

				// onmessage, vecchio metodo che peró se usato in cicli for se non si usa await veniva sovrascritto e non funzionava
				// this.worker.onmessage = ({ data }) => {
				// 	resolve(data.payload)
				// 	return
				// }
				this.worker.postMessage(message)
			} else {
				reject('Web Worker is not available.')
			}
		})

		return promise
	}

	public decryptDataAllInOneWorker(data: postMessageToWorker): Promise<any> {
		const promise = new Promise<any>((resolve, reject) => {
			data.type = workerFunctionType.decryptDataAllInOne
			this.postMessageToWorker(data).then((result) => {
				resolve(result)
			})
		})
		return promise
	}

	public decryptDataWithKeyWorker(data: postMessageToWorker): Promise<any> {
		const promise = new Promise<any>((resolve, reject) => {
			data.type = workerFunctionType.decryptDataWithKey
			this.postMessageToWorker(data).then((result) => {
				resolve(result)
			})
		})
		return promise
	}

	public decryptBoxWithPwdSWorker(data: postMessageToWorker): Promise<any> {
		const promise = new Promise<any>((resolve, reject) => {
			data.type = workerFunctionType.decryptBoxWithPwdS
			this.postMessageToWorker(data).then((result) => {
				resolve(result)
			})
		})
		return promise
	}

	generateBag(): CryptoBag {
		return new CryptoBag()
	}

	purge(bag: CryptoBag) {
		for (var property in bag) {
			if (bag.hasOwnProperty(property)) {
				if (!bag[property]) {
					delete bag[property]
				}
			}
		}
	}

	/**
    From bytes to bytes
  */
	private decrypt(key: forge.util.ByteStringBuffer, data, transformation): Promise<any> {
		try {
			//return this.q.when(this.decryptCBC(key, data, transformation));
			return Promise.resolve(this.decryptCBC(key, data, transformation))
		} catch (e) {
			console.log('(decrypt) exception ' + e) // 24.03.2017
			//return this.q.reject(e);
			return Promise.reject(e)
		}
	}

	/**
    From bytes to bytes, 
  */
	// key built by the caller
	private encrypt(key, data, transformation) {
		try {
			return Promise.resolve(this.encryptCBC(key, data, transformation))
		} catch (e) {
			//return this.q.reject(e);  // 25.08.2022
			return Promise.reject(e)
		}
	}

	// 04.09.2018 per keybox_vice, senza promise --ls
	public decryptBoxWithPwdS(password, data, mySalt) {
		var key = this.createAesKeyS(password, mySalt)

		//return this.decryptAes(key, data, "base64ToBytes");
		return this.decryptCBC(key, data, 'base64ToBytes')
	}

	/**
  From bytes to bytes, without salt
  */
	private decryptWithPwd(password, data, transformation) {
		var key = this.createAesKey(password)
		return this.decrypt(key, data, transformation)
	}

	private decryptWithPwdS(password, data, mySalt, transformation) {
		//console.log("decryptWithPwdS - 1"); // ok
		var key = this.createAesKeyS(password, mySalt)
		//console.log("decryptWithPwdS - 2"); // ok
		return this.decrypt(key, data, transformation)
	}

	/*
    // 23.01.2017 differenziate funzioni che hanno gia' la key per encrypt
    private encryptWithPwd(password, data, transformation) {    
      var key = this.createAesKey(password);
      return this.encrypt(key, data, transformation); 
    }
  */

	// 04.09.2018 nuova funzione piu' solida per utenti vice e admins --ls
	private encryptWithPwdS(password, data, mySalt, transformation) {
		var key = this.createAesKeyS(password, mySalt)
		return this.encrypt(key, data, transformation)
	}

	// viene richiamata per aprire le keybox
	// (quindi NON convertire in stringa, ci sono anche dati binari)
	//public decryptDataWithPwd(password, data): Promise<string> {
	public decryptDataWithPuk(puk, data): Promise<string> {
		// prima converte dalla base64, poi fa decryptAES
		return this.decryptWithPwd(puk, data, 'base64ToBytes') // non serve salatura con il puk
	}

	/**
   * 23.01.2017 questa va richiamata nel fare le keybox con il PUK
    Encrypt from data to encryptedBytes to base64
  */
	//public encryptDataWithPwd(password, data): Promise<string> {
	public encryptDataWithPuk(puk, data): Promise<string> {
		var key = this.createAesKey(puk) // non serve salatura
		//return this.encryptWithPwd(puk, data, null)
		return this.encrypt(key, data, null).then((bytes) => {
			var base64 = this.applyToOutput('bytesToBase64', bytes)

			// 18.01.2017 aggiunta conversione in stringa --ls
			var printable = this.base64ToString(base64)
			return printable
		})
	}

	// usato anche per crittare i dati paziente da mandare a Vistel
	// 30.04.2020 creo una keybox crittando gia' con una key
	public encryptDataWithMyKey(key, data): Promise<string> {
		//var key = angular.copy(myKey);

		//return this.encryptWithPwd(puk, data, null)
		return this.encrypt(key, data, null).then((bytes) => {
			var base64 = this.applyToOutput('bytesToBase64', bytes)

			// 18.01.2017 aggiunta conversione in stringa --ls
			var printable = this.base64ToString(base64)
			return printable
		})
	}

	// 04.09.2018 aggiunto salt --ls
	public decryptDataWithPwdS(password, data, mySalt): Promise<forge.util.ByteStringBuffer> {
		//console.log("decryptDataWithPwdS - inizio");
		// prima converte dalla base64, poi fa decryptAES
		return this.decryptWithPwdS(password, data, mySalt, 'base64ToBytes')
	}

	// 04.09.2018 aggiunto usrname per salare la key --ls
	// viene richiamata nel fare le keybox
	public encryptDataWithPwdS(password, data, usrname): Promise<string> {
		return this.encryptWithPwdS(password, data, usrname, null).then((bytes) => {
			// 18.01.2017 aggiunta conversione in stringa --ls
			var base64 = this.applyToOutput('bytesToBase64', bytes)
			var printable = this.base64ToString(base64)
			return printable
		})
	}

	// 23.01.2017 alias piu' chiaro, vd decryptFromBase64ToBase64Content
	// questa usa gia' la secretKey come pwd, quindi non ne genera una nuova per AES
	// viene richiamata per esempio sul logo per il pdf, sul display_name, sulla firma
	public decryptDataWithKey(key: forge.util.ByteStringBuffer, data): Promise<string> {
		// prima converte dalla base64, poi fa decryptAES
		return this.decrypt(key, data, 'base64ToBytes')
			.then((bytes) => {
				//console.log("(decryptDataWithKey) prima del toString");
				//return this.q.when(this.applyToOutput("applyableToString", bytes));
				return Promise.resolve(this.applyToOutput('applyableToString', bytes)) // 16.11.2021
			})
			.catch((e) => {
				console.log('(decryptDataWithKey) exception ' + e) // 24.03.2017
				//return this.q.reject(e);
				return Promise.reject(e)
			})
	}

	// 23.01.2017 alias piu' chiaro
	// questa usa gia' la secretKey come pwd, quindi non ne genera una nuova per AES
	public encryptDataWithKey(key, data): Promise<string> {
		return this.encryptFromStringToBase64(key, data)
	}

	/**
    Encrypt from data to encryptedBytes to base64
  */
	// questa usa gia' la secretKey come pwd, quindi non ne genera una nuova per AES
	public encryptFromStringToBase64(key, data): Promise<string> {
		//18.08.2021  // la altera ?!
		//var localKey = {...key};  // 18.01.2022 lo fa dentro ApplyAes

		return this.encrypt(key, data, null).then((bytes) => {
			// orig
			//return this.applyToOutput("bytesToBase64", bytes);
			// 18.01.2017 aggiunta conversione in stringa --ls
			var base64 = this.applyToOutput('bytesToBase64', bytes)
			//var base64 = this.bytesToBase64(bytes);
			var printable = this.base64ToString(base64) // 06.11.2018 questa usa encodeUtf8
			//console.log("(encryptFromStringToBase64) "+printable);  // 01.02.2017 --ls
			return printable
		})
	}

	/**
    Decrypt from base64 to encryptedBytes to base64 to String
    usato per decrittare tutte le bag
    
    per le retine usa la nuova decryptImage
    // 13.07.2017 NB: nelle immagini c'e' un base64 aggiuntivo rispetto alle altre funz. di crittografia ?!
  */
	public decryptFromBase64ToBase64Content(key, data): Promise<string> {
		// 18.01.2022 lo fa dentro ApplyAes
		//var localKey = {...key};  // la altera ?!

		return this.decrypt(key, data, 'base64ToBytes').then((bytes) => {
			// 20.12.2017 su campi anagrafici delle bag va gia' bene cosi', ma poi ko su edit paziente --ls
			//return (bytes);

			// 24.01.2017  OK last version
			return this.applyToOutput('applyableToString', bytes)

			//return this.applyToOutput("base64ToString", this.applyToOutput("applyableToString", bytes));  // 19.01.2017 --ls
			//return this.applyToOutput("base64ToString", this.applyToOutput("bytesToBase64", bytes));  // 20.01.2017
		})
	}

	/**
    Encrypt from data to base64 to encryptedBytes to base64
  */
	// usato per esempio per le bag con i dati del profilo o i dati del paziente
	// questa usa gia' la secretKey come pwd, quindi non ne genera una nuova per AES
	public encryptFromStringToBase64Content(key: forge.util.ByteStringBuffer, data): Promise<string> {
		//console.log("(encryptFromStringToBase64Content) inizio");

		//var localKey = {...key};  // la altera ?!  23.01.2017
		var localKey = key // 29.11.2021 viene duplicata su applyAes

		// 20.01.2017 per test
		//return this.encrypt(localKey, data, "stringToBase64")
		//return this.encrypt(key, data, null)
		// 06.11.2018 orig
		//return this.encrypt(localKey, data, null)

		// 06.11.2018 FIX x il cinese, testare
		return this.encrypt(localKey, data, 'stringToBytes').then((bytes) => {
			//console.log("(encryptFromStringToBase64Content) prima di bytesToBase64");
			var base64 = this.applyToOutput('bytesToBase64', bytes)
			// return base64;
			// 20.01.2017 aggiunta conversione in stringa --ls
			//var printable = this.base64ToString(base64);
			//console.log("(encryptFromStringToBase64Content) prima di base64ToString");
			var printable = this.applyToOutput('base64ToString', base64)
			return printable
		})
	}

	// 11.07.2017 usato per critt dell'immagine della firma (arriva gia' in base64)
	// questa usa gia' la secretKey come pwd, quindi non ne genera una nuova per AES
	public encryptImage(key, data): Promise<string> {
		//console.log("(encryptImage) inizio");
		//var localKey = {...key}; // 23.01.2017
		// 18.01.2022 lo fa dentro ApplyAes

		return this.encrypt(key, data, null).then((bytes) => {
			var base64 = this.applyToOutput('bytesToBase64', bytes)
			return base64
		})
	}

	// 21.07.2017 nuova funzione per decrypt immagini binarie, salva spazio su DB
	//
	// fare il decrypt dell' immagine,
	// trasformarlo in stringa base64,
	// aggiungere in testa la parte "data:image/jpeg;base64,"
	// compatibile anche per le immagine a vecchio (gia' in base64)
	// NB: @param data arriva come bag, per compatibilita' a vecchio
	// ritorna direttamente l'immagine, non la bag
	// 29.04.2020 viene usata solo per il recover con puk, come test
	public decryptImage(key, data): Promise<string> {
		//var localKey = {...key};
		// 18.01.2022 lo fa dentro ApplyAes

		// prima applica la transformation (base64ToBytes), poi il decrypt --ls
		return this.decrypt(key, data, 'base64ToBytes').then((bagC) => {
			var isNew = false
			var isPng = false // 28.02.2018
			var iOff = 0
			var img = bagC.image

			// ok
			var testArray = null
			if (img) {
				// 05.07.2018 aggiunto test --ls
				testArray = img.bytes() // fa un clone   09.08.2017
			} else {
				console.log('(decryptImage) KO image!') // 18.05.2021
				return null // o meglio "" ?
			}

			// TODO trovare altro modo per copiare solo i primi 4

			if (testArray) {
				if (testArray[iOff] == 'd' && testArray[iOff + 1] == 'a' && testArray[iOff + 2] == 't' && testArray[iOff + 3] == 'a') {
					//console.log("(decryptImage) versione vecchia! ");
					isNew = false
					// 19.03.2020 non dovrebbe mai passare di qui per le retine,
					// invece per il logo e la firma ci passa.
				} else if (testArray[iOff + 1] == 'P' && testArray[iOff + 2] == 'N' && testArray[iOff + 3] == 'G') {
					isPng = true
					//console.log("(decryptImage) mosaic png "); // OK
					isNew = true // 12.03.2018 fix
				} else {
					//console.log("(decryptImage) versione nuova");
					isNew = true
				}
			}

			// 12.03.2018 test
			//console.log("(decryptImage) inizio, 0: "+testArray[iOff]+" 1: "+testArray[iOff+1]+" 2: "+testArray[iOff+2]+" 3: "+testArray[iOff+3]);

			if (isNew) {
				// 21.07.2017  forziamo la base64 x esporlo a web

				var bag = this.applyToOutput('bytesToBase64', bagC)
				var myImage = bag.image
				var myStr = ''

				// 28.02.2018 se e' un mosaico e' nel formato png
				if (isPng) {
					myStr = 'data:image/png;base64,' + myImage
				} else {
					//myStr = "data:image/jpeg;base64," + myImage.toString();  // non serve
					myStr = 'data:image/jpeg;base64,' + myImage
				}
				return myStr
			} else {
				// arriva gia' in base64, old way
				var bag = this.applyToOutput('applyableToString', bagC)
				return bag.image
			}
		})
	}

	// 06.04.2020 @param data e' una bag di immagini
	// ritorna una bag
	public decryptImages(key, data): Promise<CryptoBag> {
		// 18.01.2022 lo fa dentro ApplyAes
		//var localKey = {...key};

		var bagRis = new CryptoBag()

		//console.log("(decryptImages) inizio ");

		// prima applica la transformation (base64ToBytes), poi il decrypt --ls
		return this.decrypt(key, data, 'base64ToBytes').then((bagC) => {
			for (var property in bagC) {
				//if (bagC.hasOwnProperty(property)) {

				var img = bagC[property]

				var isNew = false
				var isPng = false // 28.02.2018
				var iOff = 0
				var testArray = null

				if (img) {
					//console.log("(decryptImages) p "+property); // 08.01.2021 tolta trace

					testArray = img.bytes() // fa un clone
				}

				if (testArray) {
					if (testArray[iOff] == 'd' && testArray[iOff + 1] == 'a' && testArray[iOff + 2] == 't' && testArray[iOff + 3] == 'a') {
						//console.log("(decryptImages) versione vecchia! ");
						isNew = false // per il logo e la firma ci passa.
					} else if (testArray[iOff + 1] == 'P' && testArray[iOff + 2] == 'N' && testArray[iOff + 3] == 'G') {
						isPng = true
						isNew = true // 12.03.2018 fix
					} else {
						isNew = true
						console.log('(decryptImages) versione nuova')
					}
				} else {
					console.log('(decryptImages) ko testArray')
				}

				// test
				//console.log("(decryptImages) inizio, 0: "+testArray[iOff]+" 1: "+testArray[iOff+1]+" 2: "+testArray[iOff+2]+" 3: "+testArray[iOff+3]);
				//console.log(testArray);

				if (isNew) {
					// forziamo la base64 x esporlo a web

					//var bag = this.applyToOutput("bytesToBase64", bagC);
					//var myImage =  bag.image;

					var myImage = this.applyToOutput('bytesToBase64', img)
					//var myImage = this.applyToOutput("bytesToBase64", testArray);
					var myStr = ''

					// 28.02.2018 se e' un mosaico e' nel formato png
					if (isPng) {
						myStr = 'data:image/png;base64,' + myImage
					} else {
						//myStr = "data:image/jpeg;base64," + myImage.toString();  // non serve
						//myStr = "data:image/jpeg;base64," + myImage;
						myStr = CryptoUtilsService.JPEG_BASE64 + myImage // 15.07.2021
					}
					//return myStr;
					bagRis[property] = myStr
					//console.log("(decryptImages) N got "+property);
					//console.log(bagRis[property]); // OK  07.04.2020
				} else {
					// arriva gia' in base64, old way
					//var bag = this.applyToOutput("applyableToString", bagC);
					//return bag.image;

					var myImage = this.applyToOutput('applyableToString', img)
					bagRis[property] = myImage

					//console.log("(decryptImages) OLD got "+property);
					//console.log(bagRis[property]);
				}

				//}
			}
			//console.log("(decryptImages) exit 1 "); // OK qui
			//return this.q.all(bagRis);
			return Promise.resolve(bagRis) // 16.11.2021

			//return bagRis;
		})

		//console.log("(decryptImages) exit 2 "); // passa di qui prima di fare il decrypt
	}

	// 28.04.2020 decritta bag con campi misti, sia immagini che dati numerici
	// 06.04.2020 @param data e' una bag di immagini
	// ritorna una bag
	public decryptAll(key, data): Promise<CryptoBag> {
		//var localKey = {...key};  // 18.01.2022 fatto dentro applyAes
		var bagRis = new CryptoBag()

		// 28.04.2020 TODO, tenere aggiornato questo array
		// con tutti i nomi dei campi immagine, per i 12 exam_type che hanno immagini e dati (misti)
		var imgTags = [
			'image',
			'axial_map',
			'elevation_map',
			'tangential_map', // topo
			'image_auto',
			'image_man_1',
			'image_man_2',
			'image_man_3', // external
			'image_man_4',
			'image_man_5',
			'image_man_6',
			'image_with_data', // pachy
			'image_grid',
			'image_meso', // wf
			'image_low',
			'image_high', // retro
			'image_central',
			'image_nasal',
			'image_supero_nasal',
			'image_supero_temporal',
			'image_temporal',
			'image_inferior',
			'image_external',
			'image_central_nasal', // fundus
			'signature', // 21.01.2022
		]

		//console.log("(decryptAll) inizio ");  // 27.08.2020 tolta trace

		// prima applica la transformation (base64ToBytes), poi il decrypt --ls
		return this.decrypt(key, data, 'base64ToBytes').then((bagC) => {
			for (var property in bagC) {
				var img = bagC[property]
				var isNew = false
				var isPng = false
				var iOff = 0
				var testArray = null

				// 28.04.2020
				if (imgTags.includes(property)) {
					// e' un campo immagine
					if (img) {
						//console.log("(decryptAll) p "+property); // OK
						testArray = img.bytes() // fa un clone
					}

					if (testArray) {
						if (testArray[iOff] == 'd' && testArray[iOff + 1] == 'a' && testArray[iOff + 2] == 't' && testArray[iOff + 3] == 'a') {
							//console.log("(decryptAll) versione vecchia! ");
							isNew = false // per il logo e la firma ci passa.
						} else {
							isNew = true
							//console.log("(decryptAll) versione nuova");

							if (testArray[iOff + 1] == 'P' && testArray[iOff + 2] == 'N' && testArray[iOff + 3] == 'G') {
								isPng = true
							}
						}
					} else {
						console.log('(decryptAll) ko testArray')
					}
				} else {
					// e' un campo testo
					isNew = false
					//console.log("(decryptAll) text field: "+property); // ok
				}

				// test
				//console.log("(decryptAll) inizio, 0: "+testArray[iOff]+" 1: "+testArray[iOff+1]+" 2: "+testArray[iOff+2]+" 3: "+testArray[iOff+3]);
				//console.log(testArray);

				if (isNew) {
					// forziamo la base64 x esporlo a web
					var myStr = ''
					var myImage = this.applyToOutput('bytesToBase64', img)

					// se e' un mosaico e' nel formato png
					if (isPng) {
						myStr = 'data:image/png;base64,' + myImage
					} else {
						//myStr = "data:image/jpeg;base64," + myImage;
						myStr = CryptoUtilsService.JPEG_BASE64 + myImage // 15.07.2021
					}

					bagRis[property] = myStr
				} else {
					// arriva gia' in base64, old way
					var myImage = this.applyToOutput('applyableToString', img)
					bagRis[property] = myImage
				}
			}
			//console.log("(decryptAll) exit 1 "); // OK qui
			//return this.q.all(bagRis);
			return Promise.resolve(bagRis) // 16.11.2021
		})

		//console.log("(decryptImages) exit 2 "); // passa di qui prima di fare il decrypt
	}

	// 13.02.2017 bastano 10-15 caratteri ASCII, non serve che sia una chiave --ls
	// e' come una seconda pwd, in base 64 per essere stampata
	public generatePUK(): Promise<string> {
		var randomBytes = forge.random.getBytesSync(12) // 20.02.2017 aumentato da 10 a 12 --ls
		var byteBuffer = forge.util.createBuffer(randomBytes)
		return Promise.resolve(this.bytesToBase64(byteBuffer))
	}

	public generateRandomString(): Promise<string> {
		var randomBytes = forge.random.getBytesSync(32)
		var byteBuffer = forge.util.createBuffer(randomBytes)
		return Promise.resolve(this.bytesToBase64(byteBuffer))
	}

	//23.01.2017 richiamate da session per generare le keyPrivacy e keyDoctor --ls
	// puo' contenere anche val binari
	public generateRandomKeyAES(): Promise<string> {
		var randomBytes = forge.random.getBytesSync(32)
		//var byteBuffer = util.createBuffer(randomBytes);

		// 30.04.2020 non serve, e' gia' random e lunga abbastanza
		//var myKey = this.createAesKey(byteBuffer);
		//console.log("(generateRandomKeyAES) v1: "+myKey); // con binari

		return Promise.resolve(randomBytes)
	}

	private createAesKey(password): forge.util.ByteStringBuffer {
		var input = password
		var salt = forge.random.getBytesSync(0) // EMPTY!!
		var md = forge.md.sha256.create()

		let derivedKey: forge.util.ByteStringBuffer

		let tmp = forge.pkcs5.pbkdf2(input, salt, CryptoUtilsService.PBKDF2_ITERATIONS_MASTER, CryptoUtilsService.HASH_BYTES, md)

		//derivedKey = this.stringToBytes(tmp);
		derivedKey = new forge.util.ByteStringBuffer(tmp)

		return derivedKey
	}

	// 04.09.2018 nuova funzione piu' sicura,
	// richiamare con salt = username utente, cosi' anche a pari pwd, la key viene diversa
	private createAesKeyS(password, mySalt): forge.util.ByteStringBuffer {
		var input = password
		var salt = mySalt // 29.04.2020

		var md = forge.md.sha256.create()

		var derivedKey = forge.pkcs5.pbkdf2(input, salt, CryptoUtilsService.PBKDF2_ITERATIONS_MASTER, CryptoUtilsService.HASH_BYTES, md)
		//CryptoUtilsService.DIGEST);

		//console.log("(createAesKeyS) dopo pbkdf2"); // ok

		//return derivedKey;  // 17.11.2021
		return new forge.util.ByteStringBuffer(derivedKey)
	}

	// 30.04.2020
	public encryptAesCBC(key, content) {
		let iv = forge.random.getBytesSync(16) // initialization vector

		//console.log("(encryptAesCBC) inizio, iv len: "+iv.length); // ok 16

		//let cipher = forge.cipher.createCipher(CryptoUtilsService.AES_CBC, key);
		let cipher = forge.cipher.createCipher('AES-CBC', key)

		//console.log("(encryptAesCBC) 2");

		cipher.start({ iv: iv })
		cipher.update(forge.util.createBuffer(content))
		cipher.finish()

		//console.log("(encryptAesCBC) 3");

		//prepend iv to the output
		//var encrypted = cipher.output;
		var encrypted = forge.util.createBuffer()
		encrypted.putBytes(iv)
		encrypted.putBuffer(cipher.output)

		//console.log("(encryptAesCBC) len cri: "+cipher.output.length); // undefined

		// 29.11.2021 No promise
		// 16.11.2021 aggiunta promise, come per altre volte su chiamata random - serve ??
		return encrypted
		//return Promise.resolve(encrypted);
	}

	// 30.04.2020
	public decryptAesCBC(key: string, content) {
		//console.log("(decryptAesCBC) inizio"); // , key len: "+key.length);  // ok 32 per la keybox

		//let input = forge.util.createBuffer(content, 'binary');  // 16.11.2021
		let input = forge.util.createBuffer(content)

		// read the iv and take it away from the input
		let iv = input.getBytes(16) // inizialization vector

		//let decipher = forge.cipher.createDecipher(CryptoUtilsService.AES_CBC, key);
		let decipher = forge.cipher.createDecipher('AES-CBC', key)
		decipher.start({ iv: iv })
		decipher.update(input)
		//console.log("(decryptAesCBC) decipher 2 ");
		decipher.finish()

		let plain = decipher.output
		//let plain = decipher.output.toString();   // 17.11.2021 no!

		//console.log("(decryptAesCBC) decipher 3 ");

		return plain
	}

	// 30.04.2020 usiamo CBC sempre

	// 30.04.2020
	public encryptCBC(key, content, transformation) {
		return this.applyAes('encryptAesCBC', key, content, transformation)
	}

	public decryptCBC(key, content, transformation) {
		//console.log("(decryptCBC) prima di decryptAesCBC, key len: "+key.length);
		return this.applyAes('decryptAesCBC', key, content, transformation)
	}

	// prima applica la transformation (al content), poi il type --ls
	// es: transformation = base64ToBytes, type = decryptAesCBC
	private applyAes(type: string, key: forge.util.ByteStringBuffer, content: Array<string> | CryptoBag | string, transformation: string) {
		//console.log("(applyAes) inizio, type:"+type+" transf:"+transformation);

		var result: any

		// 03.02.2017
		if (content == null) {
			//Util.debug("(applyAes) nothing to do, null bag!");
			return result
		}

		if (key == null) {
			console.log('(applyAes) key is null!')
			return result // 07.06.2023
		}

		// 16.11.2021 patch per sostituire angular.copy [ls]
		// aes la altera  23.01.2017
		let localKey: forge.util.ByteStringBuffer

		if (Array.isArray(content)) {
			result = new Array<string>()
			//console.log("(applyAes) isArray "+type);

			for (let i = 0; i < content.length; i++) {
				try {
					// es: transformation = base64ToBytes
					//console.log("(applyAes) array - [" + transformation + "] elem: " + i ); // 16.11.2021

					if (content[i] == null) {
						console.log('(applyAes) elem content n. ' + i + ' nullo')

						result.push(null) // 17.11.2021
						continue // 17.11.2021
					}

					// 17.11.2021 nuovo oggetto ogni volta
					localKey = new forge.util.ByteStringBuffer(key)

					// 16.11.2021
					//var c = transformation ? this[transformation](content) : content;
					var c = transformation ? this[transformation](content[i]) : content[i]

					if (c == null) {
						console.log('(applyAes) elem n. ' + i + ' transformed nullo')
					}

					// es: type = decryptAesCBC
					result.push(this[type](localKey, c))
					//result.push(this[type](key, c));
				} catch (err) {
					// es: type = decryptAesCBC
					console.log('(applyAes) array - [' + type + '] elem: ' + i + ' err: ' + err)
				}
			}

			// 16.11.2021
			return Promise.resolve(result)
			//return result;
		} else if (content instanceof CryptoBag) {
			//console.log("(applyAes) Bag - type: "+type+" transf: "+transformation);
			result = new CryptoBag()
			var iTot = 0

			for (var property in content) {
				if (content.hasOwnProperty(property)) {
					iTot++

					if (content[property] != null && content[property].length > 0) {
						try {
							//console.log("(applyAes) "+iTot+" BAG "+type+" prop: "+property);  // 23.01.2017

							var c = transformation ? this[transformation](content[property]) : content[property]

							// 06.11.2018 test ko
							//var c = transformation ? this[transformation](content[property]) : (content[property]).toString('utf8');

							// aes la altera  23.01.2017
							// 17.11.2021 nuovo oggetto ogni volta
							localKey = new forge.util.ByteStringBuffer(key)

							result[property] = this[type](localKey, c)
						} catch (err) {
							// es: (applyAes) [decryptAesCBC] BAG 1 prop firstname err: Error: Invalid key parameter.
							Util.debug('(applyAes) [' + type + '] BAG ' + iTot + ' prop ' + property + ' err: ' + err)
							// 10.02.2017 meglio di no, cosi'continua con gli altri campi della bag --ls
							//throw err;   // 08.02.2017 verificare se ok o fa peggio --ls
						}
					}
				}
			}
			//return result;
			return Promise.resolve(result) // 17.11.2021
		} else {
			var c = transformation ? this[transformation](content) : content
			//console.log("(applyAes) basic - "+type+" transf: "+transformation);
			// aes la altera  23.01.2017
			let localKey: forge.util.ByteStringBuffer
			localKey = new forge.util.ByteStringBuffer(key)
			let result = this[type](localKey, c)
			return result
		}
	}

	private applyToOutput(type, content) {
		var result

		if (content == null)
			// 03.02.2017 --ls
			return result

		//console.log("(applyToOutput) inizio "+type); // applyableToString

		if (Array.isArray(content)) {
			console.log('(applyToOutput) - array ')
			result = []
			for (var i = 0; i < content.length; i++) {
				result[i] = this[type](content)
			}
			return result
		} else if (content instanceof CryptoBag) {
			//console.log("(applyToOutput) - bag ");
			result = new CryptoBag()
			for (var property in content) {
				if (content.hasOwnProperty(property)) {
					//Util.debug("(applyToOutput) "+type+" prop: "+property);  // 20.01.2017
					if (content[property] != null)
						// 07.02.2017
						result[property] = this[type](content[property])
					//else
					//  result[property] = null;  // 07.02.2017 meglio stringa vuota ?
				}
			}
			return result
		} else {
			// 09.01.2017 --ls
			if (content != null) {
				//console.log("(applyToOutput) basic - ok content, type: "+type);
			} else {
				console.log('(applyToOutput) ko content')
			}
			return this[type](content)
		}
	}

	/**
    Public only for debug purpose
  */
	public bytesToBase64(byteStringBuffer): string {
		//console.log("(bytesToBase64) inizio");

		// 06.11.2018 orig
		return forge.util.encode64(byteStringBuffer.data)

		// 06.11.2018 test inutile
		//console.log("(bytesToBase64) TEST ");
		//var myInp = byteStringBuffer.getBytes('utf8');
		//return util.encode64(myInp);
	}

	/*
  // 02.02.2017
  public pukToBase64(bytePuk) : string {  	
    return util.encode64(bytePuk);
  }
*/

	/**
    Public only for debug purpose
  */
	public stringToBase64(str): string {
		//console.log("(stringToBase64) inizio");
		return forge.util.encode64(str)
	}

	/**
    Public only for debug purpose
  */
	public bytesToHex(bytes): string {
		//console.log("(bytesToHex) inizio");
		return forge.util.bytesToHex(bytes)
	}

	/**
    Public only for debug purpose
  */
	public base64ToBytes(base64): string {
		//console.log("(base64ToBytes) inizio");

		return forge.util.decode64(base64)

		// 06.11.2018 test inutile
		//var ris =  util.decode64(base64);
		//return ris.toString('utf8');
	}

	/**
    Public only for debug purpose
  */
	public base64ToString(base64): string {
		// orig, ko, errore nella decode
		//var valO = util.decode64(base64);
		//console.log("(base64ToString) O:"+valO);

		// 18.01.2017 FIX  --ls
		var val = forge.util.encodeUtf8(base64)
		//console.log("(base64ToString) Utf8: "+val);

		return val
	}

	/**
    Public only for debug purpose
  */
	public applyableToString(output): string {
		// 19.01.2017 modificato --ls
		//return output.toString();
		let printable: string
		printable = null // o meglio "" ? --ls

		if (output) {
			// 03.02.2017 aggiunto test --ls

			// output e' un ByteStringBuffer
			//printable = output.toString('utf8'); // 22.06.2022 ORIG
			//printable = output.toString('utf16le');  // 22.06.2022 test

			// 22.06.2022 patch [ls]
			try {
				printable = output.toString('utf8')
			} catch (e) {
				printable = '' + output.data
			}
		}

		//console.log("(applyableToString) "+printable);
		return printable
	}

	// 06.11.2018 FIX
	public stringToBytes(message) {
		let buffer = forge.util.createBuffer(message, 'utf8')
		let binaryString = buffer.getBytes()
		return binaryString
	}

	/*
// 20.01.2017
  public stringToBytes(input) : string {
    var str2 = util.createBuffer(input, 'utf8');
    //console.log("(stringToBytes) "+str2);    
    var str1 = input.getBytes();
    //console.log("(stringToBytes) "+str1);    
    return str1;
  }
  */

	/*
  private parseHex(hexValue) : string {
    return this.CryptoJS.enc.Hex['parse'](hexValue);
  }  
  private toUtf8String(value) : string {
    return value.toString(this.CryptoJS.enc.Utf8);
  }*/

	// 18.01.2017 --ls
	public encodeUtf8(base64): string {
		//console.log("(encodeUtf8) inizio");
		return forge.util.encodeUtf8(base64)
	}

	// 17.01.2020   // senza promise
	getKeyPair() {
		let rsa = forge.pki.rsa

		// generate an RSA key pair synchronously
		// *NOT RECOMMENDED*: Can be significantly slower than async and may block
		// JavaScript execution. Will use native Node.js 10.12.0+ API if possible.

		let keypair = rsa.generateKeyPair({ bits: 2048, e: 0x10001 })
		return keypair
	}

	// con promise, piu' veloce ?  ERR Cannot GET /forge/prime.worker.js
	genKeyPair() {
		console.log('(genKeyPair) begin')
		return new Promise((resolve, reject) => {
			//var rsa = pki.rsa;

			// ERR: Cannot GET /forge/prime.worker.js
			//pki.rsa.generateKeyPair({bits: 2048, workers: -1}, function (err, keypair) {
			forge.pki.rsa.generateKeyPair({ bits: 2048, e: 0x10001 }, function (err, keypair) {
				//rsa.generateKeyPair({bits: 2048, workers: -1 }, function(err, keypair) {
				//rsa.generateKeyPair({bits: 2048, e: 0x10001}, (err, keypair) => {
				if (err) {
					console.log('(genKeyPair) error ' + err)
					reject(err)
					//throw("Error: "+msg);
				} else {
					// keypair.privateKey, keypair.publicKey
					console.log('(genKeyPair) done')

					//resolve(keypair);
					resolve({ keypair })
				}
			})
		}).catch((err) => {
			let msg = err.data ? err.data.error : err.toString()
			console.log('(genKeyPair) KO keyPair ' + msg)
			throw err
		})
	}

	/*
genKeyPair(){
  console.log("(genKeyPair) begin");  
  new Promise((f, r) => nodeforge.pki.rsa.generateKeyPair(
    2048, (err, pair) => err ? r(err) : f(pair)))
    .then(keypair => {         
        console.log("(genKeyPair) done");
        return keypair;
    })
    .catch(err => console.log(err)
    );
}
*/

	/*
      if (err) {
        reject(err)
    } else {
        let privateKey = fg.util.bytesToHex(fg.asn1.toDer(fg.pki.wrapRsaPrivateKey(fg.pki.privateKeyToAsn1(keypair.privateKey))).getBytes());
        let publicKey = fg.util.bytesToHex(fg.asn1.toDer(fg.pki.publicKeyToAsn1(keypair.publicKey)).getBytes());
        
        resolve({ publicKey, privateKey })
    }
  
  */

	/*
  // generate an RSA key pair asynchronously (uses web workers if available)
  // use workers: -1 to run a fast core estimator to optimize # of workers
  // *RECOMMENDED*: Can be significantly faster than sync. Will use native
  // Node.js 10.12.0+ or WebCrypto API if possible.
  rsa.generateKeyPair({bits: 2048, workers: 2}, function(err, keypair) {
    // keypair.privateKey, keypair.publicKey
  });

  poi  
  
  // encrypt data with a public key (defaults to RSAES PKCS#1 v1.5)
  var encrypted = publicKey.encrypt(bytes);

  // decrypt data with a private key (defaults to RSAES PKCS#1 v1.5)
  var decrypted = privateKey.decrypt(encrypted);

  // encrypt data with a public key using RSAES PKCS#1 v1.5
  var encrypted = publicKey.encrypt(bytes, 'RSAES-PKCS1-V1_5');

  // decrypt data with a private key using RSAES PKCS#1 v1.5
  var decrypted = privateKey.decrypt(encrypted, 'RSAES-PKCS1-V1_5');

  vd. https://github.com/digitalbazaar/forge

  */

	/*
  if(keypair != null){
  
    // convert a Forge public key to PEM-format (which is binary encoded as base64)
    var pem1 = pki.publicKeyToPem(keypair.publicKey);
    console.log("(getKeyPair) public: "+pem1); // TODO: then save on DB 

    var pem2 = pki.privateKeyToPem(keypair.privateKey);
    console.log("(getKeyPair) private: "+pem2);

    // ritorno:
    // convert a PEM-formatted public key to a Forge public key
    // var publicKey = pki.publicKeyFromPem(pem);
  }
  */

	// 19.03.2020
	/*** SHA256 */
	getSHA256(myMsg: string) {
		let md = forge.md.sha256.create()
		//md.start();
		md.digest()
		md.update(myMsg, 'utf8')
		let digest_sha256 = md.digest().toHex()
		return digest_sha256
	}

	// 14.03.2022 portate fuori da session

	publicKeyToPem(publicKey) {
		return forge.pki.publicKeyToPem(publicKey)
	}

	privateKeyToPem(privateKey) {
		return forge.pki.privateKeyToPem(privateKey)
	}

	// 07.06.2023 il ritorno, da Pem a key, per poi fare encrypt
	privateKeyFromPem(pKey) {
		let privateKey = forge.pki.privateKeyFromPem(pKey)
		return privateKey
	}

	publicKeyFromPem(pKey) {
		let pubKey = forge.pki.publicKeyFromPem(pKey)
		return pubKey
	}

	// 15.03.2022
	public compareKeys(keyA: forge.util.ByteStringBuffer, keyB): boolean {
		// con angular1:
		// angular.equals(keyA, keyB)

		//let options = {includeBitStringContents: true};
		//let ret = forge.asn1.compare(keyA, keyB, options);  // ko
		//let ret = (keyA.toString() == keyB.toString()); // KO
		let ret = keyA.toHex() == keyB.toHex()
		return ret
	}

	// ************************************************************
	// 17.11.2021
	// prima applica la transformation (al content), poi il type --ls
	// es: transformation = base64ToBytes, type = decryptAesCBC
	public decryptBag(key: forge.util.ByteStringBuffer, content: Array<string>): Promise<Array<string>> {
		console.log('(decryptBag) inizio')
		var result: Array<string>

		if (content == null) {
			console.log('(decryptBag) nothing to do')
			return null
		}

		if (key == null) {
			console.log('(decryptBag) key nulla!')
			return null
		}

		result = new Array<string>()

		//console.log("(applyAes) isArray "+type);
		for (let i = 0; i < content.length; i++) {
			try {
				if (content[i] == null) {
					console.log('(decryptBag) elem content nullo')
					//result.push(null); // 17.11.2021  TODO
					continue // 17.11.2021
				}

				// 16.11.2021
				let c = this.base64ToBytes(content[i])

				if (c == null) {
					console.log('(decryptBag) elem transformed nullo')
				}

				//let localKey = JSON.parse(JSON.stringify(key.toString()));
				let localKey: forge.util.ByteStringBuffer
				localKey = new forge.util.ByteStringBuffer(key)

				// inglobata la funzione decryptAesCBC  *******
				let input = forge.util.createBuffer(c)

				// read the iv and take it away from the input
				let iv = input.getBytes(16) // inizialization vector

				//let decipher = forge.cipher.createDecipher(CryptoUtilsService.AES_CBC, key);
				//let decipher = forge.cipher.createDecipher('AES-CBC', cloneKey[i]);
				//let decipher = forge.cipher.createDecipher('AES-CBC', key);
				let decipher = forge.cipher.createDecipher('AES-CBC', localKey)

				decipher.start({ iv: iv })
				decipher.update(input)
				console.log('(decryptBag) decipher 2 ')
				decipher.finish()
				let plain = decipher.output.toString()
				console.log('(decryptBag) decipher 3 ')

				//return plain;
				result.push(plain)
			} catch (err) {
				// es: type = decryptAesCBC
				console.log('(decryptBag) elem: ' + i + ' err: ' + err)
			}
		}

		// 16.11.2021
		return Promise.resolve(result)
	}

	// 07.06.2022 serve ??
	public copyKey(origKey) {
		let localKey: forge.util.ByteStringBuffer
		localKey = new forge.util.ByteStringBuffer(origKey)
		return localKey
	}

	// 26.07.2022 AES-CBC con vettore iv esterno
	public decryptAiReport(key: forge.util.ByteStringBuffer, base64Data: string, iv: string): Promise<any> {
		// prima converte dalla base64, poi fa decryptAES, ma con iv passato come parametro
		// il pdf e' un binario, non va forzato applyableToString
		return Promise.resolve(this.myAiDecrypt(key, base64Data, iv))
		//return Promise.resolve(this.myVistelTest(base64Data));  // 28.07.2022
	}

	// 24.08.2022
	// 26.07.2022 with external iv
	private myAiDecrypt(key: forge.util.ByteStringBuffer, base64Data: string, ivB: string) {
		// aes la altera  23.01.2017
		let localKey = new forge.util.ByteStringBuffer(key) // clone

		//localKey = new forge.util.ByteStringBuffer(key.toString());
		//console.log("(myAiDecrypt) hex key:"+ forge.util.bytesToHex(key.toString()));

		let content = this.base64ToBytes(base64Data)

		let input = forge.util.createBuffer(content)
		//let input = forge.util.createBuffer(content, 'utf8');  // 29.07.2022 ko

		let iv = forge.util.decode64(ivB)

		// 25.08.2022 funzionano entrambi, valutare performance ?
		//let plain = this.decryptBigData(input, localKey, iv);
		let plain = this.decryptSingleShot(input, localKey, iv)

		return plain
	}

	// 26.07.2022 with chunks
	// https://github.com/digitalbazaar/forge#cipher
	private decryptBigData(encrypted, key, iv) {
		//var encryptedBytes = encrypted.bytes('utf8');  // test
		let encryptedBytes = encrypted.bytes()

		let decipher = forge.cipher.createDecipher('AES-CBC', key)
		decipher.start({ iv: iv })

		var length = encryptedBytes.length
		var chunkSize = 1024 * 64
		var index = 0
		var decrypted = ''
		do {
			decrypted += decipher.output.getBytes()
			let buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize))
			//var buf = forge.util.createBuffer(encryptedBytes.substr(index, chunkSize), 'utf8'); // 29.07.2022

			decipher.update(buf)
			index += chunkSize
		} while (index < length)

		let rc = decipher.finish()
		console.log('(chunks) done ? ' + rc)

		decrypted += decipher.output.getBytes() // ultimo pezzo

		return decrypted
	}

	// unico processo, decritta tutto insieme
	private decryptSingleShot(encrypted, key, iv) {
		// the default padding for AES-CBC is PKCS#7
		let decipher = forge.cipher.createDecipher('AES-CBC', key)
		decipher.start({ iv: iv })
		decipher.update(encrypted)
		let rc = decipher.finish()
		console.log('(singleShot) done ? ' + rc) // ok true

		//let plain = decipher.output;
		let plain = decipher.output.getBytes() // 29.07.2022 cosi' ok

		//console.log("(singleShot) hex completo: ");
		//console.log(hexPlain);

		return plain
	}
}

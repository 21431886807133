import { Component, OnInit } from '@angular/core'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { ChangeGraderFormQuestion } from 'src/app/models/changeGrader.model'
import { ToastOptions } from 'src/app/models/toast.model'
import { Util } from 'src/app/models/util.model'
import { SessionService } from 'src/app/service/session.service'
import { AppToastService } from 'src/app/service/toast.service'

@Component({
	selector: 'app-request-change-grader-modal',
	templateUrl: './request-change-grader.modal.html',
	styleUrls: ['./request-change-grader.modal.scss'],
})
export class RequestChangeGraderModalContent implements OnInit {
	formQuestions: ChangeGraderFormQuestion[]
	formValid: { id: number; reply: boolean }[]
	canSendForm: boolean
	loading: boolean
	faSpinner = faSpinner

	constructor(public session: SessionService, public activeModal: NgbActiveModal, private translator: TranslateService, private toastService: AppToastService) {}

	ngOnInit() {
		this.formQuestions = []
		this.formValid = []
		this.canSendForm = false
		this.loading = true

		this.session
			.loadChangeGraderFormQuestions()
			.then((questions) => {
				this.formQuestions = questions && questions.length > 0 ? questions : []
				this.formQuestions.forEach((question) => {
					question.replies.forEach((reply) => {
						reply.checked = false
					})
				})
			})
			.catch((err) => {
				Util.debug('(loadChangeGraderFormQuestions) KO ' + this.session.parseErrorMessage(err))
				this.formQuestions = []
			})
			.finally(() => {
				this.formValid = this.formQuestions.map((question) => {
					return {
						id: question.question_id,
						reply: false,
					}
				})

				this.loading = false
			})
	}

	toggle(obj: ChangeGraderFormQuestion, n: number) {
		// const replies = obj.replies
		// replies.forEach((reply, i) => {
		// 	// set checked to true only for the given n index
		// 	// reply.checked = i === n
		// })

		// save replied question + check if all have been answered
		// this.canSendForm = this.formValid.filter((el) => el.reply).length === this.formValid.length
		// this.formValid.find((el) => el.id === obj.question_id).reply = true

		const questionIndex = this.formQuestions.findIndex((question) => question.question_id === obj.question_id)
		// ugly workaround to track positive answer
		const replyIndex = obj.replies.findIndex((reply) => reply.reply_id === 901)
		this.formQuestions[questionIndex].replies[replyIndex].checked = n === 1

		const repliedQuestions = this.formQuestions.filter((question) => {
			return question.replies.filter((reply) => reply.checked).length > 0
		})

		this.canSendForm = repliedQuestions.length > 0
	}

	sendForm() {
		this.loading = true
		const repliesList = this.buildReplies()
		this.session
			.sendChangeGraderForm(repliesList)
			.then(() => {
				this.loading = false
				const header = this.translator.instant('CHANGE_GRADER_MODAL.NEW_GRADER_REQUEST_NOTIFICATION_TITLE')
				const body = this.translator.instant('CHANGE_GRADER_MODAL.NEW_GRADER_REQUEST_NOTIFICATION_SUCCESS')
				const options = new ToastOptions('success')
				this.toastService.show(header, body, false, options, 'center')
				this.activeModal.close()
			})
			.catch((err) => {
				Util.debug('(sendChangeGraderForm) KO ' + this.session.parseErrorMessage(err))
				this.loading = false
				const header = this.translator.instant('CHANGE_GRADER_MODAL.NEW_GRADER_REQUEST_NOTIFICATION_TITLE')
				const body = this.translator.instant('CHANGE_GRADER_MODAL.NEW_GRADER_REQUEST_NOTIFICATION_ERROR')
				const options = new ToastOptions('error')
				this.toastService.show(header, body, false, options, 'center')
				this.activeModal.close()
			})
	}

	buildReplies() {
		const repliesList: { question_id: number; reply_id: number }[] = this.formQuestions.map((question) => {
			const chosenReply = question.replies.find((reply) => reply.checked)
			// ugly workaround to get negative answer, if question is unchecked
			const negativeReply = question.replies.find((reply) => reply.reply_id === 902)

			return {
				question_id: question.question_id,
				reply_id: chosenReply ? chosenReply.reply_id : negativeReply.reply_id,
			}
		})

		return repliesList
	}

	dismiss() {
		this.activeModal.dismiss()
	}
}

import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { TranslateService } from '@ngx-translate/core'
import { Doctor } from 'src/app/models/doctor.model'
import { Events, UserEvents } from 'src/app/models/userEvents.model'
import { Util } from 'src/app/models/util.model'
import { SessionService } from 'src/app/service/session.service'

import { faFileCsv, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CsvLine } from 'src/app/models/csvLine.model'

@Component({
	selector: 'user-events-modal',
	templateUrl: './userEvent.modal.html',
	styleUrls: ['./userEvent.modal.scss'],
})
export class UserEventsModal implements OnInit {
	@Input() list: UserEvents[]
	@Input() isLevel1: boolean
	@Input() userId: number
	@Input() userName: string
	@ViewChild(MatPaginator) paginator: MatPaginator //10.10.22
	@ViewChild(MatSort) sort: MatSort //10.10.22

	eventList: MatTableDataSource<UserEvents>

	isloading: boolean
	loadCSV: boolean

	displayedColumns: string[]

	loadEvents: Promise<any>

	faFileCsv = faFileCsv
	faSpinner = faSpinner

	constructor(public session: SessionService, public activeModal: NgbActiveModal, public translator: TranslateService) {
		Util.debug(' (UserEventsModal) - constructor')
		this.displayedColumns = ['creation_date', 'event_type', 'details']
		this.isloading = true
		this.loadCSV = false
		this.userId = null
		this.userName = ''
	}

	ngOnInit(): void {
		Util.debug(' (UserEventsModal) - ngOnInit')

		if (this.isLevel1) {
			this.displayedColumns.push('created_by')
		} else {
			this.displayedColumns.push('user_id')
		}

		this.loadEvents.then(() => {
			this.isloading = false

			this.eventList = new MatTableDataSource<UserEvents>(this.list)

			this.sort.active = 'creation_date'
			this.sort.direction = 'desc'

			this.eventList.paginator = this.paginator
			this.eventList.sort = this.sort
		})
	}

	public exportToCSV() {
		Util.debug('(UserEventsModal) - exportToCSV')

		this.loadCSV = true

		let filterList = this.eventList.filteredData.slice()

		let exportFilterList: any[]
		let el: any

		exportFilterList = []

		for (let i = 0; i < filterList.length; i++) {
			const act = filterList[i]

			if (this.isLevel1) {
				el = {
					creation_date: this.session.formatDateTime(act.creation_date),
					event_type: this.translator.instant('USER_EVENTS.' + act.event_type),
					details: act.details.replaceAll(',', ''),
					created_by: act.created_by,
				}
			} else {
				el = {
					creation_date: this.session.formatDateTime(act.creation_date),
					event_type: this.translator.instant('USER_EVENTS.' + act.event_type),
					details: act.details.replaceAll(',', ''),
					user_id: act.user_id,
				}
			}

			exportFilterList.push(el)
		}

		let tmp = Util.convertToCSV(exportFilterList)

		let strBuffer = Util.replaceAll(tmp, ',', CsvLine.SEP)
		if (this.userName != '') {
			var filename = 'export' + '_' + this.userName + '_' + this.userId + '_' + Date.now() + '.csv'
		} else {
			var filename = 'export' + '_' + this.userId + '_' + Date.now() + '.csv'
		}

		let blob = new Blob([strBuffer], { type: 'text/csv;charset=utf-8' })

		setTimeout(() => {
			Util.mySaveAs(filename, blob)
			this.loadCSV = false
		}, 600)
	}
}

<div class="first-login">
	<!-- <button type="button" class="btn" (click)="success=!success">sucess</button>
	<button type="button" class="btn" (click)="isLoading=!isLoading">loading</button> test buttons -->
	<div class="container" [hidden]="session.isLogged() && session.isLogging()">
		<div class="row justify-content-center">
			<!-- <div class="first-log-container"> -->
			<div class="col-8 first-log-container py-4">
				<div class="panel panel-default">
					<div class="panel-heading">
						<h3 class="panel-title fw-bold">{{ 'FIRST_LOGIN.PAGE_TITLE' | translate }}</h3>
					</div>
					<div class="panel-body">
						<p [hidden]="success">{{ 'FIRST_LOGIN.DESCRIPTION' | translate }}</p>

						<!-- <p [hidden]="success || fail">{{ 'FIRST_LOGIN.DESCRIPTION' | translate }}</p> -->
						<!-- Insert a new password to get access to the account -->
						<p [hidden]="!success">{{ 'FIRST_LOGIN.DESCRIPTION_SUCCESS' | translate }}</p>
						<!-- Download and print your PUK before proceeding -->
						<!-- <p [hidden]="!fail" class="error-message">{{ 'FIRST_LOGIN.DESCRIPTION_FAIL' | translate }} - {{ errMsg }}</p> -->

						<div class="panel-body__container position-relative">
							<loader [activateSpinner]="isLoading"></loader>
							<!-- <div class="loader-container" [ngClass]="isLoading ? 'show' : '' ">
								<div [hidden]="false" class="lds-ring">
									<div></div>
									<div></div>
									<div></div>
									<div></div>
								</div>

								<label for="disclaimer">{{ pleaseWait }}</label>
							</div> -->

							<div class="row d-flex justify-content-between panel-body__container-form" [ngClass]="isLoading ? '' : 'show' ">
								<div class="col-6" *ngIf="!success">
									<form name="firstLoginForm" [formGroup]="firstLoginForm" (ngSubmit)="submit()">
										<div class="form-group position-relative password1-field mb-5">
											<label for="password">{{ 'FIRST_LOGIN.PASSWORD' | translate }}</label>
											<input type="password" class="form-control" name="password" id="password" placeholder="{{ 'FIRST_LOGIN.PASSWORD_HINT' | translate }}" (ngModelChange)="onChangeNewPwd($event)" formControlName="password1" />
											<fa-icon [ngClass]="firstLoginForm.get('password1').valid ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
											<!-- <p class="error-message mt-2" [hidden]="validPwd">{{ 'PROFILE.INVALID_PASSWORD' | translate }}</p> -->
											<p class="error-msg" *ngIf="firstLoginForm.get('password1').invalid">
												<span [ngClass]="firstLoginForm.get('password1').errors['Invalid blank space'] ? 'show' : '' ">{{ 'FIRST_LOGIN.VALIDATOR_BLANK_CHAR' | translate }}</span>
											</p>
										</div>

										<div class="form-group my-4 password2-field position-relative">
											<label for="password">{{ 'FIRST_LOGIN.PASSWORD_CONFIRM' | translate }}</label>
											<input type="password" class="form-control" name="passwordConfirm" id="passwordConfirm" placeholder="{{ 'FIRST_LOGIN.PASSWORD_CONFIRM_HINT' | translate }}" formControlName="password2" />
											<fa-icon [ngClass]="firstLoginForm.get('password2').valid ? 'show' : '' " [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon>
											<p class="error-msg">
												<span [ngClass]="firstLoginForm.get('password2').invalid ? 'show' : ''">{{ ('FIRST_LOGIN.PWD_ERR_NOT_MATCH' | translate )}}</span>
											</p>
										</div>

										<!-- <div class="row">
									<div class="error-message-container">
										<p class="error-message">&nbsp;&nbsp;{{errMsg}} </p>
									</div>
								</div> -->

										<button type="submit" class="btn btn-primary mt-5" [disabled]="firstLoginForm.invalid">{{ 'BUTTONS.CONTINUE' | translate }}</button>
									</form>
								</div>
								<div class="col-4 form-group" *ngIf="!success">
									<p class="fw-bold">{{ 'FIRST_LOGIN.VALIDATOR_TITLE' | translate }}</p>
									<div class="mandatory-points">
										<div class="position-relative" [ngClass]="charNum ?  'valid': 'invalid' ">
											<p>
												<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
												<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_CHAR' | translate }}</span>
											</p>
										</div>
										<div class="position-relative" [ngClass]="uppercase ?  'valid': 'invalid' ">
											<p>
												<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
												<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_UPP' | translate }}</span>
											</p>
										</div>
										<div class="position-relative" [ngClass]="specialChar ?  'valid': 'invalid' ">
											<p>
												<fa-icon [icon]="faCircleXmark" faCircleXmark aria-hidden="true"></fa-icon>
												<fa-icon [icon]="faCircleCheck" faCircleCheck aria-hidden="true"></fa-icon><span>{{ 'FIRST_LOGIN.VALIDATOR_SPEC' | translate }}</span>
											</p>
										</div>
									</div>
								</div>
							</div>

							<div [hidden]="!success" class="puk">
								<p class="me-3">{{ puk }}</p>
								<button class="btn btn-primary me-3" [hidden]="!success" (click)="printPuk(puk)"><fa-icon [icon]="faDownload" aria-hidden="true" class="me-2"></fa-icon>{{ 'BUTTONS.DOWNLOAD' | translate }}</button>
								<button [disabled]="btnProceedDisabled" type="button" class="btn btn-primary" (click)="goLanding()">{{ 'BUTTONS.PROCEED' | translate }}</button>
							</div>
						</div>

						<!-- <div class="row">
							<label for="disclaimer">{{ pleaseWait }}</label>
						</div> -->
						<!-- 
						<div [hidden]="!isLoadingKeys()" class="uil-loading-css uil-loading-sm">
							<div></div>
							<div></div>
							<div></div>
						</div> -->
						<!-- 
						<div [hidden]="!success" class="puk">
							<p class="me-3">{{ puk }}</p>
							<button class="btn me-3" [hidden]="!success" (click)="printPuk(puk)"><fa-icon [icon]="faDownload" aria-hidden="true"></fa-icon>{{ 'BUTTONS.DOWNLOAD' | translate }}</button>
							<button [disabled]="btnProceedDisabled" type="button" class="btn" (click)="goLanding()">{{ 'BUTTONS.PROCEED' | translate }}</button>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<footer></footer>
	</div>
</div>

<div class="panel panel-modal panel-default ng-cloak position-relative">
	<div class="loading-container" [hidden]="!submitDisable">
		<div class="tail-spin">&nbsp;</div>
	</div>

	<!-- modal-panel -->
	<div class="panel-heading">
		<label [hidden]="parent.currentAction != 'create'" class="panel-title">New</label>
		<label [hidden]="parent.currentAction != 'modify'" class="panel-title">Edit</label>
	</div>
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
	</div>

	<div class="panel-body">
		<form #updateForm="ngForm" *ngIf="!flagDone" class="needs-validation" novalidate>
			<div class="container">
				<div class="row">
					<div class="form-group col-2 position-relative">
						<label for="device-model" class="form-label">Device {{"DEVICE.MODEL" | translate}}*</label>
						<select class="form-select form-select-sm" aria-label="Device Model" id="device-model" name="model" #Devmod="ngModel" [(ngModel)]="currUpdate.model" required>
							<option *ngFor="let model of session.deviceModels" [value]="model">{{model}}</option>
						</select>
						<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" *ngIf="Devmod.invalid"></fa-icon>
					</div>

					<div class="form-group col-5 position-relative">
						<label for="component-name" class="form-label">Component Name*</label>
						<input type="text" class="form-control form-control-sm" maxlength="50" aria-label="Component Model" id="component-name" name="component-name" #CmpName="ngModel" [(ngModel)]="currUpdate.component_name" required />
						<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" *ngIf="CmpName.invalid"></fa-icon>
					</div>

					<div class="form-group col-2">
						<label for="os" class="form-label">OS</label>
						<input type="text" class="form-control form-control-sm" aria-label="Os" id="os" name="os" [(ngModel)]="currUpdate.os_ver" />
					</div>

					<div class="form-group col-3">
						<label for="branding" class="form-label">Branding</label>
						<input type="text" class="form-control form-control-sm" aria-label="branding" id="branding" name="branding" [(ngModel)]="currUpdate.branding" />
					</div>
				</div>

				<div class="row my-2 border-dark">
					<div class="col-4">
						<label for="target-sn" class="form-label">Target SN*</label>
						<div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" name="target-sn" value="NONE" [(ngModel)]="snChoice" id="none" (click)="deleteSN()" checked />
								<label class="form-check-label" for="none">None</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" name="target-sn" value="all" [(ngModel)]="snChoice" id="all" (click)="deleteSN()" />
								<label class="form-check-label" for="all">All</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" name="target-sn" value="single" [(ngModel)]="snChoice" id="single" (click)="deleteSN()" />
								<label class="form-check-label" for="single">Single</label>
							</div>
							<div class="form-check form-check-inline">
								<input class="form-check-input" type="radio" name="target-sn" value="list" [(ngModel)]="snChoice" id="list" (click)="deleteSN()" />
								<label class="form-check-label" for="list">List</label>
							</div>
						</div>
					</div>

					<div class="col-4">
						<div class="form-group position-relative">
							<div *ngIf="snChoice == 'single'">
								<label class="form-label" for="single-sn">SINGLE SN</label>
								<input type="text" class="form-control" aria-label="single-sn" id="single-sn" name="sn" #singlesn="ngModel" [(ngModel)]="currUpdate.sn" required />
								<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" *ngIf="singlesn.invalid"></fa-icon>
							</div>
							<div *ngIf="snChoice == 'list'">
								<label class="form-label" for="multiple-sn">LIST of SN</label>
								<textarea class="form-control" id="multiple-sn" cols="60" rows="2" name="sn" #sn="ngModel" [(ngModel)]="currUpdate.sn" required></textarea>
								<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" *ngIf="sn.invalid"></fa-icon>
							</div>
						</div>
					</div>
				</div>

				<div class="border-bottom"></div>

				<div class="row">
					<div class="col-6">
						<div class="form-group position-relative" *ngIf="parent.currentAction == 'create'">
							<label for="logo" class="form-label">UPLOAD BINARY [only *.zip *.gz *.exe *.apk]</label>
							<input type="file" class="form-control form-control-sm file-select" [ngClass]="{invalid: fileInvalid}" name="binary" id="binary" #binary accept=".zip,.gz,.apk,.exe" (change)="checkBinary()" required />
							<fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon>
						</div>

						<div class="form-group" *ngIf="parent.currentAction == 'modify'">
							<label for="logo" class="form-label">BINARY Uploaded </label>
							<input type="text" readonly class="form-control form-control-sm" name="url" id="url" ng-model="modal.currentUpdt.url" [(ngModel)]="currUpdate.url" />
						</div>
					</div>

					<div class="col-2 d-flex flex-column justify-content-center">
						<div class="form-group position-relative">
							<label for="type" class="form-label">TYPE*</label>
							<select class="form-select form-select-sm" aria-label="select type" id="type" name="dev_type" #type="ngModel" [(ngModel)]="currUpdate.dev_type" required>
								<option *ngFor="let devType of parent.devTypes" [value]="devType">{{devType}}</option>
							</select>
							<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" *ngIf="type.invalid"></fa-icon>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-4">
						<label for="parameters" class="form-label">PARAMETERS</label>
						<input type="text" class="form-control form-control-sm" aria-label="parameters" id="parameters" name="paramenters" [(ngModel)]="currUpdate.parameters" />
					</div>

					<div class="form-group col-2">
						<label for="required" class="form-label">REQUIRED</label>
						<select class="form-select form-select-sm" aria-label="required" id="required" name="required" [(ngModel)]="currUpdate.required">
							<option value="Y">YES</option>
							<option value="N">NO</option>
						</select>
					</div>

					<div class="form-group col-2">
						<label for="blocker" class="form-label">BLOCKER</label>
						<select class="form-select form-select-sm" aria-label="blocker" id="blocker" name="blocker" [(ngModel)]="currUpdate.blocker">
							<option value="N">NO</option>
							<option value="Y">YES</option>
						</select>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-4 position-relative">
						<label for="package-name" class="form-label">PACKAGE NAME*</label>
						<input type="text" class="form-control form-control-sm" aria-label="Package name" id="package-name" name="package_name" #pkname="ngModel" [(ngModel)]="currUpdate.package_name" required />
						<fa-icon [icon]="faTriangleExclamation" aria-hidden="true" *ngIf="pkname.invalid"></fa-icon>
					</div>

					<div class="form-group col-4">
						<label for="build-version" class="form-label">BUILD VERSION (STRING)</label>
						<input type="text" class="form-control form-control-sm" aria-label="build version" id="build-version" name="build_ver" [(ngModel)]="currUpdate.build_ver" />
					</div>

					<div class="form-group col-4">
						<label for="build-version-n" class="form-label">BUILD VERSION (INT)*</label>
						<input type="number" class="form-control form-control-sm" aria-label="buod version number" id="build-version-n" name="build_num" #build_num [(ngModel)]="currUpdate.build_num" required />
					</div>
				</div>

				<div class="row">
					<div class="col-4"></div>

					<!-- <div class="col-4 position-relative">
						<label for="password" class="form-label">{{"CREATE_USER.PASSWORD" | translate}} *</label>
						<input
							type="password"
							class="form-control form-control-sm normalcase"
							[ngClass]="{wrong : wrongPwd}"
							name="pwd"
							id="pwd"
							#pwd
							placeholder="{{'MESSAGE.MNGR_PWD_HINT' | translate}}"
							[(ngModel)]="dispPassword"
							required />
						<p class="error-message" style="text-align: right" [hidden]="!wrongPwd">{{"MESSAGE.WRONG_PWD" | translate}}</p>
					</div> -->
					<div class="col-4 d-flex flex-row justify-content-between align-items-end">
						<div>
							<button type="submit" [disabled]="updateForm.invalid || submitDisable" (click)="submit(updateForm)" class="btn btn-primary">{{'BUTTONS.SAVE' | translate}}</button>
						</div>
						<div>
							<button type="clear" (click)="activeModal.dismiss('undo');" class="btn btn-secondary">{{"BUTTONS.UNDO" | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

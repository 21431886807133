import { Directive, OnInit } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'

@Directive({
	selector: '[scrollCheck]',
})
export class ScrollCheckDirective implements OnInit {
	constructor(private elRef: MatAutocompleteTrigger) {}

	ngOnInit(): void {
		window.addEventListener('scroll', this.scrollEvent, true)
	}

	scrollEvent = (): void => {
		if (this.elRef.panelOpen) {
			this.elRef.updatePosition()
		}
	}
}

import { Directive, OnInit, ElementRef, Input } from '@angular/core'

@Directive({
	selector: '[patterncheck]',
})
export class patternVisusDirective implements OnInit {
	inputValue: string

	constructor(private elRef: ElementRef) {
		this.inputValue = ''
	}

	ngOnInit(): void {
		// console.log('[patternVisusDirective] ngOnInit')

		this.elRef.nativeElement.addEventListener('click', this.click, true)

		this.elRef.nativeElement.addEventListener('keyup', this.replace, true)
	}

	click = (): void => {
		// mi copio il valore iniziale al click
		this.inputValue = this.elRef.nativeElement.value
	}

	replace = (): void => {
		let newValue = this.elRef.nativeElement.value // mi ricavo il nuovo valore

		// console.log(newValue)
		// console.log(newValue.length)
		// console.log(this.inputValue)

		if (newValue.length < 6) {
			// max character 5

			let pattern2 = newValue.replace(/[0-9/,.]/g, '') //se é nel pattern vuol dire che va bene e aptter sará vuoto

			this.inputValue = newValue.replace(pattern2, '') //passando un pattern vuoto non verrá fatto nessun replace

			this.elRef.nativeElement.value = this.inputValue
		} else {
			this.elRef.nativeElement.value = this.inputValue
		}
	}
}

import { Injectable } from '@angular/core'
import { Address } from '../models/address.model'
import { TokenResponse, User } from '../models/user.model'
import { SessionService } from './session.service'
import { CryptoUtilsService } from './crypto-utils.service'
import { Config } from 'src/config'
import { Util } from '../models/util.model'
import { DataModelService, DataStatus } from './data-model.service'
import { mapConfig } from '../models/map.model'
import { BehaviorSubject, Subscription } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class MapService {
	private mapConfig: mapConfig
	private mapConfigStatus: DataStatus
	private mapConfigStatusObservable: BehaviorSubject<DataStatus>
	private mapConfifStatusSubscribe: Subscription

	constructor(private session: SessionService, public cryptoUtils: CryptoUtilsService, private data: DataModelService) {
		Util.debug('MapService (constructor)')

		this.mapConfigStatus = DataStatus.NOT_LOADED
		this.mapConfigStatusObservable = new BehaviorSubject<DataStatus>(this.mapConfigStatus)
	}

	public updateProfileAddress(addressDraft: Address) {
		Util.debug('MapService (updateProfileMainAddress)')

		let request: any = this.buildBaseRequest()

		request.method = 'PUT'
		request.url = Config.addressesEndpoint + '/' + this.session.getUserId()

		let fieldToDecryptAddr: string[] = Config.fieldToDecryptAddr
		var myBag = this.cryptoUtils.generateBag()

		for (let field of fieldToDecryptAddr) {
			myBag[field] = addressDraft[field]
		}
		this.cryptoUtils.purge(myBag)

		let goodKey = this.session.user.getKeyPhoto()

		return this.cryptoUtils.encryptFromStringToBase64Content(goodKey, myBag).then((bagCri) => {
			let fieldClearAddr: string[] = Config.fieldClearAddr

			for (let field of fieldClearAddr) {
				bagCri[field] = addressDraft[field]
			}

			let dataReq = {
				data: {
					addresses: [bagCri],
				},
			}

			// console.log(dataReq)

			return this.data
				.myPut(request, dataReq)
				.then(() => {
					Util.debug('MapService (updateProfileAddress) ok, done')

					this.session.refreshProfile()
					return true
				})
				.catch((err) => {
					throw err
				})
		})
	}

	public removeAddress(addressId: number) {
		Util.debug('MapService (removeAddress)')

		let request: any = this.buildBaseRequest()
		request.url = Config.addressesEndpoint + '/' + addressId

		return this.data
			.myDelete(request)
			.then(() => {
				Util.debug('MapService (removeAddress) ok, done')

				this.session.refreshProfile()
				return true
			})
			.catch((err) => {
				throw err
			})
	}

	public getAmplifyConfiguration(tokenData: string): Promise<mapConfig> {
		const promise = new Promise<mapConfig>((resolve, reject) => {
			this.mapConfigStatus = this.mapConfigStatusObservable.value
			// console.log(this.mapConfigStatus)
			if (this.mapConfigStatus == DataStatus.NOT_LOADED) {
				this.requestAmplifyConfig(tokenData)
					.then((res: mapConfig) => {
						resolve(res)
					})
					.catch((err) => {
						console.log(err)
						reject(err)
					})
			} else if (this.mapConfigStatus == DataStatus.LOADED) {
				resolve(this.mapConfig)
			} else {
				this.mapConfifStatusSubscribe = this.mapConfigStatusObservable.subscribe((status) => {
					// console.log(status)
					if (status == DataStatus.LOADED) {
						// console.log(this.mapConfig)
						resolve(this.mapConfig)
						this.unsubscribe()
					} else if (status == DataStatus.LOAD_FAILED) {
						reject('Map config not loaded')
					}
				})
			}
		})

		return promise
	}

	private unsubscribe() {
		this.mapConfifStatusSubscribe.unsubscribe()
	}

	private requestAmplifyConfig(tokenData: string): Promise<mapConfig> {
		let request: any = this.buildBaseRequest()
		request.method = 'GET'
		request.url = Config.amplifyConfigEndpoint
		request.headers.Authorization = tokenData

		this.mapConfigStatusObservable.next(DataStatus.LOADING)

		return this.data
			.myGet(request)
			.then((res: mapConfig) => {
				// console.log(res)
				this.mapConfig = res
				this.mapConfigStatusObservable.next(DataStatus.LOADED)
				return res
			})
			.catch((err) => {
				console.log(err)
				this.mapConfigStatusObservable.next(DataStatus.LOAD_FAILED)
				throw err
			})
	}

	private buildBaseRequest(tokenData?: TokenResponse) {
		var token = !!tokenData ? User.buildToken(tokenData) : this.session.user.token

		if (token == null || token == undefined) {
			token = ''
		}
		return {
			headers: {
				Authorization: token,
				caller: 'feWeb2',
				Pragma: 'no-cache',
				'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
				Expires: 0,
			},
		}
	}

	public resetMapConfig() {
		this.mapConfig = null
		this.mapConfigStatusObservable.next(DataStatus.NOT_LOADED)
	}
}

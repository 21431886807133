<div class="loading-container" [hidden]="!dataService.isLoadingAgreement()">
	<div class="tail-spin">&nbsp;Loading the agreement...</div>
</div>

<div>
	<div class="container">
		<div class="row justify-content-center">
			<!-- <div class="col-3"></div> -->
			<div class="col-xs-12 col-md-7 agreement-container position-relative">
				@if(fromPairing){
				<div class="btn-close-container-pos">
					<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
				</div>
				}

				<div class="p-4 text-center d-flex flex-column align-items-center">
					<h3 class="fw-bold mb-3">{{'AGREEMENT.WELCOME' | translate}}</h3>
					<p [innerHTML]="'AGREEMENT.SUBTITLE' | translate"></p>
					<form [formGroup]="form" (ngSubmit)="activate()" novalidate>
						<div class="agreementsList">
							<li *ngFor="let agreement of agreementsDisplayList; let i=index" class="agreementListItem">
								<input class="form-check-input float-none" type="checkbox" [id]="agreement.id" [name]="agreement.label" value="accept" (change)="onCheckboxChange($event)" />
								<span>
									<a href="{{agreement.url}}" target="_blank">{{agreement.label | translate}}</a>
									<span><strong> ({{agreement.status === 'new' ? ('MISC.NEW' | translate | uppercase ): ('MISC.UPDATED' | translate | uppercase)}})</strong></span>
								</span>
							</li>
						</div>
						<p class="mt-3" [innerHTML]="'AGREEMENT.REMINDER' | translate"></p>
						<button type="button" class="btn btn-primary" [disabled]="!accepted" (click)="activate()">{{ 'BUTTONS.PROCEED' | translate }}</button>
					</form>
				</div>
			</div>
			<!-- <div class="col-3"></div> -->
		</div>
	</div>
</div>

<div class="panel panel-modal panel-default ng-cloak position-relative">
	<!-- modal-panel -->
	<div class="panel-heading">
		<label class="panel-title"> {{"DISTRIB.MODAL_TITLE_UPDATE" | translate}} - {{currentRef.username}}</label>

		<div class="btn-close-container-pos">
			<button type="button" class="btn-close" aria-label="Close" (click)="dismiss('Cross click')"></button>
		</div>
	</div>

	<div class="topview container-fluid" [hidden]="!session.isLoadingSpecialist()"><mat-spinner></mat-spinner>{{"MISC.LOADING" | translate}}</div>

	<div class="panel-body" *ngIf="session.hasLoadedSpecialist(currentRef.id)">
		<form #doctorForm="ngForm">
			<div class="row">
				<div class="twothird-container fields-container">
					<!-- poi su altro third ci sono i settings -->

					<div class="row">
						<div class="form-group third-container">
							<label for="first-name">{{"DISTRIB.MODAL_FIRST_NAME" | translate}}</label>
							<input type="text" class="form-control" readonly name="firstName" id="first-name" [value]="currentRef.firstname" />
						</div>

						<div class="form-group third-container">
							<label for="last-name">{{"DISTRIB.MODAL_LAST_NAME" | translate}}</label>
							<input type="text" class="form-control" readonly name="lastName" id="last-name" [value]="currentRef.lastname" />
						</div>

						<div class="form-group sixth-container">
							<label for="code">{{"DISTRIB.MODAL_CODE" | translate}}</label>
							<input type="text" class="form-control" readonly name="code" id="code" [value]="currentRef.code" />
						</div>
					</div>

					<div class="row">
						<div class="form-group third-container">
							<label for="email">{{"DOCTOR.MODAL_EMAIL" | translate}}</label>
							<input type="text" class="form-control" readonly name="email" id="email" [value]="currentRef.getMail()" />
						</div>

						<div class="form-group third-container">
							<label for="phone">{{"DOCTOR.PHONE" | translate}}</label>
							<input type="text" class="form-control" readonly name="phone" id="phone" [value]="currentRef.getPhone()" />
						</div>

						<div class="form-group sixth-container">
							<label for="country">COUNTRY</label>
							<input type="text" class="form-control" readonly id="country" [value]="getReadOnlyCountry()" />
						</div>
					</div>

					<div class="row">
						<div class="form-group third-container">
							<label for="first-name">Profile</label>
							<input type="text" class="form-control" readonly name="profile" id="profile" [value]="currentRef.user_type" />
						</div>

						<div class="form-group third-container">
							<label for="subtype">{{ 'CREATE_USER.SUBTYPE' | translate }}</label>
							<select name="subtype" [(ngModel)]="currentRef.user_subtype" class="form-control">
								<option *ngFor="let opt of session.usrSubtypesSpec" [ngValue]="opt">{{ opt }}</option>
							</select>
						</div>

						<div class="form-group sixth-container">
							<label for="test">{{"CREATE_USER.TEST" | translate}}</label>
							<select class="form-control" name="test" id="test" [(ngModel)]="currentRef.is_test">
								<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
							</select>
						</div>
					</div>

					<div class="row">
						<div class="form-group third-container">
							<label for="subscrT">CREATED</label>
							<input type="text" class="form-control" readonly id="subscrT" [value]="session.formatDate(currentRef.creationDate)" />
						</div>

						<div class="form-group third-container">
							<label for="order">{{"DISTRIB.MODAL_ORDER_REG_NUM" | translate}}</label>
							<input type="text" class="form-control" name="order" id="order" readonly [value]="currentRef.order_reg_num" />
						</div>

						<div class="form-group sixth-container">
							<label for="owner">{{"DOCTOR.MODAL_OWNER" | translate}}</label>
							<input type="text" class="form-control" (readonly)="!session.isGod()" name="owner" id="owner" readonly [value]="currentRef.created_by" />
						</div>
					</div>

					<div class="row">
						<div class="form-group third-container">
							<label for="displ">Display Name</label>
							<input type="text" class="form-control" readonly id="displ" [value]="currentRef.display_name" />
						</div>

						<div class="form-group third-container">
							<label for="sign_name">sign file name</label>
							<input type="text" class="form-control" name="order" id="sign_name" readonly [value]="currentRef.signature_name" />
						</div>

						<div class="form-group sixth-container">
							<label for="sign">Signature ?</label>
							<input type="text" class="form-control" readonly name="sign" id="sign" [value]="(currentRef.signature != null ? 'Y' : 'N')" />
						</div>
					</div>
				</div>

				<!-- CLOSE twothird-container con edit doct -->

				<div class="third-container fields-container">
					<!-- style="float:right;" -->

					<div class="row" *ngIf="this.currentRef.isSpecialist()">
						<div class="settings">
							<p>SETTINGS</p>
							<p></p>

							<div class="row">
								<div class="half-container">
									<label for="diagn_grp">{{"SETTINGS.DIAGNOSIS_GRP" | translate}}</label>
								</div>
								<div class="half-container" *ngIf="canEditDiagnGroup()">
									<select class="form-control" name="diagn_grp" [(ngModel)]="currentRef.settings.diagnosis_group">
										<option *ngFor="let opt of session.diagnosisGroups" [ngValue]="opt.val">{{opt.descr}}</option>
									</select>
								</div>
								<div class="half-container" *ngIf="!canEditDiagnGroup()">
									<input type="text" readonly name="diagn_grp" class="form-control" [(ngModel)]="myDiagnGroup" />
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="diagonal">{{"SETTINGS.DIAGONAL" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="diagonal" [(ngModel)]="currentRef.admSettings.diagonal">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="lintel">{{"SETTINGS.LINTEL" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="lintel" [(ngModel)]="currentRef.admSettings.lintel">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="telerefract">{{"SETTINGS.TELEREFRACT" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="telerefract" [(ngModel)]="currentRef.settings.telerefract">
										<option *ngFor="let opt of session.yesNoOptions" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>

							<div class="row">
								<div class="half-container">
									<label for="brand">{{"SETTINGS.BRAND" | translate}}</label>
								</div>
								<div class="half-container">
									<select class="form-control" name="brand" [(ngModel)]="currentRef.settings.brand">
										<option *ngFor="let opt of session.brands" [ngValue]="opt">{{opt}}</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<div class="row">&nbsp;</div>
					<!-- spazio vuoto -->
				</div>
				<!-- chiude settings -->
			</div>

			<!-- <div class="row">
				<p>NB: the display name and signature are saved on each relation.</p>
			</div> -->
			<div class="spacer">&nbsp;</div>

			<div class="row fields-container">
				<!-- <div class="twothird-container">
					<div class="row">
						// password per conferma edit by admins 
						<div class="third-container">
							<input type="password" class="form-control" name="pwd" id="password" #fn="ngModel" placeholder="{{'MESSAGE.MNGR_PWD_HINT' | translate}}" [(ngModel)]="dispPassword" required />
							<div class="error-wrapper error-message-container">
								<p class="error-message" [hidden]="!(fn.touched && fn.invalid)">{{'MESSAGE.REQUIRED' | translate}}</p>
								&nbsp;
								<p class="error-message text-danger" [hidden]="!(wrongPwd)">{{'MESSAGE.WRONG_PWD' | translate}}</p>
							</div>
						</div>
					</div>
				</div> -->

				<div class="third-container">
					<div class="row">
						<div class="half-container">
							<button type="submit" [disabled]="doctorForm.invalid" (click)="editCurrentDoct()" class="btn btn-primary">{{'BUTTONS.SAVE' | translate}}</button>
						</div>
						<div class="half-container">
							<button type="clear" (click)="dismiss('undo');" class="btn btn-secondary">{{"BUTTONS.UNDO" | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

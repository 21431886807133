import { Injectable } from '@angular/core'
import { Anamnesis, PatientReply, VA } from '../models/anamnesis.model'
import { SessionService } from './session.service'
import { Config } from 'src/config'
import { DataModelService } from './data-model.service'

@Injectable({
	providedIn: 'root',
})
export class AnamnesisService {
	constructor(private session: SessionService, private data: DataModelService) {}

	// based on anamn_group of the optician
	// ritorna array di domande con traduzioni e array di risposte
	loadAnamnesisQuestions() {
		let anamnGroup = 1
		let lang = this.session.getLanguage()

		if (this.session.isLevel1()) {
			anamnGroup = this.session.user.settings.anamnesis_group
		}

		return this.data.loadAnamnesis(this.session.buildBaseRequest(), anamnGroup, lang)
	}

	// come list mi basta un array di coppie: question_id, reply_id, solo quelle risposte
	savePatientAnamnesis(patientId: number, list: PatientReply[], va: VA) {
		let request: any = this.session.buildBaseRequest()
		request.method = 'POST'
		request.url = Config.anamnesisEndpoint

		// gestire poi su edit un "annullo" risposta ?

		let myAnamn = {
			patient_id: patientId,
			questions: list,
			va: va,
		}

		let myData = { anamnesis: myAnamn }

		return this.data.myPost(request, myData)
	}

	// domande e risposte date dal paziente
	getPatientAnamnesisAnswers(patientId: number, repDate?): Promise<Anamnesis[]> {
		// TODO, vedere se le ho gia' in memoria
		// se no, le chiedo al server
		let lang = 'en'
		lang = this.session.getLanguage()

		return this.data.loadPatientAnamnesis(this.session.buildBaseRequest(), patientId, lang, repDate)
	}

	// ************* patient anamnesis VA *****************
	// il post é unico con anamnesis

	getPatientVA(patientId: number, repDate?): Promise<VA> {
		return this.data.loadPatientVA(this.session.buildBaseRequest(), patientId, repDate)
	}
}

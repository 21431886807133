import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import { faEye, faEyeSlash, faArrowLeft, faArrowRight, faInfoCircle, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { checkVAT, Country as JsvatCountry, countries as jsvatCountryList } from 'jsvat'
// TEMP TODO  import * as $ from "jquery";

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'

import { User } from '../../models/user.model'
import { Settings, AdmSettings } from '../../models/settings.model'
import { Address } from '../../models/address.model'
import { Util } from '../../models/util.model'
import { Country, DBCountrySettings } from '../../models/countries.models'

export type VatCheckerResponseT = {
	address: { streetAddress: string; zip: string; city: string; province: string }
	error: boolean
	fault: string | null
	organization: string
	valid: boolean
}

@Component({
	selector: 'createUser',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss'],
})
export class CreateUserComponent implements OnInit {
	@ViewChild('searchCountry') searchCountry: any
	@ViewChild('username') username: ElementRef
	@ViewChild('password') password: ElementRef

	draftUser: User

	usernameExtension: string
	isLoading: boolean

	//user_type: string; // e' il "radio" sul form, corrisponde al draftUser.role

	//password: string;
	//passwordConfirm: string;
	showPwd: boolean

	countryDBSettings: DBCountrySettings[]
	//birthdate: string;

	deviceModel: string // 25.05.2021 lista dei modelli abilitati sul manager
	listAvail: string[]
	listSelected: string[]

	//countries: Countries[];
	countries: Country[]
	countryIso2: string
	jsvatCountry: JsvatCountry

	//code: string;
	//group: string; TODO sara' anamnesis_group
	//group_paths: string; // 27.04.2017 per gruppi di patologie

	settings: Settings
	admSettings: AdmSettings // 21.02.2022
	mainAddr: Address

	salePlanId: string // 17.08.2021  string per associarlo al value del radio
	//is_test: string;  // 13.10.2021 Y / N

	// 18.10.2019
	//dicomServerList: Data.DicomServer[];  // lista di quelli disponibili
	//dicomServers : Data.DicomServer[]; // lista di quelli scelti
	validPassword: boolean
	validUsername: boolean
	alertMessage: string
	alertPswMessage: string

	success = false
	fail = false
	assignedUserId: string

	usrSubtypes: string[] // 29.03.2022 ripristinato // 03.03.2021  // 16.09.2021 spostate su session
	models: string[] // 25.05.2021

	modelIndex: number // 02.05.2022 per model list su manager

	diagnEnabled: string // 21.10.2021 per refertatori, diagnosi non su tutti, lista di valori
	diagnChoice: string
	diagnExamChoices: string[]
	categChoices: string[]

	showBanner: boolean
	typeBanner: number
	bannerMsg: string

	vat: string
	vatError: boolean
	internalVatCheckerError: boolean
	isValidatingVat: boolean
	disableVat: boolean

	public yesNoOptions = ['Y', 'N']

	public uploadOptions = [
		{ val: 'N', descr: 'No' },
		{ val: 'Y', descr: 'Yes' },
		{ val: 'D', descr: 'onDemand' },
	]

	faEyeOpen = faEye
	faEyeClosed = faEyeSlash
	faArrowLeft = faArrowLeft
	faArrowRight = faArrowRight
	faInfoCircle = faInfoCircle
	faTriangleExclamation = faTriangleExclamation

	constructor(public session: SessionService, public translator: TranslateService, private renderer: Renderer2) {
		this.initNewUser()

		this.usernameExtension = '@nexus'
		this.isLoading = false

		this.countries = this.session.getDtCountries()

		// 30.08.2022 richiedo solo una volta per sessione

		// if (this.countries != null && this.countries.length > 0) {
		// 	Util.debug('(createUsr) already loaded tot countries: ' + this.countries.length)
		// } else {
		// 	Util.debug('(createUsr) going to request countries...')
		// 	// 08.06.2022
		// 	this.session
		// 		.getCountries()
		// 		.then((list) => {
		// 			this.countries = list
		// 			Util.debug('(createUsr) tot countries: ' + this.countries.length)
		// 		})
		// 		.catch((error) => {
		// 			Util.debug('(createUsr) ERR on countries!')
		// 			console.log(error)
		// 		})
		// }

		this.countryDBSettings = []

		this.validPassword = true
		this.validUsername = true

		this.alertMessage = ''
		this.alertPswMessage = ''

		this.showBanner = false
		this.typeBanner = 0 // 1 success banner 2 danger
		this.bannerMsg = ''

		this.vatError = false
		this.internalVatCheckerError = false
		this.isValidatingVat = false
		this.disableVat = false
	}

	ngOnInit(): void {
		this.validUsername = true

		this.success = false
		this.fail = false
		this.assignedUserId = ''

		this.session
			.requestDBCountrySettings()
			.then((settings) => {
				this.countryDBSettings = settings
			})
			.catch((err) => {
				console.log(err)
				alert(err)
			})
	}

	// 06.12.2022 portato fuori dal costruttore, serve anche nella restartForm
	private initNewUser() {
		// 13.05.2019 valori di default nel costruttore della classe --ls
		this.draftUser = new User(null)

		this.settings = new Settings(null)
		this.admSettings = new AdmSettings(null)
		this.mainAddr = new Address()

		//this.draftUser.type = UserType.OPERATOR;
		//this.draftUser.subType = UserType.PHARM;

		this.draftUser.role = Config.PR_OPTICIAN // 12.05.2021 il piu' probabile
		//this.draftUser.role = this.user_type;

		this.draftUser.user_subtype = Config.SUB_STD
		this.draftUser.is_test = 'N'

		// default di comodo per temporanea
		this.draftUser.password = 'password'
		this.showPwd = true // 28.03.2022

		this.settings.brand = this.session.brands[0] // 12.05.2021
		this.settings.diagnosis_group = 2 // 02.05.2022 nuovo default, vd sotto su changeProfile

		// 04.02.2021 per i test
		this.mainAddr.city = '-'
		this.mainAddr.province = '-'
		this.mainAddr.address_line1 = '-'
		this.mainAddr.organization = '-'

		//this.mainAddr.country = '-';   // 08.06.2022 lascio vuoto x forzare la tendina
		this.mainAddr.country = ''

		this.deviceModel = '' // ko con *  il valore per nuovo manager

		// 21.10.2021
		this.diagnExamChoices = []
		this.diagnExamChoices.push(Config.CHOICE_MAN)
		this.diagnExamChoices.push(Config.CHOICE_ALL)

		//this.diagnChoice = Config.CHOICE_MAN;
		this.diagnChoice = Config.CHOICE_ALL // parto con tutti sulla listaB

		this.categChoices = Config.CATEG_NAMES

		/*
    this.dicomServerList = [];        
    var completeList = this.session.getDicomServerList();
    if(completeList != null && completeList.length>0){
      this.dicomServerList = completeList;     
    }
    */

		// 17.08.2021
		this.salePlanId = '1' // default basic

		this.usrSubtypes = this.getUsrSubtypes() // 29.03.2022

		this.listAvail = this.session.deviceModels
		this.listSelected = []
	}

	// 16.09.2021 in base a scelta tra optician e doctor, ritorna valori diversi
	getUsrSubtypes() {
		let ret = [Config.SUB_STD]

		if (this.draftUser.role == Config.PR_OPTICIAN) {
			ret = this.session.usrSubtypesOpts
		} else if (this.draftUser.role == Config.PR_DOCTOR) {
			ret = this.session.usrSubtypesNoMini
		} else if (this.draftUser.role == Config.PR_SPECIALIST || this.draftUser.role == Config.PR_CLINIC) {
			ret = this.session.usrSubtypesSpec
		} else if (this.draftUser.role == Config.PR_SUPPORT) {
			ret = this.session.usrSubtypesNoMini // 11.04.2023
		} else if (this.draftUser.role == Config.PR_INSTALLER) {
			ret = this.session.usrSubtypesInstaller // 24.10.2023
		}
		/*else {
      return [Config.SUB_STD];
    }*/
		//Util.debug("(getUsrSubtypes) curr: "+this.draftUser.role+" len: "+ret.length);

		return ret
	}

	erase() {
		this.searchCountry.drpcountry = ''
		this.searchCountry.countrySelected = false
	}

	resetCountry(event) {
		//resetto la contry se viene cancellata nel serachDropdown
		// console.log(event);
		if (event) {
			this.mainAddr.country = ''
		}
	}

	// 21.10.2021
	// changeProfile(evt) {
	changeProfile() {
		//var target = evt.target;
		//if (target.checked) {...}
		//Util.debug("(changeProfile) curr: "+this.draftUser.role);

		if (Config.brand == Config.BR_CLALIT) {
			this.settings.diagnosis_group = 1 // ok per tutti
			/*
    } else if(this.draftUser.role == Config.PR_DOCTOR){
      this.settings.diagnosis_group = 1;
    */
		} else {
			//03.05.2022 ok gruppo 2 per tutti
			//this.settings.diagnosis_group = 0;
			this.settings.diagnosis_group = 2
		}

		this.usrSubtypes = this.getUsrSubtypes() // 29.03.2022

		this.draftUser.user_subtype = this.draftUser.role === Config.PR_INSTALLER ? Config.SUB_GLOBAL : Config.SUB_STD
		this.onChangeSubtype() // verifica telerefract

		this.usernameExtensionSet()
	}

	private usernameExtensionSet() {
		if (this.draftUser.role == Config.PR_OPTICIAN) {
			this.usernameExtension = '@nexus'
		} else if (this.draftUser.role == Config.PR_SPECIALIST) {
			this.usernameExtension = '@' + Config.PR_SPECIALIST
		} else if (this.draftUser.role == Config.PR_SUPPORT) {
			this.usernameExtension = '@' + Config.PR_SUPPORT
		} else if (this.draftUser.role == Config.PR_CLINIC) {
			this.usernameExtension = '@' + Config.CLINIC
		} else {
			this.usernameExtension = ''
		}
	}

	// 06.08.2018 nascondiamo per profili che non li usano
	enableSettings() {
		var ret = false // 04.09.2020 cambiato il default iniziale [ls]

		if (
			this.draftUser.role == Config.PR_OPTICIAN ||
			this.draftUser.role == Config.PR_DOCTOR ||
			this.draftUser.role == Config.PR_SPECIALIST ||
			this.draftUser.role == Config.PR_CLINIC ||
			this.draftUser.role == Config.PR_MANAGER
		) {
			// 25.05.2021
			ret = true
		}

		return ret
	}

	// 17.08.2021 per ora solo livelli 1, potrebbe servire per i distrib ?
	enablePlans() {
		var ret = false
		if (this.draftUser.role == Config.PR_OPTICIAN || this.draftUser.role == Config.PR_DOCTOR) {
			ret = true
		}
		return ret
	}

	// 12.08.2022
	enableTestFlag() {
		let ret =
			this.draftUser.role == Config.PR_OPTICIAN ||
			this.draftUser.role == Config.PR_DOCTOR ||
			this.draftUser.role == Config.PR_SPECIALIST ||
			this.draftUser.role == Config.PR_CLINIC

		return ret
	}

	// 30.08.2022
	enableSubtype() {
		let ret =
			this.draftUser.isLevel1() ||
			this.draftUser.role == Config.PR_SPECIALIST ||
			this.draftUser.role == Config.PR_CLINIC ||
			this.draftUser.role == Config.PR_SUPPORT || // 11.04.2023 aggiunto support
			this.draftUser.role == Config.PR_INSTALLER // 24.10.2023 aggiunto installer

		return ret
	}

	private containsInvalidChars(value) {
		let ret = false

		// 19.04.2018 blocca il carattere '$' nello username, perche' poi verra' usato come replacement per il .
		if (value.indexOf('$') >= 0) {
			ret = true
			let msg = 'Please avoid using character $ on username' // TODO su json
			this.alertMessage = msg
			// alert(msg);
		}
		// 17.08.2021 blocchiamo anche lo spazio
		else if (value.indexOf(' ') >= 0) {
			ret = true
			let msg = 'Please avoid using blank character on username' // TODO su json
			this.alertMessage = msg
			// alert(msg);
		}

		// 08.06.2022
		// if (value.length == 0) { // non serve viene giá evidenziato
		// 	ret = true;
		// 	let msg = 'Please fill required username field';
		// 	this.alertMessage = msg;
		// 	alert(msg);
		// }

		return ret
	}

	// 12.04.2023
	onChangeSubtype() {
		if (this.draftUser.user_subtype == Config.SUB_SUPER && this.draftUser.role == Config.PR_DOCTOR) {
			// enable telerefract
			this.settings.telerefract = 'Y'
		} else {
			// disable telerefract
			this.settings.telerefract = 'N'
		}
	}

	onChangeUsrname(event?) {
		//console.log("(onChangeUsrname) "+event);
		let value = this.draftUser.username
		this.validUsername = !this.containsInvalidChars(value)

		this.alert(!this.validUsername, this.username.nativeElement)
	}

	onChangeVat(event: Event) {
		this.vat = (event.target as HTMLInputElement).value
		this.validateVat(this.vat)
	}

	async validateVat(vat: string) {
		if (this.vat === '' || this.vat === undefined) {
			this.vatError = false
			this.internalVatCheckerError = false
			return
		}

		if (this.mainAddr.country) {
			this.vatError = !checkVAT(`${this.countryIso2.toUpperCase()}${vat}`, [this.jsvatCountry]).isValid

			if (!this.vatError) {
				this.isValidatingVat = true
				this.internalVatCheckerError = false

				this.session
					.vatChecker({ state: this.countryIso2.toUpperCase(), vatNumber: this.vat.replace(' ', '') })
					.then((res: VatCheckerResponseT) => {
						if (res.error) {
							return (this.internalVatCheckerError = true)
						}

						if (!res.valid) {
							return (this.vatError = true)
						}
					})
					.catch(() => {
						this.internalVatCheckerError = true
					})
					.finally(() => {
						this.isValidatingVat = false
					})
			}
		}
	}

	alert(cond: boolean, elem: ElementRef) {
		if (cond) {
			this.renderer.addClass(elem, 'headshake')
			setTimeout(() => {
				this.renderer.removeClass(elem, 'headshake')
			}, 1000)
		}
	}

	hiddenExtension() {
		// vogliamo che si possa cmq inserire @demo o altro, x cui se scriviamo @ viene svuotato la estensione e nascosta #18929
		if (this.draftUser.username.indexOf('@') > 0) {
			if (this.usernameExtension != '') {
				this.usernameExtension = ''
			}
		} else {
			if (this.usernameExtension == '') {
				this.usernameExtensionSet()
			}
		}
	}

	//submit(form) {
	createUser(myForm?) {
		console.log('[createUser] submit has been called!')
		// console.log(myForm)

		// 08.06.2022
		if (myForm) {
			if (myForm.invalid) {
				alert('Please fill required fields')
				return
			}
		}

		//if (form.$valid) {
		{
			// alert fatti nella funzione
			this.fail = this.containsInvalidChars(this.draftUser.username)

			this.alert(this.fail, this.username.nativeElement)

			if (this.fail) {
				this.bannerMsg = this.translator.instant('CREATE_USER.DESCRIPTION_FAIL')
				this.showBanner = true
				this.typeBanner = 2
				setTimeout(() => {
					this.showBanner = false
				}, 3500)
				return false
			}

			// 02.05.2022
			if (this.draftUser.username.indexOf('@doc') > 0 && this.draftUser.role != 'Doctor') {
				let msg = 'The username refers to a doctor but the profile type is different: \nDo you want to proceed anyway ?'
				if (!confirm(msg)) {
					return false
				}
			}

			// 27.12.2022 per tutti i nonLivelli1
			// 04.08.2022
			//if (this.draftUser.password == 'password' && this.draftUser.role == 'Specialist') {
			if (
				this.draftUser.password == 'password' &&
				!(this.draftUser.role == 'Optician' || this.draftUser.role == 'Doctor' || this.draftUser.role == Config.PR_SPECIALIST)
			) {
				let msg = 'The user cannot change his pwd. Please choose a safer one.'
				this.alertPswMessage = msg
				this.validPassword = false
				this.alert(!this.validPassword, this.password.nativeElement)

				// alert(msg);
				return false
			}

			/* tutto su draftUser  

			draftForm.username = this.username;
			draftForm.password = this.password;
			draftForm.user_type = this.user_type;
			draftForm.user_subtype = this.user_subtype;  // 17.04.2020
			draftForm.is_test = this.is_test;  //13.10.2021
			
			draftForm.firstname = this.firstname;
			draftForm.lastname = this.lastname;          
			draftForm.phone = this.phone;
			draftForm.organization = this.organization;
			draftForm.ref_email = this.ref_email;
			
			// 31.05.2017 aggiunto indirizzo x esporlo nel report 
			draftForm.address = this.address;  
			draftForm.city = this.city;
			draftForm.province = this.province;  
			draftForm.country = this.country;  // 18.03.2020     
			*/
			//Added ngIf on template to have required attribute, so this condition never happens
			// if (this.draftUser.role == Config.PR_OPTICIAN && !this.mainAddr.organization) {
			// 	var msg = this.translator.instant('CREATE_USER.ORG_MSG');
			// 	//var msg = "new optician: organization is required, it will appear on reports";
			// 	alert(msg);
			// 	this.fail = true;
			// 	console.log('(createUser) ' + msg);
			// 	return;
			// }

			// 07.03.2019 fatto con "required" sul form --ls
			// 03.10.2017
			// if (!this.draftUser.role) {
			// 	var msgA = this.translator.instant('CREATE_USER.TYPE_REQUIRED_MSG');
			// 	//alert("Please select the user type: doctor, optician, ... ");
			// 	alert(msgA);
			// 	this.fail = true;
			// 	//console.log("(createUser) "+msgA);
			// 	return;
			// }

			// 11.04.2023 anche superSupport
			// 03.03.2021
			//if (this.draftUser.role != Config.PR_DOCTOR && this.draftUser.user_subtype == Config.SUB_SUPER) {
			if (this.draftUser.user_subtype == Config.SUB_SUPER) {
				if (this.draftUser.role != Config.PR_DOCTOR && this.draftUser.role != Config.PR_SUPPORT) {
					//var msgB = "At the moment the Super subtype is allowed only for Doctor profile Type.";
					var msgB = this.translator.instant('CREATE_USER.SUBTYPE_ALLOWED_MSG')
					alert(msgB)
					this.fail = true
					return
				}
			}

			// 24.02.2021
			if (this.draftUser.user_subtype == Config.SUB_SUPER && this.settings.max_colleagues == 0) {
				//var msgC = "Are you sure about a superB user with 0 colleagues? ";
				var msgC = this.translator.instant('CREATE_USER.COLLEAGUES_WARNING')
				if (!confirm(msgC)) {
					return
				}
			}

			// 08.06.2022 controllo su country, necessario per agreement
			if (!this.mainAddr.country || this.mainAddr.country.length == 0) {
				var msgD = 'Please fill the country field, required for the agreement.'
				alert(msgD)
				this.fail = true
				return
			} else {
				console.log('submit country: ' + this.mainAddr.country)
			}

			// 19.10.2022 fix ? no, gia' fatto su session
			//this.draftUser.country = this.mainAddr.country;

			var newSettings = null // di tipo Settings
			var admSett = null // 25.10.2021 solo AdmSettings

			if (!this.enableSettings()) {
				this.settings = null // 20.09.2018 serve per i livelli alti che non li hanno --ls
			} else {
				newSettings = this.settings
			}

			// 25.05.2021
			if (this.draftUser.role == Config.PR_MANAGER) {
				// per ora unico setting e' il device model
				this.deviceModel = this.getSelectedModels()
				if (this.deviceModel == '') {
					alert('Please select at least one model')
					return false
				}
				console.log('(createUser) manager on models:' + this.deviceModel)
				admSett = new AdmSettings() // sono gli unici che ha
				admSett.models = this.deviceModel // valori dal form
			}
			// 21.02.2022
			else if (this.draftUser.role == Config.PR_SPECIALIST) {
				admSett = this.admSettings // valori dal form, lintel e diagonal
			}

			this.draftUser.mainAddress = this.mainAddr

			/* TODO
			// 25.10.2021
			else if(this.draftUser.role == Config.PR_SPECIALIST){
				
				this.diagnEnabled = this.getSelectedCateg();
				if(this.diagnEnabled == ""){
				alert("Please select at least one category");
				return false;
				}       
				console.log("(createUser) specialist on categs:"+this.diagnEnabled);
				
				admSett = new Data.AdmSettings();  // aggiungo ai settings classici

				// 25.10.2021 se sono tutte, imposto valore '*'
				var tot = this.getTotAvailable();
				if(tot == 0){  // sono state scelte tutte
				admSett.categories = '*';
				} else {          
				admSett.categories = this.diagnEnabled;  // valori dal form
				}
			}
			*/

			// 31.08.2021 disab
			// 17.08.2021
			//draftForm.sale_plan_id = this.salePlanId;
			//console.log("(createUser) plan: "+draftForm.sale_plan_id);

			this.fail = false // resetta il msg se poi riprova

			// 18.10.2019
			//console.log("(createUser) dicom servers: ");
			//console.log(this.dicomServers); // ok, solo quelli scelti
			/*var listaSrv = [];
      if(this.dicomServers != null && this.dicomServers.length>0){
        for(let i=0; i<this.dicomServers.length; i++){
          listaSrv[i] = this.dicomServers[i].id;
        }
      }*/
			this.isLoading = true
			// 03.08.2022 trim empty spaces around values
			this.draftUser.username = this.draftUser.username + this.usernameExtension
			this.draftUser.username = this.draftUser.username.trim()
			this.draftUser.password = this.draftUser.password.trim()

			//this.session.createNewDoctor(draftForm, this.settings, this.dicomServers)

			this.session
				.createNewUser(this.draftUser, newSettings, admSett, null)
				.then((id) => {
					console.log('create user: initialize success, id ' + id)
					this.assignedUserId = id
					this.erase() //resetto la contry
					this.success = true
					this.isLoading = false

					//banner message
					this.bannerMsg = this.translator.instant('CREATE_USER.DESCRIPTION_SUCCESS')
					this.showBanner = true
					this.typeBanner = 1
					setTimeout(() => {
						this.showBanner = false
					}, 3500)
				})
				.catch((error) => {
					this.fail = true
					this.isLoading = false
					console.log('create user: initialize error')
					console.log(error)

					if (error.status == 409) {
						// 18.05.2021 differenziato err specifico, per poter tradurre il msg sul json
						// username already exists in the DB
						var errMsg = this.translator.instant('CREATE_USER.CONFLICT_MSG')
						this.validUsername = false
						this.alertMessage = errMsg
						this.alert(!this.validUsername, this.username.nativeElement)

						this.bannerMsg = this.translator.instant('CREATE_USER.DESCRIPTION_FAIL')
						this.showBanner = true
						this.typeBanner = 2
						setTimeout(() => {
							this.showBanner = false
						}, 3500)

						// alert(errMsg);
					} else {
						//alert("[createUser] KO, error "+error);
						let msg = this.session.parseErrorMessage(error, 'alert') // 28.03.2022
						alert(msg)
					}
				})
		}
	}

	goLanding() {
		// TEMP TODO
		return this.session.loadDoctorsPage()
		//this.location.url("/doctors");
	}

	// 10.03.2021 cambia visibilita' del campo pwd
	clickTogglePassword() {
		/*
    // toggle the type attribute
    var passwordField = document.querySelector('#password');
    const type = passwordField.getAttribute('type') === 'password' ? 'text' : 'password';        
    passwordField.setAttribute('type', type);

    // toggle the eye icon
    var eyeClass = ((type == 'text') ? "glyphicon glyphicon-eye-open" : "glyphicon glyphicon-eye-close");
    var togglePassword = document.querySelector('#togglePassword');
    togglePassword.setAttribute('class', eyeClass); 
    */

		this.showPwd = !this.showPwd // 28.03.2022

		//console.log("(clickTogglePassword) reversed ");  // ok
	}

	/*
  setEyeContainer(){      
    var togglePassword = document.getElementById("togglePassword")
    //togglePassword.style.marginLeft = "-30px";  // non funziona  
    togglePassword.style.cursor = "pointer";    
  }
  */

	// per profilo manager ******************

	// sposta quello checked nella lista 2
	addModel(i) {
		Util.debug('(addModels) ' + i)
		let elem = this.listAvail[i]
		this.listSelected.push(elem)
		this.listAvail.splice(i, 1) // lo toglie
	}

	// sposta quello checked nella lista 1
	removeModel(j) {
		Util.debug('(removeModel) ' + j)
		let elem = this.listSelected[j]
		this.listAvail.push(elem)
		this.listSelected.splice(j, 1) // lo toglie
	}

	getSelectedModels() {
		Util.debug('(getSelectedModels)')
		var myNewModels = ''
		var items = this.listSelected
		var n = items.length
		Util.debug('(getSelModels) list2: ' + n)

		for (let idx = 0; idx < n; idx++) {
			if (idx != 0) myNewModels += ',' // non davanti al primo
			myNewModels += '' + items[idx]
		}

		Util.debug('(getSelModels) ' + myNewModels)
		return myNewModels
	}

	private getSelectedCateg() {}

	// 21.10.2021
	applyDiagnChoice() {
		Util.debug('(applyDiagnChoice)')
	}

	// Aggiunta per recuperare la country selezionata
	selCountry(selCountry: { countryIso2: string; countryIso3: string }) {
		console.log('(CreateUser) select country: ' + selCountry.countryIso3)
		this.mainAddr.country = selCountry.countryIso3
		this.countryIso2 = selCountry.countryIso2
		this.jsvatCountry = jsvatCountryList.find((jsvatCountry) => {
			return jsvatCountry.codes[1] === selCountry.countryIso3
		})
		this.validateVat(this.vat)
		this.getDefaultCountrySettings(selCountry.countryIso3)

		if (selCountry.countryIso3 == 'USA') {
			this.disableVat = true
		} else {
			this.disableVat = false
		}
	}

	private getDefaultCountrySettings(countryIso3: string) {
		let countrySettings = this.countryDBSettings.find((c) => c.country === countryIso3)

		// console.log(countrySettings)

		if (countrySettings) {
			this.settings.free_trial_available = countrySettings.free_trial
			this.settings.ecommerce = countrySettings.ecommerce
			this.settings.cup_to_disc = countrySettings.cup_to_disc
			this.settings.offline_days = Number(countrySettings.offline_days)
			this.settings.p_self_reg = countrySettings.p_self_reg
			this.settings.syncr_patients = countrySettings.syncr_patients
			this.settings.upload_exams = countrySettings.upload_exams

			alert('Some country settings are applied')
		}
	}

	// 06.12.2022 a fine processo, riparte per crearne un altro
	restartForm() {
		Util.debug('(restartForm) inizio ')
		this.initNewUser()

		this.success = false
		this.fail = false
		this.assignedUserId = ''
	}
}

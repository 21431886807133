<div class="container-fluid panel panel-modal position-relative">
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"></button>
	</div>

	<div class="row">
		<div class="col-12 balance-container__content">
			<ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" [destroyOnHide]="false" class="nav nav-pills">
				<!-- SERVICES  -->
				<li ngbNavItem="plan" class="nav-item me-3">
					<a ngbNavLink uppercase class="nav-link fw-bold"> SERVICES </a>
					<ng-template ngbNavContent>
						<div class="container-fluid">
							<div class="row mb-4 position-relative">
								<div class="col" style="min-height: 200px">
									<loader [activateSpinner]="!userPlanReady"></loader>

									<!-- <div class="loader-container">
										<div [hidden]="userPlanReady" class="lds-ring">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div> -->

									<div *ngIf="userPlanReady">
										<app-credits [currDoc]="currentDoctor" [saleInfo]="saleInfo"></app-credits>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</li>

				<li ngbNavItem="balance" class="nav-item me-3">
					<a ngbNavLink uppercase class="nav-link fw-bold"> BALANCES </a>
					<ng-template ngbNavContent>
						<div class="container-fluid">
							<div class="row mb-4 position-relative">
								<div class="col" style="min-height: 200px">
									<loader [activateSpinner]="!userPlanReady"></loader>
									<!-- 
									<div class="loader-container">
										<div [hidden]="userPlanReady" class="lds-ring">
											<div></div>
											<div></div>
											<div></div>
											<div></div>
										</div>
									</div> -->
									<div *ngIf="userPlanReady">
										<balance [currDoc]="currentDoctor" [balanceList]="records"></balance>
									</div>
								</div>
							</div>
						</div>
					</ng-template>
				</li>
			</ul>

			<div [ngbNavOutlet]="nav" class="mt-4"></div>
		</div>

		<!-- <div class="topview container-fluid" [hidden]="!session.isLoadingDoctor()"><mat-spinner></mat-spinner>{{"MISC.LOADING" | translate}}</div>
	
		<div class="panel-body">
			
			<div *ngIf="userPlanReady">
				<app-credits [currDoc]="currentDoctor" [saleInfo]="saleInfo"></app-credits>
			</div>
	
			<div class="spacer">&nbsp;</div>
	
			<div class="row">
				<balance [currDoc]="currentDoctor" [balanceList]="records"></balance>
			</div>
	
			<div class="spacer"></div>

			<div class="container-fluid">
				<div class="row mt-3">
					<div class="col d-flex justify-content-end">
						<button type="clear" (click)="activeModal.dismiss('close');" class="btn btn-exit">{{"BUTTONS.CLOSE" | translate}}</button>
					</div>
				</div>
			</div>
		</div> -->
	</div>
</div>

import { Component, OnInit, Input, Renderer2, ViewChild, ElementRef } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'

import { SessionService } from 'src/app/service/session.service'
import { AppToastService } from 'src/app/service/toast.service'

@Component({
	selector: 'confirm-modal',
	templateUrl: './confirm.modal.html',
	styleUrls: ['./confirm.modal.scss'],
	providers: [NgbModal, FormsModule],
})
export class ConfirmModal implements OnInit {
	@ViewChild('pwd') pwd: ElementRef
	@Input() isExit: boolean
	@Input() isQuest: boolean

	password: string
	wrongPwd: boolean
	submitDisable: boolean
	warnText: string

	faTriangleExclamation = faTriangleExclamation

	constructor(private session: SessionService, public activeModal: NgbActiveModal, public renderer: Renderer2, private toastService: AppToastService) {
		this.wrongPwd = false
		this.submitDisable = false
	}

	ngOnInit(): void {
		// add clear to avoid to app toast notification over confirm modal
		this.toastService.clear()
	}

	dismiss() {
		this.activeModal.dismiss()
	}

	close() {
		this.activeModal.close(true)
	}

	exit() {
		let valid = this.session.isCorrectPwd(this.password)

		if (valid) {
			this.activeModal.close(valid)
		} else {
			this.wrongPwd = true
			this.alert(this.pwd)
			this.submitDisable = true
			setTimeout(() => {
				this.activeModal.close(valid)
			}, 1000)
		}
	}

	alert(elem) {
		this.renderer.addClass(elem.nativeElement, 'headshake')

		setTimeout(() => {
			this.renderer.removeClass(elem.nativeElement, 'headshake')
		}, 1000)
	}
}

import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef, HostListener } from '@angular/core'

import { faSave } from '@fortawesome/free-regular-svg-icons'

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
import { ExamImage } from '../../models/exam.model'
import { DateParser } from '../../models/dateParser.model'
import { Util } from '../../models/util.model'

@Component({
	selector: 'album',
	templateUrl: './album.component.html',
	styleUrls: ['./album.component.scss'],
})
export class AlbumComponent implements OnInit {
	@Input() current: number
	@Input() images: ExamImage[]
	@Input() isWizard: boolean

	@Output() close = new EventEmitter()

	@ViewChild('myCanvas', { static: true }) myCanvas: ElementRef<HTMLCanvasElement>

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		console.log(event)
		if (this.zoomActive) {
			this.closeFullscreen()
			event.preventDefault()
		}
	}

	eye: string
	hasImages: boolean
	zoomActive: boolean
	resampledImage: any // HTMLImageElement;
	areAdditions: boolean // 25.11.2022

	faSave = faSave // 02.08.2022

	constructor(public session: SessionService) {
		//console.log("[Album] - constructor");
		//console.log("[Album] - constructor "+this.eye); // qui ancora undefined

		this.hasImages = false
		this.current = 0
		this.images = [] // 27.12.2021 vuoto, non nullo
		this.isWizard = false // TODO, lo prende da categories
		this.eye = ''
		this.areAdditions = false
		this.resampledImage = new Image()
	}

	ngOnInit(): void {
		if (this.images != null && this.images[0] != null) {
			this.eye = this.images[0].eye
			//Util.debug('[Album] (ngOnInit) - ok images for eye: '+this.eye)
			this.hasImages = true // 12.05.2022
			this.areAdditions = this.images[0].type == Config.EXM_ADDITION // 25.11.2022
		} else {
			Util.debug('[Album] (ngOnInit) - empty images!') // mai qui
			this.images = [] // 27.12.2021 vuoto, non nullo
			this.hasImages = false
		}
		//console.log("[Album] - ngOnInit "+this.eye);  // ok
	}

	normalize() {
		if (this.current < 0) {
			this.current = this.images.length - 1

			// 12.05.2022 patch [ls]
			if (this.current < 0) this.current = 0
		}

		if (this.current >= this.images.length) {
			this.current = 0
		}
	}

	showNext() {
		this.current++
		this.normalize()
		//console.log("[Album] - showNext");
	}

	showPrevious() {
		this.current--
		this.normalize()
		//console.log("[Album] - showPrevious");
	}

	showFullscreen(imgId) {
		let context: CanvasRenderingContext2D
		context = this.myCanvas.nativeElement.getContext('2d')
		let test = new Image()
		test.src = this.images[imgId].image

		if (test.width >= test.height) {
			context.canvas.height = window.innerHeight
			context.canvas.width = (window.innerHeight * test.width) / test.height
		} else {
			context.canvas.height = (window.innerWidth * test.height) / test.width
			context.canvas.width = window.innerWidth
		}
		//    context.canvas.width  = window.innerWidth;
		//    context.canvas.height = window.innerHeight;

		context.drawImage(test, 0, 0, test.width, test.height, 0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height)

		this.resampledImage = new Image()
		this.resampledImage.src = this.myCanvas.nativeElement.toDataURL()

		//let elemId = 'imgEye-'+imgId;
		let el = document.getElementById('imgEye-' + imgId)

		if (el != null) {
			//console.log("[album] showFullscreen, imgId: "+el.id);
			//el.style.setProperty('width', '150px');
			//el.style.setProperty('height', '150px');
		} else {
			console.log('[album] TODO showFullscreen, null img ' + imgId)
		}

		console.log('[album] showFullscreen')
		this.zoomActive = true
	}

	closeFullscreen() {
		this.zoomActive = false
	}

	// 02.08.2022
	downloadImg(img: ExamImage) {
		let imgDate = DateParser.formatSqlDate(img.date)
		let filename = img.type + '_' + img.examId + '_' + imgDate + '_' + img.eye + '_' + img.descr + '.jpg'

		this.session.getBlobFromBase64(img.image).then((blob: Blob) => {
			Util.mySaveAs(filename, blob)
		})
	}
}

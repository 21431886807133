import { Component, OnInit, Input, AfterViewInit, ElementRef, ViewChild, Output } from '@angular/core'
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

import { faSave, faEye, faCircle } from '@fortawesome/free-regular-svg-icons'
import { faCircleMinus, faCircleCheck, faCircleXmark, faRotate } from '@fortawesome/free-solid-svg-icons'

// 25.07.2022    MAT TABLE IMPORT
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatPaginator, MatPaginatorIntl, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { TablePrefs } from '../../models/settings.model'

import { SessionService } from '../../service/session.service'
import { DataModelService } from '../../service/data-model.service'

import { Util } from '../../models/util.model'
import { AiReport } from '../../models/aiReport.model'
import { DotReport } from '../../models/util.model'
import { visitListService } from 'src/app/service/visitList.service'
import { Subscription } from 'rxjs'

// per il modal
var providers = [NgbModal, FormsModule]
@Component({
	selector: 'report-ai',
	templateUrl: './report-ai.component.html',
	styleUrls: ['./report-ai.component.scss'],
	providers,
})
export class ReportAiList implements OnInit, AfterViewInit {
	// presenta una tabella ma su un modal
	dataSource: MatTableDataSource<AiReport>

	// i valori di input sono settati dal visitList
	//@Input() parent: VisitListComponent;
	@Input() reportList: AiReport[]
	@Input() patientId: number
	@Input() patientInfo: string // 25.08.2022 nome e cognome
	@Input() targetReport: number // per RDS

	// 22.06.2022   MatTable controller pagination + sort
	@ViewChild(MatPaginator) paginator: MatPaginator
	@ViewChild(MatSort) sort: MatSort
	@ViewChild('filter') input: ElementRef
	@ViewChild('mat-table') dataTable: MatTable<AiReport> // 02.08.2022
	sortStatus: Sort
	pageStatus: PageEvent
	displayedColumns: string[]
	@Output() reportAiPref: TablePrefs // xk in output?
	localStorageName: string

	reloadEnable: boolean
	loadingList: boolean
	loadingAiReportSubscription: Subscription

	myPageSize: number

	modalWaitPdf
	isGeneratingPdf: boolean

	waitNewAiReport: boolean

	faEye = faEye
	faSave = faSave

	// faGreen = faCircleCheck
	// faYellow = faCircleMinus
	// faRed = faCircleXmark
	faCircle = faCircle // 07.10.2022 as default
	faRotate = faRotate

	constructor(
		public session: SessionService,
		public activeModal: NgbActiveModal,
		public translator: TranslateService,
		public myMatPagIntl: MatPaginatorIntl,
		public dataService: DataModelService,
		private visitListService: visitListService
	) {
		Util.debug('[AiRepList] constructor')

		this.modalWaitPdf = null
		this.isGeneratingPdf = false
		this.targetReport = 0
		this.patientId = 0
		this.patientInfo = ''
		this.myPageSize = 10

		this.reloadEnable = true
		this.loadingList = true

		// rimosso, in quanto muovendosi tra pazienti ha poco senso, se nella lista di un paziente vado a pagina 2, quando apro un'altro mi va diretto a pagina 2 e crea confusione
		// saving preferences
		this.localStorageName = this.session.getUserId() + ' - reportAiPref'
		let saveLocal = sessionStorage.getItem(this.localStorageName)
		if (saveLocal) {
			this.reportAiPref = JSON.parse(saveLocal)
		} else {
			// first time
			this.reportAiPref = new TablePrefs()

			this.reportAiPref.empty = false
			// default data
			this.reportAiPref.sort = 'creation_date'
			this.reportAiPref.dir = 'desc'
			this.reportAiPref.itemsPerPage = 10

			sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportAiPref))
		}

		this.waitNewAiReport = this.visitListService.isWaitNewAiReport()
	}

	ngOnInit(): void {
		this.initColumns()
		Util.debug('[AiRepList] onInit')
		if (this.reportList) {
			Util.debug('[AiRepList] onInit, tot: ' + this.reportList.length)
		} else {
			Util.debug('[AiRepList] onInit - empty list')

			this.reportList = this.visitListService.getReportAi()
		}

		// translation for the mat-paginator
		this.myMatPagIntl.itemsPerPageLabel = this.translator.instant('PAGING.ITEMS_PER_PG')

		if (this.waitNewAiReport) {
			this.loadingAiReportSubscription = this.visitListService.loadingAiReports.subscribe((reportList) => {
				// console.log(reportList)
				this.waitNewAiReport = this.visitListService.isWaitNewAiReport()
				this.reportList = reportList
				this.manageList()
			})
		}
	}

	ngAfterViewInit() {
		Util.debug('[AiRepList] - ngAfterViewInit')
		this.manageList()

		// 24.08.2022 per RDS, gestione target report
		Util.debug('[AiRepList] afterInit, target rep. required: ' + this.targetReport)
		if (this.targetReport > 0) {
			//if(this.patientId){  // per AI non serve
			//  Util.debug("[AiRepList] afterInit - patient id: "+this.patientId);
			//}

			let myReportAi = this.getReportFromList(this.targetReport)
			if (myReportAi) {
				Util.debug('[AiRepList] - going to download target report ' + this.targetReport)
				this.downloadPdf(myReportAi)
			} else {
				Util.debug('[AiRepList] - target report not found, id: ' + this.targetReport)
			}
		}
	}

	reloadList() {
		Util.debug('(VisitList) - reload')
		this.reloadEnable = false
		this.refreshList()
	}

	private ApplySettings(pref, list) {
		// print data sort
		this.sort.active = pref.sort
		this.sort.direction = pref.dir
		this.sort.sortChange.emit()
		// print data paginator
		// this.paginator.pageIndex = pref.currentPage
		this.paginator.pageSize = pref.itemsPerPage
		list.paginator.page.emit()
		// search
		// list.filter = pref.filter
		// if (this.input) {
		// 	this.input.nativeElement.value = pref.filter
		// } else {
		// 	console.log('dead')
		// }

		// listen sort
		// list.sort.sortChange.subscribe(() => {
		// 	// save variables
		// 	pref.sort = this.sort.active
		// 	pref.dir = this.sort.direction
		// })
		// listen paginator
		list.paginator.page.subscribe(() => {
			pref.itemsPerPage = this.paginator.pageSize
			// pref.currentPage = this.paginator.pageIndex
		})
	}

	downloadPdf(reportAi: AiReport) {
		Util.debug('[AiReport] - downloadPdf id ' + reportAi.id)

		this.isGeneratingPdf = true

		this.session
			.loadAiReport(reportAi)
			.then((aiReport) => {
				// save as file
				Util.debug('[AiReport] - downloadPdf ok decrypt')

				reportAi.status = 4 // set status 4 without reload list

				// TODO more info, remove timestamp
				let timestamp = Date.now().toString()
				let fileName = 'Ai_' + aiReport.channel + '_' + reportAi.id + '_' + 'pat_' + reportAi.patient_id + '_' + timestamp + '.pdf'

				let binaryData = aiReport.pdf_clear

				// 29.07.2022 FIX need to force Uint8Array !!!!
				var ia = new Uint8Array(binaryData.length)
				for (let i = 0; i < binaryData.length; i++) {
					//ia[i] = binaryData[i];  // ko
					ia[i] = binaryData.charCodeAt(i) // ok
				}

				//var blob = new Blob([ia], {type: "application/octet-stream"});
				//var blob = new Blob([ia], {type: "octet-stream"});
				var blob = new Blob([ia], { type: 'application/pdf' })

				Util.mySaveAs(fileName, blob)

				this.isGeneratingPdf = false
			})
			.catch((error) => {
				// 26.08.2022 fix
				Util.debug('[AiReport] err: ' + error.message)
				this.isGeneratingPdf = false // blocca lo spinner

				// meglio msg genrico.. // Invalid IV length; got 3 bytes and expected 16 bytes.
				//let msg = this.session.parseErrorMessage(error, "alert");
				let msg = 'Some problems occurred during the download. Please contact your reference in Visionix.'
				alert(msg)
			})
	}

	// in quanto modal
	// Update 06.05.2019: A better way of
	// passing data from the modal-content to the modal-container
	// is to do that via modal close event instead of EventEmitter
	passBack() {
		Util.debug('[AiRepList] modal closed!')
		this.activeModal.close() // this.updatedRepStatus
	}

	manageList() {
		// console.log(this.reportList)

		if (this.reportList) {
			//22.06.2022   MatTables  caricamento della MatTables

			this.dataSource = new MatTableDataSource(this.reportList)
			this.dataSource.paginator = this.paginator
			this.dataSource.sort = this.sort

			this.ApplySettings(this.reportAiPref, this.dataSource)
		} else {
			Util.debug('[AiRepList] manageList - empty !')
		}

		this.loadingList = false
	}

	refreshList() {
		// Util.debug('[AiRepList] - refresh')

		let patId = this.patientId // parseInt(this.currentPatient.id);

		if (patId <= 0) return

		this.loadingList = true

		this.visitListService
			.requestAiReports()
			.then((replist) => {
				if (replist != null && replist.length > 0) {
					// force refresh
					this.dataSource.data = replist
					this.loadingList = false
					// Util.debug('[AiRepList] - refresh done')
				}

				setTimeout(() => {
					//abilito il pulsante dopo 5sec
					this.reloadEnable = true
				}, 5000)
			})
			.catch((err) => {
				if (!this.session.isExpired(err)) {
					Util.debug('(AiRepList) _ refresh _  KO ')
					Util.debug(err)
				}
			})
	}

	// 24.08.2022
	private getReportFromList(repId) {
		let myRep = null
		if (this.reportList) {
			for (let i = 0; i < this.reportList.length; i++) {
				if (repId == this.reportList[i].id) {
					myRep = this.reportList[i]
					break
				}
			}
		} else {
			Util.debug('[getReportFromList] - empty !')
		}
		return myRep
	}

	// 24.06.2022 per table material
	initColumns() {
		// colonne comuni a tutti i profili
		this.displayedColumns = ['id', 'creation_date', 'last_update', 'channel', 'device', 'status', 'TL'] // "TLR", "TLL"

		if (this.session.isLevel1()) {
			//this.displayedColumns.push("batch");  // info tecnica   - TEMP
			this.displayedColumns.push('actions') // solo lev1 puo' fare il download
		} else if (this.session.isLevel3()) {
			this.displayedColumns.push('batch') // info tecnica
			this.displayedColumns.push('grading') // 12.08.2022 solo il json
		}
		this.displayedColumns.push('filter')
	}

	// 12.08.2022 for admins this is the only info
	showGrading(aiRep: AiReport) {
		let tl = AiReport.TL_EMPTY
		if (aiRep) {
			tl = aiRep.getTrafficLight()
		}
		if (tl == AiReport.TL_EMPTY) {
			// valutare se fare alert ?
			Util.debug('please wait, grading results not available yet. Batch-id: ' + aiRep.batch_id)
			return
		}

		let myOD = aiRep.grading['od'] // oggetto json
		let myOS = aiRep.grading['os']

		let myVal =
			'Report type: ' +
			aiRep.channel +
			' \r\n' +
			'batch: ' +
			aiRep.batch_id +
			' \r\n' +
			'Traffic Light: ' +
			aiRep.getTrafficLight() +
			' \r\n' +
			'OD: ' +
			JSON.stringify(myOD) +
			' \r\n' +
			'OS: ' +
			JSON.stringify(myOS)

		if (!this.session.isOptician()) {
			alert(myVal)
		}
	}

	// 26.08.2022
	public getTlDotReport(myRep: AiReport) {
		let dotReport = new DotReport() // default niente

		let tl = myRep.getTrafficLight()

		if (tl == AiReport.TL_GREEN) {
			dotReport.class = 'statusrefN' // pallino verde
			dotReport.tooltip = this.translator.instant('AI_REPORTS.GREEN')
			dotReport.label = ':)'
		} else if (tl == AiReport.TL_YELLOW) {
			dotReport.class = 'statusrefY' // giallo
			dotReport.tooltip = this.translator.instant('AI_REPORTS.YELLOW')
			dotReport.label = ':-'
		} else if (tl == AiReport.TL_RED) {
			dotReport.class = 'statusrefU' // rosso
			dotReport.tooltip = this.translator.instant('AI_REPORTS.RED')
			dotReport.label = ':('
		}

		return dotReport
	}

	getTlFaIcon(myRep: AiReport) {
		let alertText

		let tl = myRep.getTrafficLight()

		if (tl == AiReport.TL_GREEN) {
			alertText = this.translator.instant('AI_REPORTS.GREEN_BODY')
		} else if (tl == AiReport.TL_YELLOW) {
			alertText = this.translator.instant('AI_REPORTS.YELLOW_BODY')
		} else if (tl == AiReport.TL_RED) {
			alertText = this.translator.instant('AI_REPORTS.RED_BODY')
		} else {
			alertText = '-'
		}

		return alertText
	}

	getChannel(Channel: AiReport) {
		let textChannel = this.translator.instant('AI_REPORTS.MCS')
		if (Channel.channel == AiReport.DSS) {
			textChannel = this.translator.instant('AI_REPORTS.DSS')
		}
		return textChannel
	}

	// 25.07.2022 [ls]
	handlePageEvent(event) {
		let newPageSize = event.pageSize
		Util.debug('new page size: ' + newPageSize)

		this.myPageSize = newPageSize // TODO salvarlo per prox accesso a questa pagina

		/* event e' un oggetto con i valori dopo il click
  {
    length: 1
    pageIndex: 0
    pageSize: 10
    previousPageIndex: 0
  }

  */
	}
	filterText() {
		this.reportAiPref.filter = this.input.nativeElement.value
		this.reportAiPref.filter = this.reportAiPref.filter.trim().toLocaleLowerCase()
		this.dataSource.filter = this.reportAiPref.filter
	}
	ngOnDestroy() {
		sessionStorage.setItem(this.localStorageName, JSON.stringify(this.reportAiPref))
		localStorage.setItem(this.localStorageName + ' - itemsPerPage', JSON.stringify(this.reportAiPref.itemsPerPage))

		if (this.loadingAiReportSubscription) {
			Util.debug('unsubscribe')
			this.loadingAiReportSubscription.unsubscribe()
		}
	}
}

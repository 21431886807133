import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core'

import { NgbModal, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
import { FormsModule } from '@angular/forms' // ReactiveFormsModule

import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
//import { DoctorListComponent } from './doctorList.component';

import { Distrib } from 'src/app/models/specialist.model'
import { Doctor } from 'src/app/models/doctor.model'
import { Address } from 'src/app/models/address.model'
import { ToastOptions } from 'src/app/models/toast.model'
import { Util } from '../../models/util.model'
import { Country } from '../../models/countries.models'
import { AppToastService } from 'src/app/service/toast.service'
import { TranslateService } from '@ngx-translate/core'

// per il modal
var providers = [NgbModal, FormsModule]

@Component({
	selector: 'app-specialist',
	templateUrl: './specialist.modal.html',
	styleUrls: ['./specialist.modal.scss'],
})
export class SpecialistModal implements OnInit, OnDestroy {
	@Input() currentRef: Distrib // FIXME, cambio nomi!

	currOrderN: string // per gestire la delete   24.02.2021
	myDiagnGroup: any

	// dispPassword: string // dispositive admin pwd field, for edit, delete, etc
	// wrongPwd: boolean

	countries: Country[] // 06.04.2023

	constructor(public session: SessionService, public activeModal: NgbActiveModal, private toastService: AppToastService, public translator: TranslateService) {
		console.log('specModal - constructor')
	}

	ngOnInit(): void {
		console.log('specialistModal - ngOnInit')
		this.countries = this.session.getDtCountries()
		//faccio bk dei campi per gestire eventuale delete per cfr [ls]
		this.myDiagnGroup = this.currentRef.getSzDiagnosisGrp() // 25.01.2022
		this.currOrderN = this.currentRef.order_reg_num

		// this.loadCountries()
	}

	// 06.04.2023 - copiata dal doctor - 30.08.2022
	isCountryReadonly() {
		let ret = true
		let currCountry = ''

		// 19.10.2022 problema: se country gia' impostata, non si riesce a settare la searchdropdown
		currCountry = this.currentRef.getCountry()
		//Util.debug('(editRef) country: '+currCountry);

		// 06.09.2022 estesi i casi
		if (currCountry == null || currCountry == '-' || currCountry == '') {
			ret = false // si puo' cambiare
		}

		return ret
	}

	// 06.04.2023
	// loadCountries() {
	// 	// 20.09.2022 solo se admin, non per superB
	// 	//if (!this.session.isSuperB()) {
	// 	if (this.session.isAdmin()) {
	// 		// 30.08.2022 richiedo solo una volta per sessione
	// 		this.countries = this.session.getDtCountries()

	// 		if (this.countries != null && this.countries.length > 0) {
	// 			Util.debug('(refModal) already loaded tot countries: ' + this.countries.length)
	// 		} else {
	// 			Util.debug('(refModal) going to request countries...')
	// 			this.session
	// 				.getCountries()
	// 				.then((list) => {
	// 					this.countries = list
	// 					Util.debug('(refModal) tot countries: ' + this.countries.length)
	// 				})
	// 				.catch((error) => {
	// 					Util.debug('(refModal) ERR on countries!')
	// 					console.log(error)
	// 				})
	// 		}
	// 		//Util.debug('(doctorModal) test 2 - country ? '+this.currentDoctor.getCountry()); // 19.10.2022
	// 	}
	// }

	//passBack() {
	editCurrentDoct() {
		Util.debug('UpdateRef - passBack')

		if (!this.session.isLevel3()) {
			// god || vice
			console.log('(UpdateRef) user ' + this.session.getUserId() + ' not authorized')
			return false
		}

		// 24.02.2021 anticipato test qui
		// if (!this.session.checkNSPwd(this.dispPassword) && !this.session.isSuperB()) {
		// 	console.log('(UpdateRef) - pwd dispositiva non corretta')
		// 	this.wrongPwd = true
		// 	return false
		// }
		// this.wrongPwd = false

		// gestione delete reg_num
		// if (this.currOrderN != '' && this.currentRef.order_reg_num == '') {
		// 	// e' stato cancellato
		// 	console.log('(UpdateRef) DELETE old order_reg_num, ' + this.currOrderN)
		// 	this.currentRef.order_reg_num = Config.DELETE_ME
		// }

		console.log('(UpdateRef) - passBack - edit')

		this.editRef()
			.then(() => {
				console.log('(UpdateRef) done... ')

				// alert('OK, updated!')
				let flagDone = true
				return this.activeModal.close(this.currentRef)
				//return true;
			})
			.catch((err) => {
				console.log(err)
				var msg = '' // (err.data)? err.data.error : err.toString();
				console.log('(updtRef) KO 2 - ' + msg)

				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = this.translator.instant('TOAST.REMOTES') + '. ' + this.translator.instant('TOAST.BODYERR.EDIT')
				let options = new ToastOptions('error')
				this.toastService.show(header, body, false, options, 'center')

				// alert('Error: ' + msg)
				return false
			})
	}

	// 27.01.2022 spostato qui da remotelist
	private editRef(): Promise<any> {
		Util.debug('(UpdateRef) sending to the server...') // 07.10.2021 solo per test
		return this.session.updateDistrib(this.currentRef)
	}

	// 15.09.2022 close without doing anything
	dismiss(reason?) {
		Util.debug('(updtRef), dismiss, no action, ' + reason)
		this.activeModal.close(false)
	}

	// 19.10.2021
	canEditDiagnGroup() {
		var ret = false
		// TODO
		return ret
	}

	// 14.04.2023
	getReadOnlyCountry() {
		let myC = this.currentRef.country
		let ret = this.session.getCountryFullName(myC)
		return ret
	}

	ngOnDestroy(): void {}
}
